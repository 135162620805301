<template>
    <div class="announcement">
        <div class="title">帮助文档</div>
        <div style="display: grid;grid-template-columns: 1fr 1fr ;">
            <div v-for="(item,index) in questionList " :key="index" class="question">
<!--                    {{item.name}}-->
                <span>{{item.name.slice(0,8)}}
                     <i v-if="item.name.length>8">...</i>
                    </span>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                questionList:[
                    {id:1,name:'如何完成个人实名认证'},
                    {id:1,name:'如何完成个人实名认证'},
                    {id:1,name:'如何完成企业实名认证'},
                    {id:1,name:'如何完成企业实名认证'},
                    {id:1,name:'如何找回账号密码'},
                    {id:1,name:'如何找回账号密码'},
                    {id:1,name:'如何修改手机号码'},
                    {id:1,name:'如何修改手机号码'},
                    {id:1,name:'怎么设置服务器的端口？'},
                    {id:1,name:'怎么设置服务器的端口？'},

                ]
            }
        }
    }
</script>
<style lang="scss" scoped>
    .announcement {
        width: 360px;
        height: 206px;
        background: #FFFFFF;
        border-radius: 2px;
        margin-bottom: 16px;
        padding: 20px;
        .title{
            width:100%;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 20px;
            margin-bottom: 13px;
        }
        .question{
            width: 150px;
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            line-height: 17px;
            margin-bottom:12px;
            cursor: pointer;
        }
        .question:hover{
            color: #3370FF;
        }
    }
</style>