<template>
    <div>
        <za-dialog
                :top-title="topTitle"
                :visible.sync="payDialog"
                width="800px"
                :showClose="false"
        >
            <div>
                <div class="prompt">
            <span style="margin-left: 16px">
                <za-svg-icon icon-class="za-wanning"></za-svg-icon>
                </span>
                    <span>请在新页面完成付款，如付款遇到问题请致电: 400-018-5113</span>
                </div>

            </div>
            <div slot="footer">
                <za-button  :type="buttonTypeObj.GREY"
                            @click="successPay" >
                    {{isPay?'支付遇到问题':'充值遇到问题'}}
                </za-button>
                <za-button :disabled="confirmBtnLoading"  :type="buttonTypeObj.BLUE"
                           @click="successPay">
                    
                    {{isPay?'已完成支付':'已完成充值'}}

                </za-button>

            </div>
        </za-dialog>
    </div>
</template>
<script>
    export default {
        props: {
            payDialog: {
                type: Boolean,
                default: false,
              require:false

            },
            topTitle:{
                type: String,
                default: ''
            },
            isPay:{
              // 是否是支付
              type: Boolean,
              default: false,
              require:false
            }
        },
        data() {
            return {}
        },
        methods: {
           async successPay() {
                await  this.$store.dispatch('user/getBalanceData')
                // this.payDialog = false
                this.$emit('click', false)
            },

        }
    }
</script>
<style lang="scss" scoped>
    .prompt {
        height: 40px;
        background: #FFFBE6;
        border-radius: 2px;
        border: 1px solid #FFE58F;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 40px;

    }
</style>