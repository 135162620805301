<template>
    <div>
        <div class="flex">
            <za-canvas
                    ref="slideblock"
                    :l="42"
                    :r="10"
                    :w='fullWidth'
                    :h="200"
                    :changeState='changeState'
                    :accuracy="accuracy"
                    :slider-text="text"
                    :imgs="imgList"
                    @again="onAgain"
                    @fulfilled="onFulfilled"
                    @success="onSuccess"
                    @fail="onFail"
                    @refresh="onRefresh"
                    @change="changePic"
            />
        </div>
    </div>
</template>

<script>
    import zaCanvas from '@/za/components/zaCanvas'

    export default {
        components: {zaCanvas},
        props: {
            selectKey: {
                type: Number,
                default: 1
            }
        },
        data() {
            return {
                fullWidth: 400,
                fullHeight: 200,
                windowHeight: '',
                windowWidth: '',
                msg: '',
                changeState: false,
                text: '请向右滑动滑块完成验证',
                // 精确度小，可允许的误差范围小；为1时，则表示滑块要与凹槽完全重叠，才能验证成功。默认值为5
                accuracy: 3,
                imgList: [
                    require('@/assets/login/verification.png'),
                    require('@/assets/login/verification_two.png'),
                    require('@/assets/login/verification_three.jpg')
                ]
            }
        },
        name: 'TrialGcpWebAdminVerify',

        mounted() {
            var that = this;
            window.onresize = () => {
                return (() => {
                    window.fullHeight = document.documentElement.clientHeight;
                    window.fullWidth = document.documentElement.clientWidth;
                    that.windowHeight = window.fullHeight;  // 高
                    that.windowWidth = window.fullWidth; // 宽
                })()
            };
            window.onresize()
        },

        watch: {
            windowWidth(val) {
                let that = this;
                this.fullWidth = (that.windowWidth * 0.23)
                this.changeState = true
            },

        },
        methods: {
            changePic(val){
                this.changeState = val
            },
            onSuccess() {
                this.msg = '验证成功！'
                this.$msgSuccess(this.msg)
                this.$emit('verifySuccess', this.selectKey)
            },
            onFail() {
                this.msg = '验证不通过'
                this.$msgError(this.msg)
            },
            onRefresh() {
                // console.log('点击了刷新小图标')
                // this.msg = ''
            },
            onFulfilled() {
                // console.log('刷新成功啦！')
            },
            onAgain() {
                this.msg = 'try again'
                // 刷新
                this.$refs.slideblock.reset()
            },
            handleClick() {
                // 父组件直接可以调用刷新方法
                this.$refs.slideblock.reset()
            },
            redrawPicture() {
                this.$baseEventBus.$emit('redrawPicture', true)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .flex {

    }

    .slide-verify {
    }
</style>