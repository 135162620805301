var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "announcement" }, [
    _c("div", { staticClass: "title" }, [_vm._v("帮助文档")]),
    _c(
      "div",
      { staticStyle: { display: "grid", "grid-template-columns": "1fr 1fr" } },
      _vm._l(_vm.questionList, function (item, index) {
        return _c("div", { key: index, staticClass: "question" }, [
          _c("span", [
            _vm._v(_vm._s(item.name.slice(0, 8)) + " "),
            item.name.length > 8 ? _c("i", [_vm._v("...")]) : _vm._e(),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }