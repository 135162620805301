var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v(_vm._s(_vm.title))]),
    ]),
    _c("div", [_c("card"), _c("customerTab")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }