/**
 * Login数据模块
 */

/**
 * @description 登录反馈结果
 * @property {String} account 账号
 * @property {Boolean} pwd_exp 密码过期 需要修改 不是退出登录
 * @property {Boolean} pwd_first_login 首次登录 需要修改密码
 * @property {String} token token
 * @property {String} uuid uuid
 * @property {String} pwd_rule 密码规则
 * @type {{pwd_rule: string, pwd_exp: boolean, uuid: string, account: string, pwd_first_login: boolean, token: string}}
 */
export const resLoginObj = {
  account: '',
  pwd_exp: false,
  pwd_first_login: false,
  token: '',
  uuid: '',
  pwd_rule: '',
}

/**
 * @description 用户余额反馈结果
 * @property {String} account 账号
 * @property {Boolean} pwd_exp 密码过期 需要修改 不是退出登录
 * @property {Boolean} pwd_first_login 首次登录 需要修改密码
 * @property {String} token token
 * @property {String} uuid uuid
 * @property {String} pwd_rule 密码规则
 * @type {{pwd_rule: string, pwd_exp: boolean, uuid: string, account: string, pwd_first_login: boolean, token: string}}
 */
export const resBalanceObj = {
  balance: '',
  cashBalance: '',
  frozenBalance: '',
  warningAmount: '',
}
/**
 * @description 用户信息反馈结果
 * @property {String} account 账号
 * @property {String} create_at 创建时间
 * @property {Number} id 用户id
 * @property {Boolean} is_super 是否超管
 * @property {Array} menus 拥有的菜单权限,超级管理员拥有所有菜单权限
 * @property {String} name 名称
 * @property {Boolean} otp 是否开启otp认证登录
 * @property {Number} role 角色id
 * @property {String} role_name 角色名称
 * @property {Boolean} state 状态： // 启用/禁用
 * @property {String} updated_at 更新时间
 * @type {{role_name: string, role: number, updated_at: string, name: string, is_super: boolean, otp: boolean, id: number, menus: *[], state: boolean, create_at: string, account: string}}
 */
export const resUserInfoObj = {
  // account: '',
  // create_at: '',
  // name: '',
  // otp: true,
  // role: 0,
  // role_name: '',
  // state: true,
  // updated_at: '',
  username:'',
  createAt:'',
  id: 0,
  is_super: true,
  menus: [],
  uuid:''
}

/**
 * @description logo信息反馈结果
 * @property {String} logo 图片
 * @property {String} name 名称
 * @type {{logo: string, name: string}}
 */
export const resLogoInfo = {
  logo: '',
  name: ''
}
