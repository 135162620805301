<template>
  <div>
    <div>
      <navbar />
    </div>
    <div class="evaluationActivity-center_bg">
      <div class="title">
        等保无忧
        <!--        <za-go-back title="等保无忧"></za-go-back>-->
      </div>
      <div class="top">
        <div>
          <za-tabs :tabArray="titleList" :handleActive="titleList[0].key" :showBorder="false"></za-tabs>
        </div>
        <el-form ref="form" :disabled="confirmBtnLoading" :model="baseForm" label-position="right" label-width="230px">
          <el-form-item :label="'服务版本:'">
            <div class="condition">
              <div
                v-for="(item, index) in versionList"
                :class="{ selected: index === versionIndex }"
                class="choose"
                @click="chooseCond(index, item, '1')"
              >
                {{ item.name }}
              </div>
            </div>
          </el-form-item>
          <el-form-item :label="'服务类型:'">
            <div class="serviceType">
              <div>定制版</div>
              <div>
                按场景化提供定制网络安全整改服务，智安云安全专家远程支持，提供等保的安全整改建议+权威机构的测评服务，一站式让您的网络安全更加无忧
              </div>
            </div>
          </el-form-item>
          <el-form-item :label="'测评级别:'">
            <div class="condition">
              <div
                v-for="(item, index) in dengbaoList"
                :class="{ selected: index === dengbaoIndex }"
                class="choose"
                @click="chooseCond(index, item, '2')"
              >
                {{ item.name }}
              </div>
            </div>
          </el-form-item>
          <el-form-item :label="'测评区域:'">
            <div class="condition">
              <div
                v-for="(item, index) in regionList"
                :class="{ selected: index === regionIndex }"
                :key="index"
                class="choose"
                @click="chooseCond(index, item, '3')"
              >
                {{ item.name }}
              </div>
            </div>
            <div v-for="(item, index) in regionList" :key="index" class="region">
              <span v-if="index === regionIndex" :class="{ regionselected: index === regionIndex }">
                {{ item.childTest }}
              </span>
            </div>
          </el-form-item>
          <el-form-item :label="'系统数量:'">
            <el-input-number
              v-model="baseForm.systemNum"
              :min="1"
              :max="50"
              @keydown.native="channelInputLimit"
            ></el-input-number>
          </el-form-item>
          <el-form-item :label="'计费模式:'">
            <div class="condition">
              <div
                v-for="(item, index) in payList"
                :class="{ selected: index === payIndex }"
                class="choose"
                @click="chooseCond(index, item, '4')"
              >
                {{ item.name }}
              </div>
            </div>
          </el-form-item>
        </el-form>
        <div class="prompt">
          <h5>{{ translateTitle('topUp.reminder') }}：</h5>
          <span>
            1、因等保测评价格与项目实际情况、IT系统所在地息息相关，费用仅做参考。购买前，请拨打400-018-5113或直接联系售前专家，确定项目报价后再下单，谢谢！
          </span>
          <span>2、该订单服务周期为1个月，订单下单后1个月后将自动失效；订单失效后将不再提供相关服务。</span>
          <span>3、非公有云场景购买前，请联系等保售前经理，确定项目范围后再下单，谢谢！</span>
        </div>
      </div>
      <div id="bottom">
        <div>
          <za-tabs :tabArray="infoList" :handleActive="infoList[0].key" :showBorder="false"></za-tabs>
        </div>
        <div style="width: 800px">
          <el-form
            ref="form"
            :disabled="confirmBtnLoading"
            :model="baseForm"
            label-position="right"
            label-width="200px"
            :rules="rules"
          >
            <el-form-item label="公司名称:" prop="corporateName">
              <el-input v-model="baseForm.corporateName" placeholder="请填写您的公司名称" maxlength="32" />
            </el-form-item>
            <el-form-item :label="'所在省市:'" prop="selectedOptions">
              <el-cascader
                size="large"
                :options="options"
                v-model="selectedOptions"
                @change="handleChange"
                style="width: 100%"
              ></el-cascader>
            </el-form-item>
            <el-form-item :label="'所在行业:'" prop="trade">
              <!--              <el-select-->
              <!--                v-model="baseForm.trade"-->
              <!--                clearable-->
              <!--                :placeholder="`${translateTitle('placeChoose')}`"-->
              <!--                style="width: 100%"-->
              <!--              >-->
              <!--                <el-option v-for="item in typeOptions" :key="item.value" :label="item.name" :value="item.value" />-->
              <!--              </el-select>-->
              <el-cascader
                clearable
                :show-all-levels="false"
                v-model="baseForm.trade"
                :options="industryOptions()"
                @change="industryChange"
                style="width: 100%"
              ></el-cascader>
            </el-form-item>
            <el-form-item :label="'系统类型:'" prop="systemType">
              <el-select
                v-model="baseForm.systemType"
                clearable
                :placeholder="`${translateTitle('placeChoose')}`"
                style="width: 100%"
              >
                <el-option v-for="item in systemType" :key="item.value" :label="item.name" :value="item.value" />
              </el-select>
            </el-form-item>
            <el-form-item :label="'联系人姓名:'" prop="accountName">
              <el-input v-model="baseForm.accountName" placeholder="请填写您的联系人姓名" />
            </el-form-item>
            <el-form-item :label="'联系电话:'" required>
              <div>
                <el-col :span="24">
                  <el-form-item prop="phone">
                    <el-input
                      v-model="baseForm.phone"
                      placeholder="请输入手机号"
                      maxlength="11"
                      @keyup.native="trim('phone')"
                      @input="changeWaring()"
                    >
                      <template slot="prepend">+86(中国)</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </div>
              <div class="code">
                <el-col :span="16">
                  <el-form-item prop="code">
                    <el-input v-model="baseForm.code" placeholder="请输入验证码！" @input="changeWaring()" />
                    <span v-if="waringState" class="red-font">{{ waringText }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="2">&nbsp;</el-col>
                <el-col :span="6">
                  <el-button
                    @click="sendData"
                    class="sendInfo"
                    :disabled="dis"
                    :class="{ grey: isGrey, blue: !isGrey }"
                  >
                    <span v-if="telState == 1">发送验证码</span>
                    <span v-if="telState == 2">{{ count }}秒重试</span>
                    <span v-if="telState == 3">重新发送</span>
                  </el-button>
                </el-col>
              </div>
            </el-form-item>
            <el-form-item label="被定级系统名称:" prop="systemName">
              <el-input v-model="baseForm.systemName" placeholder="请填写您的被定级系统名称" />
            </el-form-item>
            <el-form-item label="系统部署的服务器台数:" prop="number">
              <el-input-number
                v-model="baseForm.number"
                :min="10"
                :step="10"
                @keydown.native="channelInputLimit"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="系统带宽:" prop="tapeWidth">
              <el-input-number
                v-model="baseForm.tapeWidth"
                :min="20"
                :step="10"
                @keydown.native="channelInputLimit"
              ></el-input-number>
            </el-form-item>
            <el-form-item label=" 联系人邮箱:">
              <el-input v-model="baseForm.email" placeholder="请填写您的联系人邮箱" />
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-dialog width="25%" title="验证" :visible.sync="innerVisible" append-to-body>
            <za-verify v-if="showVerity" ref="verify" @verifySuccess="success" :selectKey="'1'" />
          </el-dialog>
        </div>
      </div>
      <div>
        <div class="bun_fix">
          <i class="red-font">*</i>
          <span class="black-font">参考价格，具体扣费请以账单为准。</span>
          <el-button type="primary" @click="getPrice">获取报价</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Navbar from '@/za/layout/components/Navbar'
  import zaTabs from '@/za/components/zaTabs'
  import { CodeToText, provinceAndCityData } from 'element-china-area-data'
  import ZaVerify from '@/za/components/zaVerify/index'
  import { apiDengbaoSendCode, apiDengbaoSubmit } from '@/api/evaluationActivity'
  import { isPhone } from '@/utils/validate'
  import { mapGetters } from 'vuex'
  import { industryOptions } from '@/utils/enums'

  export default {
    components: {
      Navbar,
      zaTabs,
      ZaVerify,
    },
    data() {
      const validateOrg = (rule, value, callback) => {
        if (!this.selectedOptions.length) {
          callback(new Error('请选择所在省市'))
        } else {
          callback()
        }
      }
      const phonePass = (rule, value, callback) => {
        if (value === '') callback(new Error('请填入手机号'))
        else if (!isPhone(value)) callback(new Error('手机格式错误，请校验后重新输入'))
        else callback()
      }
      const companyName = (rule, value, callback) => {
        let rege = /[^\a-\z\A-\Z0-9\u4E00-\u9FA5\(\)\（\）\.\_\-]/g
        if (rege.test(value) || value.length < 2 || value.length > 32) {
          callback(new Error('请输入中文，英文，数字字符，英文括号，中文括号，英文句点，下划线或中划线，且长度2到32位'))
        } else {
          callback()
        }
      }
      const linkName = (rule, value, callback) => {
        let rege = /[^\a-\z\A-\Z0-9\u4E00-\u9FA5\-\_]/g
        if (rege.test(value) || value.length < 1 || value.length > 32) {
          callback(
            new Error('联系人格式为: 1~32位字符，中文、字母、数字、下划线、中划线,不能有空格不能包含其它特殊字符')
          )
        } else {
          callback()
        }
      }
      return {
        titleList: [
          {
            name: '套餐选择',
            key: '套餐选择',
          },
        ],
        infoList: [
          {
            name: '信息填写',
            key: '信息填写',
          },
        ],
        versionList: [{ name: '等保安全', value: '等保安全' }],
        versionIndex: 0,
        dengbaoList: [
          { name: '等保二级', value: '等保二级' },
          { name: '等保三级', value: '等保三级' },
        ],
        dengbaoIndex: 0,
        regionList: [
          { name: 'A类地区', value: 'A', childTest: '广东、贵州、湖南' },
          { name: 'B类地区', value: 'B', childTest: '四川、湖北、安徽' },
          {
            name: 'C类地区',
            value: 'C',
            childTest: '山东、海南、重庆、云南、陕西、辽宁、山西、河北、河南、江苏、浙江、天津',
          },
          { name: 'D类地区', value: 'D', childTest: '西藏、内蒙古、福建、吉林、广西' },
          { name: '其他', value: '其他', childTest: '北京、上海、黑龙江' },
        ],
        regionIndex: 0,
        num: 20,
        payList: [{ name: '一次性付款', value: '一次性付款' }],
        payIndex: 0,
        baseForm: {
          phone: '',
          serviceVersion: '等保安全',
          serviceType: '定制版',
          evaluatingLevel: '等保二级',
          evaluatingArea: 'A',
          area: '广东、贵州、湖南',
          systemNum: 1,
          billingMode: '一次性付款',
          corporateName: '',
          provinceCity: '',
          trade: '',
          systemType: '公有云系统',
          accountName: '',
          code: '',
          systemName: '',
          number: 10,
          tapeWidth: 20,
          email: '',
        },
        options: provinceAndCityData,
        selectedOptions: [],
        // typeOptions: [
        //   { name: '农、林、牧、渔业', value: '农、林、牧、渔业' },
        //   { name: '采矿业', value: '采矿业' },
        //   { name: '制造业', value: '制造业' },
        //   { name: '电力、燃气及水的生产和供应业', value: '电力、燃气及水的生产和供应业' },
        //   { name: '建筑业', value: '建筑业' },
        //   { name: '交通运输、仓储和邮政业', value: '交通运输、仓储和邮政业' },
        //   { name: '信息传输、计算机服务和软件业', value: '信息传输、计算机服务和软件业' },
        //   { name: '批发和零售业', value: '批发和零售业' },
        //   { name: '住宿和餐饮业', value: '住宿和餐饮业' },
        //   { name: '卫生、社会保障和社会福利业', value: '卫生、社会保障和社会福利业' },
        //   { name: '居民服务和其他服务业', value: '居民服务和其他服务业' },
        //   { name: '其他', value: '其他' },
        // ],

        systemType: [
          { name: '公有云系统', value: '公有云系统' },
          { name: '线下IDC', value: '线下IDC' },
          { name: '私有云平台', value: '私有云平台' },
        ],
        count: '',
        telState: 1,
        dis: false,
        show: true,
        timer: null, //设置计时器,
        isGrey: false, //按钮样式
        showVerity: false,
        innerVisible: false,
        rules: {
          corporateName: [
            { required: true, message: `请输入公司名称`, trigger: 'blur' },
            {
              validator: companyName,
              message: `请输入中文，英文，数字字符，英文括号，中文括号，英文句点，下划线或中划线，且长度2到32位`,
              trigger: 'blur',
            },
          ],
          selectedOptions: [{ type: 'array', required: true, validator: validateOrg }],
          trade: [{ message: `请选择所属行业`, required: true, trigger: 'change' }],
          phone: [{ required: true, validator: phonePass, trigger: 'blur' }],
          systemType: [{ message: `请选择系统类型`, required: true, trigger: 'change' }],
          accountName: [
            { required: true, message: `请输入正确的联系人姓名`, trigger: 'blur' },
            {
              validator: linkName,
              message: `联系人格式为: 1~32位字符，中文、字母、数字、下划线、中划线,不能有空格不能包含其它特殊字符。`,
              trigger: 'blur',
            },
          ],
          systemName: [
            { required: true, message: `请输入被定级系统名称`, trigger: 'blur' },
            {
              validator: companyName,
              message: `请输入中文，英文，数字字符，英文括号，中文括号，英文句点，下划线或中划线，且长度2到32位`,
              trigger: 'blur',
            },
          ],
          number: [{ message: `请选择部署的服务器台数`, required: true, trigger: 'change' }],
          tapeWidth: [{ message: `请选择系统带宽`, required: true, trigger: 'change' }],
          code: [{ required: true, message: `请输入正确的6位验证码`, trigger: 'blur' }],
        },
      }
    },
    computed: {
      ...mapGetters({
        token: 'user/token',
      }),
    },
    methods: {
      industryOptions() {
        return industryOptions
      },
      industryChange(value) {
        console.log(value)
        this.baseForm.trade = value[1]
        console.log(this.baseForm.trade)
      },
      goBack() {
        // this.$router.push('/')
        window.open(`${process.env.VUE_APP_PORTAL_TARGET_URL}/cp-activity?comToken=` + this.token, '_self')
      },
      trim(val) {
        this.baseForm[val] = this.baseForm[val].replace(/\s*/g, '')
      },
      chooseCond(index, item, val) {
        switch (val) {
          case '1': //服务版本
            this.versionIndex = index
            this.baseForm.serviceVersion = item.value
            break
          case '2': //测评级别
            this.dengbaoIndex = index
            this.baseForm.evaluatingLevel = item.value
            break
          case '3': //测评区域
            this.regionIndex = index
            this.baseForm.evaluatingArea = item.value
            this.baseForm.area = item.childTest
            break
          case '4': //计费模式
            this.payIndex = index
            this.baseForm.billingMode = item.value
            break
          default:
            break
        }
      },
      handleChange(value) {
        if (value.length === 0) {
          return
        } else {
          if (value[1] != null) {
            this.baseForm.province = CodeToText[value[0]]
            this.baseForm.city = CodeToText[value[1]]
            this.baseForm.provinceCity = this.baseForm.province + this.baseForm.city
          } else {
            this.baseForm.province = CodeToText[value[0]]
            this.baseForm.city = ''
            this.baseForm.provinceCity = this.baseForm.province + this.baseForm.city
          }
        }
      },

      channelInputLimit(e) {
        e.returnValue = ''
      },
      sendData() {
        this.innerVisible = true
        this.showVerity = true
      },
      success(val) {
        this.innerVisible = false
        this.showVerity = false
        this.getCode()
      },
      getCode() {
        apiDengbaoSendCode({ phone: this.baseForm.phone }).catch()
        let TIME_COUNT = 60
        if (!this.timer) {
          this.count = TIME_COUNT
          this.isGrey = true
          this.telState = 2
          this.dis = true
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--
            } else {
              this.dis = false
              this.isGrey = false
              this.telState = 3
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)
        }
      },
      getPrice() {
        // 自动定位到信息填写区域
        const contentElement = document.getElementById('bottom')
        if (contentElement) {
          contentElement.scrollIntoView({ behavior: 'smooth' })
        }
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let res
            res = await apiDengbaoSubmit(this.baseForm).finally(() => (this.confirmBtnLoading = false))
            if (res.code == 0) {
              this.$router.push({
                path: '/evaluationActivity/priceDesc',
                query: {
                  EvaluatingArea: res.data.EvaluatingArea,
                  area: res.data.area,
                  evaluatingLevel: res.data.evaluatingLevel,
                  number: res.data.number,
                  originalPrice: res.data.originalPrice,
                  price: res.data.price,
                  serviceType: res.data.serviceType,
                  serviceVersion: res.data.serviceVersion,
                  systemNum: res.data.systemNum,
                  tapeWidth: res.data.tapeWidth,
                },
              })
            }
          } else {
            return false
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import '~@/za/styles/mixin.scss';

  .evaluationActivity-center_bg {
    width: 1700px;
    margin: 20px auto 0px;

    .title {
      width: 100%;
      /* px-to-viewport-ignore-next */
      height: 62px;
      line-height: 62px;
      background: #ffffff;
      border-radius: 2px;
      margin-bottom: 20px;
      padding-left: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #000000;
    }

    .top {
      width: 1700px;
      min-width: 1700px;
      padding: 20px;
      background: #ffffff;
      margin-bottom: 20px;

      .condition {
        display: flex;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        cursor: pointer;

        .choose {
          width: 130px;
          height: 32px;
          border-radius: 2px;
          border: 1px solid #c0c4cc;
          color: #c0c4cc;
          line-height: 32px;
          text-align: center;
          margin-bottom: 20px;
          margin-right: 10px;
        }

        .selected {
          width: 130px;
          height: 32px;
          border-radius: 2px;
          border: 1px solid #3370ff;
          background: #1b5deb;
          color: #ffffff;
          line-height: 32px;
          text-align: center;
          margin-right: 10px;
        }
      }

      .region {
        .regionselected {
          width: 100%;
          display: inline-block;
          padding-bottom: 10px;
          border-bottom: 1px solid #f5f5f5;
        }
      }

      .serviceType {
        height: 97px;
        background-color: rgba(248, 248, 248, 1);
        border-radius: 3px;
        padding: 20px;

        div:first-child {
          font-weight: bold;
        }
      }

      .prompt {
        width: 100%;
        height: 140px;
        background: #f4f6fa;
        border-radius: 2px;
        padding: 15px 15px;
        margin-bottom: 30px;

        h5 {
          @include top_title($size: 14px, $weight: 600, $color: red);
          margin: 0px 0px 10px 0px;
        }

        span {
          display: block;
          font-size: 14px;
          margin-bottom: 8px;
          color: red;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }
      }
    }

    #bottom {
      width: 1700px;
      min-width: 1700px;
      padding: 20px;
      background: #ffffff;
      margin-bottom: 20px;
      height: 758px;
    }

    .bun_fix {
      display: flex;
      align-items: center;
      padding: 16px 200px 16px 70%;
      position: fixed;
      bottom: 0px;
      width: 100%;
      right: 0;
      height: 64px;
      background: #ffffff;
      z-index: 99;
      box-shadow: 0px -9px 28px 8px rgba(0, 0, 0, 0.05);
    }

    .black-font {
      width: 100%;
      min-width: 227px;
    }
  }

  ::v-deep {
    .code {
      .el-col-16 {
        height: 32px !important;
      }
    }
  }
</style>