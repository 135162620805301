<template>
    <div class="overView-center_bg">
        <div class="title">
            <span>{{title}}</span>
        </div>
        <div class="center">
            <div class="centerHeard">
                <heard></heard>
            </div>

            <div class="overView-top">
                <promote-customers></promote-customers>
                <rebate-profile></rebate-profile>
            </div>
            <div>
<foote></foote>
            </div>
        </div>
    </div>
</template>
<script>
import heard from './components/heard'
import promoteCustomers from './components/promoteCustomers'
import rebateProfile from './components/rebateProfile'
import foote from './components/fooder'
    export default {
    components:{heard,promoteCustomers,rebateProfile,foote,},
        data() {
            return {
                title:''
            }
        },
        created() {
            this.title=this.$route.meta.title
        }
    }
</script>
<style lang="scss" scoped>
    .overView-center_bg {
        .centerHeard{

        }
        .title{
            height: 62px;
            background: #FFFFFF;
            border-radius: 2px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            padding: 20px;
            margin-bottom: 20px;
        }
        .overView-top {
            display: flex;
            background: #F3F4F7;
            margin-bottom: 20px;
        }
    }
</style>