<template>
  <div class="overView-center_bg">
    <div class="title">
      <span>{{ title }}</span>
    </div>
    <div class="overView-top">
      <money-surplus></money-surplus>
      <money-available></money-available>
      <rebate-balance></rebate-balance>
    </div>
    <div class="overView-content">
      <reminder></reminder>
      <card-roll></card-roll>
    </div>
    <div class="overView-btn">
      <btndetails></btndetails>
    </div>
  </div>
</template>
<script>
  import cardRoll from './components/cardRoll'
  import btndetails from './components/details'
  import moneyAvailable from './components/moneyAvailable'
  import moneySurplus from './components/moneySurplus'
  import rebateBalance from './components/rebateBalance'
  import reminder from './components/reminder'

  export default {
    components: { cardRoll, btndetails, moneyAvailable, moneySurplus, reminder, rebateBalance },
    data() {
      return {
        title: '',
      }
    },
    created() {
      this.title = this.$route.meta.title
    },
  }
</script>
<style lang="scss" scoped>
  .overView-center_bg {
    .title {
      height: 62px;
      background: #ffffff;
      border-radius: 2px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      padding: 20px;
      margin-bottom: 20px;
    }

    .overView-top,
    .overView-content {
      display: flex;
      background: #f3f4f7;
      margin-bottom: 20px;
    }
  }
</style>