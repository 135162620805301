var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-component" }, [
    _c("main", [
      _c("div", { staticClass: "left" }, [
        _c("div", { staticClass: "line" }),
        _c("p", [_vm._v(_vm._s(_vm.title))]),
      ]),
      _c("div", { staticClass: "right", on: { click: _vm.goBack } }, [
        _vm._v("返回"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }