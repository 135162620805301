var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "za-loading",
          rawName: "v-za-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "footer",
    },
    [
      _c("div", { staticClass: "head" }, [
        _c("div", { staticClass: "title" }, [_vm._v("推广收入")]),
        _c("div", { staticClass: "details", on: { click: _vm.goDetail } }, [
          _vm._v("结算明细"),
        ]),
      ]),
      _c("occ-table", {
        attrs: {
          tableData: _vm.tableData,
          columObj: _vm.columObj,
          isOp: _vm.columObj.isOp,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }