<template>
  <div class="address-container">
    <div class="title">发票信息管理</div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="发票信息" name="1">
        <info v-if="activeName == 1"></info>
      </el-tab-pane>
      <!--      <el-tab-pane label="邮寄地址" name="2">-->
      <!--        <adress v-if="activeName == 2"></adress>-->
      <!--      </el-tab-pane>-->
    </el-tabs>
  </div>
</template>
<script>
  import info from './components/info'
  import adress from './components/adress'

  export default {
    components: {
      info,
      adress,
    },
    created() {
      if (this.$route.query.type) {
        this.activeName = this.$route.query.type
      }
    },
    data() {
      return {
        activeName: '1',
      }
    },
  }
</script>
<style lang="scss" scoped>
  @import '~@/za/styles/mixin.scss';

  .title {
    @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
    width: 100%;
    height: 20px;
    margin-bottom: 27px;
  }
</style>