var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c("div", { staticClass: "heardBlock" }, [
        _c(
          "div",
          { staticClass: "ignore_center", on: { click: _vm.officialWeb } },
          [
            _c("el-image", {
              staticClass: "pic-error-parent",
              attrs: { src: require("@/assets/home/home_logo.png") },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "title" }, [_vm._v("反洗钱承诺书")]),
      ]),
    ]),
    _c("div", { staticClass: "main" }, [
      _c(
        "span",
        {
          staticClass: "blue_font",
          on: {
            click: function ($event) {
              return _vm.$router.push("/moneyCenter/expenseManagement/topUp")
            },
          },
        },
        [_vm._v("返回")]
      ),
      _vm._m(0),
      _c("h5", [_vm._v("本人（单位）郑重承诺：")]),
      _c("span", { staticClass: "first" }, [
        _vm._v(
          " 本人（单位）严格遵守《互联网金融从业机构反洗钱和反恐怖融资管理办法（试行），严格遵守《反洗钱法》等相关法律、行政法规和规章制度，依法进行商事金融活动，信守行业规则，恪守道德准则，切实履行反洗钱义务。并切实做到： "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 一、保证所用资金来源和用途合法，所支付给智安网络的款项非毒品犯罪、黑社会性质的组织犯罪、恐怖活动犯罪、走私犯罪、贪污贿赂犯罪、破坏 金融管理秩序犯罪、金融诈骗犯罪的所得及其产生的收益； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("二、保证不存在利用智安网络及其平台进行违法犯罪活动；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "三、积极参与、支持智安网络举办的反洗钱活动，坚决杜绝并检举洗钱活动；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 四、积极响应智安网络开展的反洗钱培训和宣传活动，关注和了解反洗钱知识，遵守反洗钱的法律法规，配合智安网络的反洗钱工作； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "五、接受并配合中国人民银行等相关单位对本人及智安网络的反洗钱监督、检查工作。"
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 本人（单位）如果违反以上承诺或发生违反反洗钱法等相关法律规范的行为，本人（单位）自愿按照法律法规承担所有责任，与智安网络无关。特此承诺！ "
        ),
      ]),
      _c("br"),
      _c("h5", [
        _vm._v("互联网金融从业机构反洗钱和反恐怖融资管理办法（试行）"),
      ]),
      _c("span", [
        _vm._v(
          "发文机关：中国人民银行,中国银行保险监督管理委员会,中国证券监督管理委员会"
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("发布日期：2018.10.10")]),
      _c("br"),
      _c("span", [_vm._v("生效日期：2019.01.01")]),
      _c("br"),
      _c("span", [_vm._v("时效性：现行有效")]),
      _c("br"),
      _c("span", [
        _vm._v("互联网金融从业机构反洗钱和反恐怖融资管理办法（试行）"),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 第一条 为了预防洗钱和恐怖融资活动，规范互联网金融行业反洗钱和反恐怖融资工作，根据《中华人民共和国中国人民银行法》、《中华人民共和国反洗钱法》、《中华人民共和国反恐怖主义法》、《国务院办公厅关于印发互联网金融风险专项整治工作实施方案的通知》（国办发〔2016〕21号）、《中国人民银行 工业和信息化部 公安部 财政部 工商总局 法制办 银监会 证监会 保监会 国家互联网信息办公室关于促进互联网金融健康发展的指导意见》（银发〔2015〕221号）等规定，制定本办法。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 第二条 本办法适用于在中华人民共和国境内经有权部门批准或者备案设立的，依法经营互联网金融业务的机构（以下简称从业机构）。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 互联网金融是利用互联网技术和信息通信技术实现资金融通、支付、投资及信息中介服务的新型金融业务模式。互联网金融业务反洗钱和反恐怖融资工作的具体范围由中国人民银行会同国务院有关金融监督管理机构按照法律规定和监管政策确定、调整并公布，包括但不限于网络支付、网络借贷、网络借贷信息中介、股权众筹融资、互联网基金销售、互联网保险、互联网信托和互联网消费金融等。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 金融机构和非银行支付机构开展互联网金融业务的，应当执行本办法的规定；中国人民银行、国务院有关金融监督管理机构另有规定的，从其规定。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 第三条 中国人民银行是国务院反洗钱行政主管部门，对从业机构依法履行反洗钱和反恐怖融资监督管理职责。国务院有关金融监督管理机构在职责范围内配合中国人民银行履行反洗钱和反恐怖融资监督管理职责。中国人民银行制定或者会同国务院有关金融监督管理机构制定从业机构履行反洗钱和反恐怖融资义务的规章制度。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 中国人民银行设立的中国反洗钱监测分析中心，负责从业机构大额交易和可疑交易报告的接收、分析和保存，并按照规定向中国人民银行报告分析结果，履行中国人民银行规定的其他职责。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 第四条 中国互联网金融协会按照中国人民银行、国务院有关金融监督管理机构关于从业机构履行反洗钱和反恐怖融资义务的规定，协调其他行业自律组织，制定并发布各类从业机构执行本办法所适用的行业规则；配合中国人民银行及其分支机构开展线上和线下反洗钱相关工作，开展洗钱和恐怖融资风险评估，发布风险评估报告和风险提示信息；组织推动各类从业机构制定并实施反洗钱和反恐怖融资方面的自律公约。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 其他行业自律组织按照中国人民银行、国务院有关金融监督管理机构的规定对从业机构提出建立健全反洗钱内控制度的要求，配合中国互联网金融协会推动从业机构之间的业务交流和信息共享。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 第五条 中国人民银行设立互联网金融反洗钱和反恐怖融资网络监测平台（以下简称网络监测平台），使用网络监测平台完善线上反洗钱监管机制、加强信息共享。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 中国互联网金融协会按照中国人民银行和国务院有关金融监督管理机构的要求建设、运行和维护网络监测平台，确保网络监测平台及相关信息、数据和资料的安全、保密、完整。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          "中国人民银行分支机构、中国反洗钱监测分析中心在职责范围内使用网络监测平台。"
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          "第六条 金融机构、非银行支付机构以外的其他从业机构应当通过网络监测平台进行反洗钱和反恐怖融资履职登记。"
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 金融机构和非银行支付机构根据反洗钱工作需要接入网络监测平台，参与基于该平台的工作信息交流、技术设施共享、风险评估等工作。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 第七条 从业机构应当遵循风险为本方法，根据法律法规和行业规则，建立健全反洗钱和反恐怖融资内部控制制度，强化反洗钱和反恐怖融资合规管理，完善相关风险管理机制。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 从业机构应当建立统一的反洗钱和反恐怖融资合规管理政策，对其境内外附属机构、分支机构、事业部的反洗钱和反恐怖融资工作实施统一管理。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 从业机构应当按规定方式向中国人民银行及其分支机构、国务院有关金融监督管理机构及其派出机构报备反洗钱和反恐怖融资内部控制制度。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 第八条 从业机构应当明确机构董事、高级管理层及部门管理人员的反洗钱和反恐怖融资职责。从业机构的负责人应当对反洗钱和反恐怖融资内部控制制度的有效实施负责。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 从业机构应当设立专门部门或者指定内设部门牵头负责反洗钱和反恐怖融资管理工作。各业务条线（部门）应当承担反洗钱和反恐怖融资工作的直接责任，并指定人员负责反洗钱和反恐怖融资工作。从业机构应当确保反洗钱和反恐怖融资管理部门及反洗钱和反恐怖融资工作人员具备有效履职所需的授权、资源和独立性。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 第九条 从业机构及其员工对依法履行反洗钱和反恐怖融资义务获得的客户身份资料和交易信息应当予以保密。非依法律规定，不得向任何单位和个人提供。从业机构及其员工应当对报告可疑交易、配合中国人民银行及其分支机构开展反洗钱调查等有关反洗钱和反恐怖融资工作信息予以保密，不得违反规定向任何单位和个人提供。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 第十条 从业机构应当勤勉尽责，执行客户身份识别制度，遵循“了解你的客户”原则，针对具有不同洗钱或者恐怖融资风险特征的客户、业务关系或者交易采取合理措施，了解建立业务关系的目的和意图，了解非自然人客户的受益所有人情况，了解自然人客户的交易是否为本人操作和交易的实际受益人。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 从业机构应当按照法律法规、规章、规范性文件和行业规则，收集必备要素信息，利用从可靠途径、以可靠方式获取的信息或数据，采取合理措施识别、核验客户真实身份，确定并适时调整客户风险等级。对于先前获得的客户身份资料存疑的，应当重新识别客户身份。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 从业机构应当采取持续的客户身份识别措施，审核客户身份资料和交易记录，及时更新客户身份识别相关的证明文件、数据和信息，确保客户正在进行的交易与从业机构所掌握的客户资料、客户业务、风险状况等匹配。对于高风险客户，从业机构应当采取合理措施了解其资金来源，提高审核频率。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 除本办法和行业规则规定的必备要素信息外，从业机构应当在法律法规、规章、规范性文件允许的范围内收集其他相关信息、数据和资料，合理运用技术手段和理论方法进行分析，核验客户真实身份。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 客户属于外国政要、国际组织的高级管理人员及其特定关系人的，从业机构应当采取更为严格的客户身份识别措施。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 从业机构不得为身份不明或者拒绝身份查验的客户提供服务或者与其进行交易，不得为客户开立匿名账户或者假名账户， 不得与明显具有非法目的的客户建立业务关系。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 第十一条 从业机构应当定期或者在业务模式、交易方式发生重大变化、拓展新的业务领域、洗钱和恐怖融资风险状况发生较大变化时，评估客户身份识别措施的有效性，并及时予以完善。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 第十二条 从业机构在与客户建立业务关系或者开展法律法规、规章、规范性文件和行业规则规定的特定类型交易时，应当履行以下客户身份识别程序： "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "（一）了解并采取合理措施获取客户与其建立业务关系或者进行交易的目的和意图。"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " （二）核对客户有效身份证件或者其他身份证明文件，或者按照法律法规、规章、规范性文件和行业规则要求客户提供资料并通过合法、安全、可信的渠道取得客户身份确认信息，识别客户、账户持有人及交易操作人员的身份。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " （三）按照法律法规、规章、规范性文件和行业规则通过合法、安全且信息来源独立的外部渠道验证客户、账户持有人及交易操作人员的身份信息，并确保外部渠道反馈的验证信息与被验证信息之间具有一致性和唯一对应性。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " （四）按照法律法规、规章、规范性文件和行业规则登记并保存客户、账户持有人及交易操作人员的身份基本信息。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " （五）按照法律法规、规章、规范性文件和行业规则保存客户有效身份证件或者其他身份证明文件的影印件或者复印件，或者渠道反馈的客户身份确认信息。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 第十三条 从业机构应当提示客户如实披露他人代办业务或者员工经办业务的情况，确认代理关系或者授权经办业务指令的真实性，并按照本办法第十二条的有关要求对代理人和业务经办人采取客户身份识别措施。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 第十四条 从业机构应当执行大额交易和可疑交易报告制度，制定报告操作规程，对本机构的大额交易和可疑交易报告工作做出统一要求。金融机构、非银行支付机构以外的其他从业机构应当由总部或者总部指定的一个机构通过网络监测平台提交全公司的大额交易和可疑交易报告。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 中国反洗钱监测分析中心发现从业机构报送的大额交易报告或者可疑交易报告内容要素不全或者存在错误的，可以向提交报告的从业机构发出补正通知，从业机构应当在接到补正通知之日起5个工作日内补正。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          "大额交易和可疑交易报告的要素内容、报告格式和填写要求等由中国人民银行另行规定。"
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 第十五条 从业机构应当建立健全大额交易和可疑交易监测系统，以客户为基本单位开展资金交易的监测分析，对客户及其所有业务、交易及其过程开展监测和分析。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 第十六条 客户当日单笔或者累计交易人民币5万元以上（含5万元）、外币等值1万美元以上（含1万美元）的现金收支，金融机构、非银行支付机构以外的从业机构应当在交易发生后的5个工作日内提交大额交易报告。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 中国人民银行根据需要调整大额交易报告标准。非银行支付机构提交大额交易报告的具体要求由中国人民银行另行规定。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 第十七条 从业机构发现或者有合理理由怀疑客户及其行为、客户的资金或者其他资产、客户的交易或者试图进行的交易与洗钱、恐怖融资等犯罪活动相关的，不论所涉资金金额或者资产价值大小，应当按本机构可疑交易报告内部操作规程确认为可疑交易后，及时提交可疑交易报告。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 第十八条 从业机构应当按照中国人民银行、国务院有关金融监督管理机构的要求和行业规则，建立交易监测标准和客户行为监测方案，定期或者在发生特定风险时评估交易监测标准和客户行为监测方案的有效性，并及时予以完善。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 从业机构应当按照法律法规、规章、规范性文件和行业规则，结合对相关联的客户、账户持有人、交易操作人员的身份识别情况，对通过交易监测标准筛选出的交易进行分析判断，记录分析过程；不作为可疑交易报告的，应当记录分析排除的合理理由；确认为可疑交易的，应当在可疑交易报告理由中完整记录对客户身份特征、交易特征或者行为特征的分析过程。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 第十九条 从业机构应当对下列恐怖组织和恐怖活动人员名单开展实时监测，有合理理由怀疑客户或者其交易对手、资金或者其他资产与名单相关的，应当立即提交可疑交易报告，并依法对相关资金或者其他资产采取冻结措施： "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "（一）中国政府发布的或者承认执行的恐怖活动组织及恐怖活动人员名单。"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "（二）联合国安理会决议中所列的恐怖活动组织及恐怖活动人员名单。"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " （三）中国人民银行及国务院有关金融监督管理机构要求关注的其他涉嫌恐怖活动的组织及人员名单。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 对于新发布或者新调整的名单，从业机构应当立即开展回溯性调查，按照本条第一款规定提交可疑交易报告。对于中国人民银行或者其他有权部门要求纳入反洗钱、反恐怖融资监控体系的名单，从业机构应当参照本办法相关规定执行。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "法律法规、规章和中国人民银行对上述名单的监控另有规定的，从其规定。"
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 第二十条 从业机构应当按照法律法规和行业规则规定的保存范围、保存期限、技术标准，妥善保存开展客户身份识别、交易监测分析、大额交易报告和可疑交易报告等反洗钱和反恐怖融资工作所产生的信息、数据和资料，确保能够完整重现每笔交易，确保相关工作可追溯。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          "从业机构终止业务活动时，应当按照相关行业主管部门及中国人民银行要求处理前款所述信息、数据和资料。"
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 第二十一条 从业机构应当依法接受中国人民银行及其分支机构的反洗钱和反恐怖融资的现场检查、非现场监管和反洗钱调查，按照中国人民银行及其分支机构的要求提供相关信息、数据和资料，对所提供的信息、数据和资料的真实性、准确性、完整性负责，不得拒绝、阻挠、逃避监督检查和反洗钱调查，不得谎报、隐匿、销毁相关信息、数据和资料。金融机构、非银行支付机构以外的其他从业机构通过网络监测平台向中国人民银行报送反洗钱和反恐怖融资报告、报表及相关信息、数据和资料从业机构应当依法配合国务院有关金融监督管理机构及其派出机构的监督管理。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 第二十二条 从业机构违反本办法的，由中国人民银行及其分支机构、国务院有关金融监督管理机构及其派出机构责令限期整改，依法予以处罚。从业机构违反相关法律、行政法规、规章以及本办法规定，涉嫌犯罪的，移送司法机关依法追究刑事责任。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("第二十三条 本办法相关用语含义如下")]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 中国人民银行分支机构，包括中国人民银行上海总部、分行、营业管理部、省会（首府）城市中心支行、副省级城市中心支行。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 金融机构是指依法设立的从事金融业务的政策性银行、商业银行、农村合作银行、农村信用社、村镇银行、证券公司、期货公司、基金管理公司、保险公司、保险资产管理公司、保险专业代理公司、保险经纪公司、信托公司、金融资产管理公司、企业集团财务公司、金融租赁公司、汽车金融公司、消费金融公司、货币经纪公司、贷款公司以及中国人民银行确定并公布的从事金融业务的其他机构。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 非银行支付机构是指依法取得《支付业务许可证》，获准办理互联网支付、移动电话支付、固定电话支付、数字电视支付等网络支付业务的非银行机构。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 行业规则是指由中国互联网金融协会协调其他行业自律组织，根据风险防控需要和业务发展状况，组织从业机构制定或调整，报中国人民银行、国务院有关金融监督管理机构批准后公布施行的反洗钱和反恐怖融资工作规则及相关业务、技术标准。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          "第二十四条 本办法由中国人民银行会同国务院有关金融监督管理机构负责解释。"
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("第二十五条 本办法自2019年1月1日起施行。")]),
      _c("br"),
    ]),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("h3", [_vm._v("反洗钱承诺书")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ignore_center" }, [
      _c("span", [
        _vm._v(
          " Copyright©2023 深圳市智安网络有限公司 All Rights Reve 版权所有 《中华人民共和国增值电信业务经营许可证》编号：B1-20190666 "
        ),
        _c(
          "a",
          {
            staticClass: "blue_font",
            attrs: {
              href: "https://beian.miit.gov.cn/#/Integrated/index",
              target: "_blank",
            },
          },
          [_vm._v("粤ICP备18027011号")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }