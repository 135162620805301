var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c("navbar"),
      _c("div", { staticClass: "mainBody" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [_c("userInfo"), _c("moneyInfo")],
              1
            ),
            _c("agentInfo"),
            _c("myResources"),
            _c("product"),
            _c("systemLog"),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }