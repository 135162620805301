<template>
    <div>
        <div>
            <za-go-back title="开票记录" @click="goBack"></za-go-back>
        </div>
        <div class="info-container" v-za-loading="isLoading">
            <za-query-form>
                <za-query-form-left-panel :span=4>
                    <div class="success">
                        <span>已成功开具{{invoicesTotal}}张发票</span>
                        <span>（合计 {{invoicesAmount}}元）</span>
                    </div>
                </za-query-form-left-panel>
                <za-query-form-right-panel :span="20">
                    <el-form :inline="true" :model="pageObj" @submit.native.prevent>
                        <el-form-item label="申请日期:" label-width="100px">
                            <el-date-picker
                                    v-model="timerSelect"
                                    type="date"
                                    @change="datePickEnd"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="发票号：" label-width="100px">
                            <el-input v-model="pageObj.invoicesNumber"
                                      :placeholder="translateTitle('placeTxt') + translateTitle('askfor.invoice')">
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <za-button :icon="buttonIconObj.SEARCH" @click="handleQuery">{{ translateTitle('btn.query')
                                }}
                            </za-button>
                        </el-form-item>
                    </el-form>
                </za-query-form-right-panel>
            </za-query-form>
            <occ-table :tableData="tableData" :columObj="columObj" :isOp="columObj.isOp" @select="result">
                <template #default="{row}">
                    <span class="blue_font" @click="details(row)">详情</span>
                    <span class="blue_font" @click="refund(row)">退票</span>
                    <span class="blue_font" @click="revocation(row)">撤销申请</span>
                </template>
            </occ-table>
            <pagination
                    :limit.sync="pageObj.pageSize"
                    :page.sync="pageObj.pageNo"
                    :total="total"
                    @pagination="getList"
            />
        </div>
        <div>
            <za-dialog top-title="发票详情"
                       :visible.sync="detailsInfo"
                       width="600px"
                       :footState="false"
            >
                <div>
                    <el-form
                            ref="form"
                            :disabled="confirmBtnLoading"
                            :model="baseForm"
                            label-position="right"
                            label-width="140px"
                    >
                        <el-row>
                            <el-col :span="12">
                                <el-form-item :label="'发票抬头'+ '：'">
                                    <span class="tips">{{baseForm.invoicesHead||'暂无'}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="'发票金额'+ '：'">
                                    <span class="tips">{{baseForm.invoicesAmount||'暂无'}}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item :label="'发票类型'+ '：'">
                                    <span class="tips">{{baseForm.invoicesType||'暂无'}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="'发票介质'+ '：'">
                                    <span class="tips">{{baseForm.medium||'暂无'}}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item :label="'发票号码' + '：'">
                                    <span class="tips">{{baseForm.invoicesNumber||'暂无'}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="'发票状态' + '：'">
                                    <span class="tips">{{baseForm.invoicesState||'暂无'}}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item :label="'邮寄信息' + '：'">
                                    <span class="tips">{{baseForm.addressInfo||'暂无'}}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </za-dialog>
            <za-dialog
                    top-title="退票申请"
                    :visible.sync="dialogVisible"
                    width="600px">
                <div>
                    <div class="prompt">
                        <span style="margin-left: 16px">
                             <za-svg-icon icon-class="za-wanning"></za-svg-icon>
                        </span>
                        <span>温尊提示: 此操作一旦提交后无法撤撤回且需要您承担邮寄费用，请谨慎操作</span>
                    </div>
                    <el-form
                            ref="form"
                            :disabled="confirmBtnLoading"
                            :model="returnForm"
                            label-position="right"
                            label-width="140px"
                            :rules="rules"
                    >
                        <el-row>
                            <el-col :span="24">
                                <el-form-item :label="'退票金额'+ '：'">
                                    <span class="refundMoney">{{returnForm.money+'元'||''}}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-form-item :label="'退票原因'+ '：'" prop="returnType">
                                    <el-select v-model="returnForm.returnType">
                                        <el-option v-for="item in serverMsgTypeOption" :key="item.id" :label="item.name"
                                                   :value="item.id"/>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-form-item :label="'材料要求'+ '：'" prop="mobile">
                                    <div class="requirement">
                                        <div>
                                            <span>请将 <span style="font-size: 14px;font-weight: 500">发票原件</span> 邮寄给</span>
                                        </div>
                                        <div class="requireMain">
                                            <div>
                                                <span>收件人：</span>
                                                <span>王兰</span>
                                            </div>
                                            <div>
                                                <span>电话：</span>
                                                <span>010-62671188-839840</span>
                                                <div>该联系方式仅供寄件使用，如需业务咨询，请拨打 4009100100</div>
                                            </div>
                                            <div>
                                                <span>地址：</span>
                                                <span>北京市海淀区知春路49号 希格玛大厦B1 腾讯邮件中心</span>
                                            </div>
                                        </div>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                                <el-form-item :label="'退票快递公司' + '：'" required>
                                    <el-row>
                                        <el-col :span="11">
                                            <el-form-item prop="textarea">
                                                <el-input
                                                        placeholder="请填写快递公司"
                                                        v-model="returnForm.textarea">
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="2">&nbsp;</el-col>
                                        <el-col :span="11">
                                            <el-form-item prop="textarea">
                                                <el-input
                                                        placeholder="请填写寄出的快递单号"
                                                        v-model="returnForm.textarea">
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-form-item>

                        <el-row>
                            <el-col :span="24">
                                <el-form-item :label="'发票号码' + '：'" prop="desc">
                                    <el-input
                                            type="textarea"
                                            :rows="4"
                                            placeholder="请输入内容"
                                            v-model="returnForm.desc">
                                    </el-input>

                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <div slot="footer">
                    <za-button :disabled="confirmBtnLoading" :type="buttonTypeObj.GREY" @click="dialogVisible = false">
                        {{ translateTitle('btn.cancel') }}
                    </za-button>
                    <za-button :loading="confirmBtnLoading" :type="buttonTypeObj.BLUE" @click="handleSubmitForm">
                        {{ translateTitle('btn.submit') }}
                    </za-button>
                </div>
            </za-dialog>
        </div>
    </div>
</template>
<script>
    import {apiReturnList, apiInvoicesList,apiCancel} from "@/api/moneyCenter/invoiceManagement/askfor"
    import {reqHistoryInfoObj} from '@/za/model/reqModel'
    export default {
        data() {
            return {
                serverMsgTypeOption: [
                    {id: 1, name: '发票类型错误'},
                    {id: 2, name: '退货退票'},
                    {id: 3, name: '税号，开户行错误'},
                    {id: 4, name: '主体工商名称变更'},
                    {id: 5, name: '发票金额有误'},
                ],
                baseForm:Object.assign({}, reqHistoryInfoObj),
                returnForm:{
                    money:'',
                    returnType:null,
                    invoicesId:'',
                    desc:''
                },
                rules: {
                    returnType:[ {required: true, message: '请选择退票原因', trigger: 'change'}],
                    desc:[{required: true, message: '请输入详细退票原因', trigger: 'blur'}],
                    textarea:[{required: true, message: '请输入详细退票原因', trigger: 'blur'}],
                },
                detailsInfo: false,
                timerSelect: '',
                title: '',
                total: 0,
                pageObj: {
                    pageNo: 1,
                    pageSize: 10,
                    startAt: 0,
                    invoicesNumber: '',
                    invoicesType: [],
                    invoicesState: []
                },
                tableData: [],
                columObj: {
                    // 控制选择框
                    selection: false,
                    isOp: true,
                    width: '300px',
                    columnData: [
                        {
                            text: true,
                            prop: "invoicesAt",
                            label: "申请开票时间",
                            width: "",
                            flag: true,
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "invoicesHead",
                            label: "发票抬头",
                            flag: true,
                            width: "",
                            align: "center",

                        },
                        {
                            text: true,
                            prop: "invoicesType",
                            label: "发票类型",
                            flag: true,
                            width: "",
                            align: "center",
                            filters: [
                                {text: '个人增值税发票  ', value: 1},
                                {text: '组织增值税发票  ', value: 2},
                                {text: '企业增值税普票', value: 3},
                                {text: '企业增值税专票', value: 4},
                            ],
                        },
                        {
                            text: true,
                            prop: "medium",
                            label: "发票介质",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "invoicesNumber",
                            label: "发票号码",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "invoicesAmount",
                            label: "发票金额",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "invoicesState",
                            label: "状态",
                            flag: true,
                            width: "",
                            align: "center",
                            filters: [
                                {text: '待处理  ', value: 1},
                                {text: '开票中', value: 2},
                                {text: '已开票', value: 3},
                                {text: '已邮寄  ', value: 4},
                                {text: '已取消', value: 5},
                                {text: '退票待处理', value: 6},
                                {text: '退票中  ', value: 7},
                                {text: '退票驳回', value: 8},
                                {text: '已作废', value: 9},
                                {text: '开票驳回', value: 10},
                                {text: '开票失败', value: 11},
                            ],
                        },
                    ],
                },
                invoicesTotal:'',
                invoicesAmount:''
            }
        },
        created() {
            this.title = this.$route.meta.title
            this.getList()
        },
        methods: {
            goBack() {
                this.$router.push('/moneyCenter/invoiceManagement/askfor')
            },
            getList() {
                this.onSubmitData(apiInvoicesList, this.pageObj, null, false, false, (res) => {
                    this.total = res.data.total
                    this.invoicesTotal=res.data.invoicesTotal
                    this.invoicesAmount=res.data.invoicesAmount
                    if (res.data.list && res.data.list.length) {
                        this.tableData = res.data.list
                    } else {
                        this.tableData = []
                    }
                })
            },
            datePickEnd(date) {
                if (date) {
                    this.pageObj.startAt = this.$changeTimeStamp(date)
                } else {
                    this.pageObj.startAt = 0
                }
                this.getList()
            },
            details(row) {
                this.baseForm=row
                this.detailsInfo = true
            },
            refund(row) {
                this.returnForm.money=row.invoicesAmount
                this.returnForm.invoicesId=row.id
                this.dialogVisible = true
            },
            handleSubmitForm() {
                this.$refs['form'].validate(async (valid) => {
                    if (valid) {
                        let res = await apiReturnList(this.returnForm).finally(() => (this.confirmBtnLoading = false))
                        if (res.code === 0) {
                            // this.$msgSuccess('退票成功')
                            this.dialogVisible = false;
                            this.getList()
                        }
                    } else {
                        return false
                    }
                })
            },
            revocation(row){
                this.$baseConfirm('您确认撤销开票申请吗？', null, () => {
                    const data = { invoicesId:row.id}
                    this.onDelete(apiCancel, data, '撤销开票成功')
                })
            },
            result(val) {
                //判断是发票类型下拉还是状态下拉
                if (val.invoicesType){
                    this.pageObj.invoicesType = val.invoicesType
                    this.getList()
                } else if (val.invoicesState) {
                    this.pageObj.invoicesState = val.invoicesState
                    this.getList()
                }

            },
            handleQuery() {
                this.getList()
            }
        }
    }
</script>
<style lang="scss" scoped>
    .title {
        height: 62px;
        background: #FFFFFF;
        border-radius: 2px;
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        padding: 20px;
        margin-bottom: 20px;
    }

    .success {
        font-size: 14px;
        font-weight: 400;
        color: #000000;

        span:last-child {

            color: #3370FF;
        }
    }

    .blue_font {
        margin-right: 10px;
    }

    .infoKey {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        margin-bottom: 24px;

        span:last-child {
            font-weight: 400;
            color: #555555;
        }
    }

    .info-container {
        padding: $base-main-app-padding;
        background: rgba(255, 255, 255, 1);
    }
    .prompt {
        height: 40px;
        background: #FFFBE6;
        border-radius: 2px;
        border: 1px solid #FFE58F;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 40px;
        margin-bottom: 20px;

    }
    .requirement {
        font-size: 12px;
        color: #000000;
        .requireMain{
            background: #c2c2c2;
            padding: 10px;
        }
    }

    .refundMoney {
        color: red;
        font-size: 12px;
    }

</style>