<template>
  <div v-show="isShow" class="sidebar">
<!--    <za-bg-frame>-->
    <div class="bg-frame">
      <div class="topName">
        {{topName}}
      </div>
      <el-scrollbar class="scroll">
        <div class="container">
          <!--key绑定路径，避免无关bug-->
          <item-menu v-for="item in handleRoutes" :key="item.path" :item="item"/>
        </div>
      </el-scrollbar>
    </div>

<!--    </za-bg-frame>-->
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import ZaBgFrame from '@/za/components/zaBgFrame'
import ItemMenu from '@/za/layout/components/Sidebar/components/ItemMenu'

export default {
  name: 'Sidebar',
  components: {ItemMenu, ZaBgFrame},
  data() {
    return {
      isShow: true,
      topName:''
    }
  },
  computed: {
    ...mapGetters({
      routes: 'routes/routes',
    }),
    handleRoutes() {
      const newRoute = []
      const rootPathName = this.$route.fullPath.toLowerCase().split('/').splice(1, 1)[0]
      for (let i = 0; i < this.routes.length; i++) {
        if (this.routes[i].name && this.routes[i].name.toLowerCase() === rootPathName && this.routes[i].children) {
          // console.log(this.routes[i],'----')
          this.topName=this.routes[i].meta.title
          newRoute.push(
            ...this.routes[i].children.filter((route) => {
              return !route.meta.hidden
            })
          )
          break
        }
      }
      if (newRoute.length === 0) this.changeShow(false)
      else this.changeShow(true)
      return newRoute
    },
  },
  methods: {
    changeShow(isShow) {
      this.isShow = isShow
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/za/styles/mixin.scss';

::v-deep {
  .el-scrollbar__wrap {
    overflow-x: hidden !important;
  }
}
.bg-frame {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: rgba(255, 255, 255, 1);
}
.topName{
  height: 22px;
  padding: 20px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
}
.sidebar {
  width: $base-left-menu-width;
  min-width: $base-left-menu-width;

  .scroll {
    width: 100%;
    height: 91%;
    position: absolute;
    padding: 16px 15px 16px 0;

    ::-webkit-scrollbar {
      display: none !important;
    }

    .container {
      @include flex_div($dir: column, $padding: 0);
    }
  }
}
</style>
