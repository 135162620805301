import request from '@/utils/request'
import {GET_POPULARIZE_MATTER_LIST, GET_POPULARIZE_TYPE_LIST, METHOD} from '@/api/api.config'

// 获取推广素材类型
export function apiGetPopularizeTypeList(data) {
  return request({
    url: GET_POPULARIZE_TYPE_LIST,
    method: METHOD.GET,
    data,
  })
}

//获取推广素材接口
export function apiPopularizeMatterList(data) {
  return request({
    url: GET_POPULARIZE_MATTER_LIST,
    method: METHOD.GET,
    params: data,
  })
}
