<template>
    <div class="cardRoll" v-za-loading="isLoading">
        <div class="title">卡券管理</div>
        <div class="card_mottom">
            <el-row>
                <el-col :span="12">
                    <el-card shadow="always" class="cardUl" @click.native="$router.push('/moneyCenter/cardRoll/voucher')">
                        <div class="title">代金券</div>
                        <div class="operation">
                            <div>
                                <span>{{cashCouponNum}}</span>
                                <span>张</span>
                            </div>
                        </div>

                    </el-card>
                </el-col>
                <el-col :span="12">
                    <el-card shadow="always" class="cardUl" @click.native="$router.push('/moneyCenter/cardRoll/coupon')">
                        <div class="title">折扣券</div>
                        <div class="operation">
                            <div>
                                <span>{{couponNum}}</span>
                                <span>张</span>
                            </div>

                        </div>
                    </el-card>
                </el-col>
            </el-row>

        </div>
    </div>
</template>
<script>
    import {apiCouponNum} from '@/api/moneyCenter/overView'

    export default {
        data() {
            return {
                cashCouponNum: null,
                couponNum: null
            }
        },
        created() {
            this.getList()
        },
        methods: {
            getList() {
                this.onSubmitData(apiCouponNum, null, null, false, false, (res) => {
                    this.cashCouponNum=res.data.cashCouponNum
                    this.couponNum=res.data.couponNum
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '~@/za/styles/mixin.scss';

    .cardRoll {
        height: 160px;
        width: 50%;
        background: #FFFFFF;
        margin-left: 16px;
        padding: 20px;

        .title {
            @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
            width: 100%;
            height: 20px;
            margin-bottom: 14px;
        }

        .card_mottom {
            .cardUl {
                height: 90px;
                margin-right: 20px;
                padding-left:  15px;
                cursor: pointer;
                .title {
                    font-size: 12px;
                    width: 100%;
                }

                .operation {
                    display: flex;
                    justify-content: space-between;

                    div:first-child {
                        span:first-child {
                            font-size: 24px;
                            font-family: DINAlternate-Bold, DINAlternate;
                            font-weight: bold;
                            color: #000000;
                        }
                    }
                }

            }
        }
    }

    ::v-deep {
        .el-card__body {
            padding: 10px;
        }
    }
</style>