var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isShow,
          expression: "isShow",
        },
      ],
      staticClass: "sidebar",
    },
    [
      _c(
        "div",
        { staticClass: "bg-frame" },
        [
          _c("div", { staticClass: "topName" }, [
            _vm._v(" " + _vm._s(_vm.topName) + " "),
          ]),
          _c("el-scrollbar", { staticClass: "scroll" }, [
            _c(
              "div",
              { staticClass: "container" },
              _vm._l(_vm.handleRoutes, function (item) {
                return _c("item-menu", {
                  key: item.path,
                  attrs: { item: item },
                })
              }),
              1
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }