var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c("div", { staticClass: "heardBlock" }, [
        _c(
          "div",
          { staticClass: "ignore_center", on: { click: _vm.officialWeb } },
          [
            _c("el-image", {
              staticClass: "pic-error-parent",
              attrs: { src: require("@/assets/home/home_logo.png") },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "title" }, [_vm._v("智安云线上订购协议")]),
      ]),
    ]),
    _vm._m(0),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "main" }, [
      _c("div", { staticClass: "title" }, [
        _c("h3", [_vm._v("智安云线上订购协议")]),
      ]),
      _c("span", { staticClass: "first" }, [
        _vm._v(
          " 本智安云线上订购协议是智安云官网（zhiancloud.com和zhiannet.com）的经营者深圳市智安网络有限公司（以下简称为“智安云”），与您或您所代表之实体（“您”或“您的”）共同缔结的对双方具有约束力的有效合约。您通过网络页面勾选、点击确认、进行下一步操作或以其他方式选择接受本协议，或使用智安云产品服务时（以较早发生者为准），即表示您与智安云已达成协议并同意接受本协议的全部约定内容。 如本协议的内容发生重大变动，智安云将提前通过网站页面、站内信、邮件或其他智安云认为有效的方式通知您。如您不同意相关条款的修改，您有权停止使用智安云的服务。如您继续使用服务，则视为您接受修改。 您接受本协议将视为您已经仔细阅读并理解本协议的全部内容。关于本协议，提示您特别关注限制或免除责任的条款，以及开通或使用特定产品服务时针对该等产品服务的产品服务规则。如果您不同意本协议的任何内容或者无法准确理解本协议相关条款，您可以先行向智安云寻求帮助，但请不要进行后续操作。您使用本协议项下任一产品服务即视为您已阅读并同意本协议的约束。届时您无权以未阅读本协议的内容或者未获得服务方对您问询的解答等理由，主张本协议无效，或要求撤销本协议。 "
        ),
      ]),
      _c("br"),
      _c("br"),
      _c("h5", [_vm._v("一、定义")]),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 1.“本协议”包括本智安云线上订购协议正文、订单、智安云官网（zhiancloud.com和zhiannet.com）所示《智安云用户服务协议》，以及产品服务规则。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 2.“产品服务”系指智安云基于本协议提供的各项产品及其相关服务，也称“智安云产品服务”，具体信息请参见智安云官网及相关产品服务规则。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 3.“产品服务规则”系指智安云通过智安云官网管理控制台或任何其他方式公布并可根据业务需要随时更新的，与智安云产品服务相关的任何文档、协议、规则、条款等，包括但不限于产品表述、产品定价、操作指南、产品服务等级协议（SLA）、赔偿标准等。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("4.“工作日”系指中国大陆法定工作日。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 5.“订单”系指您在智安云官网上确认购买产品服务后系统所自动生成的电子凭证，该凭证载有您购买的产品服务名称、服务时间、配置、金额等字段。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("6.“订阅期限”系指订单中显示的您付费订购相关产品服务的期限。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 7.“智安云官网” 系指智安云可不时更新的zhiancloud.com和zhiannet.com网站（以及智安云指定的任何后续或相关网址）。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "8.“建议”系指您向智安云或智安云的关联方对产品服务提出任何改进建议或反馈。"
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("二、声明、陈述与保证")]),
      _c("span", { staticClass: "first" }, [
        _vm._v("您和智安云分别向对方声明、陈述和保证："),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 1.任何一方有权签署本协议并已经获得履行本协议所需要的全部资质、许可和授权（例如：您的网站是提供非经营性互联网信息服务的，您必须办理非经营性ICP备案并保证所提交的所有备案信息真实有效，在备案信息发生变化时及时在备案系统中提交更新信息）；双方保证将签署或获得一切必需的文件或许可，并采取一切必要的行动以使本协议约定的条款顺利履行。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 2.除本协议另有规定外，双方签署的本协议符合现行有效的法律法规或对业务运作有重大影响的任何法院、管理机关或政府组织的任何判决、裁定或规定，本协议亦不损害任何第三方的合法权益。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 3.在本协议期限内，任何一方若与第三方发生合并、并购、主体变更等事件，任何一方的承继主体应继续履行本协议项下该方未完成的义务。 "
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("三、您的权利义务")]),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 1.您可在法律允许的范围内，使用智安云提供的服务；您确保在接受本协议时已明确了解智安云所提供服务的内容并已知悉智安云官网关于该技术服务的详情介绍、产品服务等级协议（SLA）、使用方法等全部产品服务规则。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 2.您可以在订购期限内享受智安云按照现有技术和条件标准提供的服务，智安云应按照本协议条款和服务标准尽商业上的合理努力为您提供连贯而安全的服务，具体服务标准参见智安云官网公布的产品服务等级协议（SLA）及其他产品服务规则。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("3.订购期限内，您可以享受智安云提供的如下产品服务："),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "(1) 智安云为您提供7×24小时的在线官方客服服务，解答您在使用中的问题。"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " (2) 智安云将于您联系智安云后24小时内消除您非人为操作所出现的故障并恢复产品服务的正常使用，但因您的原因和/或不可抗力以及非智安云控制范围之内的事项除外。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 4.您应当确保订单信息填写准确无误以便智安云进行计费统计。除您有确切证据证明智安云数据错误外，计费统计以智安云为准。因您订单信息填写错误而导致的计费统计错误，智安云不承担责任，且无需退还您已支付的服务款项。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 5.您应当按照本协议约定支付服务款项。如未按照本协议支付，智安云有权利单方面拒绝或终止向您提供产品服务，同时保留追讨欠款的法律权利。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("6.您不应在使用产品服务时安装、使用盗版软件。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 7.您对自己使用产品服务时存放的数据以及进入和管理智安云各类产品服务的口令、密码等信息的完整性和保密性负责；您账户下发生的行为将全部视为您的行为，无论该等行为是否取得您的授权，或由您、您的员工或第三方（包括您的承包商或代理商）实施，您应对您账户下的所有行为或活动负责，智安云及其关联方不对您的账户遭到未授权访问承担责任。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 8.您对自己使用产品服务时存放的数据、信息内容负责。如因您帐户中上传、发布的公开信息违反法律法规、部门规章或国家政策，智安云有权直接更改或删除上述内容同时有权停止为您提供产品服务。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 9.出于行业技术水平的客观限制，智安云无法保证其所提供的产品服务毫无瑕疵。您同意，即使智安云提供的产品服务存在瑕疵，但上述瑕疵是当时行业技术水平所无法避免的，其将不被视为智安云违约。您同意和智安云一同合作解决上述瑕疵问题。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 10.您同意，数据备份系您的义务和责任。智安云没有义务完全备份您的数据或信息，亦不对您数据备份工作或结果承担任何责任。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 11.您在使用智安云产品服务时应当遵守《网络安全法》、《电信条例》等法律法规及相关国家政策，维护互联网秩序和安全，不得从事违法违规行为也不得为上述行为提供便利。上述违法违规行为包括但不限于： "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "(1) 实施涉嫌博彩、赌博、“私服”、“外挂”等非法互联网活动或其他欺诈、误导行为；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " (2) 发布违法、违规、虚假信息或未经接收方许可进行商业短信、邮件发送或其他骚扰式营销行为； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "(3) 实施侵害智安云或第三方肖像权、名誉权、隐私权、知识产权等任何合法权益的行为；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " (4) 以过度负荷或其他任何方式导致网络中断，或实施未经授权的截取、盗用、干扰或监测等违法行为； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " (5) 实施任何破坏或试图破坏网络安全的行为，包括但不限于以病毒、木马、恶意代码、钓鱼等方式，对网站、服务器进行恶意扫描、非法侵入系统、非法获取数据等； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "(6) 实施任何改变或试图改变产品服务提供的系统配置或破坏系统安全的行为；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "(7) 利用技术或其他手段破坏、扰乱智安云的运营或影响他人对产品或服务的使用；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " (8) 利用任何技术手段或以任何方式对产品服务和相关数据进行破译、破解等行为，刺探或试图获取智安云用户个人信息或相关用户信息； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "(9) 利用智安云提供的互联网资源经营任何您不具备资质的互联网业务；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("(10) 其它可能给智安云造成不良影响或损失的行为。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 12.为实现本协议目的，您可能需提供您或其他第三方的相关信息。您应当保证收集和提供上述信息的行为已遵循合法、正当、必要的原则，已明示收集、使用信息的目的、方式和范围且已获得对应自然人的明确授权。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 13.智安云将通过合理努力追求数据分析、数据验证等产品服务的结果准确性，但不对所提供分析结果的准确性或特定用途适合性作出保证。智安云所提供的分值、分级等分析结果仅可作为参考，无法完成替代您进行商业决策的制定。如您依据智安云分析结果进行商业决策而遭受任何损失，智安云完全免责。您对所获得的所有数据分析和验证结果应当履行保密义务，不得向第三方提供或售卖数据分析或验证结果。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 14.未经智安云书面许可，您不得将智安云所提供的产品服务（包括但不限于程序、接口、软件、源代码、工具、文档）转售、转租或提供给其他任何第三方使用。 "
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("四、智安云的权利义务")]),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 1.智安云应当为您提供订单页面中所展示的产品服务，订单页面所显示全部内容均为本协议的组成部分，与本协议具有相同法律效力。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 2.智安云有权按照智安云资费标准，向您收取服务费用，具体服务的资费标准以订单页面展示为准。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 3.智安云开票事宜以智安云官网公布的信息为准。智安云有权要求您提供开具发票所需的各项资质证明。如因您的资质未能提供或提供迟延导致发票迟延交付的，智安云不承担责任。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 4.智安云有权对您使用产品服务的情况进行监督和独立判断，如经由内部审核、通知、举报等途径发现您在使用智安云所提供的产品服务时违反任何本协议的规定，智安云有权通知并要求您改正，或直接采取一切智安云认为必要的措施（包括但不限于更改或删除上载的内容、暂停或终止您使用网络服务的权利、终止向您提供部分或全部产品服务等），以减轻您不当行为给智安云造成的影响。您应当在接到智安云通知后_3_个工作日内更正违反本协议约定的使用行为，如逾期仍未更正的，智安云有权停止为您提供产品服务。由此造成的损失，您应当自行承担。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 5.依据《非经营性互联网备案管理办法》第二十三条规定，如备案信息不真实，将关闭网站并注销备案。请您承诺并确认：您提交的所有备案信息真实有效，当您的备案信息发生变化时请及时到备案系统中提交更新信息，如因未及时更新而导致备案信息不准确，智安云有权依法对接入网站进行关闭处理。 "
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("五、结算方式")]),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("1.预付费结算")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " (1)若您购买的为先付费后使用的服务，智安云将在您支付全部服务费后为您提供服务；若您未及时支付，下单后7天订单自动失效；若到期后您希望继续使用该服务，需提前支付续费款项延续服务。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " (2) 您使用智安云产品服务前需预充值，购买服务时智安云从您账户中扣除服务款项，如您账户中金额不足以支付服务款项，智安云保留拒绝为您提供服务的权利。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("2.后付费结算")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " (1)若您购买以按量或者按次等付费形式的服务，您可先开通服务，智安云将根据服务类型的不同，以小时、天或者月等周期自动计算上一个计费周期的使用量，并从您的智安云账户余额中扣除相应的服务费用，具体扣费规则以及计费项以智安云官网页面公布为准。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 3.智安云为您提供余额不足提醒服务，具体提醒服务及处理方式详见智安云官网。您因智安云账户余额不足而服务停止，以及由此产生的相关损失，智安云不承担任何责任。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "4.智安云官网会不定期推出优惠活动，活动内容及使用发放均以智安云官网公布为准。"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 充值：您可通过支付宝、微信、网上银行或银行汇款等途径将服务费充值到您智安云的账户中，支付方式及其他具体规则以智安云官网展示为准。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 6.发票申请：您可向智安云申请开具发票。具体申请要求、发票内容、开票金额及开票流程详见智安云官网。 "
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("六、保密义务")]),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 1.您与智安云郑重承诺，除本协议第六条第5款约定的情形外，一方不会将在合作过程中了解到的有关对方的保密资料，以任何形式透露给第三方，并严格限制接触保密资料的员工遵守本条之保密义务； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 2.双方同意尽最大的努力保护保密资料等不被披露。一旦发现有保密资料泄露事件，双方应合作采取一切合理措施避免或者减轻损害后果的产生。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("3.上述保密义务不因本协议的变更、解除或终止而终止。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("4.本协议项下的保密资料是指："),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " (1) 与项目有关的任何协议、往来传真或邮件等纸质或电子版材料，包括本协议的内容和条款（特别是智安云为您提供的折扣或优惠信息）； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " (2) 价格、财务及营销规划、客户资料、未发布产品、商业计划、行销信息、投资信息、财务状况、图纸、设计思想、技术诀窍、计算机程序、源代码、研究方案及其他资料； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "(3) 在披露之时或之前，披露方以文字或标识指定为商业秘密的信息及文件；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("(4) 其它符合法定商业秘密构成要件的信息。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("5. 本条款不适用于以下内容："),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("(1) 已进入公众领域的信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "(2) 信息接收方通过正当方式取得的、非保密性的、同行业普遍了解的信息；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("(3) 信息接收方在信息披露前已知的、且无义务保密的信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "(4) 由第三方披露的且信息接收方经最审慎判断认为无须保密的信息；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("(5) 信息接收方独立开发的信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("(6) 信息披露方以书面形式同意信息接收方披露的保密信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "（7）应第三方招投标要求，将本协议本身提交第三方并说明仅限投标使用的；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "（8）为提供您所要求的服务，智安云在合理和必要的程度内向第三方分享您的数据；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("(9) 按法律要求需向任何机关、机构公开的内容。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 6. 智安云理解并认可，您通过智安云所提供服务进行处理的数据，均为您的用户数据，您完全拥有您的用户数据。智安云无权访问用户数据，除非为用户提供技术协助进行故障排除或解决技术问题。 "
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("七、知识产权")]),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 1.非因本协议而产生的知识产权，归属依法已经拥有该权利的一方所有；本协议中所涉及产品服务及其相关的程序、接口、软件、源代码、工具、文档等（无论是否为您定制化开发），其知识产权由智安云所有，您仅享有订购期间内的使用权。除非另有约定，任何一方均不可凭借本协议取得另一方所拥有的著作权、专利权、商标权或任何其他知识产权。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 2.您不得部分或全部地出租、出借、拷贝、仿冒、复制或修改智安云所提供的产品、服务或技术，或用于其他任何商业目的，也不得对该等产品、服务或技术做反向工程、反编译或反汇编，或以其他方式或工具取得智安云产品、服务或技术的目标程序或源代码。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 3.若您提出任何建议，智安云及智安云的关联方有权不受限制地使用该等建议。您在此不可撤销地向智安云让与对该等建议的所有权利、所有权和权益，并同意向智安云提供智安云为证明、完善和保有智安云对该等建议的权利需要的任何协助。 "
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("八、违约、赔偿责任与免责")]),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("1.本协议任何一方违约均须依法承担违约责任。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 2.如您严重违反本协议约定，智安云有权提前终止本协议，并不退还您已支付的服务款项；上述提前终止自智安云向您发出的通知送达时生效。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 3.对于因履行本协议所导致，或与其相关或因其发生的任何损失、指控、处罚、主张、判决或费用，尤其是因您违反本协议约定而造成第三方的损失(包括诉讼费、律师费用和第三方费用等)，您应确保智安云及其雇员、代理、管理层、或其关联公司免于承担因以上损失造成的任何责任，并赔偿智安云因此向该第三方支付的损害赔偿。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 4.您应遵守相关的规则对享受的折扣优惠内容予以保密（如有），否则，智安云有权随时采取以下一项或多项措施： "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("（1）单方暂时中止、终止向您提供部分或全部优惠。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("（2）要求您补足已经享受的免费或优惠的产品服务相对应的费用。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 5.如果确因智安云原因造成您不能正常使用智安云服务的，智安云按照服务赔偿标准向您赔偿相应损失，具体服务赔偿标准参见智安云官网公示。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 6.责任免除 您同意，在法律允许的范围内，下述情况不属于智安云违约，智安云不承担任何责任： "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("(1) 您由于下述原因无法正常使用产品服务的情况："),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("①您遭到黑客攻击，导致数据丢失，服务中断；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("②您由于自身系统、网络配置变更导致数据丢失，服务中断；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("③您由于自身程序bug导致的客户服务不可正常使用；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("④其他由于非智安云原因导致的服务异常情况。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "(2) 智安云在进行服务器配置、维护时，需要短时间中断服务，且已提前通知您；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " (3) 非智安云原因导致的Internet上的通路阻塞，造成您网站访问速度下降等服务异常或中断，包括但不限于： "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("① 地震、爆炸、洪水、火灾、暴风雨、台风等天灾；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "② 战争、类似战争行为、敌对行为、武装冲突、恐怖活动、谋反、政变等情况；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("③ 罢工、暴动、民众骚乱或恶意行为；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("④ 电力或动力故障、公用设施或其他电信故障；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "⑤ 政府行为、政策变化、封锁、禁运、电信部门技术调整、政府管制等；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("(4）产品服务规则中对相关产品服务使用约定的其他免责情况。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("7.责任限制")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " (1).一方无需就对方特殊的、偶然的、间接的、附带的损害和损失（包括但不限于：预期利益、利润、商业机会、营业中断、资讯丢失等）向对方承担责任，即使该方已被告知该等损害发生的可能性。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " (2).在履行本协议时，智安云对因第三方的作为或不作为而给您或者其他第三方造成的损失不负责任。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "(3).任何情况下，智安云对本协议所承担的违约赔偿责任总额不超过违约服务对应之服务费总额。"
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("九、争议解决")]),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 1.本协议的订立、履行和解释及争议的解决均应适用中国法律并受中国法院管辖。如双方就本协议约定范围内的相关事项产生争议，双方应当先友好协商解决；如在30日内仍协商不成时，任何一方均可向深圳市南山区人民法院提起诉讼。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "2.争议事项未决期间，双方应继续履行其各自在本协议项下除争议事项外的义务。"
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("十、其他")]),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("1.本协议未尽事宜以智安云官网公布的信息为准。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 2.智安云拥有对产品服务相关的各类优惠活动（包括但不限于邀请码、代金券，虚拟货币）的合理处理权及最终解释权。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 3.本协议项下智安云对于您所有的通知均可通过网页公告、站内信、电子邮件、手机短信或其他智安云认为有效的方式进行；该等通知于发送之日视为已送达。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 4.除非法律另有规定，本协议任何一方未能行使或迟延行使本协议项下的权利并不构成对该等权利的放弃；单独或部分行使该等权利不应阻碍任何其他权利、权力或特权的行使。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 5.本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 6.未经智安云事先书面同意，您不得转让或让与本协议或本协议项下您的任何权利和义务。违反本条约定进行的任何转让或让与无效。 "
        ),
      ]),
      _c("br"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ignore_center" }, [
      _c("span", [
        _vm._v(
          " Copyright©2023 深圳市智安网络有限公司 All Rights Reve 版权所有 《中华人民共和国增值电信业务经营许可证》编号：B1-20190666 "
        ),
        _c(
          "a",
          {
            staticClass: "blue_font",
            attrs: {
              href: "https://beian.miit.gov.cn/#/Integrated/index",
              target: "_blank",
            },
          },
          [_vm._v("粤ICP备18027011号")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }