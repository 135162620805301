var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "za-loading",
          rawName: "v-za-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "agentHomeInfo",
    },
    [
      _c("div", { staticClass: "title" }, [_vm._v("代办事项")]),
      _c(
        "div",
        {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "1fr 1fr 1fr",
          },
        },
        _vm._l(_vm.list, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "listStyle",
              on: {
                click: function ($event) {
                  return _vm.path(item.path)
                },
              },
            },
            [
              _c("div", [_vm._v(_vm._s(item.name))]),
              _c("div", [
                index == "0"
                  ? _c("span", [_vm._v(_vm._s(_vm.result.unpaid))])
                  : _vm._e(),
              ]),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }