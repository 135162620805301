<template>
  <div class="moneyHomeInfo" v-za-loading="isLoading">
    <div class="title">费用信息</div>
    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr" class="listInfo">
      <div v-for="(item, index) in list" :key="index" class="listStyle">
        <div>{{ item.name }}</div>
        <div>
          <span v-if="index === 0" class="listText" @click="$router.push('/moneyCenter/overview')">
            ￥{{ addDecimalNumbers(balance.balance, withdrawFee) }}
          </span>
          <span v-if="index === 1" class="listText" @click="$router.push('/moneyCenter/cardRoll/voucher')">
            {{ result.cashCouponNum + '张' }}
          </span>
          <span v-if="index === 2" class="listText" @click="$router.push('/moneyCenter/invoiceManagement/askfor')">
            {{ '￥' + result.amount }}
          </span>
        </div>
      </div>
    </div>
    <div class="btnInfo">
      <div v-for="(item, index) in btnList" :key="index" class="btnStyle" @click="toPath(item.path)">
        <za-svg-icon :icon-class="item.icon" class-name="svg-size"></za-svg-icon>
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { apiCouponNum } from '@/api/moneyCenter/overView'
  import { apiFinanceAmount } from '@/api/moneyCenter/invoiceManagement/askfor'
  import { apiGetPopularBalance } from '@/api/management/overview'

  export default {
    data() {
      return {
        list: [{ name: '账户余额' }, { name: '优惠券' }, { name: '可开票金额' }],
        result: { cashCouponNum: 0, amount: '0' },
        btnList: [
          { icon: 'za-topUp', name: '在线预充值', path: '/moneyCenter/expenseManagement/topUp' },
          { icon: 'za-closed', name: '交易流水', path: '/moneyCenter/expenseManagement/details' },
          { icon: 'za-invoice', name: '发票管理', path: '/moneyCenter/invoiceManagement/askfor' },
          { icon: 'za-order', name: '订单管理', path: '/moneyCenter/orderManagement/myOrder' },
        ],
        withdrawFee: null,
      }
    },
    computed: {
      ...mapGetters({
        balance: 'user/balance',
      }),
    },
    created() {
      this.getList()
    },
    methods: {
      toPath(val) {
        this.$router.push(val)
      },
      getList() {
        this.onSubmitData(apiCouponNum, null, null, false, false, (res) => {
          this.result.cashCouponNum = res.data.cashCouponNum + res.data.couponNum
        })
        this.onSubmitData(apiFinanceAmount, null, null, false, false, (res) => {
          this.result.amount = res.data.amount
        })
        this.onSubmitData(apiGetPopularBalance, null, null, false, false, (res) => {
          this.withdrawFee = res.data.withdrawFee
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .moneyHomeInfo {
    width: 50%;
    height: 210px;
    background: #ffffff;
    border-radius: 2px;
    margin-left: 16px;
    margin-bottom: 16px;
    padding: 20px;

    .title {
      width: 100%;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 20px;
      margin-bottom: 14px;
    }

    .listInfo {
      height: 60px;
      border-bottom: 1px solid #f4f6fa;

      .listStyle {
        /*width: 224px;*/
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #555555;
        line-height: 17px;

        .listText {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #000000;
          margin-top: 6px;
          display: block;
          cursor: pointer;
        }
      }
    }

    .btnInfo {
      margin-top: 20px;
      display: flex;

      .btnStyle {
        width: 164px;
        height: 36px;
        background: #e4ecff;
        border-radius: 2px;
        margin-right: 5px;
        line-height: 36px;
        text-align: center;
        font-size: 12px;
        cursor: pointer;

        .svg-size {
          margin-right: 5px;
          font-size: 16px;
          vertical-align: sub;
        }
      }

      .btnStyle:hover {
        background: #1b5deb;
        color: #ffffff;
      }
    }
  }
</style>