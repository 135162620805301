<template>
  <item-bar v-if="item.meta && !item.meta.hidden" :is-menu="isMenu" :item="item">
    <template v-if="item.children && item.children.length">
      <item-menu v-for="route in item.children" :key="route.path" :item="route" />
    </template>
  </item-bar>
</template>

<script>
  import ItemBar from '@/za/layout/components/Sidebar/components/ItemBar'
  export default {
    name: 'ItemMenu',
    components: { ItemBar },
    props: {
      item: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      isMenu() {
        return this.item.children && this.item.children.length > 0
      },
    },
  }
</script>

<style lang="scss" scoped></style>
