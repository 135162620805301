<template>
    <div>
<!--        <div class="contactMe">-->
<!--            <div class="CB-gSwgjC">-->
<!--                <el-popover-->
<!--                        placement="left-start"-->
<!--                        width="200"-->
<!--                        trigger="hover"-->
<!--                        content="这是联系我们 内容。">-->
<!--                    <span class="el-icon-phone-outline call" slot="reference"   ></span>-->
<!--                </el-popover>-->
<!--            </div>-->
<!--        </div>-->
        <section class="za-main-app">
            <transition mode="out-in" name="fade-transform">
                <keep-alive :include="keepAliveNameList">
                    <router-view :key="routerKey"/>
                </keep-alive>
            </transition>
        </section>
    </div>

</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: 'AppMain',
        computed: {
            ...mapGetters({
                routes: 'routes/cachedRoutes',
            }),
            keepAliveNameList() {
                return this.routes
            },

            routerKey() {
                return this.$route.path
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~@/za/styles/mixin.scss';


    .contactMe {
        position: fixed;
        right: 0px;
        bottom: 128px;
        z-index: 101;
        opacity: 1;
        transition: all 0.25s linear 0s;
          top: 50%;
        .CB-gSwgjC {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: relative;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            background: #1B5DEB;
            .call {
                font-size: 16px;
                text-align: center;
                color: #FFFFFF;
            }
        }

    }

    .za-main-app {
        width: 100%;
        overflow: scroll;
        /*overflow-x: hidden;*/
        transition: $base-transition;
        height: $nobase-keep-alive-height;
        @include base-scrollbar;

        > [class*='-container'] {
            padding: $base-main-app-padding;
            background: rgba(255, 255, 255, 1);
        }

        > [class*='-center_bg'] {
            background: #F3F4F7;

        }

        > [class*='-details'] {
            padding: $base-main-app-details-padding;
        }

        > [class*='-cloud'] {
            padding: $base-main-app-cloud-padding;
        }

    }
</style>
