/**
 * @description 导出网络配置
 **/

module.exports = {
    // 默认的接口地址，开发环境和生产环境都会走/vab-mock-server
    // 正式项目可以选择自己配置成需要的接口地址，如"https://api.xxx.com"
    // 问号后边代表开发环境，冒号后边代表生产环境
    baseURL: process.env.NODE_ENV === 'development' ? '/api' : '/api',
    contentType: 'application/json;charset=UTF-8',
    requestTimeout: 600000,
    uploadTimeout: 600000,
    // 配后端数据的接收方式application/json;charset=UTF-8 或 application/x-www-form-urlencoded;charset=UTF-8
    // 最长请求时间
    // 上传时间
    // 操作正常code，支持String、Array、int多种类型
    //999976表示用户在修改密码时间处理过长时间响应
    //599998表示用户账号密码输入错误提示
    //589996表示用户手机号已经注册
    //589992表示手机格式错误
    successCode: [
        200, 0,
        '200', '0',
        '999976', 999976,
        '599998', 599998,
        589996, '589996',
        569983,'569983',
    ],
    // 数据状态的字段名称
    statusName: 'code',
    // 状态信息的字段名称
    messageName: 'msg',
    // 图片下载地址
    downLoadUrl: 'https://secoperation.ioiofast.com',
}
