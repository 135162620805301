var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "pagination-container",
      class: { hidden: _vm.hidden },
      style: { textAlign: _vm.position },
    },
    [
      _c(
        "el-pagination",
        _vm._g(
          _vm._b(
            {
              attrs: {
                background: "",
                "current-page": _vm.currentPage,
                layout: "total, sizes, prev, pager, next, jumper",
                "page-size": _vm.pageSize,
                "page-sizes": _vm.pageSizes,
                total: _vm.total,
              },
              on: {
                "update:currentPage": function ($event) {
                  _vm.currentPage = $event
                },
                "update:current-page": function ($event) {
                  _vm.currentPage = $event
                },
                "update:pageSize": function ($event) {
                  _vm.pageSize = $event
                },
                "update:page-size": function ($event) {
                  _vm.pageSize = $event
                },
                "current-change": function ($event) {
                  return _vm.$emit("pagination")
                },
                "size-change": function ($event) {
                  return _vm.$emit("pagination")
                },
              },
            },
            "el-pagination",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }