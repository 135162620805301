var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "allUpload" },
    [
      _c(
        "div",
        { staticClass: "clearfix" },
        [
          _c(
            "div",
            { staticClass: "wrap" },
            [
              _c(
                "draggable",
                _vm._b(
                  { attrs: { list: _vm._value } },
                  "draggable",
                  _vm.dragOptions,
                  false
                ),
                [
                  _vm._l(_vm._value, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: item.uid,
                        staticClass: "left middleCenter",
                        style: { width: _vm._width, height: _vm._height },
                      },
                      [
                        _c("img", {
                          attrs: {
                            height: _vm._height,
                            src: item.previewUrl,
                            width: _vm._width,
                            alt: "",
                          },
                        }),
                        _vm.isShowIcon(item.status)
                          ? _c("label", { staticClass: "success-bg" }, [
                              _c("i", {
                                staticClass: "success-icon el-icon-check",
                              }),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "content-wrap" }, [
                          _c(
                            "div",
                            {
                              staticClass: "content middleCenter",
                              style: { lineHeight: _vm._lineHeight },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-zoom-in",
                                on: {
                                  click: function ($event) {
                                    return _vm.handlePreviewImage(
                                      item.previewUrl
                                    )
                                  },
                                },
                              }),
                              _vm.isDelete
                                ? _c("i", {
                                    staticClass: "el-icon-delete",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDelImg(item, index)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]
                    )
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: { float: "left" },
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "imageUpload",
                          class: { hide: _vm.hideUploadEdit },
                          style: {
                            "--pictureWidth": _vm._width,
                            "--pictureHeight": _vm._height,
                            "--pictureLineHeight": _vm._lineHeight,
                          },
                          attrs: {
                            "auto-upload": false,
                            disabled: _vm._confirmBtnLoading || _vm.disable,
                            "file-list": _vm.fileList,
                            limit: _vm.limit,
                            multiple: true,
                            "on-change": _vm.onChange,
                            "on-exceed": _vm.onExceed,
                            "show-file-list": false,
                            accept: ".png,.jpeg,.jpg",
                            action: "imgUploadUrl",
                            "list-type": "picture-card",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-upload2" })]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._isShowProgress
            ? _c("el-progress", {
                attrs: { percentage: _vm.imageUploadPercent },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "za-dialog",
        {
          attrs: {
            "is-show-footer": false,
            modal: false,
            visible: _vm.previewVisible,
            "top-title": "查看图片",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.previewVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { src: _vm.previewImageUrl, alt: "图片失效", width: "100%" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }