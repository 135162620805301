import request from '@/utils/request'
import { WITHDRAWAL_LIST,METHOD,SUBMIT_WITHDRAWAL,GET_WITH_AMOUNT,SECURE_CERTIFICATION} from  '@/api/api.config'
//获取提现记录列表接口
export function withdrawallist(data) {
    return request({
        url:  WITHDRAWAL_LIST,
        method: METHOD.GET,
        params: data,
    })
}
//获取余额提现提交接口
export function apiSubmitDrawal(data) {
    return request({
        url:  SUBMIT_WITHDRAWAL,
        method: METHOD.POST,
         data,
    })
}
//获取可用余额接口
export function apiGetWithAmount(data) {
    return request({
        url:  GET_WITH_AMOUNT,
        method: METHOD.GET,
        params: data,
    })
}
//获取实名认证信息接口
export function apiCertificationName(data) {
    return request({
        url:  SECURE_CERTIFICATION,
        method: METHOD.GET,
        params: data,
    })
}