<template>
  <div class="wg-cap-btn" :style="style">
    <div class="wg-cap-btn__inner" :class="activeClass">
      <el-popover v-model="popoverVisible" placement="top" trigger="click">
        <go-captcha
          v-model="popoverVisible"
          width="300px"
          height="240px"
          :max-dot="maxDot"
          :image-base64="imageBase64"
          :thumb-base64="thumbBase64"
          @close="handleCloseEvent"
          @refresh="handleRefreshEvent"
          @confirm="handleConfirmEvent"
        />
      </el-popover>
    </div>
  </div>
</template>

<script>
  import GoCaptcha from './GoCaptcha'
  export default {
    name: 'GoCaptchaBtn',
    components: { GoCaptcha },
    props: {
      value: {
        type: String,
        default: 'default',
        validator: (value) => ['default', 'check', 'error', 'over', 'success'].indexOf(value) > -1,
      },
      showState: {
        type: Boolean,
        default: false,
      },
      width: String,
      height: String,
      maxDot: {
        type: Number,
        default: 5,
      },
      imageBase64: String,
      thumbBase64: String,
    },
    data() {
      return {
        popoverVisible: false,
        captStatus: 'default',
      }
    },
    watch: {
      showState: {
        handler: function (val) {
          // console.log(val,'<<val')
          if (val) {
            this.handleBtnEvent()
          }
        },
        immediate: true,
        deep: true,
      },
      popoverVisible(val) {
        if (val) {
          this.captStatus = 'check'
          this.$emit('refresh')
        } else if (this.captStatus === 'check') {
          this.captStatus = this.value
        }
      },
      value(val) {
        if (this.captStatus !== 'check') {
          this.captStatus = val
        }
        if (val === 'over' || val === 'success') {
          setTimeout(() => {
            this.popoverVisible = false
          }, 0)
        }
      },
      captStatus(val) {
        if (val !== 'check' && this.value !== val) {
          this.$emit('input', val)
        }
      },
    },
    computed: {
      style() {
        return `width:${this.width}; height:${this.height};`
      },
      activeClass() {
        let activeClass = this.captStatus
        return `wg-cap-active__${activeClass}`
      },
    },
    methods: {
      //点击出现弹窗
      handleBtnEvent() {
        setTimeout(() => {
          this.popoverVisible = true
        }, 0)
      },
      //刷新事件
      handleRefreshEvent() {
        this.captStatus = 'check'
        this.$emit('refresh')
      },
      // 处理确认事件
      handleConfirmEvent(data) {
        this.$emit('confirm', data)
      },
      //关闭弹窗事件
      handleCloseEvent() {
        this.$emit('close')
        this.popoverVisible = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .wg-cap-btn {
    width: 100%;
    height: 48px;
  }
  .wg-cap-btn .wg-cap-btn__inner {
    width: 100%;
    height: 48px;
    position: relative;
    letter-spacing: 1px;
  }
  .wg-cap-btn .wg-cap-state__default,
  .wg-cap-btn .wg-cap-state__check,
  .wg-cap-btn .wg-cap-state__error,
  .wg-cap-btn .wg-cap-state__success,
  .wg-cap-btn .wg-cap-state__over {
    position: absolute;
    width: 100%;
    height: 48px;
    font-size: 13px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    -webkit-appearance: none;
    box-sizing: border-box;
    outline: none;
    margin: 0;
    transition: 0.1s;
    font-weight: 500;
    -moz-user-select: none;
    -webkit-user-select: none;

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    justify-items: center;

    visibility: hidden;
  }
  .wg-cap-btn .wg-cap-state__default {
    color: #3e7cff;
    border: 1px solid #50a1ff;
    background: #ecf5ff;
    box-shadow: 0 0 20px rgba(62, 124, 255, 0.1);
    -webkit-box-shadow: 0 0 20px rgba(62, 124, 255, 0.1);
    -moz-box-shadow: 0 0 20px rgba(62, 124, 255, 0.1);
  }
  .wg-cap-btn .wg-cap-state__check {
    cursor: default;
    color: #ffa000;
    background: #fdf6ec;
    border: 1px solid #ffbe09;
    pointer-events: none;
  }
  .wg-cap-btn .wg-cap-state__error {
    color: #ed4630;
    background: #fef0f0;
    border: 1px solid #ff5a34;
  }
  .wg-cap-btn .wg-cap-state__over {
    color: #ed4630;
    background: #fef0f0;
    border: 1px solid #ff5a34;
  }
  .wg-cap-btn .wg-cap-state__success {
    color: #5eaa2f;
    background: #f0f9eb;
    border: 1px solid #8bc640;
    pointer-events: none;
  }
  .wg-cap-btn .wg-cap-active__default .wg-cap-state__default,
  .wg-cap-btn .wg-cap-active__error .wg-cap-state__error,
  .wg-cap-btn .wg-cap-active__over .wg-cap-state__over,
  .wg-cap-btn .wg-cap-active__success .wg-cap-state__success,
  .wg-cap-btn .wg-cap-active__check .wg-cap-state__check {
    visibility: visible;
  }
  .wg-cap-btn .wg-cap-state__inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
  }
  .wg-cap-btn .wg-cap-state__inner em {
    padding-left: 5px;
    color: #3e7cff;
    font-style: normal;
  }
  .wg-cap-btn .wg-cap-btn__inner .wg-cap-btn__ico {
    position: relative;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    font-size: 14px;
    display: inline-block;
    float: left;
    flex: 0;
  }
  .wg-cap-btn .wg-cap-btn__inner .wg-cap-btn__ico img {
    width: 24px;
    height: 24px;
    float: left;
    position: relative;
    z-index: 10;
  }
  @keyframes ripple {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0.05;
    }
    20% {
      opacity: 0.35;
    }
    65% {
      opacity: 0.01;
    }
    100% {
      transform: scaleX(2) scaleY(2);
      opacity: 0;
    }
  }
  @-webkit-keyframes ripple {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0.05;
    }
    20% {
      opacity: 0.35;
    }
    65% {
      opacity: 0.01;
    }
    100% {
      transform: scaleX(2) scaleY(2);
      opacity: 0;
    }
  }
  .wg-cap-btn .wg-cap-btn__inner .wg-cap-btn__verify::after {
    background: #409eff;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;

    animation: ripple 1.3s infinite;
    -moz-animation: ripple 1.3s infinite;
    -webkit-animation: ripple 1.3s infinite;
    animation-delay: 2s;
    -moz-animation-delay: 2s;
    -webkit-animation-delay: 2s;
  }

  .wg-cap-tip {
    padding: 50px 20px 100px;
    font-size: 13px;
    color: #76839b;
    text-align: center;
    line-height: 180%;
    width: 100%;
    max-width: 680px;
  }
  ::v-deep {
    .el-popover {
      width: 360px;
      top: 94px;
    }
  }
</style>
