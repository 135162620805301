<template>
  <div>
    <el-row>
      <el-form
        ref="signInInfoObj"
        :disabled="confirmBtnLoading"
        :model="signInInfoObj"
        :rules="rules"
        class="sign"
        label-position="right"
      >
        <el-col :span="24">
          <el-form-item prop="phone">
            <el-input
              v-focus
              v-model.trim="signInInfoObj.phone"
              placeholder="请输入手机号"
              :readonly="confirmBtnLoading"
              type="text"
              maxlength="11"
              @keyup.native="trim('phone')"
              @input="limitInput($event, 'phone')"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <div>
            <el-col :span="16">
              <el-form-item prop="smsCode">
                <el-input
                  v-model="signInInfoObj.smsCode"
                  placeholder="请输入验证码"
                  maxlength="6"
                  @input="changeWaring()"
                />
              </el-form-item>
            </el-col>
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="7">
              <el-button @click="sendData" class="sendInfo" :disabled="dis" :class="{ grey: isGrey, blue: !isGrey }">
                <span v-if="telState == 1">发送验证码</span>
                <span v-if="telState == 2">{{ count }}秒重试</span>
                <span v-if="telState == 3">重新发送</span>
              </el-button>
            </el-col>
          </div>
        </el-col>

        <el-col>
          <el-checkbox v-model="checked" class="confirmCheck" @keyup.enter.native="handleLogin()"></el-checkbox>
          <span class="clause" style="color: #999999">我已阅读并同意</span>
          <span class="toclause" @click="$router.push('/protocal/userProtocal')">服务协议</span>
          <span class="clause" style="color: #999999">和</span>
          <span class="toclause" @click="$router.push('/protocal/policy')">隐私政策</span>
        </el-col>
      </el-form>
    </el-row>
    <div>
      <za-button :loading="confirmBtnLoading" :size="14" class="button" @click="handleLogin">
        {{ translateTitle('btn.login') }}
      </za-button>
    </div>
    <div class="operation">
      <span>&nbsp;</span>
      <span @click="handleRegister">免费注册</span>
    </div>
    <!--        <div class="otherLogin">-->
    <!--            <span>其他登录方式:</span>-->
    <!--            <span>-->
    <!--                      <za-svg-icon class-name="icon-click" icon-class="za-qq" @click="handlePassword"/>-->
    <!--                      <za-svg-icon class-name="icon-click" icon-class="za-wechat" @click="handlePassword"/>-->
    <!--                      <za-svg-icon class-name="icon-click" icon-class="za-microblog" @click="handlePassword"/>-->
    <!--                </span>-->
    <!--        </div>-->
    <div>
      <el-dialog width="25%" title="验证" :visible.sync="innerVisible" append-to-body>
        <za-verify v-if="showVerity" ref="verify" @verifySuccess="success" :selectKey="dialogType" />
      </el-dialog>
    </div>
  </div>
</template>
<script>
  import { apiLoginByPhone, apiLoginByPhoneSms } from '@/api/apiLogin'
  import { resLoginObj } from '@/za/model/resModel'
  import { isHaveSpace, isPhone } from '@/utils/validate'
  import ZaVerify from '@/za/components/zaVerify'
  import { mapActions } from 'vuex'

  export default {
    directives: {
      focus: {
        inserted(el) {
          el.querySelector('input').focus()
        },
      },
    },
    components: { ZaVerify },
    props: {
      activeName: {
        type: String,
        default: '',
      },
    },
    data() {
      const phonePass = (rule, value, callback) => {
        if (value === '') callback(new Error('请填写11位手机号'))
        else if (!isPhone(value)) callback(new Error('手机号码格式不正确，请重新输入'))
        else callback()
      }
      const smsCode = (rule, value, callback) => {
        if (value === '') callback(new Error('验证码不能为空'))
        else if (isHaveSpace(value)) callback(new Error('验证码不能包含空格！'))
        else callback()
      }
      return {
        signInInfoObj: {
          phone: '',
          smsCode: '',
          popularizeCode: '',
        },
        redirect: undefined,
        count: '',
        telState: 1,
        dis: false,
        show: true,
        timer: null, //设置计时器,
        isGrey: false, //按钮样式
        showVerity: false,
        innerVisible: false,
        checked: false,
        rules: {
          smsCode: [{ required: true, trigger: 'blur', validator: smsCode }],
          phone: [{ required: true, message: `请输入手机号`, trigger: 'blur' }],
        },
      }
    },
    watch: {
      $route: {
        handler() {
          this.redirect = '/'
        },
        immediate: true,
      },
      'signInInfoObj.smsCode': {
        handler(value) {
          this.signInInfoObj.smsCode = value.replace(/\s+/g, '')
        },
      },
    },
    created() {
      const code = this.$route.query.id
      code ? (this.signInInfoObj.popularizeCode = code) : (this.signInInfoObj.popularizeCode = '')
    },
    mounted() {},
    methods: {
      ...mapActions({
        setLoginData: 'user/setLoginData',
      }),
      limitInput(value, name) {
        let val = '' + value
        val =
          val
            .replace(/[^\d^\.]+/g, '')
            .replace(/^0+(\d)/, '$1')
            .replace(/^\./, '0.')
            .match(/^\d*(\.?\d{0,2})/g)[0] || ''
        this.signInInfoObj[name] = val
      },
      trim(val) {
        this.signInInfoObj[val] = this.signInInfoObj[val].replace(/\s*/g, '')
      },
      handlePassword() {},
      handleRoute() {
        return this.redirect === '/404' || this.redirect === '/403' ? '/' : this.redirect
      },
      handleLogin() {
        if (!this.checked) {
          this.$msgWarning('请阅读并同意协议')
          return
        }
        this.$refs.signInInfoObj.validate(async (valid) => {
          if (valid) {
            try {
              this.confirmBtnLoading = true
              let res = await apiLoginByPhone(this.signInInfoObj).catch()
              if (res.code == 0) {
                this.$printLog(res, 'Login')
                const resObj = Object.assign(resLoginObj, res.data)
                await this.setLoginData(resObj).catch()
                await this.$router.push(this.handleRoute())
              }
            } finally {
              this.confirmBtnLoading = false
            }
          } else {
            return false
          }
        })
      },
      async success(val) {
        this.innerVisible = false
        this.showVerity = false
        let res = await apiLoginByPhoneSms({ phone: this.signInInfoObj.phone }).catch()
        if (res.code == 0) {
          let TIME_COUNT = 60
          if (!this.timer) {
            this.count = TIME_COUNT
            this.isGrey = true
            this.telState = 2
            this.dis = true
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--
              } else {
                this.dis = false
                this.isGrey = false
                this.telState = 3
                clearInterval(this.timer)
                this.timer = null
              }
            }, 1000)
          }
        }
      },
      sendData() {
        if (!this.signInInfoObj.phone) {
          this.$msgWarning('手机号不能为空')
        } else {
          this.innerVisible = true
          this.showVerity = true
        }
      },
      handleRegister() {
        this.$baseEventBus.$emit('register', true)
        // this.$message({
        //   message: '当前版本为内测版本，如有需要请联系商务开通内测账号。',
        //   type: 'warning'
        // });
      },
    },
  }
</script>
<style lang="scss" scoped>
  .sign {
    margin-top: 42px;
    margin-bottom: 38px;
    width: 360px;
    height: 100px;

    .svg-prefix {
      margin-left: 5px;
      margin-bottom: 4px;
    }

    .svg-suffix {
      margin-right: 5px;
      margin-bottom: 4px;
      cursor: pointer;
    }

    .login-btn {
      font-size: 18px;
      color: #0abbcd;
      text-align: center;
      cursor: pointer;
    }

    ::v-deep {
      .el-input__inner {
        font-size: 14px;
        background-color: #fff;
        color: black;
        user-select: text;
        border: 1px solid #d9d9d9;
      }

      .el-input__prefix {
        top: 5px;
      }

      .el-input__suffix {
        top: 5px;
      }
    }
  }

  .button {
    text-align: center;
    font-weight: bold;
    line-height: 22px;
    margin: 20px 0 20px 0;
    width: 360px;
    height: 40px;
    background: #4680ff;
    border-color: #4680ff;
    box-shadow: none;
    color: #fff;
  }

  .button:hover {
    background: #1b5deb;
    border-color: #1b5deb;
  }

  .operation {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    // margin-bottom: 32px;
    font-weight: 400;
    color: #999999;

    span:last-child {
      color: #409eff;
      cursor: pointer;
    }
  }

  .otherLogin {
    font-size: 14px;
    color: #999999;

    span {
      margin-right: 14px;
    }

    .icon-click {
      margin-right: 14px;
      font-size: 24px;
      vertical-align: middle;
      cursor: pointer;
    }
  }

  .confirmCheck {
    font-size: 12px;
    font-weight: 400;
    color: #555555;
    margin-top: 21px;
    margin-bottom: 13px;
  }

  .clause {
    font-size: 14px;
    font-weight: 400;
    color: #409eff;
  }

  .toclause {
    font-size: 14px;
    font-weight: 400;
    color: #409eff;
    cursor: pointer;
  }
</style>
