var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    { attrs: { content: "点击复制", effect: "dark", placement: "bottom" } },
    [
      _c(
        "span",
        {
          class: _vm.className,
          staticStyle: { cursor: "pointer" },
          attrs: { "data-clipboard-text": _vm.content },
          on: {
            click: function ($event) {
              return _vm.copy(_vm.className)
            },
          },
        },
        [_vm._v(_vm._s(_vm.chief || _vm.content))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }