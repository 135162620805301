<template>
    <div>
        <el-dropdown :placement="'bottom'">
              <span class="dropdown-text">
                  {{optionsTitle}}
              </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="(item,index) in optionsList" :key="index" @click.native="handleClick(item)">
                        <template v-if="num==null">
                            <za-svg-icon :icon-class="item.icon" class-name="svg-item"></za-svg-icon>
                            <span class="itemName">{{item.name}}</span>
                        </template>
                        <template v-else="!num">
                            <za-svg-icon :icon-class="item.icon" class-name="svg-item"></za-svg-icon>
                            <span class="itemName">{{item.name}}</span>
                            <span class="orderNum" v-if="index==1" >{{'{'+num+'}'}}</span>
                        </template>
                    </el-dropdown-item>
                </el-dropdown-menu>
        </el-dropdown>

    </div>
</template>
<script>
    export default {
        props: {
            optionsTitle: {
                type: String,
                default: ''
            },
            options: {
                type: Array,
                default: () => []
            },
            orderNum:{
                type: Number,
                default: null
            }
        },
        data(){
            return{
                title:this.optionsTitle,
                optionsList:this.options,
                num:this.orderNum
            }
        },
        methods:{
            handleClick(command) {
                this.$emit('click', command)
            },
        }
    }
</script>
<style lang="scss" scoped>
.dropdown-text{
    color: black;
}
.itemName{
font-size: 12px;

}
    .svg-item{
        margin-right: 9px;
    }
</style>