<template>
  <div class="reminder" v-za-loading="isLoading">
    <div class="title">推广客户</div>
    <div>
      <za-picker :timeList="timeList" @changeTime="changeTime"></za-picker>
    </div>
    <div class="card_bottom">
      <div class="cardUl" v-for="(item, index) in clickArr" :key="index">
        <div class="title">{{ item.label }}</div>
        <div class="operation">
          <span>{{ item.value }}</span>
          <span>个</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import zaPicker from '@/za/components/zaPicker'
  import { apiPopularizeUserTotal } from '@/api/management/correlation'
  import { apiPopularizeClickTotal } from '@/api/management/overview'

  export default {
    components: { zaPicker },
    data() {
      return {
        waitPayOrderNumber: null,
        waitRenewResourceNumber: null,
        amount: null,
        subObj: {
          startAt: '',
          endAt: '',
        },
        clickArr: [
          {
            value: '0',
            label: '当前关联客户数',
            name: 'currentTotal',
          },
          {
            value: '0',
            label: '推广链接点击数',
            name: 'clickTotal',
          },
          {
            value: '0',
            label: '维护期客户数',
            name: 'maintainTotal',
          },
        ],
        timeList: [
          {
            value: 11,
            label: '近七天',
          },
          {
            value: 6,
            label: '本月',
          },
          {
            value: 13,
            label: '三个月',
          },
        ],
      }
    },
    created() {},
    methods: {
      getList() {
        let resArr = []
        this.onSubmitData(apiPopularizeUserTotal, this.subObj, null, false, false, (res) => {
          resArr = Object.keys(res.data)
          resArr.map((item) => {
            if (item === 'currentTotal') this.clickArr[0].value = res.data[item]
            if (item === 'maintainTotal') this.clickArr[2].value = res.data[item]
          })
        })
        this.onSubmitData(apiPopularizeClickTotal, this.subObj, null, false, false, (res) => {
          this.clickArr[1].value = res.data.total
        })
      },
      changeTime(val) {
        this.subObj.startAt = val._startTime
        this.subObj.endAt = val._endTime
        this.getList()
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import '~@/za/styles/mixin.scss';

  .reminder {
    height: 210px;
    width: 50%;
    padding: 20px;
    background: #ffffff;
    border-radius: 2px;

    .title {
      @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
      width: 100%;
      height: 20px;
      margin-bottom: 14px;
    }

    .card_bottom {
      display: flex;
      justify-content: space-around;

      .cardUl {
        height: 90px;
        width: 33%;
        margin-right: 10px;
        margin-top: 14px;
        background: #f4f6fa;
        padding: 10px;

        .title {
          font-size: 12px;
          width: 100%;
        }

        .operation {
          padding-top: 10px;
          font-size: 20px;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: 500;
          color: #000000;
        }
      }
    }
  }

  ::v-deep {
    .el-card__body {
      padding: 10px;
    }
  }
</style>