<template>
  <div class="voucher-container" v-za-loading="isLoading">
    <div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="充值记录" name="rechargeRecord"></el-tab-pane>
      </el-tabs>
    </div>
    <div>
      <occ-table :tableData="tableData" :columObj="columObj" :isOp="columObj.isOp"></occ-table>
      <pagination :limit.sync="pageObj.pageSize" :page.sync="pageObj.pageNo" :total="total" @pagination="getList" />
    </div>
  </div>
</template>
<script>
  import { rechargeRecord } from '@/api/moneyCenter/expenseManagement/details'

  export default {
    data() {
      return {
        activeName: 'rechargeRecord',
        total: 0,
        pageObj: {
          pageNo: 1,
          pageSize: 10,
        },
        tableData: [],
        columObj: {
          // 控制选择框
          selection: false,
          isOp: false,
          width: '300px',
          columnData: [
            {
              text: true,
              prop: 'billNumber',
              label: '充值单号',
              width: '',
              flag: true,
              align: 'center',
            },
            {
              enumType: 'payType',
              prop: 'payMethod',
              label: '充值方式',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              ownDefined: true,
              prop: 'amount',
              label: '充值金额',
              flag: true,
              width: '',
              align: 'center',
              ownDefinedReturn: (row) => {
                return '￥' + row.amount
              },
            },
            {
              text: true,
              prop: 'status',
              label: '充值状态',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              text: true,
              prop: 'payTime',
              label: '充值时间',
              flag: true,
              width: '',
              align: 'center',
            },
          ],
        },
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.onSubmitData(rechargeRecord, this.pageObj, null, false, false, (res) => {
          this.total = res.data.total
          res.data.list ? (this.tableData = res.data.list) : (this.tableData = [])
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import '~@/za/styles/mixin.scss';

  .prompt {
    height: 40px;
    background: #fffbe6;
    border-radius: 2px;
    border: 1px solid #ffe58f;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 40px;
  }
</style>