<template>
    <div class="cardCenter">
        <div class="card_mottom">
            <el-row>
                <el-col :span="5">
                    <div class="cardUl">
                        <div class="title">当前客户关联数</div>
                        <div class="operation">
                            <div>
                                <span>1</span>
                                <span>个</span>
                            </div>
                        </div>

                    </div>
                </el-col>
                <el-col :span="5">
                    <div class="cardUl">
                        <div class="title">推广期客户数</div>
                        <div class="operation">
                            <div>
                                <span>2</span>
                                <span>个</span>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="5">
                    <div  class="cardUl">
                        <div class="title">维护期客户数</div>
                        <div class="operation">
                            <div>
                                <span>3</span>
                                <span>个</span>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="5">
                    <div class="cardUl">
                        <div class="title">已过期客户数</div>
                        <div class="operation">
                            <div>
                                <span>4</span>
                                <span>个</span>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {

            }
        },
        created() {

        }
    }
</script>
<style lang="scss" scoped>

    .cardCenter{
        background-color: #FFFFFF;
        margin-top: 20px;
        height: 130px;
        margin-bottom: 20px;
        padding: 20px 15px;
        .card_mottom {
            .cardUl {
                height: 90px;
                margin-right: 20px;
                 width: 230px;
                background: #F4F6FA;
                padding: 10px;

                .title {
                    font-size: 12px;
                    width: 100%;
                }
                .operation {
                    display: flex;
                    justify-content: space-between;
                      margin-top: 22px;
                    div:first-child {
                        span:first-child {
                            font-size: 24px;
                            font-family: DINAlternate-Bold, DINAlternate;
                            font-weight: bold;
                            color: #000000;
                        }
                    }
                }

            }
        }
    }

</style>