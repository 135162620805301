<template>
  <div class="container-component">
    <main>
      <div class="left">
        <div class="line"></div>
        <p>{{ title }}</p>
      </div>
      <div class="right" @click="goBack">返回</div>
    </main>
  </div>
</template>

<script>
  export default {
    name: 'ZaPageHeader',
    props: {
      title: {
        type: String,
        default: '标题',
      },
    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '~@/za/styles/mixin.scss';
  .container-component {
    width: 100%;
    background: #1a2634;
    main {
      @include flex_div($just: space-between, $padding: 0);
      border-bottom: 1px solid #536978;
      height: 50px;
      .left {
        @include flex_div($just: flex-start, $padding: 0);
        color: $base-white-text-fff;
        font-size: 20px;
        .line {
          width: 3px;
          height: 13px;
          background: #00e2ff;
          margin-right: 12px;
        }
      }
      .right {
        width: 100px;
        font-size: 16px;
        color: #00c2dc;
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
</style>
