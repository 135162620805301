var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "compage" }, [
    _c("div", { staticClass: "main" }, [
      _c(
        "div",
        [
          _c("el-image", {
            staticClass: "pic-error-parent",
            attrs: { src: require("@/assets/error_images/404.png") },
          }),
        ],
        1
      ),
      _c("div", [
        _c("span", { staticClass: "title" }, [
          _vm._v("云服务器等爆品抢先购，低至4.2元/月"),
        ]),
        _c("div", { staticClass: "newUser" }, [_vm._v("新用户优惠：")]),
        _c("div", { staticClass: "news" }, [
          _vm._v(
            "全场云服务器任享8折，满1000减400，满500减200，满200减100任优惠！"
          ),
        ]),
        _c(
          "div",
          { staticClass: "compage_input" },
          [
            _c("el-input", {
              attrs: { placeholder: "请输入内容" },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            }),
            _c("el-button", { on: { click: _vm.handleClick } }, [
              _vm._v("复制推广链接"),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "footer" },
          [
            _c(
              "el-button",
              {
                staticStyle: { background: "#3370FF" },
                attrs: { type: "primary" },
              },
              [_vm._v("下载推广图片包")]
            ),
            _c("div", { staticClass: "otherLogin" }, [
              _c("span", [_vm._v("分享至 :")]),
              _c(
                "span",
                [
                  _c("za-svg-icon", {
                    attrs: {
                      "class-name": "icon-click",
                      "icon-class": "za-qq",
                    },
                    on: { click: _vm.handlePassword },
                  }),
                  _c("za-svg-icon", {
                    attrs: {
                      "class-name": "icon-click",
                      "icon-class": "za-wechat",
                    },
                    on: { click: _vm.handlePassword },
                  }),
                  _c("za-svg-icon", {
                    attrs: {
                      "class-name": "icon-click",
                      "icon-class": "za-microblog",
                    },
                    on: { click: _vm.handlePassword },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }