<template>
  <div
    ref="div"
    :class="[type ? 'button--' + type : '',{'is-loading': loading,'is-disabled': disabled,}]"
    :style="[type === 'text' ? { color: textColor } : '', { fontSize: size + 'px' }]"
    class="button"
    @click="handleClick"
  >
    <i v-if="loading" class="el-icon-loading"></i>
    <i v-if="icon && !loading" :class="icon"></i>
    <span v-if="$slots.default"><slot></slot></span>
  </div>
</template>

<script>
import {debounce} from "@/za/plugins/debounce";

export default {
  name: 'ZaButton',
  props: {
    type: {
      type: String,
      default: 'default',
    },
    icon: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    textColor: {
      type: String,
      default: '#409EFF',
    },
    size: {
      type: Number,
      default: 14,
    },
  },
  methods: {
    handleClick: debounce(function () {
      if (this.loading || this.disabled) return
      return this.$emit('click')
    }),
  },
}
</script>

<style lang="scss" scoped>
// default模式下样式
@mixin default($alpha: 0.1, $type: 1) {
  background-color:#409eff

}

@mixin info($type: 0) {
  $color: #000000;
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  box-shadow: none;

  @if ($type == 1) {
    border: 1px solid #909399;
  } @else if ($type == 2) {
    border: 1px solid #80a4bc;
    box-shadow: 0 0 3px 0 #909399;
  }
}

@mixin text($type: 0) {
  opacity: 1;

  @if ($type == 1) {
    opacity: 0.8;
  } @else if ($type == 2) {
    color: #536978 !important;
  }
}

@mixin primary($type: 0) {
  color: $base-font-color-white-6f;
  background-color: #3370FF;
  opacity: 1;
  box-shadow: none;

  @if ($type == 1) {
    opacity: 0.7;
  } @else if ($type == 2) {
    box-shadow: 0 0 4px 0 #00d2ed;
  } @else if ($type == 3) {
    background-color: #424d5b;
    color: #80a4bc !important;
  }
}

@mixin danger($type: 0) {
  color: #ff4d4f;
  background-color: rgba(255, 77, 79, 0.14);
  border: 1px solid #ff4d4f;
  box-shadow: none;
}

.button {
  display: inline-block;
  border-radius: 2px;
  margin-left: 10px;
  padding: 9px 15px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  position: relative;


  > i {
    margin-right: 5px;
  }
}

.button--default {
  color: #fff;
  @include default;

  &:hover {
    @include default($alpha: 1, $type: 3);
  }

  &:active {
    @include default($alpha: 1, $type: 2);
  }

  &[class*='is-'] {
    &:hover,
    &:active {
      @include default;
    }
  }
}

.button--text {
  padding: 9px 0;
  background-color: transparent;
  color: coral;
  @include text;

  &:hover {
    @include text($type: 1);
  }

  &:active {
    @include text;
  }

  &[class*='is-'] {
    @include text($type: 2);

    &:hover,
    &:active {
      @include text($type: 2);
    }
  }
}

.button--info {
  @include info;

  &:hover {
    @include info($type: 1);
  }

  &:active {
    @include info($type: 2);
  }

  &[class*='is-'] {
    &:hover,
    &:active {
      @include info;
    }
  }
}

.button--primary {
  @include primary;

  &:hover {
    @include primary($type: 1);
  }

  &:active {
    @include primary($type: 2);
  }

  &[class*='is-'] {
    @include primary($type: 3);

    &:hover,
    &:active {
      @include primary($type: 3);
    }
  }
}

.button--danger {
  @include danger;

  &:hover {
    box-shadow: 0 0 4px 0 #ff4d4f inset;
  }
}

.is-disabled,
.is-loading {
  opacity: 0.7;
  cursor: not-allowed;
}
</style>
