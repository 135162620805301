import request from '@/utils/request'
import {
  GET_POPULARIZE_BALANCE,
  GET_POPULARIZE_CLICK_POINT,
  GET_POPULARIZE_CLICK_TOTAL,
  GET_POPULARIZE_INFO,
  GET_POPULARIZE_INFO_ISREGISTER,
  GET_POPULARIZE_MENTLIST,
  METHOD,
  POST_POPULARIZE_CASH_BACK_TO_BALANCE,
  POST_POPULARIZE_TRANSFER_TO_BALANCE,
} from '@/api/api.config'

//判断用户是否为智安云推官
export function apiIsCloudPushOfficer(data) {
  return request({
    url: GET_POPULARIZE_INFO_ISREGISTER,
    method: METHOD.GET,
    params: data,
  })
}

// 获取云推官详情
export function apiGetPopularizeInfo(data) {
  return request({
    url: GET_POPULARIZE_INFO,
    method: METHOD.GET,
    params: data,
  })
}

//获取推广总览-推广客户-推广链接点击数（只有当为云推官时才调用接口）
export function apiPopularizeClickTotal(data) {
  return request({
    url: GET_POPULARIZE_CLICK_TOTAL,
    method: METHOD.GET,
    params: data,
  })
}

//获取推广链接点击操作（携带 推广码时 接口触发）
export function apiPopularizeClickPoint(data) {
  return request({
    url: GET_POPULARIZE_CLICK_POINT,
    method: METHOD.POST,
    data,
  })
}

// 获取返佣余额
export function apiGetPopularBalance(data) {
  return request({
    url: GET_POPULARIZE_BALANCE,
    method: METHOD.GET,
    params: data,
  })
}

// 返佣余额转出
export function apiTransferToBalance(data) {
  return request({
    url: POST_POPULARIZE_TRANSFER_TO_BALANCE,
    method: METHOD.POST,
    data,
  })
}

// 返佣余额提现
export function apiCashBackToBalance(data) {
  return request({
    url: POST_POPULARIZE_CASH_BACK_TO_BALANCE,
    method: METHOD.POST,
    data,
  })
}

//获取推广总览-结算列表/推广收入
export function apiPopularizeSettlementList(data) {
  return request({
    url: GET_POPULARIZE_MENTLIST,
    method: METHOD.GET,
    params: data,
  })
}
