import request from '@/utils/request'
import {
    SAVESECURITY,
    METHOD,
    USEROVERVIEW,
    GET_SECURITY,
    VERIFY_SECURITY,
    UPDATE_PWD,
    UPDATE_LOGIN_SAFE,
    GET_PHONE,
    GET_EMAIL,
    SENG_PHONE,
    SEND_EMAIL,
    SECURE_PHONE,
    SECURE_UPDATESMS,
    SECURE_EMAIL,
    SECURE_UPDATEEMAIL,
    SECURE_ADDEMAIL,
    SECURE_SECUREEMAIL,
    SECURE_SMS
} from '@/api/api.config'
/**
 * @description 安全设置-修改手机号接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiSecureSms(data) {
    return request({
        url: SECURE_SMS,
        method: METHOD.POST,
        data,
    })
}
/**
 * @description 安全设置新增邮箱接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiAddEmail(data) {
    return request({
        url: SECURE_ADDEMAIL,
        method: METHOD.POST,
        data,
    })
}
/**
 * @description 安全设置新用户邮箱号发送验证码接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiNewSecureEmail(data) {
    return request({
        url: SECURE_SECUREEMAIL,
        method: METHOD.POST,
        data,
    })
}
/**
 * @description 安全设置获取邮箱号接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiGetEmail(data) {
    return request({
        url: GET_EMAIL,
        method: METHOD.GET,
        params:data,
    })
}
/**
 * @description 安全设置获取邮箱验证码接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiSendEmail(data) {
    return request({
        url: SEND_EMAIL,
        method: METHOD.GET,
        params:data,
    })
}
/**
 * @description 安全设置验证邮箱验证码接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiSecureEmail(data) {
    return request({
        url: SECURE_EMAIL,
        method: METHOD.POST,
        data,
    })
}
/**
 * @description 安全设置修改邮箱接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiUpdateEmail(data) {
    return request({
        url: SECURE_UPDATEEMAIL,
        method: METHOD.POST,
        data,
    })
}
/**
 * @description 安全设置获取手机号接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiGetPhone(data) {
    return request({
        url: GET_PHONE,
        method: METHOD.GET,
        params:data,
    })
}
/**
 * @description 安全设置获取手机号验证码接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiSendPhone(data) {
    return request({
        url: SENG_PHONE,
        method: METHOD.GET,
        params:data,
    })
}
/**
 * @description 安全设置验证手机号验证码接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiSecurePhone(data) {
    return request({
        url: SECURE_PHONE,
        method: METHOD.POST,
        data,
    })
}
/**
 * @description 安全设置修改手机号接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiSecureUpdateSms(data) {
    return request({
        url: SECURE_UPDATESMS,
        method: METHOD.POST,
        data,
    })
}
/**
 * @description 安全设置界面接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiUseerOverview(data) {
    return request({
        url: USEROVERVIEW,
        method: METHOD.GET,
        data,
    })
}

/**
 * @description 编辑密保问题接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apisaveSecurity(data) {
    return request({
        url: SAVESECURITY,
        method: METHOD.POST,
        data,
    })
}

/**
 * @description 查询密保问题接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiGetSecurity(data) {
    return request({
        url: GET_SECURITY,
        method: METHOD.GET,
        params: data,
    })
}

/**
 * @description 校验密保问题接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiVeritySecurity(data) {
    return request({
        url: VERIFY_SECURITY,
        method: METHOD.POST,
        data,
    })
}

/**
 * @description 修改密码接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiUpdatePwd(data) {
    return request({
        url: UPDATE_PWD,
        method: METHOD.POST,
        data,
    })
}
/**
 * @description 登陆保护接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiLoginSafe(data) {
    return request({
        url: UPDATE_LOGIN_SAFE,
        method: METHOD.POST,
        data,
    })
}


