<template>
  <el-dropdown placement="bottom" trigger="click" v-bind="$attrs" @command="handleClick">
    <div class="menu-btn">{{ btnTxt }}</div>
    <el-dropdown-menu slot="dropdown">
      <slot></slot>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: 'ZaContextMenu',
  props: {
    btnTxt: {
      type: String,
      default: '更多',
    },
  },
  methods: {
    handleClick(command) {
      this.$emit('click', command)
    },
  },
}
</script>

<style lang="scss">
.menu-btn {
  position: relative;
  /* px-to-viewport-ignore-next */

  cursor: pointer;
  /* px-to-viewport-ignore-next */
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  color: #3370FF;
  display: inline-block;
  margin-left: 10px;
}
</style>
