var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "eChart-div" }, [
    _c(
      "div",
      {
        ref: "slotDiv",
        staticClass: "slot_st",
        style: [{ width: _vm.getWidth }],
      },
      [_vm._t("default")],
      2
    ),
    _c("div", {
      ref: _vm.compName,
      style: [{ width: _vm.getWidth }, { height: _vm.getHeight }],
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }