import * as EL_TYPE from '@/za/model/commonEnum'
import { isArray } from '@/utils/validate'
import { hasPermi } from '@/za/plugins/directive'
import { translateTitle } from '@/utils/i18n'

const DIALOG_TYPE_OBJECT = {
  ADD: {
    type: 1,
    text: '',
  },
  EDIT: {
    type: 2,
    text: '',
  },
}

export default {
  data() {
    return {
      /**
       * el-Icon
       */
      buttonIconObj: Object.assign({}, EL_TYPE.EL_BUTTON_ICON),

      /**
       * el-Color
       */
      buttonTypeObj: Object.assign({}, EL_TYPE.EL_COLOR),

      /**
       * 加载控制器
       */
      isLoading: false,

      /**
       * 数据提交控制器
       */
      confirmBtnLoading: false,

      /**
       * 通用提示dialog显隐控制器
       */
      dialogVisible: false,

      /**
       * 通用dialog对象
       */
      dialogTypeObj: Object.assign({}, DIALOG_TYPE_OBJECT),

      /**
       * 通用dialog类型
       */
      dialogType: DIALOG_TYPE_OBJECT.ADD.type,

      /**
       * 原始提交String数据
       */
      orgSubmitDataStr: '',

      /**
       * 筛选器全局单独处理项
       */
      /**
       * 筛选器全局单独处理项
       */

      commonRetain: [
        { pageNo: 1 },
        { page_num: 1 },
        { pageNum: 1 },
        { page_size: 10 },
        { pageIndex: 1 },
        { pageSize: 10 },
        { page: 1 },
        { size: 10 },
      ],
      waringState: false,
      waringText: '验证码错误或已失效，请重新获取',
      waringPwd: false,
      warringQuestion: '密保问题验证失败，请重新输入',
      loginState: false,
      loginWaring: '用户名或密码错误,请校验后重新输入',
      waringDescState: false,
      waringDesc: '',
      productList: [
        { name: '全部', value: '' },
        { name: '云服务器', value: 'BCC' },
        { name: '专属服务器', value: 'DCC' },
        { name: '云磁盘', value: 'CDS' },
        { name: '弹性IP', value: 'EIP' },
        { name: '共享带宽', value: 'EIPGROUP' },
        { name: '带宽包', value: 'EIPBP' },
        { name: '私有网络', value: 'VPC' },
        { name: '负载均衡', value: 'BLB' },
        { name: '云解析', value: 'DNS' },
        { name: '对象存储', value: 'BOS' },
        { name: '云数据库', value: 'RDS' },
        { name: 'Redis', value: 'SCS' },
        { name: '数据迁移服务', value: 'DTS' },
        { name: '云监控', value: 'BCM' },
        { name: '高防服务', value: 'DDoS' },
        { name: '应用防火墙', value: 'WAF' },
        { name: '主机安全', value: 'HOSTEYE' },
        { name: '云堡垒机', value: 'CAG' },
        { name: 'CDN', value: 'CDN' },
        { name: '短信', value: 'SMS' },
        { name: '智能门户', value: 'AIPage' },
        { name: 'SSL证书', value: 'CAS' },
        { name: '云虚拟主机', value: 'BCH' },
        { name: '域名', value: 'BCD' },
        { name: '商标', value: 'TMS' },
      ],
      productObj: {
        '': '全部',
        BCC: '云服务器',
        DCC: '专属服务器',
        CDS: '云磁盘',
        EIP: '弹性IP',
        EIPGROUP: '共享带宽',
        EIPBP: '带宽包',
        VPC: '私有网络',
        BLB: '负载均衡',
        DNS: '云解析',
        BOS: '对象存储',
        RDS: '云数据库',
        SCS: 'Redis',
        DTS: '数据迁移服务',
        BCM: '云监控',
        DDoS: '高防服务',
        WAF: '应用防火墙',
        HOSTEYE: '主机安全',
        CAG: '云堡垒机',
        CDN: 'CDN',
        SMS: '短信',
        AIPage: '智能门户',
        CAS: 'SSL证书',
        BCH: '云虚拟主机',
        BCD: '域名',
        TMS: '商标',
      },
    }
  },
  watch: {
    dialogVisible(newVla) {
      if (newVla === false) {
        this.clearDialogStatus('form')
        this.$refs['form'].resetFields()
      } else {
        // this.waringState = false
        // this.waringPwd = false
        this.waringDescState = false
      }
    },
  },

  methods: {
    hasPermi,
    translateTitle,
    /**
     * @description 清楚ref的validate数据
     * @param ref 指定清楚的ref-validate数据
     */
    clearDialogStatus(ref) {
      this.$nextTick(() => {
        if (ref && this.$refs[ref]) {
          this.$refs[ref].clearValidate()
          return
        }
        for (const key in this.$refs) {
          if (this.$refs[key].clearValidate) this.$refs[key].clearValidate()
        }
      })
    },

    /**
     * @description 是否可提交数据
     * @param {Object} curObj
     * @returns {boolean}
     */
    isCanSubmit(curObj) {
      if (!curObj) return true
      const tmp = JSON.stringify(curObj)
      return this.orgSubmitDataStr !== tmp
    },

    isRetain(retain, checkKey) {
      if (!retain || !checkKey) return false
      for (const item of retain) {
        const key = Object.keys(item)[0]
        if (key === checkKey) return true
      }
      return false
    },
    getChecklist() {
      this.columObj.columnData.forEach((item) => {
        this.checkList.push(item.label)
        this.checkboxList.push(item.label)
      })
    },
    resetListQuery(que, retain = undefined) {
      const curRetain = this.$deepClone(this.commonRetain)
      if (retain && isArray(retain)) curRetain.push(...retain)
      for (const item of curRetain) {
        const key = Object.keys(item)[0]
        if (que[key] !== null && que[key] !== undefined) {
          que[key] = item[key] === null ? que[key] : item[key]
        }
      }
      for (const queKey in que) {
        if (this.isRetain(curRetain, queKey)) continue
        switch (typeof que[queKey]) {
          case 'string':
            que[queKey] = ''
            break
          case 'number':
            que[queKey] = 0
            break
          case 'object':
            if (isArray(que[queKey])) que[queKey].splice(0)
            else que[queKey] = {}
            break
          case 'boolean':
            que[queKey] = true
            break
          case 'array':
            que[queKey] = []
            break
          default:
            break
        }
      }
    },

    onGetData(apiFuc, paramObj, callBack = null) {
      if (!apiFuc) return
      apiFuc(paramObj).then((res) => {
        this.$printLog(res, apiFuc.name)
        if (callBack) callBack(res)
      })
    },

    onDelete(apiFuc, sendData, tips = null, isRefresh = true, callBack = null) {
      this.confirmBtnLoading = true
      if (!apiFuc) return
      apiFuc(sendData)
        .then((res) => {
          if (this.total > 10 && this.total % 10 === 1) {
            if (this.listQuery.page_num > 1) this.listQuery.page_num--
            else if (this.listQuery.pageNum > 1) this.listQuery.pageNum--
            else if (this.listQuery.pageIndex > 1) this.listQuery.pageIndex--
            else if (this.listQuery.page > 1) this.listQuery.page--
          }
          this.$printLog(res, apiFuc.name)
          if (tips) this.$msgSuccess(tips)
          if (isRefresh) this.getList()
          if (callBack) callBack(res)
        })
        .finally(() => {
          this.confirmBtnLoading = false
        })
    },
    //安全组批量删除方法，要求延时调用
    onDelete_two(apiFuc, sendData, tips = null, isRefresh = true, callBack = null) {
      this.confirmBtnLoading = true
      if (!apiFuc) return
      apiFuc(sendData)
        .then((res) => {
          if (this.total > 10 && this.total % 10 === 1) {
            if (this.listQuery.page_num > 1) this.listQuery.page_num--
            else if (this.listQuery.pageNum > 1) this.listQuery.pageNum--
            else if (this.listQuery.pageIndex > 1) this.listQuery.pageIndex--
            else if (this.listQuery.page > 1) this.listQuery.page--
          }
          this.$printLog(res, apiFuc.name)
          if (tips) this.$msgSuccess(tips)
          if (isRefresh) {
            setTimeout(() => {
              this.getList()
            }, 2000)
          }
          if (callBack) callBack(res)
        })
        .finally(() => {
          this.confirmBtnLoading = false
        })
    },
    onSubmitData(apiFuc, sendData, tips = null, isDialog = true, isRefresh = true, callBack = null) {
      if (!apiFuc) return
      if (isDialog) this.confirmBtnLoading = true
      else this.isLoading = true
      apiFuc(sendData)
        .then((res) => {
          this.$printLog(res, apiFuc.name)
          if (tips) this.$msgSuccess(tips)
          if (isRefresh) this.getList()
          if (isDialog) this.dialogVisible = false
          if (callBack) callBack(res)
        })
        .finally(() => {
          if (isDialog) this.confirmBtnLoading = false
          else this.isLoading = false
        })
    },
    copy(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$msgSuccess('复制成功')
      })
    },
    changeWaring() {
      this.waringState = false
    },
    changePwdWaring() {
      this.waringPwd = false
    },
    clangeWaringDesc() {
      this.waringDescState = false
    },
    /**
     * @description 精度计算
     * @param num1
     * @param num2
     * @returns {number}
     */
    addDecimalNumbers(num1, num2) {
      // 将小数转换为整数，以提高精度
      const precision = Math.pow(10, 10)
      const intNum1 = Math.round(num1 * precision)
      const intNum2 = Math.round(num2 * precision)
      // 将整数相加
      const resultInt = intNum1 + intNum2
      // 将结果转换回小数形式
      return resultInt / precision
    },

    // 验证码倒计时刷新更新验证码
    // handleTimer(val,test=1) {
    //     if( localStorage.getItem(val)){
    //         if(test==1){
    //             this.count=localStorage.getItem(val)
    //             this.isGrey = true;
    //             this.telState = 2;
    //             this.dis = true;
    //             this.timer = setInterval(() => {
    //                 if (this.count > 0 && this.count <= localStorage.getItem(val)) {
    //                     this.count--
    //                     localStorage.setItem(val,this.count)
    //                 } else {
    //                     this.dis = false;
    //                     this.isGrey = false;
    //                     this.telState = 3;
    //                     clearInterval(this.timer);
    //                     this.timer = null;
    //                     localStorage.removeItem(val)
    //                 }
    //             }, 1000);
    //         }else{
    //             this.two_count = localStorage.getItem(val);
    //             this.two_isGrey = true;
    //             this.two_telState = 2;
    //             this.two_dis = true;
    //             this.two_timer = setInterval(() => {
    //                 if (this.two_count > 0 && this.two_count <= localStorage.getItem(val)) {
    //                     this.two_count--;
    //                     localStorage.setItem(val,this.two_count)
    //                 } else {
    //                     this.two_dis = false;
    //                     this.two_isGrey = false;
    //                     this.two_telState = 3;
    //                     clearInterval(this.two_timer);
    //                     this.two_timer = null;
    //                     localStorage.removeItem(val)
    //                 }
    //             }, 1000);
    //         }
    //
    //     }
    // },
  },
}
