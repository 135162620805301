import request from '@/utils/request'
import { CONSOLE_INFO, METHOD,UPDATE_INFO} from '@/api/api.config'



/**
 * @description 基本信息接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiUserInfo(data) {
    return request({
        url: CONSOLE_INFO,
        method: METHOD.GET,
        params: data,
    })
}
/**
 * @description 保存基本信息接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiSaveInfo(data) {
    return request({
        url: UPDATE_INFO,
        method: METHOD.POST,
         data,
    })
}
