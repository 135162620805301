<template>
  <div>
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'ZaFrameItem',
  }
</script>

<style lang="scss" scoped>
  div {
    box-shadow: 2px -2px 18px 3px rgba(173, 244, 255, 0.32) inset;
    border-radius: 8px;
    filter: blur(0px);
  }
</style>
