<template>
  <div>
    <div class="title">
      <span>{{ title }}</span>
    </div>
    <div class="backOrder_center">
      <el-empty :image-size="200" v-if="promoteArr.length <= 0"></el-empty>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="item.label" :name="item.name" v-for="(item, index) in promoteArr" :key="index">
          <comPage v-if="activeName === item.name" :productType="activeName"></comPage>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
  import comPage from './components/compage'
  import { apiGetPopularizeTypeList } from '@/api/management/center'

  export default {
    components: { comPage },
    data() {
      return {
        title: '',
        activeName: '',
        promoteArr: [],
      }
    },
    created() {
      this.title = this.$route.meta.title
      this.getList()
    },
    methods: {
      getList() {
        this.onSubmitData(apiGetPopularizeTypeList, null, null, false, false, (res) => {
          if (res.data.list) {
            this.activeName = res.data.list[0]
            for (const item of res.data.list) {
              const tempObj = {
                name: item,
                label: item,
              }
              this.promoteArr.push(tempObj)
            }
          }
        })
      },
      handleClick(tab) {
        this.activeName = tab.name
      },
    },
  }
</script>
<style lang="scss" scoped>
  .title {
    height: 62px;
    background: #ffffff;
    border-radius: 2px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    padding-left: 20px;
    line-height: 62px;
    margin-bottom: 20px;
  }

  .backOrder_center {
    background: #ffffff;
    padding: 20px;
  }

  ::v-deep {
    .el-tabs__item {
      font-weight: 600 !important;
    }
  }
</style>