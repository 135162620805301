<template>
  <div class="settlement">
    <!-- <za-query-form>
            <za-query-form-left-panel :span="18">
                <el-form :inline="true" :model="pageObj" @submit.native.prevent>
                    <el-form-item label="注册时间" >
                        <el-date-picker
                                v-model="pickTime"
                                type="date"
                                placeholder="选择日期"
                                @change="datePickEnd"
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-form>
            </za-query-form-left-panel>
        </za-query-form> -->
    <div>
      <occ-table :tableData="tableData" :columObj="columObj" :isOp="columObj.isOp"></occ-table>
      <pagination :limit.sync="pageObj.pageSize" :page.sync="pageObj.pageNo" :total="total" @pagination="getList" />
    </div>
  </div>
</template>
<script>
  import { apiPopularizeSettlementList } from '@/api/management/overview'

  export default {
    data() {
      return {
        pageObj: {
          time: 0,
          pageNo: 1,
          pageSize: 10,
        },
        pickTime: '',
        tableData: [],
        total: 0,
        columObj: {
          // 控制选择框
          selection: false,
          isOp: false,
          width: '300px',
          columnData: [
            {
              text: true,
              prop: 'month',
              label: '结算时间',
              width: '',
              flag: true,
              align: 'center',
            },
            {
              ownDefined: true,
              prop: 'orderPrice',
              label: '有效订单金额',
              flag: true,
              width: '',
              align: 'center',
              ownDefinedReturn: (row) => {
                return `￥${row.orderPrice}`
              },
            },
            {
              ownDefined: true,
              prop: 'commissionAmount',
              label: '结算推广佣金',
              flag: true,
              width: '',
              align: 'center',
              ownDefinedReturn: (row) => {
                return `￥${row.commissionAmount}`
              },
            },
            {
              ownDefined: true,
              prop: 'taxAmount',
              label: '代扣税费',
              prompt: true,
              width: '',
              align: 'center',
              ownDefinedReturn: (row) => {
                return `￥${row.taxAmount}`
              },
            },
            {
              ownDefined: true,
              prop: 'receivableAmount',
              label: '本期应收佣金',
              flag: true,
              width: '',
              align: 'center',
              ownDefinedReturn: (row) => {
                return `￥${row.receivableAmount}`
              },
            },
            {
              ownDefined: true,
              prop: 'realityAmount',
              label: '本期实收佣金',
              flag: true,
              width: '',
              align: 'center',
              ownDefinedReturn: (row) => {
                return `￥${row.realityAmount}`
              },
            },
          ],
        },
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.onSubmitData(apiPopularizeSettlementList, this.pageObj, null, false, false, (res) => {
          res.data.list ? (this.tableData = res.data.list) : (this.tableData = [])
          this.total = res.data.total
        })
      },
      datePickEnd(date) {
        if (date) {
          this.pageObj.time = this.$changeTimeStamp(date)
        } else {
          this.pageObj.time = 0
        }
        this.getList()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .settlement {
    background: #ffffff;
    padding: 20px;
  }
</style>