<template>
    <div>
        <div class="onlinePay">
            <div style="width: 30%">
                <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item :label="translateTitle('topUp.topUpMoney') +':'">
                        <el-input
                                class="input-with-select"
                                v-model="form.amount"
                                @input="limitInput($event,'amount')"
                        >
                            <el-button slot="prepend">￥</el-button>
                        </el-input>
                        <span class="red-font" v-if="shouTest">请输入0.01~9999999范围内的数字，小数点后最多两位</span>
                    </el-form-item>
                    <el-form-item :label="translateTitle('topUp.platform') +':'" class="za-radio">
                        <el-radio-group v-model="form.payType">
                            <el-radio label="wechat">
                                <div>
                                    <img class="pay_img" :src="weixinPay_src"/>
                                </div>
                            </el-radio>
                            <el-radio label="alipay">
                                <div>
                                    <img class="pay_img" :src="zhifuPay_src"/>
                                </div>
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="myCheck">
            <div class="choose">
                <el-checkbox v-model="form.agree">我已了解</el-checkbox>
            </div>
            <div>
                <div class="prompt">
                    <span>1.查看<span  @click="$router.push('/protocal/promise')">反洗钱承诺书</span></span>
                    <span> {{translateTitle('topUp.fourth')}}</span>
                    <!-- <span> {{translateTitle('topUp.fifth')}}</span> -->
                </div>
            </div>
            <div class="submit">
                <el-button type="primary" @click="topUpSubmit" :disabled="!form.amount||form.amount<0.01||form.amount>9999999">
                    {{translateTitle('btn.confirmTopUp')}}
                </el-button>
            </div>
            <div>
                <za-pay-dialog :isPay="false"  :payDialog="payDialog" @click="noneX" topTitle="充值完成前请不要关闭此窗口"></za-pay-dialog>
            </div>
        </div>
    </div>
</template>
<script>
    import {translateTitle} from '@/utils/i18n'
    import zaPayDialog from '@/za/components/zaPayDialog'

    export default {
        name: 'OnlinePay',
        components: {
            zaPayDialog
        },
        data() {
            return {
                payDialog: false,
                form: {
                    amount: null,
                    payType: 'wechat',
                    agree: false
                },
                shouTest:false,
                weixinPay_src: require('@/assets/moneyCenter/weixin.png'),
                zhifuPay_src: require('@/assets/moneyCenter/zhifu.png')
            }
        },
        methods: {
            limitInput(value, name) {
                let val = "" + value;
                val = val
                    .replace(/[^\d^\.]+/g, "")
                    .replace(/^0+(\d)/, "$1")
                    .replace(/^\./, "0.")
                    .match(/^\d*(\.?\d{0,2})/g)[0] || "";
                if(val<0.01||val>9999999){
                    this.shouTest=true
                }else{
                    this.shouTest=false
                }
                this.form[name] = val
            },
            noneX() {
                this.payDialog = false;
                this.$router.push('/moneyCenter/overview')
            },
            topUpSubmit() {
                if (this.form.agree) {
                    this.$emit('onSubmit', this.form)
                    this.payDialog = true;
                } else {
                    this.$msgWarning(translateTitle('topUp.warning'))
                }

            },


        }
    }
</script>
<style lang="scss" scoped>
    .onlinePay {
        width: 100%;
        height: 200px;
        background: #F4F6FA;
        border-radius: 2px;
        padding: 30px;
    }

    .myCheck {
        margin-top: 20px;

        .choose {
            margin-bottom: 14px;
            color: #000000;
        }

        .prompt {
            width: 100%;
            height: 30px;

            span {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #555555;
                display:block;
                margin-bottom: 10px;
                span{
                    display: inline;
                    font-size: 14px;
                    color: #3370FF !important;
                    margin-left: 10px;
                    cursor: pointer;
                }
            }
        }
    }

    .question {
        color: #999999;
        margin-left: 10px;

    }

    .submit {
        margin-top: 30px;
    }

    .pay_img {
        height: 70px;
    }

    ::v-deep {
        .el-radio__input {
            display: none;
        }

        .za-radio .is-checked {
            position: relative;
            border: 1px solid #336FFE;
            height: 72px;

            &.is-checked::after {
                content: '✔';
                display: block;
                height: 0px;
                width: 0px;
                position: absolute;
                bottom: 0;
                right: 0;
                color: #fff;
                /**对号大小*/
                font-size: 12px;
                line-height: 8px;
                border: 10px solid;
                border-color: transparent #4884ff #4884ff transparent;
            }
        }

        .el-radio__label {
            padding: 0;
        }

        .el-radio-group {
            display: flex;
        }
    }
</style>