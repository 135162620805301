var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Verify", {
        ref: "verify",
        attrs: {
          mode: "pop",
          captchaType: "clickWord",
          imgSize: { width: "330px", height: "155px" },
        },
        on: { success: _vm.success, clickFalse: _vm.clickFalse },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }