var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "go-captcha-wrap" },
      [
        _c("GoCaptchaBtn", {
          staticClass: "go-captcha-btn",
          attrs: {
            width: "100%",
            height: "50px",
            "image-base64": _vm.captBase64,
            "thumb-base64": _vm.captThumbBase64,
            showState: _vm.showState,
          },
          on: {
            confirm: _vm.handleConfirm,
            refresh: _vm.handleRequestCaptCode,
            close: _vm.handleCloseEvent,
          },
          model: {
            value: _vm.captStatus,
            callback: function ($$v) {
              _vm.captStatus = $$v
            },
            expression: "captStatus",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }