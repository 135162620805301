import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6dfc016e&scoped=true"
var script = {}
import style0 from "./index.vue?vue&type=style&index=0&id=6dfc016e&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dfc016e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/.npm/za-console-portal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6dfc016e')) {
      api.createRecord('6dfc016e', component.options)
    } else {
      api.reload('6dfc016e', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=6dfc016e&scoped=true", function () {
      api.rerender('6dfc016e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/za/layout/components/Navbar/NavMa/index.vue"
export default component.exports