var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "za-loading",
          rawName: "v-za-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "moneySurplus",
    },
    [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "title" },
          [
            _c("span", [_vm._v("账户余额")]),
            _c(
              "el-tooltip",
              { attrs: { effect: "light", placement: "right-start" } },
              [
                _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                  _c("div", { staticClass: "orange_font" }, [
                    _vm._v("账户余额:指当前账号内的总余额"),
                  ]),
                  _c("div", { staticClass: "grey_font" }, [
                    _vm._v("账户余额 = 可用余额 + 返佣余额"),
                  ]),
                ]),
                _c("i", { staticClass: "el-icon-info blue_font" }),
              ]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "main" }, [
          _c("div", [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.addDecimalNumbers(
                    _vm.balance.cashBalance,
                    _vm.withdrawFee
                  )
                )
              ),
            ]),
            _c("span", [_vm._v("元")]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "btn",
            on: {
              click: function ($event) {
                return _vm.$router.push(
                  "/moneyCenter/expenseManagement/details"
                )
              },
            },
          },
          [_vm._v("交易流水")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }