import Vue from 'vue'
import ElementUI, {MessageBox} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import {scrollBoard} from '@jiaminghi/data-view'
import SlideVerify from 'vue-monoplasty-slide-verify';
import axios from 'axios'
import lodash from 'lodash'
Vue.prototype.$axios= axios
Vue.prototype.$lodash= lodash
import {Base64} from 'js-base64'
Vue.prototype.$Base64 = Base64;

import '@/za/icons'
Vue.use(SlideVerify);
Vue.use(scrollBoard)
Vue.use(ElementUI, {
  size: 'small',
})

/**
 * ------------------------------------------------------------------
 * 加载插件
 * ------------------------------------------------------------------
 */
const Plugins = require.context('./plugins', true, /\.js$/)
Plugins.keys().map(Plugins)

/**
 * ------------------------------------------------------------------
 * 添加全局样式
 * ------------------------------------------------------------------
 */
import './styles/za.scss'

/**
 * ------------------------------------------------------------------
 * 添加全局的筛选函数
 * ------------------------------------------------------------------
 */
import * as filters from '@/za/filters'

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

/**
 * ------------------------------------------------------------------
 * 添加全局混入数据
 * ------------------------------------------------------------------
 */
import globalMixin from '@/za/mixin/globalMixin'
import {compNameChange} from '@/utils'

Vue.mixin(globalMixin)

/**
 * ------------------------------------------------------------------
 * 挂载全局组件
 * ------------------------------------------------------------------
 */
// import Pagination from '@/za/components/Pagination'
// Vue.component('Pagination', Pagination)

// import ZaButton from '@/za/components/zaButton'
// Vue.component('za-button', ZaButton)

// 加载组件
const Components = require.context('./components', true, /\.vue$/)
Components.keys()
  .map(Components)
  .forEach((item) => {
    if (item.default.name && item.default.name !== 'Layout')
      Vue.component(compNameChange(item.default.name), item.default)
  })

/**
 * ------------------------------------------------------------------
 * 添加vue prototype函数
 * ------------------------------------------------------------------
 */

/**
 * @description 成功类型弹窗提示
 * @param {String} msg 提示内容
 */
Vue.prototype.$msgSuccess = function (msg) {
  this.$message({showClose: true, message: msg, type: 'success',offset:60})
}

/**
 * @description 错误类型弹窗提示
 * @param {String} msg 提示内容
 */
Vue.prototype.$msgError = function (msg) {
  this.$message({showClose: true, message: msg, type: 'error',offset:60})
}

/**
 * @description 警告类型弹窗提示
 * @param {String} msg 提示内容
 */
Vue.prototype.$msgWarning = function (msg) {
  this.$message({showClose: true, message: msg, type: 'warning',offset:60})
}

/**
 * @description 普通类型弹窗提示
 * @param {String} msg 提示内容
 */
Vue.prototype.$msgInfo = function (msg) {
  this.$message.info(msg)
}

/**
 * @description 打印函数
 * @param {*} msg 所需打印的对象
 * @param {String} funcName 方法名称
 */
Vue.prototype.$printLog = function (msg, funcName) {
  if (process.env.NODE_ENV === 'development' && msg) {
    const curRoute = this.$route
    if (funcName) {
      // console.log(`[${curRoute.path}][${funcName}]:`, msg)
    } else {
      // console.log(`[${curRoute.path}]:`, msg)
    }
  }
}

/**
 * @description 对象深拷贝
 * @param {Object} source 拷贝的对象
 * @returns {*[]}
 */
Vue.prototype.$deepClone = function (source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = Vue.prototype.$deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 * @description 表格高度
 * @param {*} formType
 */
Vue.prototype.$baseTableHeight = (formType) => {
  let height = window.innerHeight
  const paddingHeight = 285
  const formHeight = 60

  if ('number' === typeof formType) {
    height = height - paddingHeight - formHeight * formType
  } else {
    height = height - paddingHeight
  }
  return height
}

/**
 * @description 全局事件总线
 */
Vue.prototype.$baseEventBus = new Vue()

import {loadingText} from '@/config'
import {Loading} from 'element-ui'

/**
 * @description 全局加载层
 * @param {number} index 自定义加载图标类名ID
 * @param {string} text 显示在加载图标下方的加载文案
 */
Vue.prototype.$baseLoading = (index = undefined, text = loadingText) => {
  return Loading.service({
    lock: true,
    text,
    spinner: index ? 'za-loading-type' + index : index,
    background: 'hsla(0,0%,100%,.8)',
  })
}

/**
 * @description 全局多彩加载层
 * @param {number} index 自定义加载图标类名ID
 * @param {string} text 显示在加载图标下方的加载文案
 */
Vue.prototype.$baseColorfullLoading = (index = undefined, text = loadingText) => {
  let loading
  if (!index) {
    loading = Loading.service({
      lock: true,
      text,
      spinner: 'dots-loader',
      background: 'hsla(0,0%,100%,.8)',
    })
  } else {
    const spinnerDict = {
      1: 'dots',
      2: 'gauge',
      3: 'inner-circles',
      4: 'plus',
    }
    loading = Loading.service({
      lock: true,
      text,
      spinner: spinnerDict[index] + '-loader',
      background: 'hsla(0,0%,100%,.8)',
    })
  }
  return loading
}

/**
 * @description 同源数据拷贝
 * @param {Object} fillData 填充数据
 * @param {Object} copiedData 被拷贝数据
 */
Vue.prototype.$copyData = (fillData, copiedData) => {
  if (fillData && copiedData) {
    for (const key in fillData) {
      if (copiedData[key] !== undefined) fillData[key] = copiedData[key]
    }
  }
}

/**
 * @description 将时间格式转换为10为时间戳
 * @param {Object} dateObj 时间对象
 */
Vue.prototype.$changeTimeStamp = (dateObj) => {
  if (dateObj.getTime) return Math.round(dateObj.getTime() / 1000)
  return ''
}

/**
 * @description 全局Confirm
 * @param {string|VNode} content 消息正文内容
 * @param {string} title 标题
 * @param {function} callbackConfirm 确认回调
 * @param {function} callbackCancel 关闭或取消回调
 * @param {string} confirmButtonText 确定按钮的文本内容
 * @param {string} cancelButtonText 取消按钮的自定义类名
 */
Vue.prototype.$baseConfirm = (
  content,
  title = undefined,
  callbackConfirm = undefined,
  callbackCancel = undefined,
  confirmButtonText = '确定',
  cancelButtonText = '取消'
) => {
  MessageBox.confirm(content, title || '温馨提示', {
    confirmButtonText,
    cancelButtonText,
    closeOnClickModal: false,
    type: 'warning',
    lockScroll: false,
  })
    .then(() => {
      if (callbackConfirm) {
        callbackConfirm()
      }
    })
    .catch(() => {
      if (callbackCancel) {
        callbackCancel()
      }
    })
}

/**
 * @description 将多行字符串切割为数组
 * @param {String} str 需要切割的字符串
 */
Vue.prototype.$foo = str => {
  let temp = str.split(/[\n,]/g);
  for (let i = 0; i < temp.length; i++) {
    if (temp[i] == "") {
      temp.splice(i, 1);
      //删除数组索引位置应保持不变
      i--;
    }
  }
  return temp;
}

/**
 * ------------------------------------------------------------------
 * 类型扩展
 * ------------------------------------------------------------------
 */

/**
 * 替换所有匹配exp的字符串为指定字符串
 * @param exp 被替换部分的正则
 * @param newStr 替换成的字符串
 */
String.prototype.replaceAll = function (exp, newStr) {
  return this.replace(new RegExp(exp, 'gm'), newStr)
}

/**
 * 原型：字符串格式化
 * @param args 格式化参数值
 */
String.prototype.format = function (args) {
  let result = this
  if (arguments.length < 1) {
    return result
  }

  let data = arguments // 如果模板参数是数组
  if (arguments.length === 1 && typeof args == 'object') {
    // 如果模板参数是对象
    data = args
  }
  for (const key in data) {
    const value = data[key]
    if (undefined !== value) {
      result = result.replaceAll('\\{' + key + '\\}', value)
    }
  }
  return result
}
