<template>
  <div id="oauth">
    <img src="@/assets/hourglass.png" alt="" />
    <h3>LOADING {{ ellipsis }}</h3>
    <h2>正在进入系统</h2>
  </div>
</template>
<script>
  import { apiLogin } from '@/api/apiLogin'
  import { mapActions } from 'vuex'

  export default {
    name: 'Oauth',
    data() {
      return {
        ellipsis: '...',
        loginForm: {
          username: '',
          password: '',
          popularizeCode: '',
          force: true,
        },
      }
    },
    created() {
      this.loginForm.username = this.$route.query.username
      this.loginForm.password = this.$route.query.password
      this.loginForm.popularizeCode = this.$route.query.popularizeCode
      this.oLogin()
    },
    methods: {
      ...mapActions({
        setLoginData: 'user/setLoginData',
      }),
      oLogin() {
        setTimeout(async () => {
          const { data } = await apiLogin(this.loginForm).catch(() => {
            setTimeout(() => {
              close()
            }, 3000)
          })
          await this.setLoginData(data)
          await this.$router.push('/')
        }, 3000)
      },
    },
  }
</script>
<style lang="scss" scoped>
  @keyframes rotate {
    0% {
      transform: rotate(0);
    }

    50% {
      transform: rotate(180deg);
    }

    100% {
      transform: rotate(180deg);
    }
  }

  #oauth {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1a1c1a;
    color: #fff;

    img {
      width: 98px;
      margin-bottom: 20px;
      animation: rotate 2s 2s infinite;
    }

    h2,
    h3 {
      margin: 5px 0;
    }
  }
</style>