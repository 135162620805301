<template>
    <div class="clip">
        <div>
            <el-upload
                    ref="pl_upload"
                    class="upload-demo upload"
                    list-type="picture-card"
                    :headers="{ Authorization: tok}"
                    action="#"
                    :on-success="importData_handleAvatarSuccess"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :on-change="handleChange"
                    :file-list="fileList"
                    :auto-upload='false'
                    :show-file-list="false"
            >
                <div class="picture">
                    <div  class="upload-text" >
                        <div slot="trigger" v-show="!imgSrc" class="upload-plus">
                            <i  class="el-icon-plus"></i>
                        </div>
                        <div class="upload-icon" v-show="imgSrc"  @click.stop>
                            <el-image
                                    :src="imgSrc"
                                    :fit="'scale-down'"
                                    @click.stop='ensss'
                            >
                            </el-image>
                            <div class="img_mc">
                                <span><i class="el-icon-delete" @click.stop='expurgate'></i></span>
                                <span><i class="el-icon-zoom-in" @click.stop='enlargement'></i></span>
                            </div>
                        </div>
                    </div>
                </div>

            </el-upload>
            <div class="remarks">
                <div>图片尺寸需要大于100 * 100像素</div>
                <div>支持jpg、png、jpeg等格式大小不能超过2MB</div>
            </div>
            <za-dialog :top-title="'图片预览'"
                       append-to-body
                       :visible.sync="showViewer"
                       width="620px">
                <img :src="imgSrc_obj"></img>
            </za-dialog>
        </div>
        <div class="">
            <za-dialog
                    :top-title="'剪裁图片'"
                    append-to-body
                    :visible.sync="outerVisible"
                    width="620px"
                    @close='closeDialog'
            >
                <div class="cropper-content">
                    <div class="cropper" style="text-align:center">
                        <vue-cropper
                                ref="cropper"
                                :img="option.img"
                                :outputSize="option.outputSize"
                                :outputType="option.outputType"
                                :info="option.info"
                                :canScale="option.canScale"
                                :autoCrop="option.autoCrop"
                                :autoCropWidth="option.autoCropWidth"
                                :autoCropHeight="option.autoCropHeight"
                                :fixed="option.fixed"
                                :fixedNumber="option.fixedNumber"
                                :full="option.full"
                                :fixedBox="option.fixedBox"
                                :canMove="option.canMove"
                                :canMoveBox="option.canMoveBox"
                                :original="option.original"
                                :centerBox="option.centerBox"
                                :height="option.height"
                                :infoTrue="option.infoTrue"
                                :maxImgSize="option.maxImgSize"
                                :enlarge="option.enlarge"
                                :mode="option.mode"
                                @realTime="realTime"
                                @imgLoad="imgLoad"

                        >
                        </vue-cropper>
                    </div>
                    <div class="preview">
                        <img :src='previewImg' alt="" class='previewImg' ref="img">
                        <div>头像预览</div>
                    </div>

                </div>
                <!--底部操作工具按钮-->
                <div class="footer-btn" slot="footer">
                    <div class="upload-btn">
                        <za-button :disabled="confirmBtnLoading" :type="buttonTypeObj.GREY" @click="onStep">
                            上一步
                        </za-button>
                        <za-button :disabled="confirmBtnLoading" :type="buttonTypeObj.GREY"
                                   @click="submitUpload('blob')">
                            上传图片
                        </za-button>
                    </div>
                </div>
            </za-dialog>
        </div>
    </div>
</template>

<script>
    import {VueCropper} from 'vue-cropper'
    import ZaDialog from "../../../../../za/components/zaDialog/index";
    import { apiUploadImg } from '@/api/apiCommon'
    export default {
        // mixins: [imageViewMixins],
        name: 'UserIndex',
        components: {
            ZaDialog,
            'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer'),
            VueCropper,
        },
        props: {},
        data() {
            return {
                loading: false,
                outerVisible: false,
                showViewer: false,//大图
                fileList: [],
                imgSrc: '',
                imgSrc_obj: [],
                tok: '',
                list_show: '',
                previews: {},
                previewImg: null, // 预览后的图片
                option: {
                    img: '', // 裁剪图片的地址
                    info: true, // 裁剪框的大小信息
                    outputSize: 1, // 裁剪生成图片的质量
                    outputType: 'png', // 裁剪生成图片的格式
                    canScale: true, // 图片是否允许滚轮缩放
                    autoCrop: true, // 是否默认生成截图框
                    autoCropWidth: 212, // 默认生成截图框宽度
                    autoCropHeight: 106, // 默认生成截图框高度
                    fixedBox: false, // 固定截图框大小 不允许改变
                    fixed: false, // 是否开启截图框宽高固定比例
                    fixedNumber: [2, 1], // 截图框的宽高比例
                    full: false, // 是否输出原图比例的截图
                    canMoveBox: true, // 截图框能否拖动
                    original: false, // 上传图片按照原始比例渲染
                    centerBox: false, // 截图框是否被限制在图片里面
                    infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
                    canMove: true,
                },
            };
        },
        computed: {},
        watch: {},
        created() {
        },
        mounted() {

        },
        methods: {
            closeDialog() {//关闭模态框时执行
                this.fileList = []
                this.imgSrc = ''
                this.imgSrc_obj = []
                console.log('模态框关闭', this.fileList);
            },
            //文件上传成功时
            importData_handleAvatarSuccess(res, file) {//批量导入
                console.log(res,file);
            },
            //点击文件列表中已上传的文件时
            handlePreview(val) {
                console.log('成功', val);
            },
            //文件列表移除文件时
            handleRemove(val) {
                console.log("失败", val);
            },
            //文件状态改变时的钩子
            handleChange(file, fileList) {
                console.log(file);
                console.log(fileList);
                this.$emit('change')
                this.fileList = fileList;
                fileList.forEach(item => {
                    //文件信息中raw才是真的文件
                    this.name = item.name
                    this.list_show = item.raw
                })
                //转化为blob
                let reader = new FileReader()
                reader.onload = (e) => {
                    console.log(e);
                    let data
                    if (typeof e.target.result === 'object') {
                        data = window.URL.createObjectURL(new Blob([e.target.result]))
                    } else {
                        data = e.target.result
                    }
                    this.option.img = data
                }
                reader.readAsDataURL(this.list_show)
                console.log(this.list_show);
                this.outerVisible = true
            },
            //上一步
            onStep() {
                this.$emit('top')
                this.outerVisible = false
            },
            async submitUpload() {
                this.loading = true
                let that = this;
                this.$refs.cropper.getCropBlob(async (data) => {
                    let formData = new FormData();
                    formData.append('file', data)
                    let file = formData.get("file");
                    let reader = new FileReader();
                    that.imgSrc_obj = []
                    reader.onload = function () {
                        // base64结果
                        that.imgSrc = this.result;
                        that.imgSrc_obj.push(this.result)
                        // that.loading = false;
                    };
                    reader.readAsDataURL(file);
                    console.log(formData,reader,file);
                    console.log(reader,'<<<reader');
                    console.log(file,'<<file')
                    //这里用你自己的上传接口
                    // let resData = await apiUploadImg(formData);
                    // if (resData.data.code === 200) {
                    //   this.outerVisible = false;
                    // }else{
                      // 上传失败后清除 等等 根据自己的代码去修改
                      // that.imgSrc = ''
                      // that.imgSrc_obj = []
                    // }
                    this.outerVisible = false
                })
            },
            //初始化函数
            imgLoad(msg) {
                console.log("工具初始化函数=====" + msg)
                console.log(this.option);
            },
            //实时预览函数
            realTime(data) {
                // this.previews = data
                // console.log(this.previews);
                const that = this
                this.$refs.cropper.getCropBlob(data => {
                    // 这里data数据为Blob类型，blobToDataURI方法转换成base64
                    //     console.log(data)
                    this.blobToDataURI(data, function (res) {
                        that.previewImg = res
                    })
                })

            },
            blobToDataURI(blob, callback) {
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onload = function (e) {
                    callback(e.target.result);
                }
            },
            expurgate() {
                console.log('点击删除');
                this.imgSrc = ''
                this.imgSrc_obj = []
                this.fileList = []

            },
            enlargement() {
                console.log('点击浏览大图');
                this.showViewer = true

            },
            closeViewer() {
                this.showViewer = false
                console.log('关闭浏览大图');
            },
        }

    }
</script>

<style scoped lang="scss">
    //设置裁剪框样式
    .cropper-content {
        display: flex;
        justify-content: space-evenly;
        .cropper {
            width: 50%;
            height: 300px;

            .vue-cropper {
                height: 84%;
            }
        }
        .preview{
            width: 200px;
            height: 200px;
           img{
               object-fit: cover;
               border-radius: 50%;
               height: 150px;
               width: 150px;
               margin-left: 20px;
           }
            div{
                text-align: center;
                margin-top: 20px;
                font-size: 16px;
            }
        }
    }

    .footer-btn {
        .scope-btn {
            display: flex;
            justify-content: space-between;
            padding-right: 10px;
        }

        .upload-btn {
            // flex: 1;
            // -webkit-flex: 1;
            // display: flex;
            // display: -webkit-flex;
            // justify-content: center;
        }

        .btn {
            outline: none;
            display: inline-block;
            line-height: 1;
            white-space: nowrap;
            cursor: pointer;
            -webkit-appearance: none;
            text-align: center;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            outline: 0;
            -webkit-transition: .1s;
            transition: .1s;
            font-weight: 500;
            padding: 8px 15px;
            font-size: 12px;
            border-radius: 3px;
            color: #fff;
            background-color: #409EFF;
            border-color: #409EFF;
            margin-right: 10px;
        }
    }
.picture{
    height: 148px;
}
    .upload-text, .upload-icon, .el-image, .img_mc {
        width: 100%;
        height: 100%;
    }

    .upload-plus {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        position: relative;

        i {
            position: absolute;
            /*top: 40%;*/
            top: 60px;
        }

        div {
            position: absolute;
            top: 20px
        }
    }

    .remarks {
        position: absolute;
        left: 44%;
        top: 40%;

        div {
            height: 30px;
        }
    }

    .upload-icon {
        position: relative;
        z-index: 999;

        .img_mc {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            cursor: default;
            text-align: center;
            color: #fff;
            opacity: 0;
            font-size: 20px;
            background-color: rgba(0, 0, 0, .5);
            -webkit-transition: opacity .3s;
            transition: opacity .3s;

            span {
                display: none;
                cursor: pointer;

                i {
                    color: #fff;
                    font-size: 22px;
                }
            }

            span:nth-child(2) {
                margin-left: 25%;
            }
        }

        .img_mc:hover {
            opacity: 1;

        }

        .img_mc:hover span {
            display: inline-block;
        }
    }
</style>
