var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "systemLog" }, [
    _c("div", { staticClass: "title" }, [_vm._v("系统访问日志")]),
    _c(
      "div",
      [
        _c("occ-table", {
          attrs: {
            tableData: _vm.tableData,
            columObj: _vm.columObj,
            "style-height": "220",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }