import request from '@/utils/request'
import {
    GET_FINANCE_HEAD,
    METHOD,
    GET_FINANCE_ADDRESS,
    GET_HISTORY_LIST,
    GET_FINANCE_GETVOICABLEAMOUNT,
    GET_FINANCE_INVOICES_RETURN,
    GET_FINANCE_INVOICES_LIST,
    GET_FINANCE_INVOICES_CANCEL
} from '@/api/api.config'

//获取发票管理。可开票金额接口
export function apiFinanceAmount(data) {
    return request({
        url: GET_FINANCE_GETVOICABLEAMOUNT,
        method: METHOD.GET,
        params: data,
    })
}

//获取发票管理。发票抬头信息接口
export function apiFinanceHead(data) {
    return request({
        url: GET_FINANCE_HEAD,
        method: METHOD.GET,
        params: data,
    })
}

//获取发票管理。获取默认寄送地址接口
export function apiFinanceAddress(data) {
    return request({
        url: GET_FINANCE_ADDRESS,
        method: METHOD.GET,
        params: data,
    })
}

//获取发票管理。获取开票历史接口
export function apiHistoryList(data) {
    return request({
        url: GET_HISTORY_LIST,
        method: METHOD.POST,
        data,
    })
}
//获取发票管理。获取开票记录列表接口
export function apiInvoicesList(data) {
    return request({
        url: GET_FINANCE_INVOICES_LIST,
        method: METHOD.POST,
        data,
    })
}
//获取发票管理。获取开票记录退票接口
export function apiReturnList(data) {
    return request({
        url: GET_FINANCE_INVOICES_RETURN,
        method: METHOD.POST,
        data,
    })
}
//获取发票管理。获取开票记录退票接口
export function apiCancel(data) {
    return request({
        url: GET_FINANCE_INVOICES_CANCEL,
        method: METHOD.POST,
        data,
    })
}