export const rule = [
  {
    id: 100,
    pid: 0,
    url: '',
    name: 'Home',
    type: 1,
    path: '/',
    menu_type: 'M',
    order_num: 0,
    icon: '',
    menu_name: '首页',
    component: 'Home',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 200,
    pid: 0,
    url: '',
    name: 'Business',
    type: 1,
    path: '/business',
    menu_type: 'M',
    order_num: 0,
    icon: '',
    menu_name: '业务管理',
    component: 'business',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 300,
    pid: 0,
    url: '',
    name: 'AccountCenter',
    type: 1,
    path: 'accountCenter',
    menu_type: 'M',
    order_num: 3,
    icon: '',
    menu_name: '账号中心',
    component: 'Layout',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 400,
    pid: 0,
    url: '',
    name: 'Success',
    type: 1,
    path: '/successPay',
    menu_type: 'M',
    order_num: 0,
    icon: '',
    menu_name: '支付成功',
    component: 'pay/successPay',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 500,
    pid: 0,
    url: '',
    name: 'MoneyCenter',
    type: 1,
    path: 'moneyCenter',
    menu_type: 'M',
    order_num: 2,
    icon: '',
    menu_name: '财务中心',
    component: 'Layout',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 600,
    pid: 0,
    url: '',
    name: 'MsgCenter',
    type: 1,
    path: 'msgCenter',
    menu_type: 'M',
    order_num: 2,
    icon: '',
    menu_name: '消息中心',
    component: 'Layout',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 700,
    pid: 0,
    url: '',
    name: 'OrderPayment',
    type: 1,
    path: '/orderPayment',
    menu_type: 'M',
    order_num: 2,
    icon: '',
    menu_name: '支付页面',
    component: 'pay/orderPayment',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 800,
    pid: 0,
    url: '',
    name: 'WechatPay',
    type: 1,
    path: 'wechatPay',
    menu_type: 'M',
    order_num: 2,
    icon: '',
    menu_name: '消息中心',
    component: 'pay/wechatPay',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 1000,
    pid: 0,
    url: '',
    name: 'EvaluationActivity',
    type: 1,
    path: '/evaluationActivity',
    menu_type: 'M',
    order_num: 0,
    icon: '',
    menu_name: '测评活动',
    component: 'evaluationActivity',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 1050,
    pid: 0,
    url: '',
    name: 'icpManagement',
    type: 1,
    path: '/icpManagement',
    menu_type: 'M',
    order_num: 0,
    icon: '',
    menu_name: 'icp管理',
    component: 'icpManagement',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 1100,
    pid: 0,
    url: '',
    name: 'PriceDesc',
    type: 1,
    path: 'evaluationActivity/priceDesc',
    menu_type: 'M',
    order_num: 0,
    icon: '',
    menu_name: '报价详情',
    component: 'evaluationActivity/priceDesc',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 301,
    pid: 300,
    url: '',
    name: 'BsicInfo',
    type: 1,
    path: 'basicInfo',
    menu_type: 'C',
    order_num: 0,
    icon: 'za-basicInfo',
    menu_name: '基本信息',
    component: 'accountCenter/basicInfo',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 302,
    pid: 300,
    url: '',
    name: 'SafetySet',
    type: 1,
    path: 'safetySet',
    menu_type: 'C',
    order_num: 1,
    icon: 'za-setting',
    menu_name: '安全设置',
    component: 'accountCenter/safetySet',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 303,
    pid: 300,
    url: '',
    name: 'NameCertification',
    type: 1,
    path: 'nameCertification',
    menu_type: 'C',
    order_num: 1,
    icon: 'za-nameCertification',
    menu_name: '实名认证',
    component: 'accountCenter/nameCertification',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  // {
  //     "id": 304,
  //     "pid": 300,
  //     "url": "",
  //     "name": "SystemMsg",
  //     "type": 1,
  //     "path": "systemMsg",
  //     "menu_type": "C",
  //     "order_num": 1,
  //     "icon": "",
  //     "menu_name": "系统消息",
  //     "component": "accountCenter/systemMsg",
  //     "perms": "",
  //     "no_cache": true,
  //     "hidden": false,
  //     "children_name": "",
  //     "parent_name": ""
  // },
  {
    id: 305,
    pid: 300,
    url: '',
    name: 'LoginLog',
    type: 1,
    path: 'loginLog',
    menu_type: 'C',
    order_num: 1,
    icon: 'za-loginLog',
    menu_name: '登录日志',
    component: 'accountCenter/loginLog',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  // {
  //     "id": 306,
  //     "pid": 300,
  //     "url": "",
  //     "name": "PartyLogin",
  //     "type": 1,
  //     "path": "partyLogin",
  //     "menu_type": "C",
  //     "order_num": 1,
  //     "icon": "za-partyLogin",
  //     "menu_name": "第三方登录",
  //     "component": "accountCenter/partyLogin",
  //     "perms": "",
  //     "no_cache": true,
  //     "hidden": false,
  //     "children_name": "",
  //     "parent_name": ""
  // },
  {
    id: 310,
    pid: 302,
    url: '',
    name: 'verification',
    type: 1,
    path: 'safetySet/verification',
    menu_type: 'S',
    order_num: 1,
    icon: '',
    menu_name: '验证手机',
    component: 'accountCenter/safetySet/verification',
    perms: '',
    no_cache: true,
    hidden: true,
    children_name: '',
    parent_name: '',
  },
  {
    id: 311,
    pid: 310,
    url: '',
    name: 'Modify',
    type: 1,
    path: 'safetySet/verification/modify',
    menu_type: 'S',
    order_num: 1,
    icon: '',
    menu_name: '修改手机',
    component: 'accountCenter/safetySet/verification/modify',
    perms: '',
    no_cache: true,
    hidden: true,
    children_name: '',
    parent_name: '',
  },
  {
    id: 550,
    pid: 500,
    url: '',
    name: 'moneyCenterOverview',
    type: 1,
    path: 'overview',
    menu_type: 'C',
    order_num: 0,
    icon: 'za-sidebar-all',
    menu_name: '财务总览',
    component: 'moneyCenter/overview',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 510,
    pid: 500,
    url: '',
    name: 'ExpenseManagement',
    type: 1,
    path: 'expenseManagement',
    menu_type: 'M',
    order_num: 0,
    icon: 'za-expenseManagement',
    menu_name: '费用管理',
    component: '',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 520,
    pid: 500,
    url: '',
    name: 'CardRoll',
    type: 1,
    path: 'cardRoll',
    menu_type: 'M',
    order_num: 0,
    icon: 'za-cardRoll',
    menu_name: '卡券管理',
    component: '',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 530,
    pid: 500,
    url: '',
    name: 'InvoiceManagement',
    type: 1,
    path: 'invoiceManagement',
    menu_type: 'M',
    order_num: 0,
    icon: 'za-sidebar-fapiao',
    menu_name: '发票管理',
    component: '',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 540,
    pid: 500,
    url: '',
    name: 'OrderManagement',
    type: 1,
    path: 'orderManagement',
    menu_type: 'M',
    order_num: 0,
    icon: 'za-sidebar-dingdan',
    menu_name: '订单管理',
    component: '',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },

  {
    id: 511,
    pid: 510,
    url: '',
    name: 'TopUp',
    type: 1,
    path: 'topUp',
    menu_type: 'C',
    order_num: 0,
    icon: '',
    menu_name: '在线预充值',
    component: 'moneyCenter/expenseManagement/topUp',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 512,
    pid: 510,
    url: '',
    name: 'Details',
    type: 1,
    path: 'details',
    menu_type: 'C',
    order_num: 0,
    icon: '',
    menu_name: '交易流水',
    component: 'moneyCenter/expenseManagement/details',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 513,
    pid: 510,
    url: '',
    name: 'MoneyExtract',
    type: 1,
    path: 'moneyExtract',
    menu_type: 'C',
    order_num: 0,
    icon: '',
    menu_name: '余额提现',
    component: 'moneyCenter/expenseManagement/moneyExtract',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 514,
    pid: 510,
    url: '',
    name: 'RechargeRecord',
    type: 1,
    path: 'rechargeRecord',
    menu_type: 'C',
    order_num: 0,
    icon: '',
    menu_name: '充值记录',
    component: 'moneyCenter/expenseManagement/rechargeRecord',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 521,
    pid: 520,
    url: '',
    name: 'Voucher',
    type: 1,
    path: 'voucher',
    menu_type: 'C',
    order_num: 0,
    icon: '',
    menu_name: '代金券',
    component: 'moneyCenter/cardRoll/voucher',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 522,
    pid: 520,
    url: '',
    name: 'Coupon',
    type: 1,
    path: 'coupon',
    menu_type: 'C',
    order_num: 0,
    icon: '',
    menu_name: '折扣券',
    component: 'moneyCenter/cardRoll/coupon',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 531,
    pid: 530,
    url: '',
    name: 'Askfor',
    type: 1,
    path: 'askfor',
    menu_type: 'C',
    order_num: 0,
    icon: '',
    menu_name: '发票索取',
    component: 'moneyCenter/invoiceManagement/askfor',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 535,
    pid: 531,
    url: '',
    name: 'Info',
    type: 1,
    path: 'askfor/info',
    menu_type: 'S',
    order_num: 0,
    icon: '',
    menu_name: '开票记录',
    component: 'moneyCenter/invoiceManagement/askfor/info',
    perms: '',
    no_cache: true,
    hidden: true,
    children_name: '',
    parent_name: '',
  },
  {
    id: 533,
    pid: 531,
    url: '',
    name: 'DrawAbill',
    type: 1,
    path: 'askfor/drawAbill',
    menu_type: 'S',
    order_num: 0,
    icon: '',
    menu_name: '申请开票',
    component: 'moneyCenter/invoiceManagement/askfor/drawAbill',
    perms: '',
    no_cache: true,
    hidden: true,
    children_name: '',
    parent_name: '',
  },
  {
    id: 534,
    pid: 533,
    url: '',
    name: 'DrawInfo',
    type: 1,
    path: 'askfor/drawAbill/drawInfo',
    menu_type: 'S',
    order_num: 0,
    icon: '',
    menu_name: '确认开票',
    component: 'moneyCenter/invoiceManagement/askfor/drawAbill/drawInfo',
    perms: '',
    no_cache: true,
    hidden: true,
    children_name: '',
    parent_name: '',
  },
  {
    id: 532,
    pid: 530,
    url: '',
    name: 'Address',
    type: 1,
    path: 'address',
    menu_type: 'C',
    order_num: 0,
    icon: '',
    menu_name: '发票信息',
    component: 'moneyCenter/invoiceManagement/address',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 541,
    pid: 540,
    url: '',
    name: 'MyOrder',
    type: 1,
    path: 'myOrder',
    menu_type: 'C',
    order_num: 0,
    icon: '',
    menu_name: '我的订单',
    component: 'moneyCenter/orderManagement/myOrder',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 542,
    pid: 541,
    url: '',
    name: 'myOrderDetails',
    type: 1,
    path: 'myOrder/details',
    menu_type: 'S',
    order_num: 0,
    icon: '',
    menu_name: '订单详情',
    component: 'moneyCenter/orderManagement/myOrder/details',
    perms: '',
    no_cache: true,
    hidden: true,
    children_name: '',
    parent_name: '',
  },
  // {
  //     "id":543,
  //     "pid": 540,
  //     "url": "",
  //     "name": "Renewal",
  //     "type": 1,
  //     "path": "renewal",
  //     "menu_type": "C",
  //     "order_num": 0,
  //     "icon": "",
  //     "menu_name": "续费管理",
  //     "component": "moneyCenter/orderManagement/renewal",
  //     "perms": "",
  //     "no_cache": true,
  //     "hidden": false,
  //     "children_name": "",
  //     "parent_name": ""
  // },
  // {
  //     "id":544,
  //     "pid": 543,
  //     "url": "",
  //     "name": "OrderConfirmation",
  //     "type": 1,
  //     "path": "renewal/orderConfirmation",
  //     "menu_type": "S",
  //     "order_num": 0,
  //     "icon": "",
  //     "menu_name": "续费管理",
  //     "component": "moneyCenter/orderManagement/renewal/orderConfirmation",
  //     "perms": "",
  //     "no_cache": true,
  //     "hidden": true,
  //     "children_name": "",
  //     "parent_name": ""
  // },
  {
    id: 610,
    pid: 600,
    url: '',
    name: 'StationMsg',
    type: 1,
    path: 'stationMsg',
    menu_type: 'M',
    order_num: 0,
    icon: 'za-stationMsg',
    menu_name: '站内消息',
    component: '',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 620,
    pid: 600,
    url: '',
    name: 'MsgManagement',
    type: 1,
    path: 'msgManagement',
    menu_type: 'M',
    order_num: 0,
    icon: 'za-msgManagement',
    menu_name: '消息接收管理',
    component: '',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 611,
    pid: 610,
    url: '',
    name: 'Allmsg',
    type: 1,
    path: 'allmsg',
    menu_type: 'C',
    order_num: 0,
    icon: '',
    menu_name: '全部消息',
    component: 'msgCenter/stationMsg/allMsg',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 612,
    pid: 610,
    url: '',
    name: 'UnreadMsg',
    type: 1,
    path: 'unreadMsg',
    menu_type: 'C',
    order_num: 0,
    icon: '',
    menu_name: '未读消息',
    component: 'msgCenter/stationMsg/unreadMsg',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 613,
    pid: 610,
    url: '',
    name: 'ReadMsg',
    type: 1,
    path: 'readMsg',
    menu_type: 'C',
    order_num: 0,
    icon: '',
    menu_name: '已读消息',
    component: 'msgCenter/stationMsg/readMsg',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 621,
    pid: 620,
    url: '',
    name: 'MsgPersonnel',
    type: 1,
    path: 'msgPersonnel',
    menu_type: 'C',
    order_num: 0,
    icon: '',
    menu_name: '消息接收设置',
    component: 'msgCenter/msgManagement/msgPersonnel',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  // {
  //     "id":622,
  //     "pid": 620,
  //     "url": "",
  //     "name": "MsgSet",
  //     "type": 1,
  //     "path": "msgSet",
  //     "menu_type": "C",
  //     "order_num": 0,
  //     "icon": "",
  //     "menu_name": "接收人员管理",
  //     "component": "msgCenter/msgManagement/msgSet",
  //     "perms": "",
  //     "no_cache": true,
  //     "hidden": false,
  //     "children_name": "",
  //     "parent_name": ""
  // },
  //这里是id-612 改为613
  {
    id: 613,
    pid: 611,
    url: '',
    name: 'allMsgetails',
    type: 1,
    path: 'allMsg/details',
    menu_type: 'S',
    order_num: 0,
    icon: '',
    menu_name: '消息详情',
    component: 'msgCenter/stationMsg/allMsg/details',
    perms: '',
    no_cache: true,
    hidden: true,
    children_name: '',
    parent_name: '',
  },
]
export const individualRule = [
  {
    id: 900,
    pid: 0,
    url: '',
    name: 'PromotionManagement',
    type: 1,
    path: 'promotionManagement',
    menu_type: 'M',
    order_num: 2,
    icon: '',
    menu_name: '推广管理',
    component: 'Layout',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 910,
    pid: 900,
    url: '',
    name: 'promotionManagementOverview',
    type: 1,
    path: 'overview',
    menu_type: 'C',
    order_num: 0,
    icon: 'za-individual-overView',
    menu_name: '推广总览',
    component: 'promotionManagement/overview',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 920,
    pid: 900,
    url: '',
    name: 'Effect',
    type: 1,
    path: 'effect',
    menu_type: 'M',
    order_num: 0,
    icon: 'za-individual-effect',
    menu_name: '推广效果',
    component: '',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 921,
    pid: 920,
    url: '',
    name: 'Correlation',
    type: 1,
    path: 'correlation',
    menu_type: 'C',
    order_num: 0,
    icon: '',
    menu_name: '关联客户',
    component: 'promotionManagement/effect/correlation',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 922,
    pid: 920,
    url: '',
    name: 'BackOrder',
    type: 1,
    path: 'backOrder',
    menu_type: 'C',
    order_num: 0,
    icon: '',
    menu_name: '返佣订单',
    component: 'promotionManagement/effect/backOrder',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 922,
    pid: 920,
    url: '',
    name: 'SettlementDetails',
    type: 1,
    path: 'settlementDetails',
    menu_type: 'C',
    order_num: 0,
    icon: '',
    menu_name: '结算明细',
    component: 'promotionManagement/effect/settlementDetails',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 930,
    pid: 900,
    url: '',
    name: 'Center',
    type: 1,
    path: 'center',
    menu_type: 'C',
    order_num: 0,
    icon: 'za-individual-center',
    menu_name: '推广中心',
    component: 'promotionManagement/center',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
]
export const companyRule = [
  {
    id: 900,
    pid: 0,
    url: '',
    name: 'CompanyManagement',
    type: 1,
    path: 'companyManagement',
    menu_type: 'M',
    order_num: 2,
    icon: '',
    menu_name: '推广管理',
    component: 'Layout',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 910,
    pid: 900,
    url: '',
    name: 'Overview',
    type: 1,
    path: 'overview',
    menu_type: 'C',
    order_num: 0,
    icon: '',
    menu_name: '推广总览',
    component: 'companyManagement/overview',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 920,
    pid: 900,
    url: '',
    name: 'Effect',
    type: 1,
    path: 'effect',
    menu_type: 'M',
    order_num: 0,
    icon: '',
    menu_name: '推广效果',
    component: '',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 921,
    pid: 920,
    url: '',
    name: 'Correlation',
    type: 1,
    path: 'correlation',
    menu_type: 'C',
    order_num: 0,
    icon: '',
    menu_name: '关联客户',
    component: 'companyManagement/effect/correlation',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 922,
    pid: 920,
    url: '',
    name: 'BackOrder',
    type: 1,
    path: 'backOrder',
    menu_type: 'C',
    order_num: 0,
    icon: '',
    menu_name: '返佣订单',
    component: 'companyManagement/effect/backOrder',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
  {
    id: 930,
    pid: 900,
    url: '',
    name: 'Center',
    type: 1,
    path: 'center',
    menu_type: 'C',
    order_num: 0,
    icon: '',
    menu_name: '推广中心',
    component: 'companyManagement/center',
    perms: '',
    no_cache: true,
    hidden: false,
    children_name: '',
    parent_name: '',
  },
]
