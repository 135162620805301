<template>
    <div>
        <div>
            <navbar/>
        </div>
        <div v-if="url" v-za-loading="isLoading">
            <iframe :src="url" width="100%" frameborder="0" class="iframe" id="iframe"></iframe>
        </div>
    </div>
</template>
<script>
    import Navbar from '@/za/layout/components/Navbar'
    import {apiIcpinfo} from '@/api/icpManagement/index'
    export default {
        name: 'IcpManagement',
        components: {
            Navbar,
        },
        data() {
            return {
                url: null,
            }
        },
        created() {
            this.getUrl()
        },
        mounted() {
        },
        methods: {
            getUrl() {
                this.onSubmitData(apiIcpinfo, null, null, false, false, (res) => {
                    this.url = res.data.url
                })
            },
        }
    }
</script>
<style scoped lang="scss">
    .iframe {
        height: calc(#{$base-page-height} - #{$base-header-height} - 40px);;
    }

    .prompt {
        display: flex;
        height: 40px;
        background: #FFFBE6;
        border-radius: 2px;
        border: 1px solid #FFE58F;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 40px;
        margin-bottom: 20px;
        .za-waring{
            margin-left: 16px;
            margin-right: 10px;
        }
        .blue_font{
            margin-left: 10px;
            cursor: pointer;
        }
    }
</style>