var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c("za-go-back", {
          attrs: { title: "开票记录" },
          on: { click: _vm.goBack },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "za-loading",
            rawName: "v-za-loading",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
        staticClass: "info-container",
      },
      [
        _c(
          "za-query-form",
          [
            _c("za-query-form-left-panel", { attrs: { span: 4 } }, [
              _c("div", { staticClass: "success" }, [
                _c("span", [
                  _vm._v("已成功开具" + _vm._s(_vm.invoicesTotal) + "张发票"),
                ]),
                _c("span", [
                  _vm._v("（合计 " + _vm._s(_vm.invoicesAmount) + "元）"),
                ]),
              ]),
            ]),
            _c(
              "za-query-form-right-panel",
              { attrs: { span: 20 } },
              [
                _c(
                  "el-form",
                  {
                    attrs: { inline: true, model: _vm.pageObj },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "申请日期:", "label-width": "100px" } },
                      [
                        _c("el-date-picker", {
                          attrs: { type: "date", placeholder: "选择日期" },
                          on: { change: _vm.datePickEnd },
                          model: {
                            value: _vm.timerSelect,
                            callback: function ($$v) {
                              _vm.timerSelect = $$v
                            },
                            expression: "timerSelect",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "发票号：", "label-width": "100px" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder:
                              _vm.translateTitle("placeTxt") +
                              _vm.translateTitle("askfor.invoice"),
                          },
                          model: {
                            value: _vm.pageObj.invoicesNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.pageObj, "invoicesNumber", $$v)
                            },
                            expression: "pageObj.invoicesNumber",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "za-button",
                          {
                            attrs: { icon: _vm.buttonIconObj.SEARCH },
                            on: { click: _vm.handleQuery },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.translateTitle("btn.query")) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("occ-table", {
          attrs: {
            tableData: _vm.tableData,
            columObj: _vm.columObj,
            isOp: _vm.columObj.isOp,
          },
          on: { select: _vm.result },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ row }) {
                return [
                  _c(
                    "span",
                    {
                      staticClass: "blue_font",
                      on: {
                        click: function ($event) {
                          return _vm.details(row)
                        },
                      },
                    },
                    [_vm._v("详情")]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "blue_font",
                      on: {
                        click: function ($event) {
                          return _vm.refund(row)
                        },
                      },
                    },
                    [_vm._v("退票")]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "blue_font",
                      on: {
                        click: function ($event) {
                          return _vm.revocation(row)
                        },
                      },
                    },
                    [_vm._v("撤销申请")]
                  ),
                ]
              },
            },
          ]),
        }),
        _c("pagination", {
          attrs: {
            limit: _vm.pageObj.pageSize,
            page: _vm.pageObj.pageNo,
            total: _vm.total,
          },
          on: {
            "update:limit": function ($event) {
              return _vm.$set(_vm.pageObj, "pageSize", $event)
            },
            "update:page": function ($event) {
              return _vm.$set(_vm.pageObj, "pageNo", $event)
            },
            pagination: _vm.getList,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "za-dialog",
          {
            attrs: {
              "top-title": "发票详情",
              visible: _vm.detailsInfo,
              width: "600px",
              footState: false,
            },
            on: {
              "update:visible": function ($event) {
                _vm.detailsInfo = $event
              },
            },
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      disabled: _vm.confirmBtnLoading,
                      model: _vm.baseForm,
                      "label-position": "right",
                      "label-width": "140px",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "发票抬头" + "：" } },
                              [
                                _c("span", { staticClass: "tips" }, [
                                  _vm._v(
                                    _vm._s(_vm.baseForm.invoicesHead || "暂无")
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "发票金额" + "：" } },
                              [
                                _c("span", { staticClass: "tips" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.baseForm.invoicesAmount || "暂无"
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "发票类型" + "：" } },
                              [
                                _c("span", { staticClass: "tips" }, [
                                  _vm._v(
                                    _vm._s(_vm.baseForm.invoicesType || "暂无")
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "发票介质" + "：" } },
                              [
                                _c("span", { staticClass: "tips" }, [
                                  _vm._v(_vm._s(_vm.baseForm.medium || "暂无")),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "发票号码" + "：" } },
                              [
                                _c("span", { staticClass: "tips" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.baseForm.invoicesNumber || "暂无"
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "发票状态" + "：" } },
                              [
                                _c("span", { staticClass: "tips" }, [
                                  _vm._v(
                                    _vm._s(_vm.baseForm.invoicesState || "暂无")
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "邮寄信息" + "：" } },
                              [
                                _c("span", { staticClass: "tips" }, [
                                  _vm._v(
                                    _vm._s(_vm.baseForm.addressInfo || "暂无")
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "za-dialog",
          {
            attrs: {
              "top-title": "退票申请",
              visible: _vm.dialogVisible,
              width: "600px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogVisible = $event
              },
            },
          },
          [
            _c(
              "div",
              [
                _c("div", { staticClass: "prompt" }, [
                  _c(
                    "span",
                    { staticStyle: { "margin-left": "16px" } },
                    [
                      _c("za-svg-icon", {
                        attrs: { "icon-class": "za-wanning" },
                      }),
                    ],
                    1
                  ),
                  _c("span", [
                    _vm._v(
                      "温尊提示: 此操作一旦提交后无法撤撤回且需要您承担邮寄费用，请谨慎操作"
                    ),
                  ]),
                ]),
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      disabled: _vm.confirmBtnLoading,
                      model: _vm.returnForm,
                      "label-position": "right",
                      "label-width": "140px",
                      rules: _vm.rules,
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "退票金额" + "：" } },
                              [
                                _c("span", { staticClass: "refundMoney" }, [
                                  _vm._v(
                                    _vm._s(_vm.returnForm.money + "元" || "")
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "退票原因" + "：",
                                  prop: "returnType",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    model: {
                                      value: _vm.returnForm.returnType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.returnForm,
                                          "returnType",
                                          $$v
                                        )
                                      },
                                      expression: "returnForm.returnType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.serverMsgTypeOption,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "材料要求" + "：",
                                  prop: "mobile",
                                },
                              },
                              [
                                _c("div", { staticClass: "requirement" }, [
                                  _c("div", [
                                    _c("span", [
                                      _vm._v("请将 "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "14px",
                                            "font-weight": "500",
                                          },
                                        },
                                        [_vm._v("发票原件")]
                                      ),
                                      _vm._v(" 邮寄给"),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "requireMain" }, [
                                    _c("div", [
                                      _c("span", [_vm._v("收件人：")]),
                                      _c("span", [_vm._v("王兰")]),
                                    ]),
                                    _c("div", [
                                      _c("span", [_vm._v("电话：")]),
                                      _c("span", [
                                        _vm._v("010-62671188-839840"),
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          "该联系方式仅供寄件使用，如需业务咨询，请拨打 4009100100"
                                        ),
                                      ]),
                                    ]),
                                    _c("div", [
                                      _c("span", [_vm._v("地址：")]),
                                      _c("span", [
                                        _vm._v(
                                          "北京市海淀区知春路49号 希格玛大厦B1 腾讯邮件中心"
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "退票快递公司" + "：", required: "" } },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "textarea" } },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "请填写快递公司" },
                                      model: {
                                        value: _vm.returnForm.textarea,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.returnForm,
                                            "textarea",
                                            $$v
                                          )
                                        },
                                        expression: "returnForm.textarea",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("el-col", { attrs: { span: 2 } }, [_vm._v(" ")]),
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "textarea" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请填写寄出的快递单号",
                                      },
                                      model: {
                                        value: _vm.returnForm.textarea,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.returnForm,
                                            "textarea",
                                            $$v
                                          )
                                        },
                                        expression: "returnForm.textarea",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "发票号码" + "：",
                                  prop: "desc",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    rows: 4,
                                    placeholder: "请输入内容",
                                  },
                                  model: {
                                    value: _vm.returnForm.desc,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.returnForm, "desc", $$v)
                                    },
                                    expression: "returnForm.desc",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { attrs: { slot: "footer" }, slot: "footer" },
              [
                _c(
                  "za-button",
                  {
                    attrs: {
                      disabled: _vm.confirmBtnLoading,
                      type: _vm.buttonTypeObj.GREY,
                    },
                    on: {
                      click: function ($event) {
                        _vm.dialogVisible = false
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.translateTitle("btn.cancel")) + " ")]
                ),
                _c(
                  "za-button",
                  {
                    attrs: {
                      loading: _vm.confirmBtnLoading,
                      type: _vm.buttonTypeObj.BLUE,
                    },
                    on: { click: _vm.handleSubmitForm },
                  },
                  [_vm._v(" " + _vm._s(_vm.translateTitle("btn.submit")) + " ")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }