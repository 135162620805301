<template>
    <div class="bread">
        <za-bg-frame v-if="show">
            <div class="container">
                <el-breadcrumb separator="/" style="margin-left: 5px">
                    <el-breadcrumb-item v-for="(item, index) in breadcrumbList" :key="index">
                        {{ item.meta.title }}
                    </el-breadcrumb-item>
                </el-breadcrumb>
                <div class="cur-item">{{ breadcrumbList.slice(-1)[0].meta.title }}</div>
            </div>
        </za-bg-frame>
        <za-bg-frame v-else>
            <div class="second-container">
                <div class="left">
                    <div class="cur-item">{{ breadcrumbList.length > 1 ? breadcrumbList.slice(-1)[0].meta.title + ip :''
                        }}
                    </div>
                    <div class="line"></div>
                </div>
            </div>
        </za-bg-frame>
    </div>
</template>

<script>
    import ZaBgFrame from '@/za/components/zaBgFrame'
    import {mapGetters} from 'vuex'
    import {handleMatched} from '@/utils/routes'
    import {BASE_EVENT} from "@/za/plugins/event";
    import {getCache, setCache} from "@/utils/localCache";
    import zh from '@/i18n/zh'

    export default {
        name: 'BreadRoute',
        components: {ZaBgFrame},
        data() {
            return {
                show: false,
                isShow: false,
                selectNodeId: 0,
                nodeOption: [],
                module: '',
                ip: ''
            }
        },
        computed: {
            ...mapGetters({
                routes: 'routes/routes',
                userInfo: 'user/userInfo',
            }),
            breadcrumbList() {
                // console.log(handleMatched(this.routes, this.$route.name))
                return handleMatched(this.routes, this.$route.name)
            },

        },
        watch: {
            $route() {
                this.isShow = false
            },
        },
        created() {
            if (this.$route.query.ip) this.ip = `（${this.$route.query.ip}）`
        },
        beforeUpdate() {
            if (this.$route.query.ip) this.ip = `（${this.$route.query.ip}）`
            else this.ip = ''
        },
        beforeDestroy() {
            this.ip = ''
        },
        methods: {},
    }
</script>

<style lang="scss" scoped>
    @import '~@/za/styles/mixin.scss';

    ::v-deep {
        .el-breadcrumb__item {
            font-size: $base-font-size;
            font-weight: $base-font-weight-400;
        }

        .el-breadcrumb__inner {
            color: $base-font-color-grey-69;
            font-weight: $base-font-weight-300;
        }

        .el-breadcrumb__item:last-child .el-breadcrumb__inner,
        .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
            font-weight: 400;
            color: $base-font-color-white-6f;
        }
    }

    .bread {
        width: 100%;
        height: $base-route-height !important;

        .container {
            @include flex_div($dir: column, $just: space-between, $align: flex-start, $padding: 20px 35px);

            .cur-item {
                color: $base-font-color-white-6f;
                @include flex_div($just: flex-start, $padding: 0);
                height: 20px;
                font-size: $base-title-font-size;
                font-weight: 500;

                &:before {
                    content: '';
                    width: 3px;
                    height: 13px;
                    background: $base-color-blue;
                    margin-right: 12px;
                }
            }
        }

        .second-container {
            @include flex_div($just: space-between, $padding: 40px 40px);

            .left {
                width: 100%;

                .cur-item {
                    width: 100%;
                    height: 40px;
                    color: $base-font-color-black-60;
                    font-size: 20px;
                    font-weight: 500;
                    border-bottom: 1px solid rgba(248, 248, 248, 1);
                }

                .line {
                    width: 7%;
                    border-bottom: 2px solid blue;
                }

            }
        }
    }
</style>
