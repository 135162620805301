<template>
  <div>
    <div class="ignore_center">
      <span>
        Copyright©2023 深圳市智安网络有限公司 All Rights Reve 版权所有
        《中华人民共和国增值电信业务经营许可证》编号：B1-20190666
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" class="blue_font">粤ICP备18027011号</a>
      </span>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {}
    },
  }
</script>
<style lang="scss" scoped>
  .ignore_center {
    width: 100%;
    min-width: 1220px;
    background: #ffffff;
    text-align: center;
    height: 60px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 60px;
    position: absolute;
    bottom: 0;
  }
</style>
