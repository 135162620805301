import request from '@/utils/request'
import { GET_FINANCE_GETMONTHINVOICES,METHOD,GET_FINANCE_GETORDERINVOICES,SECURE_SUBMITFORM,SECURE_SUBMITORDER,GET_FINANCE_GETMONTHINVOICEDETAIL} from  '@/api/api.config'
//获取申请开票，按月份开票接口
export function apiGetMonthInvoices(data) {
    return request({
        url:  GET_FINANCE_GETMONTHINVOICES,
        method: METHOD.GET,
        params: data,
    })
}

//获取申请开票，按订单开票接口
export function apiGetOrderInvoices(data) {
    return request({
        url:  GET_FINANCE_GETORDERINVOICES,
        method: METHOD.GET,
        params: data,
    })
}
//获取申请开票，按月份提交接口
export function apiubmitForm(data) {
    return request({
        url:  SECURE_SUBMITFORM,
        method: METHOD.POST,
         data,
    })
}
//获取申请开票，按订单提交接口
export function apiubmitOrder(data) {
    return request({
        url:  SECURE_SUBMITORDER,
        method: METHOD.POST,
        data,
    })
}

//获取申请开票，开票明细
export function apiGetOrderDetail(data) {
    return request({
        url:  GET_FINANCE_GETMONTHINVOICEDETAIL,
        method: METHOD.GET,
        params: data,
    })
}

