<template>
  <div class="eChart-div">
    <div ref="slotDiv" class="slot_st" :style="[{ width: getWidth }]">
      <slot />
    </div>
    <div :ref="compName" :style="[{ width: getWidth }, { height: getHeight }]" />
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { getAutoSize } from '@/utils'
import { addListener, removeListener } from 'resize-detector'

export default {
  name: 'EChartsContain',
  props: {
    compName: {
      type: String,
      default: 'echart-box',
    },
    option: {
      type: Object,
      default: () => { },
    },
    boxWidth: {
      type: [Number,String],
      default: 600,
    },
    boxHeight: {
      type: Number,
      default: 400,
    },
  },
  data() {
    return {
      width: this.boxWidth,
      height: this.boxHeight,
    }
  },
  computed: {
    getWidth() {
      if(typeof this.boxWidth=='number'){
        return getAutoSize(this.boxWidth) + 'px'
      }else{
        return this.boxWidth
      }

    },
    getHeight() {
      return getAutoSize(this.boxHeight) + 'px'
    },
  },
  watch: {
    option: {
      handler(nVal) {
        nVal.tooltip.backgroundColor = 'rgba(35,39,42,0.8)'
        nVal.tooltip.borderColor = 'rgba(35,39,42,0.8)'
        nVal.tooltip.textStyle = {
          color: '#fff',
        }
        if (this.mapChart) {
          this.mapChart.setOption(nVal)
          this.resize()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    addListener(this.$el, this.resize)
    this.initECharts()
  },
  beforeDestroy() {
    removeListener(this.$el, this.resize)
    if (this.mapChart.dispose) this.mapChart.dispose()
  },
  methods: {
    initECharts() {
      this.mapChart = echarts.init(this.$refs[this.compName])
      this.mapChart.setOption(this.option)
    },

    resize() {
      if (this.mapChart) {
        let tempBox = this.$refs[this.compName]
        tempBox.style.width = getAutoSize(this.boxWidth) + 'px'
        tempBox.style.height = getAutoSize(this.boxHeight) + 'px'
        this.mapChart.resize()
        if (this.$refs.slotDiv) this.$refs.slotDiv.style.width = getAutoSize(this.boxWidth) + 'px'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/za/styles/mixin.scss';

.eChart-div {
  @include flex_div($dir: column, $padding: 0);

  .slot_st {
    height: auto;
    text-align: right;
  }
}
</style>
