<template>
    <div class="partyLogin-center_bg">
        <div class="title">
            {{title}}
        </div>
        <div class="main">
            <div v-for="(item,index) in list" :key="index">
                <div class="center">
                    <div>
                        <za-svg-icon class-name="icon-show" :icon-class="item.state?item.icon:item.noneIcon"></za-svg-icon>
                        <span>{{item.name}}</span>
                        <span :class="{'notBind':!item.state}" class="bind">{{item.state?'[已绑定]':'[未绑定]'}}</span>
                    </div>
                    <div>
                        <span class="state" @click="handleChange(item)">{{item.state?'解绑':'立即绑定'}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        name: 'PartyLogin',
        data() {
            return {
                list: [
                    {name: 'QQ登录', icon: 'za-qq',noneIcon:'za-none-qq', state: false},
                    {name: '微信登录', icon: 'za-wechat',noneIcon:'za-none-wechat', state: false},
                    {name: '新浪微博', icon: 'za-microblog',noneIcon:'za-none-microblog', state: false},
                    {name: '百度帐号登录', icon: 'za-baidu',noneIcon:'za-none-baidu', state: false},
                    {name: '支付宝账号登录', icon: 'za-login-payBaby',noneIcon:'za-none-payBaby', state: false},
                ],
                title: ''
            }
        },
        created() {
            this.title = this.$route.meta.title
        },
        methods:{
            handleChange(item){
                console.log(item)
            }
        }
    }
</script>
<style scoped lang="scss">
    @import '~@/za/styles/mixin.scss';

    .title {
        @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
        width: 100%;
        height: 40px;
        margin-bottom: 20px;
        background-color: #FFFFFF;
        padding: 10px;
    }

    .main {
        background-color: #FFFFFF;
        padding: 20px;

        .center {
            display: flex;
            justify-content: space-between;
            height: 70px;
            border-bottom: 1px solid rgba(248, 248, 248, 1);
            line-height: 70px;
            font-size: 12px;
            color: #000000;

            .icon-show {
                font-size: 26px;
                margin-right: 10px;
                color: #999999;
                vertical-align: middle;
            }

            .state {
                margin-right: 30px;
                color: #1B5DEB;
                cursor: pointer;
            }

            .bind {
                margin-left: 10px;
                color: #5BB943;
            }

            .notBind {
                color: red;
            }
        }
    }
</style>