import request from '@/utils/request'
import {MONEY_FINANCE_SETWARRING,
    METHOD,
    MONEY_FINANCE_NUMBER,
    MONEY_WAIT_RESOURCE,
    MONEY_GET_COUPONNUM
} from  '@/api/api.config'
//获取设置费用预警接口
export function apiSetWarring(data) {
    return request({
        url:  MONEY_FINANCE_SETWARRING,
        method: METHOD.POST,
      data,
    })
}
//获取待支付订单接口
export function apiSetNumber(data) {
    return request({
        url:  MONEY_FINANCE_NUMBER,
        method: METHOD.POST,
        data,
    })
}
//获取待续费资源接口
export function apiWaitResource(data) {
    return request({
        url:  MONEY_WAIT_RESOURCE,
        method: METHOD.POST,
        data,
    })
}
//获取优惠券可用数量接口
export function apiCouponNum(data) {
    return request({
        url:  MONEY_GET_COUPONNUM,
        method: METHOD.GET,
        params:data,
    })
}