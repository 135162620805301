<template>
    <div :class="[isHome ? 'navTop' : 'ignore_navTop']">
        <!--        左-->
        <div class="topInfo">
            <nav-drawer></nav-drawer>
            <!--右-->
            <div :class="[isHome ? 'operationContent' : 'ignore_operationContent']">
                <nav-setting :is-home="isHome"/>
            </div>
            <div class=" nav-item">
               <nav-finance :optionsTitle="'财务'"></nav-finance>
            </div>
            <!-- <div class=" nav-item">
               <nav-document :optionsTitle="'文档'" :options="documentList" @click="toNewPath"></nav-document>
            </div> -->
            <div class=" nav-item">
               <span @click="openIcpView">ICP备案</span>
            </div>
            <div class=" nav-item">
               <nav-msg></nav-msg>
            </div>
            <div class=" nav-item">
               <nav-ma></nav-ma>
            </div>
<!--            <div class="search">-->
<!--                <za-svg-icon class-name="search_btn" icon-class="za-home-search" @click="showInput"/>-->
<!--                <div class="search_input" ref="input">-->
<!--                    <el-input v-model="inputVal" placeholder="请输入内容"></el-input>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>

    import NavSetting from './NavSetting'
    import NavDocument from './NavDocument'
    import NavFinance from './NavFinance'
    import NavMsg from './NavMsg'
    import NavDrawer from './NavDrawer'
      import NavMa from './NavMa'

    export default {
        name: 'Navbar',
        components: {NavSetting, NavDocument, NavFinance, NavMsg,NavDrawer,NavMa},
        props: {
            isHome: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                inputVal: '',

                documentList: [
                    {id: 1, name: '产品文档', path: '/', icon: 'za-document'},
                    {id: 2, name: 'API文档', path: '/', icon: 'za-nav-api'},
                    {id: 3, name: 'SDK中心', path: '/', icon: 'za-nav-sdk'}
                ],
            }
        },
        methods: {
            showInput() {
                console.log(this.inputVal, '--inputVal-')
            },
            toNewPath(val) {
                // console.log(val, '--<<val')
            },
            openIcpView(){
                this.$router.push('/icpManagement')
            }

        }
    }
</script>

<style lang="scss" scoped>
    @import '~@/za/styles/mixin.scss';
    .timeIcon {
        width: 42px;
        height: 52px;
        cursor: pointer;
    }

    .ignore_navTop {
        background-color: rgba(255, 255, 255, 1);
        width: 100%;
        /*min-width: 1220px;*/
        height: 50px;
        box-shadow: 0px 2px 6px 2px rgba(68, 68, 68, 0.1);
        .topInfo {
            z-index: 9999 !important;
            position: fixed;
            min-width: 1220px;
            width: 100%;
            background-color: #ffffff;
            box-shadow: 0px 2px 6px 2px rgba(68, 68, 68, 0.1);
        }

        .ignore_timeIcon {
            width: 50px;
            height: 51px;
            background-color: #2468F2;
            cursor: pointer;
            float: left;
            cursor: pointer;

            .svg-homeMore {
                margin: 17px 16px;
            }
        }

        .ignore_center {
            float: left;
            width: 104px;
            padding-top: 10px;
            box-sizing: border-box;
            margin: 0 16px 0 24px;
            cursor: pointer;
        }

        .ignore_toHome {
            padding-left: 10px;
            border-left: 1px solid #F4F6FA;
            float: left;
            font-family: PingFangSC-Medium;
            font-size: 14px;
            color: #333;
            line-height: 50px;
            font-weight: 500;
            cursor: pointer;
        }

        .ignore_toHome:hover {
            color: #2468F2;

        }

        .search {
            float: right;
            padding: 7px 10px;
            margin: 17px 20px 0 0;
            box-sizing: border-box;
            position: relative;

            .search_btn {
                position: absolute;
                right: 0px;
                top: -2px;
                z-index: 99;
                font-size: 20px;
            }

            .search_input {
                position: absolute;
                top: -8px;
                right: -3px;
            }
        }

        .nav-item {
            position: relative;
            display: inline-block;
            float: right;
            padding: 0 12px;
            line-height: 50px;
            font-size: 13px;
            cursor: pointer;


        }

        .ignore_operationContent {
            position: relative;
            height: 50px;
            z-index: 999;
            float: right;
            cursor: pointer;
        }

    }
    ::v-deep {
        .el-input__inner {
            width: 224px;
        }


    }
</style>
