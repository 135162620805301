<template>
    <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" top="10vh" v-bind="$attrs"
               v-on="$listeners" :showClose="showClose">
        <div slot="title" class="dialog-title" v-if="titleState">{{ topTitle }}</div>
        <slot/>
        <div v-if="isShowFooter" slot="footer"
             :class="footState?'dialog-footer':'footerNone'"
        >
            <slot name="footer"/>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        name: 'ZaDialog',
        props: {
            topTitle: {
                type: String,
                default: '',
            },
            isShowFooter: {
                type: Boolean,
                default: true,
            },
            titleState: {
                type: Boolean,
                default: true,
            },
            footState: {
                type: Boolean,
                default: true,
            },
            showClose:{
                type: Boolean,
                default: true,
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '~@/za/styles/mixin.scss';

    .dialog-title {
        @include flex_div($just: flex-start, $padding: 0);
        font-size: 16px;
        font-weight: 500;
        color: #999999;
        background: #FFFFFF;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    }

    ::v-deep {
        .el-dialog__header {
            padding: 0;
            height: 40px;

            .el-dialog__headerbtn {
                top: 12px;
            }
        }

        .dialog-title {
            padding: 16px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
        }

    }

    .dialog-footer {
        @include flex_div($just: flex-end, $padding: 0);
        border-top: 1px solid rgba(0, 0, 0, 0.06);

        padding-top: 10px;
    }

    .footerNone {
        @include flex_div($just: flex-end, $padding: 0);
    }
</style>
