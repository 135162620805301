<template>
  <div class="audit-sql-container" v-za-loading="isLoading">
    <div class="title">{{ title }}</div>
    <div class="heard" v-if="!certificationName">
      <div class="waring">
        <span style="margin: 0 7px 0 20px; font-size: 16px">
          <za-svg-icon icon-class="za-wanning"></za-svg-icon>
        </span>
        <span>温馨提示：请实名认证之后执行充值操作，谢谢！</span>
      </div>
    </div>
    <div class="prompt">
      <span style="color: #faad13; margin-bottom: 15px">
        <za-svg-icon style="font-size: 16px; margin-right: 6px" icon-class="za-wanning"></za-svg-icon>
        {{ translateTitle('topUp.reminder') }}:
      </span>
      <!--      <span>1.请确保您输入的充值金额和交易方式正确，以免造成不必要的损失。</span>-->
      <span>1.若您有欠费账单，充值后将优先抵扣欠费账单。</span>
      <span>2.若您后付费产品已欠费并停止服务，请充值大于欠款的金额。</span>
      <span style="color: #555555">3.充值后请及时支付未支付订单，以免影响正常服务。</span>
    </div>
    <div class="balanceMoney">
      <div>
        <div>当前可用余额</div>
        <div class="money">
          <span>￥</span>
          <span>{{ haveMoney }}</span>
        </div>
      </div>
      <div class="record">
        <za-svg-icon icon-class="za-record" class-name="svg-success"></za-svg-icon>
        <span @click="toPath">充值记录</span>
      </div>
    </div>
    <div class="payType">
      <div style="width: 100%">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="在线支付" name="first">
            <onlinepay @onSubmit="onSubmit"></onlinepay>
          </el-tab-pane>
          <!--                    <el-tab-pane label="线下汇款" name="second">-->
          <!--                        <offlinepay></offlinepay>-->
          <!--                    </el-tab-pane>-->
        </el-tabs>
      </div>
    </div>

    <za-dialog top-title="充值注意：" :visible.sync="isWarnDialog" width="800px">
      <div>
        <div class="prompt" style="height: 160px">
          <span style="color: #faad13; margin-bottom: 15px">
            <za-svg-icon style="font-size: 16px; margin-right: 6px" icon-class="za-wanning"></za-svg-icon>
            {{ translateTitle('topUp.reminder') }}:
          </span>
          <span style="color: #555555">1.当前存在后付费产品，且已产生负费，可能会影响您的业务，请及时充值。</span>
          <span style="color: #555555">2.充值余额满100元时，将自动重启账号下所有停用产品。</span>
          <span style="color: #555555">
            3.充值前请注意停用产品，如果您手动关停了产品，请确保资源已经彻底删除，如果您没有清空回收站里不需要的内容，可能会产生额外的费用扣除。
          </span>
        </div>
      </div>
      <div slot="footer">
        <za-button :type="buttonTypeObj.BLUE" @click="isWarnDialog = false">确定</za-button>
      </div>
    </za-dialog>
  </div>
</template>
<script>
  import onlinepay from './components/onlinePay'
  import offlinepay from './components/offlinePay'
  import { apiGetBalance, apisubmit } from '@/api/moneyCenter/expenseManagement/topUp'
  import { apiAfterPayOrder } from '@/api/moneyCenter/orderManagement/renewal'
  import { apiCertificationName } from '@/api/moneyCenter/expenseManagement/moneyExtract'

  export default {
    components: { onlinepay, offlinepay },
    data() {
      return {
        activeName: 'first',
        title: '',
        haveMoney: '',
        tableData: [],
        isWarnDialog: false,
        certificationName: '已实名',
      }
    },
    created() {
      this.title = this.$route.meta.title
      this.getList()
    },
    methods: {
      async getList() {
        let res = await apiCertificationName().catch()
        this.certificationName = res.data.name || ''
        this.onSubmitData(apiGetBalance, null, null, false, false, async (res) => {
          this.haveMoney = res.data.balance
          let resourceList = await apiAfterPayOrder()
          if (resourceList.data.total > 0 && this.haveMoney < 0) {
            this.isWarnDialog = true
          }
        })
      },
      handleClick(tab, event) {},
      onSubmit(val) {
        val.amount = Number(val.amount)
        this.onSubmitData(apisubmit, val, null, false, false, (res) => {
          if (val.payType == 'alipay') {
            window.open(res.data.content, '_blank')
          } else {
            // console.log(process.env.VUE_APP_URL)
            window.open(
              process.env.VUE_APP_URL +
                '/wechatPay' +
                '?content=' +
                res.data.content +
                '&businessName=' +
                res.data.businessName +
                '&createAt=' +
                res.data.createAt +
                '&goodsName=' +
                res.data.goodsName +
                '&orderNumber=' +
                res.data.orderNumber +
                '&amount=' +
                res.data.amount +
                '&payAdress=' +
                '2',
              '_blank'
            )
            // window.open("https://za-console.ioiofast.com/#/wechatPay"+"?content="+res.data.content+"&businessName="+res.data.businessName+"&createAt="+res.data.createAt+"&goodsName="+res.data.goodsName+"&orderNumber="+res.data.orderNumber+"&amount="+res.data.amount+"&payAdress="+'2', '_blank',)
            // window.open("https://localhost:10001/#/wechatPay"+"?content="+res.data.content+"&businessName="+res.data.businessName+"&createAt="+res.data.createAt+"&goodsName="+res.data.goodsName+"&orderNumber="+res.data.orderNumber+"&amount="+res.data.amount+"&payAdress="+'2', '_blank',)
            localStorage.setItem('content', res.data.content)
          }
        })
      },
      toPath() {
        this.$router.push('/moneyCenter/expenseManagement/rechargeRecord')
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import '~@/za/styles/mixin.scss';

  .title {
    @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
    width: 100%;
    height: 20px;
    margin-bottom: 20px;
  }

  .prompt {
    width: 100%;
    height: 174px;
    background: #fffbe6;
    border-radius: 2px;
    border: 1px solid #ffe58f;
    padding: 20px 20px;

    span {
      height: 17px;
      display: block;
      font-size: 12px;
      line-height: 17px;
      margin-bottom: 11px;
      color: #eb2f2f;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
  }

  .balanceMoney {
    width: 100%;
    height: 54px;
    line-height: 40px;
    font-size: 14px;
    margin: 30px 0 30px 0;
    position: relative;

    .money {
      font-size: 20px;
      font-family: DINAlternate-Bold, DINAlternate;
      font-weight: bold;
      color: #000000;

      span:first-child {
        font-size: 14px;
      }
    }
  }

  .payType {
    margin-top: 20px;
  }

  .record {
    position: absolute;
    right: 0;
    top: 48%;
    width: 110px;
    height: 32px;
    background: #f3f4f7;
    border-radius: 2px;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
  }

  .heard {
    margin-bottom: 20px;

    .waring {
      height: 40px;
      background: #fffbe6;
      border-radius: 2px;
      border: 1px solid #ffe58f;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 40px;
    }
  }
</style>