var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-form",
            {
              ref: "signInInfoObj",
              staticClass: "sign",
              attrs: {
                disabled: _vm.confirmBtnLoading,
                model: _vm.signInInfoObj,
                rules: _vm.rules,
                "label-position": "right",
              },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "username" } },
                    [
                      _c("el-input", {
                        directives: [{ name: "focus", rawName: "v-focus" }],
                        attrs: {
                          placeholder: "账户名/邮箱",
                          readonly: _vm.confirmBtnLoading,
                          type: "text",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            return _vm.trim("username")
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prefix",
                            fn: function () {
                              return [
                                _c("za-svg-icon", {
                                  attrs: {
                                    "class-name": "svg-prefix",
                                    "icon-class": "za-people-fill",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.signInInfoObj.username,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.signInInfoObj,
                              "username",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "signInInfoObj.username",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "password" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入密码",
                          readonly: _vm.confirmBtnLoading,
                          type: _vm.passwordType,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.clangeWaringDesc()
                          },
                        },
                        nativeOn: {
                          keyup: [
                            function ($event) {
                              return _vm.trim("password")
                            },
                            function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handleSub()
                            },
                          ],
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prefix",
                            fn: function () {
                              return [
                                _c("za-svg-icon", {
                                  attrs: {
                                    "class-name": "svg-prefix",
                                    "icon-class": "za-lock",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.signInInfoObj.password,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.signInInfoObj,
                              "password",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "signInInfoObj.password",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.waringDescState
                    ? _c("span", { staticClass: "red-font" }, [
                        _vm._v(_vm._s(_vm.waringDesc)),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-checkbox", {
                    staticClass: "confirmCheck",
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.handleSub()
                      },
                    },
                    model: {
                      value: _vm.checked,
                      callback: function ($$v) {
                        _vm.checked = $$v
                      },
                      expression: "checked",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "clause",
                      staticStyle: { color: "#999999" },
                    },
                    [_vm._v("我已阅读并同意")]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "toclause",
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/protocal/userProtocal")
                        },
                      },
                    },
                    [_vm._v("服务协议")]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "clause",
                      staticStyle: { color: "#999999" },
                    },
                    [_vm._v("和")]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "toclause",
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/protocal/policy")
                        },
                      },
                    },
                    [_vm._v("隐私政策")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "login_btn" },
        [
          _vm.dialogState
            ? _c(
                "div",
                { staticClass: "coordinate" },
                [
                  _c("goCaptCha", {
                    attrs: {
                      showState: _vm.dialogState,
                      loginObj: _vm.signInInfoObj,
                      heardBase: this.signInInfoObj.b64,
                      centerBase: this.signInInfoObj.tb64,
                    },
                    on: { close: _vm.handleCloseEvent },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "za-button",
            {
              staticClass: "button",
              attrs: { loading: _vm.confirmBtnLoading, size: 14 },
              on: { click: _vm.handleSub },
            },
            [_vm._v(" " + _vm._s(_vm.translateTitle("btn.login")) + " ")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "operation" }, [
        _c("span", { on: { click: _vm.forGetPwd } }, [_vm._v("忘记密码")]),
        _c("span", { on: { click: _vm.handleRegister } }, [_vm._v("免费注册")]),
      ]),
      _c(
        "div",
        [
          _c(
            "za-dialog",
            {
              attrs: {
                "top-title": "验证手机号登录",
                visible: _vm.dialogVisible,
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form",
                        {
                          ref: "form",
                          attrs: {
                            disabled: _vm.confirmBtnLoading,
                            model: _vm.baseForm,
                            "label-position": "right",
                            "label-width": "100px",
                            rules: _vm.rules,
                          },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c("el-input", {
                                    staticClass: "input-with-select",
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.baseForm.phone,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.baseForm, "phone", $$v)
                                      },
                                      expression: "baseForm.phone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 16 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "code" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入验证码",
                                          maxlength: "6",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.changeWaring()
                                          },
                                        },
                                        model: {
                                          value: _vm.baseForm.code,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.baseForm, "code", $$v)
                                          },
                                          expression: "baseForm.code",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("el-col", { attrs: { span: 1 } }, [
                                _vm._v(" "),
                              ]),
                              _c(
                                "el-col",
                                { attrs: { span: 7 } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "sendInfo",
                                      class: {
                                        grey: _vm.isGrey,
                                        blue: !_vm.isGrey,
                                      },
                                      attrs: { disabled: _vm.dis },
                                      on: { click: _vm.sendData },
                                    },
                                    [
                                      _vm.telState == 1
                                        ? _c("span", [_vm._v("发送验证码")])
                                        : _vm._e(),
                                      _vm.telState == 2
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.count) + "秒重试"
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm.telState == 3
                                        ? _c("span", [_vm._v("重新发送")])
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "za-button",
                    {
                      attrs: {
                        disabled: _vm.confirmBtnLoading,
                        type: _vm.buttonTypeObj.GREY,
                      },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.translateTitle("btn.cancel")) + " "
                      ),
                    ]
                  ),
                  _c(
                    "za-button",
                    {
                      attrs: {
                        loading: _vm.confirmBtnLoading,
                        type: _vm.buttonTypeObj.BLUE,
                      },
                      on: { click: _vm.handleSubmitForm },
                    },
                    [_vm._v("登录")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            [
              _c(
                "el-dialog",
                {
                  attrs: {
                    width: "25%",
                    title: "验证",
                    visible: _vm.innerVisible,
                    "append-to-body": "",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.innerVisible = $event
                    },
                  },
                },
                [
                  _vm.showVerity
                    ? _c("za-verify", {
                        ref: "verify",
                        attrs: { selectKey: _vm.dialogType },
                        on: { verifySuccess: _vm.success },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }