var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "za-loading",
          rawName: "v-za-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "orderConfirmation-center_bg",
    },
    [
      _c(
        "div",
        [
          _c("za-go-back", {
            attrs: { title: "续费" },
            on: { click: _vm.goBack },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("续费订单确认")]),
          _c(
            "div",
            [
              _c("occ-table", {
                attrs: {
                  tableData: _vm.tableData,
                  columObj: _vm.columObj,
                  isOp: _vm.columObj.isOp,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 16 } }, [
                    _c("div", [_vm._v(" ")]),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "prompt" }, [
                      _c("span", [_vm._v("订单合计金额：￥")]),
                      _c("span", [_vm._v(_vm._s(_vm.amount) + "元")]),
                    ]),
                    _c("div", { staticClass: "warring" }, [
                      _c("span", [
                        _vm._v(
                          "此处金额仅供参考，实际支付金额以支付页为准，产品优惠券请前往支付页面使用"
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "warring" },
                      [
                        _c(
                          "za-button",
                          {
                            attrs: {
                              loading: _vm.confirmBtnLoading,
                              type: _vm.buttonTypeObj.BLUE,
                            },
                            on: { click: _vm.nextSubmit },
                          },
                          [_vm._v(" 提交订单 ")]
                        ),
                        _c(
                          "za-button",
                          {
                            attrs: {
                              disabled: _vm.confirmBtnLoading,
                              type: _vm.buttonTypeObj.GREY,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.goBack()
                              },
                            },
                          },
                          [_vm._v(" 取消 ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "za-dialog",
            {
              attrs: {
                "top-title": "提醒",
                visible: _vm.dialogVisible,
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c("div", { staticClass: "promptTest" }, [
                _c(
                  "span",
                  { staticStyle: { "margin-left": "16px" } },
                  [
                    _c("za-svg-icon", {
                      attrs: { "icon-class": "za-wanning" },
                    }),
                  ],
                  1
                ),
                _c("span", [
                  _vm._v(
                    " 您操作的续费实例中有未支付订单，可在订单管理中心进行支付，如若继续支付，历史未支付订单将自动取消，请确认是否继续支付？"
                  ),
                ]),
              ]),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "za-button",
                    {
                      attrs: {
                        loading: _vm.confirmBtnLoading,
                        type: _vm.buttonTypeObj.BLUE,
                      },
                      on: { click: _vm.handleSubmitForm },
                    },
                    [_vm._v(" 确定 ")]
                  ),
                  _c(
                    "za-button",
                    {
                      attrs: {
                        disabled: _vm.confirmBtnLoading,
                        type: _vm.buttonTypeObj.GREY,
                      },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("div"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }