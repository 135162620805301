var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "footer" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("返佣订单")]),
      _c("occ-table", {
        attrs: {
          tableData: _vm.tableData,
          columObj: _vm.columObj,
          isOp: _vm.columObj.isOp,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }