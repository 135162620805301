var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "announcement" }, [
    _c("div", { staticClass: "title" }, [_vm._v("最新公告")]),
    _c("div", { staticClass: "conter" }, [
      _c(
        "ul",
        _vm._l(_vm.msgList, function (item, index) {
          return _c(
            "li",
            {
              key: index,
              on: {
                click: function ($event) {
                  return _vm.handleClick(item)
                },
              },
            },
            [
              _c("span", { attrs: { href: item.href, target: "_blank" } }, [
                _vm._v(_vm._s(item.name.slice(0, 17)) + " "),
                item.name.length > 17 ? _c("i", [_vm._v("...")]) : _vm._e(),
              ]),
              _c("span", [_vm._v(_vm._s(item.time))]),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }