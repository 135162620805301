<template>
    <div class="back-con" @click="handleClick">
        <span style="margin-left: 16px;"><za-svg-icon icon-class="za-go-back"></za-svg-icon></span>
        <span >{{title}}</span>
    </div>
</template>

<script>
    import ZaButton from '@/za/components/zaButton'

    export default {
        name: 'ZaGoBack',
        components: {ZaButton},
        props: {
            title: {
                type: String,
                default: '',
            },
        },
        methods: {
            handleClick() {
                this.$emit('click')
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~@/za/styles/mixin.scss';

    .back-con {
        @include flex_div($just: '', $padding: 0);
        width: 100%;
        /* px-to-viewport-ignore-next */
        height: 62px;
        background: #FFFFFF;
        border-radius: 2px;
        margin-bottom: 20px;
        margin-left: 0;
      cursor: pointer;
        span:last-child {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: bold;
            color: #000000;
            display: block;
            margin-bottom: 3px;
        }

    }
</style>
