/**
 * 数据请求方式
 */
export const METHOD = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'patch',
}

/**
 * ------------------------------------------------------------------
 * 通用模块
 * ------------------------------------------------------------------
 */

/**[上传接口地址]**/
export const UPLOAD_PATH = '/v1/upload/upload'
/**[获取密码规则接口地址]**/
export const PWD_RULE_PATH = '/v1/common/getPwdRule'

/**
 * ------------------------------------------------------------------
 * ------------------------------------------------------------------
 * Login模块
 * ------------------------------------------------------------------
 */

/**[login接口地址]**/
//登录接口
export const LOGIN_PATH = '/v1/console/login'
export const LOGIN_PHONE = '/v1/console/loginByPhone'

//手机登录短信接口
export const LOGIN_SMS = '/v1/console/loginSms'

//登出接口
export const USER_LOGOUT_PATH = '/v1/console/logout'

//注册接口
export const LOGIN_REGISTER = '/v1/console/logon'
// 微信登录
export const LOGIN_WEIXIN = '/v1/console/loginwx'
// 微信关联
export const BIND_WX = '/v1/console/user/bind'
// 微信关联-绑定已有手机号
export const BIND_PHONE = '/v1/console/user/bindOpenId'

//获取用户基础信息接口
export const USER_BASE_INFO = '/v1/console/user/overview'

//获取注册手机号短信发送
export const USER_SENGSMS = '/v1/console/sendSms'
export const USER_VERIFY = '/v1/console/verify'

//重置密码
export const USER_UPDATEPWD = '/v1/console/secure/updaterPassword'
export const USER_UP_PWD = '/v1/console/upPassword'

//忘记密码
export const USER_SENG = '/v1/console/send'
//绑定微信获取手机验证码
export const BIND_SENDSMS = '/v1/console/bindSend'

//登录防爆破接口
export const USER_GETCAPTCHA = '/v1/console/getCaptcha'

//账号密码登录获取验证码
export const USER_SECURESMS = '/v1/console/secureSms'

//鉴权登录-登录保护-校验后登录
export const USER_CONSOLE_VERIFYLOGIN = '/v1/console/verifyLogin'

//鉴权登录-防止爆破——登录
export const USER_CONSOLE_CAPTCHALOGIN = '/v1/console/captchaLogin'
export const USER_LOGINZ = '/v1/console/loginz'

export const UPLOAD_ICO_PATH = '/v1/upload/uploadIco' //上传图标

//首页
export const ORDER_HOME_RESOURCE = '/v1/order/resource/getResourceNumber'
export const ORDER_HOME_GETWAIT_NUMBER = '/v1/order/resource/getWaitRenewResourceNumber'
export const ORDER_HOME_EXPIRE_NUMBER = '/v1/order/resource/getExpireResourceNumber'
export const ORDER_HOME_OVERLOG = '/v1/console/overviewLog'

//侧边栏展开页
export const BCC_LIST = '/v1/product/bcc/list'
export const RDS_LIST = '/v1/product/rds/list'
export const CDN_LIST = '/v1/product/cdn/list'
export const BOS_LIST = '/v1/product/bos/list'
export const BCD_LIST = '/v1/product/bcd/list'
export const DCC_LIST = '/v1/product/dcc/list'
export const CDS_LIST = '/v1/product/cds/list'
export const EIP_LIST = '/v1/product/eip/list'
export const EIPBP_LIST = '/v1/product/eipBp/list'
export const VPC_LIST = '/v1/product/vpc/list'
export const BLB_LIST = '/v1/product/blb/list'
export const DNS_LIST = '/v1/product/dns/list'
export const BCH_LIST = '/v1/product/bch/list'
export const CAS_LIST = '/v1/product/cas/list'

export const BBC_LIST = '/v1/product/bbc/list'
export const DTS_LIST = '/v1/product/dts/list'
export const KAFKA_LIST = '/v1/product/kafka/list'
export const BCM_LIST = '/v1/product/bcm/list'
export const KAFKA_CREATE = '/v1/product/kafka/create'

export const BCD_CREATE = '/v1/product/bcd/create'

//账户中心
//财务总览
export const MONEY_FINANCE_SETWARRING = '/v1/finance/setAmountWarning'
export const MONEY_FINANCE_NUMBER = '/v1/order/getWaitPayOrderNumber'
export const MONEY_WAIT_RESOURCE = '/v1/order/resource/getWaitRenewResourceNumber'
export const MONEY_GET_COUPONNUM = '/v1/finance/getEffectiveCouponNum'

//在线充值
export const MONEY_FINANCE_GET = '/v1/finance/getBalance'
export const MONEY_FINANCE_SUBMIT = '/v1/finance/submitRecharge'
export const MONEY_PAY_STATEQUERY = '/v1/finance/payStateQuery'

//收支明细
export const MONEY_DETAILED_LIST = '/v1/finance/detailedList'

// 交易流水
export const BILL_DETAILED_LIST = '/v1/finance/billDetails'
// 交易流水
export const RECHARGE_RECORD = '/v1/finance/rechargeDetails'

//余额提现
export const GET_WITH_AMOUNT = '/v1/finance/getWithDraw'
export const SUBMIT_WITHDRAWAL = '/v1/finance/submitWithdrawal '
export const WITHDRAWAL_LIST = '/v1/finance/withdrawList'
export const SECURE_CERTIFICATION = '/v1/console/secure/certificationName'

//卡券管理
export const GET_CASHCOUPON = '/v1/finance/getCashCoupon' // 接口删除
export const GET_COUPON = '/v1/finance/getCoupon' // 合并代金券和折扣券接口
export const GET_EXCHANGE_COUPON = '/v1/finance/exchangeCoupon'

//订单管理
export const GET_ORDER_LIST = '/v1/order/orderList'
export const GET_ORDER_DETAIL = '/v1/order/orderDetail'
export const GET_ORDER_CANCEL = '/v1/order/orderCancel'
export const GET_ORDER_COUPON = '/v1/order/orderResetCoupon'
export const GET_ORDER_RESOURCE = '/v1/order/resource/resourceParams'
export const GET_RESOURCE_LIST = '/v1/order/resource/resourceList'
export const GET_AFTERPAY_LIST = '/v1/order/resource/resourcePostPayCount'

//发票管理
export const GET_FINANCE_HEAD = '/v1/finance/getInvoicesHead'
export const GET_FINANCE_ADDRESS = '/v1/finance/getDefaultAddress'
export const GET_HISTORY_LIST = '/v1/finance/getInvoicesList'
export const GET_FINANCE_UPDATE = '/v1/finance/updateInvoicesHead'
export const GET_FINANCE_GETADDRESSLIST = '/v1/finance/getAddressList'
export const GET_FINANCE_ADDRESSLIST = '/v1/finance/addAddress'
export const GET_FINANCE_GETDEFAULT_ADDRESS = '/v1/finance/setDefaultAddress'
export const GET_FINANCE_DEL_ADDRESS = '/v1/finance/delAddress'
export const GET_FINANCE_UPDATE_ADDRESS = '/v1/finance/updateAddress'
export const GET_FINANCE_GETMONTHINVOICES = '/v1/finance/getMonthInvoices'
export const GET_FINANCE_GETORDERINVOICES = '/v1/finance/getOrderInvoices'
export const GET_FINANCE_GETVOICABLEAMOUNT = '/v1/finance/getInvoicableAmount'
export const GET_FINANCE_GETMONTHINVOICEDETAIL = '/v1/finance/getMonthInvoiceDetail'

//开票记录
export const GET_FINANCE_INVOICES_LIST = '/v1/finance/getInvoicesList'
export const GET_FINANCE_INVOICES_RETURN = '/v1/finance/invoicesReturn'
export const GET_FINANCE_INVOICES_CANCEL = '/v1/finance/invoicesCancel'

//财务中心
export const ORDER_CREATE = '/v1/order/orderCreate'
export const ORDER_MERGEPAY = '/v1/order/orderMergePay'
export const ORDER_SUBMITPAY = '/v1/order/orderSubmitPay'
export const COUPON_AVAIL = '/v1/finance/availableCoupons'
export const ORDER_RESOURCE_RENEW = '/v1/order/resource/resourceRenew'
export const ORDER_RENEW_SUBMIT = '/v1/order/resource/resourceRenewSubmit'
export const ORDER_RENEW_AUTO_RENRW = '/v1/order/resource/resourceAutoRenew'
export const ORDER_RENEW_CLOSE_RENRW = '/v1/order/resource/closeAutoRenew'

//个人中心
//基本信息模块
export const CONSOLE_INFO = '/v1/console/user/info'
export const UPDATE_INFO = '/v1/console/user/updateUserInfo'

//登录日志
export const CONSOLE_LOG = '/v1/console/log'

//安全设置
export const SAVESECURITY = '/v1/console/secure/saveSecurity'
export const USEROVERVIEW = '/v1/console/secure/getInfo'
export const GET_SECURITY = '/v1/console/secure/getSecurity'
export const VERIFY_SECURITY = '/v1/console/secure/verifySecurity'
export const UPDATE_PWD = '/v1/console/secure/updaterPassword'
export const UPDATE_LOGIN_SAFE = '/v1/console/secure/updateLoginSafe'
export const GET_PHONE = '/v1/console/secure/getPhone'
export const SENG_PHONE = '/v1/console/secure/sendSms'
export const SECURE_PHONE = '/v1/console/secure/verifySms'
export const SECURE_UPDATESMS = '/v1/console/secure/updateSms'
export const GET_EMAIL = '/v1/console/secure/getEmail'
export const SEND_EMAIL = '/v1/console/secure/sendEmail'
export const SECURE_EMAIL = '/v1/console/secure/verifyEmail'
export const SECURE_UPDATEEMAIL = '/v1/console/secure/updateEmail'
export const SECURE_ADDEMAIL = '/v1/console/secure/addEmail'
export const SECURE_SECUREEMAIL = '/v1/console/secure/email'
export const SECURE_SUBMITFORM = '/v1/finance/invoicingSubmitForMonth'
export const SECURE_SUBMITORDER = '/v1/finance/invoicingSubmitForOrder'
export const SECURE_SMS = '/v1/console/secure/sms'

//实名认证
export const CERTIFICATION = '/v1/console/secure/certification'
export const GET_CERTIFICATION = '/v1/console/secure/getCertification'
export const GET_CORPORATE = '/v1/console/secure/CorporateCertification'
export const GET_PERSONAL = '/v1/console/secure/personalCertification'

//推广返利中心
//云推官判断接口
export const GET_POPULARIZE_INFO_ISREGISTER = '/v1/popularize/info/isRegister'
//云推官详情接口
export const GET_POPULARIZE_INFO = '/v1/popularize/info/detail'
//推广总览-推广链接-点击统计
export const GET_POPULARIZE_CLICK_TOTAL = '/v1/popularize/click/list'
//推广总览-推广链接-点击操作
export const GET_POPULARIZE_CLICK_POINT = '/v1/popularize/click/point'
//获取推广总览-结算列表/推广收入
export const GET_POPULARIZE_MENTLIST = '/v1/popularize/settlement/list'
//获取推广效果-结算概览/返利概览
export const GET_POPULARIZE_SETTLEMENT_LIST = '/v1/popularize/settlement/total'
//获取推广效果-关联客户统计
export const GET_POPULARIZE_TOTAL = '/v1/popularize/user/total'
//获取推广效果-关联客户列表
export const GET_POPULARIZE_LIST = '/v1/popularize/user/list'
//获取推广效果-返佣订单列表统计
export const GET_POPULARIZE_ORDER_LIST = '/v1/popularize/order/list'
// 获取返佣余额
export const GET_POPULARIZE_BALANCE = '/v1/popularize/balance/getBalance'
//返佣余额转出
export const POST_POPULARIZE_TRANSFER_TO_BALANCE = '/v1/popularize/balance/transferToBalance'
//返佣余额提现
export const POST_POPULARIZE_CASH_BACK_TO_BALANCE = '/v1/popularize/balance/submitWithdrawal'
// 获取推广素材类型
export const GET_POPULARIZE_TYPE_LIST = '/v1/popularize/matter/type/list'
//获取推广素材接口
export const GET_POPULARIZE_MATTER_LIST = '/v1/popularize/matter/list'

//消息中心
export const GET_MESSAGE_LIST = '/v1/message/list'
export const GET_MESSAGE_DELETE = '/v1/message/delete'
export const GET_MESSAGE_READ = '/v1/message/read'
export const GET_CONENT_TYPE = '/v1/message/conentType'
export const GET_MESSAGE_READALL = '/v1/message/readAll'
export const GET_MESSAGE_DELALL = '/v1/message/delAll'
export const GET_MESSAGE_INFOR = '/v1/message/infor'
export const GET_MESSAGE_CONFIG_GET = '/v1/message/config/get'
export const GET_MESSAGE_CONFIG_EDIT = '/v1/message/config/edit'

//活动
export const GET_DENGBAO_ACTIVITES = '/v1/console/dengbao_activities/sendCode'
export const GET_DENGBAO_SUBMIT = '/v1/console/dengbao_activities/submit'

//icp
export const GET_ICP_INFO = '/v1/console/icpRecord'

// isFirstPurchase
export const GET_IS_FIRST_PURCHASE = '/v1/order/isFirstPurchase'
