<template>
  <div>
    <div class="compage">
      <el-empty :image-size="200" v-if="list.length <= 0"></el-empty>
      <template v-else>
        <div class="main" v-for="(item, index) in list" :key="index">
          <div>
            <el-image class="pic-error-parent" :src="item.pic" />
          </div>
          <div>
            <span class="title">{{ item.title }}</span>
            <div class="news">{{ item.desc }}</div>
            <div class="compage_input">
              {{ item.url + '?popularizeCode=' + popularizeInfo.code + '&matterId=' + item.id }}
            </div>

            <div class="footer">
              <el-button
                type="primary"
                @click="handleClick(item.url + '?popularizeCode=' + popularizeInfo.code + '&matterId=' + item.id)"
              >
                复制推广链接
              </el-button>
              <div class="footer_share">
                <span>分享至 :</span>
                <za-svg-icon
                  class-name="icon-click"
                  icon-class="za-qq"
                  @click="
                    handleShare(
                      item.url + '?popularizeCode=' + popularizeInfo.code + '&matterId=' + item.id,
                      item.title,
                      'qq'
                    )
                  "
                />

                <el-popover placement="top" width="280" trigger="click">
                  <div class="tips">
                    <div id="qrcode" ref="qrcode"></div>
                    <div>
                      <h2>分享到微信</h2>
                      <div>打开微信扫一扫</div>
                      <div>点击屏幕右上角分享</div>
                    </div>
                  </div>
                  <za-svg-icon
                    slot="reference"
                    class-name="icon-click"
                    icon-class="za-wechat"
                    @click="
                      handleShare(
                        item.url + '?popularizeCode=' + popularizeInfo.code + '&matterId=' + item.id,
                        item.title,
                        'wechat',
                        index
                      )
                    "
                  />
                </el-popover>

                <za-svg-icon
                  class-name="icon-click"
                  icon-class="za-microblog"
                  @click="
                    handleShare(
                      item.url + '?popularizeCode=' + popularizeInfo.code + '&matterId=' + item.id,
                      item.title,
                      'weibo'
                    )
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <pagination :limit.sync="pageObj.size" :page.sync="pageObj.page" :total="total" @pagination="getList" />
  </div>
</template>
<script>
  import QRCode from 'qrcodejs2'
  import { mapGetters } from 'vuex'
  import { toQQ, toWeibo } from '@/utils/share.js'

  import { apiPopularizeMatterList } from '@/api/management/center'
  import autoTableHeightMixin from '@/za/mixin/autoTableHeightMixin'

  export default {
    mixins: [autoTableHeightMixin],
    props: {
      productType: {
        type: String,
        default: '',
      },
    },
    created() {},
    data() {
      return {
        total: 0,
        list: [],
        pageObj: {
          product: this.productType,
          page: 1,
          size: 10,
        },
        qrCodeURL: '', // 二维码地址
        temporaryVar: null,
      }
    },
    computed: {
      ...mapGetters({
        popularizeInfo: 'user/popularizeInfo',
      }),
    },
    // computed: {
    //   autoHeight: function () {
    //     return this.tableHeight + 'px'
    //   },
    // },
    mounted() {
      this.getList()
    },
    methods: {
      getList() {
        this.onSubmitData(apiPopularizeMatterList, this.pageObj, null, false, false, (res) => {
          this.list = this.list.concat(res.data.list)
          // res.data.list ? (this.list = res.data.list) : (this.list = [])
          this.total = res.data.total
        })
      },
      handleClick(val) {
        this.copy(val)
      },
      // 生成二维码
      creatQrCode(i, url) {
        if (this.list[i].flag) return
        // if (this.temporaryVar) {
        //   this.temporaryVar.clear() //清除二维码
        //   this.$refs['qrcode'][i].innerHTML = '' //清除二维码
        //   this.temporaryVar = null
        //   return
        // }
        this.qrCodeURL = url // 赋值(这里的地址你自己定义)
        this.temporaryVar = new QRCode(this.$refs.qrcode[i], {
          text: this.qrCodeURL, //页面地址 ,如果页面需要参数传递请注意哈希模式#
          width: 100,
          height: 100,
        })
        this.list[i].flag = true
      },
      handleShare(url = '', title = '', option, i = null) {
        switch (option) {
          case 'qq':
            toQQ(url, title)
            break
          case 'wechat':
            this.creatQrCode(i, url)
            break
          case 'weibo':
            toWeibo(url, title)
            break
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .compage {
    padding: 50px 20px;

    .main {
      border: 1px solid #f4f6fa;
      padding: 20px;
      margin-bottom: 20px;
      display: flex;

      .title {
        font-size: 16px;
        font-weight: bold;
        color: #000;
      }

      .pic-error-parent {
        width: 260px;
        height: 180px;
        margin-right: 20px;
      }

      .newUser {
        font-size: 14px;
        margin-bottom: 10px;
        margin-top: 10px;
        font-weight: 400;
        color: #eb2f2f;
      }

      .news {
        font-size: 14px;
        color: #555555;
        margin: 10px 0;
      }

      .compage_input {
        min-width: 650px;
        max-width: 750px;
        height: 40px;
        font-size: 14px;
        color: #555555;
        line-height: 14px;
        border: 1px solid #ccc;
        padding: 11px 15px;
      }

      .footer {
        margin-top: 10px;
        display: flex;
        align-items: center;

        .footer_share {
          margin-left: 25px;

          span {
            font-size: 14px;
          }

          .icon-click {
            margin-left: 10px;
            font-size: 24px;
          }
        }
      }
    }
  }

  .tips {
    display: flex;
    justify-content: space-around;
    margin: 10px;

    #qrcode {
      margin-right: 20px;
    }

    h2 {
      color: #1a1c1a;
    }
  }
</style>