<template>
    <div>
        <navbar/>
        <div class="main">
            <div>
                <el-image class="wechatPay" :src="require('@/assets/pay/wechatPay.png')"/>
            </div>
            <div class="payMoney ">
                <div class="infoTitle">
                    <span>支付金额</span>
                    <span style="color:red">￥{{amount}}</span>
                    <span>请使用微信扫描下方二维码完成支付</span>
                </div>
                <div class="infoMain">
                    <div class="code">
                        <el-image class="dimensional" :src="getUrl()"/>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <span>商家名称：</span>
                                <span>{{businessName}}</span>
                            </li>
                            <li>
                                <span>商品名称：</span>
                                <span>{{goodsName}}</span>
                            </li>
                            <li>
                                <span>流水编号：</span>
                                <span>{{orderNumber}}</span>
                            </li>
                            <li>
                                <span>创建时间：</span>
                                <span>{{createAt}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="footer">
                    <div>智安云</div>
                    <div>联系电话：400-018-5113</div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import Navbar from '@/za/layout/components/Navbar'
    import {apiStateQuery} from '@/api/moneyCenter/expenseManagement/topUp'
    import {getLocation} from '@/utils'

    export default {
        components: {
            Navbar
        },
        data() {
            return {
                wechatUrl: '',
                businessName: '',
                createAt: '',
                goodsName: '',
                orderNumber: '',
                amount: '',
                timer: '',
                type:null
            }
        },
        created() {
            this.wechatUrl = localStorage.getItem('content')
            this.businessName = getLocation('businessName');
            this.createAt = getLocation('createAt');
            this.goodsName = getLocation('goodsName');
            this.orderNumber = getLocation('orderNumber');
            this.amount = getLocation('amount');
            this.type=getLocation('payAdress')
            this.setSyncStatus()
        },
        beforeDestroy() {
            clearInterval(this.timer)
            localStorage.removeItem('content')
        },
        methods: {
            getUrl() {
              let url = 'data:image/png;base64,' +this.wechatUrl
              // console.log(url,this.wechatUrl)
                return url
            },
            setSyncStatus() {
                if (this.timer) {
                    clearInterval(this.timer)
                }
                this.getSyncStatus()
                this.timer = setInterval(() => {
                    this.getSyncStatus()
                }, 1000)
            },
            getSyncStatus() {
                let result = {orderNumber: this.orderNumber}
                this.onSubmitData(apiStateQuery, result, null, false, false, res => {
                    if (res.data.ok == true) {
                        clearInterval(this.timer)
                        //type:1，订单支付，type:2,充值
                        this.$router.push({path:'/successPay',query:{type:this.type}})

                    }
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '~@/za/styles/mixin.scss';

    .main {
        width: 98%;
        margin: 20px;
        background: #FFFFFF;
        height: calc(#{$base-page-height} - #{$base-header-height} - #{$base-padding} * 2);

        .wechatPay {
            margin: 70px 45% 28px 45%;
        }

        .payMoney {
            width: 60%;
            height: 500px;
            margin: auto;
            padding: 40px 80px;
            @include img_div($imgUrl: url('~@/assets/pay/shape.png'));
        }

        .infoTitle {
            text-align: center;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            padding-bottom: 29px;

        }

        .infoMain {
            padding: 36px;
            display: flex;
            border-top: 1px dotted rgba(151, 151, 151, 1);
            border-bottom: 1px dotted rgba(151, 151, 151, 1);

            .code {
                width: 218px;
                height: 218px;
                background: #01d2ee;
                margin-left: 16%;
            }

            ul {
                list-style-type: none;
                margin-top: 44px;

                li {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    margin-bottom: 14px;

                    span:last-child {
                        color: #000000;
                    }
                }
            }
        }

        .footer {
            margin-top: 23px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;

            div {
                text-align: center;
            }

            div:last-child {
                color: #999999;
            }
        }
    }

    .dimensional {
        width: 100%;
        height: 100%;
    }
</style>