<template>
  <div class="heard-container">
    <!-- 头像 -->
    <div class="showHeardPicture">
      <el-image ref="image" class="icon-heardPicture" fit="cover" :src="icoUrl()">
        <div slot="error">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>
    </div>
    <div class="main">
      <!-- 主体信息 -->
      <div class="heardFlex">
        <div class="header_title">
          <el-col :span="6">
            <span>您好:</span>
            <span class="value">{{ userInfo.username }}</span>
          </el-col>
          <el-col :span="10" class="heard_rate">
            <span>当前推广等级 :</span>
            <el-rate v-model="popularizeInfo.level" disabled />
            <span>{{ popularizeInfo.level }}星会员</span>
          </el-col>
          <el-col :span="8" class="recentLogin">
            <span>上次登录:</span>
            <span class="value">{{ dayjs.unix(userInfo.loginAt).format('YYYY-MM-DD HH:mm:ss') }}</span>
          </el-col>
        </div>
      </div>
      <!-- 推广链接 -->
      <div class="sponsoredLinks">
        <span>专属推广链接：</span>
        <span>{{ links }}</span>
        <span>
          <i class="el-icon-copy-document copyDocument" @click="copy(links)"></i>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import dayjs from 'dayjs'

  export default {
    data() {
      return {
        links: null, //推广链接
      }
    },
    created() {
      this.links = `${process.env.VUE_APP_URL}/login?id=${this.popularizeInfo.code}`
    },
    computed: {
      dayjs() {
        return dayjs
      },
      ...mapGetters({
        userInfo: 'user/userInfo',
        popularizeInfo: 'user/popularizeInfo',
      }),
    },
    methods: {
      getList() {},
      icoUrl() {
        if (this.userInfo.profile) {
          return this.userInfo.profile
        } else {
          return require('@/assets/home/default.png')
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .heard-container {
    width: 65%;
    min-width: 750px;
    height: 120px;
    padding: 20px;
    display: flex;
    background: #ffffff;
    margin-bottom: 20px;

    .showHeardPicture {
      width: 80px;
      height: 80px;
      min-width: 80px;
      min-height: 80px;
      margin-right: 20px;

      ::v-deep .el-image {
        border-radius: 50%;
      }
    }

    .main {
      border-right: 1px solid #f4f6fa;
      width: 100%;

      .heardFlex {
        //width: 90%;
        margin-right: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #555555;
        border-bottom: 1px dashed #ccc;
        //display: flex;
        //align-items: center;
        //justify-content: space-between;

        div {
          .value {
            color: #000000;
            margin-top: 10px;
            display: inline-block;
            margin-left: 5px;
            font-size: 14px;
          }
        }

        .header_title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          //width: 95%;
          min-width: 400px;

          div:first-child {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            margin-bottom: 13px;
          }

          .heard_rate {
            display: flex;
          }

          .recentLogin {
            margin-bottom: 8px;
          }
        }
      }

      .sponsoredLinks {
        margin-top: 8px;
        font-size: 14px;

        span:first-child {
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #000000;
          padding-right: 15px;
        }
      }
    }
  }
</style>