import request from '@/utils/request'
import {COUPON_AVAIL, METHOD, ORDER_CREATE, ORDER_MERGEPAY, ORDER_SUBMITPAY} from '@/api/api.config'

/**
 * @description 获取支付订单接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiOrderCreate(data) {
  return request({
    url: ORDER_CREATE,
    method: METHOD.POST,
    data,
    hiddenErrorFlag: true,
  })
}

/**
 * @description 订单合并支付接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiOrderMergePay(data) {
  return request({
    url: ORDER_MERGEPAY,
    method: METHOD.POST,
    data,
  })
}

/**
 * @description 获取优惠券、折扣券接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiCouponAvailableCoupons(data) {
  return request({
    url: COUPON_AVAIL,
    method: METHOD.POST,
    data,
  })
}

/**
 * @description 提交订单
 * @param data
 * @returns {AxiosPromise}
 */
export function apisubmitPay(data) {
  return request({
    url: ORDER_SUBMITPAY,
    method: METHOD.POST,
    data,
  })
}
