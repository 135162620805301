import { createUniqueString } from '@/utils'

/**
 * UPLOAD_ORG: 原始图片（用以区分需要统计的删除图片所用）
 * UPLOAD_SUCCESS: 图片上传成功
 * UPLOAD_FAIL: 图片未上传或上传失败
 * @type {{UPLOAD_ORG: string, UPLOAD_FAIL: string, UPLOAD_SUCCESS: string}}
 */
export const IMAGE_UPLOAD_STATUS = {
  UPLOAD_ORG: 'org',
  UPLOAD_SUCCESS: 'success',
  UPLOAD_FAIL: 'fail',
}

/**
 * NONE: 不进行任何检测
 * EQUAL_RATIO: 比例检测
 * ONLY_WIDTH: 只检测宽度
 * ONLY_HEIGHT: 只检测高度
 * WIDTH_HEIGHT: 宽高均检测
 * SIZE: 只检测图片大小
 * @type {{ONLY_HEIGHT: number, ONLY_WIDTH: number, SIZE: number, NONE: number, WIDTH_HEIGHT: number, EQUAL_RATIO: number}}
 */
export const IMAGE_CHECK_TYPE = {
  NONE: -1,
  EQUAL_RATIO: 0,
  ONLY_WIDTH: 1,
  ONLY_HEIGHT: 2,
  WIDTH_HEIGHT: 3,
  SIZE: 4,
}

/**
 * type: 检测类型
 * Prop: 比例值
 * width: 检测的宽度值
 * height: 检测的高度值
 * @type {{Prop: number, width: number, type: number, height: number}}
 */
export const ImagePixelCheckObj = {
  type: IMAGE_CHECK_TYPE.NONE,
  Prop: 1,
  width: 0,
  height: 0,
}

/**
 * uid: 图片的唯一ID
 * id: 图片所属ID
 * status: 图片状态
 * previewUrl: 图片预览地址
 * url: 图片唯一地址
 * raw: 图片文件
 * other: 用于提交的参数
 * name: 图片名称
 * @type {{uid: string, other: undefined, previewUrl: string, raw: {}, id: undefined, url: string, status: string}}
 */
export const ImageObj = {
  uid: '',
  id: undefined,
  status: IMAGE_UPLOAD_STATUS.UPLOAD_FAIL,
  previewUrl: '',
  url: '',
  raw: {},
  other: undefined,
  name: '',
}

/**
 * 检测上传图片格式
 * @param file
 * @returns {boolean}
 */
export function checkImageUploadFormat(file) {
  return !(file.raw.type !== 'image/jpeg' && file.raw.type !== 'image/png' && file.raw.type !== 'image/jpg')
}

/**
 * 获取媒体资源的统一返回结构
 * @param {*} imgFile 图片对象
 * @returns {undefined|{uid: string, other: undefined, previewUrl: string, raw: {}, id: undefined, url: string, status: string}}
 */
export function changeMediaDataObj(imgFile) {
  if (!imgFile) {
    return undefined
  }
  const mediaData = Object.assign({}, ImageObj)
  mediaData.status = IMAGE_UPLOAD_STATUS.UPLOAD_ORG
  switch (typeof imgFile) {
    case 'object':
      mediaData.uid = imgFile.uid.toString()
      mediaData.previewUrl = imgFile.url
      mediaData.url = imgFile.url
      mediaData.name = imgFile.name
      if (imgFile.raw) {
        mediaData.raw = imgFile.raw
        mediaData.status = IMAGE_UPLOAD_STATUS.UPLOAD_FAIL
      }
      if (imgFile.other) mediaData.other = imgFile.other
      break
    case 'string':
      mediaData.uid = createUniqueString()
      mediaData.previewUrl = imgFile
      mediaData.url = imgFile
      break
  }
  if (mediaData.previewUrl.indexOf('http') === -1) {
    mediaData.previewUrl = process.env.VUE_APP_IMGAE_URL + mediaData.previewUrl
  }
  return mediaData
}
