var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-form",
            {
              ref: "signInInfoObj",
              staticClass: "sign",
              attrs: {
                disabled: _vm.confirmBtnLoading,
                model: _vm.signInInfoObj,
                rules: _vm.rules,
                "label-position": "right",
              },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "phone" } },
                    [
                      _c("el-input", {
                        directives: [{ name: "focus", rawName: "v-focus" }],
                        attrs: {
                          placeholder: "请输入手机号",
                          readonly: _vm.confirmBtnLoading,
                          type: "text",
                          maxlength: "11",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.limitInput($event, "phone")
                          },
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            return _vm.trim("phone")
                          },
                        },
                        model: {
                          value: _vm.signInInfoObj.phone,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.signInInfoObj,
                              "phone",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "signInInfoObj.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", [
                _c(
                  "div",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 16 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "smsCode" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入验证码",
                                maxlength: "6",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.changeWaring()
                                },
                              },
                              model: {
                                value: _vm.signInInfoObj.smsCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.signInInfoObj, "smsCode", $$v)
                                },
                                expression: "signInInfoObj.smsCode",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("el-col", { attrs: { span: 1 } }, [_vm._v(" ")]),
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "sendInfo",
                            class: { grey: _vm.isGrey, blue: !_vm.isGrey },
                            attrs: { disabled: _vm.dis },
                            on: { click: _vm.sendData },
                          },
                          [
                            _vm.telState == 1
                              ? _c("span", [_vm._v("发送验证码")])
                              : _vm._e(),
                            _vm.telState == 2
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.count) + "秒重试"),
                                ])
                              : _vm._e(),
                            _vm.telState == 3
                              ? _c("span", [_vm._v("重新发送")])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "el-col",
                [
                  _c("el-checkbox", {
                    staticClass: "confirmCheck",
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.handleLogin()
                      },
                    },
                    model: {
                      value: _vm.checked,
                      callback: function ($$v) {
                        _vm.checked = $$v
                      },
                      expression: "checked",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "clause",
                      staticStyle: { color: "#999999" },
                    },
                    [_vm._v("我已阅读并同意")]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "toclause",
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/protocal/userProtocal")
                        },
                      },
                    },
                    [_vm._v("服务协议")]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "clause",
                      staticStyle: { color: "#999999" },
                    },
                    [_vm._v("和")]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "toclause",
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/protocal/policy")
                        },
                      },
                    },
                    [_vm._v("隐私政策")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "za-button",
            {
              staticClass: "button",
              attrs: { loading: _vm.confirmBtnLoading, size: 14 },
              on: { click: _vm.handleLogin },
            },
            [_vm._v(" " + _vm._s(_vm.translateTitle("btn.login")) + " ")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "operation" }, [
        _c("span", [_vm._v(" ")]),
        _c("span", { on: { click: _vm.handleRegister } }, [_vm._v("免费注册")]),
      ]),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                width: "25%",
                title: "验证",
                visible: _vm.innerVisible,
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.innerVisible = $event
                },
              },
            },
            [
              _vm.showVerity
                ? _c("za-verify", {
                    ref: "verify",
                    attrs: { selectKey: _vm.dialogType },
                    on: { verifySuccess: _vm.success },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }