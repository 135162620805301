<template>
  <div class="customerTab">
    <div class="customerTab_form">
      <el-form :inline="true" :model="pageObj" @submit.native.prevent>
        <el-form-item label="客户类型">
          <el-select v-model="pageObj.state" @change="getList()">
            <el-option v-for="item in userTypeOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联时间" style="margin-left: 15px !important">
          <el-date-picker
            v-model="pickTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="datePickEnd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="关联状态" label-width="100px">
          <el-select v-model="pageObj.relatedState" @change="getList()">
            <el-option v-for="item in typeOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form :inline="true" :model="pageObj" @submit.native.prevent>
        <el-form-item label-width="100px">
          <el-input v-model="pageObj.keyword" placeholder="输入客户名或客户ID查找" />
        </el-form-item>
        <el-form-item>
          <za-button :icon="buttonIconObj.SEARCH" @click="handleQuery">{{ translateTitle('btn.query') }}</za-button>
          <za-button :icon="buttonIconObj.RESET" :type="buttonTypeObj.GREY" @click="handleResetQuery">
            {{ translateTitle('btn.reset') }}
          </za-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <occ-table :tableData="tableData" :columObj="columObj" :isOp="columObj.isOp"></occ-table>
      <pagination :limit.sync="pageObj.pageSize" :page.sync="pageObj.pageNo" :total="total" @pagination="getList" />
    </div>
  </div>
</template>
<script>
  import { apiPopularizeUserList } from '@/api/management/correlation'

  export default {
    data() {
      return {
        pageObj: {
          pageNo: 1,
          pageSize: 10,
          state: 0,
          relatedState: 0,
          startAt: 0,
          endAt: 0,
          keyword: '',
        },
        pickTime: [],
        userTypeOptions: [
          { id: 0, name: '全部' },
          { id: 1, name: '推广期客户' },
          { id: 2, name: '维护期客户' },
          { id: 3, name: '已失效客户' },
        ],
        typeOptions: [
          { id: 0, name: '全部' },
          { id: 1, name: '正常' },
          { id: 2, name: '失效' },
        ],
        tableData: [],
        total: 0,
        columObj: {
          // 控制选择框
          selection: false,
          isOp: false,
          width: '300px',
          columnData: [
            {
              text: true,
              prop: 'username',
              label: '关联客户名称',
              width: '',
              flag: true,
              align: 'center',
            },
            {
              text: true,
              prop: 'uuid',
              label: '关联客户ID',
              width: '200',
              flag: true,
              align: 'center',
            },
            {
              enumType: 'userType',
              prop: 'userType',
              label: '客户类型',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              enumType: 'correlationState',
              prop: 'state',
              label: '关联状态',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              ownDefined: true,
              prop: 'amount',
              label: '消费金额',
              flag: true,
              width: '',
              align: 'center',
              ownDefinedReturn: (row) => {
                return `￥${row.amount}`
              },
            },
            {
              ownDefined: true,
              prop: 'commission',
              label: '累计有效佣金',
              flag: true,
              width: '',
              align: 'center',
              ownDefinedReturn: (row) => {
                return `￥${row.commission}`
              },
            },
            {
              text: true,
              prop: 'createAt',
              label: '关联成功时间',
              flag: true,
              width: '200',
              align: 'center',
            },
            {
              text: true,
              prop: 'expireAt',
              label: '关联失效时间',
              flag: true,
              width: '200',
              align: 'center',
            },
            {
              text: true,
              prop: 'unRelatedReason',
              label: '备注',
              flag: true,
              width: '',
              align: 'center',
            },
          ],
        },
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.onSubmitData(apiPopularizeUserList, this.pageObj, null, false, false, (res) => {
          res.data.list ? (this.tableData = res.data.list) : (this.tableData = [])
          this.total = res.data.total
        })
      },
      handleQuery() {
        this.getList()
      },
      handleResetQuery() {
        this.pickTime = []
        this.pageObj = {
          pageNo: 1,
          pageSize: 10,
          state: 0,
          relatedState: 0,
          startAt: 0,
          endAt: 0,
          keyword: '',
        }
        this.getList()
      },
      datePickEnd(date) {
        if (date) {
          this.pageObj.startAt = this.$changeTimeStamp(date[0])
          this.pageObj.endAt = this.$changeTimeStamp(date[1]) + 3600 * 24 - 1
        } else {
          this.pageObj.startAt = 0
          this.pageObj.endAt = 0
        }
        this.getList()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .customerTab {
    background: #ffffff;
    padding: 20px;

    .customerTab_form {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
</style>