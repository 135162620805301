var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "za-loading",
            rawName: "v-za-loading",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
      },
      [
        _c("span", { staticClass: "title" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticStyle: { width: "800px" } },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.querydata,
                      "label-position": "right",
                      rules: _vm.rule,
                      "label-width": "160px",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("* "),
                                ]),
                                _c("span", [_vm._v(" 联系人")]),
                              ]
                            ),
                            _c("el-input", {
                              attrs: {
                                placeholder: `${_vm.translateTitle(
                                  "placeTxt"
                                )}`,
                                disabled: "",
                              },
                              model: {
                                value: _vm.querydata.contacts,
                                callback: function ($$v) {
                                  _vm.$set(_vm.querydata, "contacts", $$v)
                                },
                                expression: "querydata.contacts",
                              },
                            }),
                            !_vm.querydata.contacts
                              ? _c("span", { staticClass: "waringTest" }, [
                                  _vm._v(" 请前往 "),
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push(
                                            "/accountCenter/nameCertification"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("实名认证")]
                                  ),
                                  _vm._v(" 进行绑定 "),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("* "),
                                ]),
                                _c("span", [_vm._v(" 移动电话")]),
                              ]
                            ),
                            _c("el-input", {
                              attrs: {
                                placeholder: `${_vm.translateTitle(
                                  "placeTxt"
                                )}`,
                                disabled: "",
                              },
                              model: {
                                value: _vm.querydata.phone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.querydata, "phone", $$v)
                                },
                                expression: "querydata.phone",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("* "),
                                ]),
                                _c("span", [_vm._v(" 电子邮件")]),
                              ]
                            ),
                            _c("el-input", {
                              attrs: {
                                placeholder: `${_vm.translateTitle(
                                  "placeTxt"
                                )}`,
                                disabled: "",
                              },
                              model: {
                                value: _vm.querydata.email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.querydata, "email", $$v)
                                },
                                expression: "querydata.email",
                              },
                            }),
                            !_vm.querydata.email
                              ? _c("span", { staticClass: "waringTest" }, [
                                  _vm._v(" 请前往 "),
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push(
                                            "/accountCenter/safetySet"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("安全设置")]
                                  ),
                                  _vm._v(" 进行绑定 "),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "电话:" } },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "11",
                                placeholder: `${_vm.translateTitle(
                                  "placeTxt"
                                )}`,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.limitInput($event, "mobile")
                                },
                              },
                              model: {
                                value: _vm.querydata.mobile,
                                callback: function ($$v) {
                                  _vm.$set(_vm.querydata, "mobile", $$v)
                                },
                                expression: "querydata.mobile",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "QQ号:" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: `${_vm.translateTitle(
                                  "placeTxt"
                                )}`,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.limitInput($event, "qq")
                                },
                              },
                              model: {
                                value: _vm.querydata.qq,
                                callback: function ($$v) {
                                  _vm.$set(_vm.querydata, "qq", $$v)
                                },
                                expression: "querydata.qq",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "官网:" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请填写您的官网URL" },
                              model: {
                                value: _vm.querydata.website,
                                callback: function ($$v) {
                                  _vm.$set(_vm.querydata, "website", $$v)
                                },
                                expression: "querydata.website",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "所在城市:" } },
                          [
                            _c("el-cascader", {
                              attrs: {
                                clearable: "",
                                options: _vm.cityOptions,
                                "show-all-levels": false,
                              },
                              model: {
                                value: _vm.seleCity,
                                callback: function ($$v) {
                                  _vm.seleCity = $$v
                                },
                                expression: "seleCity",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "行业领域 : ", prop: "industry" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "200px" },
                                attrs: {
                                  clearable: "",
                                  placeholder: `${_vm.translateTitle(
                                    "placeTxt"
                                  )}`,
                                },
                                model: {
                                  value: _vm.querydata.industry,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.querydata, "industry", $$v)
                                  },
                                  expression: "querydata.industry",
                                },
                              },
                              _vm._l(_vm.typeOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.name,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "za-button",
                          {
                            staticClass: "btn",
                            on: { click: _vm.handleQuery },
                          },
                          [_vm._v(_vm._s(_vm.translateTitle("btn.save")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }