<script>
  import enums from '@/utils/enums'

  export default {
    name: 'StorageData',
    created() {
      window.addEventListener('message', this.receiveMessage, false)
    },
    beforeDestroy() {
      window.removeEventListener('message', this.receiveMessage)
    },
    methods: {
      receiveMessage(event) {
        // if (event.origin !== 'https://za-portal-dev.zhiannet.com/zhianCloud/domainAndNetWebsite/domainRegister/') return
        const curData = event.data
        if (!curData) return
        let curKey = ''
        for (const key in enums.localKey) {
          const keyStr = enums.localKey[key]
          if (Object.hasOwn(curData, keyStr)) {
            curKey = keyStr
            break
          }
        }

        if (!curKey) return
        localStorage.setItem(curKey, curData[curKey])
      },
    },
  }
</script>

<template>
  <div></div>
</template>

<style scoped lang="scss"></style>