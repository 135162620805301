import request from '@/utils/request'
import {
    METHOD,
    GET_MESSAGE_LIST,
    GET_MESSAGE_DELETE,
    GET_MESSAGE_READ,
    GET_CONENT_TYPE,
    GET_MESSAGE_READALL,
    GET_MESSAGE_DELALL,
    GET_MESSAGE_INFOR,
    GET_MESSAGE_CONFIG_GET,
    GET_MESSAGE_CONFIG_EDIT
} from '@/api/api.config'
/**
 * @description //获取消息列表
 * @param data
 * @returns {AxiosPromise}
 */
export function apiMsgList(data) {
    return request({
        url: GET_MESSAGE_LIST,
        method: METHOD.POST,
        data,
    })
}
/**
 * @description //删除消息
 * @param data
 * @returns {AxiosPromise}
 */
export function apiMsgDel(data) {
    return request({
        url: GET_MESSAGE_DELETE,
        method: METHOD.POST,
        data,
    })
}
/**
 * @description //已读消息
 * @param data
 * @returns {AxiosPromise}
 */
export function apiMsgRead(data) {
    return request({
        url: GET_MESSAGE_READ,
        method: METHOD.POST,
        data,
    })
}
/**
 * @description //获取消息类型
 * @param data
 * @returns {AxiosPromise}
 */
export function apiMsgConentType(data) {
    return request({
        url: GET_CONENT_TYPE,
        method: METHOD.GET,
        params:data,
    })
}
/**
 * @description //全部已读
 * @param data
 * @returns {AxiosPromise}
 */
export function apiMsgReadAll(data) {
    return request({
        url: GET_MESSAGE_READALL,
        method: METHOD.GET,
        params:data,
    })
}
/**
 * @description //全部删除
 * @param data
 * @returns {AxiosPromise}
 */
export function apiMsgDelAll(data) {
    return request({
        url: GET_MESSAGE_DELALL,
        method: METHOD.GET,
        params:data,
    })
}
/**
 * @description //获取消息文章
 * @param data
 * @returns {AxiosPromise}
 */
export function apiMsgInfor(data) {
    return request({
        url: GET_MESSAGE_INFOR,
        method: METHOD.GET,
        params:data,
    })
}
/**
 * @description // 获取消息接收设置
 * @param data
 * @returns {AxiosPromise}
 */
export function apiMsgConfigGet() {
    return request({
        url: GET_MESSAGE_CONFIG_GET,
        method: METHOD.GET,
    })
}

/**
 * @description // 修改消息接收设置
 * @param data
 * @returns {AxiosPromise}
 */
export function apiMsgConfigEdit(data) {
    return request({
        url: GET_MESSAGE_CONFIG_EDIT,
        method: METHOD.POST,
        data,
    })
}
