var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "herard-container" }, [
    _c("div", { staticClass: "main" }, [
      _c(
        "div",
        { staticClass: "showHeardPirectrue" },
        [
          _c(
            "el-image",
            {
              ref: "image",
              staticClass: "icon-heardPicture",
              attrs: { fit: "cover", src: _vm.icoUrl() },
            },
            [
              _c("div", { attrs: { slot: "error" }, slot: "error" }, [
                _c("i", { staticClass: "el-icon-picture-outline" }),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "heardFlex" }, [
        _c("div", { staticClass: "header_title" }, [
          _c("div", [
            _c("span", [_vm._v("您好:")]),
            _c("span", { staticClass: "value" }, [
              _vm._v(_vm._s(_vm.querydata.username)),
            ]),
          ]),
        ]),
        _c("div", [
          _c("span", [_vm._v("上次登录:")]),
          _c("span", { staticClass: "value" }, [
            _vm._v(" " + _vm._s(_vm.querydata.uuid)),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }