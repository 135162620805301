<template>
    <div>
        <div>
            <loginHeard :title="'忘记密码'"></loginHeard>
        </div>
        <div class="heard">
            <div class="center">
                <div class="teps">
                    <el-steps :active="active" align-center>
                        <el-step title="找回密码"></el-step>
                        <el-step title="重置密码"></el-step>
                        <el-step title="重置成功"></el-step>
                    </el-steps>
                </div>
                <div class="form_pwd" v-if="stepIndex=='1'">
                    <el-form
                            ref="form"
                            :disabled="confirmBtnLoading"
                            :model="baseForm"
                            label-position="left"
                            label-width="140px"
                            :rules="rules"
                    >
                        <el-form-item prop="number">
                            <el-input 
                                v-model="baseForm.number" 
                                placeholder="请输入您账号绑定的邮箱/手机号码"
                                @keyup.native="newTrim('number')"
                            />
                        </el-form-item>
                        <el-form-item required>
                            <el-col :span="16">
                                <el-form-item prop="code">
                                    <el-input 
                                        v-model="baseForm.code "
                                        placeholder="请输入验证码！"
                                        @input="changeWaring()"
                                        @keyup.native="newTrim('code')"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="1">&nbsp;</el-col>
                            <el-col :span="7">
                                <el-button @click="sendData" class="sendInfo" :disabled="dis"
                                           :class="{grey:isGrey,blue:!isGrey}">
                                    <span v-if="telState=='1'">发送验证码</span>
                                    <span v-if="telState=='2'">{{count}}秒重试</span>
                                    <span v-if="telState=='3'">重新发送</span>
                                </el-button>
                            </el-col>
                            <el-col>
                                <span v-if="waringState" class="red-font">{{waringText}}</span>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <div class="prompt_btn">
                                <za-button :loading="confirmBtnLoading" :size="14" class="button"
                                           @click="doubleNext">
                                    下一步
                                </za-button>
                                <za-button :loading="confirmBtnLoading" :size="14" class="goback"
                                           @click="$router.push('/login')">
                                    取消
                                </za-button>
                            </div>
                        </el-form-item>
                        <div>
                            <el-dialog
                                    width="25%"
                                    title="验证"
                                    :visible.sync="innerVisible"
                                    append-to-body>
                                <za-verify
                                        v-if="showVerity"
                                        ref="verify"
                                        @verifySuccess="success"
                                        :selectKey="dialogType"
                                />
                            </el-dialog>
                        </div>
                    </el-form>
                </div>
                <div class="form_pwd" v-if="stepIndex=='2'">
                    <span class="userTitle">您正在为账户名为<span>{{user}}</span>的账户重置密码</span>
                    <el-row>
                        <el-form
                                ref="passwordForm"
                                :disabled="confirmBtnLoading"
                                :model="pwdForm"
                                label-position="left"
                                label-width="140px"
                                :rules="pwdrule"
                        >
                            <el-col :span="24">
                                <el-form-item prop="password">
                                    <el-input
                                            v-model.trim="pwdForm.password"
                                            :placeholder="translateTitle('register.placePwd')"
                                            ref="password"
                                            :type="passwordType"
                                            @keyup.native="trim('password')"
                                    >
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item prop="newPassword">
                                    <el-input
                                            v-model.trim="pwdForm.newPassword"
                                            :placeholder="translateTitle('register.placeConfirmPwd')"
                                            ref="newPasswordType"
                                            :type="newPasswordType"
                                            @keyup.native="trim('newPassword')"
                                    >
                                    </el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="24">
                                <el-form-item>
                                    <div class="prompt_btn">
                                        <za-button :loading="confirmBtnLoading" :size="14" class="goback"
                                                   @click="()=>{
                                                     stepIndex=1
                                                     active=1
                                                   }">
                                            上一步
                                        </za-button>
                                        <za-button :loading="confirmBtnLoading" :size="14" class="button"
                                                   @click="tripleNext">
                                            下一步
                                        </za-button>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-form>
                    </el-row>
                </div>
                <div class="form_pwd" v-if="stepIndex=='3'">
                    <div class="end">
                        <div>
                            <div>
                                <el-image class="registerSuccess" :src="require('@/assets/login/Group.png')"/>
                            </div>
                            <div class="title">密码修改成功，请牢记账号密码</div>
                        </div>
                        <div class="prompt_btn">
                            <za-button :loading="confirmBtnLoading" :size="14" class="button"
                                       @click="$router.push('/login')">
                                重新登录
                            </za-button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div>
            <loginFoot></loginFoot>
        </div>
    </div>
</template>
<script>
    import loginHeard from '@/views/login/components/loginHeard'
    import loginFoot from '@/views/login/components/loginFoot'
    import ZaVerify from '@/za/components/zaVerify'
    import {translateTitle} from "@/utils/i18n";
    import {apiLoginSend, apiLoginVerify, apiUpdatePwd, apiUpPwd} from '@/api/apiLogin'
    import {isPwd,isPwdNotTest,isHaveSpecial,isSPpassWord,isHaveSpace} from '@/utils/validate'

    export default {
        components: {loginHeard, loginFoot, ZaVerify},
        data() {
            const password = (rule, value, callback) => {
                if (value === '') callback(new Error(translateTitle('register.placePwd')))
                else if (value.length < 6||value.length > 20) callback(new Error(' 请输入6-20字符'))
                else if(isPwdNotTest(value))callback(new Error('密码设置不能输入中文'))
                else if (isSPpassWord(value)) callback(new Error('密码复杂度太低，限字母、数字或特殊字符至少包含2种'))
                else callback()
            }
            const newpassword = (rule, value, callback) => {
                if (value === '') callback(new Error(translateTitle('register.tipsConfirmPwd')))
                else if (this.pwdForm.newPassword !== '' && this.pwdForm.newPassword !== this.pwdForm.password){
                    callback(new Error(translateTitle('register.tipsDistinctPwd')))
                }else callback()
            }
            const smsCode=  (rule, value, callback) => {
                if (value === '') callback(new Error('验证码不能为空'))
                else if (isHaveSpace(value))callback(new Error('验证码不能包含空格！'))
                else callback()
         
            }
            return {
                active: 1,
                count: "",
                telState: 1,
                dis: false,
                show: true,
                timer: null, //设置计时器,
                isGrey: false, //按钮样式
                showVerity: false,
                innerVisible: false,
                rules: {
                    number: [{required: true, message: '请输入邮箱号或手机号', trigger: 'blur'}],
                    code: [{required: true, trigger: 'blur', validator: smsCode}],
                },
                pwdrule: {
                    password: [{required: true, trigger: 'blur', validator: password}],
                    newPassword: [{required: true, trigger: 'blur', validator: newpassword}],
                },
                baseForm: {
                    number: '',
                    code: ''
                },
                pwdForm: {
                    number: '',
                    password: '',
                    newPassword: ''
                },
                stepIndex: '1',
                user: '',
                passwordType: 'password',
                newPasswordType: 'password',

            }
        },
        mounted() {
            // if (JSON.parse(localStorage.getItem('twoParts'))) {
            //     this.stepIndex = JSON.parse(localStorage.getItem('twoParts')).active
            //     this.active = JSON.parse(localStorage.getItem('twoParts')).active
            //     this.user = JSON.parse(localStorage.getItem('twoParts')).user
            // }
        },
        // beforeDestroy() {
        //     localStorage.removeItem('twoParts')
        // },
        methods: {
            trim(val) {
                this.pwdForm[val] = this.pwdForm[val].replace(/\s*/g, '')
            },
            newTrim(val) {
                this.baseForm[val] = this.baseForm[val].replace(/\s*/g, '')
            },
            async success(val) {
                this.innerVisible = false
                this.showVerity = false
                let res = await apiLoginSend({number: this.baseForm.number}).catch()
                if (res.code == 0) {
                    let TIME_COUNT = 60;
                    if (!this.timer) {
                        this.count = TIME_COUNT;
                        this.isGrey = true;
                        this.telState = 2;
                        this.dis = true;
                        this.timer = setInterval(() => {
                            if (this.count > 0 && this.count <= TIME_COUNT) {
                                this.count--;
                            } else {
                                this.dis = false;
                                this.isGrey = false;
                                this.telState = 3;
                                clearInterval(this.timer);
                                this.timer = null;
                            }
                        }, 1000);
                    }
                }
            },
            sendData() {
                if (!this.baseForm.number) {
                    this.$msgWarning('手机号或邮箱号不能为空')
                } else {
                    this.innerVisible = true
                    this.showVerity = true
                }
            },
            doubleNext() {
                this.$refs['form'].validate(async (valid) => {
                    if (valid) {
                        let res
                        res = await apiLoginVerify(this.baseForm).finally(() => (this.confirmBtnLoading = false))
                        if (res.code == 0) {
                            this.$msgSuccess('校验成功')
                            this.user = res.data.username
                            this.active = 2
                            this.stepIndex = 2
                            // let data={
                            //     active:this.active,user:res.data.username
                            // }
                            // localStorage.setItem('twoParts', JSON.stringify(data))
                        }
                    } else {
                        return false;
                    }
                });

            },
            tripleNext() {
                this.$refs['passwordForm'].validate(async (valid) => {
                    if (valid) {
                        let res
                        this.pwdForm.number = this.baseForm.number
                        // delete this.pwdForm.password;
                        res = await apiUpPwd(this.pwdForm).finally(() => (this.confirmBtnLoading = false))
                        if (res.code == 0) {
                            this.$msgSuccess('重置成功')
                            this.active = 3
                            this.stepIndex = 3
                        }
                    } else {
                        return false;
                    }
                });

            }
        }
    }
</script>
<style lang="scss" scoped>
    .heard {
        height: calc(100vh - 60px);
        background-color: #FFFFFF;
        box-sizing: border-box;
        border-bottom: 1px solid #E4E5E9;
        position: relative;

        .center {
            width: 786px;
            height: 350px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -70%);
            margin-top: 50px;

            .form_pwd {
                width: 90%;
                margin-top: 100px;
                margin-bottom: 24px;
            }

            .goback {
                background: none;
                border: 1px solid #999999;
                width: 108px;
                color: #000000;
            }

            .button {
                width: 108px;
                margin-right: 40px;
                text-align: center;
            }

            .teps {
                width: 1500px;
                margin-left: -40%;
            }
        }

        .title {
            font-size: 16px;
            font-weight: 400;
            color: #000000;

            span {
                color: #1B5DEB;
                padding-left: 5px;
                padding-right: 5px;
            }
        }


    }

    .userTitle {
        text-align: center;
        margin-bottom: 20px;
        display: block;

        span {
            color: #1B5DEB;
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    .end {
        text-align: center;
        margin-left: 180px;

        .registerSuccess {
            text-align: center;
            width: 72px;
            height: 72px;
        }

        .title {
            font-size: 24px;
            font-weight: 500;
            color: #000000;
            margin-top: 24px;
            margin-bottom: 34px;
        }

        .button {
            margin-right: 0;
        }
    }

    ::v-deep {
        .el-step__icon.is-text{
        }
        .is-process {
            font-weight: 100;
            color: #C0C4CC;
            .is-text{
                border-color: #C0C4CC;

            }
        }

        .el-step__title.is-finish {
            color: #1B5DEB;
        }

    }
    .blue{
        width: 164px;
    }
    .grey{
        width: 164px;
    }
</style>