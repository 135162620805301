var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { staticClass: "za_layout" },
    [
      _c("el-header", { staticClass: "za_layout_header" }, [_c("navbar")], 1),
      _c(
        "el-container",
        { staticClass: "za_layout_main" },
        [
          _c("sidebar", { staticClass: "za_layout_left_menu" }),
          _c("el-container", { staticClass: "za_layout_right_main" }, [
            _c(
              "div",
              { staticClass: "za_layout_app_main" },
              [_c("app-main")],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }