<template>
    <el-tabs v-model="activeName"  >
        <el-tab-pane label="账号登录" name="login">
        <loginUser ></loginUser>
        </el-tab-pane>
        <el-tab-pane label="手机号登录" name="tel">
           <loginTel ></loginTel>
        </el-tab-pane>
    </el-tabs>

</template>

<script>
    import loginUser from '@/views/login/components/loginUser'
    import loginTel from '@/views/login/components/loginTel'
    export default {
        name: 'SignIn',
        components: {loginUser,loginTel},
        data() {
            return {
                activeName: 'login',
            }
        },
        methods:{

        }

    }
</script>

<style lang="scss" scoped>
</style>
