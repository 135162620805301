var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dropdown",
        { attrs: { placement: "bottom" } },
        [
          _c(
            "el-badge",
            { attrs: { "is-dot": _vm.dotFlag } },
            [
              _c("za-svg-icon", {
                attrs: {
                  "class-name": "svg-homeMa",
                  "icon-class": "za-home-msg",
                },
              }),
            ],
            1
          ),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("div", { staticClass: "top_dropdown_money" }, [
                _c("div", { staticClass: "top_first" }, [
                  _c("div", [_vm._v("站内消息通知")]),
                  _c("div", { on: { click: _vm.toMsg } }, [_vm._v("消息管理")]),
                ]),
              ]),
              !_vm.state
                ? _c(
                    "div",
                    _vm._l(_vm.optionsList, function (item, index) {
                      return _c(
                        "el-dropdown-item",
                        {
                          key: index,
                          nativeOn: {
                            click: function ($event) {
                              return _vm.toPath(item.path)
                            },
                          },
                        },
                        [
                          [
                            _c("div", { staticClass: "magInfo" }, [
                              _c("div", [
                                _c("i", {
                                  staticClass: "el-icon-info",
                                  class: { notRead: item.readAt !== "1" },
                                }),
                                _vm._v(
                                  " " + _vm._s(item.msg.slice(0, 25)) + " "
                                ),
                                item.msg.length > 25
                                  ? _c("i", [_vm._v("...")])
                                  : _vm._e(),
                              ]),
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "14px" } },
                                [_vm._v(_vm._s(item.time))]
                              ),
                            ]),
                          ],
                        ],
                        2
                      )
                    }),
                    1
                  )
                : _c("div", [
                    _c("div", { staticClass: "noMsg" }, [
                      _vm._v("暂无最新消息"),
                    ]),
                  ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }