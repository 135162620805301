<template>
  <div class="reminder" v-za-loading="isLoading">
    <div class="title">返利概况</div>
    <!--    <za-picker :timeList="timeList" @changeTime="changeTime" :range="dataPickType"></za-picker>-->
    <div class="card_bottom">
      <el-row>
        <el-col :span="8" class="cardUl" v-for="(item, index) in amountArr" :key="index">
          <div class="title">{{ item.label }}</div>
          <div class="operation">
            <span>¥</span>
            <span>{{ item.value }}</span>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
  // import zaPicker from '@/za/components/zaPicker'
  import { apiPopularizeSettlementTotal } from '@/api/management/settlementDetails'

  export default {
    // components: { zaPicker },
    data() {
      return {
        subObj: {
          startAt: 0,
          endAt: 0,
        },
        amountArr: [
          {
            label: '返利总金额',
            value: 0,
            name: 'allAmount',
          },
          {
            label: '已结算返利金额',
            value: 0,
            name: 'settled',
          },
          {
            label: '待结算返利金额',
            value: 0,
            name: 'toSettled',
          },
        ],
        timeList: [
          {
            value: 6,
            label: '本月',
          },
          {
            value: 13,
            label: '三个月',
          },
          {
            value: 9,
            label: '六个月',
          },
        ],
        dataPickType: 'monthrange',
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.onSubmitData(apiPopularizeSettlementTotal, this.subObj, null, false, false, (res) => {
          const resArr = Object.keys(res.data)
          resArr.pop()
          resArr.map((item, index) => {
            if (item === this.amountArr[index].name) this.amountArr[index].value = res.data[item]
          })
        })
      },
      changeTime(val) {
        this.subObj.startAt = val._startTime
        this.subObj.endAt = val._endTime
        this.getList()
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import '~@/za/styles/mixin.scss';

  .reminder {
    height: 210px;
    width: 50%;
    background: #ffffff;
    margin-left: 16px;
    padding: 20px;

    .title {
      @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
      width: 100%;
      height: 20px;
      margin-bottom: 14px;
    }

    .card_bottom {
      .cardUl {
        height: 90px;
        padding-left: 10px;
        margin-top: 50px;

        .title {
          font-size: 12px;
          width: 100%;
        }

        .operation {
          padding-top: 10px;
          font-size: 20px;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: 500;
          color: #000000;
        }
      }
    }
  }

  ::v-deep {
    .el-card__body {
      padding: 10px !important;
    }
  }
</style>