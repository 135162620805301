<template>
  <div class="info-center_bg">
    <div>
      <za-go-back title="订单信息" @click="goBack"></za-go-back>
    </div>
    <div class="order-card" v-za-loading="isLoading">
      <div class="title">订单信息</div>
      <div class="infoStyle">
        <div class="testInfo">
          <span>订单编号：</span>
          <span>{{ orderNumber }}</span>
        </div>
        <div class="testInfo">
          <span>订单类型：</span>
          <span>{{ orderType }}</span>
        </div>
        <div class="testInfo">
          <span>产品名称：</span>
          <span>{{ serviceName || '-' }}</span>
        </div>
        <div class="testInfo">
          <span>订单状态：</span>
          <span>{{ orderState }}</span>
        </div>
        <div class="testInfo">
          <span>订单创建时间：</span>
          <span>{{ createAt }}</span>
        </div>
        <div class="testInfo">
          <span>订单支付时间：</span>
          <span>{{ payAt }}</span>
        </div>
        <div class="testInfo">
          <span>付费方式：</span>
          <span>{{ payType == '1' ? '预付费' : payType == '2' ? '后付费' : '预付+后付费' }}</span>
        </div>
      </div>
    </div>
    <div class="order-main" v-za-loading="isLoading">
      <div class="title">订单配置</div>
      <occ-table :tableData="tableData" :columObj="columObj" :isOp="columObj.isOp"></occ-table>
      <div class="moneyInfo">
        <div>
          <span>订单金额:</span>
          <span>¥ {{ price }}</span>
        </div>
        <div>
          <span>活动优惠:</span>
          <span>¥ {{ discountPrice }}</span>
        </div>
        <div>
          <span>订单实付:</span>
          <span>¥ {{ actualPrice }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ZaGoBack from '../../../../../za/components/zaGoBack/index'
import { apiMyOrderDetail } from '@/api/moneyCenter/orderManagement/myOrder'

export default {
  components: { ZaGoBack },
  data() {
    return {
      tableData: [],
      columObj: {
        // 控制选择框
        selection: false,
        isOp: false,
        width: '300px',
        columnData: [
          {
            text: true,
            prop: 'serviceName',
            label: '产品名称',
            width: '',
            flag: true,
            align: 'center',
          },
          {
            ownInnerHtml: true,
            prop: 'hortIds',
            label: '资源ID',
            flag: true,
            showOverFlow: false,
            width: '240',
            align: 'center',
            htmlTest: (row, index) => {
              if (row.hortIds && row.hortIds.length) {
                let result = []
                row.hortIds.forEach((item) => {
                  result.push('<span>' + `${item.replace(/\"|\'|/g, '')}` + '</span><br>')
                })
                let str = JSON.stringify(result)
                let name = str.replace(/\[|]/g, '')
                return name.replace(/\"|\'|\,|/g, '')
              } else {
                return `<span>-</span>`
              }
            },
          },
          {
            text: true,
            prop: 'configurations',
            label: '产品配置',
            flag: true,
            showOverFlow: false,
            width: '240',
            align: 'center',
          },
          {
            ownDefined: true,
            prop: 'count',
            label: '数量',
            flag: true,
            width: '',
            align: 'center',
            ownDefinedReturn: (row) => {
              return 'x' + row.count
            },
          },
          {
            text: true,
            prop: 'duration',
            label: '购买时长',
            flag: true,
            width: '',
            align: 'center',
          },
          {
            text: true,
            prop: 'catalogPrice',
            label: '单价',
            flag: true,
            width: '',
            align: 'center',
          },
          {
            text: true,
            prop: 'billing',
            label: '计费方式',
            flag: true,
            width: '',
            align: 'center',
          },
          // {
          //     text: true,
          //     prop: "discount",
          //     label: "折扣",
          //     flag: true,
          //     width: "",
          //     align: "center",

          // },
          {
            ownDefined: true,
            prop: 'productPrice',
            label: '产品金额',
            color: 'red',
            flag: true,
            width: '',
            align: 'center',
            ownDefinedReturn: (row) => {
              return '¥' + row.productPrice
            },
          },
          // {
          //     text: true,
          //     prop: "couponName",
          //     label: "代金券金额",
          //     flag: true,
          //     width: "",
          //     align: "center",

          // },
          // {
          //     ownDefined: true,
          //     prop: "paymentPrice",
          //     label: "现金已付",
          //     flag: true,
          //     color: 'red',
          //     width: "",
          //     align: "center",
          //     ownDefinedReturn: ((row) => {
          //         return '¥' + row.paymentPrice
          //     })
          // },
        ],
      },
      orderNumber: '',
      createAt: '',
      orderType: null,
      serviceName: '',
      payType: '',
      payAt: '',
      orderState: '',
      price: '',
      actualPrice: '',
      discountPrice: '',
      oderType: [
        { text: '新购', value: 0 },
        { text: '续费', value: 1 },
        { text: '升配', value: 3 },
        { text: '降配', value: 4 },
        { text: '退款', value: 7 },
      ],
    }
  },
  created() {
    if (this.$route.query.orderNumber) {
      this.getInfo(this.$route.query.orderNumber)
    }
  },
  methods: {
    getInfo(id = '') {
      let result = {
        orderNumber: id,
      }
      this.onSubmitData(apiMyOrderDetail, result, null, false, false, (res) => {
        // SSL证书单独添加活动优惠字段
        if (res.data.serviceName === 'SSL证书') {
          const obj = {
            text: true,
            prop: 'baiduDiscountPrice',
            label: '活动优惠',
            flag: true,
            showOverFlow: false,
            align: 'center',
          }
          this.columObj.columnData.splice(6, 0,obj)
        }
        this.tableData = res.data.orderDetail
        this.orderNumber = res.data.orderNumber
        this.createAt = res.data.createAt
        this.serviceName = res.data.serviceName
        // this.orderType = res.data.orderType
        this.payType = res.data.payType
        this.payAt = res.data.payAt
        this.orderState = res.data.orderState
        this.price = res.data.price
        this.discountPrice = res.data.discountPrice
        this.actualPrice = res.data.actualPrice
        this.oderType.forEach((item) => {
          if (item.value == res.data.orderType) {
            this.orderType = item.text
          }
        })
      })
    },
    handleSelectionChange() {},
    goBack() {
      this.$router.push('/moneyCenter/orderManagement/myOrder')
    },
  },
}
</script>
<style lang="scss" scoped>
.title {
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  margin-bottom: 14px;
  font-size: 14px;
}

.order-card {
  height: 124px;
  background: #ffffff;
  border-radius: 2px;
  padding: 20px;
  font-size: 14px;

  .infoStyle {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    color: #555555;

    .testInfo {
      margin-bottom: 10px;

      span:last-child {
        color: #000000;
      }
    }
  }
}

.order-main {
  margin-top: 20px;

  background: #ffffff;
  padding: 20px;

  .order-car {
    padding: 20px;
  }

  .moneyInfo {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    margin-left: 90%;
    margin-top: 20px;

    div {
      margin-bottom: 10px;

      span:last-child {
        color: #555555;
      }
    }

    div:last-child {
      font-size: 16px;

      span:last-child {
        font-size: 16px;
        color: #eb2f2f;
      }
    }
  }
}
</style>