var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [_c("loginHeard", { attrs: { title: "注册" } })], 1),
    _c("div", { staticClass: "heard" }, [
      _c("div", { staticClass: "center" }, [
        _c(
          "div",
          { staticClass: "form_pwd" },
          [
            _c(
              "p",
              { staticStyle: { "text-align": "left", "margin-left": "145px" } },
              [_vm._v("智安云账号关联")]
            ),
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  disabled: _vm.confirmBtnLoading,
                  model: _vm.baseForm,
                  "label-position": "left",
                  "label-width": "140px",
                  rules: _vm.rules,
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "name" } },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: _vm.translateTitle(
                          "register.placeAccount"
                        ),
                        readonly: _vm.confirmBtnLoading,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.clangeWaringDesc()
                        },
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          return _vm.trim("number")
                        },
                      },
                      model: {
                        value: _vm.baseForm.name,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.baseForm,
                            "name",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "baseForm.name",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "password" } },
                  [
                    _c("el-input", {
                      ref: "password",
                      attrs: {
                        placeholder: _vm.translateTitle("register.placePwd"),
                        readonly: _vm.confirmBtnLoading,
                        type: _vm.passwordType,
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          return _vm.trim("password")
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          _vm.passwordType === "password"
                            ? {
                                key: "suffix",
                                fn: function () {
                                  return [
                                    _c("za-svg-icon", {
                                      attrs: {
                                        "class-name": "svg-suffix",
                                        "icon-class": "za-eye-close",
                                      },
                                      on: { click: _vm.handlePassword },
                                    }),
                                  ]
                                },
                                proxy: true,
                              }
                            : {
                                key: "suffix",
                                fn: function () {
                                  return [
                                    _c("za-svg-icon", {
                                      attrs: {
                                        "class-name": "svg-suffix",
                                        "icon-class": "za-eye-open",
                                      },
                                      on: { click: _vm.handlePassword },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.baseForm.password,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.baseForm,
                            "password",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "baseForm.password",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "confirmPassword" } },
                  [
                    _c("el-input", {
                      ref: "newPasswordType",
                      attrs: {
                        placeholder: _vm.translateTitle(
                          "register.placeConfirmPwd"
                        ),
                        readonly: _vm.confirmBtnLoading,
                        type: _vm.newPasswordType,
                        maxlength: "30",
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          return _vm.trim("confirmPassword")
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          _vm.newPasswordType === "password"
                            ? {
                                key: "suffix",
                                fn: function () {
                                  return [
                                    _c("za-svg-icon", {
                                      attrs: {
                                        "class-name": "svg-suffix",
                                        "icon-class": "za-eye-close",
                                      },
                                      on: { click: _vm.handleNewPassword },
                                    }),
                                  ]
                                },
                                proxy: true,
                              }
                            : {
                                key: "suffix",
                                fn: function () {
                                  return [
                                    _c("za-svg-icon", {
                                      attrs: {
                                        "class-name": "svg-suffix",
                                        "icon-class": "za-eye-open",
                                      },
                                      on: { click: _vm.handleNewPassword },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.baseForm.confirmPassword,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.baseForm,
                            "confirmPassword",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "baseForm.confirmPassword",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "number" } },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "请输入要绑定的手机号码",
                        disabled: _vm.phoneDisabled,
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          return _vm.newTrim("number")
                        },
                      },
                      model: {
                        value: _vm.baseForm.number,
                        callback: function ($$v) {
                          _vm.$set(_vm.baseForm, "number", $$v)
                        },
                        expression: "baseForm.number",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { required: "" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 16 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "code" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入验证码！" },
                              on: {
                                input: function ($event) {
                                  return _vm.changeWaring()
                                },
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  return _vm.newTrim("code")
                                },
                              },
                              model: {
                                value: _vm.baseForm.code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.baseForm, "code", $$v)
                                },
                                expression: "baseForm.code",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("el-col", { attrs: { span: 1 } }, [_vm._v(" ")]),
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "sendInfo",
                            class: { grey: _vm.isGrey, blue: !_vm.isGrey },
                            attrs: { disabled: _vm.dis },
                            on: { click: _vm.sendData },
                          },
                          [
                            _vm.telState == "1"
                              ? _c("span", [_vm._v("发送验证码")])
                              : _vm._e(),
                            _vm.telState == "2"
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.count) + "秒重试"),
                                ])
                              : _vm._e(),
                            _vm.telState == "3"
                              ? _c("span", [_vm._v("重新发送")])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("el-col", [
                      _vm.waringState
                        ? _c("span", { staticClass: "red-font" }, [
                            _vm._v(_vm._s(_vm.waringText)),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
                _c("el-form-item", [
                  _c(
                    "div",
                    { staticClass: "prompt_btn" },
                    [
                      _c(
                        "za-button",
                        {
                          staticClass: "button",
                          attrs: { loading: _vm.confirmBtnLoading, size: 14 },
                          on: { click: _vm.doubleNext },
                        },
                        [_vm._v("提交")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "el-dialog",
                      {
                        attrs: {
                          width: "25%",
                          title: "验证",
                          visible: _vm.innerVisible,
                          "append-to-body": "",
                        },
                        on: {
                          "update:visible": function ($event) {
                            _vm.innerVisible = $event
                          },
                        },
                      },
                      [
                        _vm.showVerity
                          ? _c("za-verify", {
                              ref: "verify",
                              attrs: { selectKey: _vm.dialogType },
                              on: { verifySuccess: _vm.success },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-dialog",
                  {
                    staticStyle: { position: "absolute", top: "200px" },
                    attrs: {
                      title: "提示",
                      visible: _vm.dialogVisible,
                      width: "400px",
                      "append-to-body": "",
                      "close-on-click-modal": false,
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogVisible = $event
                      },
                      close: _vm.reloadBaseForm,
                    },
                  },
                  [
                    _c("span", [_vm._v("该手机号已存在，是否需要绑定微信")]),
                    _c(
                      "span",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c("el-button", { on: { click: _vm.reloadBaseForm } }, [
                          _vm._v("取 消"),
                        ]),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.confirmBind },
                          },
                          [_vm._v("确 定")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _c("div", [_c("loginFoot")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }