var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "back-con", on: { click: _vm.handleClick } },
    [
      _c(
        "span",
        { staticStyle: { "margin-left": "16px" } },
        [_c("za-svg-icon", { attrs: { "icon-class": "za-go-back" } })],
        1
      ),
      _c("span", [_vm._v(_vm._s(_vm.title))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }