<template>
  <div>
    <div v-if="show">
      <div :class="[{ enable: enable==true||enable=='1' }, { disabled: enable==false||enable=='0' }]" class="state"/>
      <span>{{ enable==true||enable=='1' ? enabledTxt : disabledTxt }}</span>
    </div>
    <div v-else>
      <div :style="{borderColor}" class="state"/>
      <span>{{ txt }}</span>
    </div>
  </div>
</template>

<script>
import {translateTitle} from '@/utils/i18n'

export default {
  name: 'ZaState',
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    borderColor: {
      type: String,
      default: 'red'
    },
    txt: {
      type: String,
      default: '失败'
    },
    enable: {
      type: Boolean|String,
      default: true,
    },
    enabledTxt: {
      type: String,
      default: translateTitle('status.enable'),
    },
    disabledTxt: {
      type: String,
      default: translateTitle('status.disable'),
    },
  },
  methods: {
    translateTitle,
  },
}
</script>

<style lang="scss" scoped>
@import '~@/za/styles/mixin.scss';

div {
  @include flex_div($padding: 0);
  justify-content: center;

  .state {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    border: 1px solid transparent;
    margin-right: 13px;
  }

  .enable {
    background: rgba(82, 196, 26, 1);
  }

  .disabled {
    background: #ff0000;
  }
}
</style>
