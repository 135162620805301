var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-switch",
    _vm._g(
      _vm._b(
        { attrs: { "active-color": "#00D2ED", "inactive-color": "#80A4BC" } },
        "el-switch",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }