var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "za-loading",
          rawName: "v-za-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "details",
    },
    [
      _c(
        "el-card",
        [
          _c("div", { staticClass: "title" }, [_vm._v("消费明细")]),
          _c("occ-table", {
            attrs: {
              tableData: _vm.tableData,
              columObj: _vm.columObj,
              isOp: _vm.columObj.isOp,
              styleHeight: _vm.height,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }