import request from '@/utils/request'
import { GET_POPULARIZE_TOTAL,METHOD,GET_POPULARIZE_LIST} from  '@/api/api.config'
//获取关联客户统计
export function apiPopularizeUserTotal(data) {
    return request({
        url:  GET_POPULARIZE_TOTAL,
        method: METHOD.GET,
        params: data,
    })
}
//获取关联客户列表
export function apiPopularizeUserList(data) {
    return request({
        url:  GET_POPULARIZE_LIST,
        method: METHOD.GET,
        params: data,
    })
}