import Vue from 'vue'
import store from '@/store'
import {Loading} from 'element-ui'
import {getLoadingConfig, sendLoadingConfig} from '@/config'

Vue.directive('hasPermi', {
  inserted(el, binding) {
    const {value} = binding
    if (!hasPermi(value)) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  },
})

export function hasPermi(arr) {
  const all_permission = '*:*:*'
  const permissions = store.getters && store.getters['routes/permissions']

  if (!arr) return false

  let curArr = []
  if (arr instanceof Object) {
    for (const key in arr) {
      curArr.push(arr[key])
    }
  } else{ 
    // debugger
    curArr = arr}

  if (curArr instanceof Array && curArr.length > 0) {
    let permissionFlag = curArr
    return permissions.some((permission) => {
      return all_permission === permission || permissionFlag.includes(permission)
    })
  }
  return false
}

Vue.directive('zaLoading', {
  bind(el, binding) {
    el.__zaLoading__ = null
    loading(el, binding.value, binding.modifiers['send'])
  },
  update(el, binding) {
    loading(el, binding.value, binding.modifiers['send'])
  },
  unbind(el) {
    if (el.__zaLoading__) el.__zaLoading__.close()
    delete el.__zaLoading__
  },
})

function loading(target, isOpen, isSend) {
  if (target.__zaLoading__ || !isOpen) {
    if (target.__zaLoading__ !== null) target.__zaLoading__.close()
    target.__zaLoading__ = null
  }
  if (isOpen) {
    const options = {
      target,
      lock: true,
      text: !isSend ? getLoadingConfig.tips : sendLoadingConfig.tips,
      spinner: !isSend ? getLoadingConfig.gif : sendLoadingConfig.gif,
      background: !isSend ? getLoadingConfig.bg : sendLoadingConfig.bg,
    }
    target.__zaLoading__ = Loading.service(options)
  }
}
