var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "za-loading",
          rawName: "v-za-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "verification-center_bg",
    },
    [
      _c(
        "div",
        [
          _c("za-go-back", {
            attrs: { title: "安全设置/" + _vm.title },
            on: { click: _vm.goBack },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c("div", { staticClass: "prompt" }, [
            _c(
              "span",
              { staticStyle: { "margin-left": "16px" } },
              [_c("za-svg-icon", { attrs: { "icon-class": "za-wanning" } })],
              1
            ),
            _c("span", [
              _vm._v("为确保账号是您本人操作，请任意选择一种方式验证身份"),
            ]),
          ]),
          _c(
            "div",
            _vm._l(_vm.list, function (item, index) {
              return _c("div", { key: index }, [
                item.state == 1
                  ? _c("div", { staticClass: "container" }, [
                      _c(
                        "div",
                        { staticClass: "bg" },
                        [_c("el-image", { attrs: { src: item.url } })],
                        1
                      ),
                      _c("div", { staticClass: "verification" }, [
                        _c("div", [
                          _c("div", { staticClass: "heard_tltle" }, [
                            _vm._v(" " + _vm._s(item.title) + " "),
                            item.tel
                              ? _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(
                                    _vm._s(item.tel + _vm.baseForm.phone + ")")
                                  ),
                                ])
                              : _vm._e(),
                            item.email
                              ? _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(
                                    _vm._s(
                                      item.email + _vm.baseForm.email + ")"
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "heard_foot" }, [
                            _vm._v(_vm._s(item.desc)),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.info(item.fun)
                              },
                            },
                          },
                          [
                            _c("el-button", { attrs: { type: "primary" } }, [
                              _vm._v(_vm._s(item.btn)),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          ),
          _c(
            "za-dialog",
            {
              attrs: {
                "top-title": _vm._f("dialogTitle")(
                  _vm.dialogType,
                  _vm.dialogTypeObj
                ),
                visible: _vm.dialogVisible,
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "prompt" },
                    [
                      _c("za-svg-icon", {
                        staticStyle: { "margin-left": "16px" },
                        attrs: { "icon-class": "za-wanning" },
                      }),
                      _vm.dialogType !== 3
                        ? _c("span", [
                            _vm._v(
                              "若1分钟内未收到短信提示，建议在垃圾短信中查看!"
                            ),
                          ])
                        : _vm._e(),
                      _vm.dialogType == 3
                        ? _c("span", [
                            _vm._v(
                              "请妥善保管密保问题，设置后可用于找回密码、变更安全设置等!"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        disabled: _vm.confirmBtnLoading,
                        model: _vm.baseForm,
                        "label-position": "right",
                        "label-width": "140px",
                        rules: _vm.rules,
                      },
                    },
                    [
                      _vm.dialogType == "1"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "已验证手机" + "：" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: true },
                                model: {
                                  value: _vm.baseForm.phone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.baseForm, "phone", $$v)
                                  },
                                  expression: "baseForm.phone",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.dialogType == "1"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "短信验证码" + "：",
                                required: "",
                              },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 16 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { prop: "smsCode" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入验证码！",
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.changeWaring()
                                              },
                                            },
                                            model: {
                                              value: _vm.baseForm.smsCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.baseForm,
                                                  "smsCode",
                                                  $$v
                                                )
                                              },
                                              expression: "baseForm.smsCode",
                                            },
                                          }),
                                          _vm.waringState
                                            ? _c(
                                                "span",
                                                { staticClass: "red-font" },
                                                [_vm._v(_vm._s(_vm.waringText))]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("el-col", { attrs: { span: 2 } }, [
                                    _vm._v(" "),
                                  ]),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "sendInfo",
                                          class: {
                                            grey: _vm.isGrey,
                                            blue: !_vm.isGrey,
                                          },
                                          attrs: { disabled: _vm.dis },
                                          on: { click: _vm.sendData },
                                        },
                                        [
                                          _vm.telState == 1
                                            ? _c("span", [_vm._v("发送验证码")])
                                            : _vm._e(),
                                          _vm.telState == 2
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.count) + "秒重试"
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm.telState == 3
                                            ? _c("span", [_vm._v("重新发送")])
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.dialogType == "2"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "已验证邮箱" + "：" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: true },
                                model: {
                                  value: _vm.baseForm.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.baseForm, "email", $$v)
                                  },
                                  expression: "baseForm.email",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.dialogType == "2"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "邮箱验证码" + "：",
                                required: "",
                              },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 16 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { prop: "smsCode" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入验证码",
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.changeWaring()
                                              },
                                            },
                                            model: {
                                              value: _vm.baseForm.smsCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.baseForm,
                                                  "smsCode",
                                                  $$v
                                                )
                                              },
                                              expression: "baseForm.smsCode",
                                            },
                                          }),
                                          _vm.waringState
                                            ? _c(
                                                "span",
                                                { staticClass: "red-font" },
                                                [_vm._v(_vm._s(_vm.waringText))]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("el-col", { attrs: { span: 2 } }, [
                                    _vm._v(" "),
                                  ]),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "sendInfo",
                                          class: {
                                            two_grey: _vm.two_isGrey,
                                            two_blue: !_vm.two_isGrey,
                                          },
                                          attrs: { disabled: _vm.two_dis },
                                          on: { click: _vm.sendData },
                                        },
                                        [
                                          _vm.two_telState == 1
                                            ? _c("span", [_vm._v("发送验证码")])
                                            : _vm._e(),
                                          _vm.two_telState == 2
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.two_count) +
                                                    "秒重试"
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm.two_telState == 3
                                            ? _c("span", [_vm._v("重新发送")])
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.dialogType == "3"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "密保问题" + "：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder:
                                    _vm.translateTitle("placeTxt") +
                                    _vm.translateTitle("askfor.contract"),
                                  disabled: true,
                                },
                                model: {
                                  value: _vm.baseForm.oneQuestion,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.baseForm, "oneQuestion", $$v)
                                  },
                                  expression: "baseForm.oneQuestion",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.dialogType == "3"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "请输入答案" + "：",
                                prop: "oneAnswer",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入密保答案" },
                                on: {
                                  input: function ($event) {
                                    return _vm.changePwdWaring()
                                  },
                                },
                                model: {
                                  value: _vm.baseForm.oneAnswer,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.baseForm, "oneAnswer", $$v)
                                  },
                                  expression: "baseForm.oneAnswer",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.dialogType == "3"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "密保问题" + "：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder:
                                    _vm.translateTitle("placeTxt") +
                                    _vm.translateTitle("askfor.contract"),
                                  disabled: true,
                                },
                                model: {
                                  value: _vm.baseForm.twoQuestion,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.baseForm, "twoQuestion", $$v)
                                  },
                                  expression: "baseForm.twoQuestion",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.dialogType == "3"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "请输入答案" + "：",
                                prop: "twoAnswer",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入密保答案" },
                                on: {
                                  input: function ($event) {
                                    return _vm.changePwdWaring()
                                  },
                                },
                                model: {
                                  value: _vm.baseForm.twoAnswer,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.baseForm, "twoAnswer", $$v)
                                  },
                                  expression: "baseForm.twoAnswer",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.dialogType == "3"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "密保问题" + "：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder:
                                    _vm.translateTitle("placeTxt") +
                                    _vm.translateTitle("askfor.contract"),
                                  disabled: true,
                                },
                                model: {
                                  value: _vm.baseForm.threeQuestion,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.baseForm, "threeQuestion", $$v)
                                  },
                                  expression: "baseForm.threeQuestion",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.dialogType == "3"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "请输入答案" + "：",
                                prop: "threeAnswer",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入密保答案" },
                                on: {
                                  input: function ($event) {
                                    return _vm.changePwdWaring()
                                  },
                                },
                                model: {
                                  value: _vm.baseForm.threeAnswer,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.baseForm, "threeAnswer", $$v)
                                  },
                                  expression: "baseForm.threeAnswer",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        [
                          _c(
                            "el-dialog",
                            {
                              attrs: {
                                width: "25%",
                                title: "验证",
                                visible: _vm.innerVisible,
                                "append-to-body": "",
                              },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.innerVisible = $event
                                },
                              },
                            },
                            [
                              _vm.showVerity
                                ? _c("za-verify", {
                                    ref: "verify",
                                    attrs: { selectKey: _vm.dialogType },
                                    on: { verifySuccess: _vm.success },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _vm.waringPwd
                    ? _c("span", { staticClass: "red-font" }, [
                        _vm._v(_vm._s(_vm.warringQuestion)),
                      ])
                    : _vm._e(),
                  _c(
                    "za-button",
                    {
                      attrs: {
                        disabled: _vm.confirmBtnLoading,
                        type: _vm.buttonTypeObj.GREY,
                      },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.translateTitle("btn.cancel")) + " "
                      ),
                    ]
                  ),
                  _c(
                    "za-button",
                    {
                      attrs: {
                        loading: _vm.confirmBtnLoading,
                        type: _vm.buttonTypeObj.BLUE,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmitForm(_vm.dialogType)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.translateTitle("btn.submit")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }