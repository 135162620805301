<template>
  <div class="drawAbill-center-bg">
    <div>
      <za-go-back title="返回发票列表" @click="goBack"></za-go-back>
    </div>
    <div class="main">
      <div class="prompt">
        <span class="orange_font_bold">
          普票电子化通知：为积极响应国家发票无纸化政策，智安云云将于2023年10月1日起关闭增值税电子发票和增值税纸质发票申请入口，您可以直接申请全电发票。感谢您的支持！
        </span>
        <h5>{{ translateTitle('topUp.reminder') }}：</h5>
        <span>
          1.智安云已支持全电发票，推荐您使用全电发票。
          <span class="orange_font">
            电子发票与纸质发票具有同等法律效力，可在发票通过审核后24小时内开出并发送到您的邮箱中。
          </span>
        </span>
        <span>
          2.后付费资源产生的消费次月账期结算后计入发票总额，当月发票仅能选择预支付订单直接开票，如有问题请及时联系客服。
        </span>
        <span>3.若当前可用余额欠费，后付费资源产生的费用无法开票，请充值后刷新页面重试。</span>
      </div>
      <div class="payType">
        <div style="width: 100%">
          <el-tabs v-model="activeName">
            <el-tab-pane label="按月份开票" name="first">
              <mouth v-if="activeName == 'first'"></mouth>
            </el-tab-pane>
            <el-tab-pane label="按订单开票" name="second">
              <order v-if="activeName == 'second'"></order>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import mouth from './components/mouth'
  import order from './components/order'

  export default {
    components: { mouth, order },
    data() {
      return {
        activeName: 'first',
      }
    },
    methods: {
      goBack() {
        this.$router.push('/moneyCenter/invoiceManagement/askfor')
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import '~@/za/styles/mixin.scss';

  .drawAbill-center-bg {
    .main {
      background: #ffffff;
      padding: 20px;

      .prompt {
        width: 100%;
        height: 150px;
        background: #fffbe6;
        border-radius: 2px;
        border: 1px solid #ffe58f;
        padding: 15px 15px;
        margin-bottom: 30px;

        .orange_font_bold {
          display: inline;
          color: orange;
          font-weight: 700;
          font-size: 14px;
        }

        .orange_font {
          display: inline;
          color: orange;
        }

        h5 {
          @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
          margin: 10px 0;
        }

        span {
          display: block;
          font-size: 12px;
          margin-bottom: 8px;
          color: #555555;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }
      }
    }
  }
</style>