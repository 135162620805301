import request from '@/utils/request'
import {
    METHOD,GET_DENGBAO_ACTIVITES,GET_DENGBAO_SUBMIT
} from '@/api/api.config'
/**
 * @description //等保活动-发送验证码
 * @param data
 * @returns {AxiosPromise}
 */
export function apiDengbaoSendCode(data) {
    return request({
        url: GET_DENGBAO_ACTIVITES,
        method: METHOD.POST,
        data,
    })
}
/**
 * @description //等保活动-提交
 * @param data
 * @returns {AxiosPromise}
 */
export function apiDengbaoSubmit(data) {
    return request({
        url: GET_DENGBAO_SUBMIT,
        method: METHOD.POST,
        data,
    })
}
