var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [_c("navbar")], 1),
    _vm.url
      ? _c(
          "div",
          {
            directives: [
              {
                name: "za-loading",
                rawName: "v-za-loading",
                value: _vm.isLoading,
                expression: "isLoading",
              },
            ],
          },
          [
            _c("iframe", {
              staticClass: "iframe",
              attrs: {
                src: _vm.url,
                width: "100%",
                frameborder: "0",
                id: "iframe",
              },
            }),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }