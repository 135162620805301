var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.show
      ? _c("div", [
          _c("div", {
            staticClass: "state",
            class: [
              { enable: _vm.enable == true || _vm.enable == "1" },
              { disabled: _vm.enable == false || _vm.enable == "0" },
            ],
          }),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.enable == true || _vm.enable == "1"
                  ? _vm.enabledTxt
                  : _vm.disabledTxt
              )
            ),
          ]),
        ])
      : _c("div", [
          _c("div", {
            staticClass: "state",
            style: { borderColor: _vm.borderColor },
          }),
          _c("span", [_vm._v(_vm._s(_vm.txt))]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }