<template>
  <div class="cardCenter" v-za-loading="isLoading">
    <div class="prompt">
      <span style="margin-left: 16px">
        <za-svg-icon icon-class="za-wanning" class-name="za-warning"></za-svg-icon>
      </span>
      <span>
        新客户通过推广者的推广链接注册/登录，即与推广者建立30天关联
        <!--        <span class="blue_font" @click="goToPortal">查看客户关联规则</span>-->
      </span>
    </div>
    <div class="card_bottom">
      <div class="cardUl" v-for="(item, index) in enumArr" :key="index">
        <div class="title">{{ item.label }}</div>
        <div class="operation">
          <div>
            <span>{{ item.value }}</span>
            <span>个</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { apiPopularizeUserTotal } from '@/api/management/correlation'

  export default {
    data() {
      return {
        enumArr: [
          {
            label: '当前关联客户数',
            value: 0,
            name: 'currentTotal',
          },
          {
            label: '推广期客户数',
            value: 0,
            name: 'popularizeTotal',
          },
          {
            label: '维护期客户数',
            value: 0,
            name: 'maintainTotal',
          },
          {
            label: '已失效客户数',
            value: 0,
            name: 'expireTotal',
          },
        ],
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.onSubmitData(apiPopularizeUserTotal, null, null, false, false, (res) => {
          const resArr = Object.keys(res.data)
          resArr.map((item, index) => {
            if (item === this.enumArr[index].name) this.enumArr[index].value = res.data[item]
          })
        })
      },
      goToPortal() {
        console.log('前往门户文档中心')
        // window.open(`${process.env.VUE_APP_PORTAL_TARGET_URL}/zhianCloud/domainAndNetWebsite/domainRegister`, '_self')
      },
    },
  }
</script>
<style lang="scss" scoped>
  .cardCenter {
    background-color: #ffffff;
    margin-top: 20px;
    height: 190px;
    margin-bottom: 20px;
    padding: 20px 15px;

    .prompt {
      height: 40px;
      background: #fffbe6;
      border-radius: 2px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 40px;

      .za-warning {
        margin-right: 10px;
      }
    }

    .card_bottom {
      display: flex;
      margin-top: 20px;

      .cardUl {
        height: 90px;
        margin-right: 20px;
        width: 230px;
        background: #f4f6fa;
        padding: 10px;

        .title {
          font-size: 12px;
          width: 100%;
        }

        .operation {
          display: flex;
          justify-content: space-between;
          margin-top: 22px;

          div:first-child {
            span:first-child {
              font-size: 24px;
              font-family: DINAlternate-Bold, DINAlternate;
              font-weight: bold;
              color: #000000;
            }
          }
        }
      }
    }
  }
</style>