var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "userHomeInfo" }, [
    _c("div", { staticClass: "user_top" }, [
      _c(
        "div",
        [
          _c(
            "el-image",
            {
              ref: "image",
              staticClass: "icon-html",
              attrs: { fit: "cover", src: _vm.icoUrl() },
            },
            [
              _c("div", { attrs: { slot: "error" }, slot: "error" }, [
                _c("i", { staticClass: "el-icon-picture-outline" }),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "user_text" }, [
        _c("div", [_vm._v(_vm._s(_vm.userInfo.username))]),
        _c(
          "div",
          {
            staticClass: "user_certification",
            on: {
              click: function ($event) {
                return _vm.$router.push("/accountCenter/nameCertification")
              },
            },
          },
          [
            _vm.userInfo.accountType == 0
              ? _c("div", [
                  _c("div", { staticClass: "not_certification" }, [
                    _vm._v("未认证"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "now",
                      on: {
                        click: function ($event) {
                          return _vm.$router.push(
                            "/accountCenter/nameCertification"
                          )
                        },
                      },
                    },
                    [_vm._v("立即认证")]
                  ),
                ])
              : _vm._e(),
            _vm.userInfo.accountType == 2
              ? _c("div", [
                  _c("div", { staticClass: "not_certification individual" }, [
                    _vm._v("个人认证"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "now",
                      on: {
                        click: function ($event) {
                          return _vm.$router.push(
                            "/accountCenter/nameCertification"
                          )
                        },
                      },
                    },
                    [_vm._v("升级为企业认证")]
                  ),
                ])
              : _vm._e(),
            _c("div", [
              _vm.userInfo.accountType == 1
                ? _c("div", { staticClass: "not_certification enterprise" }, [
                    _vm._v("企业认证"),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
      ]),
    ]),
    _c("div", { staticClass: "infoId" }, [
      _c("div", [
        _c("span", [_vm._v("账号ID：")]),
        _c("span", [_vm._v(_vm._s(_vm.userInfo.uuid))]),
        _c("span", [
          _c("i", {
            staticClass: "el-icon-copy-document copyDocument",
            on: {
              click: function ($event) {
                return _vm.copy(_vm.userInfo.uuid)
              },
            },
          }),
        ]),
      ]),
      _c("div", [
        _c("span", [_vm._v("登录IP：")]),
        _c("span", [_vm._v(_vm._s(_vm.userInfo.ip))]),
      ]),
    ]),
    _c("div", { staticClass: "info_safety" }, [
      _c("div", { staticClass: "title" }, [_vm._v("账号安全")]),
      _c("div", { staticClass: "infoName" }, [
        _c("div", { staticClass: "infoIcon", on: { click: _vm.toSafetySet } }, [
          _c(
            "div",
            [
              _c("za-svg-icon", {
                attrs: {
                  "icon-class":
                    _vm.userInfo.mobileBind == 0
                      ? "za-phone"
                      : "za-certification-phone",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "beautiful" }, [_vm._v("手机绑定")]),
        ]),
        _c("div", { staticClass: "infoIcon", on: { click: _vm.toSafetySet } }, [
          _c(
            "div",
            [
              _c("za-svg-icon", {
                attrs: {
                  "icon-class":
                    _vm.userInfo.emailBind == 0
                      ? "za-mailbox"
                      : "za-certification-mailbox",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "beautiful" }, [_vm._v("邮箱绑定")]),
        ]),
        _c("div", { staticClass: "infoIcon", on: { click: _vm.toSafetySet } }, [
          _c(
            "div",
            [
              _c("za-svg-icon", {
                attrs: {
                  "icon-class":
                    _vm.userInfo.securityBind == 0
                      ? "za-pwd"
                      : "za-certification-pwd",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "beautiful" }, [_vm._v("密码保护")]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }