<template>
  <div>
    <navbar />
    <div v-if="url" v-za-loading="isLoading">
      <iframe
        :src="url"
        width="100%"
        frameborder="0"
        class="iframe"
        id="iframe"
        ref="iframe"
        @load="handleIframeLoad"
      ></iframe>
    </div>
    <za-dialog top-title="余额不足：" :visible.sync="isWarnDialog" width="800px" :showClose="false">
      <div>
        <div class="prompt" style="height: 90px">
          <span style="color: #faad13; margin-bottom: 15px">
            <za-svg-icon style="font-size: 16px; margin-right: 6px" icon-class="za-wanning"></za-svg-icon>
            {{ translateTitle('topUp.reminder') }}:
          </span>
          <span style="color: #555555">
            您当前账户可用余额小于云产品的起购金额100元，部分服务将访问受限，如需使用相关服务请保证账户余额充足再进行购买。
            <span style="color: #3370ff; display: inline; cursor: pointer" @click="reCharge">立即充值</span>
          </span>
        </div>
      </div>
      <div slot="footer">
        <za-button :type="buttonTypeObj.BLUE" @click="closeDialog">确定</za-button>
      </div>
    </za-dialog>

    <za-dialog :visible.sync="createDefeatDialog" width="800px" :showClose="true" class="orderCreate">
      <div class="create" style="height: 100px; display: flex">
        <div>
          <span style="color: #ff3b30; margin-top: 7px">
            <za-svg-icon style="font-size: 22px; margin-right: 10px" icon-class="za-error"></za-svg-icon>
          </span>
        </div>
        <div>
          <span style="color: #555555; font-size: 16px; font-weight: 500">订单创建失败</span>
          <span style="color: #777676">当前订单中包含后付费资源，资源开出后将按照实际使用情况进行扣费。</span>
          <span style="color: #777676">
            由于目前你的账户余额不足，暂时无法使用后付费服务，建议您及时充值，充值完成后可以重新选购产品。
            <span
              style="color: #3370ff; display: inline; cursor: pointer; text-decoration: underline"
              @click="reCharge"
            >
              去充值
            </span>
          </span>
        </div>
      </div>
    </za-dialog>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import Navbar from '@/za/layout/components/Navbar'
  import { apiOrderCreate } from '@/api/business/pay.js'
  import { apiBcdCreate } from '@/api/navbar/index.js'
  import enums from '@/utils/enums'

  import cookie from 'js-cookie'

  export default {
    name: 'Business',
    components: {
      Navbar,
    },
    data() {
      return {
        url: null,
        pageName: '',
        isWarnDialog: true,
        createDefeatDialog: false, //订单创建失败弹框
      }
    },
    computed: {
      ...mapGetters({
        balance: 'user/balance',
      }),
    },
    created() {},
    mounted() {
      // var iframeSrc = document.getElementById('iframe').src;
      // console.log(iframeSrc,'<<<iframeSrc')
      //监听message 收到数据e.data;
      // window.addEventListener('message', function (rs) {
      //     var href = rs.data;
      //     if (href != "" && href != host && href != undefined) {
      //         historyUrl = href;
      //     }
      // });
      // window.addEventListener("message", this.getOrderEvent(), false)
      window.onmessage = (e) => {
        // e.data?.orderInfo?.orderId
        if (e.data && e.data.orderInfo && e.data.orderInfo.orderId) {
          this.getOrder(e.data.orderInfo.orderId)
        }
      }
    },
    watch: {
      $route: {
        handler: function (val) {
          this.pageName = val.query.url
          this.balance.balance >= 0 ? (this.isWarnDialog = false) : (this.isWarnDialog = true)
        },
        immediate: true,
        deep: true,
      },
      pageName: {
        handler: async function (val) {
          if (val === 'bcdCreate') {
            let domains = []
            domains = JSON.parse(localStorage.getItem(enums.localKey.domains)) || []
            const accessToken = cookie.get('accessToken', { domain: process.env.VUE_APP_DOMAIN })
            const strings = accessToken.split('.') //截取token，获取载体
            const uuid = JSON.parse(
              decodeURIComponent(window.atob(strings[1].replace(/-/g, '+').replace(/_/g, '/')))
            ).userId
            const compainId = `search_purchase_data_${uuid}`
            this.newSetData(compainId, { domains }).then(() => {
              this.onSubmitData(apiBcdCreate, null, null, false, false, (res) => {
                // 跳转百度域名下单页
                this.url = res.data.url
                this.isLoading = false
              })
            })
          } else if (enums.iframePage[val]) this.allPage(enums.iframePage[val])
          this.isLoading = true
        },
        immediate: true,
        deep: true,
      },
    },
    methods: {
      async getOrder(id) {
        try {
          let result = { orderNumber: id }
          // console.log('调用getOrder')
          this.isLoading = true
          let res = await apiOrderCreate(result)
          this.isLoading = false
          if (res.code == 0) {
            this.$router.push({ path: '/orderPayment', query: { orderId: [id] } })
          }
        } catch (error) {
          this.createDefeatDialog = true
          this.isLoading = false
        }
        // this.onSubmitData(apiOrderCreate, result, null, false, false, (res) => {
        //     if(res.code==0){
        //         this.$router.push({path: '/orderPayment', query: {orderId: [id]}})
        //     }
        // })
      },
      // getOrderEvent(e){
      //     if (e.data.orderInfo.orderId) {
      //         this.getOrder(e.data.orderInfo.orderId)
      //     }
      // },
      allPage(val) {
        this.onSubmitData(val, null, null, false, false, (res) => {
          this.url = res.data.url
          this.isLoading = false
        })
      },
      handleIframeLoad() {
        // const iframeWin = document.getElementsByTagName('iframe')[0].contentWindow.location
        // const iframeWin2 = this.$refs.iframe.contentWindow
        // try {
        //     iframeWin.addEventListener('hashchange', this.handleIframeHashChange)
        // } catch (error) {
        //     console.log(error);
        // }
      },
      handleIframeHashChange(event) {
        console.log('iframe内部变化')
        console.log('变化事件', event)
      },
      closeDialog() {
        this.$router.push('/')
      },
      reCharge() {
        this.$router.push('/moneyCenter/expenseManagement/topUp')
      },
      isSandbox() {
        //判断是否为沙河环境，如果是沙盒环境，返回true，否则返回false
        return false
      },
      newSetData(key, value) {
        return new Promise((resolve, reject) => {
          // 通过isSandbox（）⽅法判断是否是沙盒环境,
          // 如果该⽅法返回true，env的取值为SANDBOX，否则env的取值为ONLINE
          const env = this.isSandbox() ? 'SANDBOX' : 'ONLINE' // 环境变量
          window.bceStorage.init(env)
          window.bceStorage
            .set({
              key,
              payload: {
                ...value,
                timestamp: new Date().getTime() + 24 * 60 * 60 * 1000, //存储在localStorage时，需要设置⼀个过期时间
              },
              storage: 'localStorage', // 既可以存储在localStorage，也可以存在sessionStorage
            })
            .then((data) => {
              resolve({ key, value })
            })
        })
      },

      // 跳转百度域名下单页面
      setData(key, value) {
        return new Promise((resolve) => {
          const FRAME_ID = 'iframe'
          let iframe = document.getElementById(FRAME_ID)
          if (!iframe) {
            iframe = document.createElement('iframe')
            iframe.id = 'iframe'
            iframe.style.cssText = 'position:absolute;zindex:10000;left:-9999px'
          }
          iframe.src = `https://cloud.baidu.com/helper/relay.html?_=${new Date().getTime()}`
          iframe.onload = () => {
            let data = {}
            data[key] = value
            iframe.contentWindow.postMessage(data, '*')
            resolve({ key, value })
          }
          document.body.appendChild(iframe)
        })
      },
    },
    beforeDestroy() {
      // 在组件生命周期结束的时候销毁。
      window.onmessage = null
    },
  }
</script>
<style scoped lang="scss">
  .iframe {
    height: $nobase-main-height;
  }

  .prompt {
    width: 100%;
    height: 174px;
    background: #fffbe6;
    border-radius: 2px;
    border: 1px solid #ffe58f;
    padding: 20px 18px;

    span {
      height: 17px;
      display: block;
      font-size: 12px;
      line-height: 17px;
      margin-bottom: 11px;
      color: #eb2f2f;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
  }

  .orderCreate {
    ::v-deep .el-dialog__header {
      height: 0;
    }

    ::v-deep .el-dialog__footer {
      display: none !important;
    }

    ::v-deep .el-dialog__body {
      padding: 0;
    }

    ::v-deep .el-dialog {
      display: flex;
      flex-direction: column;
      margin: 0 !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-height: calc(100% - 30px);
      max-width: calc(100% - 30px);
    }

    ::v-deep .el-dialog .el-dialog__body {
      flex: 1;
      overflow: auto;
    }

    .create {
      width: 100%;
      height: 174px;
      background: #ffeee6;
      border-radius: 2px;
      border: 1px solid #f7d8c8;
      padding: 20px 20px;

      span {
        height: 12px;
        display: block;
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 10px;
        color: #eb2f2f;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
    }
  }
</style>