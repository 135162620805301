var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dropdown",
    { attrs: { placement: "bottom" } },
    [
      _c("div", { class: [_vm.isHome ? "container" : "ignore_container"] }, [
        _c("div", { class: [_vm.isHome ? "user_txt" : "ignore_user_txt"] }, [
          _c("div", [
            _c("span", { staticClass: "userName" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.userInfo.username
                      ? _vm.userInfo.username.slice(0, 5)
                      : ""
                  ) +
                  " "
              ),
              (
                _vm.userInfo.username
                  ? _vm.userInfo.username.length > 5
                  : "ture"
              )
                ? _c("i", [_vm._v("...")])
                : _vm._e(),
            ]),
          ]),
          _c(
            "div",
            [
              _c(
                "el-image",
                {
                  ref: "image",
                  staticClass: "icon-html",
                  attrs: { fit: "cover", src: _vm.icoUrl() },
                },
                [
                  _c("div", { attrs: { slot: "error" }, slot: "error" }, [
                    _c("i", { staticClass: "el-icon-picture-outline" }),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "el-dropdown-menu",
        [
          _c("div", { staticClass: "userInfo" }, [
            _c("div", { staticClass: "infoName" }, [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.userInfo.username
                        ? _vm.userInfo.username.slice(0, 14)
                        : ""
                    ) +
                    " "
                ),
                (
                  _vm.userInfo.username
                    ? _vm.userInfo.username.length > 14
                    : "ture"
                )
                  ? _c("i", [_vm._v("...")])
                  : _vm._e(),
              ]),
              _c(
                "span",
                {
                  staticClass: "defalut",
                  class: [
                    _vm.userInfo.accountType === 0
                      ? "grey_bg"
                      : _vm.userInfo.accountType === 1
                      ? "green_bg"
                      : "yellow_bg",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.$router.push(
                        "/accountCenter/nameCertification"
                      )
                    },
                  },
                },
                [
                  _c("za-svg-icon", {
                    attrs: { "icon-class": "za-nav-realName" },
                  }),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.userInfo.accountType === 0
                          ? "未认证"
                          : _vm.userInfo.accountType === 1
                          ? "企业认证"
                          : "个人认证"
                      ) +
                      " "
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "infoId" }, [
              _c("span", [_vm._v("账号 ID：" + _vm._s(_vm.userInfo.uuid))]),
              _c("i", {
                staticClass: "el-icon-copy-document copyDocument",
                on: {
                  click: function ($event) {
                    return _vm.copy(_vm.userInfo.uuid)
                  },
                },
              }),
            ]),
          ]),
          _vm._l(_vm.optionsList, function (item, index) {
            return _c(
              "el-dropdown-item",
              {
                key: index,
                nativeOn: {
                  click: function ($event) {
                    return _vm.toPath(item.path, item)
                  },
                },
              },
              [
                _c("za-svg-icon", {
                  attrs: { "icon-class": item.icon, "class-name": "svg-item" },
                }),
                _c("span", [_vm._v(_vm._s(item.name))]),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }