var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "heardBlock orange_font_bold" }, [
        _vm._v(
          " 温馨提示：您已经成功通过个人认证（或企业认证），请不要随意变更实名认证信息，若您需要变更，请仔细阅读《 "
        ),
        _c("span", { staticClass: "blue_font" }, [
          _vm._v("申请账号主体的变更协议"),
        ]),
        _vm._v(" 》，您提交认证变更视为已知晓并同意该协议。 "),
      ]),
      _c("div", { staticClass: "main" }, [
        _c("div", { staticClass: "title" }, [
          _c("h3", [_vm._v("申请账号主体变更协议")]),
        ]),
        _c("span", [
          _vm._v(" 在您正式提交账号主体变更申请前，请您务必认真阅读本协议。 "),
          _c("span", { staticClass: "first" }, [
            _vm._v(
              " 本协议将通过加粗或加下划线的形式提示您特别关注对您的权利及义务将产生重要影响的相应条款。 "
            ),
          ]),
          _vm._v(
            " 请向智安云咨询，如果您不同意本协议的内容，或者无法准确理解智安云对条款的解释，请不要进行后续操作。 当您通过网络页面直接确认、接受引用本页面链接及提示遵守内容、签署书面协议、以及智安云认可的其他方式， 或以其他法律法规或惯例认可的方式选择接受本协议，即表示您与智安云已达成协议，并同意接受本协议的全部约定内容。自本协议约定的生效之日起，本协议对您具有法律约束力。 "
          ),
        ]),
        _c("br"),
        _c("br"),
        _c("span", { staticClass: "first" }, [
          _vm._v(
            " 请您务必在接受本协议，且确信通过账号主体变更的操作，能够实现您所希望的目的，且您能够接受因本次变更行为的相关后果与责任后，再进行后续操作。 "
          ),
        ]),
        _c("br"),
        _c("h5", [_vm._v("一、协议主体、内容与生效")]),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 1.1 本协议是特定智安云账号的账号持有人（“您”、“原主体”）与智安云之间， 就您申请将双方之前就本次申请主体变更的智安云账号所达成的《智安云用户服务协议》 的权利义务转让给第三方，及相关事宜所达成的一致条款。 "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft first" }, [
          _vm._v(
            " 1.2 本协议为附生效条件的协议，仅在以下三个条件同时满足的情况下，才对您及智安云产生法律效力： "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            "1.2.1 您所申请变更的智安云账号已完成了实名认证，且您为该实名认证主体；"
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("1.2.2 智安云审核且同意您的账号主体变更申请；"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 1.2.3 您申请将账号下权利义务转让给第三方（“新主体”），且其同意按照《接受账号主体变更协议》的约定，接受账号主体变更。 "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft first" }, [
          _vm._v(
            " 1.3 您与新主体就账号下所有的产品、服务、资金、债权、债务等（统称为“账号下资源”）转让等相关事项，由您与新主体之间另外自行约定。但如果您与新主体之间的约定如与本协议约定冲突的，应优先适用本协议的约定。 "
          ),
        ]),
        _c("br"),
        _c("h5", [_vm._v("二、定义和解释")]),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            "2.1 “智安云官网”：是指包含域名为cloud.baidu.com的网站以及智安云客户端，如APP。"
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            "2.2 “智安云账号”：是指智安云分配给注册用户的数字ID，以下简称为“智安云账号”、“账号”。"
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            "2.3 “智安云账号持有人”，是指注册、持有并使用智安云账号的用户。"
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 已完成实名认证的账号，除有相反证据外，智安云将根据用户的实名认证信息来确定账号持有人，如用户填写信息与实名认证主体信息不同的，以实名认证信息为准； 未完成实名认证的账号，智安云将根据用户的填写信息，结合其他相关因素合理判断账号持有人。 "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 2.4 “账号实名认证主体变更”：是指某一智安云账号的实名认证主体（原主体），变更为另一实名认证主体（新主体），本协议中简称为“账号主体变更”。 "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft first" }, [
          _vm._v(
            " 2.5 “账号实名认证主体变更”：是指某一智安云账号的实名认证主体（原主体），变更为另一实名认证主体（新主体），本协议中简称为“账号主体变更”。 "
          ),
        ]),
        _c("br"),
        _c("h5", [_vm._v("三、变更的条件及程序")]),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("3.1 智安云仅接受符合以下条件下的账号主体变更申请；"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            "3.1.1 由于原账号主体发生合并、分立、重组、解散、死亡等原因，需要进行账号主体变更的；"
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            "3.1.2 根据生效判决、裁定、裁决、决定等生效法律文书，需要账号主体变更的；"
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            "3.1.3 账号实际持有人与账号实名认证主体不一致，且提供了明确证明的；"
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("3.1.4 根据法律法规规定，应当进行账号主体变更的；"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            "3.1.5 智安云经过审慎判断，认为可以进行账号主体变更的其他情形。"
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("3.2 您发起账号主体变更，应遵循如下程序要求："),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("3.2.1 您应在申请变更的智安云账号下发起账号主体变更申请；"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 3.2.2 智安云有权通过手机号、人脸识别等进行二次验证、要求您出具授权证明（当您通过账号管理人发起变更申请时）、以及其他智安云认为有必要的材料，确认本次申请账号主体变更的行为确系您本人意愿； "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("3.2.3 您应同意本协议的约定，接受本协议对您具有法律约束力；"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            "3.2.4 您应遵守与账号主体变更相关的其他智安云规则、制度等的要求。"
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft first" }, [
          _vm._v("3.3 您理解并同意，"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft first" }, [
          _vm._v(
            "3.3.1 在新主体确认接受且完成实名认证前，您可以撤回、取消本账号主体变更流程；"
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft first" }, [
          _vm._v(
            "3.3.2 当新主体确认接受且完成实名认证后，您的撤销或取消请求智安云将不予支持；"
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft first" }, [
          _vm._v("3.3.3 且您有义务配合新主体完成账号管理权的转交。"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft first" }, [
          _vm._v(
            " 3.3.4 在您进行实名认证主体变更期间，本账号下的登录和操作行为均视为您的行为，您应注意和承担账号的操作风险。 "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft first" }, [
          _vm._v(
            " 3.4 您理解并同意，如果发现以下任一情形的，智安云有权随时终止账号主体变更程序或采取相应处理措施： "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft first" }, [
          _vm._v("3.4.1 第三方对该账号发起投诉，且尚未处理完毕的；"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft first" }, [
          _vm._v("3.4.2 该账号正处于国家主管部门的调查中；"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft first" }, [
          _vm._v("3.4.3 该账号正处于诉讼、仲裁或其他法律程序中；"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft first" }, [
          _vm._v(
            "3.4.4 该账号下存在与智安云的信控关系、伙伴关系等与原主体身份关联的合作关系的；"
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft first" }, [
          _vm._v(
            " 3.4.5 存在其他可能损害国家、社会利益，或者损害智安云、智安或其他第三方权利的情形的； "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft first" }, [
          _vm._v(
            "3.4.6 该账号因存在频繁变更引起的账号纠纷或账号归属不明确的情形。"
          ),
        ]),
        _c("br"),
        _c("h5", [_vm._v("四、账号主体变更的后果")]),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 4.1 当您的账号主体变更申请经智安云同意，且新主体确认并完成实名认证后，该账号主体将完成变更，变更成功以智安云系统记录为准，变更成功后会对您产生如下后果： "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft font_bold" }, [
          _vm._v(
            " 4.1.1 您本账号下的权益转让给变更后的实名主体，权益包括不限于账号控制权、账号下已开通的服务、账号下未消耗的充值金额等。 "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 4.1.2 该账号及该账号下的全部资源的归属权全部转由新主体拥有。 "
          ),
          _c("span", { staticClass: "font_bold" }, [
            _vm._v(
              " 但智安云有权终止，原主体通过该账号与智安云另行达成的关于优惠政策、信控、伙伴合作等相关事项的合作协议，或与其他智安云账号之间存在的关联关系等； "
            ),
          ]),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft first" }, [
          _vm._v(
            " 4.1.3 智安云不接受您以和新主体之间的协议为由或以其他理由，要求将该账号下一项或多项业务、权益转移给您指定的其他账号的要求； "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft first" }, [
          _vm._v(
            " 4.1.4 智安云有权拒绝您以和新主体之间存在纠纷为由或以其他理由，要求撤销该账号主体变更的请求； "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft first" }, [
          _vm._v(
            " 4.1.5 智安云有权在您与新主体之间就账号管理权发生争议或纠纷时，采取相应措施使得新主体获得该账号的实际管理权。 "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft first" }, [
          _vm._v(
            " 4.2 您理解并确认，账号主体变更并不代表您自变更之时起已对该账号下的所有行为和责任得到豁免或减轻： "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft first" }, [
          _vm._v(
            "4.2.1 您仍应对账号主体变更前，该账号下发生的所有行为承担责任；"
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft first" }, [
          _vm._v(
            " 4.2.2 您还需要对于变更之前已经产生，变更之后继续履行的合同及其他事项，对新主体在变更之后的履行行为及后果承担连带责任。 "
          ),
        ]),
        _c("br"),
        _c("h5", [_vm._v("五、双方权利与义务")]),
        _c("span", { staticClass: "mgLeft" }, [_vm._v("5.1 您应承诺并保证，")]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 5.1.1 您在账号主体变更流程中所填写的内容及提交的资料均真实、准确、有效，且不存在任何误导或可能误导智安云同意接受该项账号主体变更申请的行为； "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 5.1.2 您不存在利用智安云的账号主体变更服务进行任何违反法律、法规、部门规章和国家政策等，或侵害任何第三方权利的行为； "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft first" }, [
          _vm._v(
            " 5.1.3 您进行账号主体变更的操作不会置智安云于违约或者违法的境地。因该账号主体变更行为而产生的任何纠纷、争议、损失、侵权、违约责任等，智安云不承担法律明确规定外的责任。 您进一步承诺，如上述原因给智安云造成损失的，您应向智安云承担相应赔偿责任。 "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [_vm._v("5.2 您理解并同意，")]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 5.2.1 智安云有权在您发起申请后的任一时刻，要求您提供书面材料或其他证明，证明您有权进行变更账号主体的操作； "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 5.2.2 智安云有权依据自己谨慎的判断来确定您的申请是否符合法律法规或政策的规定及账号协议的约定，如存在违法违规或其他不适宜变更的情形的，智安云有权拒绝； "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 5.2.3 智安云有权记录账号实名认证主体变更前后的账号主体、交易流水、合同等相关信息，以遵守法律法规的规定，以及维护自身的合法权益； "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 5.2.4 如果您存在违反本协议第5.1条的行为的，智安云一经发现，有权直接终止账号主体变更流程，或者撤销已完成的账号主体变更操作，将账号主体恢复为没有进行变更前的状态。 "
          ),
        ]),
        _c("br"),
        _c("h5", [_vm._v("六、附则")]),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 6.1 您理解并接受，本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律，与法律规定不一致或存在冲突的，该不一致或冲突条款不具有法律约束力。 "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 6.2 就本协议内容或其执行发生任何争议，双方应进行友好协商；协商不成时，任一方均可向被告方所在地有管辖权的人民法院提起诉讼。 "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 6.3 本协议如果与双方以前签署的有关条款或者智安云的有关陈述不一致或者相抵触的，以本协议约定为准。 "
          ),
        ]),
        _c("br"),
        _c("h5", [
          _vm._v(
            " 您在此再次保证已经完全阅读并理解了上述《申请账号主体变更协议》，并自愿正式进入账号主体变更的后续流程，接受上述条款的约束。 "
          ),
        ]),
        _c("br"),
      ]),
      _c("div", { staticClass: "ignore_center" }, [
        _c("span", [
          _vm._v(
            " Copyright©2023 深圳市智安网络有限公司 All Rights Reve 版权所有 《中华人民共和国增值电信业务经营许可证》编号：B1-20190666 "
          ),
          _c(
            "a",
            {
              staticClass: "blue_font",
              attrs: {
                href: "https://beian.miit.gov.cn/#/Integrated/index",
                target: "_blank",
              },
            },
            [_vm._v("粤ICP备18027011号")]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }