var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "za-loading",
          rawName: "v-za-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "invoiceHeader",
    },
    [
      _c("div", { staticClass: "main" }, [
        _c("ul", [
          _c("li", [
            _c("div", { staticClass: "title" }, [_vm._v("可开票金额")]),
            _c("div", { staticClass: "money" }, [
              _c("span", [_vm._v(_vm._s(_vm.amount))]),
              _c("span", [_vm._v("元")]),
            ]),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", disabled: _vm.buttonFlag },
                    on: { click: _vm.handleQuery },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.translateTitle("btn.applyFor")) + " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("li", [
            _c("div", { staticClass: "title" }, [_vm._v("发票抬头信息")]),
            _c("div", { staticClass: "text" }, [
              _c("span", [_vm._v("抬头类型：")]),
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.headType === _vm.headTypes.personal
                        ? "个人"
                        : _vm.headType === _vm.headTypes.organization
                        ? "组织"
                        : _vm.headType === _vm.headTypes.enterprise
                        ? "企业"
                        : "暂无"
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "text" }, [
              _c("span", [_vm._v("发票类型：")]),
              _c("span", [_vm._v(_vm._s(_vm.invoicesTypeName))]),
            ]),
            _c("div", [
              _c(
                "span",
                {
                  staticClass: "btn",
                  on: {
                    click: function ($event) {
                      return _vm.toAddress("1")
                    },
                  },
                },
                [_vm._v("管理发票信息")]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }