import request from '@/utils/request'
import {BILL_DETAILED_LIST, METHOD, MONEY_DETAILED_LIST, RECHARGE_RECORD} from '@/api/api.config'

//获取收支明细列表接口
export function moneylist(data) {
  return request({
    url: MONEY_DETAILED_LIST,
    method: METHOD.GET,
    params: data,
  })
}

// V1.0.2获取交易流水列表接口
export function billList(data) {
  return request({
    url: BILL_DETAILED_LIST,
    method: METHOD.GET,
    params: data,
  })
}

// V1.0.0 充值记录接口
export function rechargeRecord(data) {
  return request({
    url: RECHARGE_RECORD,
    method: METHOD.GET,
    params: data,
  })
}
