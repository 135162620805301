var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "onlinePay" }, [
      _c(
        "div",
        { staticStyle: { width: "30%" } },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.translateTitle("topUp.topUpMoney") + ":",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "input-with-select",
                      on: {
                        input: function ($event) {
                          return _vm.limitInput($event, "amount")
                        },
                      },
                      model: {
                        value: _vm.form.amount,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "amount", $$v)
                        },
                        expression: "form.amount",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { slot: "prepend" }, slot: "prepend" },
                        [_vm._v("￥")]
                      ),
                    ],
                    1
                  ),
                  _vm.shouTest
                    ? _c("span", { staticClass: "red-font" }, [
                        _vm._v(
                          "请输入0.01~9999999范围内的数字，小数点后最多两位"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "za-radio",
                  attrs: { label: _vm.translateTitle("topUp.platform") + ":" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.payType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "payType", $$v)
                        },
                        expression: "form.payType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "wechat" } }, [
                        _c("div", [
                          _c("img", {
                            staticClass: "pay_img",
                            attrs: { src: _vm.weixinPay_src },
                          }),
                        ]),
                      ]),
                      _c("el-radio", { attrs: { label: "alipay" } }, [
                        _c("div", [
                          _c("img", {
                            staticClass: "pay_img",
                            attrs: { src: _vm.zhifuPay_src },
                          }),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "myCheck" }, [
      _c(
        "div",
        { staticClass: "choose" },
        [
          _c(
            "el-checkbox",
            {
              model: {
                value: _vm.form.agree,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "agree", $$v)
                },
                expression: "form.agree",
              },
            },
            [_vm._v("我已了解")]
          ),
        ],
        1
      ),
      _c("div", [
        _c("div", { staticClass: "prompt" }, [
          _c("span", [
            _vm._v("1.查看"),
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.$router.push("/protocal/promise")
                  },
                },
              },
              [_vm._v("反洗钱承诺书")]
            ),
          ]),
          _c("span", [
            _vm._v(" " + _vm._s(_vm.translateTitle("topUp.fourth"))),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "submit" },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                disabled:
                  !_vm.form.amount ||
                  _vm.form.amount < 0.01 ||
                  _vm.form.amount > 9999999,
              },
              on: { click: _vm.topUpSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.translateTitle("btn.confirmTopUp")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("za-pay-dialog", {
            attrs: {
              isPay: false,
              payDialog: _vm.payDialog,
              topTitle: "充值完成前请不要关闭此窗口",
            },
            on: { click: _vm.noneX },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }