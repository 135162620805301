<template>
    <div>
        <za-query-form>
            <za-query-form-left-panel :span="19">
                <el-form :inline="true" :model="pageObj" @submit.native.prevent>
                    <el-form-item label="退降配时间:"
                    >
                        <el-date-picker
                                v-model="pickTime"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                @change="datePickEnd"
                        ></el-date-picker>
                    </el-form-item>
                </el-form>
            </za-query-form-left-panel>
            <za-query-form-left-panel :span="5">
                <el-form :inline="true" :model="pageObj" @submit.native.prevent>
                    <el-form-item label-width="100px">
                        <el-input v-model="pageObj.tradeNo"
                                  placeholder="输入用户名或ID查找"/>
                    </el-form-item>
                    <el-form-item>
                        <za-button :icon="buttonIconObj.SEARCH" @click="handleQuery">{{ translateTitle('btn.query')
                            }}
                        </za-button>
                        <span class="zaRefresh">
                            <i class="el-icon-refresh"></i>
                        </span>
<!--                        <za-button :type="buttonIconObj.SYNC" @click="handleResetQuery">{{-->
<!--                            translateTitle('btn.reset')-->
<!--                            }}-->
<!--                        </za-button>-->
                    </el-form-item>
                </el-form>
            </za-query-form-left-panel>
        </za-query-form>
        <div>
            <occ-table :tableData="tableData" :columObj="columObj" :isOp="columObj.isOp"></occ-table>
            <pagination
                    :limit.sync="pageObj.pageSize"
                    :page.sync="pageObj.pageNo"
                    :total="total"
                    @pagination="getList"
            />
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                pageObj: {
                    pageNo: 1,
                    pageSize: 10,
                },
                pickTime: [],
                typeOptions: [],
                tableData: [],
                total: 0,
                columObj: {
                    // 控制选择框
                    selection: false,
                    isOp: false,
                    width: '300px',
                    columnData: [
                        {
                            text: true,
                            prop: "loginAt",
                            label: "产品名称",
                            width: "",
                            flag: true,
                            align: "center",


                        },
                        {
                            text: true,
                            prop: "name",
                            label: "用户名称",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "ip",
                            label: "交易流水",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "loginPlace",
                            label: "订单金额",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "loginPlace",
                            label: "退款金额",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "loginPlace",
                            label: "退款降配时间",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "loginPlace",
                            label: "需退还佣金",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                    ],
                }
            }
        },
        methods: {
            getList(){},
            handleQuery() {

            },
            handleResetQuery() {

            },
            datePickEnd(date) {
                if (date) {
                    this.pageObj.startAt = this.$changeTimeStamp(date[0])
                    this.pageObj.endAt = this.$changeTimeStamp(date[1]) + 3600 * 24 - 1

                } else {
                    this.pageObj.startAt = ''
                    this.pageObj.endAt = ''
                }
                this.getList()
            },
        }

    }
</script>
<style>

</style>