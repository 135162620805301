<template>
  <div class="verification-center_bg" v-za-loading="isLoading">
    <div>
      <za-go-back :title="'安全设置/' + title" @click="goBack"></za-go-back>
    </div>
    <div class="main">
      <div class="prompt">
        <span style="margin-left: 16px">
          <za-svg-icon icon-class="za-wanning"></za-svg-icon>
        </span>
        <span>为确保账号是您本人操作，请任意选择一种方式验证身份</span>
      </div>
      <div>
        <div v-for="(item, index) in list" :key="index">
          <div v-if="item.state == 1" class="container">
            <div class="bg">
              <el-image :src="item.url"></el-image>
            </div>
            <div class="verification">
              <div>
                <div class="heard_tltle">
                  {{ item.title }}
                  <span v-if="item.tel" style="color: red">{{ item.tel + baseForm.phone + ')' }}</span>
                  <span v-if="item.email" style="color: red">{{ item.email + baseForm.email + ')' }}</span>
                </div>
                <div class="heard_foot">{{ item.desc }}</div>
              </div>
              <div @click="info(item.fun)">
                <el-button type="primary">{{ item.btn }}</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <za-dialog :top-title="dialogType | dialogTitle(dialogTypeObj)" :visible.sync="dialogVisible" width="600px">
        <div>
          <div class="prompt">
            <za-svg-icon icon-class="za-wanning" style="margin-left: 16px"></za-svg-icon>
            <span v-if="dialogType !== 3">若1分钟内未收到短信提示，建议在垃圾短信中查看!</span>
            <span v-if="dialogType == 3">请妥善保管密保问题，设置后可用于找回密码、变更安全设置等!</span>
          </div>
          <el-form
            ref="form"
            :disabled="confirmBtnLoading"
            :model="baseForm"
            label-position="right"
            label-width="140px"
            :rules="rules"
          >
            <el-form-item :label="'已验证手机' + '：'" v-if="dialogType == '1'">
              <el-input v-model="baseForm.phone" :disabled="true" />
            </el-form-item>
            <el-form-item :label="'短信验证码' + '：'" required v-if="dialogType == '1'">
              <div>
                <el-col :span="16">
                  <el-form-item prop="smsCode">
                    <el-input v-model="baseForm.smsCode" placeholder="请输入验证码！" @input="changeWaring()" />
                    <span v-if="waringState" class="red-font">{{ waringText }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="2">&nbsp;</el-col>
                <el-col :span="6">
                  <el-button
                    @click="sendData"
                    class="sendInfo"
                    :disabled="dis"
                    :class="{ grey: isGrey, blue: !isGrey }"
                  >
                    <span v-if="telState == 1">发送验证码</span>
                    <span v-if="telState == 2">{{ count }}秒重试</span>
                    <span v-if="telState == 3">重新发送</span>
                  </el-button>
                </el-col>
              </div>
            </el-form-item>
            <el-form-item :label="'已验证邮箱' + '：'" v-if="dialogType == '2'">
              <el-input v-model="baseForm.email" :disabled="true" />
            </el-form-item>
            <el-form-item :label="'邮箱验证码' + '：'" required v-if="dialogType == '2'">
              <div>
                <el-col :span="16">
                  <el-form-item prop="smsCode">
                    <el-input v-model="baseForm.smsCode" placeholder="请输入验证码" @input="changeWaring()" />
                    <span v-if="waringState" class="red-font">{{ waringText }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="2">&nbsp;</el-col>
                <el-col :span="6">
                  <el-button
                    @click="sendData"
                    class="sendInfo"
                    :disabled="two_dis"
                    :class="{ two_grey: two_isGrey, two_blue: !two_isGrey }"
                  >
                    <span v-if="two_telState == 1">发送验证码</span>
                    <span v-if="two_telState == 2">{{ two_count }}秒重试</span>
                    <span v-if="two_telState == 3">重新发送</span>
                  </el-button>
                </el-col>
              </div>
            </el-form-item>
            <el-form-item :label="'密保问题' + '：'" v-if="dialogType == '3'">
              <el-input
                v-model="baseForm.oneQuestion"
                :placeholder="translateTitle('placeTxt') + translateTitle('askfor.contract')"
                :disabled="true"
              />
            </el-form-item>
            <el-form-item :label="'请输入答案' + '：'" v-if="dialogType == '3'" prop="oneAnswer">
              <el-input v-model="baseForm.oneAnswer" placeholder="请输入密保答案" @input="changePwdWaring()" />
            </el-form-item>
            <el-form-item :label="'密保问题' + '：'" v-if="dialogType == '3'">
              <el-input
                v-model="baseForm.twoQuestion"
                :placeholder="translateTitle('placeTxt') + translateTitle('askfor.contract')"
                :disabled="true"
              />
            </el-form-item>
            <el-form-item :label="'请输入答案' + '：'" v-if="dialogType == '3'" prop="twoAnswer">
              <el-input v-model="baseForm.twoAnswer" placeholder="请输入密保答案" @input="changePwdWaring()" />
            </el-form-item>
            <el-form-item :label="'密保问题' + '：'" v-if="dialogType == '3'">
              <el-input
                v-model="baseForm.threeQuestion"
                :placeholder="translateTitle('placeTxt') + translateTitle('askfor.contract')"
                :disabled="true"
              />
            </el-form-item>
            <el-form-item :label="'请输入答案' + '：'" v-if="dialogType == '3'" prop="threeAnswer">
              <el-input v-model="baseForm.threeAnswer" placeholder="请输入密保答案" @input="changePwdWaring()" />
            </el-form-item>
            <div>
              <el-dialog width="25%" title="验证" :visible.sync="innerVisible" append-to-body>
                <za-verify v-if="showVerity" ref="verify" @verifySuccess="success" :selectKey="dialogType" />
              </el-dialog>
            </div>
          </el-form>
        </div>
        <div slot="footer">
          <span v-if="waringPwd" class="red-font">{{ warringQuestion }}</span>
          <za-button :disabled="confirmBtnLoading" :type="buttonTypeObj.GREY" @click="dialogVisible = false">
            {{ translateTitle('btn.cancel') }}
          </za-button>
          <za-button :loading="confirmBtnLoading" :type="buttonTypeObj.BLUE" @click="handleSubmitForm(dialogType)">
            {{ translateTitle('btn.submit') }}
          </za-button>
        </div>
      </za-dialog>
    </div>
  </div>
</template>
<script>
  import ZaGoBack from '../../../../za/components/zaGoBack/index'
  import {
    apiGetEmail,
    apiGetPhone,
    apiGetSecurity,
    apiSecureEmail,
    apiSecurePhone,
    apiSendEmail,
    apiSendPhone,
    apiVeritySecurity,
  } from '@/api/accountCenter/safetySet'
  import ZaVerify from '../../../../za/components/zaVerify/index'
  import { reqverificationObj } from '@/za/model/reqModel'
  import { isHaveSpace } from '@/utils/validate'

  export default {
    components: { ZaGoBack, ZaVerify },
    data() {
      const email = (rule, value, callback) => {
        if (value === '') callback(new Error('请输入邮箱，邮箱不能为空'))
        else if (isHaveSpace(value)) callback(new Error('邮箱不能包含空格！'))
        else callback()
      }
      const smsCode = (rule, value, callback) => {
        if (!value) callback(new Error('验证码不能为空'))
        else if (isHaveSpace(value)) callback(new Error('验证码不能包含空格！'))
        else callback()
      }
      return {
        list: [
          {
            url: require('@/assets/accountCenter/sort_message.png'),
            tel: '',
            title: '通过短信验证码验证',
            desc: '接收验证码的手机号为您账号中绑定的安全手机号',
            fun: 'telInfo',
            btn: '验证修改',
            state: 1,
          },
          {
            url: require('@/assets/accountCenter/email.png'),
            email: '',
            title: '通过账户绑定邮箱验证',
            desc: '验证码将发送至您账户已绑定的安全邮箱，请确认安全邮箱是否可用',
            fun: 'mailboxInfo',
            btn: '验证修改',
            state: 1,
          },

          {
            url: require('@/assets/accountCenter/pwd.png'),
            tel: '',
            title: '通过密码保护验证',
            desc: '通过账户设置的安全密保问题验证修改',
            fun: 'pwdInfo',
            btn: '验证修改',
            state: 1,
          },
        ],
        rules: {
          email: [{ required: true, trigger: 'blur', validator: email }],
          smsCode: [{ required: true, trigger: 'blur', validator: smsCode }],
          oneAnswer: [{ required: true, message: '请输入有效答案', trigger: 'blur' }],
          twoAnswer: [{ required: true, message: '请输入有效答案', trigger: 'blur' }],
          threeAnswer: [{ required: true, message: '请输入有效答案', trigger: 'blur' }],
        },
        baseForm: Object.assign({}, reqverificationObj),
        //-------------------------------
        count: '',
        telState: 1,
        dis: false,
        show: true,
        timer: null, //设置计时器,
        isGrey: false, //按钮样式
        showVerity: false,
        innerVisible: false,
        //    ------------------------------------
        two_count: '',
        two_telState: 1,
        two_dis: false,
        two_show: true,
        two_timer: null, //设置计时器,
        two_isGrey: false, //按钮样式
        title: '',
      }
    },
    created() {
      this.dialogTypeObj.ADD.text = '手机验证'
      this.dialogTypeObj.EDIT.text = '邮箱验证'
      this.dialogTypeObj.TEST = {
        type: 3,
        text: '密保验证',
      }
      this.getPhone()
      if (this.$route.query.clickVal == '2') {
        this.getEmail()
      }
    },
    watch: {
      'baseForm.smsCode': {
        handler(value) {
          this.baseForm.smsCode = value.replace(/\s+/g, '')
        },
      },
    },
    mounted() {
      if (this.$route.query.pwdState) {
        this.list[2].state = this.$route.query.pwdState
      }
      if (this.$route.query.emailState) {
        this.list[1].state = this.$route.query.emailState
      }
      if (this.$route.query.clickVal) {
        switch (this.$route.query.clickVal) {
          case '0':
            this.title = '修改密码'
            break
          case '1':
            this.title = '修改手机'
            break
          case '2':
            this.title = '修改邮箱'
            break
          case '3':
            this.title = '修改密保'
            break
          case '4':
            this.title = '新增邮箱'
            break
          default:
            break
        }
      }
    },
    methods: {
      goBack() {
        this.$router.push('/accountCenter/safetySet')
      },
      getPhone() {
        this.onSubmitData(apiGetPhone, null, null, false, false, (res) => {
          this.baseForm.phone = res.data.phone
        })
      },
      getEmail() {
        this.onSubmitData(apiGetEmail, null, null, false, false, (res) => {
          this.baseForm.email = res.data.email
        })
      },
      info(val) {
        switch (val) {
          case 'telInfo':
            this.dialogType = this.dialogTypeObj.ADD.type
            this.dialogVisible = true
            this.getPhone()

            break
          case 'mailboxInfo':
            this.dialogType = this.dialogTypeObj.EDIT.type
            this.dialogVisible = true
            this.getEmail()
            break
          case 'pwdInfo':
            this.dialogType = this.dialogTypeObj.TEST.type
            this.onSubmitData(apiGetSecurity, null, null, false, false, (res) => {
              res.data.securityQuestion.forEach((item, index) => {
                if (index == 0) {
                  this.baseForm.oneQuestion = item
                } else if (index == 1) {
                  this.baseForm.twoQuestion = item
                } else {
                  this.baseForm.threeQuestion = item
                }
              })
            })
            this.dialogVisible = true
            break
          default:
            break
        }
      },
      sendData() {
        this.innerVisible = true
        this.showVerity = true
      },
      success(val) {
        this.innerVisible = false
        this.showVerity = false
        val == 1 ? this.phoneTime() : this.emailTime()
      },
      phoneTime() {
        apiSendPhone(null).catch()
        let TIME_COUNT = 60
        if (!this.timer) {
          this.count = TIME_COUNT
          this.isGrey = true
          this.telState = 2
          this.dis = true
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--
            } else {
              this.dis = false
              this.isGrey = false
              this.telState = 3
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)
        }
      },
      emailTime() {
        apiSendEmail(null).catch()
        let TWO_TIME_COUNT = 60
        if (!this.two_timer) {
          this.two_count = TWO_TIME_COUNT
          this.two_isGrey = true
          this.two_telState = 2
          this.two_dis = true
          this.two_timer = setInterval(() => {
            if (this.two_count > 0 && this.two_count <= TWO_TIME_COUNT) {
              this.two_count--
            } else {
              this.two_dis = false
              this.two_isGrey = false
              this.two_telState = 3
              clearInterval(this.two_timer)
              this.two_timer = null
            }
          }, 1000)
        }
      },
      handleSubmitForm(val) {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let res
            if (val == 1) {
              let result = { smsCode: this.baseForm.smsCode }
              res = await apiSecurePhone(result).finally(() => (this.confirmBtnLoading = false))
            } else if (val == 2) {
              let result = { smsCode: this.baseForm.smsCode }
              res = await apiSecureEmail(result).finally(() => (this.confirmBtnLoading = false))
            } else if (val == 3) {
              let allQuestion = {
                securityAnswer: [this.baseForm.oneAnswer, this.baseForm.twoAnswer, this.baseForm.threeAnswer],
              }
              res = await apiVeritySecurity(allQuestion).finally(() => (this.confirmBtnLoading = false))
            }
            if (res.code == 0) {
              this.dialogVisible = false
              this.$msgSuccess('校验成功')
              this.$router.push({
                path: '/accountCenter/safetySet/verification/modify',
                query: { queryData: this.$route.query.clickVal },
              })
            }
          } else {
            return false
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .verification-center_bg {
    height: 100%;
  }

  .main {
    padding: 20px;
    background: #ffffff;
    height: 80%;

    .prompt {
      height: 40px;
      background: #fffbe6;
      border-radius: 2px;
      border: 1px solid #ffe58f;
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      line-height: 40px;
      margin-bottom: 20px;
    }

    .container {
      padding: 20px;
      height: 100px;
      background: #f4f6fa;
      margin-bottom: 20px;
      display: flex;

      .bg {
        width: 60px;
        height: 60px;
        margin-right: 20px;
      }

      .verification {
        display: flex;
        flex: 1;
        justify-content: space-between;

        .heard_tltle {
          font-size: 16px;
          font-weight: 500;
          color: #000000;
        }

        .heard_foot {
          font-size: 12px;
          font-weight: 400;
          color: #555555;
          margin-top: 14px;
        }
      }
    }

    .sendInfo {
      font-size: 14px;
      font-weight: 400;
      color: #3370ff;
    }
  }

  ::v-deep {
    .el-button--primary {
      border-radius: 2px;
      border: 1px solid #336ffe;
      color: #3370ff;
      background: #ffffff;
    }

    .el-button--primary:hover {
      border-radius: 2px;
      background: #3370ff;
      color: #ffffff;
    }
  }
</style>