<template>
    <div class="askFor-center_bg">
        <div class="askFor-top">
            <div class="title">{{title}}</div>
            <div>
                <invoice-header></invoice-header>
            </div>
        </div>
        <div>
            <history></history>
        </div>
    </div>
</template>
<script>
    import invoiceHeader from './components/invoiceHeader'
    import history from './components/history'

    export default {
        components: {invoiceHeader, history},
        data() {
            return {
                title: '',
            }
        },
        created() {
            this.title = this.$route.meta.title
        }
    }
</script>
<style lang="scss" scoped>
    @import '~@/za/styles/mixin.scss';

    .askFor-top {
        height: 248px;
        background: #FFFFFF;
        border-radius: 2px;
        padding: 20px;
        margin-bottom: 20px;
    }

    .title {
        @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
        width: 100%;
        height: 20px;
        margin-bottom: 20px;
    }
</style>