<template>
    <div>
        <div class="title">
            <span>{{title}}</span>
        </div>
        <div class="backOrder_center">
            <el-tabs v-model="activeName">
                <el-tab-pane label="弹性云服务器" name="first">
                    <comPage :tabData="tabData"></comPage>
                </el-tab-pane>
                <el-tab-pane label="轻量应用服务器" name="second">
                    <comPage></comPage>
                </el-tab-pane>
                <el-tab-pane label="DDos防护" name="third">
                    <comPage></comPage>
                </el-tab-pane>
                <el-tab-pane label="SSL证书" name="four">
                    <comPage></comPage>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
    import comPage from './components/compage'
    export default {
        components:{comPage},
        data(){
            return{
                title: '',
                activeName: 'first',
                tabData:[]
            }
        },
        created() {
            this.title = this.$route.meta.title
        }
    }
</script>
<style lang="scss" scoped>
    .title {
        height: 62px;
        background: #FFFFFF;
        border-radius: 2px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        padding-left: 20px;
        line-height: 62px;
        margin-bottom: 20px;
    }
    .backOrder_center {
        background:#FFFFFF;
        padding: 20px;
    }
    ::v-deep{
        .el-tabs__item{
            font-weight: 600 !important;
        }
    }
</style>