<template>
  <div>
    <router-view />
  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    mounted() {},
  }
</script>

<style>
  /*#app {*/
  /*  font-family: Avenir, Helvetica, Arial, sans-serif;*/
  /*  -webkit-font-smoothing: antialiased;*/
  /*  -moz-osx-font-smoothing: grayscale;*/
  /*  text-align: center;*/
  /*  color: #2c3e50;*/
  /*}*/
  /*::-webkit-scrollbar{*/
  /*  display: none*/
  /*}*/
</style>
