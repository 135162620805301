import request from '@/utils/request'
import {
    METHOD,
    ORDER_HOME_RESOURCE,
    ORDER_HOME_GETWAIT_NUMBER,
    ORDER_HOME_EXPIRE_NUMBER,
    ORDER_HOME_OVERLOG

} from '@/api/api.config'
/**
 * @description 首页日志统计
 * @param data
 * @returns {AxiosPromise}
 */
export function apiGetOverviewLog(data) {
    return request({
        url: ORDER_HOME_OVERLOG,
        method: METHOD.GET,
        params: data,
    })
}

/**
 * @description 首页再用资源数量统计
 * @param data
 * @returns {AxiosPromise}
 */
export function apiGetResourceNumber(data) {
    return request({
        url: ORDER_HOME_RESOURCE,
        method: METHOD.GET,
        params: data,
    })
}
/**
 * @description 首页待续费资源数
 * @param data
 * @returns {AxiosPromise}
 */
export function apiGetWaitRenewResourceNumber(data) {
    return request({
        url: ORDER_HOME_GETWAIT_NUMBER,
        method: METHOD.POST,
        data,
    })
}
/**
 * @description 首页已过期资源数
 * @param data
 * @returns {AxiosPromise}
 */
export function apiGetExpireResourceNumber(data) {
    return request({
        url:ORDER_HOME_EXPIRE_NUMBER,
        method: METHOD.GET,
        params:data,
    })
}

