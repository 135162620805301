<template>
  <div :style="{ '--color': bgColor }" class="progress">
    <div class="txt-con">
      <span @click="$emit('click')">{{ name }}</span>
      <span>{{ perValue(curValue) }}</span>
    </div>
    <el-progress :color="color" :percentage="perValue()" :show-text="false"/>
  </div>
</template>

<script>
export default {
  name: 'ZaProgress',
  props: {
    curValue: {
      type: Number,
      default: 0,
    },
    totalValue: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'red',
    },
    bgColor: {
      type: String,
      default: '#2E2E2E',
    },
  },
  computed: {
    perValue() {
      return (curValue = null) => {
        const res = (this.curValue / this.totalValue) * 100
        if (curValue) return `${res.toFixed(2)}%`
        return res
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/za/styles/mixin.scss';

::v-deep {
  .el-progress-bar__outer {
    background-color: var(--color);
  }
}

.progress {
  width: 100%;
  height: 34px;

  .txt-con {
    @include flex_div($just: space-between, $padding: 0);
    height: 16px;
    margin-bottom: 10px;

    span:first-of-type {
      font-size: 15px;
      font-weight: 400;
      color: #ffffff;
    }

    span:last-of-type {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>
