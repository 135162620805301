export default {
  zaI18n: {
    signIn: {
      userPlace: '请输入您的账户',
      pswPlace: '请输入您的密码',
      userRules: '账户不能为空！',
      pswRules: '密码不能为空！',
      expTipTitle: '密码过期',
      firstTipTitle: '初次登录',
      register: '注册新用户',
    },
    navTop: {
      toHome: '控制台'
    },
    status: {
      enable: '启用',
      disable: '禁用'
    },
    placeTxt: '请输入',
    placeChoose: '请选择',
    operation: '操作',
    //财务中心-在线预充值
    topUp: {
      reminder: '温馨提示',
      first: '1.若您有欠费账单，充值后将优先抵扣欠费账单。',
      second: '2.若您后付费产品已欠费并停止服务，请充值大于欠款的金额，在回收站手动重启服务。',
      third: '3.充值后请及时支付未支付订单，以免影响正常服务。',
      fourth: '2.若您有欠费账单，充值后将优先抵扣欠费账单。',
      fifth: '3.若需开具发票，请在支付后90天内申请。',
      sixth: '1. 受银行处理时间影响，线下汇款方式到账会有延误，',
      sixthOne: '强烈建议采用支付宝、微信、网银实时到账。',
      seventh: '2. 为防范可能发生的诈骗、洗钱等风险，对于使用与实名认证主体不一致的银行账户进行汇款的，将进行进一步核查及审慎判断，并且有权拒绝存在异常情形的相关汇款',
      eighth: '3. 各种方式的到账时间一般为：',
      eighthOne: '招行1-2个工作日，其他银行2-5个工作日（具体到账时间以银行的实际到账时间为准）。',
      topUpMoney: '充值金额',
      platform: '支付平台',
      wechatPay: '微信支付',
      alipayPayment: '支付宝支付',
      warning: '请确认您已了解！',
      topUpAccount: '充值账户',
      moneyInfo: '汇款信息'
    },
    //财务中心-收支明细
    details: {
      transactionNum: '交易账号',
      transactionOrder: '流水编号',
      transactionOrderNum: '交易流水',
      transactionMoney: '交易金额',
      resourceId: '资源ID',
      transactionType: '交易类型',
      transactionTime: '交易时间',
      payMode: '交易方式',
      orderNum: '交易流水',
      accountBalance: '账户余额',
      remarks: '备注'
    },
    //消息中心-全部消息
    allMsg: {
      keyWord: '搜索关键词'
    },
    //发票管理-发票索取
    askfor: {
      timer: '申请日期',
      contract: '订单/月结算单/合同编号',
      invoice: '发票号',
    },
    forgetPsw: {
      tipsSub: '请修改密码，',
      labelOrgPsw: '原始密码',
      placeOrgPsw: '请输入原始密码',
      labelNewPsw: '新密码',
      placeNewPsw: '请输入新密码',
      labelCurPsw: '确认密码',
      placeCurPsw: '请和上面输入的新密码保持一致',
      rulesNewPsw1: '新密码不能为空！',
      rulesNewPsw2: '新密码与确认密码不相符！',
      rulesCurPsw1: '确认密码不能为空！',
      rulesCurPsw2: '新密码与确认密码不相符！',
      rulesOrgPsw: '原始密码不能为空！',
      successTips: '密码修改成功！',
    },
    btn: {
      confirmTopUp: '确认充值',
      setDirectory: '设置目录',
      synchronous: "全量同步",
      login: '登 录',
      confirm: '确定',
      loginOk: '确 定',
      loginNone: '取 消',
      cancel: '取消',
      refresh: '刷新',
      submit: '提交',
      open: '开启',
      close: '关闭',
      add: '添加',
      show: '查看',
      edit: '编辑',
      delete: '删除',
      operator: '操作',
      enable: '启用',
      disable: '禁用',
      query: '查询',
      reset: '重置',
      copy: '拷贝',
      return: '返回',
      prev: '上一步',
      next: '下一步',
      backup: '备份',
      set: '设置',
      detail: '详情',
      connect: '连接',
      auth: '授权',
      monitor: '监控',
      disconnect: '断开',
      playback: '回放',
      disconnected: '已断开',
      download: '下载',
      scan: "扫描",
      stop: '停止',
      read: '已读',
      readAll: '全部已读',
      deleteAll: '全部删除',
      save: '保存',
      goUse: '前往使用',
      test: '测试',
      deploy: '部署',
      register: '注册',
      start: '启动',
      alert: '告警',
      remark: '备注',
      btnImport: '批量导入',
      batchExport: '批量导出',
      batchScan: '批量扫描',
      batchStart: '批量启动',
      batchStop: '批量停止',
      batchDelete: '批量删除',
      applyFor: '申请开票'
    },
    register: {
      title: '注册用户',
      placeAccount: '请输入用户名',
      placePwd: '请输入您的密码',
      placeConfirmPwd: '请再次输入您的密码',
      placeMobile: '请输入手机号',
      placeCompany: '请输入公司名称',
      placeEmail: '请输入常用电子邮箱（选填）',
      tipsDistinctPwd: '两次密码输入不一致,请重新输入!',
      tipsConfirmPwd: '请输入确认密码',
      tipsSuccess: '注册成功',
      yourAccount: '你的账户',
      goToLogin: '去登录',
    },

  },
}
