var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "za-loading",
          rawName: "v-za-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "cardCenter",
    },
    [
      _c("div", { staticClass: "prompt" }, [
        _c(
          "span",
          { staticStyle: { "margin-left": "16px" } },
          [
            _c("za-svg-icon", {
              attrs: { "icon-class": "za-wanning", "class-name": "za-warning" },
            }),
          ],
          1
        ),
        _c("span", [
          _vm._v(
            " 新客户通过推广者的推广链接注册/登录，即与推广者建立30天关联 "
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "card_bottom" },
        _vm._l(_vm.enumArr, function (item, index) {
          return _c("div", { key: index, staticClass: "cardUl" }, [
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.label))]),
            _c("div", { staticClass: "operation" }, [
              _c("div", [
                _c("span", [_vm._v(_vm._s(item.value))]),
                _c("span", [_vm._v("个")]),
              ]),
            ]),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }