export default {
  data() {
    return {
      formType: 2,
      offsetNum: 47,
      tableHeight: this.$baseTableHeight(2) - 47,
    }
  },
  beforeMount() {
    window.addEventListener('resize', this.handleHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleHeight)
  },
  mounted() {
    this.handleHeight()
  },
  methods: {
    handleHeight() {
      this.tableHeight = this.$baseTableHeight(this.formType) - this.offsetNum
    },
    handleResetParam(formType, offsetNum, isRestHeight = true) {
      this.formType = formType
      this.offsetNum = offsetNum
      if (isRestHeight) {
        this.handleHeight()
      }
    },
  },
}
