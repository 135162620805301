var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cardCenter" }, [
    _c(
      "div",
      { staticClass: "card_mottom" },
      [
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 5 } }, [
              _c("div", { staticClass: "cardUl" }, [
                _c("div", { staticClass: "title" }, [_vm._v("当前客户关联数")]),
                _c("div", { staticClass: "operation" }, [
                  _c("div", [
                    _c("span", [_vm._v("1")]),
                    _c("span", [_vm._v("个")]),
                  ]),
                ]),
              ]),
            ]),
            _c("el-col", { attrs: { span: 5 } }, [
              _c("div", { staticClass: "cardUl" }, [
                _c("div", { staticClass: "title" }, [_vm._v("推广期客户数")]),
                _c("div", { staticClass: "operation" }, [
                  _c("div", [
                    _c("span", [_vm._v("2")]),
                    _c("span", [_vm._v("个")]),
                  ]),
                ]),
              ]),
            ]),
            _c("el-col", { attrs: { span: 5 } }, [
              _c("div", { staticClass: "cardUl" }, [
                _c("div", { staticClass: "title" }, [_vm._v("维护期客户数")]),
                _c("div", { staticClass: "operation" }, [
                  _c("div", [
                    _c("span", [_vm._v("3")]),
                    _c("span", [_vm._v("个")]),
                  ]),
                ]),
              ]),
            ]),
            _c("el-col", { attrs: { span: 5 } }, [
              _c("div", { staticClass: "cardUl" }, [
                _c("div", { staticClass: "title" }, [_vm._v("已过期客户数")]),
                _c("div", { staticClass: "operation" }, [
                  _c("div", [
                    _c("span", [_vm._v("4")]),
                    _c("span", [_vm._v("个")]),
                  ]),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }