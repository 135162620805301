<template>
    <el-container class="za_layout">
        <el-header class="za_layout_header">
            <navbar/>
        </el-header>
        <el-container class="za_layout_main">
            <sidebar class="za_layout_left_menu"/>
            <el-container class="za_layout_right_main">
                <!--                <el-header class="za_layout_route"   :class="{'error':isError}">-->
                <!--                    <bread-route/>-->
                <!--                </el-header>-->
                <div class="za_layout_app_main">
                    <!--                    <za-bg-frame>-->
                    <app-main/>
                    <!--                    </za-bg-frame>-->
                </div>
            </el-container>
        </el-container>
    </el-container>
</template>

<script>
    import {AppMain, Sidebar, BreadRoute, Navbar} from '@/za/layout/components'
    import ZaBgFrame from '@/za/components/zaBgFrame'
    import {handleMatched} from '@/utils/routes'
    import {mapGetters} from "vuex";

    export default {
        name: 'Layout',
        components: {AppMain, Sidebar, BreadRoute, ZaBgFrame, Navbar},
        computed: {
            ...mapGetters({
                routes: 'routes/routes',
                userInfo: 'user/userInfo',
            }),
            breadcrumbList() {
                // console.log(handleMatched(this.routes, this.$route.name))
                return handleMatched(this.routes, this.$route.name)
            },
        }
    }
</script>

<style lang="scss" scoped>
    [class*='za_layout'] {
        margin: 0;
        padding: 0;
    }

    .za_layout {
        width: $base-page-width;
        height: $base-page-height;

        .za_layout_header {
            height: $base-header-height !important;
        }

        .za_layout_main {
            height: 100%;

            .za_layout_left_menu {
                width: $base-left-menu-width !important;
                min-width: $base-left-menu-width !important;
            }

            .za_layout_right_main {
                flex: 1 auto;
                min-width: $base-right-menu-width !important;
                margin: 20px 20px 20px 20px;

                .za_layout_route {
                    height: $base-route-height !important;
                    background-color: $base-bg-color-blue;
                }

                .za_layout_app_main {
                    width: 100%;
                    height: $nobase-keep-alive-height;
                }
            }
        }
    }
</style>
