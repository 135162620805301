<template>
    <div>
        <div class="icon">
            <div @click="onGetList" class="refresh">
                <i style="cursor: pointer;" class="el-icon-refresh"></i>
            </div>
            <div @click="onChangeShow" class="icon-grid">
                <i style="cursor: pointer;" class="el-icon-setting"></i>
            </div>
        </div>
        <div class="selCheckbox" v-show="checkShow">
            <el-checkbox-group v-model="check" class="changeHeight">
                <div v-for="(item, index) in changeList" :key="index"
                     @click="onClick(index)">
                    <el-checkbox :label="item" @change="onChange"></el-checkbox>
                </div>
            </el-checkbox-group>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        checkboxShow:{
          type:Boolean,
          default:false
        },
        checkboxList: {
            type: Array,
            default: () => []
        },
        checkList: {
            type: Array,
            default: () => []
        },
        tableTitle: {
            type: Array,
            default: () => []
        },
    },
    data(){
        return{
            checkIndex:'',
            check:this.checkList,
            checkShow:this.checkboxShow,
            changeList:this.checkboxList
        }
    },
    methods:{
        // 筛选的显示隐藏
        onChangeShow() {
            this.checkShow = !this.checkShow;
        },
        onGetList(){
            this.$emit('getList')
        },
        // 筛选变化事件
        onChange(e) {
            if (this.check.length == 0) {
                this.tableTitle.forEach(item => {
                    item.flag = false;
                });
            } else {
                this.tableTitle[this.checkIndex].flag = e;
            }
        },
        onClick(e) {
            this.checkIndex = e;
        },
    }
}
</script>
<style lang="scss" scoped>
    .icon{
        display: flex;
        justify-content: flex-end;
        .refresh{
            height:30px;
            font-size:20px;
            width: 30px;

        }
        .icon-grid{
            height:30px;
            font-size:20px;
            width: 30px;
            margin-left: 10px;
        }
    }

    .selCheckbox{
        position:absolute;
        border:1px solid #D2D2D2;
        background-color:#fff;
        z-index:10;
        padding:10px ;
        box-sizing: border-box;
        right:2% ;
    }
    .changeHeight{
        div{
            height: 20px !important;
        }
    }
</style>