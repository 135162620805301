<template>
    <div>
        <div v-za-loading="isLoading">
            <span class="title">基本信息</span>
            <div style="width: 800px;">
                <el-row>
                    <el-form ref="form" :model="querydata" @submit.native.prevent label-position="right"
                             :rules="rule"
                             label-width="160px">
                        <el-col :span="24">
                            <el-form-item>
                                 <span slot="label">
      	                             <span style="color: red">* </span>
                                     <span> 联系人</span>
                                 </span>
                                <el-input v-model="querydata.contacts"
                                          :placeholder="`${translateTitle('placeTxt')}`" disabled/>
                                <span v-if="!querydata.contacts" class="waringTest">
                            请前往
                            <span @click="$router.push('/accountCenter/nameCertification')">实名认证</span>
                            进行绑定
                        </span>
                            </el-form-item>

                        </el-col>
                        <el-col :span="24">
                            <el-form-item >
                                <span slot="label">
      	                             <span style="color: red">* </span>
                                     <span> 移动电话</span>
                                 </span>
                                <el-input v-model="querydata.phone"
                                          :placeholder="`${translateTitle('placeTxt')}`" disabled/>

                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item>
                                <span slot="label">
      	                             <span style="color: red">* </span>
                                     <span> 电子邮件</span>
                                 </span>
                                <el-input v-model="querydata.email"
                                          :placeholder="`${translateTitle('placeTxt')}`" disabled/>
                                <span v-if="!querydata.email" class="waringTest">
                            请前往
                            <span @click="$router.push('/accountCenter/safetySet')">安全设置</span>
                            进行绑定
                        </span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="电话:">
                                <el-input v-model="querydata.mobile"
                                          maxlength="11"
                                          @input="limitInput($event,'mobile')"
                                          :placeholder="`${translateTitle('placeTxt')}`"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="QQ号:">
                                <el-input v-model="querydata.qq"
                                          @input="limitInput($event,'qq')"
                                          :placeholder="`${translateTitle('placeTxt')}`"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="官网:">
                                <el-input v-model="querydata.website"
                                          placeholder="请填写您的官网URL"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="所在城市:">
                                <el-cascader
                                        clearable
                                        v-model="seleCity"
                                        :options="cityOptions"
                                        :show-all-levels="false"
                                >
                                </el-cascader>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="行业领域 : " prop="industry">
                                <el-select v-model="querydata.industry"
                                           clearable
                                           :placeholder="`${translateTitle('placeTxt')}`" style="width:200px">
                                    <el-option v-for="item in typeOptions" :key="item.value" :label="item.name"
                                               :value="item.value"/>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <div>
                            <za-button @click="handleQuery" class="btn">{{ translateTitle('btn.save') }}</za-button>
                        </div>
                    </el-form>
                </el-row>
            </div>
        </div>
    </div>
</template>
<script>
    import City from '../city/index'
    import {apiSaveInfo} from '@/api/accountCenter/basicInfo'

    export default {
        data() {
            return {
                typeOptions: [
                    {name: '农、林、牧、渔业', value: '农、林、牧、渔业'},
                    {name: '采矿业', value: '采矿业'},
                    {name: '制造业', value: '制造业'},
                    {name: '电力、燃气及水的生产和供应业', value: '电力、燃气及水的生产和供应业'},
                    {name: '建筑业', value: '建筑业'},
                    {name: '交通运输、仓储和邮政业', value: '交通运输、仓储和邮政业'},
                    {name: '信息传输、计算机服务和软件业', value: '信息传输、计算机服务和软件业'},
                    {name: '批发和零售业', value: '批发和零售业'},
                    {name: '住宿和餐饮业', value: '住宿和餐饮业'},
                    {name: '卫生、社会保障和社会福利业', value: '卫生、社会保障和社会福利业'},
                    {name: '居民服务和其他服务业', value: '居民服务和其他服务业'},
                    {name: '其他', value: '其他'},
                ],
                cityOptions: City.city,
                rule: {
                    industry: [{required: true, message: '请选择行业领域', trigger: 'change'}],
                },
                querydata: {
                    industry: '',
                    contacts: '',
                    email: '',
                    mobile: '',
                    qq: '',
                    website: "",
                    city: ''
                },
                seleCity: []
            }
        },
        mounted() {
            this.$baseEventBus.$on('getHeardInfo', (data) => {
                this.querydata = data
                this.seleCity = this.findFa(this.cityOptions, this.querydata.city)||[]
                this.isLoading = false
            })
        },
        methods: {
            limitInput(value, name) {
                let val = "" + value;
                val = val
                    .replace(/[^\d^\.]+/g, "")
                    .replace(/^0+(\d)/, "$1")
                    .replace(/^\./, "0.")
                    .match(/^\d*(\.?\d{0,2})/g)[0] || "";
                this.querydata[name] = val
            },
            findFa(list, id) {
                for (let i in list) {
                    if (list[i].value == id) {
                        return [list[i].value]
                    }
                    if (list[i].children) {
                        let node = this.findFa(list[i].children, id)
                        if (node !== undefined) {
                            node.unshift(list[i].value)
                            return node
                        }
                    }
                }
            },
            handleQuery() {
                this.$refs['form'].validate(async (valid) => {
                    if (valid) {
                        if(this.seleCity.length){
                            this.querydata.city = this.seleCity[1]
                        }else{
                            this.querydata.city=''
                        }
                        this.confirmBtnLoading = true
                        let result = {
                            qq: this.querydata.qq,
                            mobile: this.querydata.mobile,
                            city: this.querydata.city,
                            industry: this.querydata.industry,
                            website: this.querydata.website,
                        }
                        const res = await apiSaveInfo(result).finally(() => (this.confirmBtnLoading = false))
                        if (res.code === 0) {
                            this.$msgSuccess('保存成功')
                            this.$emit('getList')
                        }
                    } else {
                        return false
                    }
                })

            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '~@/za/styles/mixin.scss';

    .title {
        @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
        width: 100%;
        height: 20px;
        margin-bottom: 20px;
        display: block;
    }

    .btn {
        margin-left: 24%;
    }

    .waringTest {
        font-size: 14px;
        cursor: pointer;

        span {
            color: #1B5DEB;
        }
    }

    ::v-deep {
        .el-input {
            width: 300px;
        }
    }
</style>