import request from '@/utils/request'
import {
  USER_LOGOUT_PATH,
  METHOD, USER_BASE_INFO,
  GET_IS_FIRST_PURCHASE
} from '@/api/api.config'
import {getToken} from '@/utils/token'

/**
 * @description 获取用户基础信息接口
 * @returns {AxiosPromise}
 */
export function apiUserInfo() {
  return request({
    url: USER_BASE_INFO,
    method: METHOD.GET,
  })
}
/**
 * @description 退出登录接口
 * @returns {AxiosPromise}
 */
export function apiUserLogout() {
  const data = {
    token: getToken(),
  }
  return request({
    url: USER_LOGOUT_PATH,
    method: METHOD.GET,
    data,
  })
}
/**
 * @description 用户是否第一次支付
 * @returns {AxiosPromise}
 */
export function apiIsFirstPurchase() {
  return request({
    url: GET_IS_FIRST_PURCHASE,
    method: METHOD.GET,
  })
}




