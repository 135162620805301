var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "partyLogin-center_bg" }, [
    _c("div", { staticClass: "title" }, [
      _vm._v(" " + _vm._s(_vm.title) + " "),
    ]),
    _c(
      "div",
      { staticClass: "main" },
      _vm._l(_vm.list, function (item, index) {
        return _c("div", { key: index }, [
          _c("div", { staticClass: "center" }, [
            _c(
              "div",
              [
                _c("za-svg-icon", {
                  attrs: {
                    "class-name": "icon-show",
                    "icon-class": item.state ? item.icon : item.noneIcon,
                  },
                }),
                _c("span", [_vm._v(_vm._s(item.name))]),
                _c(
                  "span",
                  { staticClass: "bind", class: { notBind: !item.state } },
                  [_vm._v(_vm._s(item.state ? "[已绑定]" : "[未绑定]"))]
                ),
              ],
              1
            ),
            _c("div", [
              _c(
                "span",
                {
                  staticClass: "state",
                  on: {
                    click: function ($event) {
                      return _vm.handleChange(item)
                    },
                  },
                },
                [_vm._v(_vm._s(item.state ? "解绑" : "立即绑定"))]
              ),
            ]),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }