import request from '@/utils/request'
import { MONEY_FINANCE_GET,METHOD,MONEY_FINANCE_SUBMIT,MONEY_PAY_STATEQUERY} from  '@/api/api.config'
//获取在线预充值余额接口
export function apiGetBalance(data) {
    return request({
        url:  MONEY_FINANCE_GET,
        method: METHOD.GET,
        params: data,
    })
}
//获取提交账户接口
export function apisubmit(data) {
    return request({
        url:  MONEY_FINANCE_SUBMIT,
        method: METHOD.POST,
         data,
    })
}
//获取提交账户接口
export function apiStateQuery(data) {
    return request({
        url:  MONEY_PAY_STATEQUERY,
        method: METHOD.POST,
        data,
    })
}