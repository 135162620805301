<template>
  <div class="drawInfo-center_bg">
    <div>
      <za-go-back title="确认发票信息" @click="goBack"></za-go-back>
    </div>
    <div class="main">
      <div class="prompt">
        <span>温馨提示:</span>
        <span>发票基于预付费订单、后付费月结数据开具，后付费的可开票金额可在次月2日后申请发票</span>
      </div>
      <div>
        <div class="heard">
          <span>请确认发票抬头</span>
          <span
            v-if="!tableData.length"
            class="fillOut"
            @click="$router.push('/moneyCenter/invoiceManagement/address')"
          >
            <i class="el-icon-plus"></i>
            填写发票信息
          </span>
        </div>
        <occ-table :tableData="tableData" :columObj="columObj" :isOp="columObj.isOp" :style-height="'98'">
          <template #default="{ row }">
            <el-button type="primary" @click="handleChange(row)">修改</el-button>
          </template>
        </occ-table>
      </div>
      <div class="main-redio">
        <!--                <div class="heard">
                          <span>请选择发票类型</span>
                        </div>
                        <el-radio v-model="radio" :label="1">数电票普通发票</el-radio>
                        <el-radio v-model="radio" :label="2">数电票专用发票</el-radio>-->
        <div class="main-tesc">
          <div>
            <div class="heard">
              <span>填写发票备注（选填）</span>
            </div>
            <div style="width: 30%">
              <el-form ref="form" :model="form" label-width="120px" @submit.native.prevent>
                <el-input
                  type="textarea"
                  v-model="form.desc"
                  :rows="6"
                  placeholder="填写后打印在发票的备注内容区域"
                  maxlength="230"
                ></el-input>
              </el-form>
              <span class="tips">
                <span style="color: red">*</span>
                发票备注不可超过 230 个字节，一个中文字符为 2 个字节
              </span>
            </div>
          </div>
          <!--                    <div v-if="radio == '2'">
                                <paper ref="paper" @click="changeTable" @getText="getText"></paper>
                              </div>-->
        </div>
      </div>
      <div class="bun_fix">
        <el-button primary @click="toTop">上一步</el-button>
        <!--        <el-button type="primary" v-if="radio == '2'" @click="paperBtn">下一步</el-button>-->
        <!--        <el-button type="primary" v-if="radio == '1'" @click="nextBtn">下一步</el-button>-->
        <el-button type="primary" @click="nextBtn">下一步</el-button>
        <el-button primary @click="cancel">取消</el-button>
      </div>
    </div>

    <!--    确认开票提示框-->
    <za-dialog top-title="" :visible.sync="infoDialog" width="420px" :titleState="false" :footState="false">
      <div>
        <div>
          <div class="icon">
            <i class="el-icon-warning" style="color: blue"></i>
            <p>确认开票</p>
          </div>
          <div class="iconInfo">
            <!--            <span v-if="radio == 2">-->
            <!--              请您仔细核对-->
            <!--              <span style="color: red">发票信息</span>-->
            <!--              及-->
            <!--              <span style="color: red">邮寄地址</span>-->
            <!--              ！-->
            <!--              <span>-->
            <!--                若因发票信息填写错误导致发票不可用，需待发票开具后致电客服热线: 400-018-5113 发起线下退票申请。-->
            <!--              </span>-->
            <!--            </span>-->

            <span>若因发票信息填写错误导致发票不可用，需待发票开具后发起退票申请。请仔细核对发票信息！</span>
          </div>
          <div class="wanningSubmit">
            <za-button :loading="confirmBtnLoading" :type="buttonTypeObj.BLUE" @click="infoDialogSubmit">
              确定
            </za-button>
            <za-button :disabled="confirmBtnLoading" :type="buttonTypeObj.GREY" @click="infoDialog = false">
              取消
            </za-button>
          </div>
        </div>
      </div>
    </za-dialog>

    <!--    开票请求提交成功弹框-->
    <za-dialog top-title="开票申请提交成功" :visible.sync="lastInfoDialog" width="600px">
      <div>
        <div class="success">
          <span>
            <za-svg-icon icon-class="za-wanning"></za-svg-icon>
            电子发票将在申请提交24小时内开具并发送至您的联系邮箱，您可以在联系邮箱里下载发票。
          </span>
          <!--          <span v-if="radio == '2'">-->
          <!--            <za-svg-icon icon-class="za-wanning"></za-svg-icon>-->
          <!--            &lt;!&ndash; 当天24点前可进入<span style="color: #409EFF">开票记录</span>页面撤销发票申请:若超过撤销时间，则需待收到发票后发起退票申请并回寄相关材料，-->
          <!--            我方收到退回材料后3个工作日内释放可开票金额。发票信息审核通过后将在3个工作日内为您寄出发票 &ndash;&gt;-->
          <!--            请您在提交开纸质发票后24小时之内完成撤销操作，否则系统将不再支持撤销。撤销后，该条纸质发票的申请状态将变为【已取消】，同时也无法再次恢复该发票。-->
          <!--          </span>-->
        </div>
      </div>
      <div slot="footer">
        <za-button :loading="confirmBtnLoading" :type="buttonTypeObj.BLUE" @click="closeLastInfoDialog">确定</za-button>
      </div>
    </za-dialog>

    <!--    修改发票信息弹框-->
    <za-dialog :top-title="dialogType | dialogTitle(dialogTypeObj)" :visible.sync="dialogVisible" width="600px">
      <div>
        <div class="tipsInfo">
          <za-svg-icon icon-class="za-wanning"></za-svg-icon>
          <!--          <span>请您与贵司财务人员核实发票信息，确保信息填写正确，以免影响发票的后续使用。</span>-->
          <span>
            普票电子化通知：为积极响应国家发票无纸化政策，智安云云将于2023年10月1日起关闭增值税电子发票和增值税纸质发票申请入口，改为数电发票，请您确认重新确认发票信息无误，以免影响发票的后续使用。
          </span>
        </div>
        <el-form
          ref="form"
          :disabled="confirmBtnLoading"
          :model="baseForm"
          label-position="right"
          label-width="140px"
          :rules="rules"
        >
          <el-form-item :label="'抬头类型' + '：'" prop="headType">
            <el-radio-group v-model="baseForm.headType" @change="changeRadio">
              <el-radio :label="headTypes.personal" :disabled="userInfo.accountType === accountTypes.entCer">
                {{ headTypesName.personal }}
              </el-radio>
              <el-radio :label="headTypes.enterprise">{{ headTypesName.enterprise }}</el-radio>
              <el-radio :label="headTypes.organization">{{ headTypesName.organization }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="'发票类型' + '：'" prop="invoicesType">
            <el-radio-group v-model="baseForm.invoicesType" @change="changeInvoicesTypeRadio">
              <el-radio :label="invoicesType.digEleTicket">{{ invoiceTypeName.digEleTicket }}</el-radio>
              <el-radio
                :label="invoicesType.digSpeTicket"
                v-if="baseForm.headType === headTypes.enterprise"
                :disabled="userInfo.accountType === accountTypes.perCer || userInfo.accountType === accountTypes.notCer"
              >
                {{ invoiceTypeName.digSpeTicket }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="'发票抬头：'" prop="head">
            <el-input
              v-model="baseForm.head"
              :placeholder="`${translateTitle('placeTxt')}`"
              :disabled="
                baseForm.headType === headTypes.personal || baseForm.invoicesType === invoicesType.digSpeTicket
              "
              @keyup.native="trim('head')"
            />
          </el-form-item>
          <el-form-item
            :label="baseForm.headType === headTypes.enterprise ? '企业信用代码：' : '纳税人识别号：'"
            v-if="baseForm.headType !== headTypes.personal"
            prop="taxpayerNumber"
          >
            <el-input
              v-model="baseForm.taxpayerNumber"
              maxlength="20"
              :placeholder="baseForm.headType === headTypes.enterprise ? '请填写企业信用代码' : '请填写纳税人识别号'"
              @keyup.native="trim('taxpayerNumber')"
            />
          </el-form-item>
          <el-form-item
            :label="'基本开户银行：'"
            v-if="baseForm.headType === headTypes.enterprise"
            prop="bankName"
            :rules="baseForm.invoicesType === invoicesType.digSpeTicket ? rules.bankName : rules2.bankName"
          >
            <el-input
              v-model="baseForm.bankName"
              :minlength="4"
              :maxlength="50"
              placeholder="请输入真实有效的开户银行信息"
              @keyup.native="trim('bankName')"
            />
          </el-form-item>
          <el-form-item
            :label="'基本开户账号：'"
            v-if="baseForm.headType === headTypes.enterprise"
            prop="bankAccount"
            :rules="baseForm.invoicesType === invoicesType.digSpeTicket ? rules.bankAccount : rules2.bankAccount"
          >
            <el-input
              v-model="baseForm.bankAccount"
              placeholder="请填写许可证上的开户账号"
              @input="limitInput($event, 'bankAccount')"
              @keyup.native="trim('bankAccount')"
            />
          </el-form-item>
          <el-form-item
            :label="'企业注册地址：'"
            v-if="baseForm.headType === headTypes.enterprise"
            prop="logonAddress"
            :rules="baseForm.invoicesType === invoicesType.digSpeTicket ? rules.logonAddress : rules2.logonAddress"
          >
            <el-input
              v-model="baseForm.logonAddress"
              :minlength="4"
              :maxlength="50"
              placeholder="请输入企业注册地址"
              @keyup.native="trim('logonAddress')"
            />
          </el-form-item>
          <el-form-item
            :label="'企业注册电话：'"
            v-if="baseForm.headType === headTypes.enterprise"
            prop="logonPhone"
            :rules="baseForm.invoicesType === invoicesType.digSpeTicket ? rules.logonPhone : rules2.logonPhone"
          >
            <el-input
              v-model="baseForm.logonPhone"
              placeholder="请填写企业有效联系方式"
              maxlength="20"
              @input="limitInput($event, 'logonPhone')"
              @keyup.native="trim('logonPhone')"
            />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" style="margin-top: 10px">
        <za-button :disabled="confirmBtnLoading" :type="buttonTypeObj.GREY" @click="dialogVisible = false">
          {{ translateTitle('btn.cancel') }}
        </za-button>
        <za-button :loading="confirmBtnLoading" :type="buttonTypeObj.BLUE" @click="handleSubmitForm">
          {{ translateTitle('btn.submit') }}
        </za-button>
      </div>
    </za-dialog>
  </div>
</template>
<script>
  // import paper from './paper'
  import { apiUpdateAddress } from '@/api/moneyCenter/invoiceManagement/address'
  import { apiFinanceHead } from '@/api/moneyCenter/invoiceManagement/askfor'
  import { isNumOrEng } from '@/utils/validate'
  import { apiubmitForm, apiubmitOrder } from '@/api/moneyCenter/invoiceManagement/drawAbill'
  import { accountTypes, headTypes, headTypesName, invoicesType, invoiceTypeName } from '@/utils/enums'
  import routeAddress from '@/utils/routeAdress'
  import { mapGetters } from 'vuex'
  import { apiCertificationName } from '@/api/moneyCenter/expenseManagement/moneyExtract'

  export default {
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      invoiceTypeName() {
        return invoiceTypeName
      },
      headTypesName() {
        return headTypesName
      },
      invoicesType() {
        return invoicesType
      },
      headTypes() {
        return headTypes
      },
      accountTypes() {
        return accountTypes
      },
    },
    // components: { paper },
    data() {
      const taxpayer = (rule, value, callback) => {
        let reg = /[a-z]/g
        if (!value) callback(new Error('请输入15位, 17位, 18位或20位真实有效的纳税人识别号'))
        else if (value.length !== 15 && value.length !== 17 && value.length !== 18 && value.length !== 20)
          callback(new Error('请输入15位, 17位, 18位或20位真实有效的纳税人识别号'))
        else if (isNumOrEng(value)) callback(new Error('请输入数字或英文'))
        else if (reg.test(value)) callback(new Error('请输入大写字母'))
        else callback()
      }
      const bankName = (rule, value, callback) => {
        if (value.length > 99) callback(new Error('请输入真实有效的开户银行信息'))
        else if (value === '') callback(new Error('请输入真实有效的开户银行信息'))
        else callback()
      }
      const addressRule = (rule, value, callback) => {
        if (value.length < 4 || value.length > 50) callback(new Error('请输入企业注册地址'))
        else if (value === '') callback(new Error('请输入企业注册地址'))
        else callback()
      }
      const bankUser = (rule, value, callback) => {
        if (value.length < 4 || value.length > 50) callback(new Error('请输入真实有效的开户账号信息'))
        else if (value === '') callback(new Error('请输入真实有效的开户账号信息'))
        else callback()
      }
      const comPhone = (rule, value, callback) => {
        if (value.length < 1 || value.length > 20) callback(new Error('请输入真实有效的企业注册电话'))
        else if (value === '') callback(new Error('请输入真实有效的企业注册电话'))
        else callback()
      }

      const bankNameEmpty = (rule, value, callback) => {
        if (value === '') callback()
        else if (value.length > 50 || value.length < 4) callback(new Error('请输入4-50字符'))
        else callback()
      }
      const bankUserEmpty = (rule, value, callback) => {
        if (value === '') callback()
        else if (value.length < 4 || value.length > 50) callback(new Error('请输入4-50位数字'))
        else callback()
      }
      const comPhoneEmpty = (rule, value, callback) => {
        if (value === '') callback()
        else if (value.length < 1 || value.length > 20) callback(new Error('请输入1-20位数字'))
        else callback()
      }
      return {
        infoDialog: false,
        lastInfoDialog: false,
        radio: headTypes.personal,
        form: {
          desc: '',
        },
        baseForm: {
          head: '',
          invoicesTypeName: '',
          invoicesType: '',
          headType: '',
          taxpayerNumber: '',
          bankName: '',
          bankAccount: '',
          logonAddress: '',
          logonPhone: '',
        },
        tableData: [],
        columObj: {
          // 控制选择框
          selection: false,
          isOp: true,
          width: '300px',
          columnData: [
            {
              text: true,
              prop: 'head',
              label: '发票抬头',
              width: '',
              flag: true,
              align: 'center',
            },
            {
              ownInnerHtml: true,
              prop: 'headType',
              label: '抬头类型',
              flag: true,
              width: '',
              align: 'center',
              htmlTest: (row, $index) => {
                return row.headType === headTypes.personal
                  ? headTypesName.personal
                  : row.headType === headTypes.organization
                  ? headTypesName.organization
                  : row.headType === headTypes.enterprise
                  ? headTypesName.enterprise
                  : ''
              },
            },
            {
              text: true,
              prop: 'invoicesTypeName',
              label: '发票类型',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              text: true,
              prop: 'taxpayerNumber',
              label: '纳税人识别号',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              text: true,
              prop: 'bankName',
              label: '开户银行',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              text: true,
              prop: 'bankAccount',
              label: '开户账号',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              text: true,
              prop: 'logonAddress',
              label: '企业注册地址',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              text: true,
              prop: 'logonPhone',
              label: '企业注册电话',
              flag: true,
              width: '',
              align: 'center',
            },
          ],
        },
        changeTableId: 0,
        rules: {
          headType: [{ required: true, message: '请选择抬头类型', trigger: 'change' }],
          invoicesType: [{ required: true, message: '请选择发票类型', trigger: 'change' }],
          head: [{ required: true, message: '请输入发票抬头', trigger: 'blur' }],
          bankName: [{ required: true, validator: bankName, trigger: 'blur' }],
          bankAccount: [{ required: true, validator: bankUser, trigger: 'blur' }],
          logonAddress: [{ required: true, validator: addressRule, trigger: 'blur' }],
          logonPhone: [{ required: true, validator: comPhone, trigger: 'blur' }],
          taxpayerNumber: [{ required: true, validator: taxpayer, trigger: 'blur' }],
        },
        rules2: {
          bankName: [{ required: false, validator: bankNameEmpty, trigger: 'blur' }],
          bankAccount: [{ required: false, validator: bankUserEmpty, trigger: 'blur' }],
          logonAddress: [{ required: false, validator: bankNameEmpty, trigger: 'blur' }],
          logonPhone: [{ required: false, validator: comPhoneEmpty, trigger: 'blur' }],
        },
        certificationName: '',
        defaultRow: {},
        // 当前用户发票类型
        invoiceType: null,
      }
    },
    mounted() {
      this.dialogTypeObj.EDIT.text = '修改发票信息'
      this.getList()
    },
    methods: {
      /**
       * @description 获取发票抬头信息和实名认证主体信息
       * */
      async getList() {
        this.onSubmitData(apiFinanceHead, null, null, false, false, (res) => {
          this.tableData = []
          this.defaultRow = JSON.parse(JSON.stringify(res.data))
          if (res.data.id === 0) {
            this.tableData = []
          } else {
            this.tableData.push(res.data)
            this.invoiceType = res.data.invoicesType
          }
        })
        const resultName = await apiCertificationName().catch()
        this.certificationName = resultName.data.name || ''
      },

      limitInput(value, name) {
        let val = '' + value
        val =
          val
            .replace(/[^\d^\.]+/g, '')
            .replace(/^0+(\d)/, '$1')
            .replace(/^\./, '0.')
            .match(/^\d*(\.?\d{0,2})/g)[0] || ''
        this.baseForm[name] = val
      },

      trim(val) {
        this.baseForm[val] = this.baseForm[val].replace(/\s*/g, '')
      },

      /**
       * @description 重置表单部分数据
       */
      resetForm() {
        this.baseForm.head = ''
        this.baseForm.taxpayerNumber = ''
        this.baseForm.bankName = ''
        this.baseForm.bankAccount = ''
        this.baseForm.logonAddress = ''
        this.baseForm.logonPhone = ''
      },

      /**
       * @description  修改数据回显
       * @param row
       * @returns {Promise<void>}
       */
      async handleChange(row) {
        // 深拷贝当前用户发票抬头数据
        this.baseForm = Object.assign({}, this.defaultRow)
        // 对老用户数据进行强制处理
        if (row.invoicesType === invoicesType.vatGenTicket) this.baseForm.invoicesType = invoicesType.digEleTicket
        else if (row.invoicesType === invoicesType.vatSpeTicket) this.baseForm.invoicesType = invoicesType.digSpeTicket
        this.dialogType = this.dialogTypeObj.EDIT.type
        this.$nextTick(() => {
          this.dialogVisible = true
        })
      },

      /**
       * @description 抬头类型更改
       * @param val {Number} 抬头类型绑定的枚举值
       */
      changeRadio(val) {
        // 修改
        if (this.defaultRow.id !== 0) {
          // 默认选中数电普票
          this.baseForm.invoicesType = invoicesType.digEleTicket
          // 发票抬头与数据库数据相同，展示数据；否则清空
          if (this.defaultRow.headType === val) {
            this.baseForm = Object.assign({}, this.defaultRow)
          } else {
            this.resetForm()
          }
        }
        // 抬头类型为个人，则默认为实名主体
        if (val === headTypes.personal) this.baseForm.head = this.certificationName
        this.$nextTick(() => {
          this.$refs['form'].clearValidate()
        })
      },

      /**
       * @description 发票类型更改(数电专票和数电普票切换)
       * @param val {Number} 发票类型绑定的枚举值
       */
      changeInvoicesTypeRadio(val) {
        if (this.defaultRow.id !== 0) {
          // 发票类型与当前用户一致，则展示原数据；否则置为空
          if (this.defaultRow.invoicesType === val && this.defaultRow.headType === this.baseForm.headType) {
            this.baseForm.head = this.defaultRow.head
            this.baseForm.taxpayerNumber = this.defaultRow.taxpayerNumber
            this.baseForm.logonAddress = this.defaultRow.logonAddress
            this.baseForm.logonPhone = this.defaultRow.logonPhone
            this.baseForm.bankAccount = this.defaultRow.bankAccount
            this.baseForm.bankName = this.defaultRow.bankName
          } else {
            this.resetForm()
          }
        }
        // 发票类型为数电专票，则抬头默认为实名主体
        if (val === invoicesType.digSpeTicket) this.baseForm.head = this.certificationName
        this.$nextTick(() => {
          this.$refs['form'].clearValidate()
        })
      },

      /**
       * @description （新增、修改）抬头信息
       * @returns {Promise<void>}
       */
      async handleSubmitForm() {
        if (this.dialogType === this.dialogTypeObj.EDIT.type && !this.isCanSubmit(this.baseForm)) {
          this.$msgWarning('您还未对数据进行修改')
          return
        }
        await this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const msg = '修改成功'
            this.confirmBtnLoading = true
            const res = await apiUpdateAddress(this.baseForm).finally(() => (this.confirmBtnLoading = false))
            if (res.code === 0) {
              this.$msgSuccess(msg)
              await this.getList()
              this.dialogVisible = false
            }
          } else {
            return false
          }
        })
      },
      toTop() {
        this.$router.push(routeAddress.drawAbill)
      },
      goBack() {
        this.$router.push(routeAddress.drawAbill)
      },
      //       paperBtn() {
      // if (this.tableData && this.changeTableId && this.$refs.paper.tableData) {
      // this.infoDialog = true
      // } else if (!this.tableData.length) {
      // this.$msgWarning('温馨提示：请先填写发票抬头信息，谢谢。')
      // } else if (!this.$refs.paper.tableData.length) {
      // this.$msgWarning('温馨提示：发票邮寄地址为空，请添加发票邮寄地址！')
      // } else if (!this.changeTableId) {
      // this.$msgWarning('温馨提示：请选择邮寄地址，谢谢。')
      // }
      // },
      nextBtn() {
        if (this.tableData.length) {
          this.infoDialog = true
          //if (this.radio == 1 && this.tableData[0].invoicesTypeName == '增值税专用发票') {
          // this.$msgWarning('温馨提示：目前专用发票不支持电子发票，请申请纸质发票，谢谢。')
          // } else {
          // this.infoDialog = true
          // }
        } else {
          this.$msgWarning('温馨提示：请先填写发票抬头信息，谢谢。')
        }
      },
      cancel() {
        this.$router.push(routeAddress.askfor)
      },
      // changeTable(data) {
      //   if (data.data.length > 0) {
      //     this.changeTableId = data.data[0].id
      //   } else {
      //     this.changeTableId = 0
      //   }
      // },
      // getText(date) {
      //   this.desc = date
      // },

      /**
       * @description 发票信息确认提交
       * */
      infoDialogSubmit() {
        if (this.$route.query.type === 'mouth') {
          this.invoicingFun(apiubmitForm, 'drawAbill')
        } else {
          // let desc = this.radio == 1 ? this.form.desc : this.desc
          this.invoicingFun(apiubmitOrder, 'order')
        }
      },

      /**
       * @description 开票接口 , 判断是否为老用户增值税发票类型：是，强制修改发票类型；不是，开票申请成功
       * @param {Function} api 订单开票、月份开票两个接口
       * @param {String} type 对应开票类型，获取对应的 list
       * */
      invoicingFun(api, type) {
        if (this.invoiceType === invoicesType.vatGenTicket || this.invoiceType === invoicesType.vatSpeTicket)
          this.handleChange(this.tableData[0])
        else {
          let obj = {
            list: JSON.parse(localStorage.getItem(type)),
            invoicesAddrId: this.changeTableId,
            desc: this.form.desc,
            medium: 1,
          }
          api(obj).then((res) => {
            this.confirmBtnLoading = false
            if (res.code === 0) {
              this.infoDialog = false
              this.lastInfoDialog = true
            }
          })
        }
      },

      closeLastInfoDialog() {
        this.lastInfoDialog = false
        this.$router.push(routeAddress.drawAbill)
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import '~@/za/styles/mixin.scss';

  .drawInfo-center_bg {
    position: relative;

    .main {
      padding: 20px;
      background: #ffffff;

      .title {
        padding: 20px;
        height: 110px;
        background: #f4f6fa;
        border-radius: 2px;
        margin-bottom: 30px;

        div:first-child {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          margin-bottom: 10px;
        }

        span:last-child {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #555555;
        }
      }

      .heard {
        margin-bottom: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #000000;
      }

      .main-redio {
        margin-top: 30px;

        .main-tesc {
          margin-top: 27px;
          margin-bottom: 64px;
        }
      }

      .bun_fix {
        padding: 16px 10px 16px 70%;
        position: fixed;
        left: 210px;
        bottom: 0px;
        width: 100%;
        right: 0;
        height: 64px;
        background: #ffffff;
        z-index: 99;
        box-shadow: 0px -9px 28px 8px rgba(0, 0, 0, 0.05);
      }
    }
  }

  .icon {
    font-size: 50px;
    text-align: center;

    p {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #000000;
      margin: 12px 0 13px 0;
    }
  }

  .tips {
    margin-top: 20px;
    margin-bottom: 20px;
    display: block;

    span:first-child {
      color: red;
    }
  }

  .iconInfo {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #555555;
    margin-bottom: 19px;
  }

  .wanningSubmit {
    text-align: center;
  }

  .tipsInfo {
    height: 80px;
    background: #fffbe6;
    border-radius: 2px;
    border: 1px solid #ffe58f;
    padding: 9px;
    margin-bottom: 30px;
  }

  .fillOut {
    font-size: 14px;
    color: #409eff;
    margin-left: 20px;
    cursor: pointer;
  }

  .success {
    background: #fffbe6;
    border-radius: 2px;
    border: 1px solid #ffe58f;
    padding: 9px;
    margin-bottom: 30px;
  }

  .prompt {
    width: 100%;
    height: 90px;
    background: #fffbe6;
    border-radius: 2px;
    border: 1px solid #ffe58f;
    padding: 34px 15px;
    margin-bottom: 30px;

    h5 {
      @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
      margin: 0px 0px 10px 0px;
    }

    span {
      font-size: 12px;
      margin-bottom: 8px;
      color: #555555;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;

      &:first-child {
        font-weight: 700;
        font-size: 14px;
      }
    }
  }
</style>
