<template>
    <div class="footer">
        <div class="title">返佣订单</div>
        <occ-table :tableData="tableData" :columObj="columObj"
                   :isOp="columObj.isOp"> </occ-table>
    </div>
</template>
<script>

    export default {
        data() {
            return {
                tableData: [],
                columObj: {
                    // 控制选择框
                    selection: true,
                    isOp: false,
                    width: '300px',
                    columnData: [
                        {
                            test: true,
                            prop: "center",
                            label: "产品名称",
                            width: "",
                            flag: true,
                            align: "center",
                        },
                        {
                            test: true,
                            prop: "type",
                            label: "用户名称",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            test: true,
                            prop: "pay",
                            label: "交易流水",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "time",
                            label: "订单金额",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "time",
                            label: "总返佣比例",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "time",
                            label: "推广佣金",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "time",
                            label: "订单支付时间",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "time",
                            label: "订单状态",
                            flag: true,
                            width: "",
                            align: "center",
                        }
                    ],
                },
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '~@/za/styles/mixin.scss';
.footer{
    .title {
        @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
        width: 70px;
        height: 20px;
        margin-bottom: 20px;
    }
    background-color: #FFFFFF;
    padding: 20px;
}
</style>