<template>
  <div class="add-panel">
    <div :style="itemWidth !== '' ? { width: itemWidth } : ''" class="comp-con">
      <div
        v-for="(item, index) in _value"
        :key="item.guid"
        :class="[{ 'last-item': isCloseAddBtn }, { 'is-bg': isShowBg }, { 'comp-bottom': isOpenMarginBottom }]"
        class="comp-item"
      >
        <component
          :is="comp"
          :key="item.guid"
          ref="component"
          v-model="_value[index]"
          :disabled="disabled"
          :head-data="headData"
          :sub-data="subData"
        />
        <i
          v-show="_value.length > 1"
          :class="[{ 'close-btn_disabled': disabled }, { 'close-btn_no_bottom': !isOpenMarginBottom }]"
          :style="closeStyle"
          class="el-icon-error close-btn"
          @click="_handleDelSub(index)"
        />
      </div>
    </div>
    <div v-show="!isCloseAddBtn" :class="{ 'add-btn_disabled': disabled }" class="add-btn" @click="_handleAdd">
      <i class="el-icon-circle-plus"/>
      <span>{{ translateTitle('btn.add') }}</span>
    </div>
  </div>
</template>

<script>
import {guid} from '@/za/components/zaAdd/zaAddUtils'
import {translateTitle} from '@/utils/i18n'

/**
 * @description 新增复合主组件(该组件需要配个v-if进行刷新)
 * @property {Array} value v-model 绑定对象新增的数据集合(如有数据回显则在绑定前请自行使用guid()方法生成唯一标识)
 * @property {String, Object} comp 动态绑定的组件/名称
 * @property {Number} maxLimit 最大添加数量，默认：-1(表示无限制)
 * @property {Object} templateDataObj 模板数据，用以生成动态组件的对象数据
 * @property {Object, Array} subData 子组件所需用到的相关数据，根据实际情况自行设定
 * @property {String} item 子组件所占宽度
 * @property {Object} closeStyle 子组件对应关闭按钮的样式
 * @property {Boolean} isShowBg 是否显示背景
 * @property {Boolean} isOpenVerify 添加时是否开启rules效验(如果true，则动态组件必需实现效验方法: async verify())
 * @property {Boolean} isFirstAddObj 初始是否自动添加一条模板数据
 * @property {Boolean} disabled 是否禁用
 * @property {Boolean} isOpenMarginBottom 是否开启item margin
 */
export default {
  name: 'ZaAdd',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    comp: {
      type: [String, Object],
      default: null,
    },
    maxLimit: {
      type: Number,
      default: -1,
    },
    templateDataObj: {
      type: Object,
      default: () => null,
    },
    subData: {
      type: [Object, Array],
      default: null,
    },
    headData: {
      type: [Object, Array],
      default: null,
    },
    itemWidth: {
      type: String,
      default: '',
    },
    closeStyle: {
      type: Object,
      default: () => {
      },
    },
    isShowBg: {
      type: Boolean,
      default: false,
    },
    isOpenVerify: {
      type: Boolean,
      default: true,
    },
    isFirstAddObj: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isOpenMarginBottom: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    _value: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    isCloseAddBtn() {
      return this.maxLimit === -1 ? false : this.value.length === this.maxLimit
    },
  },
  mounted() {
    if (this._value.length === 0) {
      this.clearAll()
      if (this.isFirstAddObj) this._newAddData()
    } else {
      const copyData = this.$deepClone(this._value)
      this.clearAll()
      for (let i = 0; i < copyData.length; i++) {
        this._newAddData(copyData[i])
      }
    }
  },
  methods: {
    translateTitle,
    /**
     * @description 效验所有动态组件
     * @returns {Promise<boolean>}
     */
    async isVerifyAll() {
      let isVer = true
      for (let i = 0; i < this._value.length; i++) {
        if (this.$refs.component[i].verify) {
          isVer = isVer && (await this.$refs.component[i].verify())
        }
      }
      return isVer
    },
    /**
     * @description 新增组件
     * @returns {Promise<void>}
     */
    async _handleAdd() {
      if (this.disabled) return
      let isVer = true
      if (this.isOpenVerify) {
        isVer = await this.isVerifyAll()
      }
      if (isVer) this._newAddData()
    },
    /**
     * @description 添加数据
     * @private
     */
    _newAddData(copyData = null) {
      if (this.templateDataObj === null) return
      const data = this.$deepClone(this.templateDataObj)
      data.guid = guid()
      if (copyData) {
        for (const key in data) {
          if (copyData[key] !== null && copyData[key] !== undefined) data[key] = copyData[key]
        }
      }
      this._value.push(data)
    },
    /**
     * @description 清除所有添加数据
     */
    clearAll(isAddOnce = false) {
      this._value.splice(0)
      if (isAddOnce) this._newAddData()
    },
    /**
     * @description 删除子组件
     * @param index 删除的组件index
     * @private
     */
    _handleDelSub(index) {
      if (this.disabled) return
      this._value.splice(index, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.add-panel {
  width: 100%;
  height: auto;

  .comp-con {
    width: calc(100% - 30px);
    height: auto;

    .comp-item {
      position: relative;
      width: 100%;
      height: auto;

      .close-btn {
        cursor: pointer;
        font-size: 20px;
        position: absolute;
        top: calc(50% - 10px);
        right: -30px;
        color: #80a4bc;
      }

      .close-btn_no_bottom {
        top: calc(50% - 10px - 9px);
      }

      .close-btn_disabled {
        cursor: not-allowed;
        color: #424d5b;
      }
    }

    .comp-bottom {
      margin-bottom: 20px;
    }

    .is-bg {
      border-radius: 2px;
      border: 1px solid #536978;
      padding: 19px 20px;
    }

    .last-item {
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }

  .slot-con {
    width: 100%;
    height: auto;
  }

  .add-btn {
    cursor: pointer;
    width: 100%;
    //width: 380px;
    /* px-to-viewport-ignore-next */
    height: 32px;
    background: #2d3e53;
    border-radius: 2px;
    border: 1px #536978 dashed;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      border: 1px $base-blue-theme dashed;
    }

    i {
      /* px-to-viewport-ignore-next */
      margin-right: 6px;
      color: #80a4bc;
    }

    span {
      /* px-to-viewport-ignore-next */
      font-size: 14px;
      font-weight: 400;
      color: #80a4bc;
    }
  }

  .add-btn_disabled {
    cursor: not-allowed;
    background: #424d5b;

    &:hover {
      border-color: #536978;
    }
  }
}
</style>
