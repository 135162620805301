var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar"),
      _c("div", { staticClass: "main" }, [
        _c(
          "div",
          [
            _c("el-image", {
              staticClass: "wechatPay",
              attrs: { src: require("@/assets/pay/wechatPay.png") },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "payMoney" }, [
          _c("div", { staticClass: "infoTitle" }, [
            _c("span", [_vm._v("支付金额")]),
            _c("span", { staticStyle: { color: "red" } }, [
              _vm._v("￥" + _vm._s(_vm.amount)),
            ]),
            _c("span", [_vm._v("请使用微信扫描下方二维码完成支付")]),
          ]),
          _c("div", { staticClass: "infoMain" }, [
            _c(
              "div",
              { staticClass: "code" },
              [
                _c("el-image", {
                  staticClass: "dimensional",
                  attrs: { src: _vm.getUrl() },
                }),
              ],
              1
            ),
            _c("div", [
              _c("ul", [
                _c("li", [
                  _c("span", [_vm._v("商家名称：")]),
                  _c("span", [_vm._v(_vm._s(_vm.businessName))]),
                ]),
                _c("li", [
                  _c("span", [_vm._v("商品名称：")]),
                  _c("span", [_vm._v(_vm._s(_vm.goodsName))]),
                ]),
                _c("li", [
                  _c("span", [_vm._v("流水编号：")]),
                  _c("span", [_vm._v(_vm._s(_vm.orderNumber))]),
                ]),
                _c("li", [
                  _c("span", [_vm._v("创建时间：")]),
                  _c("span", [_vm._v(_vm._s(_vm.createAt))]),
                ]),
              ]),
            ]),
          ]),
          _vm._m(0),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "footer" }, [
      _c("div", [_vm._v("智安云")]),
      _c("div", [_vm._v("联系电话：400-018-5113")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }