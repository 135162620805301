import request from '@/utils/request'
import {
    GET_FINANCE_UPDATE,
    METHOD,
    GET_FINANCE_GETADDRESSLIST,
    GET_FINANCE_ADDRESSLIST,
    GET_FINANCE_GETDEFAULT_ADDRESS,
    GET_FINANCE_DEL_ADDRESS,
    GET_FINANCE_UPDATE_ADDRESS
} from '@/api/api.config'

//新增，编辑发票抬头接口
export function apiUpdateAddress(data) {
    return request({
        url: GET_FINANCE_UPDATE,
        method: METHOD.POST,
        data,
    })
}

//获取邮寄地址接口
export function apiGetAddressList(data) {
    return request({
        url: GET_FINANCE_GETADDRESSLIST,
        method: METHOD.GET,
        params: data,
    })
}

//新增邮寄地址接口
export function apiAddressList(data) {
    return request({
        url: GET_FINANCE_ADDRESSLIST,
        method: METHOD.POST,
        data,
    })
}

//设置为默认邮寄地址接口
export function apiGetDefaultAddress(data) {
    return request({
        url: GET_FINANCE_GETDEFAULT_ADDRESS,
        method: METHOD.POST,
        data,
    })
}
//删除邮寄地址接口
export function apiDelAddress(data) {
    return request({
        url: GET_FINANCE_DEL_ADDRESS,
        method: METHOD.POST,
        data,
    })
}
//修改邮寄地址接口
export function apiUpdateAdd(data) {
    return request({
        url: GET_FINANCE_UPDATE_ADDRESS,
        method: METHOD.POST,
        data,
    })
}