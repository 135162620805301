var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v(_vm._s(_vm.title))]),
    ]),
    _c(
      "div",
      { staticClass: "backOrder_center" },
      [
        _c(
          "el-tabs",
          {
            model: {
              value: _vm.activeName,
              callback: function ($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName",
            },
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "有效订单列表", name: "first" } },
              [_c("effective")],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "无效订单列表", name: "second" } },
              [_c("voider")],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "退降配订单", name: "third" } },
              [_c("refund")],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }