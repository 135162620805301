var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.show
      ? _c("div", [
          _vm.msg == "1" ? _c("span", [_vm._v("充值")]) : _vm._e(),
          _vm.msg == "2" ? _c("span", [_vm._v("新购")]) : _vm._e(),
          _vm.msg == "3" ? _c("span", [_vm._v("续费")]) : _vm._e(),
          _vm.msg == "4" ? _c("span", [_vm._v("提现")]) : _vm._e(),
          _vm.msg == "5" ? _c("span", [_vm._v("退款")]) : _vm._e(),
          _vm.msg == "wechat" ? _c("span", [_vm._v("微信")]) : _vm._e(),
          _vm.msg == "alipay" ? _c("span", [_vm._v("支付宝")]) : _vm._e(),
          _vm.msg == "chengdu"
            ? _c("span", [_vm._v("成都智安网络")])
            : _vm._e(),
        ])
      : _c("div", [
          _c("div", {
            staticClass: "state",
            style: { borderColor: _vm.borderColor },
          }),
          _c("span", [_vm._v(_vm._s(_vm.txt))]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }