import request from '@/utils/request'
import {METHOD,GET_ORDER_LIST,GET_ORDER_DETAIL,GET_ORDER_CANCEL,GET_ORDER_COUPON} from  '@/api/api.config'
//获取我的订单列表接口
export function apiMyOrder(data) {
    return request({
        url:  GET_ORDER_LIST,
        method: METHOD.POST,
       data,
    })
}
//获取我的订单详情列表接口
export function apiMyOrderDetail(data) {
    return request({
        url:  GET_ORDER_DETAIL,
        method: METHOD.GET,
        params: data,
    })
}
//获取取消订单详情列表接口
export function apiMyOrderCancel(data) {
    return request({
        url:  GET_ORDER_CANCEL,
        method: METHOD.POST,
       data,
    })
}
//获取充值订单绑定优惠券接口
export function apiOrderReset(data) {
    return request({
        url:  GET_ORDER_COUPON,
        method: METHOD.POST,
        data,
    })
}
