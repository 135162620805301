var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "za-loading",
          rawName: "v-za-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "voucher-container",
    },
    [
      _c(
        "div",
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "充值记录", name: "rechargeRecord" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("occ-table", {
            attrs: {
              tableData: _vm.tableData,
              columObj: _vm.columObj,
              isOp: _vm.columObj.isOp,
            },
          }),
          _c("pagination", {
            attrs: {
              limit: _vm.pageObj.pageSize,
              page: _vm.pageObj.pageNo,
              total: _vm.total,
            },
            on: {
              "update:limit": function ($event) {
                return _vm.$set(_vm.pageObj, "pageSize", $event)
              },
              "update:page": function ($event) {
                return _vm.$set(_vm.pageObj, "pageNo", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }