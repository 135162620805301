<template>
  <div>
    <el-dropdown :placement="'bottom'">
      <!--      <span>-->
      <el-badge :is-dot="dotFlag">
        <za-svg-icon class-name="svg-homeMa" icon-class="za-home-msg" />
      </el-badge>
      <!--      </span>-->
      <el-dropdown-menu slot="dropdown">
        <div class="top_dropdown_money">
          <div class="top_first">
            <div>站内消息通知</div>
            <div @click="toMsg">消息管理</div>
          </div>
        </div>
        <div v-if="!state">
          <el-dropdown-item v-for="(item, index) in optionsList" :key="index" @click.native="toPath(item.path)">
            <template>
              <div class="magInfo">
                <div>
                  <i class="el-icon-info" :class="{ notRead: item.readAt !== '1' }"></i>
                  {{ item.msg.slice(0, 25) }}
                  <i v-if="item.msg.length > 25">...</i>
                </div>
                <div style="margin-left: 14px">{{ item.time }}</div>
              </div>
            </template>
          </el-dropdown-item>
        </div>
        <div v-else>
          <div class="noMsg">暂无最新消息</div>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {
        optionsList: [],
        state: false,
        dotFlag: false,
      }
    },
    created() {
      this.getList()
      // console.log(this.getMsgData)
    },
    computed: {
      ...mapGetters({
        getMsgData: 'user/MsgList',
      }),
    },
    methods: {
      toPath(val) {
        this.$router.push(val)
      },
      toMsg() {
        this.$router.push('/msgCenter/msgManagement/msgPersonnel')
      },
      async getList() {
        this.optionsList = []
        this.getMsgData &&
          this.getMsgData.forEach((item, index) => {
            if (item.readAt === '0') this.dotFlag = true
            if (index <= 4) {
              this.optionsList.push({
                readAt: item.readAt,
                msg: item.title,
                time: item.receivedTime,
                path: '/msgCenter/stationMsg/allmsg',
              })
            }
          })
        if (!this.optionsList.length) {
          this.state = true
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .svg-homeMa {
    font-size: 20px;
  }

  .top_dropdown_money {
    width: 340px;
    margin-bottom: 10px;

    .top_first {
      width: 100%;
      display: flex;
      justify-content: space-around;
      font-size: 14px;

      div:last-child {
        color: #3370ff;
        cursor: pointer;
      }
    }
  }

  .noMsg {
    font-size: 14px;
    text-align: center;
    margin: auto;
    color: #999999;
  }

  .magInfo {
    height: 60px;
    padding: 5px 10px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    border-bottom: 1px solid #f3f4f7;

    div:last-child {
    }
  }

  .magInfo:hover {
    background: #3370ff;
    color: #ffffff;
    border: none;
  }

  .el-dropdown-menu--small .el-dropdown-menu__item {
    padding: 0 !important;
  }

  .notRead {
    color: red;
  }

  ::v-deep .el-badge__content.is-fixed {
    top: 10px;
  }
</style>