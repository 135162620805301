import request from '@/utils/request'
import { GET_CASHCOUPON,GET_COUPON,GET_EXCHANGE_COUPON,METHOD} from  '@/api/api.config'
//获取代金券列表接口（接口删除）
export function apiCachCouponlist(data) {
    return request({
        url:  GET_CASHCOUPON,
        method: METHOD.GET,
        params: data,
    })
}
//获取卡券管理列表接口（//type 1:代金券，2折扣券）
export function apiCouponlist(data) {
    return request({
        url:  GET_COUPON,
        method: METHOD.GET,
        params: data,
    })
}
//获取兑换代金券/折扣券列表接口
export function apiExchangeCouponlist(data) {
    return request({
        url:  GET_EXCHANGE_COUPON,
        method: METHOD.POST,
         data,
    })
}