import enums from "@/utils/enums"
export function formatter(enumType, val) {
    if (enums.hasOwnProperty(enumType)) {
        var obj = enums[enumType]
        if (obj.hasOwnProperty(val)) {
            return obj[val]
        } else {
            return val
        }
    } else {
        return val
    }

}
