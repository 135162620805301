/**
 * @description 登录、获取用户信息、退出登录、清除token逻辑，不建议修改
 */
import Vue from 'vue'
import { tokenName } from '@/config'
import { individualRule, rule } from '@/router/router.js'
import { resBalanceObj, resLogoInfo, resUserInfoObj } from '@/za/model/resModel'
import { resetRouter } from '@/router'
import { getToken, removeToken, setToken } from '@/utils/token'

import { apiMsgList } from '@/api/msgCenter/msgIndex'
import { apiGetBalance } from '@/api/moneyCenter/expenseManagement/topUp'
import { apiUserInfo, apiUserLogout } from '@/api/apiUser'
import { apiGetPopularizeInfo, apiIsCloudPushOfficer } from '@/api/management/overview'

const state = () => ({
  token: getToken(),
  username: '',
  uuid: '',
  userInfo: Object.assign({}, resUserInfoObj),
  logoInfo: Object.assign({}, resLogoInfo),
  balance: Object.assign({}, resBalanceObj),
  MsgList: [],
  isRegister: false,
  popularizeInfo: {},
})
const getters = {
  balance: (state) => state.balance,
  token: (state) => state.token,
  username: (state) => state.username,
  uuid: (state) => state.uuid,
  userInfo: (state) => state.userInfo,
  logoInfo: (state) => state.logoInfo,
  MsgList: (state) => state.MsgList,
  isRegister: (state) => state.isRegister,
  popularizeInfo: (state) => state.popularizeInfo,
}

const mutations = {
  /**
   * @description 设置token
   * @param {*} state
   * @param {*} token
   */
  setToken(state, token) {
    state.token = token
    setToken(token)
  },
  /**
   * @description 设置用户账号
   * @param {*} state
   * @param {*} username
   */
  setAccount(state, username) {
    state.username = username
  },
  /**
   * @description 设置uuid
   * @param {*} state
   * @param {*} uuid
   */
  setUuid(state, uuid) {
    state.uuid = uuid
  },
  setBalance(state, BalanceInfo) {
    state.balance = Vue.prototype.$deepClone(BalanceInfo)
  },
  setMsg(state, MsgList) {
    state.MsgList = Vue.prototype.$deepClone(MsgList)
  },
  /**
   * @description 设置userInfo
   * @param {*} state
   * @param {*} userInfo
   */
  setUserInfo(state, userInfo) {
    const data = Vue.prototype.$deepClone(userInfo)
    delete data.rules
    state.userInfo = data
  },
  /**
   * @description 设置logoInfo
   * @param {*} state
   * @param {*} logoInfo
   */
  setLogoInfo(state, logoInfo) {
    state.logoInfo = Vue.prototype.$deepClone(logoInfo)
  },
  /**
   * @description 设置isRegister 是否为智安云推官
   * @param {*} state
   * @param {*} isRegister
   */
  setRegister(state, isRegister) {
    state.isRegister = isRegister
  },
  /**
   * @description 设置isRegister 是否为智安云推官
   * @param {*} state
   * @param {*} isRegister
   */
  setPopularizeInfo(state, popularizeInfo) {
    state.popularizeInfo = Vue.prototype.$deepClone(popularizeInfo)
  },
}
const actions = {
  /**
   * @description 登录
   * @param {*} { commit }
   * @param {*} userInfo
   */
  async setLoginData({ commit }, userInfo) {
    const token = userInfo[tokenName]
    if (token) {
      commit('setToken', token)
    }
    commit('setAccount', userInfo.username)
    commit('setUuid', userInfo.uuid)
  },
  /**
   * @description 获取用户余额接口
   * @param {*} { commit }
   * @param {*} userInfo
   */
  async getBalanceData({ commit }) {
    const balance = await apiGetBalance().catch()
    commit('setBalance', balance.data)
    return balance.data
  },
  /**
   * @description 获取消息列表接口
   * @param {*} { commit }
   * @param {*} userInfo
   */
  async getMsgData({ commit }) {
    let pageObj = {
      readAt: '',
      page: 1,
      pageSize: 10,
      msgId: '',
      keyword: '',
    }
    const msgList = await apiMsgList(pageObj).catch()
    commit('setMsg', msgList.data.messageList)
    return msgList.data.messageList
  },
  /**
   * @description 获取用户信息接口
   * @param {*} { commit, dispatch, state }
   * res:isRegister
   * popularizeRes：state:1正常 2暂停 3失效
   * @returns
   */
  async getUserInfo({ commit }) {
    // apiGetCertification()
    //   获取云推官信息（判断是否开启推广返利入口）
    const res = await apiIsCloudPushOfficer().catch()
    let allRule = JSON.parse(JSON.stringify(rule))
    if (res.data.isRegister) {
      // 获取云推官信息（若云推官推广权限为 关闭状态 则禁止访问返佣中心）
      const popularizeRes = await apiGetPopularizeInfo().catch()
      commit('setPopularizeInfo', popularizeRes.data)
      if (popularizeRes.data.state !== 3) {
        individualRule.forEach((item) => {
          allRule.push(item)
        })
      }
      // } else {
      //   companyRule.forEach((item) => {
      //     allRule.push(item)
      //   })
    }
    const userInfoRes = await apiUserInfo().catch()
    userInfoRes.data.rules = allRule
    commit('setUserInfo', userInfoRes.data)
    commit('setRegister', res.data.isRegister)
    return userInfoRes.data
  },
  /**
   * @description 退出登录
   * @param {*} { dispatch }
   */
  async logout({ dispatch }) {
    await apiUserLogout()
    await dispatch('resetAll')
  },
  /**
   * @description 重置token、roles、permission、router、tabsBar等
   * @param {*} { commit, dispatch }
   */
  async resetAll({ commit, dispatch }) {
    commit('routes/setRoutes', [], { root: true })
    commit('user/setMsg', [], { root: true })
    commit('setAccount', '')
    commit('setUuid', '')
    commit('setBalance', Object.assign({}, resBalanceObj))
    commit('setUserInfo', Object.assign({}, resUserInfoObj))
    // commit('setLogoInfo', Object.assign({}, resLogoInfo))
    await dispatch('setToken', '')
    await resetRouter()
    removeToken()
  },
  /**
   * @description 设置token
   * @param {*} { commit }
   * @param {*} token
   */
  setToken({ commit }, token) {
    commit('setToken', token)
  },
  /**
   * @description 设置头像
   * @param {*} { commit }
   * @param {*} avatar
   */
  setAvatar({ commit }, avatar) {
    commit('setAvatar', avatar)
  },
  /**
   * @description 获取logo信息接口
   * @param {*} { commit, dispatch, state }
   * @returns
   */
  // async getLogoInfo({commit}) {
  //     const res = await apiLogoInfo().catch()
  //     let domain
  //     if (process.env.NODE_ENV === 'development') domain = 'https://dev.dengbao.cloud'
  //     else domain = window.location.origin
  //     const logoInfoRes = {
  //         logo: res.data.logo ? domain + res.data.logo : '',
  //         name: res.data.name
  //     }
  //     commit('setLogoInfo', logoInfoRes)
  //     // 设置浏览器ico
  //     setLogoInfo(logoInfoRes.logo)
  //     return logoInfoRes
  // },
}
export default { state, getters, mutations, actions }
