<template>
  <div class="product-overview">
    <h1 class="title">{{ title }}</h1>
    <p class="desc">{{ desc }}</p>
    <main>
      <div v-for="(item,index) in list" :key="index">
        <img :src="item.icon" alt=""/>
        <h2>{{ item.title }}</h2>
        <p>{{ item.desc }}</p>
      </div>
    </main>
    <el-button v-if="active" size="medium" type="primary" @click="$emit('click')">{{
        translateTitle('btn.goUse')
      }}
    </el-button>
    <slot/>
  </div>
</template>

<script>

import {translateTitle} from "@/utils/i18n";

export default {
  name: "zaProductOverview",
  props: {
    active: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'title -> 标题'
    },
    desc: {
      type: String,
      default: 'desc -> 描述'
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    translateTitle,
  },
}
</script>

<style lang="scss" scoped>
.product-overview {
  width: 100%;
  height: 100%;
  padding: 80px 160px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 36px;
    color: #FFFFFF;
    margin: 0;
  }

  .desc {
    margin: 15px 0 50px;
    font-size: 18px;
    color: #AAD9F9;
    text-align: center;
  }

  main {
    width: 100%;
    margin-bottom: 75px;
    display: flex;
    justify-content: space-between;

    div {
      width: 380px;
      height: 380px;
      min-width: 380px;
      background: #24354A;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 50px 35px 35px 35px;
      box-sizing: border-box;

      &:nth-child(2) {
        margin: 0 20px;
      }

      img {
        width: 158px;
        //height: 136px;
      }

      h2 {
        margin: 28px 0 12px;
        font-size: 24px;
        color: #fff;
      }

      p {
        margin: 0;
        font-size: 14px;
        color: #AAD9F9;
        text-align: center;
      }
    }
  }
}
</style>
