var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bread" },
    [
      _vm.show
        ? _c("za-bg-frame", [
            _c(
              "div",
              { staticClass: "container" },
              [
                _c(
                  "el-breadcrumb",
                  {
                    staticStyle: { "margin-left": "5px" },
                    attrs: { separator: "/" },
                  },
                  _vm._l(_vm.breadcrumbList, function (item, index) {
                    return _c("el-breadcrumb-item", { key: index }, [
                      _vm._v(" " + _vm._s(item.meta.title) + " "),
                    ])
                  }),
                  1
                ),
                _c("div", { staticClass: "cur-item" }, [
                  _vm._v(_vm._s(_vm.breadcrumbList.slice(-1)[0].meta.title)),
                ]),
              ],
              1
            ),
          ])
        : _c("za-bg-frame", [
            _c("div", { staticClass: "second-container" }, [
              _c("div", { staticClass: "left" }, [
                _c("div", { staticClass: "cur-item" }, [
                  _vm._v(
                    _vm._s(
                      _vm.breadcrumbList.length > 1
                        ? _vm.breadcrumbList.slice(-1)[0].meta.title + _vm.ip
                        : ""
                    ) + " "
                  ),
                ]),
                _c("div", { staticClass: "line" }),
              ]),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }