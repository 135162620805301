/**
 * @description 路由守卫
 */
import router from '@/router'
import store from '@/store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
// import getPageTitle from '@/utils/pageTitle'
import { toLoginRoute } from '@/utils/routes'
import { routesWhiteList } from '@/config'
import { getLocation } from '@/utils'
import { apiGetCertification } from '@/api/accountCenter/nameCertification'

NProgress.configure({
  easing: 'ease',
  speed: 500,
  trickleSpeed: 200,
  showSpinner: false,
})
var state = getLocation('isLogin')
router.beforeEach(async (to, from, next) => {
  // 每次页面跳转回到顶部
  // document.documentElement.scrollTop = 0
  let urlString = getLocation('redirectPage')
  let hasToken = store.getters['user/token']
  // console.log(urlString, '-<<<<urlString')
  // console.log(hasToken, '<<<hasToken')
  // console.log(state, '<<<state')
  if (urlString) {
    if (hasToken && !state) {
      window.open(`${process.env.VUE_APP_PORTAL_TARGET_URL}` + urlString, '_self')
    } else if (hasToken && state) {
      if (routesWhiteList.includes(to.path)) {
        next()
      } else next(toLoginRoute(to.path))
    } else {
      if (routesWhiteList.includes(to.path)) {
        next()
      } else next(toLoginRoute(to.path))
    }
    state = null
  } else {
    NProgress.start()
    if (hasToken) {
      apiGetCertification()
      if (store.getters['routes/routes'].length) {
        // 禁止已登录用户返回登录页
        if (to.path === '/login') {
          next({ path: '/' })
          NProgress.done()
        } else next()
      } else {
        try {
          const { rules } = await store.dispatch('user/getUserInfo')
          // 根据路由模式获取路由并根据权限过滤
          await store.dispatch('routes/setRoutes', rules)
          //获取余额信息
          await store.dispatch('user/getBalanceData')
          //获取消息列表
          await store.dispatch('user/getMsgData')
          // 获取logo信息
          // if (!store.getters['user/logoInfo'].logo) await store.dispatch('user/getLogoInfo')
          next({ ...to, replace: true })
        } catch (err) {
          await store.dispatch('user/resetAll')
          next(toLoginRoute(to.path))
        }
      }
    } else {
      if (routesWhiteList.includes(to.path)) {
        // await store.dispatch('routes/setRoutes', rule)
        next()
      } else next(toLoginRoute(to.path))
    }
  }
})

router.afterEach((to, from) => {
  // document.title = getPageTitle(to.meta.title)
  if (store.getters['user/logoInfo']['name']) document.title = store.getters['user/logoInfo']['name']
  else document.title = '控制台'
  if (NProgress.status) NProgress.done()
})
