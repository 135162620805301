var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "za-loading",
          rawName: "v-za-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "reminder",
    },
    [
      _c("div", { staticClass: "title" }, [_vm._v("推广客户")]),
      _c(
        "div",
        [
          _c("za-picker", {
            attrs: { timeList: _vm.timeList },
            on: { changeTime: _vm.changeTime },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "card_bottom" },
        _vm._l(_vm.clickArr, function (item, index) {
          return _c("div", { key: index, staticClass: "cardUl" }, [
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.label))]),
            _c("div", { staticClass: "operation" }, [
              _c("span", [_vm._v(_vm._s(item.value))]),
              _c("span", [_vm._v("个")]),
            ]),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }