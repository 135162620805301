<template>
  <div>
    <div>
      <navbar />
    </div>
    <div class="priceDesc-center_bg">
      <div>
        <za-go-back title="报价详情" @click="goBack"></za-go-back>
      </div>
      <div class="top">
        <div>
          <za-tabs :tabArray="titleList" :handleActive="titleList[0].key" :showBorder="false"></za-tabs>
        </div>
        <occ-table :tableData="tableData" :columObj="columObj" :style-height="'220'"></occ-table>

        <div class="bottom">
          <div>
            <i>原价：</i>
            <i class="huaxian">￥{{ originalPrice }}</i>
          </div>
          <div>
            <span>活动价：</span>
            <span>￥{{ price }}</span>
          </div>
          <div>
            <i class="red-font" style="margin-right: 10px">*</i>
            <span>当前价格仅供参考,请以实际报价单为准</span>
          </div>
          <!--          <div>-->
          <!--            <el-button @click="goBackActive">返回活动页面</el-button>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Navbar from '@/za/layout/components/Navbar'
  import zaTabs from '@/za/components/zaTabs'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      Navbar,
      zaTabs,
    },
    data() {
      return {
        titleList: [
          {
            name: '套餐选择',
            key: '套餐选择',
          },
        ],
        tableData: [],
        price: 0,
        originalPrice: 0,
        columObj: {
          // 控制选择框
          selection: false,
          isOp: false,
          width: '300px',
          columnData: [
            {
              text: true,
              prop: 'serviceVersion',
              label: '服务版本',
              width: '',
              flag: true,
              align: 'center',
            },
            {
              text: true,
              prop: 'serviceType',
              label: '服务类型',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              text: true,
              prop: 'evaluatingLevel',
              label: '测评级别',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              ownInnerHtml: true,
              prop: 'EvaluatingArea',
              label: '测评区域',
              flag: true,
              width: '620',
              align: 'center',
              htmlTest: (row, index) => {
                return `<div>
                                            <div>${row.EvaluatingArea}类区域：</div>
                                            <div>${row.area}</div>
                                        </div>`
              },
            },
            {
              ownDefined: true,
              prop: 'systemNum',
              label: '系统数量',
              flag: true,
              width: '',
              align: 'center',
              ownDefinedReturn: (row) => {
                return 'x' + row.systemNum
              },
            },
            {
              text: true,
              prop: 'number',
              label: '系统部署的服务器台数',
              flag: true,
              width: '180',
              align: 'center',
            },
            {
              text: true,
              prop: 'tapeWidth',
              label: '系统带宽',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              text: true,
              prop: 'priceType',
              label: '计费模式',
              flag: true,
              width: '',
              align: 'center',
            },
          ],
        },
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.tableData = []
        this.tableData.push({
          serviceVersion: this.$route.query.serviceVersion,
          serviceType: this.$route.query.serviceType,
          evaluatingLevel: this.$route.query.evaluatingLevel,
          EvaluatingArea: this.$route.query.EvaluatingArea,
          systemNum: this.$route.query.systemNum,
          number: this.$route.query.number,
          tapeWidth: this.$route.query.tapeWidth,
          area: this.$route.query.area,
          priceType: '一次性计费',
        })
        this.originalPrice = this.$route.query.originalPrice
        this.price = this.$route.query.price
      },
      goBack() {
        this.$router.push('/evaluationActivity')
      },
      goBackActive() {
        window.open(`${process.env.VUE_APP_PORTAL_TARGET_URL}/cp-activity`, '_self')
      },
    },
    computed: {
      ...mapGetters({
        token: 'user/token',
      }),
    },
  }
</script>
<style lang="scss" scoped>
  .priceDesc-center_bg {
    width: 1700px;
    margin: 20px auto 0px;

    .top {
      width: 1700px;
      min-width: 1700px;
      padding: 20px;
      background: #ffffff;
      margin-bottom: 20px;
    }

    .bottom {
      text-align: end;
      font-size: 14px;
      color: #999999;
      margin-top: 20px;

      .huaxian {
        text-decoration: line-through;
      }

      div {
        margin-bottom: 20px;
      }

      div:nth-child(2) {
        font-size: 20px;
        color: #000000;

        span:last-child {
          color: red;
        }
      }
    }
  }
</style>