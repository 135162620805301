var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customerTab" },
    [
      _c(
        "za-query-form",
        [
          _c(
            "za-query-form-left-panel",
            { attrs: { span: 18 } },
            [
              _c(
                "el-form",
                {
                  attrs: { inline: true, model: _vm.pageObj },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.pageObj.tradeAccount,
                            callback: function ($$v) {
                              _vm.$set(_vm.pageObj, "tradeAccount", $$v)
                            },
                            expression: "pageObj.tradeAccount",
                          },
                        },
                        _vm._l(_vm.typeOptions, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-left": "15px !important" },
                      attrs: { label: "注册时间" },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        on: { change: _vm.datePickEnd },
                        model: {
                          value: _vm.pickTime,
                          callback: function ($$v) {
                            _vm.pickTime = $$v
                          },
                          expression: "pickTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "关联类型", "label-width": "100px" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.pageObj.payType,
                            callback: function ($$v) {
                              _vm.$set(_vm.pageObj, "payType", $$v)
                            },
                            expression: "pageObj.payType",
                          },
                        },
                        _vm._l(_vm.typeOptions, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "za-query-form-left-panel",
            { attrs: { span: 6 } },
            [
              _c(
                "el-form",
                {
                  attrs: { inline: true, model: _vm.pageObj },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "100px" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "输入用户名或ID查找" },
                        model: {
                          value: _vm.pageObj.tradeNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.pageObj, "tradeNo", $$v)
                          },
                          expression: "pageObj.tradeNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "za-button",
                        {
                          attrs: { icon: _vm.buttonIconObj.SEARCH },
                          on: { click: _vm.handleQuery },
                        },
                        [_vm._v(_vm._s(_vm.translateTitle("btn.query")) + " ")]
                      ),
                      _c(
                        "za-button",
                        {
                          attrs: { type: _vm.buttonTypeObj.GREY },
                          on: { click: _vm.handleResetQuery },
                        },
                        [_vm._v(_vm._s(_vm.translateTitle("btn.reset")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("occ-table", {
            attrs: {
              tableData: _vm.tableData,
              columObj: _vm.columObj,
              isOp: _vm.columObj.isOp,
            },
          }),
          _c("pagination", {
            attrs: {
              limit: _vm.pageObj.pageSize,
              page: _vm.pageObj.pageNo,
              total: _vm.total,
            },
            on: {
              "update:limit": function ($event) {
                return _vm.$set(_vm.pageObj, "pageSize", $event)
              },
              "update:page": function ($event) {
                return _vm.$set(_vm.pageObj, "pageNo", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }