<template>
    <div class="reminder" v-za-loading="isLoading">
        <div class="title">代办提醒</div>
        <div class="card_mottom">
            <el-row>
                <el-col :span="8">
                    <el-card shadow="always" class="cardUl"
                             @click.native="$router.push('/moneyCenter/orderManagement/myOrder')">
                        <div class="title">待支付订单</div>
                        <div class="operation">
                            <div>
                                <span>{{waitPayOrderNumber}}</span>
                                <span>个</span>
                            </div>
                        </div>

                    </el-card>
                </el-col>
                <!-- <el-col :span="8">
                    <el-card shadow="always" class="cardUl"
                             @click.native="$router.push('/moneyCenter/orderManagement/renewal')">
                        <div class="title">待续费项</div>
                        <div class="operation">
                            <div>
                                <span>{{waitRenewResourceNumber}}</span>
                                <span>个</span>
                            </div>
                        </div>
                    </el-card>
                </el-col> -->
                <el-col :span="8">
                    <el-card shadow="always" class="cardUl"
                             @click.native="$router.push('/moneyCenter/invoiceManagement/askfor')">
                        <div class="title">待开票金额</div>
                        <div class="operation">
                            <div>
                                <span>{{amount}}</span>
                                <span>元</span>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>

        </div>
    </div>
</template>
<script>
    import {apiSetNumber, apiWaitResource} from '@/api/moneyCenter/overView'
    import {apiFinanceAmount} from "@/api/moneyCenter/invoiceManagement/askfor"

    export default {
        data() {
            return {
                waitPayOrderNumber: null,
                waitRenewResourceNumber: null,
                amount: null
            }
        },
        created() {
            this.getList()
        },
        methods: {
            getList() {
                this.onSubmitData(apiSetNumber, null, null, false, false, (res) => {
                    this.waitPayOrderNumber = res.data.number
                })
                this.onSubmitData(apiWaitResource, null, null, false, false, (res) => {
                    this.waitRenewResourceNumber = res.data.number
                })
                this.onSubmitData(apiFinanceAmount, null, null, false, false, (res) => {
                    this.amount = res.data.amount
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '~@/za/styles/mixin.scss';

    .reminder {
        height: 160px;
        width: 50%;
        padding: 20px;
        background: #FFFFFF;
        border-radius: 2px;

        .title {
            @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
            width: 100%;
            height: 20px;
            margin-bottom: 14px;
        }

        .card_mottom {
            .cardUl {
                height: 90px;
                margin-right: 20px;
                padding-left: 15px;
                 cursor: pointer;
                .title {
                    font-size: 12px;
                    width: 100%;
                }

                .operation {
                    display: flex;
                    justify-content: space-between;

                    div:first-child {
                        span:first-child {
                            font-size: 24px;
                            font-family: DINAlternate-Bold, DINAlternate;
                            font-weight: bold;
                            color: #000000;
                        }
                    }
                }

            }
        }
    }

    ::v-deep {
        .el-card__body {
            padding: 10px;
        }
    }
</style>