<template>
    <div>
        <Verify
                @success="success"
                :mode="'pop'"
                :captchaType="'clickWord'"
                :imgSize="{ width: '330px', height: '155px' }"
                ref="verify"
                @clickFalse="clickFalse"
        ></Verify>
    </div>
</template>
<script>
    // 引入组件
    // import Cookie from '@/plugins/cookies';
    import Verify from './components/Verify';
    // import {captchaCheck} from './verifition/api/index2';
    export default {
        name: 'VerifyCom',
        components: {
            Verify
        },
        methods: {
            clickFalse(val){
              this.$emit('clickBool',val)
            },
            success(params) {
                // 已经滑动图片到指定位置
                let _this = this;
                // console.log(' this.captchaVerification = params.captchaVerification', params);
                let data={'captchaVerification': params.captchaVerification}
                    // console.log(data,'<<<最后穿的值')
                // this.$axios.post({
                //     url: captchaUrl + '/ant/captchaCheck?token=' + params.token,
                //     isNoUrl: true,
                //     data: {'captchaVerification': params.captchaVerification}
                // }).then(res => {
                //     console.log('是否成功', res);
                //     if (res.data.code === 0 || res.data.code === 200) {
                //         _this.$refs.verify.hide();
                //         location.reload();
                //     } else {
                //         _this.$refs.verify.show();
                //     }
                // });
                // captchaCheck({
                //     captchaVerification: params.captchaVerification
                // }).then(res => {
                //     console.log('是否成功', res);
                //     if (res.code === 0 || res.code === 200) {
                //         _this.$refs.verify.hide();
                //         location.reload();
                //     } else if (res.code === 401) {
                //         // token失效
                //         Cookie.delete('token');
                //         Cookie.set('token', '');
                //         location.reload();
                //     } else {
                //         _this.$refs.verify.show();
                //     }
                // });
            },
            useVerify() {
                this.$refs.verify.show();
            }
        }
    };
</script>
