<template>
    <div class="announcement">
        <div class="title">最新公告</div>
        <div class="conter">
            <ul>
                <li v-for="(item,index) in msgList" :key="index" @click="handleClick(item)">
                    <span :href="item.href" target="_blank">{{item.name.slice(0,17)}}
                     <i v-if="item.name.length>17">...</i>
                    </span>
                    <span>{{item.time}}</span>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                msgList: [
                    {name: '最新公告最新公告最新公告最新公告最新公告', time: '2022/2/12', href: 'https://www.baidu.com/'},
                    {name: '最新公告最新公告最新公告最新公告最新公告', time: '2022/2/12'},
                    {name: '最新公告最新公告最新公告最新公告最新公告', time: '2022/2/12'},
                    {name: '最新公告最新公告最新公告最新公告最新公告', time: '2022/2/12'},
                    {name: '最新公告最新公告最新公告最新公告最新公告', time: '2022/2/12'}
                ]
            }
        },
        methods: {
            handleClick(val) {
                // var data = new Blob([data.content], {type: "text/html"});
                // var downloadUrl = window.URL.createObjectURL(data);
                // var anchor = document.createElement("a");
                // anchor.href = downloadUrl;
                // anchor.target='_blank'
                // anchor.click()
            }
        }
    }
</script>
<style lang="scss" scoped>
    .announcement {
        width: 360px;
        height: 210px;
        background: #FFFFFF;
        border-radius: 2px;
        margin-bottom: 16px;
        padding: 20px;

        .title {
            width: 100%;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 20px;
            margin-bottom: 10px;
        }

        .conter {
            ul {
                padding: 0;
                margin: 0;
                list-style-type: none;

                li {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #000000;
                    position: relative;
                    margin-bottom: 12px;
                    cursor: pointer;

                    span:last-child {
                        position: absolute;
                        right: 0;
                    }
                }

                li:hover {
                    color: #3370FF;
                }
            }
        }
    }
</style>