var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "askFor-center_bg" }, [
    _c("div", { staticClass: "askFor-top" }, [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
      _c("div", [_c("invoice-header")], 1),
    ]),
    _c("div", [_c("history")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }