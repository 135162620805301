<template>
    <div class="loginLog-container" v-za-loading="isLoading">
        <div class="title">登录日志</div>
        <occ-table :tableData="tableData" :columObj="columObj"
                 :isOp="columObj.isOp">
        </occ-table>
        <pagination
                :limit.sync="pageObj.pageSize"
                :page.sync="pageObj.page"
                :total="total"
                @pagination="getList"
        />
    </div>
</template>
<script>
    import {apilogInfo} from '@/api/accountCenter/loginLog'

    export default {
        name: 'LoginLog',
        data() {
            return {
                pageObj: {
                    page: 1,
                    pageSize: 10,
                },
                total: 0,
                tableData: [],
                columObj: {
                    // 控制选择框
                    selection: false,
                    isOp: false,
                    width: '300px',
                    columnData: [
                        {
                            text: true,
                            prop: "index",
                            label: "序号",
                            width: "",
                            flag: true,
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "loginAt",
                            label: "登录时间",
                            flag: true,
                            width: "",
                            align: "center",

                        },
                        {
                            text: true,
                            prop: "name",
                            label: "登录账号",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "ip",
                            label: "IP地址",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            status: true,
                            prop: "state",
                            label: "登录状态",
                            flag: true,
                            width: "",
                            align: "center",
                            disabledText:'失败',
                            enabledText:'成功',
                        },
                        {
                            text: true,
                            prop: "loginPlace",
                            label: "IP所属区域（仅供参考）",
                            flag: true,
                            width: "",
                            align: "center",
                        },

                    ],
                },
            }
        },
        created() {
            this.getList()
        },
        methods: {
            getList() {
                this.onSubmitData(apilogInfo, this.pageObj, null, false, false, (res) => {
                    this.total = res.data.total;
                    let changeData=res.data.logList
                    changeData.forEach((item,index)=>{
                        item.index=(this.pageObj.page-1)*(this.pageObj.pageSize)+index+1
                    })
                    this.tableData=changeData

                })
            },

        }
    }
</script>
<style scoped lang="scss">
    @import '~@/za/styles/mixin.scss';

    .title {
        @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
        width: 100%;
        height: 20px;
        margin-bottom: 20px;
    }
</style>