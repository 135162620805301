var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showBorder
        ? _c("div", {
            staticClass: "ab_border",
            style: [{ top: _vm.top + "px" }],
          })
        : _vm._e(),
      _c(
        "el-tabs",
        {
          attrs: { value: _vm.handleActive },
          on: { "tab-click": _vm.handleClick },
        },
        _vm._l(_vm.tabArray, function (item, index) {
          return _c("el-tab-pane", {
            key: index,
            attrs: {
              label: item.name + (item.value ? "(" + item.value + ")" : ""),
              name: item.key,
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }