var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.generalFlag
        ? _c("div", { staticClass: "prompt" }, [
            _c(
              "div",
              [
                _c("za-svg-icon", {
                  attrs: {
                    "icon-class": "za-wanning",
                    "class-name": "za-waring",
                  },
                }),
              ],
              1
            ),
            _vm._m(0),
          ])
        : _vm._e(),
      _vm.cloudFlag
        ? _c("div", { staticClass: "prompt" }, [
            _c(
              "div",
              [
                _c("za-svg-icon", {
                  attrs: {
                    "icon-class": "za-wanning",
                    "class-name": "za-waring",
                  },
                }),
              ],
              1
            ),
            _c("div", [
              _c("span", [_vm._v("温馨提示:您的账号已签约成为")]),
              _c(
                "span",
                { staticClass: "blue_font", on: { click: _vm.amendCloud } },
                [_vm._v("智安云推官")]
              ),
              _c("span", [
                _vm._v(
                  "，变更实名信息将取消云推官的所有相关权益，账户未清算资金将被冻结，请勿随意变更。"
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.url
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "za-loading",
                  rawName: "v-za-loading",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
            },
            [
              _c("iframe", {
                staticClass: "iframe",
                attrs: {
                  src: _vm.url,
                  width: "100%",
                  frameborder: "0",
                  id: "iframe",
                },
              }),
            ]
          )
        : _vm._e(),
      _c(
        "za-dialog",
        {
          attrs: {
            "top-title": "申请账号主体变更协议",
            visible: _vm.promiseDialog,
            width: "1000px",
            showClose: false,
            footState: true,
          },
        },
        [
          _c("Promise"),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.promiseFlag,
                    callback: function ($$v) {
                      _vm.promiseFlag = $$v
                    },
                    expression: "promiseFlag",
                  },
                },
                [_vm._v("我已阅读并接受《申请账号主体的变更协议》")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: _vm.buttonTypeObj.BLUE,
                    disabled: !_vm.promiseFlag,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.dialogToPay()
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", [
        _vm._v(
          " 温馨提示：您已经成功通过个人认证（或企业认证），请不要随意变更实名认证信息，若您需要变更，请仔细阅读《 "
        ),
      ]),
      _c(
        "span",
        {
          staticClass: "blue_font",
          staticStyle: { cursor: "auto", margin: "0" },
        },
        [_vm._v("申请账号主体的变更协议")]
      ),
      _c("span", [_vm._v("》，您提交认证变更视为已知晓并同意该协议。")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }