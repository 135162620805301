<template>
  <div class="moneySurplus" v-za-loading="isLoading">
    <div class="container">
      <!--      <div class="title">现金余额</div>-->
      <div class="title">
        <span>账户余额</span>
        <el-tooltip effect="light" placement="right-start">
          <div slot="content">
            <div class="orange_font">账户余额:指当前账号内的总余额</div>
            <div class="grey_font">账户余额 = 可用余额 + 返佣余额</div>
          </div>
          <i class="el-icon-info blue_font"></i>
        </el-tooltip>
      </div>
      <div class="main">
        <div>
          <span>{{ addDecimalNumbers(balance.cashBalance, withdrawFee) }}</span>
          <span>元</span>
        </div>
        <!--                <div>-->
        <!--                    <el-button type="primary" @click="$router.push('/moneyCenter/expenseManagement/topUp')">充值</el-button>-->
        <!--                    <el-button  plain @click="$router.push('/moneyCenter/expenseManagement/moneyExtract')">提现</el-button>-->
        <!--                </div>-->
      </div>
      <div class="btn" @click="$router.push('/moneyCenter/expenseManagement/details')">交易流水</div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { apiGetPopularBalance } from '@/api/management/overview'

  export default {
    data() {
      return {
        withdrawFee: 0, //返佣余额
      }
    },
    created() {
      this.getList()
    },
    methods: {
      async getList() {
        await this.onSubmitData(apiGetPopularBalance, null, null, false, false, (res) => {
          this.withdrawFee = res.data.withdrawFee
        })
      },
    },
    computed: {
      ...mapGetters({
        balance: 'user/balance',
      }),
    },
  }
</script>
<style lang="scss" scoped>
  @import '~@/za/styles/mixin.scss';

  .moneySurplus {
    height: 143px;
    width: 33%;
    padding: 20px;
    background: #ffffff;

    .container {
      //border-right: 1px solid #f5f5f5;
    }

    .title {
      @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
      width: 100%;
      height: 20px;
      margin-bottom: 14px;
    }

    .main {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      div:first-child {
        span:first-child {
          font-size: 30px;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #000000;
        }
      }

      div:nth-of-type(2) {
        margin-right: 20px;
      }
    }

    .btn {
      font-size: 14px;
      color: #3370ff;
      cursor: pointer;
    }
  }
</style>