var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c("div", { staticClass: "heardBlock" }, [
        _c(
          "div",
          { staticClass: "ignore_center", on: { click: _vm.officialWeb } },
          [
            _c("el-image", {
              staticClass: "pic-error-parent",
              attrs: { src: require("@/assets/home/home_logo.png") },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "title" }, [_vm._v("智安云用户服务协议")]),
      ]),
    ]),
    _c("div", { staticClass: "main" }, [
      _c(
        "span",
        {
          staticClass: "blue_font",
          on: {
            click: function ($event) {
              return _vm.$router.push("/login")
            },
          },
        },
        [_vm._v("返回")]
      ),
      _vm._m(0),
      _c("h5", [_vm._v("声明")]),
      _c("span", { staticClass: "first" }, [
        _vm._v(
          " 本服务协议是智安云网站（zhiancloud.com，以下简称为“本网站”）的经营者深圳市智安网络有限公司（以下简称为“本公司”或“智安云”），与用户（以下称为“您”）共同缔结的、对双方具有约束力的有效契约。智安云向您提供本网站上所展示的产品与服务（下称“智安云服务”、“本服务”），并将不断更新产品与服务范围及服务内容，最新的智安云服务以本网站相关产品及服务介绍页面展示的以及向您实际提供的为准。您续签或新订购某产品或服务后，应遵守当时的服务条款，智安云推出新的产品与服务后，将可能更新本协议中相关内容。 "
        ),
      ]),
      _c("h5", [_vm._v("总则")]),
      _c("span", [
        _vm._v(
          " 1. 您确认：您在使用本服务之前，已经充分阅读、理解并接受本服务协议的全部内容，请您务必审慎阅读、充分理解各条款的内容，特别是免除或者限制责任的条款、法律适用和争议解决条款，如您对协议有任何疑问，可以联系客服或相关业务部门进行咨询。 当您通过网络页面点击同意，即表示您已充分阅读、理解并接受本协议的全部内容，并与智安云达成一致。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 2. 您同意：智安云有权随时对本服务协议及相应的服务规则内容进行单方面的变更，并有权以消息推送、网页公告等方式予以公布，而无需另行单独通知您；在本服务协议内容公告变更后，若您不同意修改后的服务协议，您有权立即停止使用本服务；若您继续使用本服务的，表示您已充分阅读、理解并接受修改后的协议内容，也将遵循修改后的协议内容使用本服务。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 3. 本协议内容同时包括智安云可能持续增加发布的关于所提供服务的相关协议、业务规则、保密协议等内容。上述内容一经正式发布并以第3条所述向您公布，即为本协议不可分割的组成部分，您同样应当遵守。 "
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("关于智安云账号")]),
      _c("span", [_vm._v("1. 账号类型")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("（1）智安云账号分为个人账号和企业账号"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " （2）个人账号是通用账号，使用手机或邮箱注册。主要面向个人用户和开发者。在实名认证时选择个人认证，您可根据认证页面的内容详细了解。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " （3）企业账号是面向企业、个体工商户、事业单位、学校、社会团体等通过营业执照、组织机构代码证、法人身份证等相关资质进行认证。在实名认证时选择企业认证。相对个人账号有更高的安全等级保障并能享有更多产品选择权。您的智安商业产品账号可以访问并操作您开通的所有智安云商业产品，您需对用户名和密码的安全性负责，请勿向任何人透露您的用户名和密码。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "若您忘记了账户密码，可自行通过您设置的密保手机按照自动流程找回相关信息。"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 若您公司负责管理帐户的人员已离开公司， 或您在任何情况下知悉或怀疑您的用户名及密码为任何第三方所知悉，或有人未经授权而使用您的账号，请您主动向智安提出申请，并按照流程要求提交相关表单。为了确认您是相关帐户的所有人，我们会要求您提供一些详细信息，如最近的付款信息或客户ID等，您予以配合。在智安根据流程处理您的账户前，您须就任何第三方使用服务或服务被用作未经授权用途负责。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("2. 账号注册")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("（1）主体资格")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您确认：在您完成注册程序或以其他智安云允许的方式实际使用本服务时，您应当是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织（以下统称为“法律主体”） "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("（2）注册账号")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 在使用本服务前，您必须先注册，取得本公司提供给您的账号，您同意并保证：您确保您已充分阅读智安云网站的注册指引，并正确选择了账号类型。因账号类型选择不当引发的问题，智安云不承担任何责任。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您所填写的用户信息是真实、准确、完整、及时的，且保证智安云可以通过您所填写的联系方式与您取得联系。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您应根据智安云对于服务的要求及时提供相应的身份证明，企业资质等资料，否则智安云有权拒绝向您提供相关服务。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 若您提供的资料或信息包含有不正确、不真实的信息，智安云保留取消您账号注册资格、中止或终止为您提供服务的权利。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您应对您的智安云账号负责，只有您本人可以使用您的智安云账号，未经智安云同意并作账号实名变更，该账号不可转让、不可赠与、不可继承。否则智安云有收回该账号的权利。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 以代理人身份代理其他自然人或者单位进行注册，您必须向智安云提供代理人和被代理人的详细资料和书面授权，未提供上述资料和文件的，智安云将视注册者为会员。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您应当准确填写并及时更新您提供的电子邮件地址、联系电话、联系地址、邮政编码等联系方式，以便智安云或其他会员与您进行有效联系，因通过这些联系方式无法与您取得联系，导致您在使用智安云服务过程中产生任何损失或增加费用的，应由您完全独自承担。您了解并同意，您有义务保持您提供的联系方式的有效性，如有变更需要更新的，您应按智安云的要求进行操作。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("（3）账号责任")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 智安云通过您的用户名和密码识别您的指示，请您妥善保管您的用户名和密码，您必须对维护密码和账户的机密性承担全部责任，您需要对账户中发生的所有活动承担全部责任，对于因密码泄露或用户名遗忘所致的损失，由您自行承担。在任何时候，未经账户持有人的同意，您都不得使用其他人的账户。如智安云或第三方因其他人使用您的账户或因您使用其他人的账户而蒙受损失，您则应承担相应责任。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 若您发现有他人冒用或盗用您的账号及密码或任何其他未经合法授权的使用或任何其他不安全的行为，您应立即以有效方式通知智安云，要求智安云暂停相关服务。同时，您理解智安云对您的请求采取行动需要合理期限，在此之前，智安云对已执行的指令及所导致的您的损失不承担任何责任。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("3. 实名认证")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您了解并同意，为保证您的账号和交易安全，智安云有权要求您完成智安云账号的实名认证；同时，智安云可能会就某些产品或服务的开通，依法要求您提供更多的身份资料、企业实名认证办理人身份信息或企业资质等信息，做进一步的身份认证或资格验证，您的账号只有在通过这些认证和验证之后，方可获得使用相关产品或服务的资格。您理解并同意，智安云有权自行或委托第三方，审查您在实名认证时提供的信息是否真实、准确及有效；如您未依照相关法律法规及智安云的要求完成实名认证，您将无法开通并使用智安云服务。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("4. 关联账号")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 通常情况下，您的智安云账号是您在本网站进行一切活动的唯一身份识别依据，每一个智安云账号都可以在本网站独立开展活动。但在下列情形下，智安云有权根据自己的判断，对同一及/或关联法律主体拥有的多个智安云账号进行特别约定，包括但不限于： "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 1) 多个智安云账号之间存在主账号与子账号、授权账号与被授权账号等关联关系，且根据法律法规、本服务协议、智安云各产品协议或其他智安云规则的规定，各账号之间互相承担连带责任的； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 2) 多个智安云账号使用同一实名认证，且其中两个或两个以上智安云账号同时存在恶意欠费及/或违反法律法规、本服务协议、智安云各产品协议或其他智安云规则的行为的； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 3) 多个智安云账号对应于同一法律实体，且该法律实体已在先被识别为恶意注册账号以获取不正当利益者； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "4) 其他智安云有充足理由需要对多个智安云账号进行统一处理的情形。"
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("5. 账号注销")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您有权决定停止使用智安云服务并注销您的智安云账号。您可以根据《 "
        ),
        _c(
          "span",
          {
            staticClass: "blue_font",
            on: {
              click: function ($event) {
                return _vm.$router.push("/protocal/logout")
              },
            },
          },
          [_vm._v("智安云账号注销协议")]
        ),
        _vm._v(" 》及操作指引发起账号注销。 "),
      ]),
      _c("br"),
      _c("span", [_vm._v("6. 合法要求")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您理解并同意，智安云有权了解您使用本网站产品及服务的真实背景及目的，有权要求您如实提供真实、全面、准确的信息；如果智安云有合理理由怀疑您提供的信息不真实、您进行虚假交易，或您的行为违反智安云的服务规则的， 智安云有权暂时或永久限制您账号下所使用的所有产品及/或服务的部分或全部功能。 您理解并同意，智安云有权按照国家司法、行政、军事、安全等机关（包括但不限于公安机关、检察机关、法院、海关、税务机关、安全部门等）的要求对您的个人信息及在本网站的资金、交易及账号等进行查询、冻结或扣划。 "
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("用户的权利与义务")]),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 1. 您的经营许可要求 如果您利用智安云提供的服务进行经营或非经营的活动需要获得国家有关部门的许可或批准的，您应获得该有关的许可或批准。包括但不限于以下内容： "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 1) 如果您在云服务器服务上开办了多个网站，须保证所开办的全部网站均获得国家有关部门的许可或批准； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 2) 如您网站提供非经营性互联网信息服务的，必须办理非经营性网站备案，并保证所提交的所有备案信息真实有效，在备案信息变化时及时在备案系统中提交更新信息； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "3) 如您网站提供经营性互联网信息服务的，还应自行在当地通信管理部门取得经营性网站许可证;"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "4) 如您提供BBS等电子公告服务的，也需根据相关法规政策要求备案或获得相应批准;"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("5) 如您经营互联网游戏网站的，您应依法获得网络文化经营许可证；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "6) 如您经营互联网音、视频网站的，您应依法获得信息网络传播视听节目许可证；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 7) 如您从事新闻、出版、教育、医疗保健、药品和医疗器械等互联网信息服务，依照法律、行政法规以及国家有关规定须经有关主管部门审核同意，在申请经营许可或者履行备案手续前，应当依法经有关主管部门审核同意。您理解并认可，以上列举并不能穷尽您进行经营或非经营活动需要获得国家有关部门的许可或批准的全部类型，您应获得有关的许可或批准，并应符合国家及地方不时颁布相关法律法规之要求。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 2. 行为合法性保证 您保证您使用本服务时将遵从国家、地方法律法规、行业惯例和社会公共道德，不会利用本公司提供的服务进行存储、发布、传播如下信息和内容： "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("1) 违反国家法律法规政策的任何内容（信息）；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("2) 违反国家规定的政治宣传和/或新闻信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("3) 涉及国家秘密和/或安全的信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("4) 封建迷信和/或淫秽、色情、下流的信息或教唆犯罪的信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("5) 博彩有奖、赌博游戏；违反国家民族和宗教政策的信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("6) 妨碍互联网运行安全的信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "7) 侵害他人合法权益的信息和/或其他有损于社会秩序、治安、公共道德的信息或内容。"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您同时承诺不得为他人发布上述不符合国家规定和/或本服务协议约定的信息内容提供任何便利，包括但不限于设置URL、BANNER链接，发送邮件、短信等。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您同意智安云有权在您违反上述约定时终止向您提供服务并不予退还任何款项，因您的上述行为给智安云或智安云其他用户造成损失的，您应承担相关法律责任。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("3. 禁止有害信息散布")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您不应散布未经请求的大批量电子邮件广告、垃圾邮件（SPAM）：不利用智安云提供的服务散发大量未经请求的电子邮件、电子广告或包含反动、色情等有害信息的电子邮件。“有害信息”包括但不限于： "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 1） 使用通信服务发送调查、竞赛、传销信息、连锁信、垃圾邮件或者任何复制性或主动提供的消息（商业或其他目的）； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 2） 诽谤、辱骂、骚扰、恐吓、威胁其他人或以其他方式侵犯其他人的合法权利（如隐私权和公开权）； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 3） 发布、张贴、上载、分发或散布任何不恰当的、亵渎的、诽谤的、猥亵的、下流的或其他非法主题、名称、资料或信息； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 4） 上传或以其他方式提供包含受知识产权法（包括但不限于，版权法或商标法）、隐私权或公开权保护的图像、照片、软件或其他资料的文件，但您自己拥有或控制这些权利或者获得所有必要许可的情况除外； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 5） 在使用通过服务获取的任何资料或信息（包括图像、照片）时，以任何方式侵犯任何当事方的任何版权、商标、专利、商业秘密或其他专有权； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 6） 上载含病毒、木马、蠕虫、定时炸弹、清除器、损坏的文件或者任何类似的、可能损害其他人的计算机或资产的软件或程序； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 7） 出于任何商业目的进行广告宣传或者提出销售、购买任何商品或服务，但通信服务明确允许此类消息的情况除外； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 8） 下载另一名通信服务用户张贴的、您明确知道或者理应知道不得以这种方式进行非法复制、展示、执行和/或分发的任何文件； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 9） 伪造或删除任何版权管理信息，如作者归属、法律声明或其他相应的声明、专有标志、上载文件所含的软件或其他资料的来源或出处标签； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("10） 获取或以其他方式收集其他人的信息，包括电子邮件地址；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("11） 捏造假身份来误导其他人；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 12） 使用、下载或以其他方式复制、或者向其他人或实体（免费或收费）提供服务用户或其他用户的任何目录、使用信息或其部分信息。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("4. 通信服务资料合法性要求"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 智安云不承担监控通信服务的义务，但保留查看张贴到通信服务上的资料以及自行决定移除任何资料的权利。智安云保留出于合法原因随时终止您访问任何或所有通信服务的权利，并且不会另行通知。 在任何通信服务中提供任何有关您自己或受您监护的人的个人身份信息时，请始终谨慎行事。智安云不会控制或认可任何通信服务中出现的内容、消息或信息，对通信服务以及因您加入通信服务导致的任何行为不承担任何责任。上载到通信服务的资料可能受到有关使用、复制和/或散布的公开限制；如果您下载资料，应遵守这些限制。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("5. 资源使用合法性要求")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您不应大量占用，亦不得导致如程序或进程等大量占用智安云云计算资源（如云服务器、网络带宽、存储空间等）所组成的平台（以下简称“云平台”）中服务器内存、CPU或者网络带宽资源（如互联网挖矿等行为等），并给云平台或者平台其他用户的网络、服务器（包括但不限于本地及外地和国际的网络、服务器等）、产品/应用等带来严重的、不合理的负荷，影响智安云与国际互联网或其他特定网络、服务器正常通畅的联系，或者导致云平台产品与服务或者其他平台用户的服务器宕机、死机或者影响其他平台用户基于云平台的产品/应用不可访问等。一经发现，您的主机将被强制停机，您的智安云账号也会被立即冻结，智安云并保留追究您的法律责任的权利。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("6. 软件使用安全要求")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 未经智安云书面许可的，您不应修改、翻译、改编、出租、转许可、在信息网络上传播或转让智安云提供的服务或软件，也不得逆向工程、反编译或试图以其他方式发现智安云提供的服务或软件的源代码。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您不应进行任何破坏或试图破坏网络安全的行为（包括但不限于钓鱼，黑客，网络诈骗，网站或空间中含有或涉嫌散播：病毒、木马、恶意代码，及对其他网站、服务器进行涉嫌攻击行为如扫描、嗅探、ARP欺骗、DDoS等）。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您不应进行任何改变或试图改变智安云提供的系统配置或破坏系统安全的行为。在使用服务时，不得以任何方式损坏、禁用、过载或妨碍任何智安云服务器或连接到任何智安云服务器的网络，也不得干扰任何其他用户使用和享有任何服务。您不得尝试通过入侵、密码盗取或任何其他手段擅自使用任何服务、其他账户、计算机系统或连接到任何智安云服务器或任何服务的网络。您不得通过任何手段获取或尝试获取服务未明确提供的任何资料或信息。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("7. 用户行为限制性要求")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 除非您购买了专门用于此目的的服务，您不应利用本服务从事DDoS防护、DNS防护等防护售卖业务。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "您不应利用智安云的服务从事可不经网络审查为境内获取境外非法信息的活动。"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您不应在智安云服务或平台之上安装、使用盗版软件；您对自行安装软件和进行的操作承担相应的责任。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您应自行对因使用本服务而存储在智安服务器的各类数据等信息，在本服务之外，采取合理、安全的技术措施，确保其安全性和完整性，并对自己的行为（包括但不限于欠费，自行安装软件、采取加密措施或进行其他安全措施等）所引起的结果承担全部责任。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("8. 用户行为合法性要求")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您在使用本服务时须遵守本协议以及智安的相关要求，不得利用本服务从事包括但不限于以下行为，也不得为以下行为提供便利（包括但不限于为您的最终用户的行为提供便利等）： "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 1) 将本服务的云服务器用作虚拟服务器或用作磁盘空间进行出租，以及用作代理服务器（Proxy）或邮箱服务器； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("2) 进行博彩、赌博游戏、“私服”、“外挂”等非法互联网活动；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("3) 发布、传送、传播电子邮件广告、垃圾邮件（SPAM）；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 4) 发布、传送、传播不受欢迎的或者未经请求的电子邮件、电子广告、短信广告或包含反动、色情等有害信息的电子邮件或短信； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 5) 建立或利用有关设备、配置运行与所使用服务无关的程序或进程，导致大量占用本服务中的服务器内存、CPU或者网络带宽资源，给本服务的系统或者本服务中的用户所使用的其他网络、服务器（包括但不限于本地及外地和国际的网络、服务器等）、产品/应用、服务等带来严重的负荷，影响本服务与国际互联网或者本服务与特定网络、服务器及本服务内部的通畅联系，或者导致本服务或者本服务中的其他用户所使用的服务器宕机、死机或者其他智安云服务使用者的产品或服务不可访问等； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 6) 进行任何破坏或试图破坏网络安全的行为，包括但不限于以病毒、木马、恶意代码、钓鱼等方式，采取对其他网站、服务器进行恶意扫描、非法侵入系统、非法获取数据等； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "7) 进行任何改变或试图改变本服务提供的系统配置或破坏系统安全的行为；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "8) 利用技术或其他手段破坏、扰乱本服务的运营或他人对本服务的使用；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 9) 以任何方式干扰或企图干扰智安任何产品或任何部分、功能的正常运行，或者制作、发布、传播上述工具、方法等； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 10) 进行任何可能违反诚实信用、公序良俗、公众道德的行为；以任何方式发布虚假信息，诱导用户分享虚假信息等行为； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 11) 若您因从事包括但不限于“DNS解析”、“安全服务”、“域名代理”、“反向代理”等任何业务，导致智安云服务平台或平台其他用户频繁遭受攻击（包括但不限于DDoS攻击）且未及时更正您的行为或未根据智安要求消除影响，从而对本服务平台或其他平台用户产生威胁的，一经发现，您的主机将被强制停机，您的账号也会被立即冻结。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("9. 数据合理处理约定")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您可以指定您的内容所存储的区域。未经您的同意，我们不会从您选择的区域中迁移您的内容，除非（a）必须迁移以遵守适用的法律法规或者政府机关的约束性命令；（b）根据您的委托，您应确保您的委托已获得必要的政府许可及用户授权，有权利委托智安传输相关内容至其他区域;（c）为了提供账单、管理、技术服务或者因调查安全事件或者调查违反本协议的行为。我们可能会在您使用服务的数据中心区域处理一些数据，我们也可能会在我们维护运营、支持和调查系统及团队的区域处理这些数据。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 如您有违反本用户服务协议的行为，智安云有权拒绝继续为您提供任何服务。若您使用智安云产品或服务从事任何违法、违规或侵犯他人合法权益的行为，智安云有权依法履行网络安全管理义务，中止或终止服务，并依法保留追究您相关法律责任的权利。 "
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("智安云的权利与义务")]),
      _c("span", [_vm._v("1. 服务商资质承诺")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 深圳市智安网络有限公司承诺是依据中国法律设立并有效存续的有限责任公司，将按照本协议约定为您提供产品和服务，并切实履行本协议项下的义务和责任。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("2. 商业秘密保护承诺")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 智安云会严格依协议约定保护您的商业秘密和隐私，非因法定原因或您的许可，不会向任何第三方披露您的保密信息。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("3. 技术架构支持")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 智安云负责操作系统以下的底层技术架构支持，以及对智安云提供的软件进行运营维护。您在系统上安装的第三方应用程序由您负责。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("4. 按现状提供服务")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 智能云不断提升服务质量及服务水平。您理解，智安云无法保证所提供的产品服务毫无瑕疵，您同意，即使智安云提供的服务存在瑕疵，但上述瑕疵是当时行业技术水平所无法避免的，其将不被视为智安云违约。您同意和智安云一同合作解决由于行业技术水平限制造成的服务瑕疵类问题。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("5. 关联账户管理")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 智安云的某些产品服务具备账户授权管理功能，您可将您对服务的全部或部分操作权限授权给您指定的一个或多个被授权账户，此种情况下，任一被授权账户下进行的所有操作行为，均将被视为您通过本人账户所进行的行为，都将由您承担相应的责任以及由此产生的服务费用。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("6. 产品兼容性免责")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您理解并认可，智安云为您提供基于某些服务的安全防护，以及云上资源管理与监控的相关功能及服务，尽管智安云对该等服务经过详细的测试，但并不能保证其与所有的软硬件系统完全兼容，亦不能保证其软件及服务的完全准确性。如果出现不兼容及软件错误的情况，您应立即关闭或停止使用相关功能，并及时联系智安云，获得技术支持。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("7. 免费资源活动")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您应理解并同意，智安云在对服务进行测试研发、新品宣传推广活动时，您免费试（使）用服务期间，或您免费使用服务的额度内，智安云会对服务可用性和可靠性提供最好支撑，但不对任何服务可用性、可靠性做出承诺，亦不对您使用或不能使用智安云服务的结果承担任何责任。智安云保留日后对该等免费服务收取费用的可能性，收取服务费用之前，智安云将另行通知您。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("8. ICP备案信息真实性要求")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 依据《非经营性互联网信息服务备案管理办法》第二十三条规定，如备案信息不真实，将关闭网站并注销备案。请您承诺并确认：您提交的所有备案信息真实有效，当您的备案信息发生变化时请及时到备案系统中提交更新信息，如因未及时更新而导致备案信息不准确，智安云有权依法对接入网站进行关闭处理。 您理解并同意授权智安云基于为您提供ICP备案服务之目的，向您收集与ICP备案工作相关的图文资料、活体信息等并自行或委托第三方可信任机构开展真实性核验，在确认您的备案信息真实性的情况下，为您提供ICP备案、备案变更、备案注销等服务。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 智安云基于为您提供ICP备案服务之目的向您收集的信息，仅限于为该目的使用，如您对相关信息收集有异议，您有权拒绝提供，您还可以通过提交工单或电话等途径详细咨询。您理解并认可，智安云不会超范围收集及处理您的备案信息，如因第三方泄露您的信息或发生其他数据安全事件，由第三方承担全部法律责任。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("9. 禁止滥用系统资源")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "智安云保留在您滥用系统资源的情况下，强制停机及冻结账号的权利。"
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("10. 欠费行为制约")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 智安云保留在您资源欠费超过一定天数（具体规则参见具体产品文档）时将中止提供资源、服务的权利。对因此情形中断服务可能引发的一切后果，包括数据毁损灭失的可能后果，由您自行承担。 "
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("用户业务数据和公开信息")]),
      _c("span", [_vm._v("用户业务数据")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 1. 智安云理解并认可，您通过智安云提供的服务，加工、存储、上传、下载、分发以及通过其他方式处理的数据，均为您的用户业务数据，您完全拥有您的用户业务数据。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 2. 您应对您的用户业务数据的来源及内容负责，智安云提示您谨慎判断数据来源及内容的合法性。因您的用户业务数据内容违反法律法规、部门规章或国家政策而造成的全部结果及责任均由您自行承担。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 3. 就用户业务数据，智安云除执行您的服务要求外，不进行任何未获授权的使用及披露；但以下情形除外： "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 1) 在国家有关机关依法查询或调阅用户业务数据时，智安云具有按照相关法律法规或政策文件要求提供配合，并向第三方或者行政、司法等机构披露的义务； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("2) 您和智安云另行协商一致。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 4. 您可自行对您的用户业务数据进行删除、更改等操作。如您自行释放服务或删除数据的，智安云将删除您的在线数据，按照您的指令不再保留该等数据。就数据的删除、更改等操作，您应谨慎操作。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 5. 当服务期届满、服务提前终止（包括但不限于双方协商一致提前终止，其他原因导致的提前终止等）或您发生欠费时，除法律法规明确约定、主管部门要求或双方另有约定外，智安云仅在一定的缓冲期（以您所订购的服务适用的专有条款、产品文档、服务说明等所载明的时限为准）内继续存储您的用户业务数据（如有），缓冲期届满，智安云将删除所有用户业务数据，包括所有缓存或者备份的副本，不再保留您的任何用户业务数据。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 6. 用户业务数据一经删除，即不可恢复；您应自行承担数据因此被删除所引发的后果和责任，您理解并同意，智安云没有继续保留、导出或者返还用户业务数据的义务。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 7. 根据您与智安云协商一致，智安云在您选定的数据中心存储用户业务数据。智安云恪守对用户的安全承诺，根据适用的法律保护用户存储在智安云数据中心的数据。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("用户账户信息")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "1. 您的用户账户信息，包括您在登录智安云账户或订购、使用智安云服务时："
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " （1）所填写或提交的包括公司名称、营业执照信息、姓名、性别、出生年月日、身份证号码、护照信息、电话号码、电子邮箱、地址（含邮政编码）、支付账号。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " （2）智安云对服务过程的记录，包括您的账单、历史购买信息、咨询记录、报障记录以及排障过程等。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 2. 因收集您的用户账户信息是为了向您提供服务及提升服务质量的目的，为了实现这一目的，智安云仅把您的信息用于下列用途，除非您和智安云另行协商一致或在国家有关机关依法查询或调阅： "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("（1）向您提供您使用的各项服务，并维护、改进这些服务；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " （2）在符合相关法律法规的要求下，向您推荐您可能感兴趣的内容，包括但不限于向您发出产品和服务信息。用户支持数据您理解并同意，为了及时响应您的帮助请求，保障云平台的整体安全，改进智安云的服务，智安云将检测、浏览、记录您的服务使用行为、您数据的不安全特征等用户支持数据。用户支持数据不包括用户业务数据和用户账户信息。 "
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("服务的开通、付费与终止")]),
      _c("span", [_vm._v("1. 您可通过您的智安云账户开通、使用服务。")]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 2. 您可以通过预充值或后付费方式开通服务，开通后您获得智安云向您发送的登录、使用服务的密钥、口令即可使用服务，服务期限自开通之时起算。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 3. 服务期限届满前，若您无理由擅自退订相应服务，智安云有权采取恢复原价计费、收取违约金或收取退订/退款手续费等方式进行处理，以相应官网展示页面或相应订单中载明的方式为准； "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          "4. 服务期间收费方式、资源使用方式、服务届满未用尽资源的处理等，以相应产品或服务订单约定为准。"
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 5. 您理解并认可，为技术升级、服务体系升级、或因经营策略调整或配合国家重大技术、法规政策等变化，智安云不保证永久的提供某种服务，或可能变更所提供服务的形式、规格或其他方面，在终止该种服务或进行此种变更前，智安云将尽最大努力且提前以网站公告、站内信、邮件或短信等一种或多种方式进行事先通知；如终止提供该种服务的，智安云的提前通知将不短于30天，并且妥善处理后续事宜（包括但不限于您已缴纳但未消费的服务费用将退还至您的智安云账户）。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("6. 发生下列情形之一的，服务期限提前终止：")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("1） 双方协商一致提前终止的；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 2） 您严重违反本协议（包括但不限于，您严重违反相关法律法规规定，或您严重违反本协议项下之任一承诺内容等），智安云有权提前终止服务直至清除您的全部数据； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 3） 您理解并充分认可，虽然智安云已经建立（并将根据技术的发展不断完善）必要的技术措施来防御包括计算机病毒、网络入侵和攻击破坏（包括但不限于DDoS）等危害网络安全事项或行为（以下统称该等行为），但鉴于网络安全技术的局限性、相对性以及该等行为的不可预见性，因此如因您网站遭遇该等行为而给智安云或智安其他网络或服务器（包括但不限于本地及外地和国际的网络、服务器等）带来危害，或影响智安云与国际互联网或者其他特定网络、服务器及智安云内部的通畅联系，智安云可决定暂停或终止服务或其他必要的保护措施。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("免责声明")]),
      _c("br"),
      _c("span", [_vm._v("1. 智安云不保证（包括但不限于）")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("（1）智安云适合所有用户的使用要求；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " （2）智安云不受干扰，及时、安全、可靠或不出现错误；及用户经由智安云取得的任何产品、服务或其他材料符合用户的期望。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " （3）您使用经由智安云下载或取得的任何资料，其风险由您自行承担；因使用本产品导致您的电脑系统损坏或资料流失，您应负全部责任； "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 2. 智安云保留您违反国家、地方法律法规规定或违反本服务协议的情况下中止或终止为您提供服务的权利，并保留向您追究相关法律责任的权利。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 3. 您同意并授权智安云在您使用智安云服务时如有欺诈、发布或销售伪劣商品、侵犯他人合法权益或其他严重违反任意智安云服务规则的行为，有权直接终止向您提供服务、依法追究您的法律责任，并有权在智安关联网站范围内对此进行披露，您的智安云账号可能被直接注销，您可能因前述行为导致不能再使用任意一款智安PC端、App端产品，不能再登录任意一家智安网站，智安所有服务同时终止向您提供。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 4. 您在使用智安云服务过程中，本协议内容、相关产品页面上出现的关于交易操作的提示或智安云发送到您手机的信息（短信或电话等）内容是您使用本服务的相关指引，您使用本服务即表示您同意接受智安云服务的相关指引、规则。您了解并同意智安云有权单方改进、调整智能云服务的相关规则，而无须征得您的意，服务指引、规则以您使用服务时的页面提示（或发送到该手机的短信或电话等）为准，您同意并遵照服务指引、规则是您使用智安云服务的前提。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 5. 智安云可能会以电子邮件（或发送到您手机的短信或电话等）方式通知您服务进展情况以及提示您进行下一步的操作，但智安云不保证您能够收到或者及时收到该邮件（或发送到该手机的短信或电话等），且不承诺对此承担任何后果。因此，在服务过程中您应当及时登录到相关产品服务网站查看和进行交易操作。因您没有及时查看或/和对服务状态进行修改或确认或未能提交相关申请而导致的任何纠纷或损失，智安云不承担任何责任。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 6. 对由于您向智安云提供的联络方式有误以及您用于接收智安云邮件的电子邮箱安全性、稳定性不佳而导致的一切后果，您应自行承担责任，包括但不限于因您未能及时收到智安云的相关通知而导致的后果和损失。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 7. 系统中断或故障 系统可能因下列状况无法正常运作，使您无法使用各项联网服务时，智安云不承担损害赔偿责任，该状况包括但不限于： "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("（1）智安云在系统停机维护期间；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("（2）任何智安云所属设备以外的网络、设备故障或配置调整引起的；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " （3）因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成智安云系统障碍不能执行业务的； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " （4）由于黑客攻击、电信部门技术调整或故障、网站升级、银行方面的问题等原因而造成的服务中断或者延迟； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "（5）客户维护不当或保密不当致使数据、口令、密码等丢失或泄漏所引起的；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("（6）客户的疏忽或由客户授权的操作所引起的；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " （7）客户未遵循智安云产品使用文档或使用建议引起的，如客户在控制台、API 或者 CLI 等控制方式对BCC实例进行停机、重启、卸载云磁盘等操作引起的不可用； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " （8）本地磁盘出现宕机数据会被擦除，依赖本地磁盘及本地磁盘中数据作为启动依赖项而导致的不可用； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " （9）由于客户所安装软件或者其他非智安云直接运营的第三方软件或者配置引起的 BCC 实例出现错误； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("（10）由于出价低于市场价或者库存不足导致的抢占实例被释放；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("（11）其他非智安云原因造成的服务不可用或服务不达标的情况。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("（12）由于您或您的代理人故意或过失导致您遭受损失的情况；"),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          "8. 如您使用的服务是第三方提供，第三方所提供之服务品质由该第三方自行负责。"
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 9. ”此处“的一些链接可能会引导您离开智安云站点。链接的站点不在智安云的控制之内，智安云不对任何链接站点的内容、链接站点中包含的任何链接或此类站点的任何更改或更新负责。智安云不对源自任何链接站点内容负责。智安云仅为了提供便利的目的而向您提供这些链接，但并不暗示智安云认可相应的第三方站点。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 10. 在法律允许的情况下，智安云对于与本协议有关或由本协议引起的任何间接的、惩罚性的、特殊的、派生的损失（包括业务损失、收益损失、利润损失、使用数据或其他经济利益的损失），不论是如何产生的，也不论是由对本协议的违约（包括违反保证）还是由侵权造成的，均不负有任何责任，即使事先已被告知此等损失的可能性。另外即使本协议规定的排他性救济没有达到其基本目的，也应排除智安云对上述损失的责任。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 11. 鉴于网络服务的特殊性，您同意智安云在有正当理由的情况下可以随时变更、中断或终止部分或全部的服务，也无需向您承担任何责任。但是，智安云会尽可能事前通知您，以便您做好相关数据的转移备份以及业务调整等，以保护您的合法权益。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 12. 为保证服务的安全性和稳定性，智安云可能对您使用的机房、服务器、宽带、数据库等产品进行不定期的检修、维护、升级、迁移等，如因此类情况造成相关服务在合理时间内中断或暂停的，智安云无需为此向您承担任何责任。当涉及重大变更（如机房迁移），智安云应当提前至少30天通知您，您应予以配合，您理解并同意智安云可能会终止您的服务并将您已支付但未消费的款项退还至您的账户。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 13. 您理解并同意：智安云的服务是按照现有技术和条件所能达到的现状提供的。智安云会尽最大努力向您提供服务，确保服务的连贯性和安全性；但智安云不能保证其所提供的服务毫无瑕疵，也无法随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失、数据泄露或被窃取以及其他的损失和风险。所以您同意：即使智安云提供的服务存在瑕疵，但上述瑕疵是当时行业技术水平所无法避免的，其将不被视为智安云违约，同时，由此给您造成的数据或信息丢失等损失智安云将予以免责。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 14. 您理解并同意：在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。出现上述情况时，智安云将努力在第一时间与相关单位配合，及时进行修复，但是由此给您造成的损失智安云将予以免责。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 15. 您理解并同意：您在智安云主机安装使用过程中,自行安装盗版软件、系统、数据资源，造成自身损失的，智安云不承担任何责任。您自身的行为导致智安损失的，智安保留追偿权。 "
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("知识产权")]),
      _c("span", [
        _vm._v(
          " 1. 智安云所有的产品、技术与所有程序均属于智安公司的知识产权。“智安云”、智安云其他产品服务名称及相关图形、标识等为智安的注册商标。未经智安云及智安公司的许可，任何人不得擅自（包括但不限于：以非法的方式复制、传播、展示、镜像、上载、下载）使用。否则，智安云及智安公司将依法追究法律责任。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 2. 在本协议项下一方向对方提供的任何资料、技术或技术支持、软件、服务等的知识产权均属于提供一方或其合法权利人所有；除提供方或合法权利人明示同意外，另一方无权复制、传播、转让、许可或提供他人使用上述知识成果，否则应承担相应的责任。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 3. 您应保证提供至智安云的各项信息、上传至智安云服务上的全部数据、对智安云服务的使用，以及使用智安云服务所涉及的素材或产生的相应成果不会侵犯任何第三方的合法权益；如有第三方就您所提供、上传或使用的信息、数据或就您的行为及工作成果向智安云提起任何投诉、索赔、诉讼或其他类型的诉求，则您理解并确认，您应立即出面解决，并应赔偿智安云因此遭受的全部经济损失，包括但不限于各种费用、赔偿支出等。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 4. 如果第三方机构或个人对智安云服务的知识产权归属提出质疑或投诉，您应及时告知智安云，智安云将进行处理。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 5. 您向智安云提供的材料或者在任何智安网站上发布的材料 您向智安云提供的资料（包括反馈和建议），或者张贴、上传、输入或提交给任何服务或其关联服务供一般公众或任何公共、私有社区成员查看的资料（以下称您的提交资料），如果您张贴、上传、输入、提供或提交（以下简称“张贴”）您的提交资料，即表明您授予智安云及其关联公司、必要的分许可的被许可人使用与其互联网业务（包括但不限于所有的智安服务）运作相关的提交内容的权利，包括但不限于以下许可权利：复制、分发、传送、公开展示、公开执行、复制、编辑、翻译和重新排印您的提交资料；以及向服务的任何供应商分许可此类权利的权利。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 6. 据此规定，在使用您的提交资料时，不会支付任何补偿金。智安云没有义务张贴或使用您可能提供的任何资料，并且智安云可以自行决定随时移除任何提交资料。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 7. 如果您张贴您的提交资料，即表明您保证并声明，您对该提交资料拥有或以其他方式控制本使用条款描述的所有权利，包括但不限于，您提供、张贴、上载、输入或提交所需的所有权利。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 8. 除了上述保证和声明之外，如果您张贴含图像、照片、图片或其他全部或部分是图形（以下简称“图像”）的提交，即表明您保证并声明：(a) 您是此类图像的版权所有人，或者此类图像的版权所有人已经允许您使用此类图像或此类图像包含的任何内容和/或图像，并且您的使用方式和使用目的与授权和本使用条款和服务的规定一致，(b) 您拥有授予许可证和分许可证所需的权利（如本使用条款所述），并且 (c) 此类图像中的每个人（如果有的话）已经同意本使用条款规定的图像用途，包括但不限于，分发、公开展示和复制此类图像。如果您张贴图像，即表明您授予 (a) 私有社区的所有成员（因为每张此类图像可供此类私有社区的成员查看），和/或 (b) 一般公众（因为每张此类图像可放在服务上除私有社区之外的任何地方）在使用任何服务时根据本使用条款使用您的图像（包括但不限于，制作含此类图像的印刷品和礼品）的权利，包括但不限于，非排他性的、全球性的、免版税的许可证，以便：复制、分发、传送、公开展示、公开执行、复制、编辑、翻译和重新排印您的图像，且无需在此类图像上附您的名字；以及向服务的任何供应商分许可此类权利的权利。当您从服务彻底移除此类图像时，前一句中授予的图像许可证即终止，但前提是，此类终止不应影响在您彻底移除此类图像之前授予的、与此图像相关的任何许可证。在使用您的图像时，不会支付任何补偿金。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 9. 如果 (1) 所有副本包含以下版权声明，而且同时包含版权声明和该许可声明；(2) 此类服务文档的使用仅限于获得信息以及非商业或个人用途，同时不会在任何网络计算机上复制或公布，或者通过任何介质传播；以及 (3) 没有对任何文档做出修改，则授予对服务文档（比如白皮书、新闻稿、数据表和常见问题解答）的使用许可。经认可的教育机构，如学校、研究所等，可以下载和复制文档，并基于教学目的在课堂上分发。如需在课堂之外分发文档，必须获得明确的书面许可。将文档用于任何其他目的是法律明确禁止的，并可能导致严重的民事和刑事处罚。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 10. 上述文档不包含智安云网站或智安拥有、运营、许可或控制的任何其他站点的设计或布局。网站的元素受商业外观法、商标法、反不正当竞争法和其他法律的保护，不得整体或部分复制或模仿这些元素。未经智安明确许可，不得复制或转发网站上的任何智安公司或产品相关的徽标、图形、声音或图像。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 11. 对于出于任何目的，而作为该服务的组成部分发布的该文档所含信息和相关图形，智安和/或其供应商没有做出有关其适用性的陈述。所有此类文档和相关图形按“原样”提供，无任何形式的保证。智安对于因使用或执行服务提供的信息引起的或与其相关的使用中断、数据或利润损失，无论是由合同行为、过失或其他侵权行为导致，智安对由此造成的任何特别的、间接的、后果性损害或任何其他损害概不负责。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("保密条款")]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 1. 您与智安云郑重承诺，除本协议约定的情形外，一方不会将在合作过程中将了解到的有关对方的保密资料，以任何形式透露给第三方，并严格限制接触保密资料的员工遵守本条之保密义务。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 2. 双方同意尽最大的努力保护保密资料等不被披露。一旦发现有保密资料泄露事件，双方应合作采取一切合理措施避免或者减轻损害后果的产生。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v("3. 上述保密义务不因本协议的变更、解除或终止而终止。"),
      ]),
      _c("br"),
      _c("span", [_vm._v("4. 本协议项下的保密资料是指：")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " a) 与项目有关的任何协议、往来传真或邮件等纸质或电子版材料，包括本协议的内容和条款（特别是智安云为您提供的折扣或优惠信息）； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " b) 价格、财务及营销规划、客户资料、未发布产品、商业计划、行销信息、投资信息、财务状况、图纸、设计思想、技术诀窍、计算机程序、源代码、研究方案及其他资料； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "c) 在披露之时或之前，披露方以文字或标识指定为商业秘密的信息及文件；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("d) 其它符合法定商业秘密构成要件的信息。"),
      ]),
      _c("br"),
      _c("span", [_vm._v("5. 本条款不适用于以下内容：")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("a) 已进入公众领域的信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "b) 信息接收方通过正当方式取得的、非保密性的、同行业普遍了解的信息；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("c) 信息接收方在信息披露前已知的、且无义务保密的信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("d) 由第三方披露的且信息接收方经最审慎判断认为无须保密的信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("e) 信息接收方独立开发的信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("f) 信息披露方以书面形式同意信息接收方披露的保密信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "g) 应第三方招投标要求，将本协议本身提交第三方并说明仅限投标使用的；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "h) 为提供您所要求的服务，智安云在合理和必要的程度内向第三方分享您的数据；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("i) 按法律要求需向任何机关、机构公开的内容。"),
      ]),
      _c("br"),
      _c("span", [_vm._v("其他")]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 1. 本协议一经公布立即生效，智安云有权随时对协议内容进行修改，修改后的结果以消息推送、网页公告等方式公布于智安云网站上，而无需另行单独通知您。如果不同意智安云对本协议相关协议所做的修改，您有权停止使用网络服务。若您继续使用网络服务，则视为您接受智安云对本协议相关协议所做的修改。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          "2. 智安云拥有对本协议项下的各类优惠活动包括但不限于邀请码、代金券，虚拟货币的合理处理权及最终解释权。"
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 3. 本协议项下智安云对于您所有的通知均可通过工单、网页公告、站内信、电子邮件、手机短信或其他形式等方式进行；该等通知于发送之日视为已送达收件人。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 4. 本协议的订立、执行和解释及争议的解决均应适用中国法律（为本协议之目的，不包括香港、澳门特别行政区和台湾地区法律，也不使用冲突法）。如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向深圳市南山区人民法院提起诉讼。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 5. 本协议构成双方对本协议之约定事项及其他有关事宜的完整协议，除本协议规定的之外，未赋予本协议各方其他权利。 "
        ),
      ]),
      _c("br"),
    ]),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("h3", [_vm._v("智安云用户服务协议")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ignore_center" }, [
      _c("span", [
        _vm._v(
          " Copyright©2023 深圳市智安网络有限公司 All Rights Reve 版权所有 《中华人民共和国增值电信业务经营许可证》编号：B1-20190666 "
        ),
        _c(
          "a",
          {
            staticClass: "blue_font",
            attrs: {
              href: "https://beian.miit.gov.cn/#/Integrated/index",
              target: "_blank",
            },
          },
          [_vm._v("粤ICP备18027011号")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }