<template>
    <div class="systemLog">
        <div class="title">系统访问日志</div>
        <div>
            <occ-table :tableData="tableData" :columObj="columObj" :style-height="'220'"    >
            </occ-table >
        </div>
    </div>
</template>
<script>
    import OccTable from "../../../../za/components/occTable/index";
    import {apiGetOverviewLog } from '@/api/home'
    export default {
        components: {OccTable},
        data(){
            return{
                tableData: [],
                columObj: {
                    // 控制选择框
                    selection: false,
                    isOp: false,
                    width: '300px',
                    columnData: [
                        {
                            text: true,
                            prop: "loginAt",
                            label: "登录时间",
                            width: "",
                            flag: true,
                            align: "center",


                        },
                        {
                            text: true,
                            prop: "name",
                            label: "登录账号",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "ip",
                            label: "IP地址",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "loginPlace",
                            label: "IP所属区域（仅供参考）",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            status: true,
                            prop: "state",
                            label: "登录状态",
                            flag: true,
                            width: "",
                            align: "center",
                            disabledText:'失败',
                            enabledText:'成功',
                        },
                    ],
                },
            }
        },
        created() {
          this.getList()

        },
        methods:{
            getList(){
                this.onSubmitData(apiGetOverviewLog, null, null, false, false, (res) => {
                    this.tableData=[]
                    res.data.logList.forEach((item)=>{
                        if(item){
                            this.tableData.push(item)
                        }
                    })
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    .systemLog{
        width: 100%;
        height: 344px;
        background: #FFFFFF;
        border-radius: 2px;
        padding: 20px;
        .title{
            width: 100%;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 20px;
            margin-bottom: 14px;
        }
    }
</style>