<template>
  <el-dropdown placement="bottom">
    <div :class="[isHome ? 'container' : 'ignore_container']">
      <div :class="[isHome ? 'user_txt' : 'ignore_user_txt']">
        <div>
          <span class="userName">
            {{ userInfo.username ? userInfo.username.slice(0, 5) : '' }}
            <i v-if="userInfo.username ? userInfo.username.length > 5 : 'ture'">...</i>
          </span>
        </div>
        <div>
          <el-image ref="image" class="icon-html" fit="cover" :src="icoUrl()">
            <div slot="error">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
      </div>
    </div>
    <el-dropdown-menu>
      <div class="userInfo">
        <div class="infoName">
          <span>
            {{ userInfo.username ? userInfo.username.slice(0, 14) : '' }}
            <i v-if="userInfo.username ? userInfo.username.length > 14 : 'ture'">...</i>
          </span>
          <span
            class="defalut"
            :class="[userInfo.accountType === 0 ? 'grey_bg' : userInfo.accountType === 1 ? 'green_bg' : 'yellow_bg']"
            @click="$router.push('/accountCenter/nameCertification')"
          >
            <za-svg-icon icon-class="za-nav-realName"></za-svg-icon>
            {{ userInfo.accountType === 0 ? '未认证' : userInfo.accountType === 1 ? '企业认证' : '个人认证' }}
          </span>
        </div>
        <div class="infoId">
          <span>账号 ID：{{ userInfo.uuid }}</span>
          <i class="el-icon-copy-document copyDocument" @click="copy(userInfo.uuid)"></i>
        </div>
      </div>
      <el-dropdown-item v-for="(item, index) in optionsList" :key="index" @click.native="toPath(item.path, item)">
        <za-svg-icon :icon-class="item.icon" class-name="svg-item"></za-svg-icon>
        <span>{{ item.name }}</span>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { toLoginRoute } from '@/utils/routes'

  export default {
    name: 'NavSetting',
    props: {
      isHome: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        routerRootName: 'System',
        optionsList: [
          { id: 1, name: '用户中心', path: '/accountCenter/basicInfo', icon: 'za-nav-userInfo' },
          {
            id: 2,
            name: '实名认证',
            path: '/accountCenter/nameCertification',
            icon: 'za-nameCertification',
          },
          { id: 3, name: '安全设置', path: '/accountCenter/safetySet', icon: 'za-nav-set' },
          { id: 5, name: '退出登录', path: 'goLayout', icon: 'za-nav-exit' },
        ],
        promotion: { id: 4, name: '推广返利中心', path: '/promotionManagement/overview', icon: 'za-nav-promotion' },
        promotionPortal: { id: 4, name: '推广返利中心', path: 'promotionManagement', icon: 'za-nav-promotion' },
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        popularizeInfo: 'user/popularizeInfo',
        routes: 'routes/routes',
        isCloudPushOfficer: 'user/isRegister',
      }),
    },
    watch: {
      /**
       * 0:未认证，1:企业认证，2:个人认证
       * 判断该是否放开推广返利中心入口：1、云推官，2、实名认证并为个人认证
       * 1、如果是云推官，则跳转至推广总览
       * 2、如果为个人认证则跳转至门户推广页
       */
      'userInfo.accountType': {
        async handler(nV) {
          if (nV === 2) {
            // this.isCloudPushOfficer && this.popularizeInfo.state !== 3
            //   ? (this.optionsList[3].visible = true)
            //   : (this.optionsList[4].visible = true)
            this.isCloudPushOfficer && this.popularizeInfo.state !== 3
              ? this.optionsList.splice(3, 0, this.promotion)
              : this.optionsList.splice(3, 0, this.promotionPortal)
          }
        },
        immediate: true,
      },
    },
    methods: {
      ...mapActions({
        _logout: 'user/logout',
      }),
      icoUrl() {
        if (this.userInfo.profile) {
          return this.userInfo.profile
        } else {
          return require('@/assets/home/default.png')
        }
      },
      toPath(command) {
        if (command === 'goLayout') {
          this.logout()
        } else if (command === 'promotionManagement') {
          window.open(`${process.env.VUE_APP_PORTAL_TARGET_URL}/partners/cps`, '_self')
        } else {
          this.$router.push(command)
        }
      },

      async logout() {
        await this._logout()
        if (this.$route.name === 'TopUp') await this.$router.push(toLoginRoute(this.$route.fullPath, false))
        else await this.$router.push(toLoginRoute(this.$route.fullPath))
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '~@/za/styles/mixin.scss';

  .container {
    width: 167px;
    height: 52px;
    position: relative;
    cursor: pointer;

    .user_txt {
      width: 100px;
      font-size: 14px;
      font-weight: 500;
      color: #56fefe;
      position: absolute;
      left: 59px;
      top: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .ignore_container {
    width: 167px;
    height: 52px;
    position: relative;
    cursor: pointer;
    border-left: 1px solid #f4f6fa;

    .ignore_user_txt {
      font-size: 14px;
      font-weight: 500;
      position: absolute;
      left: 30px;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;

      .userName {
        line-height: 50px;
        margin-right: 10px;
        /*width: 70px;*/
        display: block;
        text-align: end;
      }

      .icon-html {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        margin-top: 2px;
      }
    }
  }

  .userInfo {
    width: 270px;
    border-bottom: 1px solid rgba(245, 245, 245, 1);
    padding: 20px;

    .infoName {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;

      .defalut {
        width: 100px;
        display: inline-block;
        height: 20px;
        line-height: 20px;
        text-align: center;
        font-size: 12px;
        margin-left: 18px;
        font-weight: 300;
        cursor: pointer;
      }

      .grey_bg {
        color: #ffffff;
        background-color: #999999;
      }

      .green_bg {
        color: #ffffff;
        background-color: #5bb943;
      }

      .yellow_bg {
        color: #ffffff;
        background-color: #f38800;
      }
    }

    .infoId {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #555555;
      padding-top: 16px;
    }

    .infoUser {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #555555;
      padding-top: 10px;
    }
  }

  .svg-item {
    margin-right: 9px;
    font-size: 16px;
    vertical-align: middle;
  }
</style>