var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "div",
      staticClass: "button",
      class: [
        _vm.type ? "button--" + _vm.type : "",
        { "is-loading": _vm.loading, "is-disabled": _vm.disabled },
      ],
      style: [
        _vm.type === "text" ? { color: _vm.textColor } : "",
        { fontSize: _vm.size + "px" },
      ],
      on: { click: _vm.handleClick },
    },
    [
      _vm.loading ? _c("i", { staticClass: "el-icon-loading" }) : _vm._e(),
      _vm.icon && !_vm.loading ? _c("i", { class: _vm.icon }) : _vm._e(),
      _vm.$slots.default ? _c("span", [_vm._t("default")], 2) : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }