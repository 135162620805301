var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    _vm._l(_vm.buttonList, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "btn",
          class: { "is-active": index === _vm.activeIndex },
          on: {
            click: function ($event) {
              return _vm.handleClick(index, item)
            },
          },
        },
        [_vm._v(" " + _vm._s(item[_vm.label]) + " ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }