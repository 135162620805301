var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v(_vm._s(_vm.title))]),
    ]),
    _c(
      "div",
      { staticClass: "backOrder_center" },
      [
        _vm.promoteArr.length <= 0
          ? _c("el-empty", { attrs: { "image-size": 200 } })
          : _vm._e(),
        _c(
          "el-tabs",
          {
            on: { "tab-click": _vm.handleClick },
            model: {
              value: _vm.activeName,
              callback: function ($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName",
            },
          },
          _vm._l(_vm.promoteArr, function (item, index) {
            return _c(
              "el-tab-pane",
              { key: index, attrs: { label: item.label, name: item.name } },
              [
                _vm.activeName === item.name
                  ? _c("comPage", { attrs: { productType: _vm.activeName } })
                  : _vm._e(),
              ],
              1
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }