<template>
  <div>
    <za-from-left-panel :span="16">
      <slot name="default" />
      <slot name="toggle" />
    </za-from-left-panel>
    <za-from-right-panel :span="8">
      <slot name="turn" />
      <span>展开</span>
    </za-from-right-panel>
  </div>
</template>

<script>
  export default {
    name: 'ZaQueryFormHeader',
    data() {
      return {
        show: false,
      }
    },
  }
</script>

<style lang="scss" scoped></style>
