var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "msgPersonner-container" }, [
    _c("div", { staticClass: "title" }, [_vm._v("接受设置")]),
    _c(
      "div",
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "main" },
          [
            _c("div", { staticClass: "master_top" }, [
              _c("div", { staticClass: "master_title" }, [
                _vm._v(_vm._s(item.name)),
              ]),
            ]),
            _vm._l(item.mConfig2, function (childItem, childIndex) {
              return _c(
                "div",
                { key: childIndex, staticClass: "master_main" },
                [
                  _c("div", [_vm._v(_vm._s(childItem.name))]),
                  _c(
                    "div",
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-value": 1,
                          "inactive-value": 0,
                          "active-color": "#13ce66",
                          "inactive-color": "#ff4949",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.childChange(childItem)
                          },
                        },
                        model: {
                          value: childItem.value,
                          callback: function ($$v) {
                            _vm.$set(childItem, "value", $$v)
                          },
                          expression: "childItem.value",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            }),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }