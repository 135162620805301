<template>
    <div class="details-container">
        <div @click="goBack" class="back">
            <span style="cursor: pointer;">
                <span><za-svg-icon icon-class="za-go-back"></za-svg-icon></span>
                <span>返回</span>
            </span>
        </div>
        <div class="main">
            <div class="title">
                <span>{{title}}</span>
            </div>
            <div class="describe">
                <span>消息类型：</span>
                <span>{{transType(conentType)}}</span>
                <span>发布时间：</span>
                <span>{{createAt}}</span>
            </div>
            <div class="content">
                <div v-html="content"></div>
            </div>
            <!-- <div v-html="content"></div> -->
<!--            <div class="requirement">-->
<!--                为贯彻落实《中华人民共和国反恐怖主义法》《中华人民共和国网络安全法》《互联网信息服务管理办法》《非经营性互联网信息服务备案管理办法》等法律法规和规章的要求，配合《工业和信息化部关于进一步落实网站备案信息真实性核验工作方案（试行）》的开展，XXX将进一步规范落实网站备案真实性核验工作要求，以保证备案信息真实有效。届时XXX将对存在问题的备案信息进行通知，若逾期未按通知进行修改，则备案信息将被取消接入。-->
<!--            </div>-->
<!--            <div class="center">-->
<!--                <div class="heard">-->
<!--                    具体核查内容包括：-->
<!--                </div>-->
<!--                <div class="desc">-->
<!--                    <div>1.主体信息是否真实准确：主办单位名称、主办单位证件号码需与现实际主体有效证件一致。</div>-->
<!--                    <div> 2.联系方式是否真实准确：主体负责人固定电话及手机号码、网站负责人固定电话及手机号码需为备案本人且电话有效可拨通。</div>-->
<!--                    <div> 3.网站备案真实性核验材料是否真实完整：包括网站主办者身份证件、组织机构代码证、工商营业执照、核验照片等。</div>-->
<!--                    <div> 4.接入信息是否真实准确：备案域名当前解析的IP地址的归属，需与备案接入的接入服务商一致。</div>-->
<!--                    <div> 5.网站内容、域名持有者是否与备案信息相符。</div>-->
<!--                </div>-->
<!--                <div class="heard">-->
<!--                    同时，如下情况将被视为网站内容与备案信息不符：-->
<!--                </div>-->
<!--                <div class="desc">-->
<!--                    <div>①个人性质的备案开办企业网站。</div>-->
<!--                    <div>②企业性质备案网站内容与备案主体企业不一致。</div>-->
<!--                    <div>③网站内容涉及各项前置审批内容但并未办理前置审批。</div>-->
<!--                </div>-->
<!--                <div class="heard">-->
<!--                    备案信息不合格，修改方案：-->
<!--                </div>-->
<!--                <div class="heard">-->
<!--                    请您登录代备案管理系统中提交变更备案操作。变更备案流程链接：http://help.xxx.com/knowledge_detail/5974925.html-->
<!--                </div>-->
<!--                <div class="heard">-->
<!--                    接入信息不合格，修改方案：-->
<!--                </div>-->
<!--                <div class="heard">-->
<!--                    请您登录代备案管理系统中提交接入备案操作。接入备案流程链接：https://help.xxx.com/knowledge_detail/36924.html-->
<!--                </div>-->
<!--                <div class="heard">-->
<!--                    网站内容不合格，修改方案：-->
<!--                </div>-->
<!--                <div class="desc">-->
<!--                    <div>方式一：将备案信息的主体性质变更为与网站内容相一致。请您登录代备案管理系统中提交变更备案操作。</div>-->
<!--                    <div>方式二：修改网站内容，确保与备案信息相符。</div>-->
<!--                </div>-->
<!--                <div class="desc">-->
<!--                    <div>-->
<!--                        确保网站备案真实性是网站主办者的基本义务，请广大用户务必提前进行自查自纠。同时，为配合以上专项清理整顿工作的开展，XXX将对存在问题的备案信息进行通知，请相关用户按照通知要求进行及时修改，以避免因虚假备案或备案信息不真实而触犯国家相关法律法规，导致网站无法正常访问。-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="desc">-->
<!--                    <div>-->
<!--                        备案真实性核验工作是XXX作为接入服务商工作的重中之重，一直以来，XXX都在积极推动创新真实性核验的革新工作，并协同相关部门强化信息安全管理工作，为用户提供便捷、合规的备案接入服务。XXX也将一如既往的打击虚假备案情况，确保互联网环境绿色、健康。-->
<!--                    </div>-->
<!--                </div>-->

            </div>
    </div>
</template>
<script>
    import {apiMsgInfor} from '@/api/msgCenter/msgIndex'
    import {apiMsgConentType} from '@/api/msgCenter/msgIndex'
    export default {
        data() {
            return {
                title: '',
                content: '',
                createAt: '',
                conentType: '',
                infoType:[],
            }
        },
        methods: {
            goBack() {
                // this.$router.push('/msgCenter/stationMsg/allmsg')
                this.$router.go(-1)
            },
            getType(){
                this.onSubmitData(apiMsgConentType, {read:'0'}, null, false, false, (res) => {
                    this.infoType=[]
                    res.data.conentTypes.forEach((item)=>{
                        this.infoType.push(item)
                    })
                })
            },
            transType(t){
                if (t) {
                    let res = []
                    this.infoType.forEach(item => {
                        item.key == t ? res.push(item) : ''
                    })
                    return res ? res[0].name : "--"
                }
            }

        },
        created() {
            this.getType()
            if (this.$route.query.id) {
                this.onSubmitData(apiMsgInfor, {id: this.$route.query.id}, null, false, false, (res) => {
                    this.title = res.data.title;
                    this.createAt = res.data.createAt;
                    this.conentType = res.data.conentType
                    // let domResult,domHtmlStr = ''
                    // let regXHtml = /(?<=(<p[^>]*?>)).*?(?=(<\/p>))/g
                    // if (res && res.data && res.data.content) {
                    //   console.log(res);
                                this.content = res?.data?.content

                   
                    // }
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    .details-container {
        .back {
            font-size: 14px;
            font-weight: 500;
            color: #000000;
            margin-bottom: 20px;
        }

        .main {
            font-size: 20px;
            font-weight: 500;
            color: #000000;

            .title {
                margin-bottom: 20px;
            }

            .describe {
                height: 40px;
                background: #F4F6FA;
                border-radius: 2px;
                font-size: 14px;
                font-weight: 400;
                padding: 9px;
                margin-bottom: 20px;

                span:nth-of-type(3) {
                    margin-left: 20px;
                }

                span:nth-child(even) {
                    color: #000000;
                }

                span:nth-child(odd) {
                    color: #555555;
                }
            }

        }
        .content {
            width: 100%;
            height: 100%;
            ::v-deep div {
                width: 100%;
                height: 20px;
                display: inline-block;
                font-size: 14px !important;
                font-weight: 400;
                line-height: 16px;
            }
        }
    }
</style>