var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c("div", { staticClass: "heardBlock" }, [
        _c(
          "div",
          { staticClass: "ignore_center", on: { click: _vm.officialWeb } },
          [
            _c("el-image", {
              staticClass: "pic-error-parent",
              attrs: { src: require("@/assets/home/home_logo.png") },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "title" }, [_vm._v("智安云账号注销协议")]),
      ]),
    ]),
    _c("div", { staticClass: "main" }, [
      _c(
        "span",
        {
          staticClass: "blue_font",
          on: {
            click: function ($event) {
              return _vm.$router.push("/login")
            },
          },
        },
        [_vm._v("返回")]
      ),
      _vm._m(0),
      _c("span", { staticClass: "first" }, [
        _vm._v(
          " 在您正式发起智安云账号注销程序前，请您务必认真阅读、充分理解本《智安云账号注销协议》内容。如您对本协议条款有任何疑问，请向我方咨询（客服电话：400-018-5113）。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 【特别提示】当您按照注销页面提示开始填写信息，即表示您已充分阅读、理解并接受本协议的全部内容。阅读本协议的过程中，如果您不同意任何条款或条件约定，您有权立即停止账户注销程序。 "
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("一、协议主体、内容与生效")]),
      _c("span", [
        _vm._v(
          " 1.1 本协议是特定智安云账号的账号持有人（“您”）与深圳市智安网络有限公司（“我方”）之间，就解除双方之前达成的《智安云用户服务协议》（“用户服务协议”）、《智安云线上订购协议》及相关事宜，所达成的一致条款。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 1.2 本协议为附生效条件的协议。在您向我方提交账号注销申请后，我方将按照注销提示的相关说明，对您账号的相关情况进行逐一核查，通过核查确认您符合如下账号注销条件的，本协议即可生效： "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("1）账号不存在诸如以下未完成的订单、服务："),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("账号本月有账期未出账")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("账号有资源运行未删除")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("账号有未完成订单")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("账号存在提现过程中未到账的金额"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "2）账号不存在现金余额、返点余额，且不存在以下任何拖欠或者未缴纳的款项："
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("账号有欠款")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("账号账期未还")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("账号未确认入账金额")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("账号有欠票")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("3）账号不存在企业组织关系。"),
      ]),
      _c("br"),
      _c("h5", [_vm._v("二、账号注销的程序")]),
      _c("span", [
        _vm._v(
          " 我们在此善意提醒，账号一旦注销后，我们将无法协助您找回，请您备份好账号下所有业务信息后谨慎操作。您注销账户后，除满足《网络安全法》对于数据留存的强制性要求外，我们将在相关行为的诉讼时效届满后，对您的个人信息予以删除或匿名化处理。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("2.1 按照注销页面提示，发起账号注销。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 2.2 您发起账户注销之时起，系统将启动自动核查机制，该机制无法强行中止，如通过自动核查，且法律法规无相反规定的，账号将自动删除。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 2.3 系统自动核查中，如发现相关账号下存在未完成的订单、服务，或其他不符合注销条件的，系统将提示错误信息，账号注销程序将自动中止，且不会自动恢复。如果您希望继续注销的，请您按照提示完成相关操作，符合注销条件后请您重新发起账号注销。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 2.4 账号注销期间，如果我方发现您的智安云账号被他人投诉、被国家机关调查或者正处于诉讼、仲裁程序中，我方有权自行终止您账号注销进程而无需另行得到您的同意。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("2.5 账号注销过程需要一定时间，请您耐心等待。"),
      ]),
      _c("br"),
      _c("h5", [_vm._v("三、账号注销的后果")]),
      _c("span", [
        _vm._v(
          "3.1 账号注销意味着账号协议及该账号下所有服务条款、协议、合同等一并解除，将对您造成如下后果："
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("3.1.1 您将无法再次登录智安云网站。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("3.1.2 您将无法访问控制面板或云站点控制面板;"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("3.1.3 您将无法进行依赖于账号权限进行的其他操作；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "3.1.4 您无法找回该账号下的个人信息、交易记录、业务数据、历史信息等。"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "提示：账号注销并不代表您在该账号注销前的所有账号行为和相关责任得到豁免或减轻。"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("3.2 账号注销后，将对我方造成如下后果："),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 3.2.1 我方将无权继续收集、存储和使用您的个人信息和数据，但法律法规另有要求的情形除外，例如：为了税务合规而保留相应的发票信息等，因法律规定需要留存的个人信息，我们将妥善保管，不再将其应用于业务场景； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 3.2.2 在遵守国家法律法规的前提下，我方有义务保障您在该账号下的所有数据在实现日常业务功能所涉及的系统中不可被检索、访问； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 3.2.3 我方不再负有向您提供与已注销账号相关的信息展示与披露、数据恢复与找回，或提供其他服务的义务。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 提示：账号注销不影响我方在政府主管机关要求或其他合法场景下，依法确认该账号注销前的用户真实身份等相关义务的履行。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 3.3 账号注销后，经过一定的注销保护期后，任何第三方均可使用同一用户名发起注册申请。账号新注册成功后，新的账号持有人对账号注销前的所有账户下行为不承担任何责任。若新旧账号持有人之间发生任何纠纷，由双方自行解决。 "
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("四、附则")]),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 4.1 您理解并接受，本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律，与法律规定不一致或存在冲突的，该不一致或冲突条款不具有法律约束力。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 4.2 就本协议内容或其执行发生任何争议，双方应进行友好协商；协商不成时，任一方均可向我方所在地有管辖权的人民法院提起诉讼。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 4.3 本协议如果与双方以前签署的有关条款或者我方的有关陈述不一致或者相抵触的，以本协议约定为准。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您在此再次保证已经完全阅读并理解了上述账号注销协议，并自愿正式进入账号注销的后续流程，接受上述条款的约束。 "
        ),
      ]),
      _c("br"),
    ]),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("h3", [_vm._v("智安云账号注销协议")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ignore_center" }, [
      _c("span", [
        _vm._v(
          " Copyright©2023 深圳市智安网络有限公司 All Rights Reve 版权所有 《中华人民共和国增值电信业务经营许可证》编号：B1-20190666 "
        ),
        _c(
          "a",
          {
            staticClass: "blue_font",
            attrs: {
              href: "https://beian.miit.gov.cn/#/Integrated/index",
              target: "_blank",
            },
          },
          [_vm._v("粤ICP备18027011号")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }