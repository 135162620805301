<template>
  <div class="voucher-container" v-za-loading="isLoading">
    <div>
      <za-tabs :tabArray="titleList" :handleActive="titleList[0].key" :showBorder="false"></za-tabs>
    </div>
    <div class="prompt">
      <span style="margin-left: 16px; font-size: 14px">
        <za-svg-icon icon-class="za-wanning"></za-svg-icon>
      </span>
      <span>
        申请退款时，代金券不支持退还；且代金券不可抵扣欠费金额、不支持延长有效期、转移至其他账号、提现、开票。
      </span>
    </div>
    <div style="margin-top: 45px">
      <za-query-form>
        <za-query-form-left-panel :span="4">
          <za-button :icon="buttonIconObj.ADD" @click="exchange">兑换代金券</za-button>
        </za-query-form-left-panel>
        <za-query-form-right-panel :span="20">
          <el-form :inline="true" :model="pageObj" @submit.native.prevent>
            <el-form-item label="适用产品:">
              <el-select
                v-model="pageObj.product"
                :placeholder="`${translateTitle('placeTxt')}`"
                style="width: 200px"
                @change="getList()"
              >
                <el-option v-for="item in productList" :key="item.value" :label="item.name" :value="item.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="状态:" label-width="60px">
              <el-select
                v-model="pageObj.state"
                :placeholder="`${translateTitle('placeTxt')}`"
                style="width: 200px"
                @change="getList()"
              >
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.name" :value="item.value" />
              </el-select>
            </el-form-item>
            <el-form-item style="margin-left: 50px !important">
              <el-input v-model="pageObj.cdkeyId" placeholder="输入代金券编号">
                <el-button slot="append" icon="el-icon-search" @click="getList()"></el-button>
              </el-input>
            </el-form-item>
          </el-form>
        </za-query-form-right-panel>
      </za-query-form>
    </div>
    <div>
      <occ-table :tableData="tableData" :columObj="columObj" :isOp="columObj.isOp"></occ-table>
      <pagination :limit.sync="pageObj.pageSize" :page.sync="pageObj.pageNo" :total="total" @pagination="getList" />
    </div>
    <div>
      <za-dialog top-title="兑换代金券" :visible.sync="dialogVisible" width="600px">
        <el-form
          ref="form"
          :disabled="confirmBtnLoading"
          :model="baseForm"
          label-position="right"
          label-width="140px"
          :rules="rules"
        >
          <el-form-item :label="'代金券号' + '：'" prop="cdkeyId">
            <el-input v-model="baseForm.cdkeyId" placeholder="请输入兑换代金券号码" />
          </el-form-item>
        </el-form>
        <div slot="footer">
          <za-button :loading="confirmBtnLoading" :type="buttonTypeObj.BLUE" @click="handleSubmitForm">确定</za-button>
          <za-button :disabled="confirmBtnLoading" :type="buttonTypeObj.GREY" @click="dialogVisible = false">
            取消
          </za-button>
        </div>
      </za-dialog>
    </div>
  </div>
</template>
<script>
import ZaDialog from '../../../../za/components/zaDialog/index'
import { apiCouponlist, apiExchangeCouponlist } from '@/api/moneyCenter/cardRoll/voucher'
import zaTabs from '@/za/components/zaTabs'
import { tenBitTimestamp } from '@/utils'
export default {
  components: { ZaDialog, zaTabs },
  data() {
    return {
      titleList: [
        {
          key: '代金券管理',
          name: '代金券管理',
        },
      ],
      typeOptions: [
        { name: '全部', value: '' },
        { name: '未使用', value: '1' },
        { name: '已使用', value: '2' },
        { name: '已作废', value: '3' },
        { name: '已过期', value: '4' },
      ],
      baseForm: {
        cdkeyId: '',
      },
      rules: {
        cdkeyId: [
          {
            required: true,
            trigger: 'blur',
            message: '代金券号不能为空',
          },
        ],
      },
      total: 10,
      pageObj: {
        product: '',
        state: '',
        cdkeyId: '',
        pageNo: 1,
        pageSize: 10,
        type: '1', // 1:代金券，2折扣券
      },
      tableData: [],
      columObj: {
        // 控制选择框
        selection: false,
        isOp: false,
        width: '300px',
        columnData: [
          {
            text: true,
            prop: 'name',
            label: '代金券名称',
            width: '',
            flag: true,
            align: 'center',
          },
          {
            text: true,
            prop: 'cdkeyId',
            label: '代金券号',
            flag: true,
            width: '',
            align: 'center',
          },
          {
            ownInnerHtml: true,
            prop: 'state',
            label: '状态',
            flag: true,
            width: '',
            align: 'center',
            htmlTest: (row, $index) => {
              return `<div>${
                row.state == '1'
                  ? '未使用'
                  : row.state == '2'
                  ? '已使用'
                  : row.state == '3'
                  ? '已作废'
                  : row.state == '4'
                  ? '已过期'
                  : ''
              }</div>`
            },
          },
          {
            text: true,
            prop: 'amount',
            label: '优惠金额',
            flag: true,
            width: '',
            align: 'center',
          },
          {
            ownDefined: true,
            prop: 'product',
            label: '适用产品',
            flag: true,
            width: '',
            align: 'center',
            ownDefinedReturn: (row) => {
              if (row.product && row.product.length) {
                let arr = JSON.parse(row.product)
                let tempList = []
                arr.map((item)=>{
                  if(this.productObj[item])tempList.push(this.productObj[item])
                })
                return tempList.join(',')
              } else if (!row.product) {
                return '-'
              } else {
                return '所有都适用'
              }
            },
          },
          {
            ownDefined: true,
            prop: 'start',
            label: '开始时间',
            flag: true,
            width: '',
            align: 'center',
            ownDefinedReturn: (row) => {
              return row.start === 0 ? '-' : tenBitTimestamp(row.start)
            },
          },
          {
            ownDefined: true,
            prop: 'end',
            label: '结束时间',
            flag: true,
            width: '',
            align: 'center',
            ownDefinedReturn: (row) => {
              return row.end === 0 ? '-' : tenBitTimestamp(row.end)
            },
          },
        ],
      },
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.onSubmitData(apiCouponlist, this.pageObj, null, false, false, (res) => {
        this.total = res.data.total
        this.tableData = res.data.list
      })
    },
    exchange() {
      this.dialogVisible = true
    },
    handleSubmitForm() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          let result = {
            cdkeyId: this.baseForm.cdkeyId,
            type: 1,
          }
          let res = await apiExchangeCouponlist(result).finally(() => (this.confirmBtnLoading = false))
          if (res.code === 0) {
            this.$msgSuccess('兑换成功')
            this.dialogVisible = false
            this.getList()
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~@/za/styles/mixin.scss';

.prompt {
  height: 40px;
  background: #fffbe6;
  border-radius: 2px;
  border: 1px solid #ffe58f;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 40px;
}
</style>