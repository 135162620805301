    <template>
    <div class="medify-center_bg">
        <div>
            <za-go-back :title="'安全设置/'+title" @click="goBack"></za-go-back>
        </div>
        <div class="main">
            <div>
                <el-steps :active="active" align-center>
                    <el-step title="验证身份"></el-step>
                    <el-step :title="title"></el-step>
                    <el-step title="完成"></el-step>
                </el-steps>
            </div>
            <div class="main_btn">
                <div v-if="state &&selectValue==0">
                    <el-row>
                        <el-form :model="pwdForm" status-icon :rules="pwdRules" ref="pwdForm"
                                 :disabled="confirmBtnLoading">
                            <el-col :span="24">
                                <el-form-item prop="password">
                                    <el-input
                                            v-model="pwdForm.password"
                                            placeholder="请输入新密码"
                                            ref="password"
                                            :type="passwordType"
                                            @keyup.native="trim('password')"
                                    >
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item prop="newPassword">
                                    <el-input
                                            v-model="pwdForm.newPassword"
                                            placeholder="请确认新密码"
                                            ref="newPasswordType"
                                            :type="newPasswordType"
                                            @keyup.native="trim('newPassword')"
                                    >
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-form>
                    </el-row>
                    <div class="changeBtn">
                        <el-button type="primary" @click="submitForm('pwdForm')">确认修改</el-button>
                    </div>
                </div>
                <div v-if="state &&selectValue==1">
                    <el-form :model="telForm" status-icon :rules="telFormRules" ref="telForm" label-width="200px">
                        <el-form-item label="当前手机号：" prop="oldPhone">
                            <el-input type="text" v-model="telForm.oldPhone" autocomplete="off" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="新的手机号" prop="phone">
                            <el-input type="text" v-model="telForm.phone" autocomplete="off" maxlength="11"
                                      @keyup.native="teltrim('phone')"></el-input>
                        </el-form-item>
                        <el-form-item :label="'输入验证码'+ '：'" required>
                            <div>
                                <el-col :span="16">
                                    <el-form-item prop="smsCode">
                                        <el-input v-model="telForm.smsCode"
                                                  placeholder="请输入验证码！"
                                                  @input="changeWaring()"
                                        />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="2">&nbsp;</el-col>
                                <el-col :span="6">
                                    <el-button @click="sendData('telForm')" class="sendInfo" :disabled="dis"
                                               :class="{grey:isGrey,blue:!isGrey}">
                                        <span v-if="telState==1">发送验证码</span>
                                        <span v-if="telState==2">{{count}}秒重试</span>
                                        <span v-if="telState==3">重新发送</span>
                                    </el-button>
                                </el-col>
                            </div>
                        </el-form-item>
                    </el-form>
                    <div class="changeBtn">
                        <el-button type="primary" @click="submitForm('telForm')">确认修改</el-button>
                    </div>
                </div>
                <div v-if="state &&selectValue==2">
                    <el-form :model="emailForm" status-icon :rules="rules" ref="emailForm" label-width="200px">
                        <el-form-item label="当前绑定邮箱：" prop="oldEmail">
                            <el-input type="text" v-model="emailForm.oldEmail" autocomplete="off" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="新的邮箱" prop="email">
                            <el-input type="text" v-model="emailForm.email" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item :label="'输入验证码'+ '：'" required>
                            <div>
                                <el-col :span="16">
                                    <el-form-item prop="smsCode">
                                        <el-input v-model="emailForm.smsCode"
                                                  placeholder="请输入验证码！"
                                                  @input="changeWaring()"
                                        />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="2">&nbsp;</el-col>
                                <el-col :span="6">
                                    <el-button @click="sendData('emailForm')" class="sendInfo" :disabled="two_dis"
                                               :class="{two_grey:two_isGrey,two_blue:!two_isGrey}">
                                        <span v-if="two_telState==1">发送验证码</span>
                                        <span v-if="two_telState==2">{{two_count}}秒重试</span>
                                        <span v-if="two_telState==3">重新发送</span>
                                    </el-button>
                                </el-col>
                            </div>
                        </el-form-item>
                    </el-form>
                    <div class="changeBtn">
                        <el-button type="primary" @click="submitForm('emailForm')">确认修改</el-button>
                    </div>
                </div>
                <div v-if="state&&selectValue==3">
                    <el-form
                            ref="pwdProtection"
                            :disabled="confirmBtnLoading"
                            :model="baseForm"
                            label-position="right"
                            label-width="140px"
                            :rules="threeRules"
                    >
                        <el-form-item :label="'请选择密保问题' + '：'" prop="oneQuestion">
                            <el-select v-model="baseForm.oneQuestion" style="width:100%" clearable
                                       @change="handleChange('oneQuestion',$event)">
                                <el-option
                                        v-for="item in oneQuestion"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.id"
                                        :disabled="item.disabled"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item :label="'自定义问题'+ '：'" prop="orderNumber1" v-if="baseForm.oneQuestion=='oneId'">
                            <el-input v-model="baseForm.orderNumber1"
                                      placeholder="请输入自定义问题"/>
                        </el-form-item>
                        <el-form-item :label="'密保答案'+ '：'" prop="oneAnswer">
                            <el-input v-model="baseForm.oneAnswer"
                                      placeholder="请输入密保答案"
                            />
                        </el-form-item>
                        <el-form-item :label="'请选择密保问题' + '：'" prop="twoQuestion">
                            <el-select v-model="baseForm.twoQuestion" style="width:100%" clearable
                                       @change="handleChange('twoQuestion',$event)">
                                <el-option
                                        v-for="item in twoQuestion"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.id"
                                        :disabled="item.disabled"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item :label="'自定义问题'+ '：'" prop="orderNumber2" v-if="baseForm.twoQuestion=='oneId'">
                            <el-input v-model="baseForm.orderNumber2"
                                      placeholder="请输入自定义问题"/>
                        </el-form-item>
                        <el-form-item :label="'密保答案'+ '：'" prop="twoAnswer">
                            <el-input v-model="baseForm.twoAnswer"
                                      placeholder="请输入密保答案"
                            />
                        </el-form-item>
                        <el-form-item :label="'请选择密保问题' + '：'" prop="threeQuestion">
                            <el-select v-model="baseForm.threeQuestion" style="width:100%" clearable
                                       @change="handleChange('threeQuestion',$event)">
                                <el-option
                                        v-for="item in threeQuestion"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.id"
                                        :disabled="item.disabled"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item :label="'自定义问题'+ '：'" prop="orderNumber3"
                                      v-if="baseForm.threeQuestion=='oneId'">
                            <el-input v-model="baseForm.orderNumber3"
                                      placeholder="请输入自定义问题"/>
                        </el-form-item>
                        <el-form-item :label="'密保答案'+ '：'" prop="threeAnswer">
                            <el-input v-model="baseForm.threeAnswer"
                                      placeholder="请输入密保答案"
                            />
                        </el-form-item>
                    </el-form>
                    <div class="changeBtn">
                        <el-button type="primary" @click="submitForm('pwdProtection')">确认修改</el-button>
                    </div>
                </div>
                <div v-if="state&&selectValue==4">
                    <el-form :model="setEmailForm" status-icon :rules="rules" ref="setEmailForm" label-width="200px">
                        <el-form-item :label="'输入邮箱号'+ '：'" prop="email">
                            <el-input v-model="setEmailForm.email"
                            />
                        </el-form-item>
                        <el-form-item :label="'输入验证码'+ '：'" required>
                            <div>
                                <el-col :span="16">
                                    <el-form-item prop="smsCode">
                                        <el-input v-model="setEmailForm.smsCode"
                                                  placeholder="请输入验证码"
                                                  @input="changeWaring()"
                                        />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="2">&nbsp;</el-col>
                                <el-col :span="6">
                                    <el-button @click="sendData('setEmailForm')" class="sendInfo" :disabled="two_dis"
                                               :class="{two_grey:two_isGrey,two_blue:!two_isGrey}">
                                        <span v-if="two_telState==1">发送验证码</span>
                                        <span v-if="two_telState==2">{{two_count}}秒重试</span>
                                        <span v-if="two_telState==3">重新发送</span>
                                    </el-button>
                                </el-col>
                            </div>
                        </el-form-item>
                        <div class="changeBtn">
                            <el-button type="primary" @click="submitForm('setEmailForm')">确认添加</el-button>
                        </div>
                    </el-form>
                </div>
            </div>
            <div v-if="!state" class="successPage">
                <el-image :src="require('@/assets/accountCenter/success.png')"></el-image>
                <div class="successTest">
                    <span>{{title+'成功，请牢记账号密码！'}}</span>
                </div>
                <el-button type="primary" @click="$router.push('/accountCenter/basicInfo')">返回个人中心</el-button>
            </div>
            <div>
                <el-dialog
                        width="25%"
                        title="验证"
                        :visible.sync="innerVisible"
                        append-to-body>
                    <za-verify
                            v-if="showVerity"
                            ref="verify"
                            @verifySuccess="success"
                            :selectKey="selectValue *1 "
                    />
                </el-dialog>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        apiUpdatePwd,
        apisaveSecurity,
        apiSecureUpdateSms,
        apiSendPhone,
        apiGetEmail,
        apiSendEmail,
        apiSecureSms,
        apiNewSecureEmail,
        apiUpdateEmail,
        apiAddEmail
    } from '@/api/accountCenter/safetySet'
    import ZaVerify from '@/za/components/zaVerify'
    import ZaGoBack from "../../../../../za/components/zaGoBack/index";
    import {apiGetPhone,} from '@/api/accountCenter/safetySet'
    import {translateTitle} from "@/utils/i18n";
    import {isPwd,isPwdNotTest,isHaveSpecial,isSPpassWord,isHaveSpace} from '@/utils/validate'

    export default {
        components: {ZaVerify, ZaGoBack},
        data() {
            const password = (rule, value, callback) => {
                if (value === '') callback(new Error(translateTitle('register.placePwd')))
                else if (value.length < 6||value.length > 20) callback(new Error(' 请输入6-20字符'))
                else if(isPwdNotTest(value))callback(new Error('密码设置不能输入中文'))
                else if (isSPpassWord(value)) callback(new Error('密码复杂度太低，限字母、数字或特殊字符至少包含2种'))
                else callback()
            }
            const newpassword = (rule, value, callback) => {
                if (value === '') callback(new Error(translateTitle('register.tipsConfirmPwd')))
                else if (this.pwdForm.newPassword !== '' && this.pwdForm.newPassword !== this.pwdForm.password)
                    callback(new Error(translateTitle('register.tipsDistinctPwd')))
                else callback()
            }
            const email =  (rule, value, callback) => {
                if (value === '') callback(new Error('请输入邮箱，邮箱不能为空'))
                else if (isHaveSpace(value))callback(new Error('邮箱不能包含空格！'))
                else callback()
         
            }

            const smsCode=  (rule, value, callback) => {
                if (value === '') callback(new Error('验证码不能为空'))
                else if(isHaveSpace(value))callback(new Error('验证码不能包含空格！'))
                else callback()
         
            }
            return {
                emailForm: {
                    oldEmail: '',
                    email: '',
                    smsCode: '',
                },
                telForm: {
                    oldPhone: '',
                    phone: '',
                    smsCode: ''
                },
                telFormRules: {
                    phone: [{required: true, message: '请输入手机号', trigger: 'blur'},],
                    smsCode: [{required: true, trigger: 'blur', validator: smsCode}],
                },
                count: "",
                telState: 1,
                dis: false,
                show: true,
                timer: null, //设置计时器,
                isGrey: false, //按钮样式
                showVerity: false,
                innerVisible: false,
                two_count: "",
                two_telState: 1,
                two_dis: false,
                two_show: true,
                two_timer: null, //设置计时器,
                two_isGrey: false, //按钮样式
                pwdForm: {
                    password: '',
                    newPassword: ''
                },
                pwdRules: {
                    password: [{required: true, trigger: 'blur', validator: password}],
                    newPassword: [{required: true, trigger: 'blur', validator: newpassword}],
                },
                active: 2,
                setEmailForm: {
                    email: '',
                    smsCode: ''
                },
                rules: {
                    pass: [
                        {message: '请输入当前绑定邮箱', trigger: 'blur', required: true,}
                    ],
                    checkPass: [
                        {message: '请输入新的邮箱', trigger: 'blur', required: true,}
                    ],
                    newpass: [
                        {message: '请确认新的登录密码', trigger: 'blur', required: true,}
                    ],
                    email: [{required: true, trigger: 'blur', validator: email}],

                    smsCode: [{required: true, trigger: 'blur', validator: smsCode}],
                },
                state: true,
                selectValue: 0,
                baseForm: {
                    oneAnswer: '',
                    twoAnswer: '',
                    threeAnswer: '',
                    oneQuestion: '',
                    twoQuestion: '',
                    threeQuestion: '',
                    orderNumber3: '',
                    orderNumber2: '',
                    orderNumber1: ''
                },
                threeRules: {
                    oneQuestion: [{required: true, message: '请选择密保问题', trigger: 'change'}],
                    twoQuestion: [{required: true, message: '请选择密保问题', trigger: 'change'}],
                    threeQuestion: [{required: true, message: '请选择密保问题', trigger: 'change'}],
                    orderNumber3: [{required: true, message: '请输入密保问题', trigger: 'blur'}],
                    orderNumber2: [{required: true, message: '请输入密保问题', trigger: 'blur'}],
                    orderNumber1: [{required: true, message: '请输入密保问题', trigger: 'blur'}],
                    oneAnswer: [{required: true, message: '请输入密保答案', trigger: 'blur'}],
                    twoAnswer: [{required: true, message: '请输入密保答案', trigger: 'blur'}],
                    threeAnswer: [{required: true, message: '请输入密保答案', trigger: 'blur'}],
                },
                oneQuestion: [
                    {id: '我母亲的姓名?', label: '我母亲的姓名?'},
                    {id: '我就读的第一所学校的名称？', label: '我就读的第一所学校的名称？'},
                    {id: '我的学号或工号是什么？', label: '我的学号或工号是什么'},
                    {id: '我父亲的姓名', label: '我父亲的姓名'},
                    {id: '我父亲的职业', label: '我父亲的职业'},
                    {id: '我母亲的职业', label: '我母亲的职业'},
                    {id: '我的出生地', label: '我的出生地'},
                    {id: '我初中班主任的名字是', label: '我初中班主任的名字是'},
                    {id: '我最爱的人的名字', label: '我最爱的人的名字'},
                    {id: '我妈妈的生日', label: '我妈妈的生日'},
                    {id: 'oneId', label: '自定义问题'},
                ],
                twoQuestion: [
                    {id: '我母亲的姓名?', label: '我母亲的姓名?'},
                    {id: '我就读的第一所学校的名称？', label: '我就读的第一所学校的名称？'},
                    {id: '我的学号或工号是什么？', label: '我的学号或工号是什么'},
                    {id: '我父亲的姓名', label: '我父亲的姓名'},
                    {id: '我父亲的职业', label: '我父亲的职业'},
                    {id: '我母亲的职业', label: '我母亲的职业'},
                    {id: '我的出生地', label: '我的出生地'},
                    {id: '我初中班主任的名字是', label: '我初中班主任的名字是'},
                    {id: '我最爱的人的名字', label: '我最爱的人的名字'},
                    {id: '我妈妈的生日', label: '我妈妈的生日'},
                    {id: 'oneId', label: '自定义问题'},
                ],
                threeQuestion: [
                    {id: '我母亲的姓名?', label: '我母亲的姓名?'},
                    {id: '我就读的第一所学校的名称？', label: '我就读的第一所学校的名称？'},
                    {id: '我的学号或工号是什么？', label: '我的学号或工号是什么'},
                    {id: '我父亲的姓名', label: '我父亲的姓名'},
                    {id: '我父亲的职业', label: '我父亲的职业'},
                    {id: '我母亲的职业', label: '我母亲的职业'},
                    {id: '我的出生地', label: '我的出生地'},
                    {id: '我初中班主任的名字是', label: '我初中班主任的名字是'},
                    {id: '我最爱的人的名字', label: '我最爱的人的名字'},
                    {id: '我妈妈的生日', label: '我妈妈的生日'},
                    {id: 'oneId', label: '自定义问题'},
                ],
                title: '',
                passwordType: 'password',
                newPasswordType: 'password',
                seleObj: {
                    oneQuestion: '',
                    twoQuestion: "",
                    threeQuestion: ''
                },
            }
        },
        mounted() {
            this.selectValue = this.$route.query.queryData;
            if (localStorage.getItem('successChange') == 'false') {
                this.state = false
                this.active = 3
            } else {
                this.state = true
                this.active = 2
            }
            if (this.$route.query.queryData) {
                switch (this.$route.query.queryData) {
                    case '0':
                        this.title = '修改密码'
                        break
                    case '1':
                        this.title = '修改手机号'
                        break
                    case '2':
                        this.title = '修改邮箱'
                        break
                    case '3':
                        this.title = '修改密保'
                        break
                    case '4':
                        this.title = '新增邮箱'
                        break
                    default:
                        break
                }
            }
            this.getPhone()
        },
        beforeDestroy() {
            localStorage.removeItem('successChange')
        },
        methods: {
            handleChange(val, event) {
                if (val == 'oneQuestion' && event !== 'oneId'&& event !== 'twoId'&& event !== 'threeId') {
                    this.seleObj.oneQuestion = event
                } else if (val == 'twoQuestion'  && event !== 'oneId'&& event !== 'twoId'&& event !== 'threeId') {
                    this.seleObj.twoQuestion = event
                } else if (val == 'threeQuestion' && event !== 'oneId'&& event !== 'twoId'&& event !== 'threeId') {
                    this.seleObj.threeQuestion = event
                }
                this.oneQuestion.forEach((item) => {
                    item.disabled = false
                    if (this.seleObj.oneQuestion == item.id || this.seleObj.twoQuestion == item.id || this.seleObj.threeQuestion == item.id) {
                        item.disabled = true
                    }
                })
                this.twoQuestion= this.oneQuestion
                this.threeQuestion=this.oneQuestion

            },
            trim(val) {
                this.pwdForm[val] = this.pwdForm[val].replace(/\s*/g, '')
            },
            teltrim(val) {
                this.telForm[val] = this.telForm[val].replace(/\s*/g, '')
            },
            getPhone() {
                this.onSubmitData(apiGetPhone, null, null, false, false, (res) => {
                    this.telForm.oldPhone = res.data.phone
                })
                if (this.$route.query.queryData == 2) {
                    this.onSubmitData(apiGetEmail, null, null, false, false, (res) => {
                        this.emailForm.oldEmail = res.data.email
                    })
                }
            },
            goBack() {
                this.$router.push('/accountCenter/safetySet')
            },
            sendData(formName) {
        
              switch (formName) {
                case 'emailForm':
              this.$refs[formName].validateField('email',(Error)=>{     
                if(!Error){
                this.innerVisible = true 
                this.showVerity = true
              }
              });
             
              break;
                case 'telForm':

              this.$refs[formName].validateField('phone',(Error)=>{
                if(!Error){
                this.innerVisible = true 
                this.showVerity = true
              }
                
              });
              break;
                case 'setEmailForm':
              this.$refs[formName].validateField('email',(Error)=>{
              if(!Error){
                this.innerVisible = true 
                this.showVerity = true
              }
              
              });
              break;

             
            }
     
            },
            success(val) {
                this.innerVisible = false
                this.showVerity = false
                val == 1 ? this.phoneTime() : this.emailTime(val)
            },
            phoneTime() {
                apiSecureSms({phone:this.telForm.phone}).catch()
                let TIME_COUNT = 60;
                if (!this.timer) {
                    this.count = TIME_COUNT;
                    this.isGrey = true;
                    this.telState = 2;
                    this.dis = true;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= TIME_COUNT) {
                            this.count--;
                        } else {
                            this.dis = false;
                            this.isGrey = false;
                            this.telState = 3;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000);
                }
            },
            emailTime(val) {
                if (val == 2) {
                    apiNewSecureEmail({email: this.emailForm.email}).catch()
                } else {
                    apiNewSecureEmail({email: this.setEmailForm.email}).catch()
                }
                let TWO_TIME_COUNT = 60;
                if (!this.two_timer) {
                    this.two_count = TWO_TIME_COUNT;
                    this.two_isGrey = true;
                    this.two_telState = 2;
                    this.two_dis = true;
                    this.two_timer = setInterval(() => {
                        if (this.two_count > 0 && this.two_count <= TWO_TIME_COUNT) {
                            this.two_count--;
                        } else {
                            this.two_dis = false;
                            this.two_isGrey = false;
                            this.two_telState = 3;
                            clearInterval(this.two_timer);
                            this.two_timer = null;
                        }
                    }, 1000);
                }
            },
            submitForm(formName) {
                this.$refs[formName].validate(async (valid) => {
                    if (valid) {
                        let res
                        let msg
                        switch (formName) {
                            case 'pwdForm':
                                res = await apiUpdatePwd(this.pwdForm).finally(() => (this.confirmBtnLoading = false))
                                msg = '密码设置成功'
                                break
                            case 'telForm':
                                res = await apiSecureUpdateSms(this.telForm).finally(() => (this.confirmBtnLoading = false))
                                msg = '手机号设置成功'
                                break
                            case 'emailForm':
                                res = await apiUpdateEmail(this.emailForm).finally(() => (this.confirmBtnLoading = false))
                                msg = '邮箱设置成功'
                                break
                            case 'pwdProtection':
                                let allQuestion = {
                                    securityQuestion: [this.baseForm.oneQuestion, this.baseForm.twoQuestion, this.baseForm.threeQuestion],
                                    securityAnswer: [this.baseForm.oneAnswer, this.baseForm.twoAnswer, this.baseForm.threeAnswer],
                                };
                                if (this.baseForm.oneQuestion == 'oneId') {
                                    allQuestion.securityQuestion[0] = this.baseForm.orderNumber1
                                }
                                if (this.baseForm.twoQuestion == 'oneId') {
                                    allQuestion.securityQuestion[1] = this.baseForm.orderNumber2
                                }
                                if (this.baseForm.threeQuestion == 'oneId') {
                                    allQuestion.securityQuestion[2] = this.baseForm.orderNumber3
                                }
                                res = await apisaveSecurity(allQuestion).finally(() => (this.confirmBtnLoading = false))
                                msg = '密保设置成功'
                                break
                            case 'setEmailForm':
                                let result = {email: this.setEmailForm.email, smsCode: this.setEmailForm.smsCode}
                                res = await apiAddEmail(result).finally(() => (this.confirmBtnLoading = false))
                                msg = '邮箱添加成功'
                                break
                            default:
                                break
                        }
                        if (res.code == 0) {
                            this.$store.dispatch('user/getUserInfo')
                            this.$msgSuccess(msg)
                            this.state = false
                            localStorage.setItem('successChange', this.state)
                            this.active = 3
                        } else if (res.code == 999976) {
                            this.$msgError(res.msg)
                            this.$router.push('/accountCenter/safetySet')
                        }
                    } else {
                        return false;
                    }
                });
            },
        }
    }
</script>
<style lang="scss" scoped>
    .modify-container {
        height: 80%;
    }

    .main {
        background: #FFFFFF;
        width: 100%;
        height: 100%;
        padding: 100px;

        .main_btn {
            width: 50%;
            margin: 50px auto;
        }

        .changeBtn {
            text-align: center;
        }
    }

    .successPage {
        text-align: center;

        .successTest {
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: bold;
            color: #000000;
            margin-top: 24px;
            margin-bottom: 34px;
        }
    }

    ::v-deep {
        .is-process {
            font-weight: 100;
        }

        .el-step__title.is-finish {
            color: #1B5DEB;
        }

        .el-button--primary {
            background-color: #1B5DEB;
        }
    }
</style>