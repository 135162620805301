<template>
  <div class="moneyAvailable" v-za-loading="isLoading">
    <div class="container">
      <div class="title">
        <span>可用余额</span>
        <el-tooltip effect="light" placement="right-start">
          <div slot="content">
            <div class="orange_font">可用余额:指当前账号内可用于消费的金额</div>
            <div class="grey_font">可用余额 = 现金余额 - 已消费金额 - 提现金额</div>
            <div class="grey_font">可用余额不足时，可向现金余额钱包中充值。</div>
          </div>
          <i class="el-icon-info blue_font"></i>
        </el-tooltip>
      </div>
      <div class="main">
        <div>
          <span class="money">{{ balance.balance }}</span>
          <span>元</span>
        </div>
        <div>
          <el-button type="primary" @click="$router.push('/moneyCenter/expenseManagement/topUp')">充值</el-button>
          <el-button plain @click="$router.push('/moneyCenter/expenseManagement/moneyExtract')">提现</el-button>
        </div>
        <!-- <div class="symbol">=</div>
        <div>
            <div>现金余额</div>
            <div>{{balance.cashBalance}} 元</div>
        </div>
        <div class="symbol">-</div>
        <div>
            <div>欠费金额</div>
            <div>{{balance.frozenBalance}} 元</div>
        </div> -->
      </div>
      <!-- <div class="btn" @click="moneyWanning">费用预警</div> -->
    </div>
    <div>
      <za-dialog top-title="设置预警阈值" :visible.sync="dialogVisible" width="600px">
        <div class="prompt">
          <span style="font-size: 16px; margin-right: 6px">
            <za-svg-icon icon-class="za-wanning"></za-svg-icon>
          </span>
          <span>
            当账户可用余额小于该值时，将以短信方式提醒，每天提醒一次(最多连续提醒5天)，可前往
            <span class="blue_font" @click="$router.push('/msgCenter/msgManagement/msgPersonnel')">消息接收管理</span>
            页面选择关闭
          </span>
        </div>
        <el-form
          ref="form"
          :disabled="confirmBtnLoading"
          :model="baseForm"
          label-position="right"
          label-width="140px"
          :rules="rules"
        >
          <el-form-item label="预警阈值：" prop="amount">
            <el-input v-model="baseForm.amount" @input="limitInput($event, 'amount')"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <za-button :disabled="confirmBtnLoading" :type="buttonTypeObj.GREY" @click="dialogVisible = false">
            {{ translateTitle('btn.cancel') }}
          </za-button>
          <za-button :loading="confirmBtnLoading" :type="buttonTypeObj.BLUE" @click="handleSubmitForm">
            {{ translateTitle('btn.submit') }}
          </za-button>
        </div>
      </za-dialog>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { apiSetWarring } from '@/api/moneyCenter/overView'

  export default {
    data() {
      return {
        baseForm: {
          amount: null,
        },
        rules: {
          amount: [
            {
              required: true,
              trigger: 'blur',
              message: '请输入预警阈值',
            },
          ],
        },
      }
    },
    created() {
      this.getList()
    },

    computed: {
      ...mapGetters({
        balance: 'user/balance',
      }),
    },
    methods: {
      async getList() {
        await this.$store.dispatch('user/getBalanceData')
      },
      limitInput(value, name) {
        let val = '' + value
        val =
          val
            .replace(/[^\d^\.]+/g, '')
            .replace(/^0+(\d)/, '$1')
            .replace(/^\./, '0.')
            .match(/^\d*(\.?\d{0,2})/g)[0] || ''
        this.baseForm[name] = val
      },
      async moneyWanning() {
        //获取预警阈值信息
        let { warningAmount } = await this.$store.dispatch('user/getBalanceData')
        this.baseForm.amount = warningAmount
        this.dialogVisible = true
      },
      handleSubmitForm() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.baseForm.amount = Number(this.baseForm.amount)
            let res = await apiSetWarring(this.baseForm).finally(() => (this.confirmBtnLoading = false))
            if (res.code === 0) {
              this.$msgSuccess('设置成功')
              this.dialogVisible = false
            }
          } else {
            return false
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import '~@/za/styles/mixin.scss';

  .moneyAvailable {
    height: 143px;
    width: 33%;
    padding: 20px;
    background: #ffffff;
    margin-left: 15px;

    .container {
      .title {
        @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
        width: 100%;
        height: 20px;
        margin-bottom: 14px;
      }

      .main {
        display: flex;
        justify-content: space-between;
        font-size: 14px;

        div {
          margin-right: 20px;
        }

        div:first-child {
          span:first-child {
            font-size: 30px;
            font-family: DINAlternate-Bold, DINAlternate;
            font-weight: bold;
            color: #000000;
          }
        }

        .symbol {
          margin-top: 10px;
        }
      }

      .btn {
        font-size: 14px;
        color: #3370ff;
        margin-top: 14px;
        cursor: pointer;
      }
    }

    .prompt {
      width: 100%;
      height: 72px;
      background: #fffbe6;
      border-radius: 2px;
      border: 1px solid #ffe58f;
      padding: 15px 15px;
      margin-bottom: 30px;

      span {
        font-size: 12px;
        margin-bottom: 8px;
        color: #555555;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
    }
  }
</style>