<template>
    <div>
        <div class="title">请选择邮寄地址</div>
        <div class="btn">
            <el-button type="primary"  @click="addAdress">添加邮寄地址</el-button>
        </div>
        <div class="main">
            <occ-table :tableData="tableData" :columObj="columObj" :isOp="columObj.isOp" :redio="columObj.redio"
                       :style-height="'200px'"  @handleSelectionChange="handleSelectionChange">
                <template #default="{row}">
                    <el-button type="primary" @click="clickRowHandle(row)">修改</el-button>
                </template>
            </occ-table>
            <div>
                <span class="tips" style=""><span>*</span>为了更好地为您服务，当前添加的新地址信息，将会同步到我们的邮寄地址库中</span>
                <div class="title">
                    <span>填写发票备注（选填）</span>
                </div>
                <div style="width: 30%">
                    <el-form ref="form" :model="form" label-width="120px" @submit.native.prevent>
                        <el-input type="textarea" v-model="form.desc" :rows="6"
                                  placeholder="填写后打印在发票的备注内容区域" @input="searchData(form.desc)" maxlength="230"></el-input>
                        <span class="tips"><span>*</span>发票备注不可超过 115个字节，一个中文字符为 2 个字节</span>
                    </el-form>
                </div>
            </div>
        </div>
        <div>
            <za-dialog
                    :top-title="dialogType | dialogTitle(dialogTypeObj)"
                    :visible.sync="dialogVisible"
                    width="600px">
                <div>
                    <el-form
                            ref="form"
                            :disabled="confirmBtnLoading"
                            :model="baseForm"
                            label-position="right"
                            label-width="140px"
                            :rules="rules"
                    >
                        <el-form-item :label="'收件人姓名'+ '：'" prop="consignee">
                            <el-input v-model="baseForm.consignee"
                                      :placeholder="`${translateTitle('placeTxt')}`"/>
                        </el-form-item>
                        <el-form-item :label="'邮寄地址'+ '：'" prop="selectedOptions">
                            <el-cascader
                                    size="large"
                                    :options="options"
                                    v-model="selectedOptions"
                                    @change="handleChange"
                                    style="width: 100%"
                            >
                            </el-cascader>
                        </el-form-item>
                        <el-form-item :label="'详细地址'+ '：'" prop="address">
                            <el-input v-model="baseForm.address"
                                      maxlength="99"
                                      @keyup.native="trim('address')"
                                      :placeholder="`${translateTitle('placeTxt')}`" />
                        </el-form-item>
                        <el-form-item :label="'联系方式'+ '：'" prop="mobile">
                            <el-input v-model="baseForm.mobile"
                                      maxlength="11"
                                      @keyup.native="trim('mobile')"
                                      :placeholder="`${translateTitle('placeTxt')}`" />
                        </el-form-item>
                    </el-form>
                </div>
                <div slot="footer">
                    <za-button :disabled="confirmBtnLoading" :type="buttonTypeObj.GREY" @click="dialogVisible = false">
                        {{ translateTitle('btn.cancel') }}
                    </za-button>
                    <za-button :loading="confirmBtnLoading" :type="buttonTypeObj.BLUE" @click="handleSubmitForm">
                        {{ translateTitle('btn.submit') }}
                    </za-button>
                </div>
            </za-dialog>
        </div>
    </div>
</template>
<script>
    import {regionData, CodeToText, TextToCode} from 'element-china-area-data'
    import {apiGetAddressList,apiAddressList,apiUpdateAdd} from "@/api/moneyCenter/invoiceManagement/address"
    import {isPhone} from '@/utils/validate'

    export default {
        data() {
            const validateOrg = (rule, value, callback) => {
                if (!this.selectedOptions.length) {
                    callback(new Error('邮寄地址不能为空'))
                } else {
                    callback()
                }
            }
            const phonePass = (rule, value, callback) => {
                if (value === '') callback(new Error('请填写11位手机号'))
                else if (!isPhone(value)) callback(new Error('手机号码格式不正确，请重新输入'))
                else callback()
            }
            return {
                addressid: '',
                address: '',
                options: regionData,
                selectedOptions: [],
                baseForm: {
                    consignee: '',
                    province:'',
                    city:'',
                    area:'',
                    address:'',
                    mobile:''
                },

                form: { desc: ''},
                rules:{
                    consignee: [{required: true, message: `请输入联系人`, trigger: 'blur'}],
                    selectedOptions: [{ type: 'array', required: true, validator: validateOrg }],
                    address: [{required: true, message: `请输入详细地址`, trigger: 'blur'}],
                    mobile: [{required: true, validator: phonePass, trigger: 'blur'}]
                },
                tableData: [],
                columObj: {
                    // 控制选择框
                    selection: true,
                    redio: false,
                    isOp: true,
                    width: '300px',
                    columnData: [
                        {
                            text: true,
                            prop: "consignee",
                            label: "收件人",
                            width: "",
                            flag: true,
                            align: "center",

                        },
                        {
                            text: true,
                            prop: "mobile",
                            label: "联系方式",
                            flag: true,
                            width: "",
                            align: "center",

                        },
                        {
                            ownDefined: true,
                            prop: "address",
                            label: "收件地址",
                            flag: true,
                            width: "",
                            align: "center",
                            ownDefinedReturn:((row)=>{
                                let province=''
                                let city=''
                                let area=''
                                if(row.province){
                                  province=row.province
                                }else{
                                    province=''
                                }
                                if(row.city){
                                    city=row.city
                                }else{
                                    city=''
                                }
                                if(row.area){
                                    area=row.area
                                }else{
                                    area=''
                                }
                                return province +' '+ city+' ' + area+' '+ row.address
                            })
                        }
                    ],
                },
            }
        },
        created() {
            this.dialogTypeObj.ADD.text = '新增邮寄地址'
            this.dialogTypeObj.EDIT.text = '修改邮寄地址'
            this.getList()
        },
        methods: {
            getList(){
                this.onSubmitData(apiGetAddressList, null, null, false, false, (res) => {
                    this.total=res.data.total
                    if (!res.data.list) {
                        this.tableData = []
                    } else {
                        this.tableData=res.data.list
                    }
                })
            },
            trim(val) {
                this.baseForm[val] = this.baseForm[val].replace(/\s*/g, '')
            },
            clickRowHandle(row) {
                this.dialogType = this.dialogTypeObj.EDIT.type
                this.baseForm = Object.assign({}, row)
                if(this.baseForm.province){
                    let province= TextToCode[this.baseForm.province]
                    this.selectedOptions.push(province.code)
                }
                if(this.baseForm.province){
                    let city= TextToCode[this.baseForm.province][this.baseForm.city]
                    this.selectedOptions.push(city.code)
                }
                if(this.baseForm.province){
                    let area= TextToCode[this.baseForm.province][this.baseForm.city][this.baseForm.area]
                    this.selectedOptions.push(area.code)
                }
                this.orgSubmitDataStr = JSON.stringify(this.baseForm)
                this.dialogVisible=true

            },
            handleSubmitForm() {
                if (this.dialogType === this.dialogTypeObj.EDIT.type && !this.isCanSubmit(this.baseForm)) {
                    this.$msgWarning('您还未对数据进行修改')
                    return
                }
                this.$refs['form'].validate(async (valid) => {
                    if (valid) {
                        let msg =
                            this.dialogType === 1 ? '添加成功' : '修改成功'
                        let res
                        // this.confirmBtnLoading = true
                        if (this.dialogType === 1) {
                            res = await apiAddressList(this.baseForm).finally(() => (this.confirmBtnLoading = false))
                        }else {
                            res = await apiUpdateAdd(this.baseForm).finally(() => (this.confirmBtnLoading = false))
                        }
                        if (res.code === 0) {
                            this.$msgSuccess(msg)
                            this.getList()
                            this.dialogVisible = false
                        }
                    } else {
                        return false
                    }
                })
            },
            handleSelectionChange(val){
                val.forEach((item)=>{
                    if(item){
                        this.$emit('click',{data:val})
                    } else {
                        this.$emit('click',{data:[]})
                    }
                })
            },
            searchData (val){
                if(val){
                    this.$emit('getText',val)
                }
            },
            handleChange(value) {
                if (value[1] != null && value[2] != null) {
                    this.baseForm.province=CodeToText[value[0]]
                    this.baseForm.city=CodeToText[value[1]]
                    this.baseForm.area=CodeToText[value[2]]
                } else {
                    if (value[1] != null) {
                        this.baseForm.province=CodeToText[value[0]]
                        this.baseForm.city=CodeToText[value[1]]
                        this.baseForm.area=''
                    } else {
                        this.baseForm.province=CodeToText[value[0]]
                        this.baseForm.city=''
                        this.baseForm.area=''
                    }
                }
            },
            addAdress(){
                this.dialogType = this.dialogTypeObj.ADD.type
                this.dialogVisible=true
            }
        }
    }
</script>
<style lang="scss" scoped>
    .title {
        margin-bottom: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #000000;
    }

    .main {
        margin-top: 20px;
    }

    .tips {
        margin-top: 20px;
        margin-bottom: 20px;
        display: block;
        span:first-child{
            color: red;
        }
    }
</style>