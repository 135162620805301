<template>
  <div>
    <div v-if="balance" class="ignore_timeIcon" @click="handleEnter">
      <za-svg-icon class-name="svg-homeMore" icon-class="za-home-more" />
    </div>
    <div class="ignore_center" @click="officialWeb">
      <el-image class="pic-error-parent" :src="require('@/assets/home/home_logo.png')" />
    </div>
    <div class="ignore_toHome" @click="$router.push('/')">
      <span>{{ translateTitle('navTop.toHome') + '总览' }}</span>
    </div>
    <div v-if="drawer" class="leftDrawer">
      <el-drawer :visible.sync="drawer" direction="ltr" size="50%">
        <div class="navDrawer">
          <div class="navLeft">
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="vertical" @select="handleSelect">
              <el-menu-item :index="item.path" v-for="(item, index) in menuLeftList" :key="index">
                {{ item.name }}
              </el-menu-item>
            </el-menu>
          </div>
          <div class="navRight">
            <div>
              <div>
                <div class="prompt">
                  <span style="margin: 0 7px 0 16px; font-size: 14px">
                    <za-svg-icon icon-class="za-wanning"></za-svg-icon>
                  </span>
                  <span>
                    温馨提示: 若点击无法跳转，刷新页面即可返回该资源
                    <span style="color: #336ffe; cursor: pointer" @click="reloadPage">刷新页面</span>
                  </span>
                </div>
                <p>
                  <el-input
                    placeholder="请输入搜索关键字"
                    v-model="drawerInput"
                    prefix-icon="el-icon-search"
                    @input="searchRightList"
                    @keyup.enter.native="record(drawerInput)"
                  ></el-input>
                </p>
              </div>
              <div v-if="searchList.length" class="navSearch">
                <span class="search">最近搜索 :</span>
                <span
                  v-for="(item, index) in searchList"
                  :key="index"
                  @click="resordClick(item.name)"
                  class="searchText"
                >
                  {{ item.name }}
                  <span>|</span>
                </span>
              </div>
            </div>
            <div class="searchBtm">
              <div v-for="(item, index) in menuRightList" :key="index" class="navBlock">
                <div class="blockTitle">
                  <za-svg-icon class-name="svg-homeMore" :icon-class="item.icon"></za-svg-icon>
                  <span>{{ item.title }}</span>
                </div>
                <div class="blockText">
                  <p
                    v-for="(value, valindex) in item.children"
                    :key="valindex"
                    @click="goRouter(value.path)"
                    class="blockName"
                  >
                    {{ value.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
  import { getLocation } from '@/utils'
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {
        searchList: [],
        menuLeftList: [
          { id: 1, name: '服务总览', path: '' },
          { id: 2, name: 'BCC云服务器', path: 'esc' },
          { id: 3, name: '云数据库RDS', path: 'mysql' },
          { id: 4, name: 'CDN', path: 'cdn' },
          { id: 5, name: '对象存储', path: 'bos' },
          // {id: 6, name: '域名服务', path: 'bcd'},
          { id: 7, name: '等保无忧', path: '/evaluationActivity' },
        ],
        menuRightList: [
          {
            id: 1,
            title: '云计算',
            children: [
              { name: 'BCC云服务器', path: 'esc' },
              { name: '专属服务器', path: 'dcc' },
              { name: '弹性裸金属服务器', path: 'bbc' },
            ],
            icon: 'za-cloud-sever',
          },
          {
            id: 2,
            title: '存储和CDN',
            children: [
              { name: '对象存储', path: 'bos' },
              { name: '云磁盘', path: 'cds' },
              { name: '内容分发网络CDN', path: 'cdn' },
            ],
            icon: 'za-drawer-cdn',
          },
          {
            id: 4,
            title: '网络',
            children: [
              { name: '弹性公网IP', path: 'eip' },
              { name: '共享宽带', path: 'eipBp' },
              { name: '私有网络VPC', path: 'vpc' },
              { name: '负载均衡', path: 'blb' },
              { name: '智能云解析DNS', path: 'dns' },
            ],
            icon: 'za-drawer-computer',
          },
          {
            id: 5,
            title: '数据库',
            children: [
              { name: '云数据库 RDS', path: 'mysql' },
              // {name: '云数据库RDS tor MySQL', path: 'mysql'},
              // {name: '云数据库RDS for SQL Server', path: 'mysql'},
              // {name: '云数据库RDS tor PostqreSQL', path: 'mysql'},
              { name: '云数据库 SCS for Redis', path: 'mysql' },
              { name: '数据传输服务', path: 'dts' },
            ],
            icon: 'za-drawer-sql',
          },
          {
            id: 6,
            title: '域名与网站',
            children: [
              { name: '域名注册', path: 'bcd' },
              { name: 'SSL证书', path: 'cas' },
              // { name: 'BCM云监控', path: 'bcm' },
            ],
            icon: 'za-domain-websites',
          },
          // {
          //   id: 7,
          //   title: '智能大数据',
          //   children: [{ name: '消息服务 for Kafka', path: 'kafka' }],
          //   icon: 'za-big-data',
          // },
        ],
        copyRightList: [
          {
            id: 1,
            title: '云计算',
            children: [
              { name: 'BCC云服务器', path: 'esc' },
              { name: '专属服务器', path: 'dcc' },
              { name: '弹性裸金属服务器', path: 'esc' },
            ],
            icon: 'za-cloud-sever',
          },
          {
            id: 2,
            title: '存储和CDN',
            children: [
              { name: '对象存储', path: 'bos' },
              { name: '云磁盘', path: 'cds' },
              { name: '内容分发网络CDN', path: 'cdn' },
            ],
            icon: 'za-drawer-cdn',
          },
          {
            id: 4,
            title: '网络',
            children: [
              { name: '弹性公网IP', path: 'eip' },
              { name: '共享宽带', path: 'eipBp' },
              { name: '私有网络VPC', path: 'vpc' },
              { name: '负载均衡', path: 'blb' },
              { name: '智能云解析DNS', path: 'dns' },
            ],
            icon: 'za-drawer-computer',
          },
          {
            id: 5,
            title: '数据库',
            children: [
              { name: '云数据库 RDS', path: 'mysql' },
              // {name: '云数据库RDS tor MySQL', path: 'mysql'},
              // {name: '云数据库RDS for SQL Server', path: 'mysql'},
              // {name: '云数据库RDS tor PostqreSQL', path: 'mysql'},
              { name: '云数据库 SCS for Redis', path: 'mysql' },
              { name: '数据传输服务', path: 'mysql' },
            ],
            icon: 'za-drawer-sql',
          },
          {
            id: 6,
            title: '域名与网站',
            children: [
              { name: '域名注册', path: 'bcd' },
              { name: 'SSL证书', path: 'cas' },
              // { name: 'BCM云监控', path: 'bcm' },
            ],
            icon: 'za-domain-websites',
          },
          // {
          //   id: 7,
          //   title: '智能大数据',
          //   children: [{ name: '消息服务 for Kafka', path: 'kafka' }],
          //   icon: 'za-big-data',
          // },
        ],
        drawer: false,
        activeIndex: '',
        drawerInput: '',
      }
    },
    mounted() {
      this.activeIndex = getLocation('url')
    },
    watch: {
      $route: {
        handler: function (val) {
          this.activeIndex = getLocation('url')
        },
        immediate: true,
        deep: true,
      },
    },
    computed: {
      ...mapGetters({
        token: 'user/token',
        balance: 'user/balance',
      }),
    },
    methods: {
      searchRightList(val) {
        if (!val) {
          this.menuRightList = this.copyRightList
        } else {
          let searchResult = []
          this.menuRightList = this.copyRightList
          this.menuRightList.forEach((item) => {
            item.children.forEach((i) => {
              val = val.toUpperCase()
              if (i.name.search(val) !== -1) {
                searchResult.push({
                  id: item.id,
                  title: item.title,
                  children: [i],
                  icon: item.icon,
                })
              }
            })
          })

          this.menuRightList = this.changeList(searchResult)
        }
      },
      handleEnter() {
        this.drawer = true
      },
      changeList(arr) {
        let newArr = []
        arr.forEach((item) => {
          let _index = newArr.findIndex((e) => {
            return e.title === item.title
          })
          if (_index === -1) {
            newArr.push(item)
          } else {
            let newItem = newArr[_index]
            newItem.children = newItem.children.concat(item.children)
            newArr[_index] = newItem
          }
        })
        return newArr
      },
      record(val) {
        this.searchRightList(val)
        if (this.searchList.length <= 10) {
          this.searchList.push({ name: val })
        }
      },
      resordClick(val) {
        this.drawerInput = val
        this.searchRightList(val)
      },
      officialWeb() {
        window.open(`${process.env.VUE_APP_PORTAL_TARGET_URL}`, '_self')
      },
      handleSelect(tab, event) {
        if (event[0] && event[0] != '/evaluationActivity') {
          this.$router.push({ path: '/business', query: { url: event[0] } })
          this.drawer = false
        } else if (event[0] && event[0] == '/evaluationActivity') {
          this.$router.push({ path: '/evaluationActivity' })
          this.drawer = false
        } else {
          this.$router.push({ path: '/' })
          this.drawer = false
        }
      },
      goRouter(val) {
        if (val === 'bcd') window.open(`${process.env.VUE_APP_PORTAL_TARGET_URL}/products/bcd`, '_self')
        else this.$router.push({ path: '/business', query: { url: val } })
        this.drawer = false
      },
      reloadPage() {
        window.location.reload()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .ignore_timeIcon {
    width: 50px;
    height: 51px;
    background-color: #2468f2;
    cursor: pointer;
    float: left;
    cursor: pointer;

    .svg-homeMore {
      margin: 17px 16px;
    }
  }

  .ignore_center {
    float: left;
    width: 104px;
    padding-top: 10px;
    box-sizing: border-box;
    margin: 0 16px 0 24px;
    cursor: pointer;
  }

  .ignore_toHome {
    padding-left: 10px;
    border-left: 1px solid #f4f6fa;
    float: left;
    font-family: PingFangSC-Medium;
    font-size: 13px;
    color: #333;
    line-height: 50px;
    font-weight: 500;
    cursor: pointer;
  }

  .leftDrawer {
    .navDrawer {
      display: flex;
      height: 100%;

      .navLeft {
        width: 20%;
        height: 100%;
      }

      .navRight {
        padding: 0px 20px;
        width: 100%;

        .navSearch {
          height: 20px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 20px;
          margin-bottom: 20px;

          .search {
            color: #1b5deb;
            margin-right: 20px;
          }

          .searchText {
            color: #999999;
          }

          .searchText:hover {
            color: #3370ff;
          }
        }

        .searchBtm {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;

          .navBlock {
            margin-right: 20%;

            .svg-homeMore {
              margin-right: 10px;
              font-size: 16px;
              vertical-align: middle;
            }

            .blockTitle {
              font-size: 14px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #3370ff;
            }

            .blockText {
              font-size: 12px;
              color: #666666;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 100;
              cursor: pointer;
            }

            .blockName:hover {
              color: #3370ff;
            }
          }
        }
      }
    }
  }

  ::v-deep {
    .el-drawer {
      width: 50% !important;
      margin-top: 51px;
      position: relative;

      .el-drawer__header {
        margin: 0;
        padding: 0;
        position: absolute;
        right: 0;
        top: 10px;
      }
    }

    .el-menu {
      background-color: #f3f4f7;
    }

    .el-input__inner {
      width: 500px !important;
    }

    .is-active {
      background-color: #3370ff;
      color: #ffffff !important;
    }
  }

  .prompt {
    width: 95%;
    height: 40px;
    background: #fffbe6;
    border-radius: 2px;
    border: 1px solid #ffe58f;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 40px;
    margin-top: 25px;
  }
</style>