var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "developer" }, [
    _c("div", { staticClass: "title" }, [_vm._v("开发者资源")]),
    _c(
      "div",
      { staticStyle: { display: "flex" } },
      _vm._l(_vm.list, function (item, index) {
        return _c("div", { key: index, staticClass: "loperName" }, [
          _vm._v(_vm._s(item.name)),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }