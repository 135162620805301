<template>
    <div class="allMsg-container" v-za-loading="isLoading">
        <div class="title">{{title}}</div>
        <div>
            <za-query-form>
                <za-query-form-left-panel :span="24">
                    <el-form :inline="true" :model="pageObj" @submit.native.prevent>
                        <el-input v-model="pageObj.keyword"
                                  placeholder="请输入搜素关键字">
                            <el-button slot="append" icon="el-icon-search" @click="getList()"></el-button>
                        </el-input>
                    </el-form>
                </za-query-form-left-panel>
                <za-query-form-left-panel :span="24">
                    <za-tabs :tabArray="timeList" :handleActive="timeList[0]?.key" @click="changeTabs" :showBorder="false"></za-tabs>
                </za-query-form-left-panel>
                <za-query-form-left-panel :span="24">
                    <el-button type="primary" @click="markRead" :disabled="multipleSelection.length===0||markReadState">
                        标记为已读
                    </el-button>
                    <el-button type="primary" @click="allRead" :disabled="tableData.length===0||allReadSate">全部已读
                    </el-button>
                    <el-button type="primary" @click="markDel" :disabled="multipleSelection.length===0">删除
                    </el-button>
                    <el-button type="primary" @click="allDel" :disabled="tableData.length===0">删除全部
                    </el-button>
                </za-query-form-left-panel>
            </za-query-form>
        </div>
        <div>
            <occ-table :tableData="tableData" :columObj="columObj"
                       @handleSelectionChange="handleSelectionChange" :isOp="columObj.isOp"
                       ref="occTable" @handleDesc="handleDesc">
            </occ-table>
            <pagination
                    :limit.sync="pageObj.pageSize"
                    :page.sync="pageObj.page"
                    :total="total"
                    @pagination="getList"
            />
        </div>
    </div>
</template>
<script>
    import {apiMsgDel, apiMsgList, apiMsgRead,apiMsgConentType,apiMsgReadAll,apiMsgDelAll} from '@/api/msgCenter/msgIndex'
    import zaTabs from '@/za/components/zaTabs'

    export default {
        components: {zaTabs},
        data() {
            return {
                total: 0,
                title: "",
                pageObj: {
                    readAt: '',
                    page: 1,
                    pageSize: 10,
                    msgId: '',
                    keyword: ''
                },
                timeList: [

                ],
                tableData: [],
                multipleSelection: [],
                columObj: {
                    // 控制选择框
                    selection: true,
                    isOp: false,
                    width: '300px',
                    columnData: [
                        {
                            desc: true,
                            prop: "title",
                            label: "消息标题",
                            width: "",
                            flag: true,
                            align: "center",

                        },
                        {
                            status: true,
                            prop: "readAt",
                            label: "消息状态",
                            flag: true,
                            width: "",
                            align: "center",
                            disabledText: '未读',
                            enabledText: '已读',
                        },
                        {
                            ownDefined: true,
                            prop: "conentType",
                            label: "消息类型",
                            flag: true,
                            width: "",
                            align: "center",
                            ownDefinedReturn:(row)=>{
                              let result = this.timeList.filter(item => {
                                return item.key == row.conentType
                              })
                              return result.length > 0 ? result[0].name : '-'
                            }
                        },
                        {
                            text: true,
                            prop: "receivedTime",
                            label: "接收时间",
                            flag: true,
                            width: "",
                            align: "center",
                        }
                    ],
                },
            }
        },
        created() {
            this.title = this.$route.meta.title
            this.getType()
        },
        computed: {
            markReadState: function () {
                let result = this.multipleSelection.filter((item) => {
                    return item.readAt == 0
                })
                if (result.length) {
                    return false
                }else{
                    return true
                }
            },
            allReadSate: function () {
                let result = this.tableData.filter((item) => {
                    return item.readAt !=1
                })
                if (result.length) {
                    return false
                }else{
                    return true
                }
            },
        },
        methods: {
            getType(){
                this.onSubmitData(apiMsgConentType, {read:'0'}, null, false, false, (res) => {
                    this.timeList=[]
                    res.data.conentTypes.forEach((item)=>{
                        this.timeList.push(item)
                    })
                    this.getList()
                })
            },
            getList() {
                this.onSubmitData(apiMsgList, this.pageObj, null, false, false, (res) => {
                    this.total = res.data.total
                    this.tableData = res.data.messageList
                })
            },
            changeTabs(val) {
                this.pageObj.msgId=val.name;
                this.getList()
            },
         async   markDel() {
                let data = {ids: []}
                const tips = '是否删除勾选的消息？'
                const deleteSuccess = '删除成功'
                data.ids = this.multipleSelection.map(item => item.id)
                data.ids.length && this.$baseConfirm(tips, null, () => this.onDelete(apiMsgDel, data, deleteSuccess))
                await this.$store.dispatch('user/getMsgData')
         },
            async markRead() {
                let data = {ids: []}
                const tips = '是否标记为已读？'
                const deleteSuccess = '已修改状态为已读'
                data.ids = this.multipleSelection.map(item => item.id)
                data.ids.length && this.$baseConfirm(tips, null, () => this.onDelete(apiMsgRead, data, deleteSuccess))
                await this.$store.dispatch('user/getMsgData')
            },
            async  allRead(){
                const tips = '是否全部标记为已读？'
                const deleteSuccess = '已修改状态为已读'
                this.$baseConfirm(tips, null, () => this.onDelete(apiMsgReadAll, null, deleteSuccess))
                await this.$store.dispatch('user/getMsgData')

            },
            async  allDel(){
                const tips = '是否全部删除？'
                const deleteSuccess = '删除成功'
                this.$baseConfirm(tips, null, () => this.onDelete(apiMsgDelAll, {read: 0}, deleteSuccess))
                await this.$store.dispatch('user/getMsgData')

            },
            handleDesc(row) {
                this.$router.push({path:'/msgCenter/stationMsg/allmsg/details',query:{id:row.id}})
            },
            handleSelectionChange(val) {
                this.multipleSelection = val
            },
        }
    }
</script>
<style lang="scss" scoped>
    @import '~@/za/styles/mixin.scss';

    .title {
        @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
        width: 56px;
        height: 20px;
        margin-bottom: 20px;
    }

    ::v-deep {
        .el-form-item__label {
            text-align: left;
        }
    }
    ::v-deep {
        .el-table__row {
             .enable {
                background-color: #d4d4d4 !important;
            }
        }
    }

</style>