import dayjs from 'dayjs'

dayjs.locale('zh-cn')

export function getTimestampRange(type) {
    let timestampRange = [Math.round(new Date() / 1000)]
    switch (type) {
        case 1:
            //最近一小时
            timestampRange.unshift(dayjs().subtract(1, 'hour').unix())
            break
        case 2:
            //最近六小时
            timestampRange.unshift(dayjs().subtract(6, 'hour').unix())
            break
        case 3:
            // 本日
            timestampRange.unshift(dayjs().startOf('day').unix())
            break
        case 4:
            // 昨日
            timestampRange.unshift(new Date(new Date().toLocaleDateString()).getTime() / 1000 - 3600 * 24)
            // timestampRange.unshift(dayjs().subtract(1, 'day').unix())
            timestampRange[1] = dayjs().startOf('day').unix() - 1
            break
        case 5:
            // 本周
            timestampRange.unshift(dayjs().startOf('week').unix() + 3600 * 24)
            break
        case 6:
            // 本月
            timestampRange.unshift(dayjs().startOf('month').unix())
            break
        case 7:
            // 最近5分钟
            timestampRange.unshift(dayjs().subtract(5, 'minutes').unix())
            break
        case 8:
            // 最近30分钟
            timestampRange.unshift(dayjs().subtract(30, 'minutes').unix())
            break
        case 9:
            // 最近半年
            timestampRange.unshift(dayjs().subtract(6, 'months').unix())
            break
        case 10:
            // 最近一年
            timestampRange.unshift(dayjs().subtract(1, 'years').unix())
            break
        case 11:
            // 最近一周
            timestampRange.unshift(dayjs().subtract(1, 'weeks').unix())
            break
        case 12:
            // 最近一个月
            timestampRange.unshift(dayjs().subtract(1, 'months').unix())
            break
        case 13:
            // 最近三个月
            timestampRange.unshift(dayjs().subtract(3, 'months').unix())
            break
    }
    return timestampRange
}
