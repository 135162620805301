var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "title" }, [_vm._v("请选择邮寄地址")]),
    _c(
      "div",
      { staticClass: "btn" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.addAdress } },
          [_vm._v("添加邮寄地址")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c("occ-table", {
          attrs: {
            tableData: _vm.tableData,
            columObj: _vm.columObj,
            isOp: _vm.columObj.isOp,
            redio: _vm.columObj.redio,
            "style-height": "200px",
          },
          on: { handleSelectionChange: _vm.handleSelectionChange },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ row }) {
                return [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.clickRowHandle(row)
                        },
                      },
                    },
                    [_vm._v("修改")]
                  ),
                ]
              },
            },
          ]),
        }),
        _c("div", [
          _vm._m(0),
          _vm._m(1),
          _c(
            "div",
            { staticStyle: { width: "30%" } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.form, "label-width": "120px" },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 6,
                      placeholder: "填写后打印在发票的备注内容区域",
                      maxlength: "230",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.searchData(_vm.form.desc)
                      },
                    },
                    model: {
                      value: _vm.form.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "desc", $$v)
                      },
                      expression: "form.desc",
                    },
                  }),
                  _c("span", { staticClass: "tips" }, [
                    _c("span", [_vm._v("*")]),
                    _vm._v(
                      "发票备注不可超过 115个字节，一个中文字符为 2 个字节"
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "za-dialog",
          {
            attrs: {
              "top-title": _vm._f("dialogTitle")(
                _vm.dialogType,
                _vm.dialogTypeObj
              ),
              visible: _vm.dialogVisible,
              width: "600px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogVisible = $event
              },
            },
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      disabled: _vm.confirmBtnLoading,
                      model: _vm.baseForm,
                      "label-position": "right",
                      "label-width": "140px",
                      rules: _vm.rules,
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "收件人姓名" + "：",
                          prop: "consignee",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: `${_vm.translateTitle("placeTxt")}`,
                          },
                          model: {
                            value: _vm.baseForm.consignee,
                            callback: function ($$v) {
                              _vm.$set(_vm.baseForm, "consignee", $$v)
                            },
                            expression: "baseForm.consignee",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "邮寄地址" + "：",
                          prop: "selectedOptions",
                        },
                      },
                      [
                        _c("el-cascader", {
                          staticStyle: { width: "100%" },
                          attrs: { size: "large", options: _vm.options },
                          on: { change: _vm.handleChange },
                          model: {
                            value: _vm.selectedOptions,
                            callback: function ($$v) {
                              _vm.selectedOptions = $$v
                            },
                            expression: "selectedOptions",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "详细地址" + "：", prop: "address" } },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: "99",
                            placeholder: `${_vm.translateTitle("placeTxt")}`,
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              return _vm.trim("address")
                            },
                          },
                          model: {
                            value: _vm.baseForm.address,
                            callback: function ($$v) {
                              _vm.$set(_vm.baseForm, "address", $$v)
                            },
                            expression: "baseForm.address",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "联系方式" + "：", prop: "mobile" } },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: "11",
                            placeholder: `${_vm.translateTitle("placeTxt")}`,
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              return _vm.trim("mobile")
                            },
                          },
                          model: {
                            value: _vm.baseForm.mobile,
                            callback: function ($$v) {
                              _vm.$set(_vm.baseForm, "mobile", $$v)
                            },
                            expression: "baseForm.mobile",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { attrs: { slot: "footer" }, slot: "footer" },
              [
                _c(
                  "za-button",
                  {
                    attrs: {
                      disabled: _vm.confirmBtnLoading,
                      type: _vm.buttonTypeObj.GREY,
                    },
                    on: {
                      click: function ($event) {
                        _vm.dialogVisible = false
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.translateTitle("btn.cancel")) + " ")]
                ),
                _c(
                  "za-button",
                  {
                    attrs: {
                      loading: _vm.confirmBtnLoading,
                      type: _vm.buttonTypeObj.BLUE,
                    },
                    on: { click: _vm.handleSubmitForm },
                  },
                  [_vm._v(" " + _vm._s(_vm.translateTitle("btn.submit")) + " ")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "tips" }, [
      _c("span", [_vm._v("*")]),
      _vm._v(
        "为了更好地为您服务，当前添加的新地址信息，将会同步到我们的邮寄地址库中"
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("填写发票备注（选填）")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }