<template>
    <div>
        <div v-if="showBorder" class="ab_border" :style="[{ top: top+'px' }]"></div>
        <el-tabs :value="handleActive" @tab-click="handleClick">
            <el-tab-pane :label="item.name+(item.value?'('+item.value+')':'')" :name="item.key" v-for="(item,index) in tabArray" :key="index">
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
    export default {
        props: {
            handleActive: {
                type:String,
                 default:''
            },
            tabArray: {
                type: Array,
                default: () => []
            },
            showBorder:{
                type:Boolean,
                default:true
            },
            top:{
                type:String,
                default:'80'
            }
        },
        data() {
            return {
                activeName: 'second'
            };
        },
        methods: {
            handleClick(tab) {
                this.$emit('click',tab)
            }
        }
    };
</script>
<style lang="scss" scoped>
.ab_border{
    position: absolute;
    border-bottom: 2px solid rgb(221,224,234) ;
    width: 100%;

}
</style>