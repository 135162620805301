var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "za-loading",
          rawName: "v-za-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "myHomeResources",
    },
    [
      _c("div", { staticClass: "title" }, [_vm._v("我的资源")]),
      _c(
        "div",
        { staticStyle: { display: "flex" } },
        _vm._l(_vm.list, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "listStyle",
              on: {
                click: function ($event) {
                  return _vm.path(item.path)
                },
              },
            },
            [
              _c("p", { staticStyle: { margin: "0" } }, [
                _c(
                  "span",
                  [
                    _c("za-svg-icon", {
                      attrs: {
                        "icon-class": item.icon,
                        "class-name": "svg-item",
                      },
                    }),
                  ],
                  1
                ),
                _c("span", [_vm._v(_vm._s(item.name))]),
              ]),
              _c("p", { staticClass: "listNum" }, [
                index == 0
                  ? _c("span", [_vm._v(_vm._s(_vm.listNum.bccNumber))])
                  : _vm._e(),
                index == 1
                  ? _c("span", [_vm._v(_vm._s(_vm.listNum.bosNumber))])
                  : _vm._e(),
                index == 2
                  ? _c("span", [_vm._v(_vm._s(_vm.listNum.bcdNumber))])
                  : _vm._e(),
                index == 3
                  ? _c("span", [_vm._v(_vm._s(_vm.listNum.cdnNumber))])
                  : _vm._e(),
                index == 4
                  ? _c("span", [_vm._v(_vm._s(_vm.listNum.casNumber))])
                  : _vm._e(),
                index == 5
                  ? _c("span", [_vm._v(_vm._s(_vm.listNum.rdsNumber))])
                  : _vm._e(),
              ]),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }