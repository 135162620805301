import { render, staticRenderFns } from "./effective.vue?vue&type=template&id=a811e28a"
import script from "./effective.vue?vue&type=script&lang=js"
export * from "./effective.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/.npm/za-console-portal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a811e28a')) {
      api.createRecord('a811e28a', component.options)
    } else {
      api.reload('a811e28a', component.options)
    }
    module.hot.accept("./effective.vue?vue&type=template&id=a811e28a", function () {
      api.rerender('a811e28a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/companyManagement/effect/backOrder/components/effective.vue"
export default component.exports