<template>
  <div class="bg-frame">
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'ZaBgFrame',
    props: {
      width: {
        type: String,
        default: '480px',
      },
    },
  }
</script>

<style lang="scss" scoped>
  .bg-frame {
    width: 100%;
    height: 100%;

  }
</style>
