var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "resources" }, [
    _c("div", { staticClass: "title" }, [_vm._v("资源预警")]),
    _c(
      "div",
      { staticStyle: { display: "grid", "grid-template-columns": "1fr 1fr" } },
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "resourcesText" },
          [
            _c("za-svg-icon", {
              attrs: { "icon-class": item.icon, "class-name": "icon" },
            }),
            _c("span", [_vm._v(_vm._s(item.name))]),
            _c(
              "span",
              { staticStyle: { color: "red", "margin-left": "3px" } },
              [_vm._v(_vm._s("(" + item.num + ")"))]
            ),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }