var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "ignore_center" }, [
        _c("span", [
          _vm._v(
            " Copyright©2023 深圳市智安网络有限公司 All Rights Reve 版权所有 《中华人民共和国增值电信业务经营许可证》编号：B1-20190666 "
          ),
          _c(
            "a",
            {
              staticClass: "blue_font",
              attrs: {
                href: "https://beian.miit.gov.cn/#/Integrated/index",
                target: "_blank",
              },
            },
            [_vm._v("粤ICP备18027011号")]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }