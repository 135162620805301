var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "oauth" } }, [
    _c("img", { attrs: { src: require("@/assets/hourglass.png"), alt: "" } }),
    _c("h3", [_vm._v("LOADING " + _vm._s(_vm.ellipsis))]),
    _c("h2", [_vm._v("正在进入系统")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }