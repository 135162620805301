var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "overView-center_bg" }, [
    _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v(_vm._s(_vm.title))]),
    ]),
    _c("div", { staticClass: "center" }, [
      _c(
        "div",
        { staticClass: "centerHeard" },
        [_c("heard"), _c("proportion")],
        1
      ),
      _c(
        "div",
        { staticClass: "overView-top" },
        [_c("promote-customers"), _c("rebate-profile")],
        1
      ),
      _c("div", [_c("foote")], 1),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }