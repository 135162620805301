<template>
  <div class="successPay">
    <navbar />
    <div class="successPage">
      <div class="successIcon"><i class="el-icon-success" style="color: green"></i></div>
      <div class="title">支付成功</div>
      <div class="bullshit-return-overview" v-if="seleType == '2'">
        <router-link to="/moneyCenter/overview">{{ jumpTime + 's' }}&nbsp;{{ btn }}</router-link>
      </div>
      <div class="bullshit-return-overview" v-if="seleType == '1'">
        <router-link to="/">{{ jumpTime + 's' }}&nbsp;{{ dingbtn }}</router-link>
      </div>
      <div class="btn" v-if="seleType == '1'">
        <el-button type="primary" @click="$router.push('/moneyCenter/orderManagement/myOrder')">进入我的订单</el-button>
        <el-button plain @click="$router.push('/')">进入首页</el-button>
      </div>
      <div class="btn" v-if="seleType == '2'">
        <el-button type="primary" @click="$router.push('/moneyCenter/overview')">进入财务总览</el-button>
        <el-button plain @click="$router.push('/moneyCenter/expenseManagement/rechargeRecord')">查看充值记录</el-button>
      </div>
    </div>
  </div>
</template>
<script>
  import Navbar from '@/za/layout/components/Navbar'

  export default {
    components: {
      Navbar,
    },
    data() {
      return {
        jumpTime: 5,
        btn: '返回财务总览',
        dingbtn: '返回首页',
        timer: 0,
        seleType: null,
      }
    },
    mounted() {
      if (this.$route.query.type) {
        this.seleType = this.$route.query.type
        this.timeChange(this.seleType)
      }
    },
    beforeDestroy() {
      clearInterval(this.timer)
    },
    methods: {
      timeChange(val) {
        this.timer = setInterval(() => {
          if (this.jumpTime) {
            this.jumpTime--
          } else {
            if (val == '2') {
              this.$router.push('/moneyCenter/overview')
            } else {
              this.$router.push('/')
            }
            clearInterval(this.timer)
          }
        }, 1000)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .successPay {
    position: relative;
    min-height: 100vh;

    .successPage {
      position: absolute;
      top: 55%;
      left: 50%;
      width: 40vw;
      height: 400px;
      transform: translate(-50%, -50%);

      .successIcon {
        text-align: center;
        font-size: 50px;
      }

      .title {
        font-family: 'Arial Normal', 'Arial';
        font-weight: 400;
        font-style: normal;
        font-size: 25px;
        text-align: center;
        margin-bottom: 50px;
      }

      .main {
        width: 100%;
        height: 100px;
        padding: 20px 0px 20px 80px;
        background: rgba(248, 248, 248, 1);
        font-size: 14px;
        display: grid;
        grid-template-columns: 1fr 1fr;

        div {
          margin-right: 20px;
        }
      }

      .btn {
        margin-top: 50px;
        text-align: center;
      }
    }

    .bullshit-return-overview {
      text-align: center;
      width: 140px;
      height: 36px;
      font-size: 14px;
      line-height: 36px;
      /*background: green;*/
      border-radius: 40px;
      border: 1px solid #999999;
      margin: auto;

      a {
        text-decoration: none;
        color: #000000;
      }
    }
  }
</style>