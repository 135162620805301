var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "za-loading",
          rawName: "v-za-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "loginLog-container",
    },
    [
      _c("div", { staticClass: "title" }, [_vm._v("登录日志")]),
      _c("occ-table", {
        attrs: {
          tableData: _vm.tableData,
          columObj: _vm.columObj,
          isOp: _vm.columObj.isOp,
        },
      }),
      _c("pagination", {
        attrs: {
          limit: _vm.pageObj.pageSize,
          page: _vm.pageObj.page,
          total: _vm.total,
        },
        on: {
          "update:limit": function ($event) {
            return _vm.$set(_vm.pageObj, "pageSize", $event)
          },
          "update:page": function ($event) {
            return _vm.$set(_vm.pageObj, "page", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }