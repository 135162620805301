import {recordRoute} from '@/config'
// import { hasPermission } from '@/utils/permission'
import {isExternal} from '@/utils/validate'
import {resolve} from 'path'

/**
 * 根据path路径获取matched
 * @param routes 菜单routes
 * @param name 路由名
 * @returns {*} matched
 */
export function handleMatched(routes, name) {
  return routes
    .filter((route) => route.childrenNameList.indexOf(name) + 1)
    .flatMap((route) => (route.children ? [route, ...handleMatched(route.children, name)] : [route]))
}

/**
 * @description 根据roles数组拦截路由
 * @param routes 路由
 * @param baseUrl 基础路由
 * @returns {[]}
 */
export function filterRoutes(routes, baseUrl = '/') {
  return (
    routes
      .map((route) => {
        route = {...route}
        route.path = route.path !== '*' && !isExternal(route.path) ? resolve(baseUrl, route.path) : route.path
        if (route.children && route.children.length > 0) {
          route.children = filterRoutes(route.children, route.path)
          if (route.children.length > 0) {
            route.childrenNameList = route.children.flatMap((_) => _.childrenNameList)
            if (!route.redirect)
              route.redirect = route.children[0].redirect ? route.children[0].redirect : route.children[0].path
          }
        } else route.childrenNameList = [route.name]
        return route
      })
  )
}

export function filterCacheRoutes(routes) {
  const cacheList = []
  for (const route of routes) {
    if (!route.children) continue
    cacheList.push(
      ...route.children.flatMap((childRoute) => {
        if (!childRoute.meta.noCache) return childRoute.name
        else return ''
      })
    )
  }
  return [...new Set(cacheList)]
}

/**
 * 获取当前跳转登录页的Route
 * @param currentPath 当前页面地址
 */
export function toLoginRoute(currentPath,flag=true) {
  if (recordRoute && currentPath !== '/')
    return {
      path: '/login',
      query: {redirect: currentPath,toHomeFlag:flag},
      replace: true,
    }
  else return {path: '/login', replace: true}
}
