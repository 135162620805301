var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    _vm._g(
      _vm._b(
        {
          ref: "za_tabledata",
          attrs: {
            "cell-style": _vm.cellStyle,
            "header-cell-style": _vm.headerRowStyle,
            height: _vm.styleHeight || _vm.tableHeight,
          },
        },
        "el-table",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }