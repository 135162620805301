<template>
  <div>
    <div class="order_search">
      <el-form :inline="true" :model="pageObj" @submit.native.prevent>
        <el-form-item label="支付时间:">
          <el-date-picker
            v-model="pickTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="datePickEnd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="订单类型:" label-width="120px">
          <el-select v-model="pageObj.orderType" @change="handleQuery">
            <el-option v-for="item in typeOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户类型:" label-width="120px">
          <el-select v-model="pageObj.userType" @change="handleQuery">
            <el-option v-for="item in customTypeOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form :inline="true" :model="pageObj" @submit.native.prevent>
        <el-form-item label-width="100px">
          <el-input v-model="pageObj.keyword" placeholder="输入购买者ID查找" />
        </el-form-item>
        <el-form-item>
          <za-button :icon="buttonIconObj.SEARCH" @click="handleQuery">{{ translateTitle('btn.query') }}</za-button>
          <za-button :icon="buttonIconObj.RESET" @click="handleResetQuery" type="info">
            {{ translateTitle('btn.reset') }}
          </za-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <occ-table :tableData="tableData" :columObj="columObj" :isOp="columObj.isOp"></occ-table>
      <pagination :limit.sync="pageObj.pageSize" :page.sync="pageObj.pageNo" :total="total" @pagination="getList" />
    </div>
  </div>
</template>
<script>
  import { apiPopularizeOrderList } from '@/api/management/backOrder'

  export default {
    props: {
      tag: {
        type: Number,
        default: 1,
      },
    },
    data() {
      return {
        pageObj: {
          pageNo: 1,
          pageSize: 10,
          StartAt: 0,
          EndAt: 0,
          orderType: 0,
          userType: 0,
          keyword: '',
        },
        pickTime: [],
        typeOptions: [
          { id: 0, name: '全部' },
          { id: 1, name: '新客户订单' },
          { id: 2, name: '老客户订单' },
        ],
        customTypeOptions: [
          { id: 0, name: '全部' },
          { id: 1, name: '企业用户' },
          { id: 2, name: '个人用户' },
        ],
        tableData: [],
        total: 0,
        columObj: {
          // 控制选择框
          selection: false,
          isOp: false,
          width: '300px',
          columnData: [
            {
              text: true,
              prop: 'productName',
              label: '产品名称',
              width: '',
              flag: true,
              align: 'center',
            },
            {
              text: true,
              prop: 'uuid',
              label: '购买者ID',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              text: true,
              prop: 'orderNumber',
              label: '订单号',
              flag: true,
              width: '300',
              align: 'center',
            },
            {
              enumType: 'orderType',
              prop: 'orderType',
              label: '订单类型',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              enumType: 'userTypeOther',
              prop: 'userType',
              label: '客户类型',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              ownDefined: true,
              prop: 'orderPrice',
              label: '订单金额',
              flag: true,
              width: '',
              align: 'center',
              ownDefinedReturn: (row) => {
                return `￥${row.orderPrice}`
              },
            },
            {
              ownDefined: true,
              prop: 'commissionRate',
              label: '总返佣比例',
              flag: true,
              width: '',
              align: 'center',
              ownDefinedReturn: (row) => {
                return `${row.commissionRate}%`
              },
            },
            {
              ownDefined: true,
              prop: 'commission',
              label: '推广佣金',
              flag: true,
              width: '',
              align: 'center',
              ownDefinedReturn: (row) => {
                return `￥${row.commission}`
              },
            },

            {
              text: true,
              prop: 'payAt',
              label: '订单支付时间',
              flag: true,
              width: '',
              align: 'center',
            },
          ],
        },
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.onSubmitData(apiPopularizeOrderList, this.pageObj, null, false, false, (res) => {
          res.data.list ? (this.tableData = res.data.list) : (this.tableData = [])
          this.total = res.data.total
        })
      },
      handleQuery() {
        this.getList()
      },
      handleResetQuery() {
        this.pickTime = []
        this.pageObj = {
          pageNo: 1,
          pageSize: 10,
          StartAt: 0,
          EndAt: 0,
          orderType: 0,
          userType: 0,
          keyword: '',
        }
        this.getList()
      },
      datePickEnd(date) {
        if (date) {
          this.pageObj.StartAt = this.$changeTimeStamp(date[0])
          this.pageObj.EndAt = this.$changeTimeStamp(date[1]) + 3600 * 24 - 1
        } else {
          this.pageObj.StartAt = 0
          this.pageObj.EndAt = 0
        }
        this.getList()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .order_search {
    display: flex;
    justify-content: space-between;
  }
</style>