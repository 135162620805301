/**
 * @description 路由拦截状态管理，目前两种模式：all模式与intelligence模式，其中partialRoutes是菜单暂未使用
 */
import {changeRoutes, constantRoutes, convertRouter, resetRouter} from '@/router'
import {filterCacheRoutes, filterRoutes} from '@/utils/routes'
import {composeTree} from "@/utils";

const state = () => ({
  routes: [],
  activeName: '',
  cachedRoutes: [],
  permissions: [],
})
const getters = {
  routes: (state) => state.routes,
  activeName: (state) => state.activeName,
  cachedRoutes: (state) => state.cachedRoutes,
  permissions: (state) => state.permissions,
}
const mutations = {
  /**
   * @description 多模式设置路由
   * @param {*} state
   * @param {*} routes
   */
  setRoutes(state, routes) {
    state.routes = routes
  },
  /**
   * @description 设置缓存页面
   * @param {*} state
   * @param {*} routes
   */
  setCachedRoutes(state, routes) {
    state.cachedRoutes = routes
  },
  /**
   * @description 设置权限列表
   * @param {*} state
   * @param {*} permissions
   */
  setPermissions(state, permissions) {
    state.permissions = permissions
  },
}
const actions = {
  /**
   * @description 多模式设置路由
   * @param {*} { commit }
   * @param mode
   * @returns
   */
  async setRoutes({commit}, rules) {
    const curPermissions = []
    const treeRules = composeTree(changeRoutes(rules, curPermissions))
    commit('setPermissions', curPermissions)//按钮权限
    // 默认前端路由
    let routes = []
    routes = convertRouter(treeRules)
    // 根据权限和rolesControl过滤路由
    const accessRoutes = filterRoutes([...constantRoutes, ...routes])
    const cachedRoutes = filterCacheRoutes(accessRoutes)
    commit('setCachedRoutes', cachedRoutes)
    // 设置菜单所需路由
    // console.log(accessRoutes,'<<<accessRoutes')
    commit('setRoutes', JSON.parse(JSON.stringify(accessRoutes)))
    // 根据可访问路由重置Vue Router
    await resetRouter(accessRoutes)
  },
}
export default {state, getters, mutations, actions}
