var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "slide-verify",
      style: { width: _vm.w + "px" },
      attrs: { id: "slideVerify", onselectstart: "return false;" },
    },
    [
      _c("div", { class: { "slider-verify-loading": _vm.loadBlock } }),
      _c("canvas", { ref: "canvas", attrs: { width: _vm.w, height: _vm.h } }),
      _vm.show
        ? _c("div", {
            staticClass: "slide-verify-refresh-icon",
            on: { click: _vm.refresh },
          })
        : _vm._e(),
      _c("canvas", {
        ref: "block",
        staticClass: "slide-verify-block",
        attrs: { width: _vm.w, height: _vm.h },
      }),
      _c(
        "div",
        {
          staticClass: "slide-verify-slider",
          class: {
            "container-active": _vm.containerActive,
            "container-success": _vm.containerSuccess,
            "container-fail": _vm.containerFail,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "slide-verify-slider-mask",
              style: { width: _vm.sliderMaskWidth },
            },
            [
              _c(
                "div",
                {
                  staticClass: "slide-verify-slider-mask-item",
                  style: { left: _vm.sliderLeft },
                  on: {
                    mousedown: _vm.sliderDown,
                    touchstart: _vm.touchStartEvent,
                    touchmove: function ($event) {
                      return _vm.handleMoveEvent($event, "touch")
                    },
                    touchend: function ($event) {
                      return _vm.handleMoveEndEvent($event, "touch")
                    },
                  },
                },
                [
                  _c("div", {
                    staticClass: "slide-verify-slider-mask-item-icon",
                  }),
                ]
              ),
            ]
          ),
          _c("span", { staticClass: "slide-verify-slider-text" }, [
            _vm._v(_vm._s(_vm.sliderText)),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }