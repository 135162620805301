var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c("div", { staticClass: "heardBlock" }, [
        _c(
          "div",
          { staticClass: "ignore_center", on: { click: _vm.officialWeb } },
          [
            _c("el-image", {
              staticClass: "pic-error-parent",
              attrs: { src: require("@/assets/home/home_logo.png") },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "title" }, [_vm._v("智安隐私条款")]),
      ]),
    ]),
    _c("div", { staticClass: "main" }, [
      _c(
        "span",
        {
          staticClass: "blue_font",
          on: {
            click: function ($event) {
              return _vm.$router.push("/login")
            },
          },
        },
        [_vm._v("返回")]
      ),
      _vm._m(0),
      _vm._m(1),
      _c("div", { staticClass: "center" }, [
        _c("span", [
          _vm._v(
            " 我们深知个人信息对您的重要性，也深知为您的信息提供有效保护是我们业务健康可持续发展的基石。感谢您对【智安云】的使用和信任！我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。我们郑重承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。 "
          ),
        ]),
        _c("br"),
        _c("h5", [_vm._v("本协议的适用范围")]),
        _c("p", [
          _vm._v(
            " 欢迎您使用【智安云】提供的服务！深圳市智安网络有限公司（以下简称“智安”或者“我们”）非常重视您的隐私和个人信息保护。除本隐私政策中另有说明以外，本隐私政策适用于您通过任何方式对【智安云】提供的各项服务的访问和使用。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 您可以通过【智安云】获取【云安全、云链+、数据中心与网络、安全服务、产品数字化赋能、基础云、人工智能、智能大数据、物联网、区块链、专有云等产品和技术服务】（以下统称“【智安云】产品或服务”）。我们仅会处理您使用产品或服务所需的必要个人信息。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            "本隐私政策适用于您通过【智安云】的【网页端】来访问和使用我们的产品和服务。"
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 本隐私政策不适用于我们作为受委托人，根据委托人的委托要求处理您的个人信息、用户数据或业务数据。我们将与委托人签订协议，在确保不低于委托人要求的个人信息保护水准的前提下为委托人提供数据处理服务。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 本隐私政策不适用于有单独的隐私政策且未纳入本隐私政策的第三方，通过我们向您提供产品或服务(“第三方服务”)。 您使用这些第三方服务(包括您向这些第三方提供的任何含个人信息在内的信息)，将受这些第三方的服务条款及隐私政策约束(而非本隐私政策)，请您仔细阅读第三方的条款。例如，第三方服务商依托智安云平台向您提供服务时，您向服务商提供的信息不适用本隐私政策，而适用该等服务商自主的隐私政策。如果您通过激活或关联使得我们提供的服务或产品与第三方平台或社交媒体相关联（例如通过第三方网站直接登录、虚拟登录或关联登录），我们可能会根据您就此的授权将您的个人信息进行披露。请您妥善保护自己的个人信息，仅在必要的情况下向第三方提供或给予授权。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 本隐私政策旨在帮助您了解我们会收集哪些个人信息、为什么收集这些个人信息，会利用这些个人信息做些什么及如何保护这些个人信息，以及您作为个人信息主体所享有的权利。我们会遵循隐私政策收集和使用您的个人信息，但不会仅因您授权同意本隐私政策而采用强制捆绑的方式收集您全部的个人信息。我们会严格按照法律法规要求，在特定情况下征求您的单独同意。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 除本隐私政策之外，我们还可能会通过产品页面告知、弹窗提示、信息推送等方式向您说明我们处理您个人信息的规则，具体业务功能对应的个人信息处理情况将以具体规则为准，具体规则与本隐私政策具有相同效力。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 您同意本隐私政策仅代表您已了解应用提供的功能，以及功能运行所需的必要个人信息，并不代表您已同意我们可以收集拓展功能下的必要个人信息，拓展功能下的个人信息会根据您使用过程中的授权情况单独征求您的同意。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 请您在使用【智安云】及相关产品和服务前仔细阅读并理解本隐私政策，尤其是字体加粗及下划线的内容，以便做出适当的选择。 "
          ),
        ]),
        _c("br"),
        _c("h5", [_vm._v("我们收集的信息及相应的使用目的")]),
        _c("p", [
          _vm._v(
            " 我们将遵循合法、正当、必要的原则，收集和使用您的个人信息。具体而言，我们出于为您提供相应的业务功能而收集并处理您的个人信息。相应功能中，如果您提供的是他人个人信息，请您确保已取得相关主体的授权，我们保留随时核查该等个人信息合法来源的权利。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 您需要向我们提供个人信息以供我们实现【智安云】的基本业务功能。为实现基本业务功能所必需的信息为必要个人信息，若您拒绝提供这些必要个人信息，我们将无法向您提供该基本业务功能及相应服务。在基本业务功能之外，您可以自主选择向我们提供部分个人信息以供我们实现【智安云】的扩展业务功能。我们将向您告知使用的目的并基于您的授权同意或履行您选择的扩展业务功能必需的目的处理这些个人信息。您拒绝提供该等扩展业务功能信息，不会影响您使用我们的基本业务功能，但如您拒绝提供实现扩展业务功能必需的个人信息，我们将无法为您提供扩展业务功能。为方便您的理解，我们将所提供的业务功能、处理目的及其所需个人信息如下所列： "
          ),
        ]),
        _c("br"),
        _c("p", [_vm._v("注册、登录账号")]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 您可以通过手机号创建智安云通用帐号，并且您可以完善您的账号信息。当注册、登录智安云通用帐号时，您需向我们提供您的手机号码。您可以选择设置帐号名称、头像、登录密码、帐号绑定邮箱。为了满足法律法规的强制性要求、出于提供产品或服务所必需或者基于您对增强帐号保护级别的需求，在您使用【智安云】备案、接入服务时或您有进一步需求时，您需提供您的真实姓名、身份证号、人脸信息或银行卡信息完成帐号实名认证。如您不注册帐号、登录【智安云】，并不会妨碍您使用【智安云】的大部分业务功能，但可能会影响到您使用需要经过实名认证才能使用的业务功能（部分服务、活动仅支持实名认证用户使用或参与，若您未认证，将无法使用或参与前述服务和活动，如网络接入、域名注册、网站备案等）。为了验证您提供信息的合法性、真实性和有效性，或保障您的帐号安全，我们可能会将您向智安云提供的实名信息与法律法规允许的机构、政府机关授权的机构、合法留存您信息的第三方机构所保存的您的对应信息进行比对核验。 "
          ),
        ]),
        _c("br"),
        _c("h5", [_vm._v("智安云产品功能")]),
        _c("p", [_vm._v("您可以选择【智安云】为您提供【ICP备案审查服务】。")]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 若您使用【ICP备案审查服务】服务，我们会需要您提供【您的网站主办者证件、主体负责人及网站负责人信息、网站负责人活体核查（如人脸识别认证）及相关网站信息等】。在经您授权同意后，我们会为您提供【ICP备案审查服务】。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 您可以随时取消授权【智安云】处理您的【您的网站主办者或主办单位证件信息、主体负责人及网站负责人信息、网站负责人活体核查（如人脸识别认证）及相关网站信息等】，停止我们对您上述信息的收集，之后您可能将无法获取ICP备案审查服务，但不会影响您正常使用【智安云】的其他不基于上述信息提供即可实现的业务功能（例如浏览、搜索智安云产品网页等）。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            "您可以通过【智安云】的【官网或APP或通过客户经理】进一步了解【 ICP备案审查服务】服务。"
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 【生物识别、金融账户等信息】属于敏感个人信息，您可以随时取消授权【智安云】对您【生物识别、金融账户等信息】的收集，之后您可能将无法使用上述基于【生物识别、金融账户等信息】提供的相关服务或功能，或者无法达到上述基于【生物识别、金融账户等信息】提供的相关服务拟达到的效果，但不会影响您正常使用【智安云】其他不基于【生物识别、金融账户等敏感个人信息】即可实现的业务功能。 "
          ),
        ]),
        _c("br"),
        _c("h5", [_vm._v("新服务和功能运营及客服处理")]),
        _c("p", [
          _vm._v(
            " 【智安云】会不时针对新推出的服务和功能发起运营活动，如推出的新服务需要处理您的个人信息或申请设备系统权限， "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 且此前未经过您的授权同意，我们会另行获得您的同意，其中，如涉及敏感个人信息的处理，我们会另行获得您的单独同意。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 当您使用问题上报功能时，我们将通过您的账号ID访问工单功能（包含查看工单、创建工单），用于快速创建您的服务请求以及后端技术服务人员快速响应您的服务请求。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 如果您参加相关运营活动，我们可能会通过运营活动界面收集您的身份信息、联系方式、交易帐号类信息，以便与您取得联系、向您发运营奖励等，具体以运营活动界面和另行向您告知的内容为准。若您不参加相关运营活动则无需提供前述信息。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 如您对于我们提供的产品服务有疑问或需要客服介入进行争议处理时，为了验证您的身份、定位您的问题，可能需要您提供您的身份信息进行核验。为了保障通话质量，确保纠纷得到妥善解决，我们可能会保存您与我们的沟通记录，包括通话记录、您提供的与您的争议解决相关的信息，您使用或提供的联系方式。 "
          ),
        ]),
        _c("br"),
        _c("h5", [_vm._v("商务合作协议的履行")]),
        _c("span", [
          _vm._v(
            " 为实现您的交易或服务请求，例如您需要申请服务开通、测试、费用发票等，为履行合同所必需，您应提供联系人、联系方式、地址、开票信息、订单等必要信息。 "
          ),
        ]),
        _c("br"),
        _c("span", [_vm._v("消息通知")]),
        _c("span", [
          _vm._v(
            " 您知悉并同意，对于您在使用产品及/或服务的过程中提供的您的联系方式（例如：联系电话），我们在运营中可能会向其中的一种或多种发送多类通知，用于用户消息告知、身份验证、安全验证、用户使用体验调研等用途。此外，我们也可能会以短信、电话的方式，为您提供可能感兴趣的服务、功能或活动等商业性信息，如您不愿接受这些信息，可以通过手机短信中提供的退订方式进行退订，也可以直接与我们联系进行退订。 "
          ),
        ]),
        _c("br"),
        _c("span", [_vm._v("保证服务安全、优化和改善服务目的")]),
        _c("span", [
          _vm._v(
            " 当您使用【智安云】时，为了保障您正常使用服务，更准确定位并解决您在使用服务时遇到的问题，改进及优化服务体验，保障您的帐号安全，预防、发现、调查欺诈、危害安全、非法或违反与我们的协议、政策或规则的行为，以保护您、我们或我们的关联公司、合作伙伴及社会公众的合法权益，我们会收集您的设备信息、位置信息、日志信息及其他与登录环境相关的信息。 "
          ),
        ]),
        _c("br"),
        _c("h5", [_vm._v("信息的汇聚融合、个性化推荐和自动化决策")]),
        _c("p", [
          _vm._v(
            " 汇聚融合：您同意我们将通过我们自身或受托方技术对您在本服务中主动或被动提供的个人信息进行汇聚融合、识别分析、数据画像或进一步加工，并形成多种类及不同颗粒度的能够反映您的行为习惯、兴趣爱好或者信用状况等特征的标签信息（以下简称“标签信息”），我们会将标签信息用于具体的场景和目的中进行自动化决策，向您推送个性化展示的信息和/或商业营销信息。除本服务中的信息用于汇聚融合外，对于从您的各种设备上收集到的信息，我们可能会将它们进行关联和汇聚，以便我们能在这些设备上为您提供一致的服务。例如，我们可能会将您的设备信息或电话号码与您的智安云帐户相关联， "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 但前提是您在注册、登录智安云帐号时同意《智安云用户服务协议》和《智安云隐私政策总则》。在确保个人信息均处于同一业务实体的控制之下且不改变数据安全保障能力和管理水平的情况下，为给您提供一致化的体验，您在本服务中主动或被动提供的个人信息可能会和您在我们运营的其他产品服务中主动或被动提供的个人信息进行汇聚融合，并可能在进一步加工后形成标签信息一并用于我们为您提供本服务或者为您提供我们运营的其他产品服务过程中。此类汇聚融合的信息不包含可以直接识别您个人身份的信息，我们会保障信息在汇聚融合后的安全处理。个性化推荐：我们将基于标签信息以及您在智安云官网上的搜索、浏览等使用行为信息、设备信息和位置信息，以便向您推荐您可能更感兴趣、相关性更高的内容。在个性化推荐服务中，我们将同时向您提供便捷的拒绝方式，我们建立了用户对个性化展示所依赖的个人信息（如标签、画像维度等）的自主控制机制，保障您调控个性化展示相关性程度的能力。具体请见本政策【“个性化内容及广告的展示和控制”】。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 自动化决策：自动化决策活动同样依赖于我们对您的使用行为信息、设备信息、位置信息、标签信息的处理。如果我们通过自动化决策方式作出对您的个人权益有重大影响的决定，您有权通过本政策【“如何联系我们”】要求我们予以说明，并有权拒绝我们仅通过自动化决策的方式作出决定。 "
          ),
        ]),
        _c("br"),
        _c("h5", [_vm._v("市场宣传活动")]),
        _c("p", [
          _vm._v(
            " 智安公司及其授权的合作伙伴为了邀请您参加智安云产品、服务、解决方案相关的市场宣传、市场推广相关活动，形式不限于论坛、会议、沙龙、展会等活动，需要收集您的包含姓名、公司、部门职位、手机、邮箱、身份证号码等个人信息，主要用途在于相关市场活动的邀约、发送相关通知以及相关场地/政府/特殊要求的身份报批，除法律法规另有强制性规定的，未经您的同意，不做任何其他用途。 "
          ),
        ]),
        _c("br"),
        _c("h5", [_vm._v("间接收集信息")]),
        _c("p", [
          _vm._v(
            " 为共同向您提供产品/服务或改进产品服务的质量和个性化程度，或出于对产品和服务安全性的考量等合理需要，我们可能按照法律法规的规定或基于您的有效授权从智安云平台、关联公司、合作伙伴及其他受信任的第三方供应商处接收您的个人信息及其他信息。我们将事先要求个人信息提供方说明个人信息来源，并对其个人信息来源的合法性进行确认，同时，我们会了解个人信息提供方已获得的个人信息处理的授权同意范围，如开展业务所需进行的个人信息处理活动超出已获得的授权同意范围的，我们将在获取个人信息后的合理期限内或处理个人信息前，征得您的明示同意，或通过个人信息提供方征得您的明示同意。 "
          ),
        ]),
        _c("br"),
        _c("h5", [_vm._v("事先征得授权同意的例外")]),
        _c("p", [
          _vm._v(
            " 请注意，根据国家法律法规的相关规定及特定事项的需求，在以下情形中，我们收集、使用您的个人信息无需事先征得您的授权同意，您知晓并充分理解此等情形： "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("与国家安全、国防安全直接相关的；"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("为订立、履行您作为一方当事人的合同所必需；"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("为履行我们的法定职责或者法定义务所必需；"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            "为对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；"
          ),
        ]),
        _c("br"),
        _c("span", [_vm._v("与犯罪侦查、起诉、审判和判决执行等直接有关的；")]),
        _c("br"),
        _c("span", [
          _vm._v(
            "出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；"
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            "依照法律法规的规定在合理的范围内收集您自行向社会公众公开或其他已经合法公开的个人信息；"
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 依照法律法规的规定在合理的范围内从合法公开披露的信息中收集您的个人信息，如合法的新闻报道、政府信息公开等渠道； "
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            "为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；"
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的； "
          ),
        ]),
        _c("br"),
        _c("span", [_vm._v("法律法规规定的其他情形。")]),
        _c("br"),
        _c("h5", [_vm._v("个人信息的去标识化/匿名化处理")]),
        _c("p", [
          _vm._v(
            " 在不公开披露、对外提供您个人信息的前提下，智安公司有权对去标识化或者匿名化处理后的用户数据库进行挖掘、分析和利用（包括商业性使用），有权对产品/服务使用情况进行统计并与公众/第三方共享匿名化处理后的统计信息。比如，我们会对我们服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示互联网行业的发展趋势等，但这些统计信息不包含您的任何身份识别信息。 "
          ),
        ]),
        _c("br"),
        _c("h5", [_vm._v("其他您另行同意的目的")]),
        _c("p", [
          _vm._v(
            " 当我们要将信息用于本隐私政策未载明的其它用途时，会事先征求您的同意。当我们要将基于特定目的收集而来的信息用于其他目的时，会重新征求您的同意。 "
          ),
        ]),
        _c("br"),
        _c("h5", [_vm._v("我们如何使用-Cookie-和同类技术")]),
        _c("p", [
          _vm._v(
            " Cookie和匿名标识符工具。Cookie是支持服务器端（或者脚本）在客户端上存储和检索信息的一种机制。当您使用【智安云】产品或服务时，我们会向您的设备发送一个或多个Cookie或匿名标识符。当您与【智安云】服务进行交互时，我们允许Cookie或者匿名标识符发送给智安公司服务器。Cookie 通常包含标识符、站点名称以及一些号码和字符。运用Cookie技术， "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 智安公司能够了解您的使用习惯，记住您的偏好，省去您输入重复信息的步骤，为您提供更加周到的个性化服务，或帮您判断您账户的安全性。Cookie还可以帮助我们统计流量信息，分析页面设计和广告的有效性。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 我们不会将 Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见AboutCookies.org。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie 的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置，但您可能因为该等修改，无法登录或使用依赖于Cookie的智安公司提供的服务或功能。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 您可以通过清除您的Cookie，限制智安公司对Cookie的使用。以百度浏览器为例，您可以在百度浏览器右上方的下拉菜单的“浏览器设置”中，通过“隐私设置——清除浏览数据”，选择清除您的Cookie。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v("我们如何共享、转让、公开披露您的个人信息以及所接入的第三方"),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 我们的某些服务将由授权合作伙伴提供，仅为实现本隐私政策中声明的目的，我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。除另行获得您的授权或法律法规另有要求，我们的合作伙伴无权将共享的个人信息用于任何其他用途。 "
          ),
        ]),
        _c("br"),
        _c("h5", [_vm._v("共享")]),
        _c("p", [
          _vm._v(
            " 除非经过您本人事先单独同意或符合其他法律法规规定的情形，我们不会向第三方共享您的个人信息，但经过处理无法识别特定个人且不能复原的信息除外。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 对我们与之共享个人信息的公司、组织和个人，我们会对其数据安全环境进行调查，与其签署严格的保密协定，要求他们按照依法采取保密和安全措施来处理个人信息。 "
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v("在下列情况下，我们可能会与以下第三方共享您的个人信息："),
        ]),
        _c("br"),
        _c("h5", [_vm._v("第三方产品或服务提供商")]),
        _c("p", [_vm._v("【智安云】引入丰富的第三方服务：")]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 当您使用【智安云云市场】接入的第三方服务时，可能需要提交您的个人信息。我们将基于您的有效授权或在订立、履行您作为一方当事人的合同所必需的情况下，将该您的必要个人信息提供给为您提供服务的第三方。关于第三方服务具体如何收集、使用您的个人信息，建议您参考第三方服务的相关服务协议及隐私政策。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 当您使用【智安云的销售伙伴、技术合作伙伴、服务伙伴】等第三方合作伙伴提供的服务时，可能需要向该等第三方提交您的个人信息。我们将基于您的有效授权或在订立、履行您作为一方当事人的合同所必需的情况下，将该您的个人信息提供给为您提供服务的第三方。关于第三方服务具体如何收集、使用您的个人信息，建议您参考第三方服务的相关服务协议及隐私政策。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 当您使用【智安云与软硬件或系统供应商等第三方合作提供的服务时，可能需要向该等第三方提交您的个人信息。我们将基于您的有效授权或在订立、履行您作为一方当事人的合同所必需的情况下，将该您的个人信息提供给为您提供服务的第三方。包括当您在服务采购协议中约定由该等第三方供应商直接向您交付产品、开具发票时，我们向第三方供应商提供您的联系方式、收货地址为向您提供产品服务所必需时，我们会将您提供的联系方式提供给为您提供产品或服务的第三方供应商；当第三方获取您的发票信息为提供产品服务所必需时，我们会将您的发票信息提供给为您提供产品或服务的第三方供应商。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 当您参加第三方提供的营销活动或服务内容时，基于服务需要可能需要您提供帐号昵称、头像、姓名、联系方式等信息，我们会经过您的单独同意且在第三方为您提供营销活动或服务内容所必需时，向第三方共享您的个人信息，以保障第三方能够顺利为您提供服务或委托第三方及时向您兑现奖励。在为您提供营销活动或服务内容所必需的情况下，如您拒绝共享，第三方将无法为您提供服务或拒绝您的参与。 "
          ),
        ]),
        _c("br"),
        _c("p", [_vm._v("关联公司或同一帐号体系使用方")]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 我们与我们的关联公司或同一帐号体系使用方之间具备高度一致的数据安全保障措施和管理水平。为了共同向您提供服务、提高您使用产品的安全性和便利性、保障一致性的服务体验，在遵循法律要求且征得您单独同意的前提下，我们会在关联公司或同一帐号体系使用方之间共享您的个人信息。在此情况下，我们只会共享必要的个人信息，如果我们共享您的敏感个人信息或接收方改变个人信息的使用及处理目的，将再次征求您的授权同意/单独同意。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 对于同一帐号体系使用方，为了履行监管要求、保障帐号安全性或进行一致化的展示，基于您的有效授权，我们可能会共享您的帐号昵称、头像、绑定状态、绑定手机号信息及帐号基础信息。 "
          ),
        ]),
        _c("br"),
        _c("h5", [_vm._v("产品或服务的受托人")]),
        _c("p", [
          _vm._v(
            " 为提供服务之必要，我们可能会将您的个人信息委托给支持部分服务功能的受托人处理。这些支持包括：提供仓储、物流、客服或其他服务，由受托人向您提供物流配送、订单查询、订单支付、售后服务、客户支持（如安装服务）、开票、与合作伙伴对账、支付机构调查回复、奖品发放、服务或产品内容推荐等服务。 "
          ),
        ]),
        _c("br"),
        _c("h5", [_vm._v("以下情形请您关注：")]),
        _c("p", [
          _vm._v("在获得您的单独同意后，我们会与其他方共享您的个人信息。"),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 在法定情形下的共享：我们可能会根据法律法规规定、诉讼、仲裁解决需要，或按行政、司法机关依法提出的要求，对外共享您的用户信息；依照法律、法规和监管规定的要求，将您提供的网站主办单位信息、主体负责人及网站负责人信息、网站负责人活体核查（刷脸实名认证）信息及相关网站信息等提供给监管机构或第三方平台用于核查比对。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 如我们与上述第三方共享您的信息，我们仅会基于合法、正当、必要原则，在为提供服务所必需的范围内与其共享您的信息，并且我们将努力确保第三方在使用您的信息时遵守本声明及我们要求其遵守的其他适当的保密和安全措施。 "
          ),
        ]),
        _c("br"),
        _c("p", [_vm._v("转让")]),
        _c("br"),
        _c("span", [
          _vm._v(
            "我们不会将您的个人信息转让给除智安公司外的任何公司、组织和个人，但以下情况除外："
          ),
        ]),
        _c("br"),
        _c("span", [_vm._v("事先获得您的明确授权或同意；")]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("满足法律法规、法律程序的要求或强制性的政府要求或司法裁定；"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 如果我们或我们的关联公司涉及合并、分立、清算、资产或业务的收购或出售等交易，您的个人信息有可能作为此类交易的一部分而被转移，我们将确保该等信息在转移时的机密性、向您告知接收方的名称或姓名和联系方式，并通过签署协议等方式尽最大可能确保接收方继续受此隐私政策的约束并履行个人信息处理者的义务；接收方变更本隐私政策中约定的处理目的、处理方式的，将依照法律规定重新向您征求授权同意。 "
          ),
        ]),
        _c("br"),
        _c("span", [_vm._v("公开披露")]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("我们仅会在以下情况下，公开披露您的个人信息："),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [_vm._v("获得您的单独同意；")]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("基于法律法规、法律程序、诉讼或政府主管部门强制性要求下。"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 对违规帐号、违规行为进行处罚公告时，我们会披露相关帐号的信息，这些信息不可避免包含账号相关认证信息及违规情况。 "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("共享、转让、公开披露个人信息时事先征得授权同意的例外"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            "在以下情形中，共享、转让、公开披露个人信息无需事先征得个人信息主体的授权同意："
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("与国家安全、国防安全直接相关的；"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("为订立、履行个人作为一方当事人的合同所必需；"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("为履行法定职责或者法定义务所必需；"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 与公共安全、公共卫生、重大公共利益直接相关的。例如：为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需； "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("与犯罪侦查、起诉、审判和判决执行等直接相关的；"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            "出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；"
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 依照法律法规的规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息，例如：合法的新闻报道、政府信息公开等渠道等； "
          ),
        ]),
        _c("br"),
        _c("p", [_vm._v("法律、行政法规另有规定的情形。")]),
        _c("br"),
        _c("p", [_vm._v("委托处理")]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 在我方作为受委托方，接受数据处理者委托处理您的数据的场景下，我们会按照法律法规要求与委托方签署符合法律法规要求的相关协议，并依法、依协议履行相关个人信息保护义务。 "
          ),
        ]),
        _c("br"),
        _c("p", [_vm._v("我们如何保存及保护您的个人信息")]),
        _c("br"),
        _c("p", [_vm._v("保存期限")]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 我们将在提供服务所需的最短期限内保存您的个人信息，您理解并认可基于不同的服务及其功能需求，必要存储期限可能会有所不同，例如，根据您签署的产品服务合同专门约定留存个人信息的时间，或依据法律要求维护相应的日志记录，或依法、依约定履行系统和服务的安全保障，或应对可能的第三方调查取证或用户投诉、问题定位等或法律、合同等是否有保留个人信息的特殊要求等。 "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 在您未撤回授权同意、删除或注销账号期间，或者个人信息处理目的未实现、保存期限未届满时，我们会持续保留相关信息。 "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 在法律法规对保存期限另有规定、您同意留存更长的期限、保证服务的安全与质量、实现争议解决目的、出现不可抗力情形等情况下，我们 .依法、依约或在合理范围内延长保存期限。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            "在超出保存期限后，我们将删除您的个人信息或进行匿名化处理，但法律法规另有规定的除外。"
          ),
        ]),
        _c("br"),
        _c("p", [_vm._v("保存地域")]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。如您的个人信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问，我们会严格履行法律法规规定的义务并事先获得您的单独同意。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            "如涉及向境外传输个人信息的情况，请您仔细阅读本隐私政策“7. 您的个人信息如何进行跨境转移”。"
          ),
        ]),
        _c("br"),
        _c("p", [_vm._v("安全措施")]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 我们会以“最小化”原则收集、使用、存储和传输用户信息，并通过用户服务协议和隐私政策告知您相关信息的使用目的和范围。 "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 我们非常重视信息安全。我们成立了专责团队负责研发和应用多种安全技术和程序等，我们会对安全管理负责人和关键安全岗位的人员进行安全背景审查，我们建立了完善的信息安全管理制度和内部安全事件处置机制等。我们会采取适当的符合业界标准的安全措施和技术手段存储和保护您的个人信息，以防止您的信息丢失、遭到被未经授权的访问、公开披露、使用、毁损、丢失或泄漏。我们会采取一切合理可行的措施，保护您的个人信息。我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击。 "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 我们会对员工进行数据安全的意识培养和安全能力的培训和考核，加强员工对于保护个人信息重要性的认识。我们会对处理个人信息的员工进行身份认证及权限控制，并会与接触您个人信息的员工、合作伙伴签署保密协议，明确岗位职责及行为准则，确保只有授权人员才可访问个人信息。若有违反保密协议的行为，会被立即终止与智安公司的合作关系，并会被追究相关法律责任，对接触个人信息人员在离岗时也提出了保密要求。 "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 我们也请您理解，在互联网行业由于技术的限制和飞速发展以及可能存在的各种恶意攻击手段，即便我们竭尽所能加强安全措施，也不可能始终保证信息的百分之百安全。请您了解，您使用我们的产品和/或服务时所用的系统和通讯网络，有可能在我们控制之外的其他环节而出现安全问题。 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 根据我们的安全管理制度，个人信息泄露、毁损或丢失事件被列为最特大安全事件，一经发生将启动公司最高级别的紧急预案，由安全部、公共事务部、法务部等多个部门组成联合应急响应小组处理。 "
          ),
        ]),
        _c("br"),
        _c("p", [_vm._v("安全事件通知")]),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 我们会制定网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施，并按照规定向有关主管部门报告。 "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 个人信息泄露、毁损、丢失属于公司级特大安全事件，我们会负责定期组织工作组成员进行安全预案演练，防止此类安全事件发生。若一旦不幸发生，我们将按照最高优先级启动应急预案，组成紧急应急小组，在最短时间内追溯原因并减少损失。 "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处理措施、您可自主防范和降低的风险的建议、对您的补救措施等。我们将及时将事件相关情况以站内通知、短信通知、电话、邮件等您预留的联系方式告知您，难以逐一告知时我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。请您理解，根据法律法规的规定，如果我们采取的措施 "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " 能够有效避免信息泄露、篡改、丢失造成危害的，除非监管部门要求向您通知，我们可以选择不向您通知该个人信息安全事件。 "
          ),
        ]),
        _c("br"),
        _c("p", [_vm._v("您的业务数据")]),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            "不同于您的个人信息，对于您的业务数据，我们将按如下方式处理："
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 您通过智安云提供的服务，加工、存储、上传、下载、分发以及通过其他方式处理的数据，均为您的用户业务数据，您完全拥有您的业务数据。智安云作为中立的技术服务提供者，会严格执行您的委托和指示，除按与您协商一致或依据特定产品规则或基于您的要求为您提供技术协助进行故障排除或解决技术问题或执行明确的法律法规要求外，我们不对您的业务数据进行任何非授权的访问、使用或披露。 "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 您应对您的业务数据来源及内容负责，我们提示您谨慎判断数据来源及内容的合法性。您应保证您有权使用我们的产品、服务对该等业务数据进行处理，且前述处理活动均符合相关法律法规的要求，不存在任何违法违规、侵权或违反与第三方合同约定的情形，亦不会将数据用于违法违规目的。因您的用户业务数据内容违反法律法规、部门规章或国家政策而造成的全部结果及责任均由您自行承担。 "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 您应对您的业务数据来源及内容负责，我们提示您谨慎判断数据来源及内容的合法性。您应保证您有权使用我们的产品、服务对该等业务数据进行处理，且前述处理活动均符合相关法律法规的要求，不存在任何违法违规、侵权或违反与第三方合同约定的情形，亦不会将数据用于违法违规目的。因您的用户业务数据内容违反法律法规、部门规章或国家政策而造成的全部结果及责任均由您自行承担。 "
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 当我们按本节内容处理您的业务数据时，如果涉及第三方个人信息，您应当依法提前向相关个人信息主体履行告知义务，取得相关个人信息主体的单独同意，并保证： "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            "(1) 根据我们要求的方式，就个人信息来源及其合法性提供书面说明和确认；"
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " (2) 在我们要求时，提供相关个人对信息处理的授权同意范围，包括使用目的，个人信息主体同意您使用我们的服务对其个人信息进行处理； "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " (3) 如您使用我们的服务所需进行的个人信息处理活动超出该等个人信息的授权同意范围的，应在提供个人信息后的合理期限内或在我们处理个人信息前，由您负责征得个人信息主体的明示同意，并在我们要求时向我们提供书面证明。 "
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 如您违反上述义务，或未按照要求向我们提供合理满意的证明，或我们收到个人信息主体举报或投诉，我们有权单方决定拒绝处理您的业务数据，或拒绝按照您的指令处理其中所涉第三方个人的信息。由此产生的全部责任均由您自行承担。 "
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 您理解并同意，您应根据自身需求自行对您的业务数据进行存储，我们仅依据相关法律法规要求或特定的服务规则约定，接受您的委托为您提供数据存储服务。您理解并同意，除非法律法规另有规定或依据服务规则约定，我们不会访问、存储您的业务数据，亦不对您的数据存储工作或结果承担任何责任。 "
          ),
        ]),
        _c("br"),
        _c("span", [_vm._v("您的权利")]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利，如您对权利行使有疑问或主张，您也可以通过【如何联系我们】与我们取得联系： "
          ),
        ]),
        _c("br"),
        _c("span", [_vm._v("查阅权")]),
        _c("br"),
        _c("span", [
          _vm._v(
            "您有权查阅您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行查阅您的个人信息："
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 账户信息——如果您希望查阅或编辑您的账户的绑定信息、更改您的密码、管理安全设备信息、进行账户关联、身份认证等，您可以通过用户中心执行此类操作。 "
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 交易信息——如果您希望查阅您在智安云的交易金额、您已开通的服务、订单情况等信息，您可以在您的管理控制台页面进行查看。 "
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            "如果上述链接无法访问，您可以通过在智安云官网提交工单与我们取得联系。"
          ),
        ]),
        _c("br"),
        _c("span", [_vm._v("更正及补充权")]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 您发现我们处理的关于您的个人信息有错误或不完整，您有权对错误或不完整的信息自行作出更正或更新，例如：您可以随时通过账号与安全修改您的绑定手机号等信息；可以通过用户中心更新您的个人信息。如您需变更通过智安云提交的实名认证信息的，您需到原账号注册的网站进行变更。 "
          ),
        ]),
        _c("br"),
        _c("span", [_vm._v("删除权")]),
        _c("br"),
        _c("span", [
          _vm._v("在以下情形中，您可以向我们提出删除个人信息的请求："),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            "（一）个人信息的处理目的已实现、无法实现或者为实现处理目的不再必要；"
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("（二）我们已停止提供产品或者服务，或者保存期限已届满；"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [_vm._v("（三）您已撤回同意；")]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            "（四）如果我们违反法律法规或与您的约定收集、使用、与他人共享或转让您的个人信息；"
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("（五）法律、行政法规规定的其他情形。"),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 当您要求我们删除信息后，我们会依据法律规定响应您的要求，及时删除相应的信息。如果法律、行政法规规定的保存期限未 "
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            "届满，或者删除个人信息从技术上难以实现的，我们将停止除存储和采取必要的安全保护措施之外的处理。"
          ),
        ]),
        _c("br"),
        _c("span", [_vm._v("撤回同意权")]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 您可以选择撤回您的授权，例如：您可以通过“联系我们”来提出您的诉求，包括但不限于撤回您的授权等诉求；或者您也可以通过注销账户的方式，撤回我们继续处理您个人信息的全部授权。 "
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再基于您此前的同意使用您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理的效力。 "
          ),
        ]),
        _c("br"),
        _c("span", [_vm._v("注销账号权")]),
        _c("br"),
        _c("span", [
          _vm._v(" 您随时可注销此前注册的智安云账户。您可以根据《 "),
          _c(
            "span",
            {
              staticClass: "blue_font",
              on: {
                click: function ($event) {
                  return _vm.$router.push("/protocal/logout")
                },
              },
            },
            [_vm._v("智安云账号注销协议")]
          ),
          _vm._v(
            " 》的指引申请注销您的账号。您知悉并理解，注销账号的行为是不可逆的行为，当您注销账号后，我们将停止为您提供任何服务，并删除有关您账号的一切信息或对相关信息进行匿名化处理，法律另有规定的情形除外。因法律规定需要留存的用户个人信息，我们会妥善保管，并不再将其应用于业务场景。 "
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 特别提醒：您的智安云账号一旦注销后，我们将无法协助您找回，请您备份好账号下所有业务信息后谨慎操作。账号注销意味着账号协议及该账号下所有服务条款、协议、合同等一并解除，包括您将无法找回该账号下的个人信息、交易记录、业务数据、历史信息等。 "
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 此外，请您知悉并理解，账号注销并不代表您在该账号注销前的所有账号行为和相关责任得到豁免或减轻。 您通过第三方账号授权登录智安云时，需向第三方申请注销账号。 "
          ),
        ]),
        _c("br"),
        _c("span", [_vm._v("复制权和可携带权")]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 您有权复制我们收集的您的个人信息。在法律法规规定的条件下，同时符合国家网信部门规定指令和条件的，如果技术可行， "
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v("您也可以要求我们将您的个人信息转移至您指定的其他主体。"),
        ]),
        _c("br"),
        _c("span", [_vm._v("提前获知产品和服务停止运营权")]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 若因特殊原因导致智安云某个产品和服务被迫停止运营，我们将提前15日或按与您签署的合作协议中约定的通知期间内，通过包括但不限于按协议约定通知方式、产品或服务的主页面或站内信或向您发送电子邮件或其他合适的能触达您的方式通知您，并将停止对您个人信息的收集，同时会按照法律规定对所持有的您的个人信息进行删除或匿名化处理等。 "
          ),
        ]),
        _c("br"),
        _c("span", [_vm._v("获得解释的权利")]),
        _c("br"),
        _c("span", [
          _vm._v(
            "您有权要求我们就个人信息处理规则作出解释说明。您可以通过【联系方式】与我们取得联系."
          ),
        ]),
        _c("br"),
        _c("span", [_vm._v("对个人信息的生前安排")]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 根据相关法律要求，您可以提前就您的个人信息提前进行生前安排，您可以通过本政策公布的联系方式，通过书面方式告知我们您的安排；如无另行安排，您的近亲属可以基于其合法、正当利益，对您生后的相关个人信息依照相关法律规定和本政策的约定，行使查阅、复制、更正、删除等权利。 "
          ),
        ]),
        _c("br"),
        _c("span", [_vm._v("我们如何响应您的上述请求")]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。 "
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。如果直接实现您的请求需要付出高额成本或存在其他显著困难的，我们将积极努力向您提供替代性方法。 "
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。 "
          ),
        ]),
        _c("br"),
        _c("span", [_vm._v("响应的例外")]),
        _c("br"),
        _c("span", [
          _vm._v(
            "在以下情形中，按照法律法规要求，我们将无法响应您的上述请求："
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("与国家安全、国防安全直接相关的；"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("与公共安全、公共卫生、重大公共利益直接相关的；"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("与犯罪侦查、起诉、审判和执行判决等直接相关的；"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 我们有充分证据表明您存在主观恶意或滥用权利的（如您的请求将危害公共安全和其他人合法权益，或您的请求超出了一般技术手段和商业成本可覆盖的范围）； "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            "响应个人信息主体的请求将导致您或其他个人、组织的合法权益受到严重损害的；"
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [_vm._v("涉及商业秘密的；")]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("法律、行政法规另有规定的情形。"),
        ]),
        _c("br"),
        _c("span", [_vm._v("我们如何处理未成年人的个人信息")]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("智安公司非常重视对未成年人信息的保护。"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 我们的产品和服务主要面向成年人。我们只会在受到法律法规允许、父母或其他监护人同意的情况下处理未成年的个人信息。 "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 如您是未满14周岁的未成年人，在使用【智安云】产品和服务前，应请您的父母或其他监护人仔细阅读，或在您的父母或其他监护人的监护、指导下共同仔细阅读本隐私政策以及《 "
          ),
          _c(
            "span",
            {
              staticClass: "blue_font",
              on: {
                click: function ($event) {
                  return _vm.$router.push("/protocal/childInfo")
                },
              },
            },
            [_vm._v("智安儿童个人信息保护声明")]
          ),
          _vm._v(
            " 》，并在征得您的父母或其他监护人同意的前提下使用我们的产品和服务，或向我们提供信息。 "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 如果我们发现在未事先获得可证实的父母或其他监护人同意的情况下收集了未满14周岁的未成年人的个人信息，我们将会采取措施尽快删除相关信息。如果任何时候监护人有理由相信我们在未获监护人同意的情况下收集了未成年人的个人信息，请通过 "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            "本隐私政策【联系方式】联系我们，我们会采取措施尽快删除相关数据。"
          ),
        ]),
        _c("br"),
        _c("h5", [_vm._v("您的个人信息如何进行跨境转移")]),
        _c("span", [
          _vm._v(
            "原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。"
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 如您的个人信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问，我们会严格履行法律法规规定的义务并事先获得您的单独同意。 "
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 在获得您的单独同意后，您的个人信息才可能会被转移到境外管辖区，或者受到来自这些管辖区的访问；以及，因政府监管、司法协助要求等情形，需向中华人民共和国境外传输您的个人信息的，我们会向您告知所涉跨境传输的数据类型、跨境传输目的、接收方的名称或者姓名、联系方式及相关安全保障措施、您向境外接收方形式个人信息权利的方式和程序等情况，并取得您的单独同意；当我们执行此类传输时，我们会根据法律法规规定和本隐私政策，履行个人信息跨境提供的法定义务，如按照国家网信部门制定的标准合同与境外接收方订立合同，约定双方的权利和义务，以及组织开展数据出境安全自评估，在符合条件时通过国家网信部门组织的安全评估等。 "
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 我们将采取必要措施，保障境外接收方处理个人信息的活动达到与相关法律法规规定和本隐私政策规定的个人信息保护标准。 "
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 例如，我们会请求您对跨境转移个人信息的单独同意，或者在跨境数据转移之前实施数据加密、去标识化等安全举措。 "
          ),
        ]),
        _c("br"),
        _c("h5", [_vm._v("本隐私政策的更新")]),
        _c("span", [
          _vm._v(
            " 我们可能适时修订本政策内容，除法律另有规定外，未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本隐私政策所做的任何变更。 "
          ),
        ]),
        _c("br"),
        _c("span", [_vm._v("本政策所指的重大变更包括但不限于：")]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等； "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等； "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("个人信息对外提供、转让或公开披露的主要对象发生变化；"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("您参与个人信息处理方面的权利及其行使方式发生重大变化；"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v(
            " 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时； 个人信息安全影响评估报告表明存在高风险时。 "
          ),
        ]),
        _c("br"),
        _c("h5", [_vm._v("如何联系我们")]),
        _c("span", [
          _vm._v(
            " 【智安云】的成长离不开各方用户达人的共同努力，我们非常感谢您对【智安云】数据更新、使用反馈方面的贡献。 "
          ),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("1. 您可以通过电话、邮件同我们联系。"),
        ]),
        _c("br"),
        _c("span", { staticClass: "endThanks" }, [
          _vm._v("再次感谢您对【智安云】的信任和使用！"),
        ]),
        _c("br"),
      ]),
    ]),
    _c("div", { staticClass: "main" }, [
      _vm._m(2),
      _c("div", { staticClass: "center" }, [
        _c("h5", [_vm._v("名词解释")]),
        _c("span", [
          _vm._v(
            " 个人信息是指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。个人信息包括姓名、出生日期、身份证件号码、个人生物识别信息、住址、通信通讯联系方式、通信记录和内容、账号密码、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息等。 敏感个人信息是指一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。 "
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 日志信息是指我们的服务器所自动记录您在使用【智安云】时所发出的请求，例如您的IP 地址、浏览器的类型和使用的语言、硬件设备信息、操作系统的版本、网络运营商的信息、您访问服务的日期、时间、时长等您在使用我们的产品或服务时提供、形成或留存的信息。 "
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            " Cookie是指支持服务器端（或者脚本）在客户端上存储和检索信息的一种机制，通过增加简单、持续的客户端状态来扩展基于Web的客户端/服务器应用。服务器在向客户端返回HTTP对象的同时发送一条状态信息，并由客户端保存。状态信息中说明了该状态下有效的URL范围。此后，客户端发起的该范围内的HTTP请求都将把该状态信息的当前值从客户端返回给服务器，这个状态信息被称为Cookie。 "
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 设备信息可能包括最终用户用于安装、运行【产品名称】SDK的终端设备的设备属性信息（例如最终用户的硬件型号，操作系统版本及系统情况，设备配置，国际移动设备身份码IMEI、国际移动用户识别码IMSI、网络设备硬件地址MAC、广告标识符IDFA、供应商标识符IDFV、移动设备识别码MEID、匿名设备标识符OAID 、集成电路卡识别码ICCID 、Android ID、硬件序列号等设备标识符）、设备连接信息（例如浏览器的类型、电信运营商、使用的语言、WIFI信息）以及设备状态信息（例如设备应用安装列表）。 "
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 用户画像是指通过收集、汇聚、分析个人信息，对某特定自然人个人特征，如其职业、经济、健康、教育、个人喜好、信用、行为等方面做出分析或预测，形成其个人特征模型的过程。直接使用特定自然人的个人信息，形成该自然人的特征模型，称为直接用户画像。使用来源于特定自然人以外的个人信息，如其所在群体的数据，形成该自然人的特征模型，称为间接用户画像。 "
          ),
        ]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 匿名化是指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。个人信息经匿名化处理后所得的信息不属于个人信息。 "
          ),
        ]),
        _c("br"),
        _c("span", [_vm._v("智安云平台是指智安公司官方网站平台。")]),
        _c("br"),
        _c("span", [
          _vm._v(
            " 关联公司是指【智安云】的经营者深圳市智安网络有限公司及其他与智安公司存在关联关系的公司的单称或合称。“关联关系”是指对于任何主体（包括个人、公司、合伙企业、组织或其他任何实体）而言，即其直接或间接控制的主体，或直接或间接控制其的主体，或直接或间接与其受同一主体控制的主体。前述“控制”指，通过持有表决权、合约或其他方式，直接或间接地拥有对相关主体的管理和决策作出指示或责成他人作出指示的权力或事实上构成实际控制的其他关系。 "
          ),
        ]),
        _c("br"),
        _c(
          "span",
          {
            staticClass: "blue_font",
            on: {
              click: function ($event) {
                return _vm.$router.push("/protocal/infoSecurity")
              },
            },
          },
          [_vm._v("查看智安云信息安全规定")]
        ),
        _c("br"),
      ]),
    ]),
    _vm._m(3),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("h3", [_vm._v("智安隐私政策")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "heard" }, [
      _c("div", [
        _c("h5", [_vm._v("简介")]),
        _c("h6", [_vm._v("本隐私政策将帮助您了解以下内容：")]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("1. 我们收集的信息及相应的使用目的"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("2. 我们如何使用 Cookie 和同类技术"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("3. 我们如何共享、转让、公开披露您的个人信息"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("4. 我们如何保存及保护您的个人信息"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [_vm._v("5. 您的权利")]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("6. 我们如何处理未成年人的个人信息"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("7. 您的个人信息如何进行跨境转移"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [_vm._v("8. 您的业务数据")]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [_vm._v("9. 本隐私政策的更新")]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [_vm._v("10. 如何联系我们")]),
        _c("br"),
      ]),
      _c("div", [
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("深圳市智安网络有限公司"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("更新日期：【 2023年 03月 31日】"),
        ]),
        _c("br"),
        _c("span", { staticClass: "mgLeft" }, [
          _vm._v("生效日期：【 2023年 03月 31日】"),
        ]),
        _c("br"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [_c("h3", [_vm._v("附录")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ignore_center" }, [
      _c("span", [
        _vm._v(
          " Copyright©2023 深圳市智安网络有限公司 All Rights Reve 版权所有 《中华人民共和国增值电信业务经营许可证》编号：B1-20190666 "
        ),
        _c(
          "a",
          {
            staticClass: "blue_font",
            attrs: {
              href: "https://beian.miit.gov.cn/#/Integrated/index",
              target: "_blank",
            },
          },
          [_vm._v("粤ICP备18027011号")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }