<template>
  <div class="login" :style="fixStyle">
    <div>
      <loginHeard :title="!isRegister ? '登录' : '注册'"></loginHeard>
    </div>
    <div>
      <div class="bg_img"></div>
      <div class="main">
        <div class="loginInfoWrapper">
          <div
            id="weichat"
            :style="{ zIndex: isQRCode ? 10 : -10 }"
            style="
              position: absolute;
              padding: 20px;
              padding-left: 30px;
              top: 20px;
              z-index: 10;
              width: 100%;
              background-color: white;
            "
          ></div>

          <!-- 打开微信登录二维码 -->
          <img @click="GET_weixin" style="position: absolute; right: 0; top: 0; z-index: 11" :src="showImg" alt="" />
          <div class="loginInfo">
            <div v-if="!isRegister" class="login-com">
              <sign-in />
            </div>
            <div v-else class="register-com">
              <register :visible.sync="isRegister" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <loginFoot></loginFoot>
    </div>
  </div>
</template>

<script>
  import loginHeard from '@/views/login/components/loginHeard'
  import loginFoot from '@/views/login/components/loginFoot'
  import SignIn from '@/views/login/components/signIn'
  import { reqForgetTipsObj } from '@/za/model/reqModel'
  import Register from '@/views/login/components/register'
  import { removeToken } from '@/utils/token'
  import { getLocation } from '@/utils'
  import { apiPopularizeClickPoint } from '@/api/management/overview'
  import { apiWeiXinLogin } from '@/api/apiLogin'
  import { mapActions } from 'vuex'
  import { resLoginObj } from '@/za/model/resModel'
  import QRImg from '@/assets/login/wechatLogin.png'
  import userImg from '@/assets/login/userLogin.png'

  export default {
    name: 'Login',
    components: { loginHeard, loginFoot, Register, SignIn },
    data() {
      return {
        activeName: 'login',
        pswErrorMsg: Object.assign({}, reqForgetTipsObj),
        isRegister: false,
        fixStyle: {},
        name: '',
        redirect: undefined,
        isQRCode: false,
        showImg: QRImg,
      }
    },
    created() {

      this.$store.dispatch('user/setToken', '')
      removeToken()
      // 推广点击统计（只有携带  推广码  才会访问接口）
      if (this.$route.query.id) {
        const reqObj = {
          popularizeCode: this.$route.query.id,
          matterId: 0,
          type: 1,
        }
        this.onSubmitData(apiPopularizeClickPoint, reqObj, null, false, false, () => {})
      }
      let isLogin = getLocation('isLogin')
      if (isLogin == '1') {
        this.isRegister = false
      } else if (isLogin == '2') {
        this.isRegister = true
      } else {
        this.isRegister = false
      }
    },
    mounted() {
      // console.log(process.env.VUE_APP_DOMAIN,process.env.VUE_APP_PORTAL_TARGET_URL);
      //注册
      this.$baseEventBus.$on('register', (val) => {
        this.isRegister = val
      })
      // window.onresize = () => {
      //     const windowWidth = document.body.clientWidth
      //     const windowHeight = document.body.clientHeight
      //     const windowAspectRatio = windowHeight / windowWidth
      //     let videoWidth
      //     let videoHeight
      //     if (windowAspectRatio < 0.5625) {
      //         videoWidth = windowWidth
      //         videoHeight = videoWidth * 0.5625
      //         this.fixStyle = {
      //             // height: windowWidth * 0.5625 + 'px',
      //             width: windowWidth + 'px',
      //             'margin-bottom': (windowHeight - videoHeight) / 2 + 'px',
      //             'margin-left': 'initial'
      //         }
      //     } else {
      //         videoHeight = windowHeight
      //         videoWidth = videoHeight / 0.5625
      //         this.fixStyle = {
      //             // height: windowHeight + 'px',
      //             width: windowHeight / 0.5625 + 'px',
      //             'margin-left': (windowWidth - videoWidth) / 2 + 'px',
      //             'margin-bottom': 'initial'
      //         }
      //     }
      // }
      // window.onresize()
    },
    methods: {
      ...mapActions({
        setLoginData: 'user/setLoginData',
      }),
      handleRoute() {
        return this.redirect === '/404' || this.redirect === '/403' ? '/' : this.redirect
      },
      // 获取微信二维码
      GET_weixin(data) {
        // 如果点击了微信二维码
        this.isQRCode = !this.isQRCode
        //如果显示的是账号登录
        if (!this.isQRCode) {
          this.showImg = QRImg
          return
        } else {
          this.showImg = userImg //如果显示二维码登录
          let href =
            'data:text/css;base64,aWZyYW1lewp3aWR0aDogMzAwcHg7CmhlaWdodDogMzAwcHg7Cm1hcmdpbjogMCBhdXRvOwp9Ci5pbXBvd2VyQm94IC5xcmNvZGV7CiAgd2lkdGg6IDE3MHB4ICFpbXBvcnRhbnQ7Cn0K'
          let obj = new WxLogin({
            self_redirect: false,
            id: 'weichat',
            appid: 'wx4c39126ebb32dee0',
            scope: 'snsapi_login',
         
            redirect_uri: decodeURIComponent(`${process.env.VUE_APP_URL}/login`),
            state: '',
            style: '',
            href: '',
          })
        }
      },
    },
    watch: {
      '$route.query': {
        async handler(query) {
          // 路由中有code参数，调用接口进行微信登录
          if (query.code) {
            localStorage.setItem('code', JSON.stringify(query.code))
            let res = await apiWeiXinLogin({ code: query.code }).catch()
            console.log(res, '微信登录结果')
            let obj = {
              loginType: '',
              accessToken: res.data.accessToken,
              phone: res.data.phone,
              uuid: res.data.uuid,
            }

            if (res.code == 0) {
              if (obj.loginType == 1) {
                this.dialogVisible = true
                this.baseForm.phone = obj.phone
                this.baseForm.username = this.signInInfoObj.username
                this.baseForm.code = ''
              } else if (obj.loginType == 2) {
                this.dialogState = true
              } else {
                console.log('loginType不是0也不是1，走到else中')
                const resObj = Object.assign(resLoginObj, obj)
                await this.setLoginData(resObj).catch()
                // 如果返回的===1.跳转到绑定页面
                if (res.data.isJump === 1) {
                  // alert(obj.phone, 'obj.phone')
                  if (obj.phone) {
                    this.$router.push({ path: '/bindWX', query: { phone: obj.phone } })
                  } else {
                    this.$router.push('/bindWX')
                  }
                }
                // 否则走正常登录流程
                if (res.data.isJump === 0) {
                  this.$printLog(obj, 'Login')
                  const resObj = Object.assign(resLoginObj, obj)
                  await this.setLoginData(resObj).catch()
                  if (this.urlString && store.getters['user/token']) {
                    console.log(this.urlString, 55)
                    const { rules } = await store.dispatch('user/getUserInfo')
                    // 根据路由模式获取路由并根据权限过滤
                    await store.dispatch('routes/setRoutes', rules)
                    //获取余额信息
                    await store.dispatch('user/getBalanceData')
                    //获取消息列表
                    await store.dispatch('user/getMsgData')
                    let pathUrl = this.urlString.substring(this.urlString.lastIndexOf('=') + 1, this.urlString.length)
                    console.log(pathUrl, 'pathUrl')
                    await this.$router.push({ path: '/business', query: { url: pathUrl } })
                  } else {
                    //如果 login后 存在(充值)重定向地址，则登陆后直接跳转重定向地址
                    if (this.$route.query.toHomeFlag === 'false') {
                      this.redirect = this.$route.query.redirect
                    }
                    // 登陆后跳转到  优惠券  页面
                    if (this.$route.query.target) {
                      this.redirect = `/${this.$route.query.target}`
                    } else {
                    }
                    await this.$router.push(this.handleRoute())
                  }
                }
              }
            } else if (res.code == 599998) {
              this.waringDescState = true
              this.waringDesc = res.msg
            }
          }
        },
        immediate: true,
      },
      $route: {
        handler() {
          this.redirect = '/'
        },
        immediate: true,
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '~@/za/styles/mixin.scss';

  .login {
    width: 100%;
    height: 100vh;
    display: flex;

    .bg_img {
      height: $nobase-loginBg-height;
      width: $nobase-loginBg-width;
      min-height: 730px;
      position: absolute;
      @include img_div($imgUrl: url('~@/assets/activity.jpg'));
    }

    .main {
      height: calc(100vh - 110px);
      width: 600px;
      background-color: #ffffff;
      box-sizing: border-box;
      padding-bottom: 2px;
      position: relative;
      margin-top: 50px;
      position: absolute;
      right: 0;
      .loginInfoWrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -200px;
        margin-top: -250px;
        padding: 50px;
        border-radius: 10px;
        box-shadow: 0px 2px 6px 2px rgba(68, 68, 68, 0.2);
        overflow: hidden;

        .loginInfo {
          width: 360px;
        }
      }
    }
  }

  ::v-deep {
    .el-tabs__content {
      overflow: inherit;
    }
  }
</style>
