<template>
    <div class="herard-container">
        <div class="main">
            <div class="showHeardPirectrue">
                <el-image ref="image" class="icon-heardPicture" fit="cover" :src="icoUrl()">
                    <div slot="error">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </el-image>
            </div>
            <div class="heardFlex">
                    <div class="header_title">
                        <div>
                            <span>您好:</span>
                            <span class="value">{{querydata.username}}</span>
                        </div>
                    </div>
                    <div>
                        <span>上次登录:</span>
                        <span class="value"> {{ querydata.uuid}}</span>
                    </div>

            </div>
        </div>

    </div>
</template>
<script>

    import {mapGetters} from "vuex";

    export default {
        data() {
            return {
                querydata: {
                    username: 'test',
                    uuid: '2022-19-20'
                },
                value: 3
            }
        },
        created() {

        },
        computed: {
            ...mapGetters({
                userInfo: 'user/userInfo',
            })
        },
        methods: {
            icoUrl() {
                if (this.userInfo.profile) {
                    return this.userInfo.profile
                } else {
                    return require('@/assets/home/default.png')
                }

            },
        }
    }
</script>
<style lang="scss" scoped>
    .herard-container {
        height: 120px;
        padding: 20px;
        display: flex;
        background: #FFFFFF;
        margin-bottom: 20px;
       .main{
           display: flex;
           align-items: center;
           width: 100%;
           .showHeardPirectrue {
               width: 60px;
               height: 60px;
               min-width: 60px;
               min-height: 60px;
               position: relative;
               margin-right: 40px;
           }

           .heardFlex {
               font-size: 14px;
               font-weight: 400;
               color: #555555;
               div {
                   .value {
                       color: #000000;
                       margin-top: 10px;
                       display: inline-block;
                       margin-left: 5px;
                       font-size: 14px;
                   }
               }

               .header_title {
                   display: flex;
                   align-items: center;
                   width: 600px;
                   justify-content: space-between;
                   min-width: 400px;
                   div:first-child{
                       font-size: 18px;
                       font-family: PingFangSC-Semibold, PingFang SC;
                       font-weight: 600;
                       color: #000000;
                       margin-bottom: 13px;
                   }
                   .heard_rate {
                       display: flex;
                   }
               }
           }
       }


    }

</style>