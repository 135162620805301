<template>
    <div>
        <za-query-form>
            <za-query-form-left-panel :span="24">
                <el-form :inline="true" :model="pageObj" @submit.native.prevent>
                    <el-form-item label="起止时间"
                    >
                        <el-date-picker
                                v-model="pickTime"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                @change="datePickEnd"
                        ></el-date-picker>
                    </el-form-item>
                    <el-form-item label="资金类型" label-width="100px">
                        <el-select v-model="pageObj.orderType" @change="getList">
                            <el-option
                                    v-for="item in typeOptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="订单编号：" label-width="100px">
                        <el-input v-model="pageObj.orderNumber"
                                  :placeholder="`${translateTitle('placeTxt')}`"/>
                    </el-form-item>
                    <el-form-item label="产品名称：" label-width="100px">
                        <el-input v-model="pageObj.productName"
                                  :placeholder="`${translateTitle('placeTxt')}`"/>
                    </el-form-item>
                    <el-form-item>
                        <za-button :icon="buttonIconObj.SEARCH" @click="getList">{{ translateTitle('btn.query') }}
                        </za-button>
                    </el-form-item>
                </el-form>
            </za-query-form-left-panel>
        </za-query-form>
        <div class="money">
            <span>可开票金额: </span>
            <span>￥{{amount}}</span>
        </div>
        <!--                   @getList="changeList"-->

        <occ-table :tableData="tableData" :columObj="columObj"
                   :expands="expands"
                   @handleSelectionChange="handleSelectionChange" :isOp="columObj.isOp"
                   ref="occTwoable"
        ></occ-table>
        <div>
            <!--            <el-checkbox v-model="checked" @change="changeMultipleSelection"-->
            <!--                         :indeterminate='isIndeterminate'></el-checkbox>-->
            <!--            <el-button type="primary" @click="$router.push('/moneyCenter/invoiceManagement/askfor/drawAbill/drawInfo')">下一步</el-button >-->
            <pagination
                    :limit.sync="pageObj.pageSize"
                    :page.sync="pageObj.pageNo"
                    :total="total"
                    @pagination="getList"
            />
        </div>
        <div>
            <div class="bun_fix">
                <el-button type="primary" @click="toTop" :disabled="multipleSelection.length==0 || isStopStep > 0">下一步</el-button>
                <el-button plain @click="cancel">取消</el-button>
            </div>
        </div>
    </div>
</template>
<script>
    import {apiGetOrderInvoices} from '@/api/moneyCenter/invoiceManagement/drawAbill'

    export default {
        data() {
            return {
                checked: false,
                expands: [],
                pickTime: [],
                typeOptions: [{id: 0, name: '全部'}, {id: 2, name: '新购'}, {id: 3, name: '续费'}],
                isIndeterminate: false,
                total: 0,
                pageObj: {
                    startAt: 0,
                    endAt: 0,
                    pageNo: 1,
                    pageSize: 10,
                    orderType: 0,
                    orderNumber: '',
                    productName: ''
                },
                monthNum:0,
                amount:0,
                multipleSelection:[],
                tableData: [],
                columObj: {
                    // 控制选择框
                    selection: true,
                    isOp: false,
                    width: '300px',
                    columnData: [
                        {
                            text: true,
                            prop: "orderNumber",
                            label: "订单编号",
                            width: "",
                            flag: true,
                            align: "center",

                        },
                        {
                            text: true,
                            prop: "productName",
                            label: "产品名称",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "fundType",
                            label: "资金类型",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        // {
                        //     text: true,
                        //     prop: "type",
                        //     label: "类型",
                        //     flag: true,
                        //     width: "",
                        //     align: "center",
                        // },
                        {
                            text: true,
                            prop: "deductionTime",
                            label: "订单/账单扣费时间",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            ownDefined: true,
                            prop: "payAmount",
                            label: "现金支付金额",
                            flag: true,
                            width: "",
                            align: "center",
                            ownDefinedReturn: ((row, index) => {
                                return  '￥'+row.payAmount
                            })
                        },
                        {
                            ownDefined: true,
                            prop: "invoicableAmount",
                            label: "可开票金额",
                            flag: true,
                            width: "",
                            align: "center",
                            ownDefinedReturn: ((row, index) => {
                                return  '￥'+row.invoicableAmount
                            })
                        }
                    ],
                },
            }
        },
        created() {
            this.getList()
        },
        computed: {
            isStopStep(){
                return this.multipleSelection.filter(item => {
                    return item.invoicableAmount === 0
                }).length
            }
        },
        methods: {
            getList() {
                this.onSubmitData(apiGetOrderInvoices, this.pageObj, null, false, false, (res) => {
                    this.total = res.data.total
                    this.monthNum=res.data.monthNum
                    this.amount=res.data.amount
                    if (!res.data.list) {
                        this.tableData = []
                    } else {
                        this.tableData = res.data.list
                    }
                })
            },
            // changeList(val) {
            //     console.log(val, ",,,,")
            // },
            toTop() {
                this.$router.push({path:'/moneyCenter/invoiceManagement/askfor/drawAbill/drawInfo',query:{type:'order'}})
            },
            cancel() {
                this.$refs.occTwoable.$emit('changeMultipleSelection', false)
            },
            handleQuery() {
                this.getList()
            },
            datePickEnd(date) {
                if (date) {
                    this.pageObj.startAt = this.$changeTimeStamp(date[0])
                    this.pageObj.endAt = this.$changeTimeStamp(date[1]) + 3600 * 24 - 1

                } else {
                    this.pageObj.startAt = 0
                    this.pageObj.endAt = 0
                }
                this.getList()

            },
            handleSelectionChange(val) {
                this.multipleSelection = val
                let orders=[]
                val.forEach((item)=>{
                orders.push({orderNum:item.orderNumber,transactionType:item.transactionType})
                })
                localStorage.setItem('order', JSON.stringify(orders))
            },
            changeMultipleSelection(bool) {
                if (bool) {
                    this.$refs.occTwoable.$emit('changeMultipleSelection', true)
                } else {
                    this.$refs.occTwoable.$emit('changeMultipleSelection', false)
                }

            }

        }
    }
</script>
<style lang="scss" scoped>
    .money {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        margin-bottom: 25px;

        span:last-child {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: red;
        }

    }

    .bun_fix {
        padding: 16px 10px 16px 80%;
        position: fixed;
        left: 210px;
        bottom: 0px;
        width: 100%;
        right: 0;
        height: 64px;
        background: #FFFFFF;
        z-index: 99;
        box-shadow: 0px -9px 28px 8px rgba(0, 0, 0, 0.05);
    }
</style>