<template>
  <div>
    <div class="heard" v-if="!certificationName">
      <div class="waring">
        <span style="margin-left: 16px">
          <za-svg-icon icon-class="za-wanning"></za-svg-icon>
        </span>
        <span>温馨提示：请实名认证之后执行提现操作，谢谢！</span>
      </div>
    </div>
    <div class="expenseManagement-container">
      <div>
        <za-tabs :tabArray="titleList" :handleActive="titleList[0].key" :showBorder="false"></za-tabs>
      </div>
      <div class="prompt">
        <span style="color: #faad13; margin-bottom: 15px">
          <za-svg-icon style="font-size: 16px; margin-right: 6px" icon-class="za-wanning"></za-svg-icon>
          {{ translateTitle('topUp.reminder') }}：
        </span>
        <span>1.申请提交后，您的款项将按照先进后出的原则返回。</span>
        <span>
          2.若您刚刚充值，请耐心等待10-15分钟后再发起提现申请。更多常见问题（如：我的提现金额什么时候能到账？我为什么不能提现？）可查看
          <span class="blue_font" @click="withdrawalRuleDialog">提现规则说明</span>
        </span>
        <span>3.当您的账户存在按时长后付的资源时，我们将从现金余额钱包中预留100元用以您账户预期消费。</span>
      </div>

      <div class="card_mottom">
        <el-row>
          <el-col :span="4">
            <el-card shadow="always" class="cardUl">
              <div class="title">
                <span>可提现金额</span>
                <el-tooltip effect="light" placement="right-start">
                  <div slot="content">
                    <div class="orange_font">
                      可提现金额是指当前帐号内的全部提现余额，包括用于消费的现金余额和返佣结算的余额
                    </div>
                  </div>
                  <i class="el-icon-info blue_font"></i>
                </el-tooltip>
              </div>
              <div class="operation">
                <div>
                  <span>{{ addDecimalNumbers(moneyInfo.withdrawalAmount, withdrawFee) }}</span>
                  <span>元</span>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="4">
            <el-card shadow="always" class="cardUl">
              <div class="title">
                <span>现金余额钱包可提现金额</span>
                <el-tooltip effect="light" placement="right-start">
                  <div slot="content">
                    <div class="orange_font">现金余额钱包：指当前账号内可用于消费的金额</div>
                    <div class="grey_font">现金余额钱包可提现金额 = 现金金额 – 已消费金额 – 冻结金额</div>
                    <div class="grey_font">冻结金额可能是以下原因导致冻结：</div>
                    <div class="grey_font">
                      您有后付费产品或后付费资源释放小于等于 24 小时，需要在账户中保留 100 元。
                    </div>
                    <div class="grey_font">您的部分现金余额已在提现流程中。</div>
                  </div>
                  <i class="el-icon-info blue_font"></i>
                </el-tooltip>
                <!--                <span style="margin-left: 10px">
                                   <el-tooltip class="item" effect="dark"
                                                            content="通过微信、支付宝、网银、对公汇款充值超过360天的金额+推广返利佣金提现的金额。"
                                                            placement="right-end">
                                                     <za-svg-icon icon-class="za-prompt"></za-svg-icon>
                                                 </el-tooltip>
                                </span>-->
              </div>
              <div class="operation">
                <div>
                  <span>{{ moneyInfo.offlineAmount }}</span>
                  <span>元</span>
                </div>
                <div>
                  <el-button
                    type="primary"
                    @click="offline"
                    :disabled="!certificationName || moneyInfo.offlineAmount <= 0"
                  >
                    线下提现
                  </el-button>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="4">
            <el-card shadow="always" class="cardUl">
              <div class="title">
                <span>返佣余额钱包可提现金额</span>
                <el-tooltip effect="light" placement="right-start">
                  <div slot="content">
                    <div class="orange_font">返佣余额是指当前账号通过推广下级用户消费所获得的收益。</div>
                    <div class="grey_font">返佣余额钱包可提现金额 = 已转出金额 – 已提现金额 – 冻结金额</div>
                    <div class="grey_font">您的部分返佣余额已在提现流程中。</div>
                  </div>
                  <i class="el-icon-info blue_font"></i>
                </el-tooltip>
              </div>
              <div class="operation">
                <div>
                  <span>{{ withdrawFee }}</span>
                  <span>元</span>
                </div>
                <div>
                  <el-tooltip effect="light" placement="right-start" :disabled="!cashBackFlag">
                    <div slot="content" v-if="promotionAuthority">
                      <div class="orange_font">
                        1. 为确保顺利提现，请在每月的 1-5 号之间发起返佣余额提现申请，其余时间无法进行
                        返佣余额提现的动作。
                      </div>
                      <div class="orange_font">
                        2. 每月 6-10 号为智安财务账期处理时间，智安财务将在这个时间段统一处理返佣提现 申请，请耐心等待。
                      </div>
                      <div class="orange_font">
                        3. 若您在提现期间遇到问题或有疑问，请及时联系我们的客服团队，我们将竭诚为您服
                        务。感谢您的理解和配合。
                      </div>
                    </div>
                    <div slot="content" v-else>
                      <div class="orange_font">
                        1. 账号异常，为保障您的资金安全，提现功能暂时无法使用，请联系客服处理。
                      </div>
                    </div>
                    <div>
                      <el-button type="primary" @click="cashBack" :disabled="cashBackFlag || withdrawFee <= 0">
                        提现
                      </el-button>
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <!--      提现记录-->
      <el-tabs v-model="activeName">
        <el-tab-pane label="提现记录" name="first">
          <div>
            <za-query-form>
              <za-query-form-left-panel :span="24">
                <el-form :inline="true" :model="pageObj" @submit.native.prevent>
                  <el-form-item
                    :label="translateTitle('details.transactionTime') + '：'"
                    style="margin-left: 15px !important"
                  >
                    <el-date-picker
                      v-model="pickTime"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      @change="datePickEnd"
                    ></el-date-picker>
                  </el-form-item>
                </el-form>
              </za-query-form-left-panel>
            </za-query-form>
            <occ-table :tableData="tableData" :columObj="columObj" :isOp="columObj.isOp"></occ-table>
            <pagination
              :limit.sync="pageObj.pageSize"
              :page.sync="pageObj.pageNo"
              :total="total"
              @pagination="getList"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!--    提现弹框-->
    <za-dialog :top-title="dialogType | dialogTitle(dialogTypeObj)" :visible.sync="dialogVisible" width="800px">
      <div>
        <div class="prompt" :class="dialogType === 1 ? 'cashback' : 'withdraw-offline'">
          <span style="color: #faad13; margin-bottom: 15px">
            <za-svg-icon style="font-size: 16px; margin-right: 6px" icon-class="za-wanning"></za-svg-icon>
            {{ translateTitle('topUp.reminder') }}：
          </span>
          <div v-if="dialogType === 2">
            <span>1.申请提交后，您的款项将七个工作日内提现至您提供的银行账户。</span>
            <span>2.为保护您的账户资金安全，您每月可提现1次，提现的资金下限为100元，上限为30000元。</span>
            <span>
              3.所有通过线下电汇方式充值的款项，我们将通过线下汇款的方式根据您主动提供的银行信息退回，需要七个工作日到账，为保证资金渠道准确，您可在发起提现前进行收款信息的确认。
            </span>
          </div>
          <div v-else>
            <span>1.为确保顺利提现，请在每月的1-5号之间发起返佣余额提现申请，其余时间无法进行返佣余额提现的动作。</span>
            <span>2.每月6-10号为智安财务账期处理时间，智安财务将在这个时间段统一处理返佣提现申请，请耐心等待。</span>
            <span>3.为保护您的账户资金安全，您每月可提现1次，提现的资金下限为100元，上限为30000元。</span>
            <span>
              4.所有通过线下电汇方式充值的款项，我们将通过线下汇款的方式根据您主动提供的银行信息退回，为保证资金渠道准确，您可在发起提现前进行收款信息的确认。
            </span>
          </div>
        </div>
        <el-form
          ref="form"
          :disabled="confirmBtnLoading"
          :model="baseForm"
          label-position="right"
          label-width="140px"
          :rules="rules"
        >
          <!--
              dialogState false 返佣提现
              dialogState true 线下提现
              fapiao 1 可提现金额
              fapiao 2 其他金额
          -->
          <el-form-item :label="'可提现金额' + '：'">
            <span v-if="!dialogState">￥ {{ withdrawFee }}</span>
            <span v-else>￥ {{ moneyInfo.offlineAmount }}</span>
          </el-form-item>
          <el-form-item :label="'本次提现金额' + '：'" prop="fapiao">
            <el-radio-group v-model="baseForm.fapiao">
              <el-radio label="1">￥{{ dialogState ? moneyInfo.offlineAmount : withdrawFee }}</el-radio>
              <el-radio label="2">其他金额</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="'其他金额' + '：'" v-if="baseForm.fapiao == '2'" prop="amount">
            <el-row class="icon-inline">
              <el-input-number
                :min="0.01"
                :max="30000"
                :precision="2"
                v-if="!dialogState"
                v-model="baseForm.amount"
                :class="baseForm.amount > withdrawFee ? 'overWaring' : 'default'"
              />
              <el-input-number
                :min="0.01"
                :max="30000"
                :precision="2"
                v-if="dialogState"
                v-model="baseForm.amount"
                :class="baseForm.amount > moneyInfo.offlineAmount ? 'overWaring' : 'default'"
              />
              <za-svg-icon
                v-if="baseForm.amount > withdrawFee && !dialogState"
                icon-class="za-wanning"
                style="font-size: 23px; margin-left: 5px"
              ></za-svg-icon>
              <za-svg-icon
                v-if="baseForm.amount > moneyInfo.offlineAmount && dialogState"
                icon-class="za-wanning"
                style="font-size: 23px; margin-left: 5px"
              ></za-svg-icon>
            </el-row>
            <div style="color: red; font-size: 12px" v-if="baseForm.amount > withdrawFee && !dialogState">
              提现金额不得高于可提现金额
            </div>
            <div style="color: red; font-size: 12px" v-if="baseForm.amount > moneyInfo.offlineAmount && dialogState">
              提现金额不得高于可提现金额
            </div>
          </el-form-item>
          <el-form-item :label="'提现银行开户名' + '：'" prop="bankAccount">
            <el-input
              v-model="baseForm.bankAccount"
              :placeholder="translateTitle('placeTxt') + translateTitle('askfor.contract')"
              disabled
            />
            <span class="tips">需与账户实名认证主体保持一致</span>
          </el-form-item>
          <el-form-item :label="'提现银行账号' + '：'" required>
            <el-col :span="5">
              <el-form-item prop="bankType">
                <el-select v-model="baseForm.bankType">
                  <el-option :label="item.label" :value="item.value" v-for="item in bankTypeOptions" :key="item.value">
                    {{ item.label }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="19">
              <el-form-item prop="bankNumber">
                <el-input
                  :placeholder="baseForm.select == '1' ? '请输入个人提现银行账号' : '请输入提现银行账号'"
                  v-model="baseForm.bankNumber"
                  class="input-with-select"
                />
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item :label="'提现银行信息' + '：'" prop="bankName">
            <el-select v-model="baseForm.bankName" style="width: 100%">
              <el-option
                :label="item.label"
                :value="item.value"
                v-for="(item, index) in blankList"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="'提现银行开户地' + '：'" prop="bankAddr">
            <el-cascader
              size="large"
              :options="options"
              v-model="baseForm.seleAddr"
              @change="handleChange"
              style="width: 100%"
            ></el-cascader>
          </el-form-item>
        </el-form>
        <div style="margin-left: 40px">
          <el-checkbox v-model="baseForm.checked" v-if="dialogState">
            我已了解提现后的余额若不足以支付按需产品账单时，该产品将被停服
          </el-checkbox>
          <el-checkbox v-model="baseForm.checked" v-if="!dialogState">已知晓，线下提现申请后无法撤销</el-checkbox>
        </div>
      </div>
      <div slot="footer">
        <za-button :disabled="confirmBtnLoading" :type="buttonTypeObj.GREY" @click="dialogVisible = false">
          {{ translateTitle('btn.cancel') }}
        </za-button>
        <!-- 原路提现 -->
        <!-- 默认 -->
        <za-button
          :loading="confirmBtnLoading"
          @click="handleSubmitForm"
          v-if="!dialogState && baseForm.fapiao == '1'"
          :disabled="!baseForm.checked"
        >
          {{ translateTitle('btn.submit') }}
        </za-button>
        <!-- 其他金额 -->
        <za-button
          :loading="confirmBtnLoading"
          @click="handleSubmitForm"
          v-if="!dialogState && baseForm.fapiao == '2'"
          :disabled="!baseForm.checked || baseForm.amount > withdrawFee"
        >
          {{ translateTitle('btn.submit') }}
        </za-button>

        <!-- 线下提现 -->
        <!-- 默认 -->
        <za-button
          :loading="confirmBtnLoading"
          @click="handleSubmitForm"
          v-if="dialogState && baseForm.fapiao == '1'"
          :disabled="!baseForm.checked || moneyInfo.offlineAmount <= 0"
        >
          {{ translateTitle('btn.submit') }}
        </za-button>
        <!-- 其他金额 -->
        <za-button
          :loading="confirmBtnLoading"
          @click="handleSubmitForm"
          v-if="dialogState && baseForm.fapiao == '2'"
          :disabled="!baseForm.checked || baseForm.amount > moneyInfo.offlineAmount"
        >
          {{ translateTitle('btn.submit') }}
        </za-button>
      </div>
    </za-dialog>

    <!--    提现规则弹框-->
    <el-dialog title="提现规则说明" width="700px" :visible.sync="withdrawRuleVisible">
      <div class="blue_font_bold">不可提现金额</div>
      <div class="grey_font_bold">申请的提现金额不能大于可提现金额，如下原因可能影响您现金账户余额的自助提现：</div>
      <li>您有后付费产品或后付费资源释放小于等于24小时，需要在账户中保留 100 元。</li>
      <li>您的部分现金余额已在提现流程中。</li>
      <li>您的账户存在欠票或欠款。</li>
      <li>您单次提现金额超过上线30000元或1月内超出提现1次。</li>
      <li>您的账户在智安云激活时间不满24小时，请激活满24小时后再申请提现。</li>
      <span slot="footer" class="dialog-footer">
        <el-button @click="withdrawRuleVisible = false">取 消</el-button>
        <el-button type="primary" @click="withdrawRuleVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import OccTable from '@/za/components/occTable/index'
  import { CodeToText, regionData } from 'element-china-area-data'
  import zaTabs from '@/za/components/zaTabs'

  import {
    apiCertificationName,
    apiGetWithAmount,
    apiSubmitDrawal,
    withdrawallist,
  } from '@/api/moneyCenter/expenseManagement/moneyExtract'
  import { apiCashBackToBalance, apiGetPopularBalance } from '@/api/management/overview'
  import { mapGetters } from 'vuex'

  export default {
    components: { OccTable, zaTabs },
    data() {
      return {
        withdrawFee: 0, //返佣余额
        // 返佣提现按钮禁用判断 （用户返佣余额提现仅能在每月 1 日-5 日发起，其他时间提现按钮置灰不可点击）
        cashBackFlag: true,
        withdrawRuleVisible: false, //提现规则说明弹框
        promotionAuthority: true, //推广权限
        titleList: [
          {
            name: '提现申请',
            key: '提现申请',
          },
        ],
        bankTypeOptions: [
          {
            label: '个人账户',
            value: 1,
          },
          {
            label: '企业账户',
            value: 2,
          },
        ],
        rules: {
          amount: [{ required: true, trigger: ['blur', 'change'], message: '请输入或编辑金额！' }],
          bankType: [{ required: true, trigger: 'change', message: '请选择提现银行账户！' }],
          bankNumber: [{ required: true, trigger: 'blur', message: '请输入提现银行账号！' }],
          bankName: [{ required: true, trigger: 'change', message: '请选择提现银行信息！' }],
          bankAddr: [{ required: true, trigger: 'change', message: '请选择提现银行开户地！' }],
        },
        moneyInfo: {
          offlineAmount: '',
          onlineAmount: '',
          withdrawalAmount: '',
        },
        options: regionData,
        selectedOptions: [],
        dialogState: false,
        title: '',
        total: 0,
        pickTime: [],
        activeName: 'first',
        pageObj: {
          startAt: '',
          endAt: '',
          pageNo: 1,
          pageSize: 10,
        },
        baseForm: {
          type: null,
          amount: '',
          bankAccount: '',
          bankType: null,
          bankNumber: '',
          bankName: '',
          bankAddr: '',
          checked: false,
          fapiao: '1',
          seleAddr: [],
        },
        blankList: [
          { label: '中国人民银行', value: '中国人民银行' },
          { label: '中国工商银行', value: '中国工商银行' },
          { label: '中国建设银行', value: '中国建设银行' },
          { label: '汇丰银行', value: '汇丰银行' },
          { label: '中国银行', value: '中国银行' },
          { label: '中国农业银行', value: '中国农业银行' },
          { label: '交通银行', value: '交通银行' },
          { label: '招商银行', value: '招商银行' },
          { label: '上海浦东发展银行', value: '上海浦东发展银行' },
        ],
        tableData: [],
        columObj: {
          // 控制选择框
          selection: false,
          isOp: false,
          width: '300px',
          columnData: [
            {
              text: true,
              prop: 'createAt',
              label: '申请提现时间',
              width: '',
              flag: true,
              align: 'center',
            },
            {
              ownDefined: true,
              prop: 'amount',
              label: '提现金额',
              flag: true,
              width: '',
              align: 'center',
              ownDefinedReturn: (row) => {
                return '￥' + row.amount
              },
            },
            {
              ownDefined: true,
              prop: 'payType',
              label: '返回渠道',
              flag: true,
              width: '',
              align: 'center',
              ownDefinedReturn: (row) => {
                return row.payType === 'offline' ? '线下' : '-'
              },
            },
            {
              text: true,
              prop: 'state',
              label: '状态',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              text: true,
              prop: 'desc',
              label: '备注',
              flag: true,
              width: '',
              align: 'center',
            },
          ],
        },
        certificationName: '已实名',
      }
    },
    computed: {
      ...mapGetters({
        popularizeInfo: 'user/popularizeInfo', // 云推官详情
        isCloudPushOfficer: 'user/isRegister', //是否为智安云推官
      }),
    },
    async created() {
      this.title = this.$route.meta.title
      this.dialogTypeObj.ADD.text = '返佣提现'
      this.dialogTypeObj.EDIT.text = '线下提现'
      await this.getList()
      this.getCashFun()
    },

    methods: {
      async getList() {
        this.isLoading = true
        let res = await apiCertificationName().catch()
        this.certificationName = res.data.name || ''
        if (res.data.name) {
          this.baseForm.bankAccount = res.data.name
        }
        this.onSubmitData(apiGetPopularBalance, null, null, false, false, (res) => {
          this.withdrawFee = res.data.withdrawFee
        })
        this.onSubmitData(withdrawallist, this.pageObj, null, false, false, (res) => {
          this.tableData = res.data.list
          this.tableData.forEach((val) => {
            if (val.state == '处理中') {
              setTimeout(() => {
                this.getList()
              }, 10000)
            }
          })
          this.total = res.data.total
        })
        this.getWithAmount()
        await this.$store.dispatch('user/getBalanceData')
      },
      async getWithAmount() {
        let res = await apiGetWithAmount().catch()
        this.moneyInfo.offlineAmount = res.data.offlineAmount
        // this.moneyInfo.onlineAmount = res.data.onlineAmount;
        this.moneyInfo.withdrawalAmount = res.data.amount
      },
      datePickEnd(date) {
        if (date) {
          this.pageObj.startAt = this.$changeTimeStamp(date[0])
          this.pageObj.endAt = this.$changeTimeStamp(date[1]) + 3600 * 24 - 1
        } else {
          this.pageObj.startAt = ''
          this.pageObj.endAt = ''
        }
        this.getList()
      },
      original() {
        this.dialogType = this.dialogTypeObj.ADD.type
        this.baseForm.type = 1
        this.dialogState = true
        this.dialogVisible = true
      },
      // 可用金额提现
      offline() {
        this.dialogType = this.dialogTypeObj.EDIT.type
        this.baseForm.type = 2
        this.resetForm()
        this.dialogState = true
        this.dialogVisible = true
      },
      // 返佣余额提现
      cashBack() {
        this.dialogType = this.dialogTypeObj.ADD.type
        this.resetForm()
        this.dialogState = false
        this.dialogVisible = true
      },
      resetForm() {
        const bankAccount = this.baseForm.bankAccount
        this.baseForm = {
          type: null,
          amount: '',
          bankAccount: bankAccount,
          bankType: null,
          bankNumber: '',
          bankName: '',
          bankAddr: '',
          checked: false,
          fapiao: '1',
          seleAddr: [],
        }
      },
      // 余额提现
      handleSubmitForm() {
        if (!this.baseForm.checked) {
          this.$msgWarning('请确认您已经了解！')
          return
        }
        /**
         * @params fapiao：1、可提现金额，2、自定义金额
         * @params dialogType：1、返佣，2、线下
         * */
        if (this.baseForm.fapiao == 1) {
          if (this.dialogType == 1) {
            this.baseForm.amount = this.withdrawFee
          } else {
            this.baseForm.amount = this.moneyInfo.offlineAmount
          }
        }
        const submitForm = {
          amount: this.baseForm.amount,
          bankType: this.baseForm.bankType,
          bankName: this.baseForm.bankName,
          bankAddr: this.baseForm.bankAddr,
          bankNumber: this.baseForm.bankNumber,
          bankAccount: this.baseForm.bankAccount,
        }
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.dialogType === 1) {
              await this.submitFun(apiCashBackToBalance, submitForm)
            } else {
              let res = await apiSubmitDrawal(this.baseForm).finally(() => (this.confirmBtnLoading = false))
              if (res.code === 0) {
                this.$msgSuccess('提现申请提交成功')
                this.dialogVisible = false
                await this.getList()
              }
            }
          } else {
            return false
          }
        })
      },
      async submitFun(api, data) {
        let res = await api(data).finally(() => (this.confirmBtnLoading = false))
        if (res.code === 0) {
          this.$msgSuccess('提现申请提交成功')
          this.dialogVisible = false
          await this.getList()
        }
      },
      handleChange(value) {
        if (value[1] != null && value[2] != null) {
          var dz = CodeToText[value[0]] + '/' + CodeToText[value[1]] + '/' + CodeToText[value[2]]
          this.addressid = value[2]
        } else {
          if (value[1] != null) {
            dz = CodeToText[value[0]] + '/' + CodeToText[value[1]]
            this.addressid = value[1]
          } else {
            dz = CodeToText[value[0]]
            this.addressid = value[0]
          }
        }
        this.address = dz
        this.baseForm.bankAddr = dz
      },
      withdrawalRuleDialog() {
        this.withdrawRuleVisible = true
      },
      /**
       * @description 判断返佣提现功能是否开启
       * 1、普通客户：功能禁用，返佣余额置为0
       * 2、云推官客户：首先判断当前时间是否为  1-5号
       *    a、是：判断推广权限（1、开启，2、暂停，3、失效）
       *       1、开启：功能放开
       *       2，3（暂停/失效）：功能禁用，悬浮账号异常提示
       *    b、否：直接禁用功能，悬浮 只有1-5号才能提现 提示
       * */
      getCashFun() {
        if (this.isCloudPushOfficer) {
          // 获取当前日期
          const currentDate = new Date()
          // 获取当前日期的日期部分（即天数）
          const currentDay = currentDate.getDate()
          // 判断当前日期是否为 1-5 号
          if (currentDay <= 5) {
            if (this.popularizeInfo.state === 1) {
              this.cashBackFlag = false
            } else {
              // 如果 推广权限 为 暂停/失效 返佣提现 按钮禁用
              this.cashBackFlag = true
              this.promotionAuthority = false
            }
          }
        } else {
          this.cashBackFlag = true
          // this.promotionAuthority = false
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import '~@/za/styles/mixin.scss';

  ::v-deep {
    .el-dialog__body {
      padding: 20px 20px 30px;
    }

    .el-dialog__title {
      font-weight: 700;
    }
  }

  li {
    font-size: 15px;
    padding: 3px 0;
    color: #999;
  }

  .blue_font_bold {
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 700;
    color: #21a6ff;
    margin-bottom: 8px;
  }

  .grey_font_bold {
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 700;
    color: #9d9a9a;
    margin-bottom: 5px;
  }

  .expenseManagement-container {
    padding: $base-main-app-padding;
    background: rgba(255, 255, 255, 1);
  }

  .title {
    @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
    width: 70px;
    height: 20px;
    margin-bottom: 20px;
  }

  .prompt {
    width: 100%;
    height: 135px;
    background: #fffbe6;
    border-radius: 2px;
    border: 1px solid #ffe58f;
    padding: 20px 20px;

    span {
      height: 17px;
      display: block;
      font-size: 12px;
      line-height: 17px;
      margin-bottom: 11px;
      color: #555555;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }

    .blue_font {
      display: inline;
    }
  }

  .withdraw-offline {
    height: 162px;
  }

  .cashback {
    height: 178px;
  }

  .card_mottom {
    margin-bottom: 20px;
    padding: 10px;

    .cardUl {
      height: 90px;
      margin-right: 20px;

      .title {
        font-size: 12px;
        width: 100%;
        margin-bottom: 14px;
      }

      .operation {
        display: flex;
        justify-content: space-between;

        div:first-child {
          span:first-child {
            font-size: 26px;
            font-family: DINAlternate-Bold, DINAlternate;
            font-weight: bold;
            color: #000000;
          }
        }
      }
    }
  }

  .heard {
    margin-bottom: 20px;
  }

  .waring {
    height: 40px;
    background: #fffbe6;
    border-radius: 2px;
    border: 1px solid #ffe58f;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 40px;
  }

  ::v-deep {
    .input-with-select .el-input--suffix {
      width: 130px;
    }

    .input-with-select .el-input-group__prepend {
      background-color: #fff;
    }

    .el-card__body {
      padding: 0;
      padding: 10px;
    }
  }

  .overWaring {
    border: 1px solid red;
    border-radius: 5px;
  }

  .icon-inline {
    display: flex;
    align-items: center;
  }
</style>