var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "overView-center_bg" }, [
    _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v(_vm._s(_vm.title))]),
    ]),
    _c(
      "div",
      { staticClass: "overView-top" },
      [_c("money-surplus"), _c("money-available"), _c("rebate-balance")],
      1
    ),
    _c(
      "div",
      { staticClass: "overView-content" },
      [_c("reminder"), _c("card-roll")],
      1
    ),
    _c("div", { staticClass: "overView-btn" }, [_c("btndetails")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }