import request from '@/utils/request'
import {
    METHOD,
    GET_ORDER_RESOURCE,
    GET_RESOURCE_LIST,
    ORDER_RESOURCE_RENEW,
    ORDER_RENEW_SUBMIT,
    ORDER_RENEW_AUTO_RENRW,
    ORDER_RENEW_CLOSE_RENRW,
    GET_AFTERPAY_LIST
} from '@/api/api.config'

//获取资源管理列表参数接口
export function apiResource(data) {
    return request({
        url: GET_ORDER_RESOURCE,
        method: METHOD.GET,
        params: data,
    })
}

//获取资源管理列表接口
export function apiResourceList(data) {
    return request({
        url: GET_RESOURCE_LIST,
        method: METHOD.GET,
        params: data,
    })
}

//获取资源管理续费下一步接口
export function apiOrderResourceRenew(data) {
    return request({
        url: ORDER_RESOURCE_RENEW,
        method: METHOD.POST,
        data,
    })
}

//获取资源管理续费提交订单接口
export function apiOrderSubmit(data) {
    return request({
        url: ORDER_RENEW_SUBMIT,
        method: METHOD.POST,
        data,
    })
}

//获取资源管理设置自动续费接口
export function apiOrderaAutoSubmit(data) {
    return request({
        url: ORDER_RENEW_AUTO_RENRW,
        method: METHOD.POST,
        data,
    })
}
//获取资源管理关闭自动续费接口
export function apiOrderCloseAutoRenew(data) {
    return request({
        url: ORDER_RENEW_CLOSE_RENRW,
        method: METHOD.POST,
        data,
    })
}
//获取所有后付费订单
export function apiAfterPayOrder(data) {
    return request({
        url: GET_AFTERPAY_LIST,
        method: METHOD.GET,
        data,
    })
}