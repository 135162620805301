var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "customerTab" }, [
    _c(
      "div",
      { staticClass: "customerTab_form" },
      [
        _c(
          "el-form",
          {
            attrs: { inline: true, model: _vm.pageObj },
            nativeOn: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "客户类型" } },
              [
                _c(
                  "el-select",
                  {
                    on: {
                      change: function ($event) {
                        return _vm.getList()
                      },
                    },
                    model: {
                      value: _vm.pageObj.state,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageObj, "state", $$v)
                      },
                      expression: "pageObj.state",
                    },
                  },
                  _vm._l(_vm.userTypeOptions, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticStyle: { "margin-left": "15px !important" },
                attrs: { label: "关联时间" },
              },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "daterange",
                    "range-separator": "至",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期",
                  },
                  on: { change: _vm.datePickEnd },
                  model: {
                    value: _vm.pickTime,
                    callback: function ($$v) {
                      _vm.pickTime = $$v
                    },
                    expression: "pickTime",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "关联状态", "label-width": "100px" } },
              [
                _c(
                  "el-select",
                  {
                    on: {
                      change: function ($event) {
                        return _vm.getList()
                      },
                    },
                    model: {
                      value: _vm.pageObj.relatedState,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageObj, "relatedState", $$v)
                      },
                      expression: "pageObj.relatedState",
                    },
                  },
                  _vm._l(_vm.typeOptions, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form",
          {
            attrs: { inline: true, model: _vm.pageObj },
            nativeOn: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { "label-width": "100px" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "输入客户名或客户ID查找" },
                  model: {
                    value: _vm.pageObj.keyword,
                    callback: function ($$v) {
                      _vm.$set(_vm.pageObj, "keyword", $$v)
                    },
                    expression: "pageObj.keyword",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "za-button",
                  {
                    attrs: { icon: _vm.buttonIconObj.SEARCH },
                    on: { click: _vm.handleQuery },
                  },
                  [_vm._v(_vm._s(_vm.translateTitle("btn.query")))]
                ),
                _c(
                  "za-button",
                  {
                    attrs: {
                      icon: _vm.buttonIconObj.RESET,
                      type: _vm.buttonTypeObj.GREY,
                    },
                    on: { click: _vm.handleResetQuery },
                  },
                  [_vm._v(" " + _vm._s(_vm.translateTitle("btn.reset")) + " ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("occ-table", {
          attrs: {
            tableData: _vm.tableData,
            columObj: _vm.columObj,
            isOp: _vm.columObj.isOp,
          },
        }),
        _c("pagination", {
          attrs: {
            limit: _vm.pageObj.pageSize,
            page: _vm.pageObj.pageNo,
            total: _vm.total,
          },
          on: {
            "update:limit": function ($event) {
              return _vm.$set(_vm.pageObj, "pageSize", $event)
            },
            "update:page": function ($event) {
              return _vm.$set(_vm.pageObj, "pageNo", $event)
            },
            pagination: _vm.getList,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }