var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "za-loading",
          rawName: "v-za-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "reminder",
    },
    [
      _c("div", { staticClass: "title" }, [_vm._v("返利概况")]),
      _c(
        "div",
        { staticClass: "card_bottom" },
        [
          _c(
            "el-row",
            _vm._l(_vm.amountArr, function (item, index) {
              return _c(
                "el-col",
                { key: index, staticClass: "cardUl", attrs: { span: 8 } },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(item.label)),
                  ]),
                  _c("div", { staticClass: "operation" }, [
                    _c("span", [_vm._v("¥")]),
                    _c("span", [_vm._v(_vm._s(item.value))]),
                  ]),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }