<template>
  <div class="footer" v-za-loading="isLoading">
    <div class="head">
      <div class="title">推广收入</div>
      <div class="details" @click="goDetail">结算明细</div>
    </div>
    <occ-table :tableData="tableData" :columObj="columObj" :isOp="columObj.isOp"></occ-table>
  </div>
</template>
<script>
  import { apiPopularizeSettlementList } from '@/api/management/overview'

  export default {
    data() {
      return {
        tableData: [],
        pageObj: {
          time: 0,
          pageNo: 1,
          pageSize: 5,
        },
        columObj: {
          // 控制选择框
          selection: false,
          isOp: false,
          width: '300px',
          columnData: [
            {
              text: true,
              prop: 'month',
              label: '结算时间',
              width: '',
              flag: true,
              align: 'center',
            },
            {
              ownDefined: true,
              prop: 'orderNums',
              label: '结算订单',
              color: '#02a7f0',
              flag: true,
              width: '',
              align: 'center',
              ownDefinedReturn: (row) => {
                return `${row.orderNums}个`
              },
            },
            {
              ownDefined: true,
              prop: 'commissionAmount',
              label: '结算推广佣金',
              flag: true,
              width: '',
              align: 'center',
              ownDefinedReturn: (row) => {
                return `￥${row.commissionAmount}`
              },
            },
            {
              ownDefined: true,
              prop: 'taxAmount',
              label: '代扣税费',
              flag: true,
              width: '',
              align: 'center',
              ownDefinedReturn: (row) => {
                return `￥${row.taxAmount}`
              },
            },
            {
              ownDefined: true,
              prop: 'receivableAmount',
              label: '本期应收佣金',
              flag: true,
              width: '',
              align: 'center',
              ownDefinedReturn: (row) => {
                return `￥${row.receivableAmount}`
              },
            },
            {
              ownDefined: true,
              prop: 'realityAmount',
              label: '本期实收佣金',
              flag: true,
              width: '',
              align: 'center',
              ownDefinedReturn: (row) => {
                return `￥${row.realityAmount}`
              },
            },
          ],
        },
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.onSubmitData(apiPopularizeSettlementList, this.pageObj, null, false, false, (res) => {
          res.data.list ? (this.tableData = res.data.list) : (this.tableData = [])
        })
      },
      goDetail() {
        this.$router.push('/promotionManagement/effect/settlementDetails')
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import '~@/za/styles/mixin.scss';

  .footer {
    .head {
      display: flex;
      justify-content: space-between;

      .title {
        @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
        width: 70px;
        height: 20px;
        margin-bottom: 20px;
      }

      .details {
        font-size: 14px;
        color: #02a7f0;
        cursor: pointer;
      }
    }

    background-color: #ffffff;
    padding: 20px;
  }
</style>