var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [_c("loginHeard")], 1),
    _c("div", { staticClass: "main" }, [
      _c("div", { staticClass: "center" }, [
        _c(
          "div",
          [
            _c("el-image", {
              staticClass: "registerSuccess",
              attrs: { src: require("@/assets/login/Group.png") },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "title" }, [_vm._v("注册成功")]),
        _c("div", { staticClass: "prompt" }, [
          _c("div", { staticClass: "prompt_heard" }, [
            _c("div", { staticClass: "prompt_path" }, [
              _c("span", [_vm._v("温馨提示：您可以前往账号中心")]),
              _c(
                "span",
                {
                  staticClass: "prompt_blue",
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("/accountCenter/basicInfo")
                    },
                  },
                },
                [_vm._v("完善个人信息，")]
              ),
              _c(
                "span",
                {
                  staticClass: "prompt_blue",
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("/accountCenter/safetySet")
                    },
                  },
                },
                [_vm._v("修改密码，")]
              ),
              _c(
                "span",
                {
                  staticClass: "prompt_blue",
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("/accountCenter/safetySet")
                    },
                  },
                },
                [_vm._v("绑定邮箱，")]
              ),
              _c(
                "span",
                {
                  staticClass: "prompt_blue",
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("/accountCenter/safetySet")
                    },
                  },
                },
                [_vm._v("修改手机号，")]
              ),
              _c("span", [_vm._v("等")]),
            ]),
          ]),
          _c("div", { staticClass: "prompt_main" }, [
            _c("div", { staticClass: "prompt_info" }, [
              _c("div", [_vm._v("手机号")]),
              _c("div", [_vm._v("登录用户名")]),
              _c("div", [_vm._v(_vm._s(_vm.infoObj.phone))]),
              _c("div", [_vm._v(_vm._s(_vm.infoObj.userName))]),
            ]),
          ]),
          _c("div", { staticClass: "prompt_foot" }, [
            _c("span", [_vm._v("完成实名认证，享受特惠云产品活动")]),
            _c(
              "div",
              { staticClass: "prompt_btn" },
              [
                _c(
                  "za-button",
                  {
                    staticClass: "button",
                    attrs: { loading: _vm.confirmBtnLoading, size: 14 },
                    on: {
                      click: function ($event) {
                        return _vm.$router.push(
                          "/accountCenter/nameCertification"
                        )
                      },
                    },
                  },
                  [_vm._v(" 实名认证 ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "prompt_btn" },
              [
                _c(
                  "za-button",
                  {
                    staticClass: "goback",
                    attrs: { loading: _vm.confirmBtnLoading, size: 14 },
                    on: {
                      click: function ($event) {
                        return _vm.$router.push("/login")
                      },
                    },
                  },
                  [_vm._v(" 返回 ")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
    _c("div", [_c("loginFoot")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }