<template>
  <div class="details" v-za-loading="isLoading">
    <el-card>
      <div class="title">消费明细</div>
      <occ-table :tableData="tableData" :columObj="columObj" :isOp="columObj.isOp" :styleHeight="height"></occ-table>
      <!-- <pagination
                    :limit.sync="pageObj.pageSize"
                    :page.sync="pageObj.pageNo"
                    :total="total"
                    @pagination="getList"
            /> -->
    </el-card>
  </div>
</template>
<script>
import { billList } from '@/api/moneyCenter/expenseManagement/details'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      height: '678',
      total: 0,
      pageObj: {
        //分页对象
        pageNo: 1,
        pageSize: 10,
        tradeAccount: '',
        orderNumber: '',
        tradeNo: '',
        transactionType: '',
        payType: '',
        payStartAt: '',
        payEndAt: '',
      },
      tableData: [],
      payOptions: [
        {
          id: 'wechat',
          name: '微信支付',
        },
        {
          id: 'alipay',
          name: '支付宝支付',
        },
        {
          id: 'balance',
          name: '余额支付',
        },
        {
          id: 'offline',
          name: '线下支付',
        },
        // {
        //     id:'unionpay',
        //     name:'网银支付'
        // },
        // {
        //     id:'xianxiapay',
        //     name:'线下汇款'
        // }
      ],
      // 计费类型
      payTypeOptions: [
        { id: '0', name: '-' },
        { id: '1', name: '预付费' },
        { id: '2', name: '后付费' },
        { id: '3', name: '预付费+后付费' },
      ],
      typeOptions: [
        { id: '1', name: '充值' },
        { id: '2', name: '新购' },
        { id: '3', name: '续费' },
        { id: '4', name: '提现' },
        { id: '5', name: '退款' },
        { id: '6', name: '后付费结算' },
      ],
      columObj: {
        // 控制选择框
        selection: false,
        isOp: false,
        columnData: [
          {
            text: true,
            prop: 'orderNumber',
            label: '交易流水',
            showOverFlow: false,
            width: '220',
            flag: true,
            align: 'center',
          },
          {
            text: true,
            prop: 'resourceId',
            label: '资源ID',
            showOverFlow: false,
            width: '220',
            flag: true,
            align: 'center',
          },
          {
            text: true,
            prop: 'resourceName',
            label: '资源名称',
            showOverFlow: false,
            width: '220',
            flag: true,
            align: 'center',
          },
          {
            ownDefined: true,
            prop: 'payType',
            label: '计费类型',
            flag: true,
            width: '',
            align: 'center',
            ownDefinedReturn: (row) => {
              let result = this.payTypeOptions.filter((item) => {
                return item.id == row.payType
              })
              return result[0].name
            },
          },
          {
            ownDefined: true,
            prop: 'transactionType',
            label: '交易类型',
            flag: true,
            width: '',
            align: 'center',
            ownDefinedReturn: (row) => {
              let result = this.typeOptions.filter((item) => {
                return item.id == row.transactionType
              })
              // 处理返回的  transactionType  2新购3续费6后付款  都展示为消费
              if (result[0].id === '2' || result[0].id === '3' || result[0].id === '6') {
                result = [
                  {
                    id: '2',
                    name: '消费',
                  },
                ]
              }
              return result && result.length > 0 && result[0].name ? result[0].name : '-'
            },
          },
          {
            ownDefined: true,
            prop: 'payMethod',
            label: '交易方式',
            flag: true,
            width: '',
            align: 'center',
            ownDefinedReturn: (row) => {
              let result = this.payOptions.filter((item) => {
                return item.id == row.payMethod
              })
              return result && result.length > 0 && result[0].name ? result[0].name : '-'
            },
          },
          {
            ownDefined: true,
            prop: 'amount',
            label: '交易金额',
            flag: true,
            width: '',
            align: 'center',
            ownDefinedReturn: (row) => {
              return '￥' + row.amount
            },
          },
            {
              ownDefined: true,
              prop: 'balance',
              label: '账户余额',
              flag: true,
              width: '',
              align: 'center',
              ownDefinedReturn: (row) => {
                return '￥' + row.balance
              },
            },
          //   {
          //     text: true,
          //     prop: 'tradeNo',
          //     label: '交易流水',
          //     flag: true,
          //     width: '280',
          //     align: 'center',
          //   },
          {
            text: true,
            prop: 'payAt',
            label: '交易时间',
            flag: true,
            width: '',
            align: 'center',
          },
          {
            text: true,
            prop: 'tradeAccount',
            label: '交易账号',
            flag: true,
            width: '',
            align: 'center',
          },
          {
            text: true,
            prop: 'desc',
            label: '备注',
            flag: true,
            width: '',
            align: 'center',
          },
        ],
      },
    }
  },
  created() {
    this.getList()
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },
  methods: {
    getList() {
      this.onSubmitData(billList, this.pageObj, null, false, false, (res) => {
        this.total = res.data.total
        this.tableData = res.data.list || []
        // 赋值交易账户
        this.tableData.forEach((item) => {
          item.tradeAccount = this.userInfo.username
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~@/za/styles/mixin.scss';

.details {
  .title {
    @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
    width: 100%;
    height: 20px;
    margin-bottom: 20px;
  }
  width: 100%;
}
</style>