<template>
  <div class="moneySurplus" v-za-loading="isLoading">
    <div class="container">
      <div class="title">
        <span>返佣余额</span>
        <el-tooltip effect="light" placement="right-start">
          <div slot="content">
            <div class="orange_font">返佣余额:指当前账号通过推广下级用户消费所获得的收益</div>
            <div class="grey_font">返佣余额提现需要先将余额转出到可提现钱包。</div>
          </div>
          <i class="el-icon-info blue_font"></i>
        </el-tooltip>
      </div>
      <div class="main">
        <div>
          <span>{{ withdrawFee }}</span>
          <span>元</span>
        </div>
        <div v-if="isCloudPushOfficer">
          <el-button type="primary" @click="navigateTo('transfer')">转出</el-button>
          <el-button plain @click="navigateTo('withdraw')">提现</el-button>
        </div>
        <div v-else>
          <el-button type="primary" @click="toPath">加入渠道</el-button>
        </div>
      </div>
    </div>

    <!--    渠道失效弹框-->
    <el-dialog width="800px" :visible.sync="withdrawOutDialog">
      <div class="prompt">
        <za-svg-icon class="svgIcon" icon-class="za-wanning"></za-svg-icon>
        <span>您当前所登录的账号云推官身份已失效，若您的账号中存在未结算完成的资金，请联系客服人员申请全部提出。</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="withdrawOutDialog = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { apiGetPopularBalance } from '@/api/management/overview'
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {
        withdrawFee: 0, //返佣余额
        withdrawOutDialog: false, //渠道失效转出弹框
        routerObj: {
          transfer: '/promotionManagement/effect/settlementDetails',
          withdraw: '/moneyCenter/expenseManagement/moneyExtract',
        },
      }
    },
    computed: {
      ...mapGetters({
        isCloudPushOfficer: 'user/isRegister', //是否为智安云推官
        popularizeInfo: 'user/popularizeInfo', // 云推官详情
      }),
    },
    created() {
      this.getList()
    },
    methods: {
      /**
       *@description 获取返佣余额、是否为云推官
       *@params  withdrawFee 、isCloudPushOfficer
       * */
      getList() {
        this.onSubmitData(apiGetPopularBalance, null, null, false, false, (res) => {
          this.withdrawFee = res.data.withdrawFee
        })
      },
      toPath() {
        window.open(`${process.env.VUE_APP_PORTAL_TARGET_URL}/partners/cps`, '_self')
      },
      /**
       * @description 转出按钮跳转逻辑
       * @type transfer：转出 ；withdraw：提现
       * */
      navigateTo(type) {
        this.popularizeInfo.state === 3 ? (this.withdrawOutDialog = true) : this.$router.push(this.routerObj[type])
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import '~@/za/styles/mixin.scss';

  .moneySurplus {
    height: 143px;
    width: 33%;
    padding: 20px;
    background: #ffffff;
    margin-left: 15px;

    .container {
      //border-right: 1px solid #f5f5f5;
    }

    .title {
      @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
      width: 100%;
      height: 20px;
      margin-bottom: 14px;
    }

    .main {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      div:first-child {
        span:first-child {
          font-size: 30px;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #000000;
        }
      }

      div:nth-of-type(2) {
        margin-right: 20px;
      }
    }

    .btn {
      font-size: 14px;
      color: #3370ff;
      cursor: pointer;
    }
  }

  .prompt {
    width: 100%;
    height: 60px;
    background: #fffbe6;
    border-radius: 2px;
    border: 1px solid #ffe58f;
    padding: 20px 10px;

    .svgIcon {
      color: #faad13;
      font-size: 16px;
      margin-right: 6px;
    }
  }
</style>