import {isUpperCase} from '@/utils/validate'

export function getAutoSize(px) {
    let cWidth = document.documentElement.clientWidth
    let onePxSize = cWidth / 100
    return (px / 19.2) * onePxSize
}

/**
 * @description 格式化时间
 * @param time
 * @param cFormat
 * @returns {string|null}
 */
export function parseTime(time, cFormat) {
    if (arguments.length === 0) {
        return null
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
        date = time
    } else {
        if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
            time = parseInt(time)
        }
        if (typeof time === 'number' && time.toString().length === 10) {
            time = time * 1000
        }
        date = new Date(time)
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
    }
    return format.replace(/{([ymdhisa])+}/g, (result, key) => {
        let value = formatObj[key]
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value]
        }
        if (result.length > 0 && value < 10) {
            value = '0' + value
        }
        return value || 0
    })
}

/**
 * @description 格式化时间
 * @param time
 * @param option
 * @returns {string}
 */
export function formatTime(time, option) {
    if (('' + time).length === 10) {
        time = parseInt(time) * 1000
    } else {
        time = +time
    }
    const d = new Date(time)
    const now = Date.now()

    const diff = (now - d) / 1000

    if (diff < 30) {
        return '刚刚'
    } else if (diff < 3600) {
        // less 1 hour
        return Math.ceil(diff / 60) + '分钟前'
    } else if (diff < 3600 * 24) {
        return Math.ceil(diff / 3600) + '小时前'
    } else if (diff < 3600 * 24 * 2) {
        return '1天前'
    }
    if (option) {
        return parseTime(time, option)
    } else {
        return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
    }
}

/**
 * @description 将url请求参数转为json格式
 * @param url
 * @returns {{}|any}
 */
export function paramObj(url) {
    const search = url.split('?')[1]
    if (!search) {
        return {}
    }
    return JSON.parse(
        '{"' +
        decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') +
        '"}'
    )
}

/**
 * @description 父子关系的数组转换成树形结构数据
 * @param data
 * @returns {*}
 */
export function translateDataToTree(data) {
    const parent = data.filter((value) => value.parentId === 'undefined' || value.parentId === null)
    const children = data.filter((value) => value.parentId !== 'undefined' && value.parentId !== null)
    const translator = (parent, children) => {
        parent.forEach((parent) => {
            children.forEach((current, index) => {
                if (current.parentId === parent.id) {
                    const temp = JSON.parse(JSON.stringify(children))
                    temp.splice(index, 1)
                    translator([current], temp)
                    typeof parent.children !== 'undefined' ? parent.children.push(current) : (parent.children = [current])
                }
            })
        })
    }
    translator(parent, children)
    return parent
}

/**
 * 转化数结构
 * @param {Array} list 需要转化的一维数组
 * @param {string} mainMatches 主匹配项
 * @param {string} viceMatches 副匹配项
 * @returns {[]}
 */
export function composeTree(list = [], mainMatches = 'id', viceMatches = 'pid') {
    const data = JSON.parse(JSON.stringify(list)) // 浅拷贝不改变源数据
    const result = []
    if (!Array.isArray(data)) {
        return result
    }
    data.forEach((item) => {
        delete item.children
    })
    const map = {}
    data.forEach((item) => {
        map[item[mainMatches]] = item
    })
    data.forEach((item) => {
        const parent = map[item[viceMatches]]
        if (parent) {
            (parent.children || (parent.children = [])).push(item)
        } else {
            result.push(item)
        }
    })
    return result
}

/**
 * @description 树形结构数据转换成父子关系的数组
 * @param data
 * @returns {[]}
 */
export function translateTreeToData(data) {
    const result = []
    data.forEach((item) => {
        const loop = (data) => {
            result.push({
                id: data.id,
                name: data.name,
                parentId: data.parentId,
            })
            const child = data.children
            if (child) {
                for (let i = 0; i < child.length; i++) {
                    loop(child[i])
                }
            }
        }
        loop(item)
    })
    return result
}

/**
 * @description 10位时间戳转换
 * @param time
 * @returns {string}
 */
export function tenBitTimestamp(time) {
    const date = new Date(time * 1000)
    const y = date.getFullYear()
    let m = date.getMonth() + 1
    m = m < 10 ? '0' + m : m
    let d = date.getDate()
    d = d < 10 ? '0' + d : d
    let h = date.getHours()
    h = h < 10 ? '0' + h : h
    let minute = date.getMinutes()
    let second = date.getSeconds()
    minute = minute < 10 ? '0' + minute : minute
    second = second < 10 ? '0' + second : second
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second //组合
}

/**
 * @description 13位时间戳转换
 * @param time
 * @returns {string}
 */
export function thirteenBitTimestamp(time) {
    const date = new Date(time / 1)
    const y = date.getFullYear()
    let m = date.getMonth() + 1
    m = m < 10 ? '' + m : m
    let d = date.getDate()
    d = d < 10 ? '' + d : d
    let h = date.getHours()
    h = h < 10 ? '0' + h : h
    let minute = date.getMinutes()
    let second = date.getSeconds()
    minute = minute < 10 ? '0' + minute : minute
    second = second < 10 ? '0' + second : second
    return y + '年' + m + '月' + d + '日 ' + h + ':' + minute + ':' + second //组合
}

/**
 * @description 获取随机id
 * @param length
 * @returns {string}
 */
export function uuid(length = 32) {
    const num = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
    let str = ''
    for (let i = 0; i < length; i++) {
        str += num.charAt(Math.floor(Math.random() * num.length))
    }
    return str
}

/**
 * @description m到n的随机数
 * @param m
 * @param n
 * @returns {number}
 */
export function random(m, n) {
    return Math.floor(Math.random() * (m - n) + n)
}

/**
 * @description addEventListener
 * @type {function(...[*]=)}
 */
export const on = (function () {
    return function (element, event, handler, useCapture = false) {
        if (element && event && handler) {
            element.addEventListener(event, handler, useCapture)
        }
    }
})()

/**
 * @description removeEventListener
 * @type {function(...[*]=)}
 */
export const off = (function () {
    return function (element, event, handler, useCapture = false) {
        if (element && event) {
            element.removeEventListener(event, handler, useCapture)
        }
    }
})()

/**
 * @description 数组打乱
 * @param array
 * @returns {*}
 */
export function shuffle(array) {
    let m = array.length,
        t,
        i
    while (m) {
        i = Math.floor(Math.random() * m--)
        t = array[m]
        array[m] = array[i]
        array[i] = t
    }
    return array
}

/**
 * @description 获取base64图片
 * @param e
 * @param {function} callBack 回调函数
 */
export function getBase64Image(e, callBack) {
    const image = new Image()
    image.src = window.URL.createObjectURL(e.target.files[0])
    image.onload = () => {
        const base64Img = toBase64(image)
        if (callBack) callBack(base64Img)
    }
}

export function toBase64(img) {
    const canvas = document.createElement('canvas')
    canvas.width = img.width
    canvas.height = img.height
    const ctx = canvas.getContext('2d')
    ctx.drawImage(img, 0, 0, img.width, img.height)
    const ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase()
    return canvas.toDataURL('image/' + ext)
}

/**
 * @returns {string}
 */
export function createUniqueString() {
    const timestamp = +new Date() + ''
    const randomNum = parseInt((1 + Math.random()) * 65536) + ''
    return (+(randomNum + timestamp)).toString(32)
}

/**
 * @description 组件名称转换（ZaButton--->za-button）
 * @param str
 * @param symbol
 * @returns {string|*}
 */
export function compNameChange(str, symbol = '-') {
    if (!str) return ''
    let index = 0
    const stringArray = str.split('').map((t) => {
        let ret = ''
        if (isUpperCase(t)) {
            ret = t.toLowerCase()
            if (index !== 0) ret = symbol + ret
        } else ret = t
        index++
        return ret
    })
    return stringArray.join('')
}

/**
 * @description 设置浏览器图表
 * @param url ico图标
 * @returns {string|*}
 */
export function setLogoInfo(url) {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = url
    document.getElementsByTagName('head')[0].appendChild(link);
}

/**
 * @description 数组去重
 * @param url ico图标
 * @returns {string|*}
 */
export function sortArr(tempArr) {
    // let newArr = [];
    // for (let i = 0; i < tempArr.length; i++) {
    //     if (newArr.indexOf(tempArr[i].delName) == -1) {
    //         newArr.push(tempArr[i].delName);
    //     } else {
    //         tempArr.splice(i, 1);
    //     }
    //     ;
    // }
    // ;
    // return tempArr;
    var arr = []
    tempArr.map((v) => {
        arr.includes(v) || arr.push(v)
    })
    return arr
};


// 查询ulr地址中data后面接的参数
export function getLocation(data) {
    let geturl = window.location.href.split('?')[1]
    if (geturl) {
        let getqys = new URLSearchParams('?' + geturl)  //将参数放在URLSearchParams函数中
        let getqycode = getqys.get(data)
        return getqycode
    } else {
        return ''
    }
}


