import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/za/layout'
import { publicPath, routerMode } from '@/config'

const _import = require('@/za/router/_import_' + process.env.NODE_ENV)

Vue.use(VueRouter)

/**
 {
  "name": "Demo",         //首字母大写，一定要与vue文件的name对应起来，name名不可重复，用于noKeepAlive缓存控制（该项特别重要）
  "path": "/",            //注意根路由（第一条数据）是斜线，第一级路由必须带斜线，第二级路由开始不能，path名不可重复
  "component": "Layout",  //后端路由时此项为字符串，前端路由时此项为import的function，第一级路由是为Layout，其余为层级为vue的文件路径
  "meta": {
    "hidden": true,       //根集菜单是否显示在菜单中显示隐藏路由（默认值：false）
    "levelHidden": false, //子集是否显示（默认值：true）
    "childrenName": ['']  //子集组件名称，用于页面嵌套页面时左侧导航栏显示
  },
  "children": []          //子集项
  }
 */

/**
 * @description 获取页面嵌套页面的初始父集pid
 * @param pid
 * @param routes
 * @returns {*|number|number}
 */
function getFirstPid(pid, routes) {
  let findRoute = null
  for (let i = 0; i < routes.length; i++) {
    const route = routes[i]
    if (route.id === pid) {
      findRoute = route
      break
    }
  }
  if (!findRoute) return 0
  else if (findRoute.menu_type === 'S') return getFirstPid(findRoute.pid, routes)
  else return findRoute.pid
}

/**
 * @description 获取第一项可用页面路径
 * @param id
 * @param routes
 * @returns {string|*}
 */
function getRedirectPath(id, routes) {
  for (let i = 0; i < routes.length; i++) {
    const route = routes[i]
    if (route.pid === id && route.menu_type !== 'S') {
      return route.component
    }
  }

  return ''
}

export const constantRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login'),
    meta: {
      title: '控制台',
      hidden: true,
    },
  },
  {
    path: '/registerSuccess',
    name: 'RegisterSuccess',
    component: () => import('@/views/login/registerSuccess'),
    meta: {
      title: '注册成功',
      hidden: true,
    },
  },
  {
    path: '/resetPwd',
    name: 'ResetPwd',
    component: () => import('@/views/login/resetPwd'),
    meta: {
      title: '重置密码',
      hidden: true,
    },
  },
  {
    path: '/bindWX',
    name: 'BindWX',
    component: () => import('@/views/login/bindWX'),
    meta: {
      title: '绑定',
      hidden: true,
    },
  },
  {
    path: '/protocal/userProtocal',
    name: 'UserProtocal',
    component: () => import('@/views/login/protocal/userProtocal'),
    meta: {
      title: '用户协议',
      hidden: true,
    },
  },
  {
    path: '/protocal/registerPrototal',
    name: 'registerPrototal',
    component: () => import('@/views/login/protocal/registerPrototal'),
    meta: {
      title: '用户注册协议',
      hidden: true,
    },
  },
  {
    path: '/protocal/logout',
    name: 'logoutPrototal',
    component: () => import('@/views/login/protocal/logout'),
    meta: {
      title: '用户注销协议',
      hidden: true,
    },
  },
  {
    path: '/protocal/policy',
    name: 'Policy',
    component: () => import('@/views/login/protocal/policy'),
    meta: {
      title: '隐私政策',
      hidden: true,
    },
  },
  {
    path: '/protocal/childInfo',
    name: 'ChildInfo',
    component: () => import('@/views/login/protocal/childInfo'),
    meta: {
      title: '儿童保护协议',
      hidden: true,
    },
  },
  {
    path: '/protocal/onlineOrder',
    name: 'OnlineOrder',
    component: () => import('@/views/login/protocal/onlineOrder'),
    meta: {
      title: '线上订购',
      hidden: true,
    },
  },
  {
    path: '/protocal/infoSecurity',
    name: 'InfoSecurity',
    component: () => import('@/views/login/protocal/infoSecurity'),
    meta: {
      title: '智安云信息安全规定',
      hidden: true,
    },
  },
  {
    path: '/protocal/promise',
    name: 'Promise',
    component: () => import('@/views/login/protocal/promise'),
    meta: {
      title: '反洗钱承诺书',
      hidden: true,
    },
  },
  {
    path: '/oauth',
    name: 'Oauth',
    component: () => import('@/views/oauth'),
    meta: {
      title: '授权登录',
      hidden: true,
    },
  },
  {
    path: '/storageData',
    name: 'StorageData',
    component: () => import('@/views/storageData'),
    meta: {
      title: 'storageData',
      hidden: true,
    },
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/403'),
    meta: {
      title: '403',
      hidden: true,
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    meta: {
      title: '404',
      hidden: true,
    },
  },
]

export function changeRoutes(routes, permissions) {
  const logRoutes = Vue.prototype.$deepClone(routes)
  const retRouter = []
  for (let i = 0; i < routes.length; i++) {
    const route = routes[i]

    //所有的按钮权限
    if (route.perms !== '' && permissions instanceof Array) {
      permissions.push(route.perms)
    }
    if (route.menu_type === 'F') continue

    /**
     * 子集页面处理，四级路由
     */
    if (route.menu_type === 'S') {
      route.pid = getFirstPid(route.pid, logRoutes)
    }

    const curRoute = {
      id: route.id,
      pid: route.pid,
      path: route.path,
      name: route.name,
    }

    if (route.component === 'Layout') {
      curRoute.component = route.component
      curRoute.redirect = getRedirectPath(route.id, logRoutes)
    } else if (route.component !== '') {
      curRoute.component = route.component
    }

    curRoute.meta = {
      title: route.menu_name,
      noCache: route.no_cache,
      hidden: route.hidden,
    }

    if (route.icon !== '') {
      curRoute.meta.icon = route.icon
    }

    if (route.children_name !== '') {
      curRoute.meta.childrenName = route.children_name
    }

    if (route.parent_name !== '') {
      curRoute.meta.parentName = route.parent_name
    }
    retRouter.push(curRoute)
  }

  retRouter.push({
    pid: 0,
    id: 99999,
    path: '*',
    redirect: '/404',
    meta: {
      hidden: true,
    },
  })
  return retRouter
}

export function convertRouter(asyncRoutes) {
  return asyncRoutes.map((route) => {
    if (route.component) {
      if (route.component === 'Layout') {
        route.component = Layout
      } else {
        route.component = _import(route.component)
      }
    }
    if (route.children && route.children.length) route.children = convertRouter(route.children)
    if (route.children && route.children.length === 0) delete route.children
    delete route.id
    delete route.pid
    return route
  })
}

const router = createRouter()

function fatteningRoutes(routes) {
  return routes.flatMap((route) => {
    return route.children ? fatteningRoutes(route.children) : route
  })
}

export function resetRouter(routes = constantRoutes) {
  routes.map((route) => {
    if (route.children) {
      route.children = fatteningRoutes(route.children)
    }
  })
  router.matcher = createRouter(routes).matcher
}

function createRouter(routes = constantRoutes) {
  return new VueRouter({
    base: publicPath,
    mode: routerMode,
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: routes,
  })
}

// 修复重复点击路由报错的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

export default router
