var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c("div", { staticClass: "heardBlock" }, [
        _c(
          "div",
          { staticClass: "ignore_center", on: { click: _vm.officialWeb } },
          [
            _c("el-image", {
              staticClass: "pic-error-parent",
              attrs: { src: require("@/assets/home/home_logo.png") },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "title" }, [
          _vm._v("智安儿童个人信息保护声明"),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "main" }, [
      _c(
        "span",
        {
          staticClass: "blue_font",
          on: {
            click: function ($event) {
              return _vm.$router.push("/login")
            },
          },
        },
        [_vm._v("返回")]
      ),
      _vm._m(0),
      _c("h5", [_vm._v("欢迎您及您的孩子使用智安的产品及服务！")]),
      _c("span", { staticClass: "first" }, [
        _vm._v(
          " 我们珍视您作为监护人在向我们提供您及您的孩子的个人信息时对我们的信任。本声明旨在帮助您和您的孩子进一步了解智安如何收集、存储、使用、加工、传输、提供、公开、转移、披露、删除和保护您的孩子个人信息，以及您和您的孩子所享有的相关权利。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 本声明所称“孩子”或“儿童”，是指不满14周岁的未成年人。我们将遵循“正当必要”、“知情同意”、“目的明确”、“安全保障”、“依法利用”的原则，收集、存储、使用、加工、传输、提供、公开、转移、披露、删除儿童个人信息，按照本声明处理儿童个人信息并保障儿童个人信息安全。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 在阅读本声明之前，请您确认您的身份为使用智安产品及服务的儿童用户的父母或其他监护人。若您的身份是不满14周岁的未成年人，在使用任何智安产品及服务前，应请您的监护人仔细阅读或在您的监护人的监护、指导下共同仔细阅读本声明，并在征得您的监护人同意的前提下使用我们的产品、服务或向我们提供信息。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 如果您是儿童用户的父母或其他监护人，请您仔细阅读并慎重选择是否同意本声明。同时，我们希望您能够与我们共同保护孩子的个人信息，教育并引导孩子增强个人信息保护意识和能力，督导和要求孩子未经父母或其他监护人同意，不应向任何网络产品和服务提供者提供任何个人信息。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 请您理解，本声明仅适用于我们专门面向14周岁以下未成年人的产品或服务、具有专门面向14周岁以下未成年人的功能板块的产品或服务，或者要求用户输入生日、年龄等信息、能够识别用户年龄为14周岁以下未成年人的产品或服务（以下简称“儿童产品及服务”）。如我们的产品或服务并非专门面向儿童的产品，不承担识别用户年龄的法定义务，或者不能识别用户的年龄，则不应适用本声明。对于该等非儿童产品及服务的用户个人信息处理及保护适用《 "
        ),
        _c(
          "span",
          {
            staticClass: "blue_font",
            on: {
              click: function ($event) {
                return _vm.$router.push("/protocal/policy")
              },
            },
          },
          [_vm._v("智安隐私政策总则")]
        ),
        _vm._v(" 》和具体产品隐私政策。 "),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 本声明适用于智安平台所有儿童产品及服务，并与《智安隐私政策总则》和具体产品隐私政策的构成相关儿童产品及服务的完整隐私政策；如在儿童个人信息保护规则方面，本声明与《智安隐私政策总则》和具体产品/服务的隐私政策内容存在不一致的，本声明优先适用。本协议使用术语的含义与《智安隐私政策总则》的定义相同。如某款智安产品或服务有单独的儿童个人信息保护规则，则该产品或服务的单独规则将优先适用；该产品或服务的单独规则未涵盖的内容，以本声明内容为准。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 本声明适用于儿童通过网页端、供第三方网站和应用程序使用的软件开发工具包（SDK）和应用程序编程接口（API）等方式来访问和使用智安的产品或服务。但请您了解并注意，我们提供的产品和服务中可能包括第三方提供的产品或服务，或者您的孩子也可能通过我们产品登录/使用第三方产品和服务；如第三方收集、存储、使用、加工、传输、提供、公开、转移、披露、删除您的孩子的相关信息，应由该第三方另行获得您的同意。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 请您务必认真阅读本声明、《智安隐私政策总则》与具体产品或服务的隐私政策，在确认充分了解之后，选择是否同意您的孩子使用智安产品及服务，或同意向我们提供您的孩子的个人信息。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("本声明将帮助您了解以下内容：")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("1. 我们如何收集和使用您的孩子的个人信息"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("2. 我们如何使用 和同类技术"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("3. 我们如何委托处理、共享、转让、公开披露您的孩子的个人信息"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("4. 我们如何保存及保护您的孩子的个人信息"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("5. 您和您的孩子的权利")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("6. 本声明的修订与通知")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("7. 如何联系我们")]),
      _c("br"),
      _c("h5", [_vm._v("一、我们如何收集和使用您的孩子的个人信息")]),
      _c("span", [
        _vm._v("（一）您或您的孩子直接提供和我们自动采集的个人信息"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("（1） 帐号注册信息")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 若您的孩子通过智安帐号登录儿童产品及服务，则您需要为您的孩子注册智安帐号。您注册智安帐号时，您理解并同意，您至少需向我们提供手机号；您还可以提供头像、帐号名称、邮箱、创设帐号密码、密保问题信息，以丰富您的孩子的帐号信息，保护您的孩子的帐号安全，方便您或您的孩子找回帐号。如我们的产品或服务涉及到上传头像、个人照片等功能，我们强烈建议您指导儿童在使用我们产品或服务的过程中不要上传真实的本人肖像照片等信息。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 请您理解，您可以选择是否填写或向我们提供，但如果不填写或提供某些特定的信息，将可能导致我们的产品或服务无法正常运行，或者无法达到我们拟达到的服务效果，您和您的孩子可能无法正常使用我们的产品、服务或相关的具体业务功能。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("（2） 第三方帐号登录信息"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您理解并同意，您的孩子可以使用第三方帐号（如微信、微博、QQ）登录儿童产品及服务。此时，您将授权我们读取您的孩子在该第三方帐号注册的公开信息（如昵称、头像）。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("（3） 与年龄识别有关的个人信息"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 在您的孩子使用儿童产品及服务前，我们可能收集您的孩子的生日、年龄等信息，以识别该用户是否属于孩子。我们收集这些信息，是为了根据法律法规定的规定识别出不满十四周岁的未成年人并在处理其个人信息前取得孩子的父母或者其他监护人的同意。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("（4） 设备信息")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您理解并同意，当您的孩子使用智安产品及服务时，为了保障您的孩子正常使用智安产品及服务，更准确定位并解决您的孩子在使用智安产品及服务时遇到的问题，改进及优化智安产品及服务智安产品及服务的服务体验，保障您的孩子帐号安全，我们会收集设备信息。我们还可能会将设备信息进行关联或与智安帐号进行关联，以便我们能在这些设备上为您的孩子提供一致的服务。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("（5） 日志信息")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 与大多数互联网服务一样，您理解并同意，我们的服务器会自动记录您的孩子在访问智安产品及服务时所发出的请求，例如您的孩子输入的搜索关键词信息，您的孩子浏览的网页内容信息，您的孩子的IP 地址、浏览器的类型和使用的语言、硬件设备信息、操作系统的版本、网络运营商的信息、您的孩子访问服务的日期、时间、时长。请注意，这是我们为了保障软件与服务的正常运行所要收集的基本信息。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("（6） 使用儿童产品及服务各项业务功能需要的信息"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您理解并同意，当您的孩子使用儿童产品及服务时，我们将获取产品及服务各项业务功能所必需的您的孩子的个人信息。您应阅读各款智安产品或服务单独的隐私政策，以了解我们如何收集和使用您的孩子的个人信息。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("（7） 使用儿童产品及服务各项业务功能涉及的系统隐私权限"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 如使用儿童产品及服务各项业务功能需要，我们会申请开启相关权限，您应阅读各款儿童产品或服务单独的隐私政策，以了解我们如何使用各个隐私权限。您可以随时在系统中取消授权，具体操作方式请参考设备及系统开发方说明或指引。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("（8） 为您的孩子提供安全保障"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 为提高您使用我们与关联方、合作方提供的产品和服务的安全性，我们可能使用您及您的孩子的信息用于身份验证、客户服务、安全防范、诈骗监测、信贷分析等，以预防、发现、调查欺诈、危害安全、非法或违反与我们的协议、政策或规则的行为，以保护您、您的孩子、我们的其他用户、我们或关联方、合作方及社会公众的合法权益。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "提示您注意，当我们要将信息用于本声明未载明的其它用途时，会事先征求您的同意"
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("（二）事先征得授权同意的例外")]),
      _c("br"),
      _c("span", [
        _vm._v(
          "请注意，在以下情形中，收集、使用个人信息无需事先征得您的授权同意："
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("1. 与我们履行法律法规规定的义务相关的;"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("2. 为订立、履行您或您的孩子作为一方当事人的合同所必需；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("3. 与国家安全、国防安全直接相关的;"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 4. 与公共安全、公共卫生、重大公共利益直接相关的。例如：为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需; "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("5. 与刑事侦查、起诉、审判和判决执行等直接相关的;"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 6. 出于维护您、您的孩子或其他个人的生命、财产等重大合法权益但又很难得到您本人授权同意的; "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "7. 依照法律法规的规定在合理的范围内收集您或您的孩子自行向社会公众公开的个人信息的;"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 8. 依照法律法规的规定在合理的范围内从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道; "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "9. 维护我们所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障;"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "10. 为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息;"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 11. 出于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的果时，对结果中所包含的个人信息进行去标识化处理的； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("12. 法律法规规定的其他情形。"),
      ]),
      _c("br"),
      _c("h5", [_vm._v("二、我们如何使用 Cookie 和同类技术")]),
      _c("span", [
        _vm._v(
          " 为确保网站正常运转，我们会在您的孩子的计算机或移动设备上存储名为 Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。Cookie主要的功能是便于您的孩子使用网站产品和服务，以及帮助网站统计独立访客数量等。运用Cookie技术，我们能够为您的孩子提供更加周到的个性化服务，并允许您获得您的孩子设定特定的服务选项。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 当您的孩子使用智安产品及服务时，会向您的孩子的设备发送Cookie。当您的孩子与我们提供给合作伙伴的服务（例如广告和/或推广服务，以及可能显示在其他网站上的由智安产品及服务提供的服务功能）进行交互时，我们允许Cookie（或者其他匿名标识符）发送给智安公司的服务器。我们不会将 Cookie 用于本声明所述目的之外的任何用途。您和您的孩子可根据您的孩子的偏好管理或删除 Cookie。有关详情，请参见 AboutCookies.org。您和您的孩子可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie 的功能。在某些情况下，您或您的孩子清除计算机上保存的Cookie可能会影响您或您的孩子安全访问我们的网站，且可能需要在您或您的孩子每一次访问我们的网站时更改用户设置，您或您的孩子可能因为该等修改，无法登录或使用依赖于Cookie的智安公司提供的服务或功能。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 您或您的孩子可以通过更改您的孩子的浏览器设置限制智安公司对Cookie的使用。以百度浏览器为例，您或您的孩子可以在百度浏览器右上方的下拉菜单的“浏览器设置”中，通过“隐私设置——清除浏览数据”，选择清除您的Cookie。如需详细了解其他浏览器如何更改浏览器设置，请参考该浏览器的帮助中心或其他相关功能指引页面，例如： "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "blue_font" }, [
        _vm._v("https://support.google.com/chrome/search?q=cookie"),
      ]),
      _c("br"),
      _c("span", { staticClass: "blue_font" }, [
        _vm._v(
          "https://support.mozilla.org/zh-CN/products/firefox/protect-your-privacy/cookies"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "blue_font" }, [
        _vm._v(
          "http://help.opera.com/opera/Windows/2393/en/controlPages.html#manageCookies"
        ),
      ]),
      _c("br"),
      _c("h5", [
        _vm._v("三、我们如何委托处理、共享、转让、公开披露您的孩子的个人信息"),
      ]),
      _c("span", [_vm._v("（一）委托处理")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 我们一般不会委托第三方处理您的孩子的个人信息。如我们委托外部供应商为儿童产品及服务中某些具体的模块或功能提供技术支持，涉及处理您的孩子的相关个人信息时，我们会对委托的公司、组织和个人进行安全评估，与其签署严格的委托、安全与保密协议，明确双方责任、处理事项、处理期限、处理性质和目的等，要求他们按照本声明以及其他任何相关的保密和安全措施来处理您的孩子的个人信息。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("（二）共享")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 除非经过您明确同意，或者满足法律法规、法律程序的要求或强制性的政府要求或司法裁定，我们不会向除智安关联公司以外的第三方共享您的孩子的个人信息。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 对我们与之共享您的孩子的个人信息的公司、组织和个人，我们会对其进行安全评估，与其签署严格的安全与保密协议，要求他们按照本声明以及其他任何相关的保密和安全措施来处理您的孩子的个人信息。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 请您注意，您的孩子可以利用儿童产品及服务的分享功能与您的孩子的特定或不特定社交好友（如微信、微博、QQ）共享其个人信息。请您注意，您的孩子在使用第三方服务或分享功能的过程中，第三方对您的孩子的个人信息的处理将适用该第三方服务商或对应社交平台的儿童个人信息保护规则。请您务必提醒您的孩子，在分享前充分考虑信息接收人的信誉情况，并建议您及您的孩子查看您的孩子利用的社交网络或第三方服务提供商的儿童个人信息保护规则，以了解他们如何处理您的孩子的信息，以便审慎决策。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("（三）转让")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "我们不会将您的孩子的个人信息转让给任何公司、组织和个人，但以下情况除外："
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("1. 经过您的明确同意；")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("2. 满足法律法规、法律程序的要求或强制性的政府要求或司法裁定；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 3. 如果我们或我们的关联公司涉及合并、分立、清算、资产或业务的收购或出售等交易，您的孩子的个人信息有可能作为此类交易的一部分而被转移，我们将确保该等信息在转移时的机密性，并尽最大可能确保新的持有您的孩子的个人信息的公司、组织继续受本声明的约束，否则我们将要求该公司、组织重新向您征求授权同意。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 对我们与之转让您的孩子的个人信息的公司、组织和个人，我们会对其进行安全评估，与其签署严格的安全与保密协议，要求他们按照本声明以及其他任何相关的保密和安全措施来处理您的孩子的个人信息。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("（四）公开披露")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 除非法律、行政法规规定应当披露或者根据与您的约定可以披露您的孩子的个人信息，我们不会公开披露您的孩子的个人信息。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " （五）共享、转让、公开披露您的孩子的个人信息时事先征得授权同意的例外 在以下情形中，共享、转让、公开披露您的孩子的个人信息无需事先征得您的授权同意： "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("1. 与我们履行法律法规规定的义务相关的;"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("2. 为订立、履行您或您的孩子作为一方当事人的合同所必需；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("3. 与国家安全、国防安全直接相关的；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 4. 与公共安全、公共卫生、重大公共利益直接相关的。例如：为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("5. 与犯罪侦查、起诉、审判和判决执行等直接相关的；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "6. 出于维护您、您的孩子或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "7. 依照法律法规的规定在合理的范围内收集您或您的孩子自行向社会公众公开的个人信息；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 8. 依照法律法规的规定在合理的范围内从合法公开披露的信息中收集您的孩子的个人信息的，如合法的新闻报道、政府信息公开等渠道； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "9. 维护我们所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障;"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "10. 为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息所必需的；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 11. 出于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的果时，对结果中所包含的个人信息进行去标识化处理的； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("12. 法律法规规定的其他情形。"),
      ]),
      _c("br"),
      _c("h5", [_vm._v("四、我们如何保存及保护您的孩子的个人信息")]),
      _c("span", [_vm._v("（一）保存期限")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您理解并同意，您的孩子在使用儿童产品及服务期间，我们将在为您的孩子提供相应产品及服务所必需的期限内持续保存您的孩子的个人信息，但法律法规对保存期限另有规定、您同意留存更长的期限、保证服务的安全与质量、实现争议解决目的等所必需的情况下，在前述保存期限到期后，我们将依法、依约或在合理范围内延长保存期限。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "在超出保存期限后，我们将根据法律规定删除您的孩子的个人信息或进行匿名化处理。"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 在超出保存期限后，或如您的孩子注销帐号或主动删除上述信息，我们将依据相关法律法规规定在法定期限内保存您的孩子的个人信息。在您的孩子注销帐号或主动删除上述信息后，我们将在完成身份验证和帐号情况核实并且法定期限届满后，及时对您的孩子的个人信息进行删除或匿名化处理。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("（二）保存地域")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您的孩子的个人信息均储存于中华人民共和国境内。如您的孩子的个人信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问，我们会严格履行法律法规规定的义务并按照法律规定事先获得您的单独同意。此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会按照中国现行法律的规定传输您的孩子的个人信息，并会确保您的孩子的个人信息得到在中华人民共和国境内足够同等的保护。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("（三）安全措施")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 1. 我们会以业界成熟的安全标准和规范收集、使用、存储和传输您的孩子的个人信息，并通过本声明告知您相关信息的使用目的和范围。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 2. 我们非常重视信息安全。我们成立了专责团队负责研发和应用多种安全技术和程序等，我们会对安全管理负责人和关键安全岗位的人员进行安全背景审查，我们建立了完善的信息安全管理制度和内部安全事件处置机制等。我们会采取适当的符合业界标准的安全措施和技术手段存储和保护您的孩子的个人信息，以防止您的孩子信息丢失、遭到被未经授权的访问、公开披露、使用、修改、毁损、丢失或泄漏。我们会采取一切合理可行的措施，保护您的孩子的个人信息。我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 3. 我们指定有专人负责儿童个人信息保护。我们会对员工进行数据安全的意识培养和安全能力的培训和考核，加强员工对于保护儿童个人信息重要性的认识。我们坚持以最小授权为原则，严格设定信息访问权限，控制儿童个人信息知悉范围。我们会对处理儿童个人信息的员工进行身份认证及权限控制，在员工访问儿童个人信息前，须经过儿童个人信息保护负责人或者其授权的管理人员审批，记录访问情况，避免员工违法复制、下载儿童个人信息。我们会与接触您的孩子的个人信息的员工签署保密协议，明确岗位职责及行为准则，确保只有授权人员才可访问您的孩子的个人信息。对于违规员工，我们会严格按照智安内部管理制度及相关法律法规规定追究法律责任。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 4. 我们提醒您注意，互联网并非绝对安全的环境，当您或您的孩子通过社交软件与其他用户交互信息时，不确定第三方软件对您的孩子的信息传递是否完全加密，为保护您的孩子的个人信息安全，我们建议您和您的孩子不要通过此类方式发送您的孩子的个人信息，以免个人信息泄露。请使用复杂密码，协助我们保证您的孩子的帐号以及个人信息安全。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 5. 我们也请您理解，在互联网行业由于技术的限制和飞速发展以及可能存在的各种恶意攻击手段，即便我们竭尽所能加强安全措施，也不可能始终保证信息的百分之百安全。请您了解，您的孩子使用我们的产品和/或服务时所用的系统和通讯网络，有可能在我们控制之外的其他环节而出现安全问题。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 6. 根据我们的安全管理制度，个人信息泄露、毁损或丢失事件被列为最特大安全事件，一经发生将启动公司最高级别的紧急预案，由安全部、政府关系部、法务部等多个部门组成联合应急响应小组处理。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("（四）安全事件通知")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 1. 我们会制定网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施，并按照规定向有关主管部门报告。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 2. 个人信息泄露、毁损、丢失属于公司级特大安全事件，我们会负责定期组织工作组成员进行安全预案演练，防止此类安全事件发生。若一旦不幸发生，我们将按照最高优先级启动应急预案，组成紧急应急小组，在最短时间内追溯原因并减少损失。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 3. 在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处理措施、您可自主防范和降低的风险的建议、对您的补救措施等。我们将及时将事件相关情况以站内通知、短信通知、电话或邮件等您预留的联系方式告知您，难以逐一告知时我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。 "
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("五、您和您的孩子的权利")]),
      _c("span", [
        _vm._v(
          " 按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您和您的孩子对您的孩子的个人信息行使以下权利： "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("（一）查阅权")]),
      _c("br"),
      _vm._m(1),
      _c("br"),
      _c("span", [_vm._v("（二）更正及补充权")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 当您或您的孩子发现我们处理的关于您的孩子的个人信息有错误或者不完整时时，您或您的孩子有权对错误或不完整的信息作出更正，经对您及您的孩子的身份进行验证，且更正不影响信息的客观性和准确性的情况下，您或您的孩子有权对错误或不完整的信息作出更正或补充，您可以自行在智安各产品及服务内进行更正或补充，或通过“七、如何联系我们”中的联系方式将您的更正或补充申请提交给我们。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("（三）撤回同意")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("您可以随时给予或收回您对于您的孩子的个人信息处理的授权同意。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "您可以通过“七、如何联系我们”中的联系方式您的撤回同意申请提交给我们。"
        ),
      ]),
      _c("br"),
      _vm._m(2),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您或您的孩子还可以随时在设备系统中直接关闭位置、通讯录、摄像头、麦克风、相册等系统权限，改变同意范围或撤回同意。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 当您撤回同意后，我们无法继续为您的孩子提供已撤回同意所对应的产品或服务，也将不再处理您的孩子相应的个人信息。但您撤回同意的决定，不会影响此前基于您的同意而开展的对您的孩子的个人信息处理。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("（四）删除权")]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 在以下情形中，您和您的孩子可以向我们提出删除个人信息的请求，您可以通过“七、如何联系我们”中的联系方式随时与我们联系： "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "1. 如果我们违反法律法规或与您、您的孩子的约定处理您的孩子的个人信息；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "2. 如果我们对您的孩子的个人信息的处理目的已实现、无法实现或者为实现处理目的不再必要；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "3. 如果我们停止向您的孩子提供产品或者服务，或者对您的孩子的个人信息的保存期限已届满；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("4. 您或您的孩子通过注销帐号等方式终止使用产品或者服务；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("5. 您撤回同意；")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("6. 法律、行政法规规定的其他情形。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 当您或您的孩子从我们的服务中删除信息后，我们可能不会立即在备份系统中删除相应的信息，但会在备份更新时删除这些信息。请您知晓并理解，根据法律法规的规定，如果法律、行政法规规定的保存期限未届满，或者删除个人信息从技术上难以实现的，我们会停止除存储和采取必要的安全保护措施之外的处理。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("（五）注销权")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("1. 您和您的孩子可以随时注销此前注册的帐号"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您或您的孩子可以登录www.zhiannet.com，注销您的孩子的智安帐号。您或您的孩子还可以通过智安各产品及服务的帐号管理功能进行上述操作。 一旦注销智安帐号，您的孩子将无法使用需登陆帐号方可使用的智安公司产品及服务，因此请您和您的孩子谨慎操作。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 我们为了保护您和您的孩子或他人的合法权益会结合您的孩子对智安公司各产品和服务的使用情况判断是否支持或允许您或您的孩子的上述请求。例如若您的孩子在智安经验里有未提现的稿费，或您的孩子在智安糯米里有未消费的糯米储值卡，或您的孩子智安网盘中还存有资料，则我们不会立即支持您或您的孩子的请求。但是，您可以选择通过帐号管理功能删除特定儿童产品或服务的服务痕迹。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 2. 您的孩子通过第三方帐号（如微信、微博、QQ）授权登录智安各产品及服务时，您或您的孩子需要向第三方申请注销帐号。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("（六）复制权和可携带权")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您有权复制我们收集的您的个人信息。在法律法规规定的条件下，同时符合国家网信部门规定指令和条件的，如果技术可行，您也可以要求我们将您的个人信息转移至您指定的其他主体。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("（七）获得解释的权利")]),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 您有权要求我们就儿童个人信息处理规则作出解释说明。您可以通过“七、如何联系我们”中的联系方式与我们取得联系。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("（八）提前获知产品和服务停止运营权")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 智安各产品及服务愿一直陪伴您和您的孩子，若因特殊原因导致产品及服务被迫停止运营，我们将在产品或服务的主页面或站内信或向您或您的孩子发送电子邮件或其他合适的能触达您或您的孩子的方式通知您，并将停止对您的孩子的个人信息的收集，同时会按照法律规定对所持有的您的孩子的个人信息进行删除或匿名化处理。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("（九）响应您和您的孩子的上述请求")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 为保障安全，您和您的孩子可能需要提供书面请求，或以其他方式证明您和您的孩子的身份。我们可能会在验证身份后，然后再处理您或您的孩子的请求。 对于您和您的孩子合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 在以下情形中，按照法律法规要求，我们将无法响应您和您的孩子的更正、删除、注销、复制、转移信息的请求： "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("1. 与我们履行法律法规规定的义务相关的；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("2. 与国家安全、国防安全直接相关的；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("3. 与公共安全、公共卫生、重大公共利益直接相关的；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("4. 与犯罪侦查、起诉、审判和执行判决等直接相关的；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 5. 我们有充分证据表明您或您的孩子存在主观恶意或滥用权利的（如您或您的孩子的请求将危害公共安全和其他人合法权益，或您或您的孩子的请求超出了一般技术手段和商业成本可覆盖的范围）； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "6. 响应请求将导致您或您的孩子或其他个人、组织的合法权益受到严重损害的；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("7. 涉及商业秘密的。")]),
      _c("br"),
      _c("h5", [_vm._v("六、本声明的修订与通知")]),
      _c("span", [
        _vm._v(
          "我们的儿童个人信息保护声明、儿童产品及服务中的儿童个人信息处理规则可能变更。"
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 未经您明确同意，我们不会削减您和您的孩子按照本声明所应享有的权利。我们会在本页面上发布对本声明之下儿童个人保护规则所做的任何变更。 对于重大变更，我们会在智安相关产品或服务的主要曝光页面或站内信或向您或您的孩子发送电子邮件或其他合适的能触达您或您的孩子的方式通知您或您的孩子。若您不同意该等变更可以要求您的孩子停止使用智安相关产品及服务，若您的孩子继续使用我们的产品及服务，即表示您同意受修订后的本声明的约束。 本声明所指的重大变更包括但不限于： "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 1. 我们的服务模式发生重大变化。如处理您的孩子的个人信息的目的、处理的您的孩子的个人信息类型、您的孩子的个人信息的使用方式等； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 2. 我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "3. 您的孩子的个人信息对外提供、转让或公开披露的主要对象发生变化；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "4. 您和您的孩子参与个人信息处理方面的权利及其行使方式发生重大变化；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "5. 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("6. 个人信息安全影响评估报告表明存在高风险时。"),
      ]),
      _c("br"),
      _c("span", [
        _vm._v("本声明更新后，我们会将本声明的旧版本存档，供您查阅。"),
      ]),
      _c("br"),
      _c("h5", [_vm._v("七、如何联系我们")]),
      _c("span", [
        _vm._v(
          " 如有任何关于您的孩子的个人信息收集、存储、使用、加工、传输、提供、公开、转移、披露和保护方面的问题，或对本声明的任何建议，您可以通过个人信息保护问题反馈同我们联系。 您还可以通过如下联络方式同我们联系： "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("深圳市南山区南新路1024号阳光科创中心B座2207室")]),
      _c("br"),
      _c("span", [_vm._v("深圳市智安网络有限公司")]),
      _c("br"),
      _c("span", [_vm._v("邮政编码：518000")]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 为保障我们高效处理您的问题并及时向您反馈，需要您提交身份证明、有效联系方式和书面请求及相关证据，我们会在验证您和您的孩子的身份后处理您的请求。一般情况下，我们将在您提出权利请求后15个工作日内回复。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 如果您对我们的回复不满意，特别是您认为我们对您的孩子的个人信息处理行为损害了您或您的孩子的合法权益，您还可以通过以下外部途径寻求解决方案：向深圳市南山区人民法院提起诉讼。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v("再次感谢您和您的孩子对智安产品及服务的信任和使用！"),
      ]),
      _c("br"),
      _c("span", [_vm._v("深圳市智安网络有限公司！")]),
      _c("br"),
      _c("span", [_vm._v("2023年03月31日生效")]),
      _c("br"),
      _vm._m(3),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("h3", [_vm._v("智安儿童个人信息保护声明")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "mgLeft" }, [
      _vm._v(" 您和您的孩子可以随时登录 "),
      _c(
        "a",
        {
          staticClass: "blue_font",
          attrs: { href: "www.zhiannet.com", target: "_blank" },
        },
        [_vm._v("www.zhiannet.com")]
      ),
      _vm._v(
        " ，访问或编辑您的孩子的帐户中的个人资料信息、更改密码、进行帐号关联或身份认证等。您或您的孩子还可以通过智安各产品及服务的帐号管理功能进行上述操作。 "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "mgLeft" }, [
      _vm._v(" 您或您的孩子还可随时登录 "),
      _c(
        "a",
        {
          staticClass: "blue_font",
          attrs: { href: "www.zhiannet.com", target: "_blank" },
        },
        [_vm._v("www.zhiannet.com")]
      ),
      _vm._v(
        " ，解除您的孩子的智安帐号与第三方帐号的绑定关系。您或您的孩子还可以通过智安各产品及服务的帐号管理功能进行上述操作。 "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ignore_center" }, [
      _c("span", [
        _vm._v(
          " Copyright©2023 深圳市智安网络有限公司 All Rights Reve 版权所有 《中华人民共和国增值电信业务经营许可证》编号：B1-20190666 "
        ),
        _c(
          "a",
          {
            staticClass: "blue_font",
            attrs: {
              href: "https://beian.miit.gov.cn/#/Integrated/index",
              target: "_blank",
            },
          },
          [_vm._v("粤ICP备18027011号")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }