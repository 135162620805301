var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dropdown",
    { attrs: { placement: "bottom" } },
    [
      _c(
        "span",
        { staticClass: "homeMa" },
        [
          _c("za-svg-icon", {
            attrs: { "class-name": "svg-homeMa", "icon-class": "za-home-ma" },
          }),
        ],
        1
      ),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        [
          _c("el-dropdown-item", [
            _c("div", { staticClass: "dropdown_block" }, [
              _c("span", { staticClass: "demonstration" }, [
                _vm._v("关注公众号"),
              ]),
              _c(
                "div",
                { staticClass: "icon-html" },
                [
                  _c(
                    "el-image",
                    {
                      ref: "image",
                      staticClass: "icon-account",
                      attrs: {
                        fit: "cover",
                        src: require("@/assets/home/twoCode.jpg"),
                      },
                    },
                    [
                      _c("div", { attrs: { slot: "error" }, slot: "error" }, [
                        _c("i", { staticClass: "el-icon-picture-outline" }),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }