var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "offLine" }, [
    _c("div", { staticClass: "main" }, [
      _c("div", { staticClass: "company" }, [
        _c("div", [
          _vm._v(_vm._s(_vm.translateTitle("topUp.topUpAccount") + ":")),
        ]),
        _c("div", { staticClass: "companyInfo" }, [_vm._v("深圳智安网络")]),
      ]),
      _c("div", { staticClass: "cardInfo" }, [
        _c("div", [
          _vm._v(_vm._s(_vm.translateTitle("topUp.moneyInfo") + ":")),
        ]),
        _vm._m(0),
      ]),
    ]),
    _c("div", { staticClass: "prompt" }, [
      _c("h5", [_vm._v(_vm._s(_vm.translateTitle("topUp.reminder")))]),
      _c("p", [
        _c("span", [_vm._v(_vm._s(_vm.translateTitle("topUp.sixth")))]),
        _c("span", { staticClass: "redText" }, [
          _vm._v(_vm._s(_vm.translateTitle("topUp.sixthOne"))),
        ]),
      ]),
      _c("p", [_vm._v(" " + _vm._s(_vm.translateTitle("topUp.seventh")))]),
      _c("p", [
        _c("span", [_vm._v(_vm._s(_vm.translateTitle("topUp.eighth")))]),
        _c("span", { staticClass: "redText" }, [
          _vm._v(_vm._s(_vm.translateTitle("topUp.eighthOne"))),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("ul", [
        _c("li", [
          _c("span", [_vm._v("开户名称")]),
          _c("span", [_vm._v("收款银行")]),
          _c("span", [_vm._v("收款账号")]),
        ]),
        _c("li", [
          _c("span", [_vm._v("深圳智安网络")]),
          _c("span", [_vm._v("招商银行深圳支行")]),
          _c("span", [_vm._v("11090 73168 10601 00030 36237")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }