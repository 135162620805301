import Vue from 'vue'
import regionGaode from '@/assets/region_gaode.json'
import { COMMON_CITY_TYPE } from '@/za/model/commonEnum'

export function getRegionByAdCode(adCode) {
  if (!adCode) {
    return ''
  }
  let curRegion = null

  for (let i = 0; i < regionGaode.length; i++) {
    const item = regionGaode[i]
    if (item.a === adCode.toString()) {
      curRegion = item
      break
    }
  }

  const res = []
  if (curRegion === null) {
    return res
  }

  let city = null
  let province = null
  let district = null

  switch (Number(curRegion.l)) {
    case COMMON_CITY_TYPE.PROVINCE:
      city = getSonRegionByID(curRegion.i)
      district = getSonRegionByID(city.i)
      res.push(curRegion, city, district)
      break
    case COMMON_CITY_TYPE.CITY:
      province = getParentRegionByID(curRegion.p)
      district = getSonRegionByID(curRegion.i)
      res.push(province, curRegion, district)
      break
    case COMMON_CITY_TYPE.DISTRICT:
      city = getParentRegionByID(curRegion.p)
      province = getParentRegionByID(city.p)
      res.push(province, city, curRegion)
      break
  }

  return res
}

function getParentRegionByID(id) {
  for (let j = 0; j < regionGaode.length; j++) {
    if (regionGaode[j].i === id) {
      return regionGaode[j]
    }
  }
}

function getSonRegionByID(id) {
  for (let j = 0; j < regionGaode.length; j++) {
    if (regionGaode[j].p === id) {
      return regionGaode[j]
    }
  }
}

/**
 * 获取省级数组数据
 * @returns {[]}
 */
export function getProvinceArray() {
  const resArray = []
  for (let i = 0; i < regionGaode.length; i++) {
    const item = regionGaode[i]
    if (Number(item.l) === COMMON_CITY_TYPE.PROVINCE) {
      resArray.push(Vue.prototype.$deepClone(item))
    }
  }

  return resArray
}

/**
 * 获取区域数据
 * @param {Number} _lockType 锁定类型
 * @returns {[]}
 */
export function getRegionArray(_lockType) {
  const resArray = []
  for (let i = 0; i < regionGaode.length; i++) {
    const item = regionGaode[i]
    if (Number(item.l) <= _lockType) {
      resArray.push(Vue.prototype.$deepClone(item))
    }
  }

  return resArray
}

/**
 * 获取置顶区域数据
 * @param {String} _adCode 区域编码
 * @returns {undefined|*}
 */
export function getRegionItem(_adCode, _level) {
  for (let i = 0; i < regionGaode.length; i++) {
    const item = regionGaode[i]
    if (Number(item.l) === Number(_level) && item.a.toString() === _adCode.toString()) {
      return Vue.prototype.$deepClone(item)
    }
  }

  return undefined
}
