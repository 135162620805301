<template>
    <div class="orderConfirmation-center_bg" v-za-loading="isLoading">
        <div>
            <za-go-back title="续费" @click="goBack"></za-go-back>
        </div>
        <div class="main">
            <div class="title">续费订单确认</div>
            <div>
                <occ-table :tableData="tableData" :columObj="columObj" :isOp="columObj.isOp">
                </occ-table>
            </div>
            <div style="margin-top: 20px">
                <el-row>
                    <el-col :span="16">
                        <div>&nbsp;</div>
                    </el-col>
                    <el-col :span="8">
                        <div class="prompt">
                            <span>订单合计金额：￥</span>
                            <span>{{amount}}元</span>
                        </div>
                        <div class="warring">
                            <span>此处金额仅供参考，实际支付金额以支付页为准，产品优惠券请前往支付页面使用</span>
                        </div>
                        <div class="warring">
                            <za-button :loading="confirmBtnLoading" :type="buttonTypeObj.BLUE" @click="nextSubmit">
                                提交订单
                            </za-button>
                            <za-button :disabled="confirmBtnLoading" :type="buttonTypeObj.GREY" @click="goBack()">
                                取消
                            </za-button>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <za-dialog
                    top-title="提醒"
                    :visible.sync="dialogVisible"
                    width="600px">
                <div class="promptTest">
                       <span style="margin-left: 16px">
                <za-svg-icon icon-class="za-wanning"></za-svg-icon>
                </span>
                    <span>  您操作的续费实例中有未支付订单，可在订单管理中心进行支付，如若继续支付，历史未支付订单将自动取消，请确认是否继续支付？</span>

                </div>
                <div slot="footer">
                    <za-button :loading="confirmBtnLoading" :type="buttonTypeObj.BLUE"
                               @click="handleSubmitForm">
                        确定
                    </za-button>
                    <za-button :disabled="confirmBtnLoading" :type="buttonTypeObj.GREY"
                               @click="dialogVisible = false">
                        取消
                    </za-button>
                </div>
            </za-dialog>
        </div>
        <div>

        </div>
    </div>
</template>
<script>
    import ZaGoBack from "../../../../../za/components/zaGoBack/index";
    import {apiOrderResourceRenew,apiOrderSubmit} from '@/api/moneyCenter/orderManagement/renewal'

    export default {
        components: {ZaGoBack},
        data() {
            return {
                tableData: [],
                columObj: {
                    // 控制选择框
                    selection: false,
                    isOp: false,
                    width: '300px',
                    columnData: [
                        {
                            text: true,
                            prop: "productName",
                            label: "产品名称",
                            width: "",
                            flag: true,
                            align: "center",

                        },
                        {
                            text: true,
                            prop: "resourceId",
                            label: "ID",
                            flag: true,
                            width: "",
                            align: "center",

                        },
                        {
                            text: true,
                            prop: "payType",
                            label: "产品类型",
                            flag: true,
                            width: "",
                            align: "center",

                        },
                        {
                            text: true,
                            prop: "configurations",
                            label: "配置",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            ownDefined: true,
                            prop: "number",
                            label: "数量",
                            flag: true,
                            width: "",
                            align: "center",
                            ownDefinedReturn:((row,$index)=>{
                                return 'x'+row.number
                            })
                        },
                        {
                            text: true,
                            prop: "time",
                            label: "时长",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            ownDefined: true,
                            prop: "price",
                            label: "单价",
                            flag: true,
                            width: "",
                            align: "center",
                            ownDefinedReturn:((row)=>{
                                return '¥'+row.price+'/台'
                            })
                        },
                        {
                            text: true,
                            prop: "billingMethod",
                            label: "计费方式",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                    ],
                },
                price: 200,
                unpaidOrder: false,
                amount: null
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                let result = {
                    list: JSON.parse(localStorage.getItem("confirmation"))
                }
                this.onSubmitData(apiOrderResourceRenew, result, null, false, false, (res) => {
                    this.tableData = res.data.orderNumbers
                    this.amount = res.data.amount
                })
            },
            goBack() {
                this.$router.push('/moneyCenter/orderManagement/renewal')
            },
            nextSubmit() {
                this.handleSubmitForm()
            },
             async handleSubmitForm() {
                 let result = []
                 this.tableData.forEach((item) => {
                     result.push(item.orderNumber)
                 })
                 let data={
                     ignoreOrder:this.unpaidOrder,
                     orderNumbers:result
                 }
                 let res = await apiOrderSubmit(data).finally(() => (this.confirmBtnLoading = false))
                 if (res.code === 0) {
                     if(res.data.unpaidOrder){
                         //为ture打开提醒
                         this.dialogVisible = true
                         this.unpaidOrder=true
                     }else{
                         this.$router.push({path: '/orderPayment', query: {orderId: result}})
                     }
                 }

            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '~@/za/styles/mixin.scss';

    .main {
        margin-top: 20px;
        padding: 20px;
        border-radius: 2px;
        background: #FFFFFF;

        .title {
            @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
            width: 100%;
            height: 20px;
            margin-bottom: 20px;
        }
    }

    .prompt {
        text-align: end;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #555555;
        margin-left: 20px;
        margin-bottom: 10px;

        span:first-child {
            color: #000000;
        }
        span:last-child{
            font-weight: 600;
        }
    }
.promptTest{
    height: 80px;
    background: #FFFBE6;
    border-radius: 2px;
    border: 1px solid #FFE58F;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 40px;

}
    .warring {
        text-align: end;
        font-size: 12px;
        color: #999999;
        margin-bottom: 20px;
    }
</style>
