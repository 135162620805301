var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "clip" }, [
    _c(
      "div",
      [
        _c(
          "el-upload",
          {
            ref: "pl_upload",
            staticClass: "upload-demo upload",
            attrs: {
              "list-type": "picture-card",
              headers: { Authorization: _vm.tok },
              action: "#",
              "on-success": _vm.importData_handleAvatarSuccess,
              "on-preview": _vm.handlePreview,
              "on-remove": _vm.handleRemove,
              "on-change": _vm.handleChange,
              "file-list": _vm.fileList,
              "auto-upload": false,
              "show-file-list": false,
            },
          },
          [
            _c("div", { staticClass: "picture" }, [
              _c("div", { staticClass: "upload-text" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.imgSrc,
                        expression: "!imgSrc",
                      },
                    ],
                    staticClass: "upload-plus",
                    attrs: { slot: "trigger" },
                    slot: "trigger",
                  },
                  [_c("i", { staticClass: "el-icon-plus" })]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.imgSrc,
                        expression: "imgSrc",
                      },
                    ],
                    staticClass: "upload-icon",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _c("el-image", {
                      attrs: { src: _vm.imgSrc, fit: "scale-down" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.ensss.apply(null, arguments)
                        },
                      },
                    }),
                    _c("div", { staticClass: "img_mc" }, [
                      _c("span", [
                        _c("i", {
                          staticClass: "el-icon-delete",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.expurgate.apply(null, arguments)
                            },
                          },
                        }),
                      ]),
                      _c("span", [
                        _c("i", {
                          staticClass: "el-icon-zoom-in",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.enlargement.apply(null, arguments)
                            },
                          },
                        }),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
        _vm._m(0),
        _c(
          "za-dialog",
          {
            attrs: {
              "top-title": "图片预览",
              "append-to-body": "",
              visible: _vm.showViewer,
              width: "620px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.showViewer = $event
              },
            },
          },
          [_c("img", { attrs: { src: _vm.imgSrc_obj } })]
        ),
      ],
      1
    ),
    _c(
      "div",
      {},
      [
        _c(
          "za-dialog",
          {
            attrs: {
              "top-title": "剪裁图片",
              "append-to-body": "",
              visible: _vm.outerVisible,
              width: "620px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.outerVisible = $event
              },
              close: _vm.closeDialog,
            },
          },
          [
            _c("div", { staticClass: "cropper-content" }, [
              _c(
                "div",
                {
                  staticClass: "cropper",
                  staticStyle: { "text-align": "center" },
                },
                [
                  _c("vue-cropper", {
                    ref: "cropper",
                    attrs: {
                      img: _vm.option.img,
                      outputSize: _vm.option.outputSize,
                      outputType: _vm.option.outputType,
                      info: _vm.option.info,
                      canScale: _vm.option.canScale,
                      autoCrop: _vm.option.autoCrop,
                      autoCropWidth: _vm.option.autoCropWidth,
                      autoCropHeight: _vm.option.autoCropHeight,
                      fixed: _vm.option.fixed,
                      fixedNumber: _vm.option.fixedNumber,
                      full: _vm.option.full,
                      fixedBox: _vm.option.fixedBox,
                      canMove: _vm.option.canMove,
                      canMoveBox: _vm.option.canMoveBox,
                      original: _vm.option.original,
                      centerBox: _vm.option.centerBox,
                      height: _vm.option.height,
                      infoTrue: _vm.option.infoTrue,
                      maxImgSize: _vm.option.maxImgSize,
                      enlarge: _vm.option.enlarge,
                      mode: _vm.option.mode,
                    },
                    on: { realTime: _vm.realTime, imgLoad: _vm.imgLoad },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "preview" }, [
                _c("img", {
                  ref: "img",
                  staticClass: "previewImg",
                  attrs: { src: _vm.previewImg, alt: "" },
                }),
                _c("div", [_vm._v("头像预览")]),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "footer-btn",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "div",
                  { staticClass: "upload-btn" },
                  [
                    _c(
                      "za-button",
                      {
                        attrs: {
                          disabled: _vm.confirmBtnLoading,
                          type: _vm.buttonTypeObj.GREY,
                        },
                        on: { click: _vm.onStep },
                      },
                      [_vm._v(" 上一步 ")]
                    ),
                    _c(
                      "za-button",
                      {
                        attrs: {
                          disabled: _vm.confirmBtnLoading,
                          type: _vm.buttonTypeObj.GREY,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.submitUpload("blob")
                          },
                        },
                      },
                      [_vm._v(" 上传图片 ")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "remarks" }, [
      _c("div", [_vm._v("图片尺寸需要大于100 * 100像素")]),
      _c("div", [_vm._v("支持jpg、png、jpeg等格式大小不能超过2MB")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }