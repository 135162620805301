<template>
    <div class="compage">
        <div class="main">
            <div>
                <el-image class="pic-error-parent" :src="require('@/assets/error_images/404.png')" />
            </div>
            <div>
                <span class="title">云服务器等爆品抢先购，低至4.2元/月</span>
                <div class="newUser">新用户优惠：</div>
                <div class="news">全场云服务器任享8折，满1000减400，满500减200，满200减100任优惠！</div>
                <div class="compage_input">
                    <el-input placeholder="请输入内容" v-model="value">
                    </el-input>
                    <el-button  @click="handleClick">复制推广链接</el-button>
                </div>
                <div class="footer">
                    <el-button type="primary" style="background: #3370FF">下载推广图片包</el-button>
                    <div class="otherLogin">
                        <span>分享至 :</span>
                        <span>
                      <za-svg-icon class-name="icon-click" icon-class="za-qq" @click="handlePassword"/>
                      <za-svg-icon class-name="icon-click" icon-class="za-wechat" @click="handlePassword"/>
                      <za-svg-icon class-name="icon-click" icon-class="za-microblog" @click="handlePassword"/>
                  </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                value:''
            }
        },
        methods:{
            handleClick(){
                console.log('-------------------')
            },
            handlePassword(){

            }
        }
    }
</script>
<style lang="scss" scoped>
    .compage {
        padding: 50px 20px;
        .main{
            border: 1px solid #F4F6FA;
            padding: 20px;
            margin-bottom: 20px;
            display: flex;
            .title{
                font-size: 16px;
                font-weight: bold;
            }
            .pic-error-parent{
                width: 240px;
                height: 180px;
                margin-right: 20px;
            }
            .newUser{
                font-size: 14px ;
                margin-bottom: 10px;
                margin-top: 10px;
                font-weight: 400;
                color: #EB2F2F;
            }
            .news{
                font-size: 14px;
                color: #555555;
                margin-bottom: 10px;
            }
            .compage_input{
                display: flex;
                .el-button{
                    margin-left: 10px;
                    border-radius: 2px;
                    border: 1px solid #336FFE;
                    font-weight: 400;
                    color: #3370FF;
                }
            }
            .footer{
                margin-top: 10px;
                display: flex;
                align-items: center;
                .otherLogin{
                    margin-left: 10px;
                    vertical-align: middle;
                    color: #999999;
                    span{
                        font-size: 14px;
                    }
                    .icon-click{
                        margin-left: 10px;
                        font-size: 24px;
                    }
                }
            }
        }

    }
</style>