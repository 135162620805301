var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "showHeardInfo" }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "showHeardPirectrue" },
            [
              _c(
                "el-image",
                {
                  ref: "image",
                  staticClass: "icon-heardPicture",
                  attrs: { fit: "cover", src: _vm.icoUrl() },
                },
                [
                  _c("div", { attrs: { slot: "error" }, slot: "error" }, [
                    _c("i", { staticClass: "el-icon-picture-outline" }),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "heardFlex" }, [
          _c("div", [
            _c("div", [
              _c("span", [_vm._v("登录账号:")]),
              _c("span", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.querydata.username)),
              ]),
            ]),
            _c("div", [
              _c("span", [_vm._v("账号ID:")]),
              _c("span", { staticClass: "value" }, [
                _vm._v(" " + _vm._s(_vm.querydata.uuid)),
              ]),
              _c("span", [
                _c("i", {
                  staticClass: "el-icon-copy-document copyDocument",
                  on: {
                    click: function ($event) {
                      return _vm.copy(_vm.userInfo.uuid)
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _c("div", [
            _c("div", [
              _c("span", [_vm._v("实名认证:")]),
              _vm.querydata.accountType == "1"
                ? _c("span", { staticClass: "certification enterprise" }, [
                    _vm._v("企业认证"),
                  ])
                : _vm._e(),
              _vm.querydata.accountType == "2"
                ? _c("span", { staticClass: "certification individual" }, [
                    _vm._v("个人认证"),
                  ])
                : _vm._e(),
              _vm.querydata.accountType == "0"
                ? _c("span", { staticClass: "certification" }, [
                    _vm._v("未认证"),
                  ])
                : _vm._e(),
              _c(
                "span",
                { staticClass: "changeTo", on: { click: _vm.toSet } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.querydata.accountType != "0" ? "详情" : "立即认证"
                    )
                  ),
                ]
              ),
            ]),
            _c("div", [
              _c("span", [_vm._v("上次登录时间:")]),
              _c("span", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.querydata.loginAt)),
              ]),
            ]),
          ]),
          _c("div", [
            _c("div", [
              _c("span", [_vm._v("注册时间:")]),
              _c("span", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.querydata.createAt)),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c(
        "za-dialog",
        {
          attrs: {
            visible: _vm.dialogdouble,
            "top-title": "修改头像",
            width: "620px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogdouble = $event
            },
          },
        },
        [
          _c("uplode-img", {
            on: { change: _vm.closeDialog, top: _vm.openDialog },
          }),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "za-button",
                {
                  attrs: {
                    disabled: _vm.confirmBtnLoading,
                    type: _vm.buttonTypeObj.GREY,
                  },
                  on: {
                    click: function ($event) {
                      _vm.dialogdouble = false
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.translateTitle("btn.cancel")) + " ")]
              ),
              false
                ? _c(
                    "za-button",
                    {
                      attrs: {
                        loading: _vm.confirmBtnLoading,
                        type: _vm.buttonTypeObj.BLUE,
                      },
                      on: { click: _vm.uploadImg },
                    },
                    [_vm._v(" 上传头像 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }