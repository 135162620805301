export default {
    city: [
        {
            "label": "热门",
            "value":'remen',
            "children": [
                { "label": "上海", "code": "3101", "value": "shanghai" },
                { "label": "北京", "code": "1101", "value": "beijing" },
                { "label": "广州", "code": "4401", "value": "guangzhou" },
                { "label": "重庆", "code": "5001", "value": "chongqing" },
                { "label": "成都", "code": "5101", "value": "chengdu" },

            ]
        },
        {
            "label": "ABC",
            "value":'ABC',
            "children": [
                { "label": "阿坝", "code": "5132", "value": "abei" },
                { "label": "阿克苏", "code": "6529", "value": "akesu" },
                { "label": "阿拉善", "code": "1529", "value": "alashan" },
                { "label": "阿里", "code": "5425", "value": "ali" },
                { "label": "安康", "code": "6109", "value": "ankang" },
                { "label": "安庆", "code": "3408", "value": "anqing" },
                { "label": "鞍山", "code": "2103", "value": "anshan" },
                { "label": "安顺", "code": "5204", "value": "anshun" },
                { "label": "安阳", "code": "4105", "value": "anyang" },
                { "label": "北京", "code": "1101", "value": "beijing" },
                { "label": "白银", "code": "6204", "value": "baiyin" },
                { "label": "保定", "code": "1306", "value": "baoding" },
                { "label": "宝鸡", "code": "6103", "value": "baoji" },
                { "label": "保山", "code": "5305", "value": "baoshan" },
                { "label": "包头", "code": "1502", "value": "baotou" },
                { "label": "巴中", "code": "5119", "value": "bazhong" },
                { "label": "北海", "code": "4505", "value": "beihai" },
                { "label": "蚌埠", "code": "3403", "value": "bengbu" },
                { "label": "本溪", "code": "2105", "value": "benxi" },
                { "label": "毕节", "code": "5205", "value": "bijie" },
                { "label": "滨州", "code": "3716", "value": "binzhou" },
                { "label": "百色", "code": "4510", "value": "baise" },
                { "label": "亳州", "code": "3416", "value": "bozhou" },
                { "label": "重庆", "code": "5001", "value": "chongqing" },
                { "label": "成都", "code": "5101", "value": "chengdu" },
                { "label": "长沙", "code": "4301", "value": "changsha" },
                { "label": "长春", "code": "2201", "value": "changchun" },
                { "label": "沧州", "code": "1309", "value": "cangzhou" },
                { "label": "常德", "code": "4307", "value": "changde" },
                { "label": "昌都", "code": "5421", "value": "changdu" },
                { "label": "长治", "code": "1404", "value": "changzhi" },
                { "label": "常州", "code": "3204", "value": "changzhou" },
                { "label": "潮州", "code": "4451", "value": "chaozhou" },
                { "label": "承德", "code": "1308", "value": "chengde" },
                { "label": "郴州", "code": "4310", "value": "chenzhou" },
                { "label": "赤峰", "code": "1504", "value": "chifeng" },
                { "label": "池州", "code": "3417", "value": "chizhou" },
                { "label": "崇左", "code": "4514", "value": "chongzuo" },
                { "label": "楚雄", "code": "5323", "value": "chuxiong" },
                { "label": "滁州", "code": "3411", "value": "chuzhou" },
                { "label": "朝阳", "code": "2113", "value": "chaoyang" },
            ]
        },
        {
            "label": "DEF",
            "value": "DEF",
            "children": [
                { "label": "大连", "code": "2102", "value": "dalian" },
                { "label": "东莞", "code": "4419", "value": "dongguan" },
                { "label": "大理", "code": "5329", "value": "dali" },
                { "label": "丹东", "code": "2106", "value": "dandong" },
                { "label": "大庆", "code": "2306", "value": "daqing" },
                { "label": "大同", "code": "1402", "value": "datong" },
                { "label": "大兴安岭", "code": "2327", "value": "daxinganling" },
                { "label": "德宏", "code": "5331", "value": "dehong" },
                { "label": "德阳", "code": "5106", "value": "deyang" },
                { "label": "德州", "code": "3714", "value": "dezhou" },
                { "label": "定西", "code": "6211", "value": "dingxi" },
                { "label": "迪庆", "code": "5334", "value": "diqing" },
                { "label": "东营", "code": "3705", "value": "dongying" },
                { "label": "鄂尔多斯", "code": "1506", "value": "eerduosi" },
                { "label": "恩施", "code": "4228", "value": "enshi" },
                { "label": "鄂州", "code": "4207", "value": "ezhou" },
                { "label": "福州", "code": "3501", "value": "fuzhou" },
                { "label": "防城港", "code": "4506", "value": "fangchenggang" },
                { "label": "佛山", "code": "4406", "value": "foshan" },
                { "label": "抚顺", "code": "2104", "value": "fushun" },
                { "label": "抚州", "code": "3610", "value": "fuzhou" },
                { "label": "阜新", "code": "2109", "value": "fuxin" },
                { "label": "阜阳", "code": "3412", "value": "fuyang" },
            ]
        },

        {
            "label": "GHJ",
            "value": "GHJ",
            "children": [
                { "label": "广州", "code": "4401", "value": "guangzhou" },
                { "label": "桂林", "code": "4503", "value": "guilin" },
                { "label": "贵阳", "code": "5201", "value": "guiyang" },
                { "label": "甘南", "code": "6230", "value": "gannan" },
                { "label": "赣州", "code": "3607", "value": "ganzhou" },
                { "label": "甘孜", "code": "5133", "value": "ganzi" },
                { "label": "广安", "code": "5116", "value": "guangan" },
                { "label": "广元", "code": "5108", "value": "guangyuan" },
                { "label": "贵港", "code": "4508", "value": "guigang" },
                { "label": "果洛", "code": "6326", "value": "guoluo" },
                { "label": "杭州", "code": "3301", "value": "hangzhou" },
                { "label": "哈尔滨", "code": "2301", "value": "haerbin" },
                { "label": "合肥", "code": "3401", "value": "hefei" },
                { "label": "海口", "code": "4601", "value": "haikou" },
                { "label": "呼和浩特", "code": "1501", "value": "huhehaote" },
                { "label": "海北", "code": "6322", "value": "haibei" },
                { "label": "海东", "code": "6321", "value": "haidong" },
                { "label": "海南", "code": "4600", "value": "hainan" },
                { "label": "海西", "code": "6328", "value": "haixi" },
                { "label": "邯郸", "code": "1304", "value": "handan" },
                { "label": "汉中", "code": "6107", "value": "hanzhong" },
                { "label": "鹤壁", "code": "4106", "value": "hebi" },
                { "label": "河池", "code": "4512", "value": "hechi" },
                { "label": "鹤岗", "code": "2304", "value": "hegang" },
                { "label": "黑河", "code": "2311", "value": "heihe" },
                { "label": "衡水", "code": "1311", "value": "hengshui" },
                { "label": "衡阳", "code": "4304", "value": "hengyang" },
                { "label": "河源", "code": "4416", "value": "heyuan" },
                { "label": "贺州", "code": "4511", "value": "hezhou" },
                { "label": "红河", "code": "5325", "value": "honghe" },
                { "label": "淮安", "code": "3208", "value": "huaian" },
                { "label": "淮北", "code": "3406", "value": "huaibei" },
                { "label": "怀化", "code": "4312", "value": "huaihua" },
                { "label": "淮南", "code": "3404", "value": "huainan" },
                { "label": "黄冈", "code": "4211", "value": "huanggang" },
                { "label": "黄南", "code": "6323", "value": "huangnan" },
                { "label": "黄山", "code": "3410", "value": "huangshan" },
                { "label": "黄石", "code": "4202", "value": "huangshi" },
                { "label": "惠州", "code": "4413", "value": "huizhou" },
                { "label": "葫芦岛", "code": "2114", "value": "huludao" },
                { "label": "呼伦贝尔", "code": "1507", "value": "hulunbeier" },
                { "label": "湖州", "code": "3305", "value": "huzhou" },
                { "label": "菏泽", "code": "3717", "value": "heze" },
                { "label": "济南", "code": "3701", "value": "jinan" },
                { "label": "佳木斯", "code": "2308", "value": "jiamusi" },
                { "label": "吉安", "code": "3608", "value": "jian" },
                { "label": "江门", "code": "4407", "value": "jiangmen" },
                { "label": "焦作", "code": "4108", "value": "jiaozuo" },
                { "label": "嘉兴", "code": "3304", "value": "jiaxing" },
                { "label": "嘉峪关", "code": "6202", "value": "jiayuguan" },
                { "label": "揭阳", "code": "4452", "value": "jieyang" },
                { "label": "吉林", "code": "2202", "value": "jilin" },
                { "label": "金昌", "code": "6203", "value": "jinchang" },
                { "label": "晋城", "code": "1405", "value": "jincheng" },
                { "label": "景德镇", "code": "3602", "value": "jingdezhen" },
                { "label": "荆门", "code": "4208", "value": "jingmen" },
                { "label": "荆州", "code": "4210", "value": "jingzhou" },
                { "label": "金华", "code": "3307", "value": "jinhua" },
                { "label": "济宁", "code": "3708", "value": "jining" },
                { "label": "晋中", "code": "1407", "value": "jinzhong" },
                { "label": "锦州", "code": "2107", "value": "jinzhou" },
                { "label": "九江", "code": "3604", "value": "jiujiang" },
                { "label": "酒泉", "code": "6209", "value": "jiuquan" },
            ]

        },
        {
            "label": "KLM",
            "value": "KLM",
            "children": [
                { "label": "昆明", "code": "5301", "value": "kunming" },
                { "label": "开封", "code": "4102", "value": "kaifeng" },
                { "label": "兰州", "code": "6201", "value": "lanzhou" },
                { "label": "拉萨", "code": "5401", "value": "lasa" },
                { "label": "来宾", "code": "4513", "value": "laibin" },
                { "label": "莱芜", "code": "3712", "value": "laiwu" },
                { "label": "廊坊", "code": "1310", "value": "langfang" },
                { "label": "乐山", "code": "5111", "value": "leshan" },
                { "label": "凉山", "code": "5134", "value": "liangshan" },
                { "label": "连云港", "code": "3207", "value": "lianyungang" },
                { "label": "聊城", "code": "3715", "value": "liaocheng" },
                { "label": "辽阳", "code": "2110", "value": "liaoyang" },
                { "label": "辽源", "code": "2204", "value": "liaoyuan" },
                { "label": "丽江", "code": "5307", "value": "lijiang" },
                { "label": "临沧", "code": "5309", "value": "lincang" },
                { "label": "临汾", "code": "1410", "value": "linfen" },
                { "label": "临夏", "code": "6229", "value": "linxia" },
                { "label": "临沂", "code": "3713", "value": "linyi" },
                { "label": "林芝", "code": "5426", "value": "linzhi" },
                { "label": "丽水", "code": "3311", "value": "lishui" },
                { "label": "六安", "code": "3415", "value": "liuan" },
                { "label": "六盘水", "code": "5202", "value": "liupanshui" },
                { "label": "柳州", "code": "4502", "value": "liuzhou" },
                { "label": "陇南", "code": "6212", "value": "longnan" },
                { "label": "龙岩", "code": "3508", "value": "longyan" },
                { "label": "娄底", "code": "4313", "value": "loudi" },
                { "label": "漯河", "code": "4111", "value": "luohe" },
                { "label": "洛阳", "code": "4103", "value": "luoyang" },
                { "label": "泸州", "code": "5105", "value": "luzhou" },
                { "label": "吕梁", "code": "1411", "value": "lvliang" },
                { "label": "马鞍山", "code": "3405", "value": "maanshan" },
                { "label": "茂名", "code": "4409", "value": "maoming" },
                { "label": "眉山", "code": "5114", "value": "meishan" },
                { "label": "梅州", "code": "4414", "value": "meizhou" },
                { "label": "绵阳", "code": "5107", "value": "mianyang" },
                { "label": "牡丹江", "code": "2310", "value": "mudanjiang" },
            ]

        },
        {
            "label": "NPQ",
            "value": "NPQ",
            "children": [
                { "label": "南京", "code": "3201", "value": "nanjing" },
                { "label": "南昌", "code": "3601", "value": "nanchang" },
                { "label": "南宁", "code": "4501", "value": "nanning" },
                { "label": "宁波", "code": "3302", "value": "ningbo" },
                { "label": "南充", "code": "5113", "value": "nanchong" },
                { "label": "南平", "code": "3507", "value": "nanping" },
                { "label": "南通", "code": "3206", "value": "nantong" },
                { "label": "南阳", "code": "4113", "value": "nanyang" },
                { "label": "那曲", "code": "5424", "value": "naqu" },
                { "label": "内江", "code": "5110", "value": "neijiang" },
                { "label": "宁德", "code": "3509", "value": "ningde" },
                { "label": "怒江", "code": "5333", "value": "nujiang" },
                { "label": "盘锦", "code": "2111", "value": "panjin" },
                { "label": "攀枝花", "code": "5104", "value": "panzhihua" },
                { "label": "平顶山", "code": "4104", "value": "pingdingshan" },
                { "label": "平凉", "code": "6208", "value": "pingliang" },
                { "label": "萍乡", "code": "3603", "value": "pingxiang" },
                { "label": "莆田", "code": "3503", "value": "putian" },
                { "label": "濮阳", "code": "4109", "value": "puyang" },
                { "label": "青岛", "code": "3702", "value": "qingdao" },
                { "label": "黔东南", "code": "5226", "value": "qindongnan" },
                { "label": "黔南", "code": "5227", "value": "qinnan" },
                { "label": "黔西南", "code": "5223", "value": "qinxinan" },
                { "label": "庆阳", "code": "6210", "value": "qingyang" },
                { "label": "清远", "code": "4418", "value": "qingyuan" },
                { "label": "秦皇岛", "code": "1303", "value": "qinhuangdao" },
                { "label": "钦州", "code": "4507", "value": "qinzhou" },
                { "label": "齐齐哈尔", "code": "2302", "value": "qiqihaer" },
                { "label": "泉州", "code": "3505", "value": "quanzhou" },
                { "label": "曲靖", "code": "5303", "value": "qujing" },
                { "label": "衢州", "code": "3308", "value": "gquzhou" },
            ]
        },
        {
            "label": "RST",
            "value": "RST",
            "children": [
                { "label": "日喀则", "code": "5423", "value": "rieze" },
                { "label": "日照", "code": "3711", "value": "rizhao" },
                { "label": "上海", "code": "3101", "value": "shanghai" },
                { "label": "深圳", "code": "4403", "value": "shenzhen" },
                { "label": "苏州", "code": "3205", "value": "suzhou" },
                { "label": "沈阳", "code": "2101", "value": "shenyang" },
                { "label": "石家庄", "code": "1301", "value": "shijiazhuang" },
                { "label": "三门峡", "code": "4112", "value": "sanmenxia" },
                { "label": "三明", "code": "3504", "value": "sanming" },
                { "label": "三亚", "code": "4602", "value": "sanya" },
                { "label": "商洛", "code": "6110", "value": "shangluo" },
                { "label": "商丘", "code": "4114", "value": "shangqiu" },
                { "label": "上饶", "code": "3611", "value": "shangrao" },
                { "label": "山南", "code": "5422", "value": "shannan" },
                { "label": "汕头", "code": "4405", "value": "shantou" },
                { "label": "汕尾", "code": "4415", "value": "shanwei" },
                { "label": "韶关", "code": "4402", "value": "shaoguan" },
                { "label": "绍兴", "code": "3306", "value": "shaoxing" },
                { "label": "邵阳", "code": "4305", "value": "shaoyang" },
                { "label": "十堰", "code": "4203", "value": "shiyan" },
                { "label": "朔州", "code": "1406", "value": "suozhou" },
                { "label": "四平", "code": "2203", "value": "siping" },
                { "label": "绥化", "code": "2312", "value": "suihua" },
                { "label": "遂宁", "code": "5109", "value": "suining" },
                { "label": "随州", "code": "4213", "value": "suizhou" },
                { "label": "宿迁", "code": "3213", "value": "suqian" },
                { "label": "宿州", "code": "3413", "value": "suzhou" },
                { "label": "天津", "code": "1201", "value": "tianjin" },
                { "label": "太原", "code": "1401", "value": "taiyuan" },
                { "label": "泰安", "code": "3709", "value": "taian" },
                { "label": "泰州", "code": "3212", "value": "taizhou" },
                { "label": "台州", "code": "3310", "value": "taizhou" },
                { "label": "唐山", "code": "1302", "value": "tangshan" },
                { "label": "天水", "code": "6205", "value": "tianshui" },
                { "label": "铁岭", "code": "2112", "value": "tieling" },
                { "label": "铜川", "code": "6102", "value": "tongchuan" },
                { "label": "通化", "code": "2205", "value": "tonghua" },
                { "label": "通辽", "code": "1505", "value": "tongliao" },
                { "label": "铜陵", "code": "3407", "value": "tongling" },
                { "label": "铜仁", "code": "5206", "value": "tongren" },
            ]
        },

        {
            "label": "WXYZ",
            "value": "WXYZ",
            "children": [
                { "label": "武汉", "code": "4201", "value": "wuhan" },
                { "label": "乌鲁木齐", "code": "6501", "value": "wulumuqi" },
                { "label": "无锡", "code": "3202", "value": "wuxi" },
                { "label": "威海", "code": "3710", "value": "weihai" },
                { "label": "潍坊", "code": "3707", "value": "weifang" },
                { "label": "文山", "code": "5326", "value": "wenshan" },
                { "label": "温州", "code": "3303", "value": "wenzhou" },
                { "label": "乌海", "code": "1503", "value": "wuhai" },
                { "label": "芜湖", "code": "3402", "value": "wuhu" },
                { "label": "乌兰察布", "code": "1509", "value": "wulancabu" },
                { "label": "武威", "code": "6206", "value": "wuwei" },
                { "label": "梧州", "code": "4504", "value": "wuzhou" },
                { "label": "厦门", "code": "3502", "value": "xiamen" },
                { "label": "西安", "code": "6101", "value": "xian" },
                { "label": "西宁", "code": "6301", "value": "xining" },
                { "label": "襄樊", "code": "4206", "value": "xiangfan" },
                { "label": "湘潭", "code": "4303", "value": "xiangtan" },
                { "label": "湘西", "code": "4331", "value": "xiangxi" },
                { "label": "咸宁", "code": "4212", "value": "xianning" },
                { "label": "咸阳", "code": "6104", "value": "xianyang" },
                { "label": "孝感", "code": "4209", "value": "xiaogan" },
                { "label": "邢台", "code": "1305", "value": "xingtai" },
                { "label": "新乡", "code": "4107", "value": "xinxiang" },
                { "label": "信阳", "code": "4115", "value": "xinyang" },
                { "label": "新余", "code": "3605", "value": "xinyu" },
                { "label": "忻州", "code": "1409", "value": "xinzhou" },
                { "label": "西双版纳", "code": "5328", "value": "xishuangbanna" },
                { "label": "宣城", "code": "3418", "value": "xuancheng" },
                { "label": "许昌", "code": "4110", "value": "xuchang" },
                { "label": "徐州", "code": "3203", "value": "xuzhou" },
                { "label": "锡林郭勒", "code": "1525", "value": "xilinguole" },
                { "label": "兴安", "code": "1522", "value": "xingan" },
                { "label": "银川", "code": "6401", "value": "yinchuan" },
                { "label": "雅安", "code": "5118", "value": "yaan" },
                { "label": "延安", "code": "6106", "value": "yanan" },
                { "label": "延边", "code": "2224", "value": "yanbian" },
                { "label": "盐城", "code": "3209", "value": "yancheng" },
                { "label": "阳江", "code": "4417", "value": "yangjiang" },
                { "label": "阳泉", "code": "1403", "value": "yangquan" },
                { "label": "扬州", "code": "3210", "value": "yangzhou" },
                { "label": "烟台", "code": "3706", "value": "yantai" },
                { "label": "宜宾", "code": "5115", "value": "yibin" },
                { "label": "宜昌", "code": "4205", "value": "yichang" },
                { "label": "宜春", "code": "3609", "value": "yichun" },
                { "label": "营口", "code": "2108", "value": "yingkou" },
                { "label": "益阳", "code": "4309", "value": "yiyang" },
                { "label": "永州", "code": "4311", "value": "yongzhou" },
                { "label": "岳阳", "code": "4306", "value": "yueyang" },
                { "label": "榆林", "code": "6108", "value": "yulin" },
                { "label": "运城", "code": "1408", "value": "yuncheng" },
                { "label": "云浮", "code": "4453", "value": "yunfu" },
                { "label": "玉树", "code": "6327", "value": "yushu" },
                { "label": "玉溪", "code": "5304", "value": "yuxi" },
                { "label": "玉林", "code": "4509", "value": "yulin" },
                { "label": "枣庄", "code": "3704", "value": "zaozhuang" },
                { "label": "张家界", "code": "4308", "value": "zhangjiajie" },
                { "label": "张家口", "code": "1307", "value": "zhangjiakou" },
                { "label": "张掖", "code": "6207", "value": "zhangye" },
                { "label": "漳州", "code": "3506", "value": "zhangzhou" },
                { "label": "湛江", "code": "4408", "value": "zhanjiang" },
                { "label": "肇庆", "code": "4412", "value": "zhaoqing" },
                { "label": "昭通", "code": "5306", "value": "zhaotong" },
                { "label": "浙江", "code": "3300", "value": "zhejiang" },
                { "label": "郑州", "code": "4101", "value": "zhengzhou" },
                { "label": "镇江", "code": "3211", "value": "zhenjiang" },
                { "label": "中山", "code": "4420", "value": "zhongshan" },
                { "label": "中卫", "code": "6405", "value": "zhongwei" },
                { "label": "周口", "code": "4116", "value": "zhoukou" },
                { "label": "舟山", "code": "3309", "value": "zhoushan" },
                { "label": "珠海", "code": "4404", "value": "zhuhai" },
                { "label": "驻马店", "code": "4117", "value": "zhumadian" },
                { "label": "株洲", "code": "4302", "value": "zhuzhou" },
                { "label": "淄博", "code": "3703", "value": "zibo" },
                { "label": "自贡", "code": "5103", "value": "zigong" },
                { "label": "资阳", "code": "5120", "value": "ziyang" },
            ]
        }
    ]
}
