var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "drawAbill-center-bg" }, [
    _c(
      "div",
      [
        _c("za-go-back", {
          attrs: { title: "返回发票列表" },
          on: { click: _vm.goBack },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "main" }, [
      _c("div", { staticClass: "prompt" }, [
        _c("span", { staticClass: "orange_font_bold" }, [
          _vm._v(
            " 普票电子化通知：为积极响应国家发票无纸化政策，智安云云将于2023年10月1日起关闭增值税电子发票和增值税纸质发票申请入口，您可以直接申请全电发票。感谢您的支持！ "
          ),
        ]),
        _c("h5", [_vm._v(_vm._s(_vm.translateTitle("topUp.reminder")) + "：")]),
        _vm._m(0),
        _c("span", [
          _vm._v(
            " 2.后付费资源产生的消费次月账期结算后计入发票总额，当月发票仅能选择预支付订单直接开票，如有问题请及时联系客服。 "
          ),
        ]),
        _c("span", [
          _vm._v(
            "3.若当前可用余额欠费，后付费资源产生的费用无法开票，请充值后刷新页面重试。"
          ),
        ]),
      ]),
      _c("div", { staticClass: "payType" }, [
        _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-tabs",
              {
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "按月份开票", name: "first" } },
                  [_vm.activeName == "first" ? _c("mouth") : _vm._e()],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "按订单开票", name: "second" } },
                  [_vm.activeName == "second" ? _c("order") : _vm._e()],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _vm._v(" 1.智安云已支持全电发票，推荐您使用全电发票。 "),
      _c("span", { staticClass: "orange_font" }, [
        _vm._v(
          " 电子发票与纸质发票具有同等法律效力，可在发票通过审核后24小时内开出并发送到您的邮箱中。 "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }