import { render, staticRenderFns } from "./effective.vue?vue&type=template&id=23921061&scoped=true"
import script from "./effective.vue?vue&type=script&lang=js"
export * from "./effective.vue?vue&type=script&lang=js"
import style0 from "./effective.vue?vue&type=style&index=0&id=23921061&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23921061",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/.npm/za-console-portal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('23921061')) {
      api.createRecord('23921061', component.options)
    } else {
      api.reload('23921061', component.options)
    }
    module.hot.accept("./effective.vue?vue&type=template&id=23921061&scoped=true", function () {
      api.rerender('23921061', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/promotionManagement/effect/backOrder/components/effective.vue"
export default component.exports