var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "za-dialog",
        {
          attrs: {
            "top-title": _vm.topTitle,
            visible: _vm.payDialog,
            width: "800px",
            showClose: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.payDialog = $event
            },
          },
        },
        [
          _c("div", [
            _c("div", { staticClass: "prompt" }, [
              _c(
                "span",
                { staticStyle: { "margin-left": "16px" } },
                [_c("za-svg-icon", { attrs: { "icon-class": "za-wanning" } })],
                1
              ),
              _c("span", [
                _vm._v(
                  "请在新页面完成付款，如付款遇到问题请致电: 400-018-5113"
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "za-button",
                {
                  attrs: { type: _vm.buttonTypeObj.GREY },
                  on: { click: _vm.successPay },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.isPay ? "支付遇到问题" : "充值遇到问题") +
                      " "
                  ),
                ]
              ),
              _c(
                "za-button",
                {
                  attrs: {
                    disabled: _vm.confirmBtnLoading,
                    type: _vm.buttonTypeObj.BLUE,
                  },
                  on: { click: _vm.successPay },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.isPay ? "已完成支付" : "已完成充值") + " "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }