var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "drawInfo-center_bg" },
    [
      _c(
        "div",
        [
          _c("za-go-back", {
            attrs: { title: "确认发票信息" },
            on: { click: _vm.goBack },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "main" }, [
        _vm._m(0),
        _c(
          "div",
          [
            _c("div", { staticClass: "heard" }, [
              _c("span", [_vm._v("请确认发票抬头")]),
              !_vm.tableData.length
                ? _c(
                    "span",
                    {
                      staticClass: "fillOut",
                      on: {
                        click: function ($event) {
                          return _vm.$router.push(
                            "/moneyCenter/invoiceManagement/address"
                          )
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _vm._v(" 填写发票信息 "),
                    ]
                  )
                : _vm._e(),
            ]),
            _c("occ-table", {
              attrs: {
                tableData: _vm.tableData,
                columObj: _vm.columObj,
                isOp: _vm.columObj.isOp,
                "style-height": "98",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleChange(row)
                            },
                          },
                        },
                        [_vm._v("修改")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c("div", { staticClass: "main-redio" }, [
          _c("div", { staticClass: "main-tesc" }, [
            _c("div", [
              _vm._m(1),
              _c(
                "div",
                { staticStyle: { width: "30%" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: { model: _vm.form, "label-width": "120px" },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 6,
                          placeholder: "填写后打印在发票的备注内容区域",
                          maxlength: "230",
                        },
                        model: {
                          value: _vm.form.desc,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "desc", $$v)
                          },
                          expression: "form.desc",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._m(2),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "bun_fix" },
          [
            _c(
              "el-button",
              { attrs: { primary: "" }, on: { click: _vm.toTop } },
              [_vm._v("上一步")]
            ),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.nextBtn } },
              [_vm._v("下一步")]
            ),
            _c(
              "el-button",
              { attrs: { primary: "" }, on: { click: _vm.cancel } },
              [_vm._v("取消")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "za-dialog",
        {
          attrs: {
            "top-title": "",
            visible: _vm.infoDialog,
            width: "420px",
            titleState: false,
            footState: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.infoDialog = $event
            },
          },
        },
        [
          _c("div", [
            _c("div", [
              _c("div", { staticClass: "icon" }, [
                _c("i", {
                  staticClass: "el-icon-warning",
                  staticStyle: { color: "blue" },
                }),
                _c("p", [_vm._v("确认开票")]),
              ]),
              _c("div", { staticClass: "iconInfo" }, [
                _c("span", [
                  _vm._v(
                    "若因发票信息填写错误导致发票不可用，需待发票开具后发起退票申请。请仔细核对发票信息！"
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "wanningSubmit" },
                [
                  _c(
                    "za-button",
                    {
                      attrs: {
                        loading: _vm.confirmBtnLoading,
                        type: _vm.buttonTypeObj.BLUE,
                      },
                      on: { click: _vm.infoDialogSubmit },
                    },
                    [_vm._v(" 确定 ")]
                  ),
                  _c(
                    "za-button",
                    {
                      attrs: {
                        disabled: _vm.confirmBtnLoading,
                        type: _vm.buttonTypeObj.GREY,
                      },
                      on: {
                        click: function ($event) {
                          _vm.infoDialog = false
                        },
                      },
                    },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "za-dialog",
        {
          attrs: {
            "top-title": "开票申请提交成功",
            visible: _vm.lastInfoDialog,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.lastInfoDialog = $event
            },
          },
        },
        [
          _c("div", [
            _c("div", { staticClass: "success" }, [
              _c(
                "span",
                [
                  _c("za-svg-icon", { attrs: { "icon-class": "za-wanning" } }),
                  _vm._v(
                    " 电子发票将在申请提交24小时内开具并发送至您的联系邮箱，您可以在联系邮箱里下载发票。 "
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "za-button",
                {
                  attrs: {
                    loading: _vm.confirmBtnLoading,
                    type: _vm.buttonTypeObj.BLUE,
                  },
                  on: { click: _vm.closeLastInfoDialog },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "za-dialog",
        {
          attrs: {
            "top-title": _vm._f("dialogTitle")(
              _vm.dialogType,
              _vm.dialogTypeObj
            ),
            visible: _vm.dialogVisible,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "tipsInfo" },
                [
                  _c("za-svg-icon", { attrs: { "icon-class": "za-wanning" } }),
                  _c("span", [
                    _vm._v(
                      " 普票电子化通知：为积极响应国家发票无纸化政策，智安云云将于2023年10月1日起关闭增值税电子发票和增值税纸质发票申请入口，改为数电发票，请您确认重新确认发票信息无误，以免影响发票的后续使用。 "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    disabled: _vm.confirmBtnLoading,
                    model: _vm.baseForm,
                    "label-position": "right",
                    "label-width": "140px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "抬头类型" + "：", prop: "headType" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.changeRadio },
                          model: {
                            value: _vm.baseForm.headType,
                            callback: function ($$v) {
                              _vm.$set(_vm.baseForm, "headType", $$v)
                            },
                            expression: "baseForm.headType",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                label: _vm.headTypes.personal,
                                disabled:
                                  _vm.userInfo.accountType ===
                                  _vm.accountTypes.entCer,
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.headTypesName.personal) + " "
                              ),
                            ]
                          ),
                          _c(
                            "el-radio",
                            { attrs: { label: _vm.headTypes.enterprise } },
                            [_vm._v(_vm._s(_vm.headTypesName.enterprise))]
                          ),
                          _c(
                            "el-radio",
                            { attrs: { label: _vm.headTypes.organization } },
                            [_vm._v(_vm._s(_vm.headTypesName.organization))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "发票类型" + "：", prop: "invoicesType" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.changeInvoicesTypeRadio },
                          model: {
                            value: _vm.baseForm.invoicesType,
                            callback: function ($$v) {
                              _vm.$set(_vm.baseForm, "invoicesType", $$v)
                            },
                            expression: "baseForm.invoicesType",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            { attrs: { label: _vm.invoicesType.digEleTicket } },
                            [_vm._v(_vm._s(_vm.invoiceTypeName.digEleTicket))]
                          ),
                          _vm.baseForm.headType === _vm.headTypes.enterprise
                            ? _c(
                                "el-radio",
                                {
                                  attrs: {
                                    label: _vm.invoicesType.digSpeTicket,
                                    disabled:
                                      _vm.userInfo.accountType ===
                                        _vm.accountTypes.perCer ||
                                      _vm.userInfo.accountType ===
                                        _vm.accountTypes.notCer,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.invoiceTypeName.digSpeTicket) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "发票抬头：", prop: "head" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: `${_vm.translateTitle("placeTxt")}`,
                          disabled:
                            _vm.baseForm.headType === _vm.headTypes.personal ||
                            _vm.baseForm.invoicesType ===
                              _vm.invoicesType.digSpeTicket,
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            return _vm.trim("head")
                          },
                        },
                        model: {
                          value: _vm.baseForm.head,
                          callback: function ($$v) {
                            _vm.$set(_vm.baseForm, "head", $$v)
                          },
                          expression: "baseForm.head",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.baseForm.headType !== _vm.headTypes.personal
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.baseForm.headType === _vm.headTypes.enterprise
                                ? "企业信用代码："
                                : "纳税人识别号：",
                            prop: "taxpayerNumber",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "20",
                              placeholder:
                                _vm.baseForm.headType ===
                                _vm.headTypes.enterprise
                                  ? "请填写企业信用代码"
                                  : "请填写纳税人识别号",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                return _vm.trim("taxpayerNumber")
                              },
                            },
                            model: {
                              value: _vm.baseForm.taxpayerNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.baseForm, "taxpayerNumber", $$v)
                              },
                              expression: "baseForm.taxpayerNumber",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.baseForm.headType === _vm.headTypes.enterprise
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "基本开户银行：",
                            prop: "bankName",
                            rules:
                              _vm.baseForm.invoicesType ===
                              _vm.invoicesType.digSpeTicket
                                ? _vm.rules.bankName
                                : _vm.rules2.bankName,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              minlength: 4,
                              maxlength: 50,
                              placeholder: "请输入真实有效的开户银行信息",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                return _vm.trim("bankName")
                              },
                            },
                            model: {
                              value: _vm.baseForm.bankName,
                              callback: function ($$v) {
                                _vm.$set(_vm.baseForm, "bankName", $$v)
                              },
                              expression: "baseForm.bankName",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.baseForm.headType === _vm.headTypes.enterprise
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "基本开户账号：",
                            prop: "bankAccount",
                            rules:
                              _vm.baseForm.invoicesType ===
                              _vm.invoicesType.digSpeTicket
                                ? _vm.rules.bankAccount
                                : _vm.rules2.bankAccount,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请填写许可证上的开户账号" },
                            on: {
                              input: function ($event) {
                                return _vm.limitInput($event, "bankAccount")
                              },
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                return _vm.trim("bankAccount")
                              },
                            },
                            model: {
                              value: _vm.baseForm.bankAccount,
                              callback: function ($$v) {
                                _vm.$set(_vm.baseForm, "bankAccount", $$v)
                              },
                              expression: "baseForm.bankAccount",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.baseForm.headType === _vm.headTypes.enterprise
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "企业注册地址：",
                            prop: "logonAddress",
                            rules:
                              _vm.baseForm.invoicesType ===
                              _vm.invoicesType.digSpeTicket
                                ? _vm.rules.logonAddress
                                : _vm.rules2.logonAddress,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              minlength: 4,
                              maxlength: 50,
                              placeholder: "请输入企业注册地址",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                return _vm.trim("logonAddress")
                              },
                            },
                            model: {
                              value: _vm.baseForm.logonAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.baseForm, "logonAddress", $$v)
                              },
                              expression: "baseForm.logonAddress",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.baseForm.headType === _vm.headTypes.enterprise
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "企业注册电话：",
                            prop: "logonPhone",
                            rules:
                              _vm.baseForm.invoicesType ===
                              _vm.invoicesType.digSpeTicket
                                ? _vm.rules.logonPhone
                                : _vm.rules2.logonPhone,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请填写企业有效联系方式",
                              maxlength: "20",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.limitInput($event, "logonPhone")
                              },
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                return _vm.trim("logonPhone")
                              },
                            },
                            model: {
                              value: _vm.baseForm.logonPhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.baseForm, "logonPhone", $$v)
                              },
                              expression: "baseForm.logonPhone",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: { "margin-top": "10px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "za-button",
                {
                  attrs: {
                    disabled: _vm.confirmBtnLoading,
                    type: _vm.buttonTypeObj.GREY,
                  },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.translateTitle("btn.cancel")) + " ")]
              ),
              _c(
                "za-button",
                {
                  attrs: {
                    loading: _vm.confirmBtnLoading,
                    type: _vm.buttonTypeObj.BLUE,
                  },
                  on: { click: _vm.handleSubmitForm },
                },
                [_vm._v(" " + _vm._s(_vm.translateTitle("btn.submit")) + " ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "prompt" }, [
      _c("span", [_vm._v("温馨提示:")]),
      _c("span", [
        _vm._v(
          "发票基于预付费订单、后付费月结数据开具，后付费的可开票金额可在次月2日后申请发票"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "heard" }, [
      _c("span", [_vm._v("填写发票备注（选填）")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "tips" }, [
      _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
      _vm._v(" 发票备注不可超过 230 个字节，一个中文字符为 2 个字节 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }