<template>
  <div>
    <!-- <div id="weichat" style="position: absolute; top: -50px; z-index: 10; width: 400px; background-color: white"></div> -->

    <el-row>
      <el-form
        ref="signInInfoObj"
        :disabled="confirmBtnLoading"
        :model="signInInfoObj"
        :rules="rules"
        class="sign"
        label-position="right"
      >
        <el-col :span="24">
          <el-form-item prop="username">
            <el-input
              v-focus
              v-model.trim="signInInfoObj.username"
              placeholder="账户名/邮箱"
              :readonly="confirmBtnLoading"
              type="text"
              @keyup.native="trim('username')"
            >
              <template #prefix>
                <za-svg-icon class-name="svg-prefix" icon-class="za-people-fill" />
              </template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="password">
            <el-input
              v-model.trim="signInInfoObj.password"
              placeholder="请输入密码"
              :readonly="confirmBtnLoading"
              :type="passwordType"
              @keyup.native="trim('password')"
              @keyup.enter.native="handleSub()"
              @input="clangeWaringDesc()"
            >
              <template #prefix>
                <za-svg-icon class-name="svg-prefix" icon-class="za-lock" />
              </template>
            </el-input>
          </el-form-item>
          <span v-if="waringDescState" class="red-font">{{ waringDesc }}</span>
        </el-col>
        <el-col>
          <el-checkbox v-model="checked" class="confirmCheck" @keyup.enter.native="handleSub()"></el-checkbox>
          <span class="clause" style="color: #999999">我已阅读并同意</span>
          <span class="toclause" @click="$router.push('/protocal/userProtocal')">服务协议</span>
          <span class="clause" style="color: #999999">和</span>
          <span class="toclause" @click="$router.push('/protocal/policy')">隐私政策</span>
        </el-col>
      </el-form>
    </el-row>

    <div class="login_btn">
      <div v-if="dialogState" class="coordinate">
        <goCaptCha
          :showState="dialogState"
          :loginObj="signInInfoObj"
          :heardBase="this.signInInfoObj.b64"
          :centerBase="this.signInInfoObj.tb64"
          @close="handleCloseEvent"
        ></goCaptCha>
      </div>
      <za-button :loading="confirmBtnLoading" :size="14" class="button" @click="handleSub">
        {{ translateTitle('btn.login') }}
      </za-button>
    </div>
    <div class="operation">
      <span @click="forGetPwd">忘记密码</span>

      <span @click="handleRegister">免费注册</span>
    </div>
    <!--        <div class="otherLogin">-->
    <!--            <span>其他登录方式 :</span>-->
    <!--            <span>-->
    <!--                      <za-svg-icon class-name="icon-click" icon-class="za-qq" @click="handlePassword"/>-->
    <!--                      <za-svg-icon class-name="icon-click" icon-class="za-wechat" @click="handlePassword"/>-->
    <!--                      <za-svg-icon class-name="icon-click" icon-class="za-microblog" @click="handlePassword"/>-->
    <!--                  </span>-->
    <!--        </div>-->
    <div>
      <za-dialog top-title="验证手机号登录" :visible.sync="dialogVisible" width="600px">
        <div>
          <el-row>
            <el-form
              ref="form"
              :disabled="confirmBtnLoading"
              :model="baseForm"
              label-position="right"
              label-width="100px"
              :rules="rules"
            >
              <el-col :span="24">
                <el-form-item>
                  <el-input v-model="baseForm.phone" class="input-with-select" disabled />
                </el-form-item>
              </el-col>
              <el-col>
                <el-col :span="16">
                  <el-form-item prop="code">
                    <el-input
                      v-model="baseForm.code"
                      placeholder="请输入验证码"
                      maxlength="6"
                      @input="changeWaring()"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="7">
                  <el-button
                    @click="sendData"
                    class="sendInfo"
                    :disabled="dis"
                    :class="{ grey: isGrey, blue: !isGrey }"
                  >
                    <span v-if="telState == 1">发送验证码</span>
                    <span v-if="telState == 2">{{ count }}秒重试</span>
                    <span v-if="telState == 3">重新发送</span>
                  </el-button>
                </el-col>
              </el-col>
            </el-form>
          </el-row>
        </div>
        <div slot="footer">
          <za-button :disabled="confirmBtnLoading" :type="buttonTypeObj.GREY" @click="dialogVisible = false">
            {{ translateTitle('btn.cancel') }}
          </za-button>
          <za-button :loading="confirmBtnLoading" :type="buttonTypeObj.BLUE" @click="handleSubmitForm">登录</za-button>
        </div>
      </za-dialog>
      <div>
        <el-dialog width="25%" title="验证" :visible.sync="innerVisible" append-to-body>
          <za-verify v-if="showVerity" ref="verify" @verifySuccess="success" :selectKey="dialogType" />
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
  import { translateTitle } from '@/utils/i18n'
  import { apiLoginz, apiSecureSms, apiVerifyLogin, apiWeiXinLogin } from '@/api/apiLogin'
  import { resLoginObj } from '@/za/model/resModel'
  import { mapActions } from 'vuex'
  import ZaVerify from '@/za/components/zaVerify'
  import goCaptCha from '@/za/components/goCaptCha'
  import { getLocation } from '@/utils'
  import store from '@/store'
  import { isHaveSpace } from '@/utils/validate'

  export default {
    components: { ZaVerify, goCaptCha },
    directives: {
      focus: {
        inserted(el) {
          el.querySelector('input').focus()
        },
      },
    },
    data() {
      const smsCode = (rule, value, callback) => {
        if (value === '') callback(new Error('验证码不能为空'))
        else if (isHaveSpace(value)) callback(new Error('验证码不能包含空格！'))
        else callback()
      }
      return {
        innerVisible: false,
        dialogState: false,
        count: '',
        telState: 1,
        dis: false,
        show: true,
        timer: null, //设置计时器,
        isGrey: false, //按钮样式
        showVerity: false,
        redirect: undefined,
        signInInfoObj: {
          username: '',
          password: '',
          phone: '',
          code: '',
          confirmPassword: '',
          captchaId: '',
        },
        checked: true,
        passwordType: 'password',
        baseForm: {
          phone: '',
          username: '',
          code: '',
        },
        rules: {
          username: [{ required: true, message: `${translateTitle('signIn.userRules')}`, trigger: 'blur' }],
          password: [{ required: true, message: `${translateTitle('signIn.pswRules')}`, trigger: 'blur' }],
          verifyValue: [{ required: true, message: '请输入安全校验码', trigger: 'blur' }],
          code: [{ required: true, trigger: 'blur', validator: smsCode }],
        },
        urlString: '',
        popularizeCode: '', //推广 id
      }
    },
    created() {
      const code = this.$route.query.id
      code ? (this.popularizeCode = code) : (this.popularizeCode = '')
    },
    mounted() {
      // console.log(process.env.NODE_ENV, "当前环境");
      if (process.env.NODE_ENV === 'local') {
        this.signInInfoObj.username = 'yzb000'
        this.signInInfoObj.password = 'yzb123'
      }
      this.urlString = getLocation('loginPage')
    },
    watch: {
      $route: {
        handler() {
          this.redirect = '/'
        },
        immediate: true,
      },
    },
    methods: {
      ...mapActions({
        setLoginData: 'user/setLoginData',
      }),
      trim(val) {
        this.signInInfoObj[val] = this.signInInfoObj[val].replace(/\s*/g, '')
      },
      handleRoute() {
        return this.redirect === '/404' || this.redirect === '/403' ? '/' : this.redirect
      },
      handleSub() {
        if (!this.checked) {
          this.$msgWarning('请阅读并同意协议')
          return
        }
        this.$refs.signInInfoObj.validate(async (valid) => {
          if (valid) {
            try {
              let timer = Math.round(new Date().getTime() / 1000).toString()
              this.signInInfoObj.time = timer
              this.confirmBtnLoading = true
              let data = {
                l:
                  'username=' +
                  this.signInInfoObj.username +
                  '&password=' +
                  this.signInInfoObj.password +
                  '&captchaId=' +
                  this.signInInfoObj.captchaId +
                  '&code=' +
                  this.signInInfoObj.code +
                  '&time=' +
                  this.signInInfoObj.time,
                popularizeCode: this.popularizeCode,
              }
              data.l = this.$Base64.encode(data.l)
              let res = await apiLoginz(data).catch()
              console.log(res, 'res是什么')
              if (res.code == 0) {
                res.data.n = this.$Base64.decode(res.data.n)
                console.log(res.data.n)
                let newRes = res.data.n.split('&')
                var obj = {}
                newRes.forEach((v) => {
                  var arr = v.split('=') // 等号分割
                  obj[arr[0]] = arr[1] // 第一项arr[0]做为属性名 第二项arr[1]做为属性值
                })
                console.log(obj, 'obj')
                //正常登录
                if (obj.loginType == 1) {
                  this.dialogVisible = true
                  this.baseForm.phone = obj.phone
                  this.baseForm.username = this.signInInfoObj.username
                  this.baseForm.code = ''
                } else if (obj.loginType == 2) {
                  this.dialogState = true
                } else {
                  this.$printLog(obj, 'Login')
                  const resObj = Object.assign(resLoginObj, obj)
                  await this.setLoginData(resObj).catch()
                  if (this.urlString && store.getters['user/token']) {
                    console.log(this.urlString, 55)
                    const { rules } = await store.dispatch('user/getUserInfo')
                    // 根据路由模式获取路由并根据权限过滤
                    await store.dispatch('routes/setRoutes', rules)
                    //获取余额信息
                    await store.dispatch('user/getBalanceData')
                    //获取消息列表
                    await store.dispatch('user/getMsgData')
                    let pathUrl = this.urlString.substring(this.urlString.lastIndexOf('=') + 1, this.urlString.length)
                    console.log(pathUrl, 'pathUrl')
                    await this.$router.push({ path: '/business', query: { url: pathUrl } })
                  } else {
                    //如果 login后 存在(充值)重定向地址，则登陆后直接跳转重定向地址
                    if (this.$route.query.toHomeFlag === 'false') {
                      this.redirect = this.$route.query.redirect
                    }
                    // 登陆后跳转到  优惠券  页面
                    if (this.$route.query.target) {
                      this.redirect = `/${this.$route.query.target}`
                    }
                    await this.$router.push(this.handleRoute())
                  }
                }
              } else if (res.code == 599998) {
                this.waringDescState = true
                this.waringDesc = res.msg
              }
            } finally {
              this.confirmBtnLoading = false
            }
          } else {
            return false
          }
        })
      },
      handleCloseEvent(value) {
        this.dialogState = false
        this.waringDescState = true
        this.waringDesc = value
      },
      sendData() {
        this.innerVisible = true
        this.showVerity = true
      },
      async success() {
        this.innerVisible = false
        this.showVerity = false
        let res = await apiSecureSms({ username: this.baseForm.username }).catch()
        if (res.code == 0) {
          let TIME_COUNT = 60
          if (!this.timer) {
            this.count = TIME_COUNT
            this.isGrey = true
            this.telState = 2
            this.dis = true
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--
              } else {
                this.dis = false
                this.isGrey = false
                this.telState = 3
                clearInterval(this.timer)
                this.timer = null
              }
            }, 1000)
          }
        }
      },
      handleSubmitForm() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let result = { username: this.baseForm.username, code: this.baseForm.code }
            let res = await apiVerifyLogin(result).finally(() => (this.confirmBtnLoading = false))
            if (res.code === 0) {
              this.dialogVisible = false
              this.$printLog(res, 'Login')
              const resObj = Object.assign(resLoginObj, res.data)
              await this.setLoginData(resObj).catch()
              await this.$router.push(this.handleRoute())
            }
          } else {
            return false
          }
        })
      },
      handlePassword() {
        this.passwordType === 'password' ? (this.passwordType = '') : (this.passwordType = 'password')
        this.$nextTick(() => {
          this.$refs.password.focus()
        })
      },
      handleRegister() {
        this.$baseEventBus.$emit('register', true)
        // this.$message({
        //   message: '当前版本为内测版本，如有需要请联系商务开通内测账号。',
        //   type: 'warning',
        // })
      },
      forGetPwd() {
        this.$router.push('/resetPwd')
      },
    },
  }
</script>
<style lang="scss" scoped>
  .sign {
    margin-top: 42px;
    margin-bottom: 38px;
    width: 360px;
    height: 100px;

    .svg-prefix {
      margin-left: 5px;
      margin-bottom: 4px;
    }

    .svg-suffix {
      margin-right: 5px;
      margin-bottom: 4px;
      cursor: pointer;
    }

    .login-btn {
      font-size: 18px;
      color: #0abbcd;
      text-align: center;
      cursor: pointer;
    }

    ::v-deep {
      .el-input__inner {
        font-size: 14px;
        background-color: #fff;
        color: black;
        user-select: text;
        border: 1px solid #d9d9d9;
      }

      .el-input__prefix {
        top: 5px;
      }

      .el-input__suffix {
        top: 5px;
      }
    }
  }

  .login_btn {
    margin-top: 20px;
    .coordinate {
      position: absolute;
      z-index: 9999 !important;
      width: auto;
      top: -43%;
    }

    .button {
      text-align: center;
      font-weight: bold;
      line-height: 22px;
      margin: 0 0 20px 0;
      width: 360px;
      height: 40px;
      background: #4680ff;
      border-color: #4680ff;
      box-shadow: none;
      color: #fff;
    }

    .button:hover {
      background: #1b5deb;
      border-color: #1b5deb;
    }
  }

  .replace {
    font-size: 14px;
    margin-bottom: 20px;
    color: #999999;
  }

  .operation {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    // margin-bottom: 32px;
    font-weight: 400;
    color: #999999;

    span {
      cursor: pointer;
    }

    span:last-child {
      color: #336ffe;
    }
  }

  .otherLogin {
    font-size: 14px;
    color: #999999;

    span {
      margin-right: 14px;
    }

    .icon-click {
      margin-right: 14px;
      font-size: 24px;
      vertical-align: middle;
      cursor: pointer;
    }
  }

  .confirmCheck {
    font-size: 12px;
    font-weight: 400;
    color: #555555;
    margin-top: 21px;
    margin-bottom: 13px;

    span:last-child {
      color: #336ffe;
    }
  }

  .confirmCheck {
    font-size: 12px;
    font-weight: 400;
    color: #555555;
    margin-top: 21px;
    margin-bottom: 13px;
  }

  .clause {
    font-size: 14px;
    font-weight: 400;
    color: #409eff;
  }

  .toclause {
    font-size: 14px;
    font-weight: 400;
    color: #409eff;
    cursor: pointer;
  }

  .hiddenIpt {
    width: 1px;
    height: 1px;
    opacity: 0;
    /*opacity: 0;*/
  }
</style>
