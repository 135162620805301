<template>
  <div class="details-center_bg" v-za-loading="isLoading">
    <div class="container">
      <div class="title">{{ title }}</div>
      <za-query-form>
        <za-query-form-left-panel :span="24">
          <el-form :inline="true" :model="pageObj" @submit.native.prevent>
            <!-- <el-form-item :label="translateTitle('details.transactionNum') + '：'">
              <el-select v-model="pageObj.tradeAccount">
                <el-option
                  v-for="item in transactionNumOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item :label="translateTitle('details.transactionType') + '：'">
              <el-select v-model="pageObj.transactionType">
                <el-option v-for="item in typeOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="translateTitle('details.transactionOrder') + '：'" label-width="100px">
              <el-input
                v-model="pageObj.orderNumber"
                :placeholder="translateTitle('placeTxt') + translateTitle('details.transactionOrderNum')"
              />
            </el-form-item>
            <el-form-item :label="translateTitle('details.payMode') + '：'" label-width="100px">
              <el-select v-model="pageObj.payType">
                <el-option v-for="item in payOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item :label="translateTitle('details.resourceId') + '：'" label-width="100px">
              <el-input
                v-model="pageObj.resourceId"
                :placeholder="translateTitle('placeTxt') + translateTitle('details.resourceId')"
              />
            </el-form-item> -->
          </el-form>
        </za-query-form-left-panel>
        <za-query-form-left-panel :span="24">
          <el-form :inline="true" :model="pageObj" @submit.native.prevent>
            <el-form-item :label="translateTitle('details.transactionTime') + '：'">
              <el-date-picker
                v-model="pickTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="datePickEnd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item>
              <za-button :icon="buttonIconObj.SEARCH" @click="handleQuery">{{ translateTitle('btn.query') }}</za-button>
              <za-button :type="buttonTypeObj.GREY" @click="handleResetQuery">
                {{ translateTitle('btn.reset') }}
              </za-button>
            </el-form-item>
          </el-form>
        </za-query-form-left-panel>
      </za-query-form>
    </div>
    <div class="container_top">
      <za-grid
        :checkList="checkList"
        :checkboxList="checkboxList"
        :tableTitle="columObj.columnData"
        @getList="getList"
      />
      <occ-table
        :tableData="tableData"
        :columObj="columObj"
        :isOp="columObj.isOp"
        @handleSelectionChange="handleSelectionChange"
      ></occ-table>
      <pagination :limit.sync="pageObj.pageSize" :page.sync="pageObj.pageNo" :total="total" @pagination="getList" />
    </div>
  </div>
</template>
<script>
import { billList } from '@/api/moneyCenter/expenseManagement/details'
import zaGrid from '@/za/components/zaGrid'
import { mapGetters } from 'vuex'

export default {
  name: 'Details',
  components: { zaGrid },
  data() {
    return {
      title: '',
      total: 0,
      pageObj: {
        //分页对象
        pageNo: 1,
        pageSize: 10,
        tradeAccount: '',
        orderNumber: '',
        tradeNo: '',
        transactionType: '',
        payType: '',
        payStartAt: '',
        payEndAt: '',
        // resourceId: '',
      },
      options: [],
      pickTime: [],
      tableData: [],
      columObj: {
        // 控制选择框
        selection: false,
        isOp: false,
        columnData: [
          {
            text: true,
            prop: 'orderNumber',
            label: '交易流水',
            showOverFlow: false,
            width: '220',
            flag: true,
            align: 'center',
          },
          {
            text: true,
            prop: 'resourceId',
            label: '资源ID',
            showOverFlow: false,
            width: '220',
            flag: true,
            align: 'center',
          },
          {
            text: true,
            prop: 'resourceName',
            label: '资源名称',
            showOverFlow: false,
            width: '220',
            flag: true,
            align: 'center',
          },
          {
            ownDefined: true,
            prop: 'payType',
            label: '计费类型',
            flag: true,
            width: '',
            align: 'center',
            ownDefinedReturn: (row) => {
              let result = this.payTypeOptions.filter((item) => {
                return item.id == row.payType
              })
              return result[0].name
            },
          },
          // {
          //     text: true,
          //     prop: "orderNumber",
          //     label: "资源订单号",
          //     showOverFlow:false,
          //     width: "220",
          //     flag: true,
          //     align: "center",
          // },
          {
            ownDefined: true,
            prop: 'transactionType',
            label: '交易类型',
            flag: true,
            width: '',
            align: 'center',
            ownDefinedReturn: (row) => {
              let result = this.typeOptionsShow.filter((item) => {
                return item.id == row.transactionType
              })
              // 处理返回的  transactionType  2新购3续费6后付款  都展示为消费
              if (result[0].id === '2' || result[0].id === '3' || result[0].id === '6') {
                result = [
                  {
                    id: '2',
                    name: '消费',
                  },
                ]
              }
              return result && result.length > 0 && result[0].name ? result[0].name : '-'
            },
          },
          {
            ownDefined: true,
            prop: 'payMethod',
            label: '交易方式',
            flag: true,
            width: '',
            align: 'center',
            ownDefinedReturn: (row) => {
              let result = this.payOptions.filter((item) => {
                return item.id == row.payMethod
              })
              return result && result.length > 0 && result[0].name ? result[0].name : '-'
            },
          },
          {
            ownDefined: true,
            prop: 'amount',
            label: '交易金额',
            flag: true,
            width: '',
            align: 'center',
            ownDefinedReturn: (row) => {
              return '￥' + row.amount
            },
          },
            {
              ownDefined: true,
              prop: 'balance',
              label: '账户余额',
              flag: true,
              width: '',
              align: 'center',
              ownDefinedReturn: (row) => {
                return '￥' + row.balance
              },
            },
          // {
          //     text: true,
          //     prop: "tradeNo",
          //     label: "交易流水",
          //     flag: true,
          //     align: "center",
          //     showOverFlow:false,
          //     width: "220",
          // },
          {
            text: true,
            prop: 'payAt',
            label: '交易时间',
            flag: true,
            width: '',
            align: 'center',
          },
          {
            text: true,
            prop: 'tradeAccount',
            label: '交易账号',
            flag: true,
            width: '',
            align: 'center',
          },
          {
            text: true,
            prop: 'desc',
            label: '备注',
            flag: true,
            width: '',
            align: 'center',
          },
        ],
      },
      checkList: [],
      checkboxList: [],
      transactionNumOptions: [
        {
          id: 'shenzhen',
          name: '深圳智安',
        },
        {
          id: 'nanjing',
          name: '南京智安',
        },
        {
          id: 'chengdu',
          name: '成都智安',
        },
      ],
      // 交易方式
      payOptions: [
        {
          id: 'wechat',
          name: '微信支付',
        },
        {
          id: 'alipay',
          name: '支付宝支付',
        },
        {
          id: 'balance',
          name: '余额支付',
        },
        {
          id: 'offline',
          name: '线下支付',
        },
        // {
        //     id:'wangyinpay',
        //     name:'网银支付'
        // },
        // {
        //     id:'xianxiapay',
        //     name:'线下汇款'
        // }
      ],
      // 交易类型 展示
      typeOptionsShow: [
        { id: '1', name: '充值' },
        { id: '2', name: '新购' },
        { id: '3', name: '续费' },
        { id: '4', name: '提现' },
        { id: '5', name: '退款' },
        { id: '6', name: '后付费' },
      ],
      // 计费类型
      payTypeOptions: [
        { id: '0', name: '-' },
        { id: '1', name: '预付费' },
        { id: '2', name: '后付费' },
        { id: '3', name: '预付费+后付费'},
      ],
      // 交易类型 查询
      typeOptions: [
        { id: '1', name: '充值' },
        { id: '2', name: '消费' },
        { id: '3', name: '提现' },
        { id: '4', name: '退款' },
      ],
    }
  },
  created() {
    this.title = this.$route.meta.title
    this.getList()
    this.getChecklist()
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },
  methods: {
    getList() {
      this.onSubmitData(billList, this.pageObj, null, false, false, (res) => {
        this.total = res.data.total
        this.tableData = res.data.list || []
        // 赋值交易账户
        this.tableData.forEach((item) => {
          item.tradeAccount = this.userInfo.username
        })
      })
    },
    handleQuery() {
      this.getList()
    },
    handleResetQuery() {
      this.pickTime = []
      this.resetListQuery(this.pageObj)
      this.getList()
    },
    datePickEnd(date) {
      if (date) {
        this.pageObj.payStartAt = this.$changeTimeStamp(date[0])
        this.pageObj.payEndAt = this.$changeTimeStamp(date[1]) + 3600 * 24 - 1
      }
    },
    //多选框变化
    handleSelectionChange() {},
  },
}
</script>
<style lang="scss" scoped>
@import '~@/za/styles/mixin.scss';

.container {
  height: 170px;
  background: #ffffff;
  border-radius: 2px;
  padding: 20px;
}
.container_top {
  margin-top: 20px;
  background: #ffffff;
  border-radius: 2px;
  padding: 20px;
}

.title {
  @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
  width: 70px;
  height: 20px;
  margin-bottom: 20px;
}
</style>