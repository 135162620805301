var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "za-loading",
          rawName: "v-za-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "reminder",
    },
    [
      _c("div", { staticClass: "title" }, [_vm._v("返利概况")]),
      _c("div", [_c("za-picker", { attrs: { timeList: _vm.timeList } })], 1),
      _c(
        "div",
        { staticClass: "card_mottom" },
        [
          _c(
            "el-row",
            [
              _c("el-col", { staticClass: "cardUl", attrs: { span: 6 } }, [
                _c("div", { staticClass: "title" }, [_vm._v("有效顶单数")]),
                _c("div", { staticClass: "operation" }, [
                  _c("div", [
                    _c("span", [_vm._v(_vm._s(_vm.waitPayOrderNumber))]),
                    _c("span", [_vm._v("个")]),
                  ]),
                ]),
              ]),
              _c("el-col", { staticClass: "cardUl", attrs: { span: 6 } }, [
                _c("div", { staticClass: "title" }, [_vm._v("有效订单金额")]),
                _c("div", { staticClass: "operation" }, [
                  _c("div", [
                    _c("span", [_vm._v(_vm._s(_vm.waitRenewResourceNumber))]),
                    _c("span", [_vm._v("次")]),
                  ]),
                ]),
              ]),
              _c("el-col", { staticClass: "cardUl", attrs: { span: 6 } }, [
                _c("div", { staticClass: "title" }, [_vm._v("带结算返利金额")]),
                _c("div", { staticClass: "operation" }, [
                  _c("div", [
                    _c("span", [_vm._v(_vm._s(_vm.amount))]),
                    _c("span", [_vm._v("个")]),
                  ]),
                ]),
              ]),
              _c("el-col", { staticClass: "cardUl", attrs: { span: 6 } }, [
                _c("div", { staticClass: "title" }, [_vm._v("用户总消费")]),
                _c("div", { staticClass: "operation" }, [
                  _c("div", [
                    _c("span", [_vm._v(_vm._s(_vm.amount))]),
                    _c("span", [_vm._v("个")]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }