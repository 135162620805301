/**
 * @description 获取token
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getCache(key, storage = 'sessionStorage') {
  if ('localStorage' === storage) {
    return localStorage.getItem(key)
  } else if ('sessionStorage' === storage) {
    return sessionStorage.getItem(key)
  } else {
    return localStorage.getItem(key)
  }
}

/**
 * @description 存储token
 * @param key
 * @param value
 * @param storage
 * @returns {void|*}
 */
export function setCache(key, value, storage = 'sessionStorage') {
  if ('localStorage' === storage) {
    return localStorage.setItem(key, value)
  } else if ('sessionStorage' === storage) {
    return sessionStorage.setItem(key, value)
  } else {
    return localStorage.setItem(key, value)
  }
}

/**
 * @description 移除token
 * @returns {void|Promise<void>}
 */
export function removeCache(key, storage = 'sessionStorage') {
  if ('localStorage' === storage) {
    return localStorage.removeItem(key)
  } else if ('sessionStorage' === storage) {
    return sessionStorage.clear()
  } else {
    return localStorage.removeItem(key)
  }
}
