var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.balance
      ? _c(
          "div",
          { staticClass: "ignore_timeIcon", on: { click: _vm.handleEnter } },
          [
            _c("za-svg-icon", {
              attrs: {
                "class-name": "svg-homeMore",
                "icon-class": "za-home-more",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "ignore_center", on: { click: _vm.officialWeb } },
      [
        _c("el-image", {
          staticClass: "pic-error-parent",
          attrs: { src: require("@/assets/home/home_logo.png") },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "ignore_toHome",
        on: {
          click: function ($event) {
            return _vm.$router.push("/")
          },
        },
      },
      [
        _c("span", [
          _vm._v(_vm._s(_vm.translateTitle("navTop.toHome") + "总览")),
        ]),
      ]
    ),
    _vm.drawer
      ? _c(
          "div",
          { staticClass: "leftDrawer" },
          [
            _c(
              "el-drawer",
              {
                attrs: { visible: _vm.drawer, direction: "ltr", size: "50%" },
                on: {
                  "update:visible": function ($event) {
                    _vm.drawer = $event
                  },
                },
              },
              [
                _c("div", { staticClass: "navDrawer" }, [
                  _c(
                    "div",
                    { staticClass: "navLeft" },
                    [
                      _c(
                        "el-menu",
                        {
                          staticClass: "el-menu-demo",
                          attrs: {
                            "default-active": _vm.activeIndex,
                            mode: "vertical",
                          },
                          on: { select: _vm.handleSelect },
                        },
                        _vm._l(_vm.menuLeftList, function (item, index) {
                          return _c(
                            "el-menu-item",
                            { key: index, attrs: { index: item.path } },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "navRight" }, [
                    _c("div", [
                      _c("div", [
                        _c("div", { staticClass: "prompt" }, [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                margin: "0 7px 0 16px",
                                "font-size": "14px",
                              },
                            },
                            [
                              _c("za-svg-icon", {
                                attrs: { "icon-class": "za-wanning" },
                              }),
                            ],
                            1
                          ),
                          _c("span", [
                            _vm._v(
                              " 温馨提示: 若点击无法跳转，刷新页面即可返回该资源 "
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#336ffe",
                                  cursor: "pointer",
                                },
                                on: { click: _vm.reloadPage },
                              },
                              [_vm._v("刷新页面")]
                            ),
                          ]),
                        ]),
                        _c(
                          "p",
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入搜索关键字",
                                "prefix-icon": "el-icon-search",
                              },
                              on: { input: _vm.searchRightList },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.record(_vm.drawerInput)
                                },
                              },
                              model: {
                                value: _vm.drawerInput,
                                callback: function ($$v) {
                                  _vm.drawerInput = $$v
                                },
                                expression: "drawerInput",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm.searchList.length
                        ? _c(
                            "div",
                            { staticClass: "navSearch" },
                            [
                              _c("span", { staticClass: "search" }, [
                                _vm._v("最近搜索 :"),
                              ]),
                              _vm._l(_vm.searchList, function (item, index) {
                                return _c(
                                  "span",
                                  {
                                    key: index,
                                    staticClass: "searchText",
                                    on: {
                                      click: function ($event) {
                                        return _vm.resordClick(item.name)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" " + _vm._s(item.name) + " "),
                                    _c("span", [_vm._v("|")]),
                                  ]
                                )
                              }),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "div",
                      { staticClass: "searchBtm" },
                      _vm._l(_vm.menuRightList, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "navBlock" },
                          [
                            _c(
                              "div",
                              { staticClass: "blockTitle" },
                              [
                                _c("za-svg-icon", {
                                  attrs: {
                                    "class-name": "svg-homeMore",
                                    "icon-class": item.icon,
                                  },
                                }),
                                _c("span", [_vm._v(_vm._s(item.title))]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "blockText" },
                              _vm._l(item.children, function (value, valindex) {
                                return _c(
                                  "p",
                                  {
                                    key: valindex,
                                    staticClass: "blockName",
                                    on: {
                                      click: function ($event) {
                                        return _vm.goRouter(value.path)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(value.name) + " ")]
                                )
                              }),
                              0
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }