<template>
  <div v-za-loading="isLoading">
    <div v-if="tableData.length">
      <occ-table :tableData="tableData" :columObj="columObj" :isOp="columObj.isOp" :style-height="'calc(100%-10px)'">
        <template #default="{ row }">
          <span class="blue_font" @click="handleChange(row)">修改</span>
        </template>
      </occ-table>
    </div>
    <div v-else>
      <div class="prompt">
        <el-button type="primary" @click="addInvoiceHead" :disabled="buttonFlag">添加发票信息</el-button>
      </div>
    </div>
    <div>
      <za-dialog :top-title="dialogType | dialogTitle(dialogTypeObj)" :visible.sync="dialogVisible" width="600px">
        <div>
          <div class="tipsInfo" v-if="dialogType === 2">
            <za-svg-icon icon-class="za-wanning"></za-svg-icon>
            <span>
              普票电子化通知：为积极响应国家发票无纸化政策，智安云云将于2023年10月1日起关闭增值税电子发票和增值税纸质发票申请入口，改为数电发票，请您确认重新确认发票信息无误，以免影响发票的后续使用。
            </span>
          </div>
          <el-form
            ref="form"
            :disabled="confirmBtnLoading"
            :model="baseForm"
            label-position="right"
            label-width="140px"
            :rules="rules"
          >
            <el-form-item :label="'抬头类型：'" prop="headType">
              <el-radio-group v-model="baseForm.headType" @change="changeRadio">
                <el-radio :label="headTypes.personal" :disabled="userInfo.accountType === accountTypes.entCer">
                  {{ headTypesName.personal }}
                </el-radio>
                <el-radio :label="headTypes.enterprise">{{ headTypesName.enterprise }}</el-radio>
                <el-radio :label="headTypes.organization">{{ headTypesName.organization }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item :label="'发票类型：'" prop="invoicesType">
              <el-radio-group v-model="baseForm.invoicesType" @change="changeInvoicesTypeRadio">
                <el-radio :label="invoicesType.digEleTicket">{{ invoiceTypeName.digEleTicket }}</el-radio>
                <el-radio
                  :label="invoicesType.digSpeTicket"
                  v-if="baseForm.headType === headTypes.enterprise"
                  :disabled="
                    userInfo.accountType === accountTypes.perCer || userInfo.accountType === accountTypes.notCer
                  "
                >
                  {{ invoiceTypeName.digSpeTicket }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item :label="'发票抬头：'" prop="head">
              <el-input
                v-model="baseForm.head"
                :placeholder="`${translateTitle('placeTxt')}`"
                :disabled="
                  baseForm.headType === headTypes.personal || baseForm.invoicesType === invoicesType.digSpeTicket
                "
                @keyup.native="trim('head')"
              />
            </el-form-item>
            <el-form-item
              :label="baseForm.headType === headTypes.enterprise ? '企业信用代码：' : '纳税人识别号：'"
              v-if="baseForm.headType !== headTypes.personal"
              prop="taxpayerNumber"
            >
              <el-input
                v-model="baseForm.taxpayerNumber"
                maxlength="20"
                :placeholder="baseForm.headType === headTypes.enterprise ? '请填写企业信用代码' : '请填写纳税人识别号'"
                @keyup.native="trim('taxpayerNumber')"
              />
            </el-form-item>
            <el-form-item
              :label="'基本开户银行：'"
              v-if="baseForm.headType === headTypes.enterprise"
              prop="bankName"
              :rules="baseForm.invoicesType === invoicesType.digSpeTicket ? rules.bankName : rules2.bankName"
            >
              <el-input
                v-model="baseForm.bankName"
                :minlength="4"
                :maxlength="50"
                placeholder="请输入真实有效的开户银行信息"
                @keyup.native="trim('bankName')"
              />
            </el-form-item>
            <el-form-item
              :label="'基本开户账号：'"
              v-if="baseForm.headType === headTypes.enterprise"
              prop="bankAccount"
              :rules="baseForm.invoicesType === invoicesType.digSpeTicket ? rules.bankAccount : rules2.bankAccount"
            >
              <el-input
                v-model="baseForm.bankAccount"
                placeholder="请填写许可证上的开户账号"
                @input="limitInput($event, 'bankAccount')"
                @keyup.native="trim('bankAccount')"
              />
            </el-form-item>
            <el-form-item
              :label="'企业注册地址：'"
              v-if="baseForm.headType === headTypes.enterprise"
              prop="logonAddress"
              :rules="baseForm.invoicesType === invoicesType.digSpeTicket ? rules.logonAddress : rules2.logonAddress"
            >
              <el-input
                v-model="baseForm.logonAddress"
                :minlength="4"
                :maxlength="50"
                placeholder="请输入企业注册地址"
                @keyup.native="trim('logonAddress')"
              />
            </el-form-item>
            <el-form-item
              :label="'企业注册电话：'"
              v-if="baseForm.headType === headTypes.enterprise"
              prop="logonPhone"
              :rules="baseForm.invoicesType === invoicesType.digSpeTicket ? rules.logonPhone : rules2.logonPhone"
            >
              <el-input
                v-model="baseForm.logonPhone"
                placeholder="请填写企业有效联系方式"
                maxlength="20"
                @input="limitInput($event, 'logonPhone')"
                @keyup.native="trim('logonPhone')"
              />
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" style="margin-top: 10px">
          <za-button :disabled="confirmBtnLoading" :type="buttonTypeObj.GREY" @click="dialogVisible = false">
            {{ translateTitle('btn.cancel') }}
          </za-button>
          <za-button :loading="confirmBtnLoading" :type="buttonTypeObj.BLUE" @click="handleSubmitForm">
            {{ translateTitle('btn.submit') }}
          </za-button>
        </div>
      </za-dialog>
    </div>
  </div>
</template>
<script>
  import { apiFinanceHead } from '@/api/moneyCenter/invoiceManagement/askfor'
  import { isNumOrEng } from '@/utils/validate'
  import { apiUpdateAddress } from '@/api/moneyCenter/invoiceManagement/address'
  import { apiCertificationName } from '@/api/moneyCenter/expenseManagement/moneyExtract'
  import { accountTypes, headTypes, headTypesName, invoicesType, invoiceTypeName } from '@/utils/enums'
  import { mapGetters } from 'vuex'

  export default {
    data() {
      const taxpayer = (rule, value, callback) => {
        let reg = /[a-z]/g
        if (!value) callback(new Error('请输入15位, 17位, 18位或20位真实有效的纳税人识别号'))
        else if (value.length !== 15 && value.length !== 17 && value.length !== 18 && value.length !== 20)
          callback(new Error('请输入15位, 17位, 18位或20位真实有效的纳税人识别号'))
        else if (isNumOrEng(value)) callback(new Error('请输入数字或英文'))
        else if (reg.test(value)) callback(new Error('请输入大写字母'))
        else callback()
      }
      const bankName = (rule, value, callback) => {
        if (value.length < 4 || value.length > 50) callback(new Error('请输入真实有效的开户银行信息'))
        else if (value === '') callback(new Error('请输入真实有效的开户银行信息'))
        else callback()
      }
      const addressRule = (rule, value, callback) => {
        if (value.length < 4 || value.length > 50) callback(new Error('请输入企业注册地址'))
        else if (value === '') callback(new Error('请输入企业注册地址'))
        else callback()
      }
      const bankUser = (rule, value, callback) => {
        if (value.length < 4 || value.length > 50) callback(new Error('请输入真实有效的开户账号信息'))
        else if (value === '') callback(new Error('请输入真实有效的开户账号信息'))
        else callback()
      }
      const comPhone = (rule, value, callback) => {
        if (value.length < 1 || value.length > 20) callback(new Error('请输入真实有效的企业注册电话'))
        else if (value === '') callback(new Error('请输入真实有效的企业注册电话'))
        else callback()
      }
      const bankNameEmpty = (rule, value, callback) => {
        if (value === '') callback()
        else if (value.length > 50 || value.length < 4) callback(new Error('请输入4-50字符'))
        else callback()
      }
      const bankUserEmpty = (rule, value, callback) => {
        if (value === '') callback()
        else if (value.length < 4 || value.length > 50) callback(new Error('请输入4-50位数字'))
        else callback()
      }
      const comPhoneEmpty = (rule, value, callback) => {
        if (value === '') callback()
        else if (value.length < 1 || value.length > 20) callback(new Error('请输入1-20位数字'))
        else callback()
      }
      return {
        buttonFlag: false,
        message: '请输入基本开户账号',
        baseForm: {
          head: '',
          invoicesTypeName: '',
          invoicesType: '',
          headType: '',
          taxpayerNumber: '',
          bankName: '',
          bankAccount: '',
          logonAddress: '',
          logonPhone: '',
        },
        tableData: [],
        rules: {
          headType: [{ required: true, message: '请选择抬头类型', trigger: 'change' }],
          invoicesType: [{ required: true, message: '请选择发票类型', trigger: 'change' }],
          head: [{ required: true, message: '请输入发票抬头', trigger: 'blur' }],
          bankName: [{ required: true, validator: bankName, trigger: 'blur' }],
          bankAccount: [{ required: true, validator: bankUser, trigger: 'blur' }],
          logonAddress: [{ required: true, validator: addressRule, trigger: 'blur' }],
          logonPhone: [{ required: true, validator: comPhone, trigger: 'blur' }],
          taxpayerNumber: [{ required: true, validator: taxpayer, trigger: 'blur' }],
        },
        rules2: {
          bankName: [{ required: false, validator: bankNameEmpty, trigger: 'blur' }],
          bankAccount: [{ required: false, validator: bankUserEmpty, trigger: 'blur' }],
          logonAddress: [{ required: false, validator: bankNameEmpty, trigger: 'blur' }],
          logonPhone: [{ required: false, validator: comPhoneEmpty, trigger: 'blur' }],
        },
        columObj: {
          // 控制选择框
          selection: false,
          isOp: true,
          width: '300px',
          columnData: [
            {
              text: true,
              prop: 'head',
              label: '发票抬头',
              width: '',
              flag: true,
              align: 'center',
            },
            {
              ownInnerHtml: true,
              prop: 'headType',
              label: '抬头类型',
              flag: true,
              width: '',
              align: 'center',
              htmlTest: (row, $index) => {
                return row.headType === headTypes.personal
                  ? headTypesName.personal
                  : row.headType === headTypes.organization
                  ? headTypesName.organization
                  : row.headType === headTypes.enterprise
                  ? headTypesName.enterprise
                  : ''
              },
            },
            {
              text: true,
              prop: 'invoicesTypeName',
              label: '发票类型',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              text: true,
              prop: 'taxpayerNumber',
              label: '纳税人识别号',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              text: true,
              prop: 'bankName',
              label: '开户银行',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              text: true,
              prop: 'bankAccount',
              label: '开户账号',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              text: true,
              prop: 'logonAddress',
              label: '企业注册地址',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              text: true,
              prop: 'logonPhone',
              label: '企业注册电话',
              flag: true,
              width: '',
              align: 'center',
            },
          ],
        },
        certificationName: '',
        defaultRow: {},
      }
    },
    created() {
      this.dialogTypeObj.ADD.text = '新增发票信息'
      this.dialogTypeObj.EDIT.text = '修改发票信息'
      this.getList()
      this.userInfo.accountType === accountTypes.notCer ? (this.buttonFlag = true) : (this.buttonFlag = false)
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      invoiceTypeName() {
        return invoiceTypeName
      },
      headTypesName() {
        return headTypesName
      },
      accountTypes() {
        return accountTypes
      },
      invoicesType() {
        return invoicesType
      },
      headTypes() {
        return headTypes
      },
    },
    methods: {
      /**
       * @description 获取发票抬头信息和实名认证主体信息
       * */
      async getList() {
        this.onSubmitData(apiFinanceHead, null, null, false, false, (res) => {
          this.tableData = []
          this.defaultRow = JSON.parse(JSON.stringify(res.data))
          if (res.data.id === 0) {
            this.tableData = []
          } else {
            this.tableData.push(res.data)
          }
        })
        const resultName = await apiCertificationName().catch()
        this.certificationName = resultName.data.name || ''
      },

      limitInput(value, name) {
        let val = '' + value
        val =
          val
            .replace(/[^\d^\.]+/g, '')
            .replace(/^0+(\d)/, '$1')
            .replace(/^\./, '0.')
            .match(/^\d*(\.?\d{0,2})/g)[0] || ''
        this.baseForm[name] = val
      },

      trim(val) {
        this.baseForm[val] = this.baseForm[val].replace(/\s*/g, '')
      },

      /**
       * @description 重置表单部分数据
       */
      resetForm() {
        this.baseForm.head = ''
        this.baseForm.taxpayerNumber = ''
        this.baseForm.bankName = ''
        this.baseForm.bankAccount = ''
        this.baseForm.logonAddress = ''
        this.baseForm.logonPhone = ''
      },

      /**
       * @description 抬头类型更改
       * @param val {Number} 抬头类型绑定的枚举值
       */
      changeRadio(val) {
        // 修改
        if (this.defaultRow.id !== 0) {
          // 默认选中数电普票
          this.baseForm.invoicesType = invoicesType.digEleTicket
          // 发票抬头与数据库数据相同，展示数据；否则清空
          if (this.defaultRow.headType === val) {
            this.baseForm = Object.assign({}, this.defaultRow)
          } else {
            this.resetForm()
          }
        }
        // 新增
        else {
          this.baseForm.invoicesType = invoicesType.digEleTicket
          this.resetForm()
          this.baseForm.head = this.certificationName
        }
        // 抬头类型为个人，则默认为实名主体
        if (val === headTypes.personal) this.baseForm.head = this.certificationName
        this.$nextTick(() => {
          this.$refs['form'].clearValidate()
        })
      },

      /**
       * @description 发票类型更改(数电专票和数电普票切换)
       * @param val {Number} 发票类型绑定的枚举值
       */
      changeInvoicesTypeRadio(val) {
        // 修改
        if (this.defaultRow.id !== 0) {
          // 发票类型与当前用户一致，则展示原数据；否则置为空
          if (this.defaultRow.invoicesType === val && this.defaultRow.headType === this.baseForm.headType) {
            this.baseForm.head = this.defaultRow.head
            this.baseForm.taxpayerNumber = this.defaultRow.taxpayerNumber
            this.baseForm.logonAddress = this.defaultRow.logonAddress
            this.baseForm.logonPhone = this.defaultRow.logonPhone
            this.baseForm.bankAccount = this.defaultRow.bankAccount
            this.baseForm.bankName = this.defaultRow.bankName
          } else {
            this.resetForm()
          }
        }
        // 新增
        else {
          this.resetForm()
          this.baseForm.head = this.certificationName
        }
        // 发票类型为数电专票，则抬头默认为实名主体
        if (val === invoicesType.digSpeTicket) this.baseForm.head = this.certificationName
        this.$nextTick(() => {
          this.$refs['form'].clearValidate()
        })
      },

      /**
       * @description 新增抬头数据绑定
       * @returns {Promise<void>}
       */
      async addInvoiceHead() {
        if (this.userInfo.accountType === accountTypes.perCer) {
          this.baseForm.headType = headTypes.personal
        } else if (this.userInfo.accountType === accountTypes.entCer) {
          this.baseForm.headType = headTypes.enterprise
        }
        // 默认选中数电普票，默认抬头为实名主体
        this.baseForm.invoicesType = invoicesType.digEleTicket
        this.baseForm.head = this.certificationName
        this.dialogType = this.dialogTypeObj.ADD.type
        this.$nextTick(() => {
          this.dialogVisible = true
        })
      },

      /**
       * @description  修改数据回显
       * @param row
       * @returns {Promise<void>}
       */
      async handleChange(row) {
        // 深拷贝当前用户发票抬头数据
        this.baseForm = Object.assign({}, this.defaultRow)
        // 对老用户数据进行强制处理
        if (row.invoicesType === invoicesType.vatGenTicket) this.baseForm.invoicesType = invoicesType.digEleTicket
        else if (row.invoicesType === invoicesType.vatSpeTicket) this.baseForm.invoicesType = invoicesType.digSpeTicket
        this.dialogType = this.dialogTypeObj.EDIT.type
        this.$nextTick(() => {
          this.dialogVisible = true
        })
      },

      /**
       * @description （新增、修改）抬头信息
       * @returns {Promise<void>}
       */
      async handleSubmitForm() {
        if (this.dialogType === this.dialogTypeObj.EDIT.type && !this.isCanSubmit(this.baseForm)) {
          this.$msgWarning('您还未对数据进行修改')
          return
        }
        await this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const msg = this.dialogType === 1 ? '添加成功' : '修改成功'
            this.confirmBtnLoading = true
            const res = await apiUpdateAddress(this.baseForm).finally(() => (this.confirmBtnLoading = false))
            if (res.code === 0) {
              this.$msgSuccess(msg)
              await this.getList()
              this.dialogVisible = false
            }
          } else {
            return false
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .tipsInfo {
    height: 80px;
    background: #fffbe6;
    border-radius: 2px;
    border: 1px solid #ffe58f;
    padding: 9px;
    margin-bottom: 30px;
  }
</style>