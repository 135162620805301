var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar"),
      _vm.url
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "za-loading",
                  rawName: "v-za-loading",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
            },
            [
              _c("iframe", {
                ref: "iframe",
                staticClass: "iframe",
                attrs: {
                  src: _vm.url,
                  width: "100%",
                  frameborder: "0",
                  id: "iframe",
                },
                on: { load: _vm.handleIframeLoad },
              }),
            ]
          )
        : _vm._e(),
      _c(
        "za-dialog",
        {
          attrs: {
            "top-title": "余额不足：",
            visible: _vm.isWarnDialog,
            width: "800px",
            showClose: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isWarnDialog = $event
            },
          },
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "prompt", staticStyle: { height: "90px" } },
              [
                _c(
                  "span",
                  {
                    staticStyle: { color: "#faad13", "margin-bottom": "15px" },
                  },
                  [
                    _c("za-svg-icon", {
                      staticStyle: {
                        "font-size": "16px",
                        "margin-right": "6px",
                      },
                      attrs: { "icon-class": "za-wanning" },
                    }),
                    _vm._v(
                      " " + _vm._s(_vm.translateTitle("topUp.reminder")) + ": "
                    ),
                  ],
                  1
                ),
                _c("span", { staticStyle: { color: "#555555" } }, [
                  _vm._v(
                    " 您当前账户可用余额小于云产品的起购金额100元，部分服务将访问受限，如需使用相关服务请保证账户余额充足再进行购买。 "
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#3370ff",
                        display: "inline",
                        cursor: "pointer",
                      },
                      on: { click: _vm.reCharge },
                    },
                    [_vm._v("立即充值")]
                  ),
                ]),
              ]
            ),
          ]),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "za-button",
                {
                  attrs: { type: _vm.buttonTypeObj.BLUE },
                  on: { click: _vm.closeDialog },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "za-dialog",
        {
          staticClass: "orderCreate",
          attrs: {
            visible: _vm.createDefeatDialog,
            width: "800px",
            showClose: true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.createDefeatDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "create",
              staticStyle: { height: "100px", display: "flex" },
            },
            [
              _c("div", [
                _c(
                  "span",
                  { staticStyle: { color: "#ff3b30", "margin-top": "7px" } },
                  [
                    _c("za-svg-icon", {
                      staticStyle: {
                        "font-size": "22px",
                        "margin-right": "10px",
                      },
                      attrs: { "icon-class": "za-error" },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      color: "#555555",
                      "font-size": "16px",
                      "font-weight": "500",
                    },
                  },
                  [_vm._v("订单创建失败")]
                ),
                _c("span", { staticStyle: { color: "#777676" } }, [
                  _vm._v(
                    "当前订单中包含后付费资源，资源开出后将按照实际使用情况进行扣费。"
                  ),
                ]),
                _c("span", { staticStyle: { color: "#777676" } }, [
                  _vm._v(
                    " 由于目前你的账户余额不足，暂时无法使用后付费服务，建议您及时充值，充值完成后可以重新选购产品。 "
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#3370ff",
                        display: "inline",
                        cursor: "pointer",
                        "text-decoration": "underline",
                      },
                      on: { click: _vm.reCharge },
                    },
                    [_vm._v(" 去充值 ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }