module.exports = {
  getLoadingConfig: {
    bg: '',
    gif: 'el-icon-loading',
    tips: '正在获取数据...',
  },
  sendLoadingConfig: {
    bg: 'rgba(0, 0, 0, 0.3)',
    gif: 'el-icon-loading',
    tips: '数据发送中...',
  },
}
