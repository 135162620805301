<template>
  <div class="invoiceHeader" v-za-loading="isLoading">
    <div class="main">
      <ul>
        <li>
          <div class="title">可开票金额</div>
          <div class="money">
            <span>{{ amount }}</span>
            <span>元</span>
          </div>
          <div>
            <el-button type="primary" @click="handleQuery" :disabled="buttonFlag">
              {{ translateTitle('btn.applyFor') }}
            </el-button>
          </div>
        </li>
        <li>
          <div class="title">发票抬头信息</div>
          <div class="text">
            <span>抬头类型：</span>
            <span>
              {{
                headType === headTypes.personal
                  ? '个人'
                  : headType === headTypes.organization
                  ? '组织'
                  : headType === headTypes.enterprise
                  ? '企业'
                  : '暂无'
              }}
            </span>
          </div>
          <div class="text">
            <span>发票类型：</span>
            <span>{{ invoicesTypeName }}</span>
          </div>
          <div>
            <span @click="toAddress('1')" class="btn">管理发票信息</span>
          </div>
        </li>
        <!--        <li>-->
        <!--          <div class="title">默认寄送地址</div>-->
        <!--          <div class="text">-->
        <!--            <span>收件人：</span>-->
        <!--            <span>{{ consignee || '暂无' }}</span>-->
        <!--          </div>-->
        <!--          <div class="text">-->
        <!--            <span>收件地址：</span>-->
        <!--            <span>{{ address || '暂无' }}</span>-->
        <!--          </div>-->
        <!--          <div>-->
        <!--            <span @click="toAddress('2')" class="btn">管理收件地址</span>-->
        <!--          </div>-->
        <!--        </li>-->
      </ul>
    </div>
  </div>
</template>
<script>
  import { apiFinanceAmount, apiFinanceHead } from '@/api/moneyCenter/invoiceManagement/askfor'
  import routeAddress from '@/utils/routeAdress'
  import { accountTypes, headTypes } from '@/utils/enums'
  import { mapGetters } from 'vuex'
  import eventBusEvent from '@/utils/eventBusEvent'

  export default {
    data() {
      return {
        headType: '',
        invoicesType: '',
        amount: '',
        invoicesTypeName: '',
        buttonFlag: false,
        // address: '',
        // consignee: '',
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      headTypes() {
        return headTypes
      },
    },
    created() {
      this.userInfo.accountType === accountTypes.notCer ? (this.buttonFlag = true) : (this.buttonFlag = false)
      // 撤销开票后更新可开票金额
      this.$baseEventBus.$on(eventBusEvent.UpdateBillAmount, this.getList)
    },
    mounted() {
      this.getList()
    },
    methods: {
      getList() {
        this.onSubmitData(apiFinanceHead, null, null, false, false, (res) => {
          this.invoicesTypeName = res.data.invoicesTypeName
          this.headType = res.data.headType
        })
        this.onSubmitData(apiFinanceAmount, null, null, false, false, (res) => {
          this.amount = res.data.amount
        })
        // this.onSubmitData(apiFinanceAddress, null, null, false, false, (res) => {
        // let province = ''
        // let city = ''
        // let area = ''
        // if (res.data.province) {
        // province = res.data.province
        // } else {
        // province = ''
        // }
        // if (res.data.city) {
        // city = res.data.city
        // } else {
        // city = ''
        // }
        // if (res.data.area) {
        // area = res.data.area
        // } else {
        // area = ''
        // }
        // this.consignee = res.data.consignee
        // this.address = province + ' ' + city + ' ' + area + ' ' + res.data.address
        // })
      },
      handleQuery() {
        this.$router.push(routeAddress.drawAbill)
      },
      toAddress(val) {
        this.$router.push({ path: routeAddress.address, query: { type: val } })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .invoiceHeader {
    .main {
      ul {
        padding: 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 0;
        list-style-type: none;

        li {
          padding: 20px;
          margin-right: 10px;
          height: 154px;
          background: #f4f6fa;
          border-radius: 2px;
          border: 1px solid #eef1fc;

          .title {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
          }

          .text {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;
            margin-top: 14px;
          }

          .money {
            font-size: 30px;
            font-family: DINAlternate-Bold, DINAlternate;
            font-weight: bold;
            color: #000000;
            margin-top: 14px;
            margin-bottom: 20px;

            span:last-child {
              font-size: 15px;
              font-family: DINAlternate-Bold, DINAlternate;
              font-weight: bold;
              color: #000000;
            }
          }

          .btn {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #3370ff;
            margin-top: 12px;
            display: block;
            cursor: pointer;
          }
        }
      }
    }
  }

  ::v-deep {
    .button {
      margin-left: 0;
      height: 32px;
      background: #3370ff;
      border-radius: 2px;
    }
  }
</style>