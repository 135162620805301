var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-cascader", {
        key: _vm.casKey,
        ref: "gdCas",
        staticClass: "cascader",
        attrs: {
          clearable: _vm.clearable,
          disabled: _vm.disabled,
          filterable: "",
          options: _vm.regionTree,
          placeholder: "请选择位置",
          props: {
            expandTrigger: "hover",
            value: "a",
            label: "n",
          },
        },
        on: { change: _vm.handleChange },
        model: {
          value: _vm.selectCas,
          callback: function ($$v) {
            _vm.selectCas = $$v
          },
          expression: "selectCas",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }