<template>
  <div class="allUpload">
    <div class="clearfix">
      <div class="wrap">
        <draggable :list="_value" v-bind="dragOptions">
          <div
            v-for="(item, index) in _value"
            :key="item.uid"
            :style="{ width: _width, height: _height }"
            class="left middleCenter"
          >
            <img :height="_height" :src="item.previewUrl" :width="_width" alt="" />
            <label v-if="isShowIcon(item.status)" class="success-bg">
              <i class="success-icon el-icon-check" />
            </label>
            <div class="content-wrap">
              <div :style="{ lineHeight: _lineHeight }" class="content middleCenter">
                <i class="el-icon-zoom-in" @click="handlePreviewImage(item.previewUrl)" />
                <i v-if="isDelete" class="el-icon-delete" @click="handleDelImg(item, index)" />
              </div>
            </div>
          </div>
          <div slot="footer" style="float: left">
            <el-upload
              ref="imageUpload"
              :auto-upload="false"
              :class="{ hide: hideUploadEdit }"
              :disabled="_confirmBtnLoading || disable"
              :file-list="fileList"
              :limit="limit"
              :multiple="true"
              :on-change="onChange"
              :on-exceed="onExceed"
              :show-file-list="false"
              :style="{
                '--pictureWidth': _width,
                '--pictureHeight': _height,
                '--pictureLineHeight': _lineHeight,
              }"
              accept=".png,.jpeg,.jpg"
              action="imgUploadUrl"
              list-type="picture-card"
            >
              <i class="el-icon-upload2" />
            </el-upload>
          </div>
        </draggable>
      </div>
      <el-progress v-if="_isShowProgress" :percentage="imageUploadPercent" />
    </div>
    <za-dialog
      :is-show-footer="false"
      :modal="false"
      :visible.sync="previewVisible"
      top-title="查看图片"
      width="30%"
    >
      <img :src="previewImageUrl" alt="图片失效" width="100%" />
    </za-dialog>
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  import zaDialog from '@/za/components/zaDialog'
  import {
    changeMediaDataObj,
    checkImageUploadFormat,
    IMAGE_CHECK_TYPE,
    IMAGE_UPLOAD_STATUS,
  } from '@/za/components/zaUploadImg/uploadUtils'
  // import { apiUpImgs } from '@/api/common'

  export default {
    name: 'ZaUploadImg',
    components: { draggable, zaDialog },
    props: {
      value: {
        type: Array,
        default: () => [],
      },
      limit: {
        type: Number,
        default: 99,
      },
      confirmLoading: {
        type: Boolean,
        default: false,
      },
      pixelCheck: {
        type: Object,
        default: undefined,
      },
      isDelete: {
        type: Boolean,
        default: true,
      },
      disable: {
        type: Boolean,
        default: false,
      },
      width: {
        type: Number,
        default: 100,
      },
      height: {
        type: Number,
        default: 100,
      },
      disabledDrag: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        previewVisible: false,
        previewImageUrl: '',
        hideUploadEdit: false,
        imageUploadPercent: 0,
        fileList: [],
        resolveImgStr: '',
        resolveImgArray: [],
        resolveDelImgArray: [],
      }
    },
    computed: {
      _width() {
        return this.width + 'px'
      },
      _height() {
        return this.height + 'px'
      },
      _lineHeight() {
        const officeHeight = this.height + 5
        return officeHeight + 'px'
      },
      _value: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
        },
      },
      _confirmBtnLoading: {
        get() {
          return this.confirmLoading
        },
        set(val) {
          this.$emit('update:confirmLoading', val)
        },
      },
      _isShowProgress() {
        if (!this._confirmBtnLoading) return false
        for (const item of this.value) {
          if (item.status === IMAGE_UPLOAD_STATUS.UPLOAD_FAIL) return true
        }
        return false
      },
      dragOptions() {
        return {
          animation: 400,
          group: 'description',
          disabled: this.$props.disable ? true : this.disabledDrag,
        }
      },
    },
    watch: {
      _value() {
        this.hideUploadEdit = this._value.length >= this.limit
      },
    },
    mounted() {
      this.hideUploadEdit = this._value.length >= this.limit
    },
    methods: {
      isShowIcon(type) {
        return type === IMAGE_UPLOAD_STATUS.UPLOAD_SUCCESS || type === IMAGE_UPLOAD_STATUS.UPLOAD_ORG
      },
      handlePreviewImage(url) {
        this.previewImageUrl = url
        this.previewVisible = true
      },
      handleDelImg(item, index) {
        this.$confirm('是否确定要删除此照片?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            if (item.status === IMAGE_UPLOAD_STATUS.UPLOAD_ORG && item.other) {
              this.resolveDelImgArray.push(item.other.id)
            }
            this._value.splice(index, 1)
            this.delFile(this.$refs.imageUpload.fileList, item.uid)
            this.delFile(this.$refs.imageUpload.uploadFiles, item.uid)
          })
          .catch(() => {})
      },
      delFile(arrayObj, uid) {
        for (let i = 0; i < arrayObj.length; i++) {
          const curData = arrayObj[i]
          if (curData.uid.toString() === uid.toString()) {
            arrayObj.splice(i, 1)
            break
          }
        }
      },
      uploadImageProgress(pro) {
        this.imageUploadPercent = ((pro.loaded / pro.total) * 100) | 0
      },
      async onChange(file, fileList) {
        const checkValue = await this.ImgAllCheck(file)
        if (checkValue) {
          this._value.push(changeMediaDataObj(file))
        } else {
          fileList.pop()
        }
      },
      async ImgAllCheck(file) {
        if (!checkImageUploadFormat(file)) {
          this.$msgError('图片格式错误（允许的图片格式：png,jpeg,jpg）！')
          return false
        }
        if (!this.pixelCheck) {
          return true
        } else {
          const isOk = await this.ImgPixelChecked(file)
          if (isOk) {
            return true
          } else {
            switch (this.pixelCheck.type) {
              case IMAGE_CHECK_TYPE.EQUAL_RATIO:
                this.$msgError(`上传的图片宽高比不足"${this.pixelCheck.Prop}"`)
                return false
              case IMAGE_CHECK_TYPE.ONLY_WIDTH:
                this.$msgError(`上传的图片宽度必须等于"${this.pixelCheck.width}"`)
                return false
              case IMAGE_CHECK_TYPE.ONLY_HEIGHT:
                this.$msgError(`上传的图片高度不足"${this.pixelCheck.height}"`)
                return false
              case IMAGE_CHECK_TYPE.WIDTH_HEIGHT:
                this.$msgError(`上传的图片宽度不足"${this.pixelCheck.width}，高度不足"${this.pixelCheck.height}"`)
                return false
            }
            return true
          }
        }
      },
      ImgPixelChecked(file) {
        return new Promise((resolve) => {
          const imgDom = new Image()
          imgDom.src = file.url
          imgDom.onload = () => {
            if (this.pixelCheck.type === IMAGE_CHECK_TYPE.NONE) {
              resolve(true)
            } else if (
              this.pixelCheck.type === IMAGE_CHECK_TYPE.EQUAL_RATIO &&
              imgDom.width / imgDom.height === this.pixelCheck.Prop
            ) {
              resolve(true)
            } else if (
              this.pixelCheck.type === IMAGE_CHECK_TYPE.ONLY_HEIGHT &&
              imgDom.height >= this.pixelCheck.height
            ) {
              resolve(true)
            } else if (this.pixelCheck.type === IMAGE_CHECK_TYPE.ONLY_WIDTH && imgDom.width >= this.pixelCheck.width) {
              resolve(true)
            } else if (
              this.pixelCheck.type === IMAGE_CHECK_TYPE.WIDTH_HEIGHT &&
              imgDom.height >= this.pixelCheck.height &&
              imgDom.width >= this.pixelCheck.width
            ) {
              resolve(true)
            } else {
              resolve(false)
            }
          }
        })
      },
      onExceed() {
        this.$msgError(`超出最大上传数量,最多可上传${this.limit}张图片`)
      },
      changeFileUrl(orgUploadArray, retUrlArray) {
        for (const index in orgUploadArray) {
          const tmpFile = orgUploadArray[index]
          // const tmpUrl = retUrlArray[index].fileUrl;
          const tmpUrl = retUrlArray[index]
          if (tmpUrl !== '') {
            for (const item of this._value) {
              if (item.uid.toString() === tmpFile.uid.toString()) {
                item.status = IMAGE_UPLOAD_STATUS.UPLOAD_SUCCESS
                item.url = tmpUrl
                break
              }
            }
          }
        }
      },
      uploadImage() {
        return new Promise((resolve, reject) => {
          this.resolveImgArray.splice(0)
          const param = new FormData()
          for (const file of this._value) {
            const imageObj = {
              imgID: 0,
              imageURL: '',
            }
            if (file.status && file.status === IMAGE_UPLOAD_STATUS.UPLOAD_ORG) {
              if (file.other) {
                imageObj.imgID = file.other.id
              }
              imageObj.imageURL = file.url
            } else if (file.status && file.status === IMAGE_UPLOAD_STATUS.UPLOAD_SUCCESS) {
              imageObj.imageURL = file.url
            } else {
              param.append('files', file.raw)
            }
            this.resolveImgArray.push(imageObj)
          }
          const uploadImages = param.getAll('files')
          if (uploadImages.length > 0) {
            reject()
            // apiUpImgs(param, this.uploadImageProgress, this.kind)
            //   .then((response) => {
            //     console.log('------');
            //     console.log(response);
            //      console.log('------');
            //     try {
            //       this.changeFileUrl(uploadImages, response.data)
            //       this.dataArrangement(response.data)
            //       resolve({
            //         imgStr: this.resolveImgStr,
            //         imgObj: this.resolveImgArray,
            //       })
            //     } catch (err) {
            //       console.log(err)
            //     }
            //   })
            //   .catch((err) => {
            //     this.msgError('请检查未成功上传的图片格式与大小!')
            //     reject()
            //   })
            //   .finally(() => {})
          } else {
            this.dataArrangement()
            resolve({ imgStr: this.resolveImgStr, imgObj: this.resolveImgArray })
          }
        })
      },
      dataArrangement(resFiles) {
        if (resFiles) {
          for (const urlStr of resFiles) {
            for (const imageItem of this.resolveImgArray) {
              if (imageItem.imageURL === '') {
                // imageItem.imageURL = urlStr.fileUrl;
                imageItem.imageURL = urlStr
                break
              }
            }
          }
        }
        this.resolveImgStr = ''
        for (const imageItem of this.resolveImgArray) {
          if (this.resolveImgStr !== '') {
            this.resolveImgStr += ','
          }
          this.resolveImgStr += imageItem.imageURL
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-upload--picture-card {
      width: var(--pictureWidth) !important;
      height: var(--pictureHeight) !important;
      line-height: var(--pictureLineHeight) !important;
      background: #2d3e53;
      border-radius: 6px;
      border: 1px dashed #536978;
    }

    .el-upload--picture-card:hover,
    .el-upload:focus {
      border: 1px dashed #00d2ed;
    }

    .hide .el-upload--picture-card {
      display: none;
    }
  }

  .allUpload {
    .left {
      float: left;
      border-radius: 2px;
      border: 1px solid #536978;
      margin: 0 8px 8px 0;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      &:hover {
        .content-wrap {
          display: block;
        }
      }
      img {
        position: absolute;
        z-index: 0;
      }
      .content-wrap {
        display: none;
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: 0.6);
        .content {
          width: 100%;
          height: 100%;
          text-align: center;
          i {
            color: #fff;
            font-size: 19px;
            &:nth-of-type(1) {
              margin-right: 5px;
            }
            &:nth-of-type(2) {
              margin-left: 12px;
            }
          }
        }
      }
    }
    .wrap {
      float: left;
    }
    .success-bg {
      position: absolute;
      right: -15px;
      top: -6px;
      width: 40px;
      height: 24px;
      background: #13ce66;
      text-align: center;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-box-shadow: 0 0 1pc 1px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 1pc 1px rgba(0, 0, 0, 0.2);
    }
    .success-icon {
      color: #ffffff;
      margin-top: 11px;
      font-size: 14px;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
</style>
