import {
  apiBbcList,
  apiBccList,
  apiBcdList,
  apiBchList,
  apiBcmList,
  apiBlbList,
  apiBosList,
  apiCasList,
  apiCdnList,
  apiCdsList,
  apiDccList,
  apiDnsList,
  apiDtsList,
  apiEipBpList,
  apiEipList,
  apiKafkaList,
  apiRdsList,
  apiVpcList,
} from '@/api/navbar/index'

const enums = {
  staffsex: {
    noPay: '未支付',
    yesPay: '已支付',
  },
  userType: {
    1: '推广期客户',
    2: '维护期客户',
    3: '已失效客户',
  },
  correlationState: {
    1: '正常',
    2: '失效',
  },
  orderType: {
    1: '新客户订单',
    2: '老客户订单',
  },
  userTypeOther: {
    1: '企业',
    2: '个人',
  },
  payType: {
    wechat: '微信支付',
    alipay: '支付宝支付',
    balance: '余额支付',
    offline: '线下支付',
  },
  iframePage: {
    esc: apiBccList,
    mysql: apiRdsList,
    cdn: apiCdnList,
    bos: apiBosList,
    bcd: apiBcdList,
    dcc: apiDccList,
    cds: apiCdsList,
    eip: apiEipList,
    eipBp: apiEipBpList,
    vpc: apiVpcList,
    blb: apiBlbList,
    dns: apiDnsList,
    bch: apiBchList,
    cas: apiCasList,
    bbc: apiBbcList,
    dts: apiDtsList,
    kafka: apiKafkaList,
    bcm: apiBcmList,
  },
  localKey: {
    domains: 'domains',
  },
}
export const headTypes = {
  //个人
  personal: 1,
  //组织机构
  organization: 2,
  //企业
  enterprise: 3,
}
export const headTypesName = {
  //个人
  personal: '个人',
  //组织机构
  organization: '组织机构',
  //企业
  enterprise: '企业',
}

export const invoicesType = {
  //增值税普票
  vatGenTicket: 1,
  //增值税专票
  vatSpeTicket: 2,
  //数电专票
  digSpeTicket: 31,
  //数电普票
  digEleTicket: 32,
}
export const invoiceTypeName = {
  //数电专票
  digSpeTicket: '数电票专用发票',
  //数电普票
  digEleTicket: '数电票普通发票',
}
export const accountTypes = {
  //未认证
  notCer: 0,
  //企业认证
  entCer: 1,
  //个人认证
  perCer: 2,
}

export const industryOptions = [
  {
    value: '计算机/互联网/通信/电子',
    label: '计算机/互联网/通信/电子',
    children: [
      {
        value: '计算机软件',
        label: '计算机软件',
      },
      {
        value: '计算机硬件',
        label: '计算机硬件',
      },
      {
        value: '计算机服务(系统、数据服务、维修)',
        label: '计算机服务(系统、数据服务、维修)',
      },
      {
        value: '通信/电信/网络设备',
        label: '通信/电信/网络设备',
      },
      {
        value: '通信/电信运营、增值服务',
        label: '通信/电信运营、增值服务',
      },
      {
        value: '互联网/电子商务',
        label: '互联网/电子商务',
      },
      {
        value: '网络游戏',
        label: '网络游戏',
      },
      {
        value: '电子技术/半导体/集成电路',
        label: '电子技术/半导体/集成电路',
      },
      {
        value: '仪器仪表/工业自动化',
        label: '仪器仪表/工业自动化',
      },
    ],
  },
  {
    value: '会计/金融/银行/保险',
    label: '会计/金融/银行/保险',
    children: [
      {
        value: '会计/审计',
        label: '会计/审计',
      },
      {
        value: '金融/投资/证券',
        label: '金融/投资/证券',
      },
      {
        value: '银行',
        label: '银行',
      },
      {
        value: '保险',
        label: '保险',
      },
      {
        value: '信托/担保/拍卖/典当',
        label: '信托/担保/拍卖/典当',
      },
    ],
  },
  {
    value: '贸易/消费/制造/营运',
    label: '贸易/消费/制造/营运',
    children: [
      {
        value: '贸易/进出口',
        label: '贸易/进出口',
      },
      {
        value: '批发/零售',
        label: '批发/零售',
      },
      {
        value: '快速消费品（食品、饮料、化妆品）',
        label: '快速消费品（食品、饮料、化妆品）',
      },
      {
        value: '服装/纺织/皮革',
        label: '服装/纺织/皮革',
      },
      {
        value: '家具/家电/玩具/礼品',
        label: '家具/家电/玩具/礼品',
      },
      {
        value: '奢侈品/收藏品/工艺品/珠宝',
        label: '奢侈品/收藏品/工艺品/珠宝',
      },
      {
        value: '办公用品及设备',
        label: '办公用品及设备',
      },
      {
        value: '机械/设备/重工',
        label: '机械/设备/重工',
      },
      {
        value: '汽车及零配件',
        label: '汽车及零配件',
      },
    ],
  },
  {
    value: '制药/医疗',
    label: '制药/医疗',
    children: [
      {
        value: '制药/生物工程',
        label: '制药/生物工程',
      },
      {
        value: '医疗/护理/卫生',
        label: '医疗/护理/卫生',
      },
      {
        value: '医疗设备/器械',
        label: '医疗设备/器械',
      },
    ],
  },
  {
    value: '广告/媒体',
    label: '广告/媒体',
    children: [
      {
        value: '广告',
        label: '广告',
      },
      {
        value: '公关/市场推广/会展',
        label: '公关/市场推广/会展',
      },
      {
        value: '影视/媒体/艺术/文化传播',
        label: '影视/媒体/艺术/文化传播',
      },
      {
        value: '文字媒体/出版',
        label: '文字媒体/出版',
      },
      {
        value: '印刷/包装/造纸',
        label: '印刷/包装/造纸',
      },
    ],
  },
  {
    value: '房地产/建筑',
    label: '房地产/建筑',
    children: [
      {
        value: '房地产',
        label: '房地产',
      },
      {
        value: '建筑/建材/工程',
        label: '建筑/建材/工程',
      },
      {
        value: '家居/室内设计/装潢',
        label: '家居/室内设计/装潢',
      },
      {
        value: '物业管理/商业中心',
        label: '物业管理/商业中心',
      },
    ],
  },
  {
    value: '专业服务/教育/培训',
    label: '专业服务/教育/培训',
    children: [
      {
        value: '中介服务',
        label: '中介服务',
      },
      {
        value: '租赁服务',
        label: '租赁服务',
      },
      {
        value: '专业服务(咨询、人力资源、财会)',
        label: '专业服务(咨询、人力资源、财会)',
      },
      {
        value: '外包服务',
        label: '外包服务',
      },
      {
        value: '检测、认证',
        label: '检测、认证',
      },
      {
        value: '法律',
        label: '法律',
      },
      {
        value: '教育/培训/院校',
        label: '教育/培训/院校',
      },
      {
        value: '学术/科研',
        label: '学术/科研',
      },
    ],
  },
  {
    value: '服务业',
    label: '服务业',
    children: [
      {
        value: '餐饮业',
        label: '餐饮业',
      },
      {
        value: '酒店/旅游',
        label: '酒店/旅游',
      },
      {
        value: '娱乐/休闲/体育',
        label: '娱乐/休闲/体育',
      },
      {
        value: '美容/保健',
        label: '美容/保健',
      },
      {
        value: '生活服务',
        label: '生活服务',
      },
    ],
  },
  {
    value: '物流/运输',
    label: '物流/运输',
    children: [
      {
        value: '交通/运输/物流',
        label: '交通/运输/物流',
      },
      {
        value: '航天/航空',
        label: '航天/航空',
      },
    ],
  },
  {
    value: '能源/原材料',
    label: '能源/原材料',
    children: [
      {
        value: '石油/化工/矿产/地质',
        label: '石油/化工/矿产/地质',
      },
      {
        value: '采掘业/冶炼',
        label: '采掘业/冶炼',
      },
      {
        value: '电气/电力/水利',
        label: '电气/电力/水利',
      },
      {
        value: '新能源',
        label: '新能源',
      },
      {
        value: '原材料和加工',
        label: '原材料和加工',
      },
    ],
  },
  {
    value: '政府/非营利组织/其他',
    label: '政府/非营利组织/其他',
    children: [
      {
        value: '政府/公共事业',
        label: '政府/公共事业',
      },
      {
        value: '非盈利组织',
        label: '非盈利组织',
      },
      {
        value: '环保',
        label: '环保',
      },
      {
        value: '农/林/牧/渔',
        label: '农/林/牧/渔',
      },
      {
        value: '多元化业务集团公司',
        label: '多元化业务集团公司',
      },
    ],
  },
]
export default enums
