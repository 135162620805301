var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [_c("navbar")], 1),
    _c("div", { staticClass: "priceDesc-center_bg" }, [
      _c(
        "div",
        [
          _c("za-go-back", {
            attrs: { title: "报价详情" },
            on: { click: _vm.goBack },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "top" },
        [
          _c(
            "div",
            [
              _c("za-tabs", {
                attrs: {
                  tabArray: _vm.titleList,
                  handleActive: _vm.titleList[0].key,
                  showBorder: false,
                },
              }),
            ],
            1
          ),
          _c("occ-table", {
            attrs: {
              tableData: _vm.tableData,
              columObj: _vm.columObj,
              "style-height": "220",
            },
          }),
          _c("div", { staticClass: "bottom" }, [
            _c("div", [
              _c("i", [_vm._v("原价：")]),
              _c("i", { staticClass: "huaxian" }, [
                _vm._v("￥" + _vm._s(_vm.originalPrice)),
              ]),
            ]),
            _c("div", [
              _c("span", [_vm._v("活动价：")]),
              _c("span", [_vm._v("￥" + _vm._s(_vm.price))]),
            ]),
            _vm._m(0),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "i",
        { staticClass: "red-font", staticStyle: { "margin-right": "10px" } },
        [_vm._v("*")]
      ),
      _c("span", [_vm._v("当前价格仅供参考,请以实际报价单为准")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }