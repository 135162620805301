var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "za-from-left-panel",
        { attrs: { span: 16 } },
        [_vm._t("default"), _vm._t("toggle")],
        2
      ),
      _c(
        "za-from-right-panel",
        { attrs: { span: 8 } },
        [_vm._t("turn"), _c("span", [_vm._v("展开")])],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }