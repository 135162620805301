var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "za-loading",
          rawName: "v-za-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "reminder",
    },
    [
      _c("div", { staticClass: "title" }, [_vm._v("推广客户")]),
      _c("div", [_c("za-picker", { attrs: { timeList: _vm.timeList } })], 1),
      _c(
        "div",
        { staticClass: "card_mottom" },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "cardUl" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("当前客户关联数"),
                  ]),
                  _c("div", { staticClass: "operation" }, [
                    _c("div", [
                      _c("span", [_vm._v(_vm._s(_vm.waitPayOrderNumber))]),
                      _c("span", [_vm._v("个")]),
                    ]),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "cardUl" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("推广链接点击数"),
                  ]),
                  _c("div", { staticClass: "operation" }, [
                    _c("div", [
                      _c("span", [_vm._v(_vm._s(_vm.waitRenewResourceNumber))]),
                      _c("span", [_vm._v("次")]),
                    ]),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "cardUl" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("维护期客户数")]),
                  _c("div", { staticClass: "operation" }, [
                    _c("div", [
                      _c("span", [_vm._v(_vm._s(_vm.amount))]),
                      _c("span", [_vm._v("个")]),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }