/**
 * @description element按钮图标
 * @type {{ADD: string, DELETE: string, DOWN: string, SEARCH: string, UPLOAD: string, EDIT: string, RESET: string, SYNC: string}}
 */
export const EL_BUTTON_ICON = {
  ADD: 'el-icon-plus',
  DELETE: 'el-icon-delete',
  EDIT: 'el-icon-edit',
  SEARCH: 'el-icon-search',
  RESET: 'el-icon-refresh-right',
  UPLOAD: 'el-icon-upload2',
  DOWN: 'el-icon-download',
  SYNC: 'el-icon-refresh',
}

/**
 * @description element颜色样式
 * @type {{RED: string, BLUE: string, TEXT: string, NONE: string, GREEN: string, GREY: string, ORANGE: string}}
 */
export const EL_COLOR = {
  NONE: '',
  TEXT: 'text',
  BLUE: 'primary',
  GREEN: 'success',
  GREY: 'info',
  ORANGE: 'warning',
  RED: 'danger',
}

/**
 * PROVINCE: 省
 * CITY: 市
 * DISTRICT: 区
 * @type {{DISTRICT: number, CITY: number, PROVINCE: number}}
 */
export const COMMON_CITY_TYPE = {
  PROVINCE: 1,
  CITY: 2,
  DISTRICT: 3,
}
