<template>
  <div class="myHomeproduct">
    <div class="title">安全产品</div>
    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr">
      <div v-for="(item, index) in list" :key="index" class="listStyle" @click="handleRoute(item.path)">
        <za-svg-icon :icon-class="item.icon" class-name="svg-item"></za-svg-icon>
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {
        list: [
          {
            name: '云保·等级保护建设系统',
            icon: '等级保护建设系统',
            path: `${process.env.VUE_APP_PORTAL_TARGET_URL}/products/gpcs`,
          },
          {
            name: '云密·商用密码建设系统',
            icon: '商用密码建设系统',
            path: `${process.env.VUE_APP_PORTAL_TARGET_URL}/products/cpcs`,
          },
          {
            name: '云控·零信任安全访问控制系统',
            icon: '零信任安全访问控制系统',
            path: `${process.env.VUE_APP_PORTAL_TARGET_URL}/products/ztsacs`,
          },
          {
            name: '云墙·网站综合防御系统',
            icon: '网站综合防御系统',
            path: `${process.env.VUE_APP_PORTAL_TARGET_URL}/products/cwds`,
          },
          {
            name: '云眼·网站安全监测系统',
            icon: '网站安全监测系统',
            path: `${process.env.VUE_APP_PORTAL_TARGET_URL}/products/wsms`,
          },
          // {name: '勒索病毒解密服务', icon: 'za-home-bingDu', path: `${process.env.VUE_APP_URL}/cloudSecurity/ransomVirusDecryption`},
          {
            name: '云盾·APP业务防御系统',
            icon: 'APP业务防御系统',
            path: `${process.env.VUE_APP_PORTAL_TARGET_URL}/products/appbds`,
          },
        ],
      }
    },
    computed: {
      ...mapGetters({
        token: 'user/token',
      }),
    },
    methods: {
      handleRoute(path) {
        window.open(path, '_self')
      },
    },
  }
</script>
<style lang="scss" scoped>
  .myHomeproduct {
    width: 100%;
    height: 180px;
    background: #ffffff;
    border-radius: 2px;
    margin-bottom: 16px;
    padding: 20px;

    .title {
      width: 100%;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 20px;
      margin-bottom: 14px;
    }

    .listStyle {
      padding: 5px 10px;
      margin-right: 16px;
      height: 50px;
      background: #f4f6fa;
      font-size: 12px;
      line-height: 39px;
      font-family: PingFangSC-Regular, PingFang SC;
      margin-bottom: 12px;
      cursor: pointer;
    }

    .listStyle:hover {
      background: #1b5deb;
      color: #ffffff;
    }

    .listStyle:last-child {
      min-width: 220px;
    }

    .svg-item {
      margin-right: 9px;
      font-size: 20px;
      vertical-align: middle;
    }
  }
</style>