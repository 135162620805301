<template>
  <div>
    <div class="go-captcha-wrap">
      <GoCaptchaBtn
        class="go-captcha-btn"
        v-model="captStatus"
        width="100%"
        height="50px"
        :image-base64="captBase64"
        :thumb-base64="captThumbBase64"
        :showState="showState"
        @confirm="handleConfirm"
        @refresh="handleRequestCaptCode"
        @close="handleCloseEvent"
      />
    </div>
  </div>
</template>

<script>
  import GoCaptchaBtn from './components/GoCaptchaBtn'
  import { resLoginObj } from '@/za/model/resModel'
  import { apiGetCaptcha, apiLoginz } from '@/api/apiLogin'

  import { mapActions } from 'vuex'

  export default {
    name: 'App',
    components: {
      GoCaptchaBtn,
    },
    props: {
      showState: {
        type: Boolean,
        default: false,
      },
      heardBase: {
        type: String,
        default: '',
      },
      centerBase: {
        type: String,
        default: '',
      },
      loginObj: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        // 验证码数据
        needCapt: false,
        popoverVisible: true,
        captBase64: '',
        captThumbBase64: '',
        captchaId: '',
        captStatus: 'default',
        captExpires: 0,
        captAutoRefreshCount: 0,
        popularizeCode: '',
      }
    },
    created() {
      const code = this.$route.query.id
      code ? (this.popularizeCode = code) : (this.popularizeCode = '')
    },
    methods: {
      ...mapActions({
        setLoginData: 'user/setLoginData',
      }),
      handleCloseEvent() {
        this.$emit('close')
      },
      /**
       * 处理请求验证码
       */
      async handleRequestCaptCode() {
        //主体图片
        this.captBase64 = ''
        //这个是依次点击旁边图片
        this.captThumbBase64 = ''
        let res = await apiGetCaptcha().catch()
        this.captBase64 = res.data.b64
        this.captThumbBase64 = res.data.tb64
        this.captchaId = res.data.captchaId
      },
      /**
       * 处理验证码校验请求
       */
      async handleConfirm(dots) {
        if (this.$lodash.size(dots) <= 0) {
          this.$msgWarning('请进行人机验证再操作')
          return
        }
        let dotArr = []
        this.$lodash.forEach(dots, (dot) => {
          dotArr.push(dot.x, dot.y)
        })
        dotArr = dotArr.join(',')
        let data = {
          l:
            'username=' +
            this.loginObj.username +
            '&password=' +
            this.loginObj.password +
            '&captchaId=' +
            this.captchaId +
            '&code=' +
            dotArr +
            '&time=' +
            this.loginObj.time,
          popularizeCode: this.popularizeCode,
        }
        data.l = this.$Base64.encode(data.l)
        //优化  利用try  catch解决验证码输入错误后控制台报错的问题
        try {
          let res = await apiLoginz(data).catch()
          if (res.code == 0) {
            res.data.n = this.$Base64.decode(res.data.n)
            this.$msgSuccess('人机验证成功')
            // this.$message({message: `人机验证成功`,type: 'success'})
            this.captStatus = 'success'
            this.captAutoRefreshCount = 0
            let newRes = res.data.n.split('&')
            var obj = {}
            newRes.forEach((v) => {
              var arr = v.split('=') // 等号分割
              obj[arr[0]] = arr[1] // 第一项arr[0]做为属性名 第二项arr[1]做为属性值
            })
            this.$printLog(obj, 'Login')
            const resObj = Object.assign(resLoginObj, obj)
            await this.setLoginData(resObj).catch()
            //如果 login后 存在(充值)重定向地址，则登陆后直接跳转重定向地址
            if (this.$route.query.toHomeFlag === 'false') {
              await this.$router.push(this.$route.query.redirect)
            } else if (this.$route.query.target) {
              await this.$router.push(`/${this.$route.query.target}`)
            } else {
              await this.$router.push('/')
            }
            // await this.$router.push('/evaluationActivity')
          } else if (res.code == 599998) {
            this.$emit('close', res.msg)
          }
        } catch (error) {
          this.handleRequestCaptCode()
        }
        // this.$axios({
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded'
        //     },
        //     method: 'post',
        //     url: '/api/go_captcha_check_data',
        //     data: Qs.stringify({
        //         dots: dotArr.join(','),
        //         key: this.captKey
        //     })
        // }).then((response) => {
        //     const {data = {}} = response;
        //
        //     if ((data['code'] || 0) === 0) {
        //         this.$message({
        //             message: `人机验证成功`,
        //             type: 'success'
        //         })
        //         this.captStatus = 'success'
        //         this.captAutoRefreshCount = 0
        //     } else {
        //         this.$message({
        //             message: `人机验证失败`,
        //             type: 'warning'
        //         })
        //         if (this.captAutoRefreshCount > 5) {
        //             this.captAutoRefreshCount = 0
        //             this.captStatus = 'over'
        //             return
        //         }
        //
        //         this.handleRequestCaptCode()
        //         this.captAutoRefreshCount += 1
        //         this.captStatus = 'error'
        //     }
        // })
      },
    },
  }
</script>

<style>
  .go-captcha-wrap {
    /*width: 400px;*/
  }

  .go-captcha-btn {
    width: 300px !important;
    margin: 0 auto !important;
  }

  .wg-cap-tip {
    padding: 50px 0 100px;
    font-size: 13px;
    color: #76839b;
    text-align: center;
    line-height: 180%;
    width: 100%;
    max-width: 680px;
  }

  .wg-cap-tip a {
    display: inline-block;
    margin: 0 5px;
  }

  .wg-cap-tip a img {
    height: 28px;
  }

  .wg-cap-tip > span {
    margin: 0 5px;
  }
</style>