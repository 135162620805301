var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "proportion" }, [
    _c("div", { staticClass: "proportion_item" }, [
      _c("p", [_vm._v("新客返佣比")]),
      _c("p", { staticClass: "value" }, [
        _vm._v(_vm._s(_vm.popularizeInfo.newCustomer) + "%"),
      ]),
    ]),
    _c("div", { staticClass: "proportion_item" }, [
      _c("p", [_vm._v("老客返佣比")]),
      _c("p", { staticClass: "value" }, [
        _vm._v(_vm._s(_vm.popularizeInfo.oldCustomer) + "%"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }