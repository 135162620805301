var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "flex" },
      [
        _c("za-canvas", {
          ref: "slideblock",
          attrs: {
            l: 42,
            r: 10,
            w: _vm.fullWidth,
            h: 200,
            changeState: _vm.changeState,
            accuracy: _vm.accuracy,
            "slider-text": _vm.text,
            imgs: _vm.imgList,
          },
          on: {
            again: _vm.onAgain,
            fulfilled: _vm.onFulfilled,
            success: _vm.onSuccess,
            fail: _vm.onFail,
            refresh: _vm.onRefresh,
            change: _vm.changePic,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }