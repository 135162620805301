<template>
    <div>
        系统消息
    </div>
</template>
<script>
    export default {
        name:'SystemMsg',
        data(){
            return{

            }
        }
    }
</script>
<style scoped lang="scss">

</style>