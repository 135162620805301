var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showBox,
            expression: "showBox",
          },
        ],
        class: _vm.mode == "pop" ? "mask" : "",
      },
      [
        _c(
          "div",
          {
            class: _vm.mode == "pop" ? "verifybox" : "",
            style: { "max-width": parseInt(_vm.imgSize.width) + 30 + "px" },
          },
          [
            _vm.mode == "pop"
              ? _c("div", { staticClass: "verifybox-top" }, [
                  _vm._v(" 请完成安全验证 "),
                  _c(
                    "span",
                    {
                      staticClass: "verifybox-close",
                      on: { click: _vm.closeBox },
                    },
                    [_c("i", { staticClass: "iconfont icon-close" })]
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "verifybox-bottom",
                style: { padding: _vm.mode == "pop" ? "15px" : "0" },
              },
              [
                _vm.componentType
                  ? _c(_vm.componentType, {
                      ref: "instance",
                      tag: "components",
                      attrs: {
                        "captcha-type": _vm.captchaType,
                        type: _vm.verifyType,
                        figure: _vm.figure,
                        arith: _vm.arith,
                        mode: _vm.mode,
                        "v-space": _vm.vSpace,
                        explain: _vm.explain,
                        "img-size": _vm.imgSize,
                        "block-size": _vm.blockSize,
                        "bar-size": _vm.barSize,
                        "default-img": _vm.defaultImg,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }