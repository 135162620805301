var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "myHomeproduct" }, [
    _c("div", { staticClass: "title" }, [_vm._v("安全产品")]),
    _c(
      "div",
      {
        staticStyle: {
          display: "grid",
          "grid-template-columns": "1fr 1fr 1fr 1fr 1fr",
        },
      },
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "listStyle",
            on: {
              click: function ($event) {
                return _vm.handleRoute(item.path)
              },
            },
          },
          [
            _c("za-svg-icon", {
              attrs: { "icon-class": item.icon, "class-name": "svg-item" },
            }),
            _c("span", [_vm._v(_vm._s(item.name))]),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }