var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: [_vm.isHome ? "navTop" : "ignore_navTop"] }, [
    _c(
      "div",
      { staticClass: "topInfo" },
      [
        _c("nav-drawer"),
        _c(
          "div",
          {
            class: [
              _vm.isHome ? "operationContent" : "ignore_operationContent",
            ],
          },
          [_c("nav-setting", { attrs: { "is-home": _vm.isHome } })],
          1
        ),
        _c(
          "div",
          { staticClass: "nav-item" },
          [_c("nav-finance", { attrs: { optionsTitle: "财务" } })],
          1
        ),
        _c("div", { staticClass: "nav-item" }, [
          _c("span", { on: { click: _vm.openIcpView } }, [_vm._v("ICP备案")]),
        ]),
        _c("div", { staticClass: "nav-item" }, [_c("nav-msg")], 1),
        _c("div", { staticClass: "nav-item" }, [_c("nav-ma")], 1),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }