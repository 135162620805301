var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "details-container" }, [
    _c("div", { staticClass: "back", on: { click: _vm.goBack } }, [
      _c("span", { staticStyle: { cursor: "pointer" } }, [
        _c(
          "span",
          [_c("za-svg-icon", { attrs: { "icon-class": "za-go-back" } })],
          1
        ),
        _c("span", [_vm._v("返回")]),
      ]),
    ]),
    _c("div", { staticClass: "main" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", [_vm._v(_vm._s(_vm.title))]),
      ]),
      _c("div", { staticClass: "describe" }, [
        _c("span", [_vm._v("消息类型：")]),
        _c("span", [_vm._v(_vm._s(_vm.transType(_vm.conentType)))]),
        _c("span", [_vm._v("发布时间：")]),
        _c("span", [_vm._v(_vm._s(_vm.createAt))]),
      ]),
      _c("div", { staticClass: "content" }, [
        _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }