var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "za-loading",
          rawName: "v-za-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "safetySet-container",
    },
    [
      _c("div", { staticClass: "title" }, [_vm._v("账号安全")]),
      _c("div", { staticClass: "pwdLevel" }, [
        _c("div", { staticClass: "levelTitle" }, [_vm._v("账号安全级别：")]),
        _c("div", [
          _c("div", {
            staticClass: "default weak",
            class: [
              { medium: _vm.pageObj.safetyLevel == "中等" },
              { strong: _vm.pageObj.safetyLevel == "较强" },
            ],
          }),
          _c("div", {
            staticClass: "default",
            class: [
              { medium: _vm.pageObj.safetyLevel == "中等" },
              { strong: _vm.pageObj.safetyLevel == "较强" },
            ],
          }),
          _c("div", {
            staticClass: "default",
            class: [_vm.pageObj.safetyLevel == "较强" ? "strong" : ""],
          }),
        ]),
        _c("div", { staticClass: "prompt" }, [
          _vm._v(_vm._s(_vm.pageObj.safetyLevel)),
        ]),
      ]),
      _c("div", { staticClass: "main" }, [
        _c("ul", [
          _c("li", [
            _c("div", { staticClass: "main_top" }, [
              _c(
                "div",
                [
                  _c("za-svg-icon", {
                    attrs: {
                      "icon-class": "za-success",
                      "class-name": "svg-success",
                    },
                  }),
                  _c("span", { staticClass: "login_pwd" }, [
                    _vm._v("登录密码"),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "modify",
                  on: {
                    click: function ($event) {
                      return _vm.pathVerification(0)
                    },
                  },
                },
                [_vm._v("修改")]
              ),
            ]),
            _c("div", { staticClass: "main_bottom" }, [
              _vm._v(
                " 安全性高的密码可以使帐号更安全。建议您定期更换密码，设置一个限字母、数字或特殊字符至少包含两种且长度超过6位的密码 "
              ),
            ]),
          ]),
          _c("li", [
            _c("div", { staticClass: "main_top" }, [
              _c(
                "div",
                [
                  _c("za-svg-icon", {
                    attrs: {
                      "icon-class":
                        _vm.pageObj.mobileBind == 0
                          ? "za-failure"
                          : "za-success",
                      "class-name": "svg-success",
                    },
                  }),
                  _c("span", { staticClass: "login_pwd" }, [
                    _vm._v("手机绑定"),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "modify",
                  on: {
                    click: function ($event) {
                      return _vm.pathVerification(1)
                    },
                  },
                },
                [_vm._v("修改")]
              ),
            ]),
            _c("div", { staticClass: "main_bottom" }, [
              _c("span", [
                _vm._v(" 您已绑定了手机( "),
                _c("span", { staticStyle: { color: "#1b5deb" } }, [
                  _vm._v(_vm._s(_vm.phone)),
                ]),
                _vm._v(" ) "),
              ]),
              _c("span", [
                _vm._v(
                  "[您的手机为安全手机，可用于接收消息，账号核身（如找回密码、变更安全设置等）]"
                ),
              ]),
            ]),
          ]),
          _c("li", [
            _c("div", { staticClass: "main_top" }, [
              _c(
                "div",
                [
                  _c("za-svg-icon", {
                    attrs: {
                      "icon-class":
                        _vm.pageObj.emailBind == 0
                          ? "za-failure"
                          : "za-success",
                      "class-name": "svg-success",
                    },
                  }),
                  _c("span", { staticClass: "login_pwd" }, [
                    _vm._v("邮箱绑定"),
                  ]),
                ],
                1
              ),
              _vm.pageObj.emailBind == 0
                ? _c(
                    "div",
                    {
                      staticClass: "modify",
                      on: {
                        click: function ($event) {
                          return _vm.pathVerification(4)
                        },
                      },
                    },
                    [_vm._v("设置")]
                  )
                : _vm._e(),
              _vm.pageObj.emailBind == 1
                ? _c(
                    "div",
                    {
                      staticClass: "modify",
                      on: {
                        click: function ($event) {
                          return _vm.pathVerification(2)
                        },
                      },
                    },
                    [_vm._v("修改")]
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "main_bottom" }, [
              _vm._v(
                "备用邮箱绑定后可用来接收智安云发送的各类系统、营销、服务通知"
              ),
            ]),
          ]),
          _c("li", [
            _c("div", { staticClass: "main_top" }, [
              _c(
                "div",
                [
                  _c("za-svg-icon", {
                    attrs: {
                      "icon-class":
                        _vm.pageObj.securityBind == 0
                          ? "za-failure"
                          : "za-success",
                      "class-name": "svg-success",
                    },
                  }),
                  _c("span", { staticClass: "login_pwd" }, [
                    _vm._v("密保问题"),
                  ]),
                ],
                1
              ),
              _vm.pageObj.securityBind == 0
                ? _c(
                    "div",
                    { staticClass: "modify", on: { click: _vm.setPwdInfo } },
                    [_vm._v("设置")]
                  )
                : _vm._e(),
              _vm.pageObj.securityBind !== 0
                ? _c(
                    "div",
                    {
                      staticClass: "modify",
                      on: {
                        click: function ($event) {
                          return _vm.pathVerification(3)
                        },
                      },
                    },
                    [_vm._v("修改")]
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "main_bottom" }, [
              _vm._v(
                " 建议您设置三个容易记住，且最不容易被他人获取的问题及答案，更有效保障您的密码安全 "
              ),
            ]),
          ]),
          _c("li", [
            _c("div", { staticClass: "main_top" }, [
              _c(
                "div",
                [
                  _c("za-svg-icon", {
                    attrs: {
                      "icon-class":
                        _vm.pageObj.accountType == 0
                          ? "za-failure"
                          : "za-success",
                      "class-name": "svg-success",
                    },
                  }),
                  _c("span", { staticClass: "login_pwd" }, [
                    _vm._v("实名认证"),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "modify",
                  on: {
                    click: function ($event) {
                      return _vm.$router.push(
                        "/accountCenter/nameCertification"
                      )
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.pageObj.accountType == 0 ? "设置" : "修改") +
                      " "
                  ),
                ]
              ),
            ]),
            _vm.pageObj.accountType !== 0
              ? _c("div", { staticClass: "main_bottom" }, [
                  _vm._v("您已通过实名认证"),
                ])
              : _vm._e(),
            _vm.pageObj.accountType == 0
              ? _c("div", { staticClass: "main_bottom" }, [
                  _vm._v("请实名认证"),
                ])
              : _vm._e(),
          ]),
          _c("li", [
            _c("div", { staticClass: "main_top" }, [
              _c(
                "div",
                [
                  _c("za-svg-icon", {
                    attrs: {
                      "icon-class":
                        _vm.pageObj.loginProtection.length <= 1
                          ? "za-failure"
                          : "za-success",
                      "class-name": "svg-success",
                    },
                  }),
                  _c("span", { staticClass: "login_pwd" }, [
                    _vm._v("登录保护"),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "modify", on: { click: _vm.nowSave } }, [
                _vm._v("立即保存"),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "main_bottom" },
              [
                _c(
                  "el-checkbox-group",
                  {
                    model: {
                      value: _vm.pageObj.loginProtection,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageObj, "loginProtection", $$v)
                      },
                      expression: "pageObj.loginProtection",
                    },
                  },
                  [
                    _c("el-checkbox", { attrs: { label: "1" } }, [
                      _vm._v("异地登录时二次验证"),
                    ]),
                    _c("el-checkbox", { attrs: { label: "2" } }, [
                      _vm._v("更换常用设备时"),
                    ]),
                    _c("el-checkbox", { attrs: { label: "3" } }, [
                      _vm._v("每次验证"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("li", [
            _c("div", { staticClass: "main_top" }, [
              _c(
                "div",
                [
                  _c("za-svg-icon", {
                    attrs: {
                      "icon-class":
                        _vm.pageObj.weChatBind === 1
                          ? "za-success"
                          : "za-failure",
                      "class-name": "svg-success",
                    },
                  }),
                  _c("span", { staticClass: "login_pwd" }, [
                    _vm._v("微信绑定"),
                  ]),
                ],
                1
              ),
            ]),
            _vm.pageObj.weChatBind === 1
              ? _c("div", { staticClass: "main_bottom" }, [
                  _vm._v(" 您已绑定微信 "),
                  _c("span", { staticStyle: { color: "#419efe" } }, [
                    _vm._v(_vm._s(_vm.pageObj.weChatInfo.weChatName)),
                  ]),
                  _vm._v(" [支持使用所绑定微信登录] "),
                ])
              : _c("div", { staticClass: "main_bottom" }, [
                  _vm._v("绑定后可获取相关信息安全认证，及使用微信登录"),
                ]),
          ]),
        ]),
      ]),
      _c(
        "za-dialog",
        {
          attrs: {
            "top-title": "设置密码保护",
            visible: _vm.dialogVisible,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "tipsInfo" },
            [
              _c("za-svg-icon", { attrs: { "icon-class": "za-wanning" } }),
              _c("span", [
                _vm._v(
                  "请妥善保管密保问题，设置后可用于找回密码、变更安全设置等!"
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                disabled: _vm.confirmBtnLoading,
                model: _vm.baseForm,
                "label-position": "right",
                "label-width": "140px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "请选择密保问题" + "：",
                    prop: "oneQuestion",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { clearable: "" },
                      on: {
                        change: function ($event) {
                          return _vm.handleChange("oneQuestion", $event)
                        },
                      },
                      model: {
                        value: _vm.baseForm.oneQuestion,
                        callback: function ($$v) {
                          _vm.$set(_vm.baseForm, "oneQuestion", $$v)
                        },
                        expression: "baseForm.oneQuestion",
                      },
                    },
                    _vm._l(_vm.oneQuestion, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.name,
                          value: item.id,
                          disabled: item.disabled,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.baseForm.oneQuestion == "oneId"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "自定义问题" + "：",
                        prop: "orderNumber1",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入自定义问题" },
                        model: {
                          value: _vm.baseForm.orderNumber1,
                          callback: function ($$v) {
                            _vm.$set(_vm.baseForm, "orderNumber1", $$v)
                          },
                          expression: "baseForm.orderNumber1",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "密保答案" + "：", prop: "oneAnswer" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入密保答案" },
                    model: {
                      value: _vm.baseForm.oneAnswer,
                      callback: function ($$v) {
                        _vm.$set(_vm.baseForm, "oneAnswer", $$v)
                      },
                      expression: "baseForm.oneAnswer",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "请选择密保问题" + "：",
                    prop: "twoQuestion",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { clearable: "" },
                      on: {
                        change: function ($event) {
                          return _vm.handleChange("twoQuestion", $event)
                        },
                      },
                      model: {
                        value: _vm.baseForm.twoQuestion,
                        callback: function ($$v) {
                          _vm.$set(_vm.baseForm, "twoQuestion", $$v)
                        },
                        expression: "baseForm.twoQuestion",
                      },
                    },
                    _vm._l(_vm.twoQuestion, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.name,
                          value: item.id,
                          disabled: item.disabled,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.baseForm.twoQuestion == "oneId"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "自定义问题" + "：",
                        prop: "orderNumber2",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入自定义问题" },
                        model: {
                          value: _vm.baseForm.orderNumber2,
                          callback: function ($$v) {
                            _vm.$set(_vm.baseForm, "orderNumber2", $$v)
                          },
                          expression: "baseForm.orderNumber2",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "密保答案" + "：", prop: "twoAnswer" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入密保答案" },
                    model: {
                      value: _vm.baseForm.twoAnswer,
                      callback: function ($$v) {
                        _vm.$set(_vm.baseForm, "twoAnswer", $$v)
                      },
                      expression: "baseForm.twoAnswer",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "请选择密保问题" + "：",
                    prop: "threeQuestion",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      on: {
                        change: function ($event) {
                          return _vm.handleChange("threeQuestion", $event)
                        },
                      },
                      model: {
                        value: _vm.baseForm.threeQuestion,
                        callback: function ($$v) {
                          _vm.$set(_vm.baseForm, "threeQuestion", $$v)
                        },
                        expression: "baseForm.threeQuestion",
                      },
                    },
                    _vm._l(_vm.threeQuestion, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.name,
                          value: item.id,
                          disabled: item.disabled,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.baseForm.threeQuestion == "oneId"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "自定义问题" + "：",
                        prop: "orderNumber3",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入自定义问题" },
                        model: {
                          value: _vm.baseForm.orderNumber3,
                          callback: function ($$v) {
                            _vm.$set(_vm.baseForm, "orderNumber3", $$v)
                          },
                          expression: "baseForm.orderNumber3",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "密保答案" + "：", prop: "threeAnswer" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入密保答案" },
                    model: {
                      value: _vm.baseForm.threeAnswer,
                      callback: function ($$v) {
                        _vm.$set(_vm.baseForm, "threeAnswer", $$v)
                      },
                      expression: "baseForm.threeAnswer",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "za-button",
                {
                  attrs: {
                    disabled: _vm.confirmBtnLoading,
                    type: _vm.buttonTypeObj.GREY,
                  },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.translateTitle("btn.cancel")) + " ")]
              ),
              _c(
                "za-button",
                {
                  attrs: {
                    loading: _vm.confirmBtnLoading,
                    type: _vm.buttonTypeObj.BLUE,
                  },
                  on: { click: _vm.handleSubmitForm },
                },
                [_vm._v(" " + _vm._s(_vm.translateTitle("btn.submit")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }