<template>
    <div class="cloudTest-center_bg" v-za-loading="isLoading">
        <div class="header-card">
            <div class="title">
                <span>账号信息</span>
            </div>
            <div>
                <changePicture></changePicture>
            </div>
        </div>
        <div>
            <el-card class="box-card">
                <div>
                    <basic @getList="getList"></basic>
                </div>
            </el-card>
        </div>
    </div>
</template>
<script>
    import changePicture from './components/changePicture'
    import basic from './components/basic'
    import {apiUserInfo} from '@/api/accountCenter/basicInfo'

    export default {
        name: 'BasicInfo',
        components: {changePicture, basic},
        data() {
            return {}
        },
        created() {
            this.getList()
        },
        methods: {
            getList() {
                this.onSubmitData(apiUserInfo, null, null, false, false, (res) => {
                    this.$nextTick(() => {
                        this.$baseEventBus.$emit('getHeardInfo', res.data)
                    })
                })
            },
        }

    }
</script>
<style lang="scss" scoped>
    @import '~@/za/styles/mixin.scss';

    .header-card {
        margin-bottom: 20px;
        height: 140px;
        background: #FFFFFF;
        border-radius: 2px;
        padding: 20px;

        .title {
            @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
            width: 100%;
            height: 20px;
            margin-bottom: 20px;
        }
    }

</style>