<template>
    <div class="developer">
        <div class="title">开发者资源</div>
        <div style="display: flex;">
            <div v-for="(item,index) in list" :key="index" class="loperName">{{item.name}}</div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                list: [{id: 1, name: 'openAPI'}, {id: 2, name: 'SDK中心'}]
            }
        }
    }
</script>
<style lang="scss" scoped>
    .developer {
        width: 360px;
        height: 106px;
        background: #FFFFFF;
        border-radius: 2px;
        margin-bottom: 16px;
        padding: 20px;

        .title {
            width: 100%;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 20px;
            margin-bottom: 14px;
        }
        .loperName{
            width: 114px;
            height: 32px;
            background: #F4F6FA;
            border-radius: 2px;
            margin-right: 16px;
            line-height: 32px;
            font-size: 12px;
            text-align: center;
        }
        .loperName:hover{
            background: #3370FF;
            color: #FFFFFF;
        }
    }
</style>