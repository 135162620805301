<template>
  <div class="container">
    <div
      v-for="(item, index) in buttonList"
      :key="index"
      :class="{ 'is-active': index === activeIndex }"
      class="btn"
      @click="handleClick(index, item)"
    >
      {{ item[label] }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ZaButtonSingle',
    props: {
      value: {
        type: Number,
        default: 0,
      },
      buttonList: {
        type: Array,
        default: () => [],
      },
      label: {
        type: String,
        default: 'name',
      },
    },
    data() {
      return {
        activeIndex: 0,
      }
    },
    computed: {
      _value: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
        },
      },
    },
    mounted() {
      this.$baseEventBus.$on('init', (val) => (this.activeIndex = val))
    },
    methods: {
      handleClick(index, item) {
        this.activeIndex = index
        this.$emit('click', item)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .container {
    width: auto;
    height: 31px;
    display: inline-flex;

    .btn {
      border: 1px solid #d9d9d9;
      font-weight: 300;
      color: #000000;
      text-align: center;
      line-height: 31px;
      padding: 0 15px;
      cursor: pointer;
      font-size: 12px;
      min-width: 70px;
    }

    .is-active {
      border: 1px solid #2468f2;
      background: #1b5deb;
      color: #ffffff;
    }
  }
</style>
