<template>
  <div>
    <navbar />
    <div class="orderPayment">
      <div class="payTest">
        <span>支付</span>
      </div>

      <div class="stayPay">
        <div class="computedMoney">
          <div class="adoutMoney">
            <span>产品金额：</span>
            <span>￥{{ allMoney }}</span>
          </div>
          <div class="punctuation">-</div>
          <div class="adoutMoney">
            <span>优惠券：</span>
            <span>￥{{ concessional }}</span>
          </div>
          <div class="punctuation">=</div>
          <div class="rowMoney">
            <span>应付金额：</span>
            <span>￥{{ actualPay }}</span>
          </div>
        </div>
        <div>
          <div class="title">待支付订单</div>
          <div>
            <occ-table :tableData="tableData" :columObj="columObj" :style-height="'220'" :isOp="columObj.isOp">
              <template #default="{ row }">
                <span @click="handleDetails(row)" class="blue_font">订单详情</span>
              </template>
            </occ-table>
          </div>
        </div>
      </div>
      <div class="selectJuan" v-if="!isPostpaid">
        <div class="computedJuan">
          <div class="adoutMoney">
            <span style="cursor: pointer" @click="handleInfo">兑换优惠卡券</span>
          </div>
          <div>
            <span style="color: red; margin-right: 5px">*</span>
            <span>相应活动申报成功后，如需使用，请先兑换优惠券。</span>
          </div>
        </div>
        <div class="title">优惠券选择</div>
        <el-form :inline="true" :model="pageObj" @submit.native.prevent>
          <el-form-item label="可用券订单:">
            <el-select
              v-model="pageObj.source_code"
              @change="sourceChange($event)"
              :placeholder="trialOption.length ? '请选择优惠券订单' : '当前无可使用优惠券订单'"
              style="width: 200px"
            >
              <el-option v-for="item in trialOption" :key="item.value" :label="item.name" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="当前订单可用券:" class="payMent">
            <el-select
              v-model="pageObj.code"
              popper-class="selectChange"
              @change="codeChange"
              clearable
              :placeholder="newOption.length ? '请选择优惠券' : '当前无可使用优惠券'"
              style="width: 200px"
            >
              <el-option
                v-for="item in newOption"
                :key="item.couponNumber"
                :label="item.name"
                :value="item.couponNumber"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="selectPay" v-if="!isPostpaid">
        <div class="title">选择交易方式</div>
        <el-tabs v-model="payStyle">
          <el-tab-pane v-for="(item, index) in list" :label="item.label" :name="item.name" :key="index" class="imgList">
            <el-radio-group v-model="payType">
              <el-radio :label="val.label" v-for="(val, child) in item.imgList" :key="child">
                <div>
                  <img class="pay_img" :src="val.url" />
                </div>
              </el-radio>
            </el-radio-group>
            <div class="prompt" v-if="payType == 'balance' && balance.balance >= actualPay">
              账户可用余额:
              <span class="avaliBalance">￥{{ balance.balance }}</span>
              ，余额足够支付当前订单，如果您有正在使用中的后付费产品,请保证有足够余额。
            </div>
            <div class="prompt" v-else-if="payType == 'balance' && balance.balance < actualPay">
              账户可用余额:
              <span class="avaliBalance">￥{{ balance.balance }}</span>
              ，余额不足以支付当前订单，请确保余额充足再进行支付，或选择其他交易方式。
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="selectPay" v-if="isPostpaid">
        <div class="postpaid">
          <div>
            <h5>{{ translateTitle('topUp.reminder') }}：</h5>
            <span>
              1.您选购的后付费订单将按照实际使用情况进行扣费，不需要提前支付，请保证当前账户内的余额充足，以防影响服务的正常使用和购买。
            </span>
            <span>
              2.提交订单后，产品将自动开通并展示到您的实例列表中，如您在使用过程中存在任何疑问，可以随时联系相关客服人员，详情请致电：400-018-5113。
            </span>
          </div>
        </div>
      </div>

      <div>
        <div class="bun_fix">
          <div class="endText">
            <el-checkbox v-model="baseForm.agree">
              我已知晓，并同意
              <span @click="openAgreementDialog(false)">线上购买协议</span>
            </el-checkbox>
          </div>

          <div class="endMoney">
            <span>付款金额：</span>
            <span>￥{{ actualPay }}</span>
          </div>
          <!-- :disabled="payType=='balance' && balance.balance < actualPay" -->
          <el-button
            type="primary"
            v-if="!isPostpaid"
            @click="submitPay"
            :disabled="payType == 'balance' && balance.balance < actualPay"
          >
            支付
          </el-button>
          <el-button type="primary" v-else @click="postPay">确认</el-button>
          <!-- <el-button type="primary"  @click="postPay">确认</el-button> -->
        </div>
      </div>
      <div>
        <za-dialog top-title="兑换优惠券" :visible.sync="dialogVisible" width="600px">
          <el-form
            ref="form"
            :disabled="confirmBtnLoading"
            :model="baseForm"
            label-position="right"
            label-width="140px"
            :rules="rules"
          >
            <el-form-item :label="'优惠券号' + '：'" prop="couponNumber">
              <el-input v-model="baseForm.couponNumber" placeholder="请输入兑换优惠券号码" />
            </el-form-item>
            <el-form-item :label="'优惠券类型' + '：'" prop="type">
              <el-select v-model="baseForm.type" placeholder="请选择优惠券类型" style="width: 100%">
                <el-option label="代金券" :value="1"></el-option>
                <el-option label="折扣券" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <za-button :disabled="confirmBtnLoading" :type="buttonTypeObj.GREY" @click="dialogVisible = false">
              取消
            </za-button>
            <za-button :loading="confirmBtnLoading" :type="buttonTypeObj.BLUE" @click="handleSubmitForm">
              提交
            </za-button>
          </div>
        </za-dialog>
        <za-dialog top-title="订单详情" :visible.sync="dialogInfo" width="800px">
          <div class="orderHed">
            <span>交易流水 :</span>
            <span>{{ dingdanNum }}</span>
          </div>
          <occ-table :tableData="InfoData" :columObj="infoObj" :style-height="'calc(100%-10px)'"></occ-table>
          <div class="orderFood">
            <span>总金额 :</span>
            <span class="orderMoney">¥ {{ rowMoney }}</span>
          </div>
          <div slot="footer">
            <za-button :disabled="confirmBtnLoading" :type="buttonTypeObj.GREY" @click="dialogInfo = false">
              取消
            </za-button>
          </div>
        </za-dialog>
      </div>
      <div>
        <za-pay-dialog
          :isPay="true"
          :payDialog="payDialog"
          @click="noneX"
          topTitle="支付完成前请不要关闭此窗口"
        ></za-pay-dialog>
      </div>

      <za-dialog
        top-title="线上购买协议"
        :visible="agreementDialog"
        width="1200px"
        :showClose="false"
        :footState="true"
      >
        <Promise />
        <div slot="footer">
          <el-button :type="buttonTypeObj.BLUE" @click="dialogToPay()" :disabled="popoverVisible">确定</el-button>
        </div>
      </za-dialog>

      <za-dialog top-title="余额不足：" :visible.sync="isWarnDialog" width="800px" :showClose="true">
        <div>
          <div class="payPrompt" style="height: 90px">
            <span style="color: #faad13; margin-bottom: 15px">
              <za-svg-icon style="font-size: 16px; margin-right: 6px" icon-class="za-wanning"></za-svg-icon>
              {{ translateTitle('topUp.reminder') }}:
            </span>
            <span style="color: #555555">
              当前您账户余额已不足，直接购买可能会导致欠费，欠费后您名下的资源将自动停用。
              为避免给您的业务造成损失，建议您及时充值，充值完成后再进行购买。
              若由于欠费导致资源停用，从而给您的业务造成损失，智安云将不承担任何法律责任。
              <span style="color: #3370ff; display: inline; cursor: pointer" @click="reCharge">立即充值</span>
            </span>
          </div>
        </div>
        <div slot="footer">
          <za-button :type="buttonTypeObj.BLUE" @click="closeDialog">确定</za-button>
        </div>
      </za-dialog>
    </div>
  </div>
</template>
<script>
  import Navbar from '@/za/layout/components/Navbar'
  import { sortArr } from '@/utils'
  import { apiExchangeCouponlist } from '@/api/moneyCenter/cardRoll/voucher'
  import { apiCouponAvailableCoupons, apiOrderCreate, apiOrderMergePay, apisubmitPay } from '@/api/business/pay.js'
  import zaPayDialog from '@/za/components/zaPayDialog'
  import { mapGetters } from 'vuex'
  import Promise from './components/promise.vue'
  import { apiIsFirstPurchase } from '@/api/apiUser.js'

  export default {
    components: {
      Navbar,
      zaPayDialog,
      Promise,
    },
    data() {
      return {
        isWarnDialog: false,
        isTopay: false,
        popoverVisible: true,
        agreementDialog: false,
        payDialog: false,
        dialogInfo: false,
        baseForm: {
          couponNumber: '',
          type: '',
          agree: false,
        },
        rules: {
          couponNumber: [
            {
              required: true,
              trigger: 'blur',
              message: '优惠卷号不能为空',
            },
          ],
          type: [
            {
              required: true,
              trigger: 'change',
              message: '请选择优惠券类型',
            },
          ],
        },
        activeName: 'first',
        payStyle: 'zaixian',
        payType: 'balance',
        list: [
          {
            label: '在线支付',
            name: 'zaixian',
            imgList: [
              {
                url: require('@/assets/moneyCenter/yue.png'),
                label: 'balance',
              },
              {
                url: require('@/assets/moneyCenter/weixin.png'),
                label: 'wechat',
              },
              { url: require('@/assets/moneyCenter/zhifu.png'), label: 'alipay' },
            ],
          },
          // {
          //     label: '个人网银',
          //     name: 'geren',
          //     imgList: [{url: require('@/assets/moneyCenter/abc.png'), label: 'unionpay'}]
          // },
          // {
          //     label: '企业网银',
          //     name: 'qiye ',
          //     imgList: [{url: require('@/assets/moneyCenter/abc.png'), label: 'unionpay'}]
          // },
        ],
        pageObj: {
          source_code: '',
          code: '',
        },
        trialOption: [], //可用券订单
        tableData: [],
        option: [], //可用优惠券
        newOption: [], //当前订单可用优惠券
        columObj: {
          // 控制选择框
          selection: false,
          isOp: true,
          width: '300px',
          columnData: [
            {
              text: true,
              prop: 'orderNumber',
              label: '交易流水',
              width: '',
              flag: true,
              align: 'center',
            },
            {
              text: true,
              prop: 'productName',
              label: '产品名称',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              text: true,
              prop: 'config',
              label: '配置列表',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              ownDefined: true,
              prop: 'number',
              label: '数量',
              flag: true,
              width: '',
              align: 'center',
              ownDefinedReturn: (row) => {
                return 'x' + row.number
              },
            },
            {
              text: true,
              prop: 'time',
              label: '购买时长',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              text: true,
              prop: 'tradeNo',
              label: '优惠比例',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              ownDefined: true,
              prop: 'payAt',
              label: '优惠金额',
              flag: true,
              width: '',
              align: 'center',
              ownDefinedReturn: (row) => {
                return row.payAt ? '￥' + row.payAt : '-'
              },
            },
            {
              ownDefined: true,
              prop: 'price',
              label: '总额',
              flag: true,
              color: 'red',
              width: '',
              align: 'center',
              ownDefinedReturn: (row) => {
                return '￥' + row.price
              },
            },
          ],
        },
        InfoData: [],
        infoObj: {
          // 控制选择框
          selection: false,
          isOp: false,
          columnData: [
            {
              text: true,
              prop: 'productName',
              label: '产品名称',
              width: '',
              flag: true,
              align: 'center',
            },
            {
              text: true,
              prop: 'config',
              label: '配置列表',
              flag: true,
              showOverFlow: false,
              width: '200',
              align: 'center',
            },
            {
              ownDefined: true,
              prop: 'number',
              label: '数量',
              flag: true,
              width: '',
              align: 'center',
              ownDefinedReturn: (row) => {
                return 'x' + row.number
              },
            },
            {
              text: true,
              prop: 'time',
              label: '时长',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              ownDefined: true,
              prop: 'price',
              label: '金额',
              flag: true,
              width: '',
              color: 'red',
              align: 'center',
              ownDefinedReturn: (row) => {
                return '￥' + row.price
              },
            },
          ],
        },
        clickIndex: -1,
        dingdanNum: '',
        rowMoney: '',
        allMoney: 0.0,
        discountsMoney: 0,
        saleMoney: 0,
        concessional: 0.0,
        actualPay: 0,
        isPostpaid: false,
        timer: '',
      }
    },
    created() {
      if (this.$route.query.orderId) {
        if (typeof this.$route.query.orderId == 'string') {
          this.getList([this.$route.query.orderId])
        } else {
          this.getList(this.$route.query.orderId)
        }
      }
    },
    async mounted() {
      let res = await apiIsFirstPurchase().finally(() => {})
      // 判断用户是否为第一次支付  如果为第一次支付则弹出线上购买协议；反之则默认不弹出
      if (res.data.isFirst) this.openAgreementDialog(true)
    },
    watch: {
      tableData: {
        handler(newValue) {
          newValue.forEach((item) => {
            if (item.payAt) {
              this.discountsMoney += item.payAt
            }
            if (item.tradeNo) {
              let discountAmount = item.tradeNo.split('%')[0] / 100
              this.saleMoney += item.price - item.price * discountAmount
            }
          })
          this.saleMoney = Number(this.saleMoney.toFixed(2))
          this.concessional = Number(this.discountsMoney) + Number(this.saleMoney)
          this.discountsMoney = 0
          this.saleMoney = 0
          this.actualPay = this.allMoney - this.concessional
          this.actualPay = this.actualPay.toFixed(2)
        },
        immediate: true,
      },
    },
    methods: {
      getList(id) {
        console.log('调用getlist')
        let payOrder = {
          orderNumber: id,
        }
        this.allMoney = 0.0
        this.trialOption = []
        this.onSubmitData(apiOrderMergePay, payOrder, null, false, false, (res) => {
          this.tableData = res.data.orderMergePay
          res.data.orderMergePay.forEach((item) => {
            item.payType === 2 ? (this.isPostpaid = true) : (this.isPostpaid = false)
          })
          this.tableData.forEach((item) => {
            this.trialOption.push({
              name: item.orderNumber,
              value: item.orderNumber,
              types: item.products,
              price: item.price,
            })
            this.allMoney += Number(item.price)
          })
          const totalMoney = this.allMoney.toFixed(2)
          this.onSubmitData(apiCouponAvailableCoupons, { amount: Number(totalMoney) }, null, false, false, (res) => {
            this.option = res.data.discountCoupons
            // console.log(this.option, 'option', '可用券')
            // console.log(this.trialOption, 'trialOption可用券订单')
            if (this.option.length) {
              let resultList = JSON.parse(JSON.stringify(this.trialOption))
              this.pageObj.source_code = resultList[0].value
              this.sourceChange(this.pageObj.source_code)
            } else {
              this.pageObj.source_code = ''
              this.trialOption = []
            }
          })
        })
      },
      //可用券订单下拉框
      sourceChange(val) {
        this.clickIndex = this.trialOption.findIndex((item) => item.value === val)
        let secordArr = [] // 当前订单可用优惠券
        if (this.trialOption[this.clickIndex].types && this.trialOption[this.clickIndex].types.length) {
          // 适用类型数组去重
          this.trialOption[this.clickIndex].types = sortArr(this.trialOption[this.clickIndex].types)
          this.option.forEach((obj) => {
            if (obj.products && this.trialOption[this.clickIndex].price >= obj.condition) {
              let couponPro = new Set(this.trialOption[this.clickIndex].types)
              // 获取当前可用产品优惠券  与  订单中的产品  的交集
              let intersect = obj.products.filter((x) => couponPro.has(x))
              // 如果交集 大于等于  订单中的产品   则代表优惠券该订单可用
              if (intersect.length >= this.trialOption[this.clickIndex].types.length) {
                obj.state = false
                secordArr.push(obj)
              }
            }
          })

          // this.trialOption[this.clickIndex].types.forEach((item) => {
          //   this.option.forEach((obj) => {
          //     if (obj.products && this.trialOption[this.clickIndex].price >= obj.condition) {
          //       let result = obj.products.some((data) => {
          //         //找到相同类型的优惠券
          //         return data === item
          //       })
          //       if (result) {
          //         obj.state = false
          //         secordArr.push(obj)
          //       }
          //     }
          //   })
          // })
          secordArr = sortArr(secordArr)
        } else {
          //如果列表的types为空，则匹配所有优惠券
          secordArr = this.option
        }
        this.trialOption.forEach((item, index) => {
          if (item.clickValue && this.clickIndex !== index) {
            secordArr.forEach((i) => {
              //如果编号等于我选择的编号，则证明已经选择过
              if (i.couponNumber === item.clickValue) {
                i.state = true
              }
            })
          }
        })
        this.pageObj.code = this.trialOption[this.clickIndex].clickValue
          ? this.trialOption[this.clickIndex].clickValue
          : ''
        // 如果优惠卷可用数量不为0
        if (secordArr.length > 0) {
          this.newOption = secordArr
          // 一一对应比较订单和优惠卷，为每个订单都自动选择最优惠的优惠券
          this.compareCoupon(this.tableData, this.option)
        } else {
          this.trialOption = []
          this.pageObj.source_code = ''
        }
      },
      codeChange(data) {
        // console.log(data);
        // console.log(this.clickIndex);
        this.trialOption[this.clickIndex].clickValue = data
        let securities = this.newOption.findIndex((item) => item.couponNumber === data)
        // console.log(this.tableData);
        this.tableData.forEach((item, index) => {
          if (item.orderNumber == this.trialOption[this.clickIndex].value) {
            if (this.newOption[securities] && this.newOption[securities].couponType == '1') {
              item.payAt = this.newOption[securities].amount
              item.tradeNo = ''
              item = JSON.parse(JSON.stringify(item))
              this.tableData.splice(index, 1, item)
            } else if (this.newOption[securities] && this.newOption[securities].couponType == '2') {
              item.tradeNo = this.newOption[securities].rate * 100 + '%'
              item.payAt = ''
              item = JSON.parse(JSON.stringify(item))
              this.tableData.splice(index, 1, item)
            } else {
              item.payAt = ''
              item.tradeNo = ''
              item = JSON.parse(JSON.stringify(item))
              this.tableData.splice(index, 1, item)
            }
          }
        })
      },
      handleInfo() {
        this.dialogVisible = true
      },
      handleDetails(row) {
        this.InfoData = row.configs
        // SSL证书单独增加活动优惠字段
        if (row.productName === 'SSL证书' && this.infoObj.columnData.length != 6) {
          const obj = {
            ownDefined: true,
            prop: 'baiduDiscountPrice',
            label: '活动优惠',
            flag: true,
            width: '',
            color: 'red',
            align: 'center',
            ownDefinedReturn: (row) => {
              return '￥' + row.baiduDiscountPrice
            },
          }
          this.infoObj.columnData[4].label = '单价'
          this.infoObj.columnData.push(obj)
        }
        this.dingdanNum = row.orderNumber
        this.rowMoney = row.price
        this.dialogInfo = true
      },
      noneX() {
        this.payDialog = false
        this.$router.push('/moneyCenter/overview')
      },
      openAgreementDialog(value) {
        this.agreementDialog = true
        this.timer = setTimeout(() => {
          this.popoverVisible = false
        }, 3000)
        this.isTopay = value
      },
      dialogToPay() {
        // if (this.isTopay) {
        //   var myMap = new Map()
        //   let orderSubmit = []
        //   if (this.trialOption.length) {
        //     this.trialOption.forEach((obj) => {
        //       myMap.set(obj.value, obj.clickValue)
        //     })
        //   }
        //   this.tableData.forEach((item) => {
        //     let result = myMap.get(item.orderNumber)
        //     if (result) {
        //       orderSubmit.push({ orderNumber: item.orderNumber, cdkeyNumber: result })
        //     } else {
        //       orderSubmit.push({ orderNumber: item.orderNumber, cdkeyNumber: '' })
        //     }
        //   })
        //   let data = {
        //     orderSubmit,
        //     payType: this.payType,
        //   }
        //   if (this.payType !== 'balance') {
        //     this.payDialog = true
        //     this.onSubmitData(apisubmitPay, data, null, false, false, (res) => {
        //       if (this.payType == 'wechat') {
        //         // console.log(process.env,res.data.content)
        //         window.open(
        //           process.env.VUE_APP_URL +
        //             '/#/wechatPay' +
        //             '?content=' +
        //             res.data.content +
        //             '&businessName=' +
        //             res.data.businessName +
        //             '&createAt=' +
        //             res.data.createAt +
        //             '&goodsName=' +
        //             res.data.goodsName +
        //             '&orderNumber=' +
        //             res.data.orderNumber +
        //             '&amount=' +
        //             res.data.amount +
        //             '&payAdress=' +
        //             '1',
        //           '_blank'
        //         )

        //         // window.open("https://za-console.ioiofast.com/#/wechatPay" + "?content=" + res.data.content + "&businessName=" + res.data.businessName + "&createAt=" + res.data.createAt + "&goodsName=" + res.data.goodsName + "&orderNumber=" + res.data.orderNumber + "&amount=" + res.data.amount+"&payAdress="+'1', '_blank',)
        //         // // window.open("https://localhost:10001/#/wechatPay" + "?content=" + res.data.content + "&businessName=" + res.data.businessName + "&createAt=" + res.data.createAt + "&goodsName=" + res.data.goodsName + "&orderNumber=" + res.data.orderNumber + "&amount=" + res.data.amount+"&payAdress="+'1', '_blank',)
        //         localStorage.setItem('content', res.data.content)
        //       } else {
        //         window.open(res.data.content, '_blank')
        //       }
        //     })
        //   } else {
        //     this.onSubmitData(apisubmitPay, data, null, false, false, (res) => {
        //       if (res.code == 0) {
        //         this.$msgSuccess('余额支付成功')
        //         this.$router.push('/moneyCenter/overview')
        //       }
        //     })
        //   }
        // }
        this.isTopay = false
        this.popoverVisible = true
        this.agreementDialog = false
      },
      /**
       * @description 支付下单函数
       */
      async submitPay() {
        if (this.baseForm.agree) {
          var myMap = new Map()
          let orderSubmit = []
          if (this.trialOption.length) {
            this.trialOption.forEach((obj) => {
              myMap.set(obj.value, obj.clickValue)
            })
          }
          // console.log(myMap);
          // console.log(this.tableData);
          this.tableData.forEach((item) => {
            let result = myMap.get(item.orderNumber)
            // console.log(result);
            if (result) {
              orderSubmit.push({ orderNumber: item.orderNumber, cdkeyNumber: result })
            } else {
              orderSubmit.push({ orderNumber: item.orderNumber, cdkeyNumber: '' })
            }
          })
          let data = {
            orderSubmit,
            payType: this.payType,
          }
          if (this.payType !== 'balance') {
            this.payDialog = true
            this.onSubmitData(apisubmitPay, data, null, false, false, (res) => {
              if (this.payType == 'wechat') {
                // console.log(process.env,res.data.content)
                window.open(
                  process.env.VUE_APP_URL +
                    '/wechatPay' +
                    '?content=' +
                    res.data.content +
                    '&businessName=' +
                    res.data.businessName +
                    '&createAt=' +
                    res.data.createAt +
                    '&goodsName=' +
                    res.data.goodsName +
                    '&orderNumber=' +
                    res.data.orderNumber +
                    '&amount=' +
                    res.data.amount +
                    '&payAdress=' +
                    '1',
                  '_blank'
                )

                // window.open("https://za-console.ioiofast.com/#/wechatPay" + "?content=" + res.data.content + "&businessName=" + res.data.businessName + "&createAt=" + res.data.createAt + "&goodsName=" + res.data.goodsName + "&orderNumber=" + res.data.orderNumber + "&amount=" + res.data.amount+"&payAdress="+'1', '_blank',)
                // // window.open("https://localhost:10001/#/wechatPay" + "?content=" + res.data.content + "&businessName=" + res.data.businessName + "&createAt=" + res.data.createAt + "&goodsName=" + res.data.goodsName + "&orderNumber=" + res.data.orderNumber + "&amount=" + res.data.amount+"&payAdress="+'1', '_blank',)
                localStorage.setItem('content', res.data.content)
              } else {
                window.open(res.data.content, '_blank')
              }
            })
          } else {
            this.onSubmitData(apisubmitPay, data, null, false, false, (res) => {
              if (res.code == 0) {
                this.$msgSuccess('余额支付成功')
                this.$router.push('/moneyCenter/overview')
              }
            })
          }
        } else {
          this.$msgWarning('请阅读并同意购买协议！')
        }
      },

      handleSubmitForm() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let result = {
              cdkeyId: this.baseForm.couponNumber,
              type: this.baseForm.type,
            }
            let res = await apiExchangeCouponlist(result).finally(() => (this.confirmBtnLoading = false))
            if (res.code === 0) {
              this.$msgSuccess('兑换成功')
              this.dialogVisible = false
              if (this.$route.query.orderId) {
                if (typeof this.$route.query.orderId == 'string') {
                  this.getList([this.$route.query.orderId])
                } else {
                  this.getList(this.$route.query.orderId)
                }
              }
            }
          } else {
            return false
          }
        })
      },
      closeDialog() {
        let orderSubmit = []
        this.tableData.forEach((item) => {
          orderSubmit.push({ orderNumber: item.orderNumber, cdkeyNumber: '' })
        })
        let data = {
          orderSubmit,
          payType: 'balance',
        }
        this.onSubmitData(apisubmitPay, data, null, false, false, (res) => {
          if (res.code == 0) {
            this.$msgSuccess('支付成功')
            this.$router.push('/moneyCenter/overview')
          }
        })

        this.isWarnDialog = false
      },
      postPay() {
        if (this.baseForm.agree) {
          let req = {
            orderNumber: this.tableData[0].orderNumber,
          }
          this.onSubmitData(apiOrderCreate, req, null, false, false, (res) => {
            console.log(res.data.tips)
            if (res.data.tips === '') {
              this.closeDialog()
            } else {
              this.isWarnDialog = true
            }
          })
        } else {
          this.$msgWarning('请阅读并同意购买协议！')
        }
      },
      reCharge() {
        this.$router.push('/moneyCenter/expenseManagement/topUp')
      },
      // 对所有订单进行优惠卷自动选择（选择优惠力度最大的）
      compareCoupon(orderList, couponList) {
        let tempCouList = [] //循环遍历时，当前订单的可用券
        // 对所有订单进行循环遍历
        orderList.forEach((item, index) => {
          // 对优惠券循环遍历  判断所使用产品的类型  是否  包含所有产品类型
          couponList.forEach((obj) => {
            if (obj.products && item.price >= obj.condition) {
              let couponPro = new Set(item.products)
              // 获取当前可用产品优惠券  与  订单中的产品  的交集
              let intersect = obj.products.filter((x) => couponPro.has(x))
              // 如果交集 大于等于  订单中的产品   则代表优惠券该订单可用
              if (intersect.length >= couponPro.size) {
                obj.state = false
                tempCouList.push(obj)
              }
            }
          })
          // 对可用优惠券 进行价格计算  并添加 价格 属性
          tempCouList.forEach((i) => {
            if (i.couponType === 1) {
              i.actualPay = item.price - i.amount
            } else {
              i.actualPay = item.price * i.rate
            }
          })
          tempCouList.sort(this.compare('actualPay')) //对订单可用优惠卷进行排序
          // console.log(tempCouList,"当前订单可用优惠券")
          //第一个订单默认选择最优惠的优惠券
          if (index === 0) {
            this.pageObj.code = tempCouList[0].couponNumber
            this.trialOption[this.clickIndex].clickValue = tempCouList[0].couponNumber
          }
          // 判断  当前的订单号  与  列表数据的订单号  是否相同
          if (item.orderNumber === this.tableData[index].orderNumber) {
            if (tempCouList[0].couponType === 1) {
              item.payAt = tempCouList[0].amount
              item.tradeNo = ''
              item = JSON.parse(JSON.stringify(item))
              this.tableData.splice(index, 1, item)
            } else if (tempCouList[0].couponType === 2) {
              item.payAt = ''
              item.tradeNo = tempCouList[0].rate * 100 + '%'
              item = JSON.parse(JSON.stringify(item))
              this.tableData.splice(index, 1, item)
            } else {
              item.payAt = ''
              item.tradeNo = ''
              item = JSON.parse(JSON.stringify(item))
              this.tableData.splice(index, 1, item)
            }
          }
        })
      },
      // 比较优惠金额进行排序（由小到大）
      compare(propertyName) {
        return function (object1, object2) {
          var value1 = object1[propertyName]
          var value2 = object2[propertyName]
          if (value2 < value1) {
            return 1
          } else if (value2 > value1) {
            return -1
          } else {
            return 0
          }
        }
      },
    },
    computed: {
      ...mapGetters({
        balance: 'user/balance',
      }),
    },
    // 优化：清除定时器  timer
    beforeDestroy() {
      clearTimeout(this.timer)
    },
  }
</script>
<style lang="scss" scoped>
  @import '~@/za/styles/mixin.scss';

  .pay_img {
    width: 160px;
    height: 70px !important;
  }

  .orderPayment {
    width: 90%;
    margin: auto;

    .title {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: bold;
      color: #000000;
      margin-bottom: 20px;
    }

    .payTest {
      background: #ffffff;
      padding: 20px;
      height: 62px;
      border-radius: 2px;
      margin: 20px 0;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
    }

    .prompt {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      position: absolute;
      top: 70%;
    }
  }

  .stayPay {
    position: relative;
    margin: 20px auto;
    background: #ffffff;
    padding: 20px;

    .computedMoney {
      position: absolute;
      display: flex;
      right: 20px;
      top: 30px;
      align-items: center;

      .adoutMoney {
        margin-right: 5px;
        margin-left: 5px;
        font-size: 14px;

        span:last-child {
          color: #999999;
        }
      }

      .rowMoney {
        font-size: 14px;

        span:last-child {
          color: red;
        }
      }
    }
  }

  .selectJuan {
    height: 138px;
    margin: 20px auto;
    background: #ffffff;
    padding: 20px;
    position: relative;

    .computedJuan {
      position: absolute;
      display: flex;
      left: 10%;
      font-size: 12px;
      color: #999999;

      .adoutMoney {
        margin-right: 20px;
        color: #1b5deb;
        z-index: 99;
      }
    }
  }

  .punctuation {
    margin-left: 5px;
    margin-right: 5px;
  }

  .selectPay {
    margin: 20px auto;
    background: #ffffff;
    padding: 20px;
    position: relative;
    margin-bottom: 70px;
  }

  .imgList {
    display: flex;
    height: 190px;
    background: #f4f6fa;
    padding: 30px;
    border-radius: 2px;
  }

  .selectDiv {
    height: 340px;
    background: #f4f6fa;
    border-radius: 2px;
  }

  .selectChange {
    /*background: blue !important;*/
    .el-select-dropdown__item.hover {
      position: relative;

      & ::after {
        content: '✔';
        display: block;
        height: 0px;
        width: 0px;
        position: absolute;
        bottom: 0;
        right: 0;
        color: #fff;
        /**对号大小*/
        font-size: 10px;
        line-height: 8px;
        border: 10px solid;
        border-color: transparent #4884ff #4884ff transparent;
      }
    }
  }

  .orderHed {
    margin-bottom: 20px;
  }

  .orderFood {
    margin-top: 20px;
    text-align: right;

    .orderMoney {
      color: red;
    }
  }

  ::v-deep {
    .el-radio__input {
      display: none;
    }

    .is-checked {
      position: relative;
      border: 1px solid #336ffe;
      height: 72px;

      &.is-checked::after {
        content: '✔';
        display: block;
        height: 0px;
        width: 0px;
        position: absolute;
        bottom: 0;
        right: 0;
        color: #fff;
        /**对号大小*/
        font-size: 12px;
        line-height: 8px;
        border: 10px solid;
        border-color: transparent #4884ff #4884ff transparent;
      }
    }

    .el-radio__label {
      padding: 0;
    }

    .el-radio-group {
      display: flex;
    }
  }

  .avaliBalance {
    color: red;
  }

  .postpaid {
    width: 100%;
    background: #fffbe6;
    border-radius: 2px;
    border: 1px solid #ffe58f;
    padding: 15px 15px;

    h5 {
      @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
      margin: 0px 0px 10px 0px;
    }

    span {
      display: block;
      font-size: 12px;
      margin-bottom: 8px;
      color: #555555;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
  }

  .bun_fix {
    padding: 16px 10px 16px 65%;
    position: fixed;
    bottom: 0px;
    width: 100%;
    right: 0;
    height: 64px;
    background: #ffffff;
    z-index: 99;
    box-shadow: 0px -9px 28px 8px rgba(0, 0, 0, 0.05);
    display: flex;

    .endMoney {
      margin-right: 20px;
      margin-top: 5px;

      span:last-child {
        color: red;
      }
    }

    .endText {
      margin-right: 10px;
      margin-top: 5px;

      span:last-child {
        color: #3370ff;
      }

      ::v-deep {
        .el-radio__input {
          display: none;
        }

        .is-checked {
          position: static;
          border: none;
          height: auto;

          &.is-checked::after {
            content: none;
            // display: block;
            // height: 0px;
            // width: 0px;
            // position: absolute;
            // bottom: 0;
            // right: 0;
            // color: #fff;
            // /**对号大小*/
            // font-size: 12px;
            // line-height: 8px;
            // border: 10px solid;
            // border-color: transparent #4884ff #4884ff transparent;
          }
        }

        .el-radio__label {
          padding: 0;
        }

        .el-radio-group {
          display: none;
        }
      }
    }
  }

  .payPrompt {
    width: 100%;
    height: 174px;
    background: #fffbe6;
    border-radius: 2px;
    border: 1px solid #ffe58f;
    padding: 20px 20px;

    span {
      height: 17px;
      display: block;
      font-size: 12px;
      line-height: 17px;
      margin-bottom: 11px;
      color: #eb2f2f;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
  }
</style>