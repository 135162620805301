<template>
    <div class="offLine">
        <div class="main">
            <div class="company">
                <div>{{translateTitle('topUp.topUpAccount')+':'}}</div>
                <div class="companyInfo">深圳智安网络</div>
            </div>
            <div class="cardInfo">
                <div>{{translateTitle('topUp.moneyInfo')+':'}}</div>
                <div >
                    <ul>
                        <li>
                            <span>开户名称</span>
                            <span>收款银行</span>
                            <span>收款账号</span>
                        </li>
                        <li>
                            <span>深圳智安网络</span>
                            <span>招商银行深圳支行</span>
                            <span>11090 73168 10601 00030 36237</span>
                        </li>
                    </ul>
                </div>

            </div>
        </div>

        <div class="prompt">
            <h5>{{translateTitle('topUp.reminder')}}</h5>
            <p>
                <span>{{translateTitle('topUp.sixth')}}</span>
                <span class="redText">{{translateTitle('topUp.sixthOne')}}</span>
            </p>
            <p> {{translateTitle('topUp.seventh')}}</p>
            <p>
                <span>{{translateTitle('topUp.eighth')}}</span>
                <span class="redText">{{translateTitle('topUp.eighthOne')}}</span>
            </p>
        </div>
    </div>
</template>
<script>
    import {translateTitle} from '@/utils/i18n'
    export default {
        name: 'OnlinePay',
        data() {
            return {

            }
        },
        methods: {

        }
    }
</script>
<style lang="scss" scoped>

    .offLine{

        border-radius: 2px;
        .main{
            padding: 40px 30px;
            height: 248px;
            background: #F4F6FA;
            .company{
                display: flex;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #000000;
                .companyInfo{
                    margin-left: 20px;
                }
            }
            .cardInfo{
                display: flex;
                margin-top: 30px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #000000;
                div:first-child{
                    width: 5%;
                }
                div:last-child{
                    width: 95%;
                    ul{
                        list-style-type:none;
                        margin: 0;
                        padding: 0;
                        li{
                            height: 40px;
                            line-height: 40px;
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr;
                            text-align: center;
                        }
                        li:first-child{
                            background: #E7EBF3;
                        }
                        li:last-child{
                            background: #FFFFFF;
                        }
                    }
                }

            }
        }

        .prompt {
            width: 100%;
            height: 133px;
            padding: 15px 20px;
            margin-top: 40px;
            h5 {
                margin: 0px 0px 10px 0px;
            }
            p {
                display: block;
                font-size: 12px;
                margin-bottom: 12px;
                color: #000;
                .redText{
                    color: red;
                }
            }
        }
    }


</style>