var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "register_main" },
    [
      _c(
        "el-row",
        [
          _c("div", { staticClass: "title" }, [_vm._v("账户注册")]),
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "register",
              attrs: {
                disabled: _vm.confirmBtnLoading,
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "right",
              },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "username" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.translateTitle(
                            "register.placeAccount"
                          ),
                          readonly: _vm.confirmBtnLoading,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.clangeWaringDesc()
                          },
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            return _vm.trim("username")
                          },
                        },
                        model: {
                          value: _vm.form.username,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "username",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.username",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "password" } },
                    [
                      _c("el-input", {
                        ref: "password",
                        attrs: {
                          placeholder: _vm.translateTitle("register.placePwd"),
                          readonly: _vm.confirmBtnLoading,
                          type: _vm.passwordType,
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            return _vm.trim("password")
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            _vm.passwordType === "password"
                              ? {
                                  key: "suffix",
                                  fn: function () {
                                    return [
                                      _c("za-svg-icon", {
                                        attrs: {
                                          "class-name": "svg-suffix",
                                          "icon-class": "za-eye-close",
                                        },
                                        on: { click: _vm.handlePassword },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : {
                                  key: "suffix",
                                  fn: function () {
                                    return [
                                      _c("za-svg-icon", {
                                        attrs: {
                                          "class-name": "svg-suffix",
                                          "icon-class": "za-eye-open",
                                        },
                                        on: { click: _vm.handlePassword },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.form.password,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "password",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.password",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "confirmPassword" } },
                    [
                      _c("el-input", {
                        ref: "newPasswordType",
                        attrs: {
                          placeholder: _vm.translateTitle(
                            "register.placeConfirmPwd"
                          ),
                          readonly: _vm.confirmBtnLoading,
                          type: _vm.newPasswordType,
                          maxlength: "30",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            return _vm.trim("confirmPassword")
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            _vm.newPasswordType === "password"
                              ? {
                                  key: "suffix",
                                  fn: function () {
                                    return [
                                      _c("za-svg-icon", {
                                        attrs: {
                                          "class-name": "svg-suffix",
                                          "icon-class": "za-eye-close",
                                        },
                                        on: { click: _vm.handleNewPassword },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : {
                                  key: "suffix",
                                  fn: function () {
                                    return [
                                      _c("za-svg-icon", {
                                        attrs: {
                                          "class-name": "svg-suffix",
                                          "icon-class": "za-eye-open",
                                        },
                                        on: { click: _vm.handleNewPassword },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.form.confirmPassword,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "confirmPassword",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.confirmPassword",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "phone" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.translateTitle(
                            "register.placeMobile"
                          ),
                          readonly: _vm.confirmBtnLoading,
                          maxlength: "11",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.clangeWaringDesc()
                          },
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            return _vm.trim("phone")
                          },
                        },
                        model: {
                          value: _vm.form.phone,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "phone",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "smsCode" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入验证码",
                              maxlength: "6",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.clangeWaringDesc()
                              },
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.handleSub()
                              },
                            },
                            model: {
                              value: _vm.form.smsCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "smsCode", $$v)
                              },
                              expression: "form.smsCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 1 } }, [_vm._v(" ")]),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "sendInfo",
                          class: { grey: _vm.isGrey, blue: !_vm.isGrey },
                          attrs: { disabled: _vm.dis },
                          on: { click: _vm.sendData },
                        },
                        [
                          _vm.telState == 1
                            ? _c("span", [_vm._v("发送验证码")])
                            : _vm._e(),
                          _vm.telState == 2
                            ? _c("span", [_vm._v(_vm._s(_vm.count) + "秒重试")])
                            : _vm._e(),
                          _vm.telState == 3
                            ? _c("span", [_vm._v("重新发送")])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", [
                _vm.waringDescState
                  ? _c("span", { staticClass: "red-font" }, [
                      _vm._v(_vm._s(_vm.waringDesc)),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "el-col",
                [
                  _c("el-checkbox", {
                    staticClass: "confirmCheck",
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.submitForm()
                      },
                    },
                    model: {
                      value: _vm.checked,
                      callback: function ($$v) {
                        _vm.checked = $$v
                      },
                      expression: "checked",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "clause",
                      staticStyle: { color: "#999999" },
                    },
                    [_vm._v("我已阅读并同意")]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "toclause",
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/protocal/registerPrototal")
                        },
                      },
                    },
                    [_vm._v("服务协议，")]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "toclause",
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/protocal/policy")
                        },
                      },
                    },
                    [_vm._v("隐私条款，")]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "toclause",
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/protocal/onlineOrder")
                        },
                      },
                    },
                    [_vm._v("线上订购协议")]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c(
                    "za-button",
                    {
                      staticClass: "button",
                      attrs: { loading: _vm.confirmBtnLoading, size: 14 },
                      on: { click: _vm.handleSub },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.translateTitle("btn.register")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("el-col", [
                _c(
                  "p",
                  { staticClass: "login-btn", on: { click: _vm.handleClose } },
                  [
                    _vm._v(" 已有账号, "),
                    _c("span", { staticStyle: { color: "#1b5deb" } }, [
                      _vm._v("立即登录"),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                width: "25%",
                title: "验证",
                visible: _vm.innerVisible,
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.innerVisible = $event
                },
              },
            },
            [
              _vm.showVerity
                ? _c("za-verify", {
                    ref: "verify",
                    attrs: { selectKey: _vm.dialogType },
                    on: { verifySuccess: _vm.success },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }