const cli = require('./cli.config')
const setting = require('./setting.config')
const network = require('./net.config')
const loading = require('./loading.config')
module.exports = {
  ...cli,
  ...setting,
  ...network,
  ...loading,
}
