<template>
  <div>
    <el-card class="history" v-za-loading="isLoading">
      <div class="title">
        <div>开票历史</div>
        <!--                <div @click="pathInfo"  class="record">开票记录</div>-->
      </div>

      <div class="occ-form">
        <za-query-form>
          <za-query-form-left-panel :span="24">
            <el-form :inline="true" :model="pageObj" @submit.native.prevent>
              <el-form-item :label="translateTitle('askfor.timer') + '：'">
                <el-date-picker
                  v-model="timeValue"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="datePickEnd"
                ></el-date-picker>
              </el-form-item>
              <!-- <el-form-item :label="translateTitle('askfor.invoice') + '：'" label-width="100px">
                  <el-input v-model="pageObj.invoicesNumber"
                            :placeholder="translateTitle('placeTxt') + translateTitle('askfor.invoice')"/>
              </el-form-item> -->
              <el-form-item>
                <za-button :icon="buttonIconObj.SEARCH" @click="handleQuery">
                  {{ translateTitle('btn.query') }}
                </za-button>
                <za-button :type="buttonTypeObj.GREY" @click="handleResetQuery">
                  {{ translateTitle('btn.reset') }}
                </za-button>
              </el-form-item>
            </el-form>
          </za-query-form-left-panel>
        </za-query-form>
      </div>
      <div>
        <div class="occ-table">
          <occ-table :tableData="tableData" :columObj="columObj" :isOp="columObj.isOp" @select="result">
            <template #default="{ row }">
              <!-- <span class="blue_font" @click="handleDetails(row)">详情</span>
              <span class="blue_font" v-if="row.invoicesState === '已开票' && row.medium === '电子发票'" @click="handleRefund(row)">退票</span>
              <span class="blue_font" v-if="getTimeFormat(row.invoicesAt) + 86400 < currentTime && row.medium === '纸质发票'" @click="revocation(row)">撤销申请</span> -->
              <div class="funBtn">
                <el-button type="text" @click="handleDetails(row)">详情</el-button>
                <el-button
                  type="text"
                  :disabled="!(row.invoicesState === '已开票' && row.medium === '电子发票')"
                  @click="handleRefund(row)"
                >
                  退票
                </el-button>
                <el-button
                  type="text"
                  :disabled="getTimeFormat(row.invoicesAt) + 86400 < currentTime || row.invoicesState !== '待处理'"
                  @click="revocation(row)"
                >
                  撤销申请
                </el-button>
              </div>
            </template>
          </occ-table>
        </div>
        <pagination :limit.sync="pageObj.pageSize" :page.sync="pageObj.pageNo" :total="total" @pagination="getList" />
      </div>

      <!--      发票详情-->
      <za-dialog top-title="发票详情" :visible.sync="dialogTicket" width="600px">
        <div class="ticketInfo">
          <el-form
            ref="form"
            :disabled="confirmBtnLoading"
            :model="baseForm"
            label-position="right"
            label-width="140px"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item :label="'发票抬头' + '：'">
                  <span class="tips">{{ baseForm.invoicesHead || '暂无' }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="'发票金额' + '：'">
                  <span class="tips">￥{{ baseForm.invoicesAmount || '暂无' }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="'发票类型' + '：'">
                  <span class="tips">{{ baseForm.invoicesType || '暂无' }}</span>
                </el-form-item>
              </el-col>
              <!--              <el-col :span="12">-->
              <!--                <el-form-item :label="'发票介质' + '：'">-->
              <!--                  <span class="tips">{{ baseForm.medium || '暂无' }}</span>-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
            </el-row>
            <el-row>
              <!-- <el-col :span="12">
                  <el-form-item :label="'发票号码' + '：'">
                      <span class="tips">{{baseForm.invoicesNumber||'暂无'}}</span>
                  </el-form-item>
              </el-col> -->
              <el-col :span="12">
                <el-form-item :label="'发票状态' + '：'">
                  <span class="tips" :style="{ color: baseForm.invoicesState == '已奇出' ? 'green' : '' }">
                    {{ baseForm.invoicesState || '暂无' }}
                  </span>
                </el-form-item>
              </el-col>
            </el-row>
            <!--            <el-row>-->
            <!--              <el-col :span="12">-->
            <!--                <el-form-item :label="'邮寄信息' + '：'">-->
            <!--                  <span class="tips">-->
            <!--                    {{ baseForm.province + baseForm.city + baseForm.area + baseForm.addressInfo || '暂无' }}-->
            <!--                  </span>-->
            <!--                </el-form-item>-->
            <!--              </el-col>-->
            <!--              <el-col :span="12">-->
            <!--                <el-form-item :label="'物流信息' + '：'" v-if="baseForm.addressInfo == '已邮寄'">-->
            <!--                  <div class="log">-->
            <!--                    <el-tooltip class="item" effect="light" content="Bottom Left 提示文字" placement="bottom-start">-->
            <!--                      <span>查看物流</span>-->
            <!--                    </el-tooltip>-->
            <!--                  </div>-->
            <!--                </el-form-item>-->
            <!--              </el-col>-->
            <!--            </el-row>-->
          </el-form>
        </div>
      </za-dialog>
      <za-dialog top-title="退票申请" :visible.sync="dialogVisible" width="600px">
        <div>
          <div class="prompt">
            <span style="margin-left: 16px">
              <za-svg-icon icon-class="za-wanning"></za-svg-icon>
            </span>
            <span v-if="mediumType === 1">
              温馨提示: 退票申请一旦提交后无法撤回，且需要您承担邮寄费用，请谨慎操作！
            </span>
            <span v-if="mediumType === 2">
              温馨提示: 请您在发起退票之前，先在邮箱里查找相应的发票号码和代码，并输入到系统中，以确保退票操作能成功进行
              ！
            </span>
          </div>
          <el-form
            ref="form"
            :disabled="confirmBtnLoading"
            :model="returnForm"
            label-position="right"
            label-width="140px"
            :rules="rules"
          >
            <el-row>
              <el-col :span="24">
                <el-form-item :label="'退票金额' + '：'">
                  <span class="refundMoney">{{ returnForm.money + '元' || '' }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item :label="'退票原因' + '：'" prop="returnType">
                  <el-select v-model="returnForm.returnType">
                    <el-option v-for="item in serverMsgTypeOption" :key="item.id" :label="item.name" :value="item.id" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item :label="'材料要求' + '：'" prop="mobile" v-if="mediumType === 1">
                  <div class="requirement">
                    <div>
                      <span>
                        请将
                        <span style="font-size: 14px; font-weight: 500">发票原件</span>
                        邮寄给
                      </span>
                    </div>
                    <div class="requireMain">
                      <div>
                        <span>收件人：</span>
                        <span>张静</span>
                      </div>
                      <div>
                        <span>联系电话：</span>
                        <span>13510781355</span>
                      </div>
                      <div>
                        <span>联系地址：</span>
                        <span>深圳市南山区南山街道阳光科创中心B座2207</span>
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item :label="'发票号码' + '：'" prop="fphm" v-if="mediumType === 2">
              <el-input placeholder="请填写发票号码" v-model="returnForm.fphm"></el-input>
            </el-form-item>
            <el-form-item :label="'发票代码' + '：'" prop="fpdm" v-if="mediumType === 2">
              <el-input placeholder="请填写发票代码" v-model="returnForm.fpdm"></el-input>
            </el-form-item>
            <el-form-item :label="'退票快递公司' + '：'" prop="textarea" v-if="mediumType === 1">
              <el-input placeholder="请填写快递公司" v-model="returnForm.textarea"></el-input>
            </el-form-item>
            <el-form-item :label="'退票快递单号' + '：'" prop="textarea" v-if="mediumType === 1">
              <el-input placeholder="请填写寄出的快递单号" v-model="returnForm.textarea"></el-input>
            </el-form-item>
            <el-col :span="24">
              <el-form-item :label="'退票说明' + '：'" prop="desc">
                <el-input
                  v-model="returnForm.desc"
                  type="textarea"
                  :rows="4"
                  placeholder="详细的退票原因说明，100字以内"
                  maxlength="100"
                  show-word-limit
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form>
        </div>
        <div slot="footer">
          <za-button :disabled="confirmBtnLoading" :type="buttonTypeObj.GREY" @click="dialogVisible = false">
            {{ translateTitle('btn.cancel') }}
          </za-button>
          <za-button :loading="confirmBtnLoading" :type="buttonTypeObj.BLUE" @click="handleSubmitForm">
            {{ translateTitle('btn.submit') }}
          </za-button>
        </div>
      </za-dialog>
    </el-card>
  </div>
</template>
<script>
  import { apiCancel, apiHistoryList, apiReturnList } from '@/api/moneyCenter/invoiceManagement/askfor'
  import { reqHistoryInfoObj } from '@/za/model/reqModel'
  import eventBusEvent from '@/utils/eventBusEvent'

  export default {
    data() {
      return {
        total: 0,
        pageObj: {
          //分页对象
          pageNo: 1,
          pageSize: 10,
          startAt: 0,
          invoicesNumber: '',
          invoicesType: [],
          invoicesState: [],
        },
        timeValue: '',
        active: 1,
        baseForm: Object.assign({}, reqHistoryInfoObj),
        refundForm: {
          serverId: '',
          textarea: '',
        },
        tableData: [],
        columObj: {
          // 控制选择框
          selection: false,
          isOp: true,
          width: '300px',
          columnData: [
            {
              text: true,
              prop: 'invoicesAt',
              label: '申请开票时间',
              width: '',
              flag: true,
              align: 'center',
            },
            {
              text: true,
              prop: 'invoicesHead',
              label: '发票抬头',
              flag: true,
              width: '',
              align: 'center',
            },
            {
              filter: true,
              text: true,
              prop: 'invoicesType',
              label: '发票类型',
              flag: true,
              width: '',
              align: 'center',
              filters: [
                { text: '个人数电普通发票  ', value: '1' },
                { text: '组织数电普通发票  ', value: '2' },
                { text: '企业数电普通发票', value: '3' },
                { text: '企业数电专用发票', value: '4' },
              ],
            },
            // {
            //   text: true,
            //   prop: 'medium',
            //   label: '发票介质',
            //   flag: true,
            //   width: '',
            //   align: 'center',
            // },
            // {
            //     text: true,
            //     prop: "invoicesNumber",
            //     label: "发票号码",
            //     flag: true,
            //     width: "",
            //     align: "center",
            // },
            {
              ownDefined: true,
              prop: 'invoicesAmount',
              label: '发票金额',
              flag: true,
              width: '',
              align: 'center',
              ownDefinedReturn: (row, $index) => {
                return '￥' + row.invoicesAmount
              },
            },
            {
              filter: true,
              text: true,
              prop: 'invoicesState',
              label: '状态',
              flag: true,
              width: '',
              align: 'center',
              filters: [
                { text: '待处理  ', value: '1' },
                { text: '开票成功', value: '2' },
                { text: '开票失败', value: '3' },
                { text: '已取消', value: '4' },
                { text: '退票成功', value: '5' },
                { text: '退票失败', value: '6' },
                { text: '退票中', value: '7' },
                { text: '开票中', value: '8' },
              ],
            },
          ],
        },
        dialogTicket: false,
        returnForm: {
          money: '',
          returnType: null,
          invoicesId: '',
          desc: '',
          fpdm: '',
          fphm: '',
        },
        rules: {
          returnType: [{ required: true, message: '请选择退票原因', trigger: 'change' }],
          desc: [{ required: true, message: '请输入详细退票原因', trigger: 'blur' }],
          fpdm: [{ required: true, message: '请输入发票代码', trigger: 'blur' }],
          fphm: [{ required: true, message: '请输入发票号码', trigger: 'blur' }],
          textarea: [{ required: true, message: '请输入详细退票原因', trigger: 'blur' }],
        },
        serverMsgTypeOption: [
          { id: 1, name: '发票类型错误' },
          { id: 2, name: '退货退票' },
          { id: 3, name: '税号，开户行错误' },
          { id: 4, name: '主体工商名称变更' },
          { id: 5, name: '发票金额有误' },
        ],
        // 1 纸质发票 2 电子发票
        mediumType: 1,
        currentTime: null,
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.onSubmitData(apiHistoryList, this.pageObj, null, false, false, (res) => {
          this.total = res.data.total
          this.currentTime = Date.parse(new Date()) / 1000
          if (res.data.list && res.data.list.length) {
            this.tableData = res.data.list
          } else {
            this.tableData = []
          }
        })
      },
      datePickEnd(date) {
        if (date) {
          this.pageObj.startAt = this.$changeTimeStamp(date[0])
          this.pageObj.endAt = this.$changeTimeStamp(date[1]) + 3600 * 24 - 1
        } else {
          this.pageObj.startAt = 0
          delete this.pageObj.endAt
        }
        this.getList()
      },
      handleQuery() {
        this.getList()
      },
      // pathInfo() {
      //   this.$router.push('/moneyCenter/invoiceManagement/askfor/info')
      // },
      handleResetQuery() {
        this.timeValue = ''
        this.resetListQuery(this.pageObj)
        this.getList()
      },

      result(val) {
        //判断是发票类型下拉还是状态下拉
        if (val.invoicesType) {
          this.pageObj.invoicesType = val.invoicesType
          this.getList()
        } else if (val.invoicesState) {
          this.pageObj.invoicesState = val.invoicesState
          this.getList()
        }
      },
      handleDetails(row) {
        this.baseForm = row
        this.dialogTicket = true
      },
      // handleRefund(row) {
      //   row.medium === '纸质发票' ? (this.mediumType = 1) : (this.mediumType = 2)
      //   this.returnForm.money = row.invoicesAmount
      //   this.returnForm.invoicesId = row.id
      //   this.dialogVisible = true
      // },
      handleSubmitForm() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let submitForm
            submitForm = {
              invoicesId: this.returnForm.invoicesId,
              returnType: this.returnForm.returnType,
              desc: this.returnForm.desc,
              fpdm: this.returnForm.fpdm,
              fphm: this.returnForm.fphm,
            }
            let res = await apiReturnList(submitForm).finally(() => (this.confirmBtnLoading = false))
            if (res.code === 0) {
              // this.$msgSuccess('退票成功')
              this.dialogVisible = false
              this.getList()
            } else {
              this.dialogVisible = false
              this.getList()
            }
          } else {
            return false
          }
        })
      },
      /**
       * @description 撤销开票
       * @param row
       */
      revocation(row) {
        this.$baseConfirm('您确认撤销开票申请吗？', null, () => {
          const data = { invoicesId: row.id }
          this.onDelete(apiCancel, data, '撤销开票成功')
          this.getList()
          // 同步更新可开票金额
          this.$baseEventBus.$emit(eventBusEvent.UpdateBillAmount)
        })
      },
      /**
       * @description 转换时间戳
       * @param timeS
       * @returns {number}
       */
      getTimeFormat(timeS) {
        if (timeS) {
          return new Date(timeS).getTime() / 1000
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import '~@/za/styles/mixin.scss';

  .title {
    @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
    height: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .record {
      cursor: pointer;
    }
  }

  .log {
    color: #1b5deb;
    font-size: 12px;
  }

  .ticketInfo {
    margin-top: 30px;
  }

  .prompt {
    height: 60px;
    background: #fffbe6;
    border-radius: 2px;
    border: 1px solid #ffe58f;
    padding: 15px 15px;
    margin-bottom: 30px;
  }

  .requirement {
    font-size: 12px;
    color: #000000;

    .requireMain {
      background: #c2c2c2;
      padding: 10px;
    }
  }

  .refundMoney {
    color: red;
    font-size: 12px;
  }

  ::v-deep {
    .occ-form .el-input {
      width: 300px;
    }

    .el-step__title {
      font-size: 14px;
    }
  }

  .funBtn {
    ::v-deep .el-button--text {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #3370ff;
      margin-right: 10px;
    }

    ::v-deep .el-button.is-disabled.el-button--text {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: gray;
      margin-right: 10px;
    }
  }
</style>