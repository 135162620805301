var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wg-cap-btn", style: _vm.style }, [
    _c(
      "div",
      { staticClass: "wg-cap-btn__inner", class: _vm.activeClass },
      [
        _c(
          "el-popover",
          {
            attrs: { placement: "top", trigger: "click" },
            model: {
              value: _vm.popoverVisible,
              callback: function ($$v) {
                _vm.popoverVisible = $$v
              },
              expression: "popoverVisible",
            },
          },
          [
            _c("go-captcha", {
              attrs: {
                width: "300px",
                height: "240px",
                "max-dot": _vm.maxDot,
                "image-base64": _vm.imageBase64,
                "thumb-base64": _vm.thumbBase64,
              },
              on: {
                close: _vm.handleCloseEvent,
                refresh: _vm.handleRefreshEvent,
                confirm: _vm.handleConfirmEvent,
              },
              model: {
                value: _vm.popoverVisible,
                callback: function ($$v) {
                  _vm.popoverVisible = $$v
                },
                expression: "popoverVisible",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }