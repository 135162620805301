<template>
  <div class="proportion">
    <div class="proportion_item">
      <p>新客返佣比</p>
      <p class="value">{{ popularizeInfo.newCustomer }}%</p>
    </div>
    <div class="proportion_item">
      <p>老客返佣比</p>
      <p class="value">{{ popularizeInfo.oldCustomer }}%</p>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {}
    },
    computed: {
      ...mapGetters({
        popularizeInfo: 'user/popularizeInfo',
      }),
    },
  }
</script>
<style lang="scss" scoped>
  .proportion {
    width: 35%;
    padding: 20px;
    height: 120px;
    background: #ffffff;
    margin-bottom: 20px;
    display: flex;
    font-size: 14px;
    font-weight: 600;

    .proportion_item {
      width: 70px;
    }

    div:last-child {
      margin-left: 170px;
    }

    .value {
      font-weight: bold;
      font-size: 16px;
    }
  }
</style>