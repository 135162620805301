var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dropdown",
    _vm._b(
      {
        attrs: { placement: "bottom", trigger: "click" },
        on: { command: _vm.handleClick },
      },
      "el-dropdown",
      _vm.$attrs,
      false
    ),
    [
      _c("div", { staticClass: "menu-btn" }, [_vm._v(_vm._s(_vm.btnTxt))]),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }