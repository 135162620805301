import request from '@/utils/request'
import { CONSOLE_LOG, METHOD} from '@/api/api.config'



/**
 * @description 登录日志接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apilogInfo(data) {
    return request({
        url: CONSOLE_LOG,
        method: METHOD.GET,
        params: data,
    })
}
