<template>
  <div class="cardCenter" v-za-loading="isLoading">
    <div class="card_mottom">
      <div class="title">结算概览</div>
      <el-row>
        <el-col :span="5" v-for="(item, index) in amountArr" :key="index">
          <div class="cardUl">
            <div class="title">{{ item.label }}</div>
            <div class="operation">
              <div>
                <span>￥</span>
                <span>{{ item.value }}</span>
                <el-button
                  v-if="item.name === 'toTransfered'"
                  type="primary"
                  class="applyFor"
                  @click="handleClick"
                  :disabled="toTransfered <= 0"
                >
                  申请转出
                </el-button>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--    返佣转出申请-->
    <za-dialog top-title="转出申请" :visible.sync="dialogVisible" width="800px" center>
      <div>
        <div class="prompt">
          <div>
            <za-svg-icon icon-class="za-wanning" class-name="za-waring"></za-svg-icon>
          </div>
          <div>
            <span>申请提交后，您的款项将提现至账户余额，如需提现到银行卡， 请到财务中心</span>
            <span class="blue_font" @click="goToBalance">余额提现</span>
            <span>管理操作提现。</span>
          </div>
        </div>
        <el-form
          ref="form"
          class="form"
          :disabled="confirmBtnLoading"
          :model="baseForm"
          label-position="left"
          label-width="140px"
          :rules="rules"
        >
          <el-form-item :label="'可提现金额' + '：'">
            <span>￥{{ toTransfered }}</span>
          </el-form-item>
          <el-form-item :label="'本次提现金额' + '：'" prop="fapiao">
            <el-radio-group v-model="baseForm.withdraw" @input="changeRadio($event)">
              <el-radio label="1">￥{{ toTransfered }}</el-radio>
              <el-radio label="2">
                其他金额：
                <el-input v-model="otherAmount" :disabled="otherAmountFlag" maxlength="8">
                  <template slot="prepend">￥</template>
                </el-input>
              </el-radio>
              <span class="red-font" v-if="showTest">请输入0.01~99999范围内的数字，小数点后最多两位</span>
              <span class="red-font" v-if="tipFlag">输入提现金额大于可提现金额</span>
            </el-radio-group>
          </el-form-item>
          <!-- <el-form-item :label="'其他金额' + '：'" v-if="baseForm.fapiao == '2'" prop="amount"> -->
          <!-- <el-input-number :min="0" v-model="baseForm.amount" /> -->
          <!-- </el-form-item> -->
        </el-form>
      </div>
      <div slot="footer">
        <za-button :loading="confirmBtnLoading" :type="buttonTypeObj.BLUE" @click="handleSubmitForm">
          {{ translateTitle('btn.confirm') }}
        </za-button>
        <za-button :disabled="confirmBtnLoading" :type="buttonTypeObj.GREY" @click="dialogVisible = false">
          {{ translateTitle('btn.cancel') }}
        </za-button>
      </div>
    </za-dialog>
  </div>
</template>
<script>
  import { apiPopularizeSettlementTotal } from '@/api/management/settlementDetails'
  import { apiTransferToBalance } from '@/api/management/overview'

  export default {
    components: {},
    data() {
      return {
        baseForm: {
          withdraw: '1',
        },
        otherAmount: null,
        withdrawalAmount: null,
        otherAmountFlag: true, //其他金额禁止输入判断
        showTest: false, //金额范围提示
        tipFlag: false, //可提现金额提示
        rules: {},
        toTransfered: null, //可转出金额
        amountArr: [
          {
            label: '返利总金额',
            value: 0,
            name: 'allAmount',
          },
          {
            label: '已结算返利金额',
            value: 0,
            name: 'settled',
          },
          {
            label: '待结算返利金额',
            value: 0,
            name: 'toSettled',
          },
          {
            label: '可转出金额',
            value: 0,
            name: 'toTransfered',
          },
        ],
      }
    },
    created() {
      this.getList()
    },
    watch: {
      otherAmount: {
        handler(nv) {
          let val = '' + nv
          val =
            val
              .replace(/[^\d^.]+/g, '')
              .replace(/^0+(\d)/, '$1')
              .replace(/^\./, '0.')
              .match(/^\d*(\.?\d{0,2})/g)[0] || ''
          this.showTest = (val < 0.01 || val > 99999) && nv !== ''
          this.tipFlag = val > this.toTransfered && nv !== ''
          this.otherAmount = val
        },
      },
    },
    methods: {
      getList() {
        this.onSubmitData(apiPopularizeSettlementTotal, null, null, false, false, (res) => {
          this.toTransfered = res.data.toTransfered
          const resArr = Object.keys(res.data)
          resArr.map((item, index) => {
            if (item === this.amountArr[index].name) this.amountArr[index].value = res.data[item]
          })
        })
      },
      changeRadio(event) {
        event === '2' ? (this.otherAmountFlag = false) : (this.otherAmountFlag = true)
        this.otherAmount = null
        this.tipFlag = false
      },
      handleClick() {
        this.dialogVisible = true
        this.baseForm.withdraw = '1'
        this.otherAmount = null
        this.otherAmountFlag = true
        this.tipFlag = false
      },
      goToBalance() {
        this.$router.push('/moneyCenter/expenseManagement/moneyExtract')
      },
      handleSubmitForm() {
        if (this.showTest || this.tipFlag) return
        const amount = this.baseForm.withdraw === '1' ? this.toTransfered : this.otherAmount
        const senData = {
          amount: Number(amount),
        }
        // 如果输入金额 > 可提现金额 则提示 输入提现金额大于可提现金额
        if (!amount) this.showTest = true
        else
          this.onSubmitData(apiTransferToBalance, senData, null, false, false, (res) => {
            if (res.code === 0) this.$msgSuccess('转出成功')
            this.getList()
          }),
            (this.dialogVisible = false)
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import '~@/za/styles/mixin.scss';

  .prompt {
    width: 100%;
    background: #f4f6fa;
    border-radius: 2px;
    display: flex;
    padding: 10px 15px;
    margin-bottom: 30px;

    .blue_font {
      margin-left: 10px;
      cursor: pointer;
    }

    h5 {
      @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
      margin: 0px 0px 10px 0px;
    }

    span {
      // display: block;
      font-size: 14px;
      // margin-bottom: 8px;
      color: #000000;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
  }

  .form {
    padding-left: 15px;
  }

  .cardCenter {
    background-color: #ffffff;
    margin-top: 20px;
    height: 170px;
    margin-bottom: 20px;

    .card_mottom {
      padding: 20px;

      .title {
        @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
        width: 100%;
        height: 20px;
        margin-bottom: 14px;
      }

      .cardUl {
        height: 90px;
        margin-right: 20px;
        padding-left: 15px;
        background: #f4f6fa;
        padding: 10px;

        .title {
          font-size: 12px;
          width: 100%;
        }

        .operation {
          display: flex;
          justify-content: space-between;
          position: relative;

          .applyFor {
            position: absolute;
            right: 0;
          }

          div:first-child {
            span:first-child {
              font-size: 24px;
              font-family: DINAlternate-Bold, DINAlternate;
              font-weight: bold;
              color: #000000;
            }
          }
        }
      }
    }
  }

  ::v-deep .dialog-footer {
    justify-content: center;
  }

  ::v-deep .el-radio {
    display: block;
    margin: 10px 0;
  }

  ::v-deep .el-input {
    width: 50%;
    top: -10px;
  }
</style>