/**
 * @description 注册事件名称
 * @property: {String} EVENT_SEC_CENTER_NODE 安全中心获取node事件
 * @property: {String} EVENT_SEC_CENTER_NODE_CHANGE 安全中心node选择变更好消息
 * @property: {String} EVENT_WEBSITE_UNREAD_MSG 未读消事件
 * @type {{EVENT_SEC_CENTER_NODE: string}}
 */
export const BASE_EVENT = {
  EVENT_SEC_CENTER_NODE: 'event_security_center_node',
  EVENT_SEC_CENTER_NODE_CHANGE: 'event_security_center_node_change',
  EVENT_WEBSITE_UNREAD_MSG: 'event_website_unread_msg',
}
