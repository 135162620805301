var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "za-loading",
          rawName: "v-za-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "reminder",
    },
    [
      _c("div", { staticClass: "title" }, [_vm._v("代办提醒")]),
      _c(
        "div",
        { staticClass: "card_mottom" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "cardUl",
                      attrs: { shadow: "always" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$router.push(
                            "/moneyCenter/orderManagement/myOrder"
                          )
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("待支付订单"),
                      ]),
                      _c("div", { staticClass: "operation" }, [
                        _c("div", [
                          _c("span", [_vm._v(_vm._s(_vm.waitPayOrderNumber))]),
                          _c("span", [_vm._v("个")]),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "cardUl",
                      attrs: { shadow: "always" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$router.push(
                            "/moneyCenter/invoiceManagement/askfor"
                          )
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("待开票金额"),
                      ]),
                      _c("div", { staticClass: "operation" }, [
                        _c("div", [
                          _c("span", [_vm._v(_vm._s(_vm.amount))]),
                          _c("span", [_vm._v("元")]),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }