<template>
  <div>
    <div>
      <loginHeard :title="'注册'"></loginHeard>
    </div>

    <div class="heard">
      <div class="center">
        <div class="form_pwd">
          <p style="text-align: left; margin-left: 145px">智安云账号关联</p>

          <el-form
            ref="form"
            :disabled="confirmBtnLoading"
            :model="baseForm"
            label-position="left"
            label-width="140px"
            :rules="rules"
          >
            <el-form-item prop="name">
              <el-input
                v-model.trim="baseForm.name"
                :placeholder="translateTitle('register.placeAccount')"
                :readonly="confirmBtnLoading"
                @keyup.native="trim('number')"
                @input="clangeWaringDesc()"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model.trim="baseForm.password"
                :placeholder="translateTitle('register.placePwd')"
                :readonly="confirmBtnLoading"
                ref="password"
                :type="passwordType"
                @keyup.native="trim('password')"
              >
                <template v-if="passwordType === 'password'" #suffix>
                  <za-svg-icon class-name="svg-suffix" icon-class="za-eye-close" @click="handlePassword" />
                </template>
                <template v-else #suffix>
                  <za-svg-icon class-name="svg-suffix" icon-class="za-eye-open" @click="handlePassword" />
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="confirmPassword">
              <el-input
                v-model.trim="baseForm.confirmPassword"
                :placeholder="translateTitle('register.placeConfirmPwd')"
                :readonly="confirmBtnLoading"
                :type="newPasswordType"
                maxlength="30"
                ref="newPasswordType"
                @keyup.native="trim('confirmPassword')"
              >
                <template v-if="newPasswordType === 'password'" #suffix>
                  <za-svg-icon class-name="svg-suffix" icon-class="za-eye-close" @click="handleNewPassword" />
                </template>
                <template v-else #suffix>
                  <za-svg-icon class-name="svg-suffix" icon-class="za-eye-open" @click="handleNewPassword" />
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="number">
              <el-input
                v-model="baseForm.number"
                placeholder="请输入要绑定的手机号码"
                @keyup.native="newTrim('number')"
                :disabled="phoneDisabled"
              />
            </el-form-item>
            <el-form-item required>
              <el-col :span="16">
                <el-form-item prop="code">
                  <el-input
                    v-model="baseForm.code"
                    placeholder="请输入验证码！"
                    @input="changeWaring()"
                    @keyup.native="newTrim('code')"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="1">&nbsp;</el-col>
              <el-col :span="7">
                <el-button @click="sendData" class="sendInfo" :disabled="dis" :class="{ grey: isGrey, blue: !isGrey }">
                  <span v-if="telState == '1'">发送验证码</span>
                  <span v-if="telState == '2'">{{ count }}秒重试</span>
                  <span v-if="telState == '3'">重新发送</span>
                </el-button>
              </el-col>
              <el-col>
                <span v-if="waringState" class="red-font">{{ waringText }}</span>
              </el-col>
            </el-form-item>
            <el-form-item>
              <div class="prompt_btn">
                <za-button :loading="confirmBtnLoading" :size="14" class="button" @click="doubleNext">提交</za-button>
              </div>
            </el-form-item>
            <div>
              <el-dialog width="25%" title="验证" :visible.sync="innerVisible" append-to-body>
                <za-verify v-if="showVerity" ref="verify" @verifySuccess="success" :selectKey="dialogType" />
              </el-dialog>
            </div>
            <el-dialog
              title="提示"
              :visible.sync="dialogVisible"
              width="400px"
              append-to-body
              :close-on-click-modal="false"
              style="position: absolute; top: 200px"
              @close="reloadBaseForm"
            >
              <span>该手机号已存在，是否需要绑定微信</span>
              <span slot="footer" class="dialog-footer">
                <el-button @click="reloadBaseForm">取 消</el-button>
                <el-button type="primary" @click="confirmBind">确 定</el-button>
              </span>
            </el-dialog>
          </el-form>
        </div>
      </div>
    </div>
    <div>
      <loginFoot></loginFoot>
    </div>
  </div>
</template>
<script>
  import loginHeard from '@/views/login/components/loginHeard'
  import loginFoot from '@/views/login/components/loginFoot'
  import ZaVerify from '@/za/components/zaVerify'
  import { translateTitle } from '@/utils/i18n'
  import {
    apiLoginSend,
    apiLoginVerify,
    apiUpdatePwd,
    apiUpPwd,
    apiSendSms,
    bindWXSend,
    bindWX,
    bindPhone,
  } from '@/api/apiLogin'
  import { isPwd, isPwdNotTest, isHaveSpecial, isSPpassWord, isHaveSpace } from '@/utils/validate'
  import { mapActions, mapGetters } from 'vuex'
  import { toLoginRoute } from '@/utils/routes'

  export default {
    components: { loginHeard, loginFoot, ZaVerify },
    data() {
      const userName = (rule, value, callback) => {
        let regx = /^[A-Za-z0-9\u4e00-\u9fa5]{6,20}$/
        if (value == '') callback(new Error('请输入用户名'))
        else if (value.length < 6 || value.length > 20) callback(new Error('请输入6-20字符，限数字、字母或者汉字'))
        else if (!regx.test(value)) callback(new Error('请输入6-20字符，限数字、字母或者汉字'))
        else callback()
      }

      const password = (rule, value, callback) => {
        if (value === '') callback(new Error(translateTitle('register.placePwd')))
        else if (value.length < 6 || value.length > 20) callback(new Error(' 请输入6-20字符'))
        else if (isPwdNotTest(value)) callback(new Error('密码设置不能输入中文'))
        else if (isSPpassWord(value)) callback(new Error('密码复杂度太低，限字母、数字或特殊字符至少包含2种'))
        else callback()
      }
      const confirmPassword = (rule, value, callback) => {
        if (value === '') callback(new Error(translateTitle('register.tipsConfirmPwd')))
        else if (this.baseForm.confirmPassword !== '' && this.baseForm.confirmPassword !== this.baseForm.password) {
          callback(new Error(translateTitle('register.tipsDistinctPwd')))
        } else callback()
      }
      const smsCode = (rule, value, callback) => {
        if (value === '') callback(new Error('验证码不能为空'))
        else if (isHaveSpace(value)) callback(new Error('验证码不能包含空格！'))
        else callback()
      }

      return {
        active: 1,
        count: '',
        telState: 1,
        dis: false,
        show: true,
        timer: null, //设置计时器,
        isGrey: false, //按钮样式
        showVerity: false,
        innerVisible: false,
        dialogVisible: false,
        phoneDisabled: false, //手机号input是否可以修改
        redirect: undefined,

        rules: {
          name: [{ required: true, validator: userName, trigger: 'blur' }],
          password: [{ required: true, trigger: 'blur', validator: password }],
          confirmPassword: [{ required: true, trigger: 'blur', validator: confirmPassword }],
          number: [{ required: true, message: '请输入手机号码', trigger: 'blur' }],
          code: [{ required: true, trigger: 'blur', validator: smsCode }],
        },
        pwdrule: {
          password: [{ required: true, trigger: 'blur', validator: password }],
        },
        baseForm: {
          name: '',
          password: '',
          number: '', //手机号
          code: '',
          confirmPassword: '',
          newPassword: '',
        },
        user: '',
        passwordType: 'password',
        newPasswordType: 'password',
      }
    },
    created() {
      console.log('来到binxWx中,query', this.$route.query)
      if (this.$route.query.phone) {
        this.baseForm.number = this.$route.query.phone
        // 将手机号的输入框改为disabled
        this.phoneDisabled = true
      }
    },

   
    methods: {
      ...mapActions({
        _logout: 'user/logout',
        setLoginData: 'user/setLoginData',
      }),
      handleRoute() {
        return this.redirect === '/404' || this.redirect === '/403' ? '/' : this.redirect
      },
      handleNewPassword() {
        this.newPasswordType === 'password' ? (this.newPasswordType = '') : (this.newPasswordType = 'password')
        this.$nextTick(() => {
          this.$refs.newPasswordType.focus()
        })
      },
      handlePassword() {
        this.passwordType === 'password' ? (this.passwordType = '') : (this.passwordType = 'password')
        this.$nextTick(() => {
          this.$refs.password.focus()
        })
      },
      trim(val) {
        this.baseForm[val] = this.baseForm[val].replace(/\s*/g, '')
      },
      newTrim(val) {
        this.baseForm[val] = this.baseForm[val].replace(/\s*/g, '')
      },
      async success(val) {
        this.innerVisible = false
        this.showVerity = false
        let res = await bindWXSend({ number: this.baseForm.number }).catch()
        if (res.code == 0) {
          let TIME_COUNT = 60
          if (!this.timer) {
            this.count = TIME_COUNT
            this.isGrey = true
            this.telState = 2
            this.dis = true
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--
              } else {
                this.dis = false
                this.isGrey = false
                this.telState = 3
                clearInterval(this.timer)
                this.timer = null
              }
            }, 1000)
          }
        }
      },
      sendData() {
        console.log('点击了发送验证码')
        if (!this.baseForm.number) {
          this.$msgWarning('手机号不能为空')
        } else {
          this.innerVisible = true
          this.showVerity = true
        }
      },
      doubleNext() {
        let formData = {}
        formData.name = this.baseForm.name
        formData.password = this.baseForm.password
        formData.phone = this.baseForm.number
        formData.code = this.baseForm.code
        formData.popularizeCode = this.$route.query.popularizeCode
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            // res = await apiLoginVerify(this.baseForm).finally(() => (this.confirmBtnLoading = false))
            let res = await bindWX(formData).finally(() => (this.confirmBtnLoading = false))
            if (res.code == 0) {
              console.log(res, '绑定微信的返回')
              // 如果remind === 0，后端会返回delToken，delToken==1，清除-重新登录,delToken==2，不清除token,直接跳到首页
              if (res.data.remind === 0) {
                if (res.data.delToken == 1) {
                  // 清除已经储存的token
                  this.logout()
                } else {
               
                  this.$router.push('/')
                }
              }
              if (res.data.remind === 1) {
                // remind === 1 弹窗提示用户是否确认绑定
               
                // 弹窗提示是否要绑定
                this.dialogVisible = true
              }
            }
          } else {
            return false
          }
        })
        console.log('点击了提交this.baseForm',this.baseForm)
         // 保存一下表单数据，如果用户点击关闭弹窗，就进行数据回显
         sessionStorage.setItem('baseForm',JSON.stringify(
                  this.baseForm
                ) )
      },
      // 点击确认绑定按钮
      async confirmBind() {
        console.log('确认绑定')
        this.dialogVisible = false
        // 调用绑定接口
        // 发送确认绑定请求
        try {
          //清除token,重新登录
          let obj = {
            phone: this.baseForm.number,
            code: this.baseForm.code,
          }
          let bPhoneRes = await bindPhone(obj).finally(() => (this.confirmBtnLoading = false))
          if (bPhoneRes.code === 0) {
            // 提示绑定成功
            this.$msgSuccess('绑定成功')
            setTimeout(() => {
              this.logout()
            }, 1000)
          }
        } catch (error) {
          this.$msgWarning(error)
        }
      },
      async logout() {
        await this._logout()
        if (this.$route.name === 'TopUp') await this.$router.push(toLoginRoute(this.$route.fullPath, false))
        else await this.$router.push(toLoginRoute(this.$route.fullPath))
      },
      // 关闭弹窗，回显数据
      reloadBaseForm(){
        this.dialogVisible=false;
        this.baseForm=JSON.parse(
          sessionStorage.getItem('baseForm')
        ) 
        // session的数据删除
        // sessionStorage.removeItem('baseForm')
      }
    },
    
  }
</script>
<style lang="scss" scoped>
  .heard {
    height: calc(100vh - 60px);
    background-color: #ffffff;
    box-sizing: border-box;
    border-bottom: 1px solid #e4e5e9;
    position: relative;

    .center {
      width: 786px;
      height: 350px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -70%);
      margin-top: 50px;

      .form_pwd {
        width: 90%;
        margin-top: 100px;
        margin-bottom: 24px;
      }

      .goback {
        background: none;
        border: 1px solid #999999;
        width: 108px;
        color: #000000;
      }

      .button {
        width: 108px;
        margin-right: 40px;
        text-align: center;
      }

      .teps {
        width: 1500px;
        margin-left: -40%;
      }
    }

    .title {
      font-size: 16px;
      font-weight: 400;
      color: #000000;

      span {
        color: #1b5deb;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  .userTitle {
    text-align: center;
    margin-bottom: 20px;
    display: block;

    span {
      color: #1b5deb;
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .end {
    text-align: center;
    margin-left: 180px;

    .registerSuccess {
      text-align: center;
      width: 72px;
      height: 72px;
    }

    .title {
      font-size: 24px;
      font-weight: 500;
      color: #000000;
      margin-top: 24px;
      margin-bottom: 34px;
    }

    .button {
      margin-right: 0;
    }
  }

  ::v-deep {
    .el-step__icon.is-text {
    }
    .is-process {
      font-weight: 100;
      color: #c0c4cc;
      .is-text {
        border-color: #c0c4cc;
      }
    }

    .el-step__title.is-finish {
      color: #1b5deb;
    }
  }
  .blue {
    width: 164px;
  }
  .grey {
    width: 164px;
  }
</style>
