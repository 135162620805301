import language from '@/i18n/zh'

export function translateTitle(title) {
  let retTxt = title
  if (retTxt && retTxt.indexOf('.')) {
    // eslint-disable-next-line no-unused-vars
    const lan = language.zaI18n
    retTxt = eval('lan.' + title)
  } else {
    retTxt = ''
  }
  return retTxt
}
