import request from '@/utils/request'
import {
  LOGIN_PATH,
  LOGIN_REGISTER,
  METHOD,
  USER_SENGSMS,
  LOGIN_PHONE,
  LOGIN_SMS,
  USER_SENG,
  USER_VERIFY,
  USER_UPDATEPWD,
  USER_UP_PWD,
  USER_GETCAPTCHA,
  USER_SECURESMS,
  USER_CONSOLE_VERIFYLOGIN,
  USER_CONSOLE_CAPTCHALOGIN,
  USER_LOGINZ,
  LOGIN_WEIXIN,
  BIND_WX,
  BIND_PHONE,
  BIND_SENDSMS,
} from '@/api/api.config'
/**
 * @description //——登录
 * @param data
 * @returns {AxiosPromise}
 */
export function apiLoginz(data) {
  return request({
    url: USER_LOGINZ,
    method: METHOD.POST,
    data,
  })
}
/**
 * @description //鉴权登录-防止爆破——登录
 * @param data
 * @returns {AxiosPromise}
 */
export function apiCaptchaLogin(data) {
  return request({
    url: USER_CONSOLE_CAPTCHALOGIN,
    method: METHOD.POST,
    data,
  })
}
/**
 * @description //微信扫码登录
 * @param data
 * @returns {AxiosPromise}
 */
export function apiWeiXinLogin(data) {
  return request({
    url: LOGIN_WEIXIN,
    method: METHOD.POST,
    data,
  })
}
/**
 * @description //账号密码登录获取验证码
 * @param data
 * @returns {AxiosPromise}
 */
export function apiVerifyLogin(data) {
  return request({
    url: USER_CONSOLE_VERIFYLOGIN,
    method: METHOD.POST,
    data,
  })
}
/**
 * @description //账号密码登录获取验证码
 * @param data
 * @returns {AxiosPromise}
 */
export function apiSecureSms(data) {
  return request({
    url: USER_SECURESMS,
    method: METHOD.POST,
    data,
  })
}

/**
 * @description 登录放爆破获取数据接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiGetCaptcha(data) {
  return request({
    url: USER_GETCAPTCHA,
    method: METHOD.POST,
    data,
  })
}

/**
 * @description 登录接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiLogin(data) {
  return request({
    url: LOGIN_PATH,
    method: METHOD.POST,
    data,
  })
}

/**
 * @description 手机号登录短信接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiLoginByPhoneSms(data) {
  return request({
    url: LOGIN_SMS,
    method: METHOD.GET,
    params: data,
  })
}

/**
 * @description 手机号登录接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiLoginByPhone(data) {
  return request({
    url: LOGIN_PHONE,
    method: METHOD.POST,
    data,
  })
}

/**
 * @description 注册接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiRegister(data) {
  return request({
    url: LOGIN_REGISTER,
    method: METHOD.POST,
    data,
  })
}

/**
 * @description 注册账号短信发送接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiSendSms(data) {
  return request({
    url: USER_SENGSMS,
    method: METHOD.GET,
    params: data,
  })
}
/**
 * @description 忘记密码获取短信接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiLoginSend(data) {
  return request({
    url: USER_SENG,
    method: METHOD.GET,
    params: data,
  })
}
/**
 * @description 微信登录绑定获取短信接口
 * @param data
 * @returns {AxiosPromise}
 */
export function bindWXSend(data) {
  return request({
    url: BIND_SENDSMS,
    method: METHOD.GET,
    params: data,
  })
}
/**
 * @description 忘记密码获取短信验证接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiLoginVerify(data) {
  return request({
    url: USER_VERIFY,
    method: METHOD.GET,
    params: data,
  })
}
/**
 * @description 智安云账号关联接口
 * @param data
 * @returns {AxiosPromise}
 */
export function bindWX(data) {
  return request({
    url: BIND_WX,
    method: METHOD.POST,
    data,
  })
}
/**
 * @description 绑定已有手机号
 * @param data
 * @returns {AxiosPromise}
 */
export function bindPhone(data) {
  return request({
    url: BIND_PHONE,
    method: METHOD.POST,
    data,
  })
}
/**
 * @description 修改密码接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiUpdatePwd(data) {
  return request({
    url: USER_UPDATEPWD,
    method: METHOD.POST,
    data,
  })
}
/**
 * @description 修改密码接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiUpPwd(data) {
  return request({
    url: USER_UP_PWD,
    method: METHOD.POST,
    data,
  })
}
