<template>
  <div class="order-container" v-za-loading="isLoading">
    <div class="title">{{ title }}</div>
    <za-query-form>
      <za-query-form-left-panel :span="24">
        <el-form :inline="true" :model="pageObj" @submit.native.prevent>
          <el-form-item label="选择产品:">
            <el-select v-model="pageObj.serviceType" @change="getList()" clearable>
              <el-option
                v-for="item in productList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="translateTitle('details.transactionTime') + '：'" label-width="100px">
            <el-date-picker
              v-model="pickTime"
              type="daterange"
              range-separator="至 "
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="datePickEnd"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="付费方式:" label-width="100px">
            <el-select v-model="pageObj.payType" @change="getList()">
              <el-option v-for="item in typeOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-left: 20px !important">
            <el-input v-model="pageObj.keyword" placeholder="请输入订单号">
              <el-button slot="append" icon="el-icon-search" @click="getList()"></el-button>
            </el-input>
          </el-form-item>
          <el-form-item style="margin-left: 60px !important">
            <za-grid
              :tableTitle="columObj.columnData"
              @getList="getList"
              :checkList="checkList"
              :checkboxList="checkboxList"
            />
          </el-form-item>
        </el-form>
      </za-query-form-left-panel>
    </za-query-form>
    <za-query-form>
      <za-query-form-left-panel :span="24">
        <!-- <el-button type="primary" @click="nowPay" :disabled="multipleSelection.length === 0"
                >批量支付
                </el-button> -->
      </za-query-form-left-panel>
    </za-query-form>
    <div>
      <occ-table
        :tableData="tableData"
        :columObj="columObj"
        @handleSelectionChange="handleSelectionChange"
        :isOp="columObj.isOp"
        @select="seleResult"
        @handleDesc="handleDesc"
      >
        <template #default="{ row }">
          <el-button type="primary" @click="nowPay(row)" :disabled="row.status !== '待支付'">立即支付</el-button>
          <el-button type="primary" @click="toInfo(row)">订单详情</el-button>
          <el-button type="primary" @click="handelCancel(row)" :disabled="row.status !== '待支付'">取消订单</el-button>
        </template>
      </occ-table>
      <pagination :limit.sync="pageObj.pageSize" :page.sync="pageObj.pageNo" :total="total" @pagination="getList" />
    </div>
  </div>
</template>
<script>
import zaGrid from '@/za/components/zaGrid'
import { apiMyOrder, apiMyOrderCancel, apiOrderReset } from '@/api/moneyCenter/orderManagement/myOrder'

export default {
  components: { zaGrid },
  data() {
    return {
      title: '',
      typeOptions: [
        { id: 0, name: '全部' },
        { id: 2, name: '后付费' },
        { id: 1, name: '预付费' },
        { id: 3, name: '预付加后付费' },
      ],
      pickTime: [],
      multipleSelection: [],
      checkList: [],
      checkboxList: [],
      pageObj: {
        startTime: 0,
        endTime: 0,
        pageNo: 1,
        pageSize: 10,
        serviceType: '',
        payType: 0,
        keyword: '',
        orderType: [],
        orderState: [],
      },
      total: 0,
      tableData: [],
      columObj: {
        // 控制选择框
        // selection: true,
        isOp: true,
        width: '400px',
        columnData: [
          {
            desc: true,
            prop: 'orderNumber',
            label: '订单号',
            showOverFlow: false,
            width: '300',
            flag: true,
            align: 'center',
          },
          {
            text: true,
            prop: 'serviceName',
            label: '产品名称',
            flag: true,
            width: '',
            align: 'center',
          },
          {
            filter: true,
            ownDefined: true,
            prop: 'orderType',
            label: '类型',
            flag: true,
            width: '',
            align: 'center',
            filters: [
              { text: '新购', value: 0 },
              { text: '续费', value: 1 },
              { text: '后付费计费方案变更', value: 2 },
              { text: '升配', value: 3 },
              { text: '降配', value: 4 },
              //  {text: '后付费', value: 5},
              { text: '赔付时长', value: 6 },
              { text: '退款', value: 7 },
              { text: '转入', value: 8 },
              { text: '后付费转预付费', value: 9 },
              { text: '预付费转后付费', value: 10 },
              { text: '续时订单', value: 11 },
            ],
            ownDefinedReturn: (row, $index, val) => {
              let result = val.filters.filter((item) => {
                if (item.value == row.orderType) {
                  return item
                }
              })
              return result[0].text
            },
          },
          {
            ownDefined: true,
            prop: 'price',
            label: '订单金额',
            flag: true,
            width: '',
            fontSize: '15px',
            align: 'center',
            ownDefinedReturn: (row) => {
              return '￥' + row.price
            },
          },
          {
            ownDefined: true,
            prop: 'actualPrice',
            label: '实付金额',
            flag: true,
            width: '',
            fontSize: '15px',
            align: 'center',
            ownDefinedReturn: (row) => {
              return '￥' + row.actualPrice
            },
          },
          {
            text: true,
            prop: 'createAt',
            label: '下单时间',
            flag: true,
            width: '',
            align: 'center',
          },
          {
            filter: true,
            colorType: true,
            prop: 'status',
            label: '订单状态',
            colors: { 待支付: 'red' },
            flag: true,
            width: '',
            align: 'center',
            filters: [
              { text: '待支付  ', value: 0 },
              { text: '已取消', value: 1 },
              { text: '已支付', value: 2 },
              { text: '已退款', value: 3 },
            ],
          },
        ],
      },
    }
  },
  created() {
    this.title = this.$route.meta.title
    this.getList()
    this.getChecklist()
  },
  methods: {
    getList() {
      this.onSubmitData(apiMyOrder, this.pageObj, null, false, false, (res) => {
        this.total = res.data.total
        this.tableData = res.data.list
      })
    },
    handleDesc(row) {
      this.toInfo(row)
    },
    seleResult(val) {
      if (val.orderType) {
        this.pageObj.orderType = val.orderType
        this.getList()
      } else if (val.status) {
        this.pageObj.orderState = val.status
        this.getList()
      }
    },
    nowPay(row) {
      if (row.orderNumber) {
        this.$router.push({ path: '/orderPayment', query: { orderId: [row.orderNumber] } })
      } else {
        const orderNumber = this.multipleSelection.map((item) => item.orderNumber)
        let data = {
          orderNumber,
        }
        this.onSubmitData(apiOrderReset, data, null, false, false, (res) => {
          if (res.code == 0) {
            this.$router.push({ path: '/orderPayment', query: { orderId: orderNumber } })
          }
        })
      }
    },
    datePickEnd(date) {
      if (date) {
        this.pageObj.startTime = this.$changeTimeStamp(date[0])
        this.pageObj.endTime = this.$changeTimeStamp(date[1]) + 3600 * 24 - 1
      } else {
        this.pageObj.startTime = 0
        this.pageObj.endTime = 0
      }
      this.getList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    toInfo(row) {
      this.$router.push({
        path: '/moneyCenter/orderManagement/myOrder/details',
        query: { orderNumber: row.orderNumber },
      })
    },
    handelCancel(row) {
      this.$baseConfirm('是否取消该订单', null, () => {
        const data = { orderNumber: row.orderNumber }
        this.onDelete(apiMyOrderCancel, data, '订单取消成功')
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~@/za/styles/mixin.scss';

.title {
  @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
  width: 100%;
  height: 20px;
  margin-bottom: 20px;
}

.blue_font {
  margin-right: 10px;
}
</style>