var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sub-item" }, [
    _c(
      "div",
      {
        ref: "item",
        staticClass: "item",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.handleClick.apply(null, arguments)
          },
          mouseenter: _vm.handleMouseEnter,
        },
      },
      [
        _c("div", {
          ref: "bg",
          staticClass: "bg",
          class: { active_bg: _vm.isActive },
        }),
        _c(
          "div",
          {
            staticClass: "route_name",
            class: { active_name: _vm.isActive, isMenu: !_vm.isMenu },
            style: { paddingLeft: _vm.getPaddingLeft },
          },
          [
            _c(
              "div",
              { staticClass: "left text-overflow" },
              [
                _vm.item.meta.icon
                  ? _c("za-svg-icon", {
                      attrs: {
                        "class-name": "svg_icon",
                        "icon-class": _vm.item.meta.icon,
                      },
                    })
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.item.meta.title))]),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { flex: "1", "text-align": "start" } },
              [
                _vm.isMenu
                  ? _c("za-svg-icon", {
                      ref: "svg",
                      attrs: {
                        "class-name": _vm.arrowClass,
                        "icon-class": "za-arrow",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ]
    ),
    _c("div", { ref: "sub", staticClass: "sub-menu" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }