import request from '@/utils/request'
import {
  BBC_LIST,
  BCC_LIST,
  BCD_CREATE,
  BCD_LIST,
  BCH_LIST,
  BCM_LIST,
  BLB_LIST,
  BOS_LIST,
  CAS_LIST,
  CDN_LIST,
  CDS_LIST,
  DCC_LIST,
  DNS_LIST,
  DTS_LIST,
  EIP_LIST,
  EIPBP_LIST,
  KAFKA_LIST,
  METHOD,
  RDS_LIST,
  VPC_LIST,
} from '@/api/api.config'

/**
 * @description 云服务器界面接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiBccList(data) {
  return request({
    url: BCC_LIST,
    method: METHOD.GET,
    params: data,
  })
}

/**
 * @description 云数据库界面接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiRdsList(data) {
  return request({
    url: RDS_LIST,
    method: METHOD.GET,
    params: data,
  })
}

/**
 * @description 云数据库界面接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiCdnList(data) {
  return request({
    url: CDN_LIST,
    method: METHOD.GET,
    params: data,
  })
}

/**
 * @description 对象存储界面接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiBosList(data) {
  return request({
    url: BOS_LIST,
    method: METHOD.GET,
    params: data,
  })
}

/**
 * @description 域名界面接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiBcdList(data) {
  return request({
    url: BCD_LIST,
    method: METHOD.GET,
    params: data,
  })
}

/**
 * @description 域名下单接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiBcdCreate(data) {
  return request({
    url: BCD_CREATE,
    method: METHOD.GET,
    params: data,
  })
}

/**
 * @description 专属服务器界面接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiDccList(data) {
  return request({
    url: DCC_LIST,
    method: METHOD.GET,
    params: data,
  })
}

/**
 * @description 云磁盘界面接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiCdsList(data) {
  return request({
    url: CDS_LIST,
    method: METHOD.GET,
    params: data,
  })
}

/**
 * @description 弹性公网ip界面接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiEipList(data) {
  return request({
    url: EIP_LIST,
    method: METHOD.GET,
    params: data,
  })
}

/**
 * @description 弹性公网ip界面接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiEipBpList(data) {
  return request({
    url: EIPBP_LIST,
    method: METHOD.GET,
    params: data,
  })
}

/**
 * @description vpc界面接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiVpcList(data) {
  return request({
    url: VPC_LIST,
    method: METHOD.GET,
    params: data,
  })
}

/**
 * @description 负载均衡界面接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiBlbList(data) {
  return request({
    url: BLB_LIST,
    method: METHOD.GET,
    params: data,
  })
}

/**
 * @description 负载均衡界面接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiDnsList(data) {
  return request({
    url: DNS_LIST,
    method: METHOD.GET,
    params: data,
  })
}

/**
 * @description 云虚拟主机界面接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiBchList(data) {
  return request({
    url: BCH_LIST,
    method: METHOD.GET,
    params: data,
  })
}

/**
 * @description 云虚拟主机界面接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiCasList(data) {
  return request({
    url: CAS_LIST,
    method: METHOD.GET,
    params: data,
  })
}

/**
 * @description 裸金属服务器界面接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiBbcList(data) {
  return request({
    url: BBC_LIST,
    method: METHOD.GET,
    params: data,
  })
}

/**
 * @description 数据迁移界面接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiDtsList(data) {
  return request({
    url: DTS_LIST,
    method: METHOD.GET,
    params: data,
  })
}

/**
 * @description 智能大数据界面接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiKafkaList(data) {
  return request({
    url: KAFKA_LIST,
    method: METHOD.GET,
    params: data,
  })
}

/**
 * @description BCM云监控
 * @param data
 * @returns {AxiosPromise}
 */
export function apiBcmList(data) {
  return request({
    url: BCM_LIST,
    method: METHOD.GET,
    params: data,
  })
}
