<template>
    <el-table :cell-style="cellStyle" :header-cell-style="headerRowStyle" :height="styleHeight || tableHeight"
              v-bind="$attrs" v-on="$listeners" ref="za_tabledata">
        <slot/>
    </el-table>
</template>

<script>
    import autoTableHeightMixin from "@/za/mixin/autoTableHeightMixin";

    export default {
        name: 'ZaTable',
        mixins: [autoTableHeightMixin],
        props: {
            isMultipleHeader: {
                type: Boolean,
                default: false,
            },
            styleHeight: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                componentData: {},
            }
        },
        methods: {
            headerRowStyle() {
                const styleObj = {}
                styleObj.color = '#fff'
                styleObj.backgroundColor = '#02323D'
                styleObj.border = 0
                styleObj.fontSize = '14px'
                styleObj.textAlign = 'center'
                if (this.isMultipleHeader) {
                    styleObj.border = '1px solid #536978'
                } else {
                    styleObj.borderBottom = '1px solid #536978'
                }
                return styleObj
            },
            cellStyle({rowIndex}) {
                const styleObj = {}
                styleObj.color = '#fff'
                styleObj.fontSize = '14px'
                styleObj.textAlign = 'center'
                if (this.isMultipleHeader) {
                    styleObj.border = '1px solid #536978'
                } else {
                    styleObj.borderBottom = '1px solid #536978'
                }
                if (rowIndex % 2 === 0) {
                    styleObj.background = '#1A2634'
                    return styleObj
                } else if (rowIndex % 2 === 1) {
                    styleObj.background = '#1f2832'
                    return styleObj
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    ::v-deep {
        .el-table__body-wrapper::-webkit-scrollbar-corner {
            background: transparent;
        }

        .el-table__body-wrapper::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: rgba(#bad7ff, 0.1);
            border: 3px solid transparent;
            border-radius: 7px;
        }

        .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
            background-color: rgba(#00e2ff, 0.3);
        }
    }

    .el-table__fixed-right::before,
    .el-table__fixed::before {
        height: 0;
    }

    /*.el-table__cell {*/
    /*    div {*/
    /*        user-select: text !important;*/
    /*    }*/
    /*}*/
</style>
