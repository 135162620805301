<template>
  <div>
    <el-cascader
      :key="casKey"
      ref="gdCas"
      v-model="selectCas"
      class="cascader"
      :clearable="clearable"
      :disabled="disabled"
      filterable
      :options="regionTree"
      placeholder="请选择位置"
      :props="{
        expandTrigger: 'hover',
        value: 'a',
        label: 'n',
      }"
      @change="handleChange"
    />
  </div>
</template>
<script>
  import { getRegionArray, getRegionByAdCode } from '@/utils/regionInfo-info'
  import { COMMON_CITY_TYPE } from '@/za/model/commonEnum'
  import { composeTree } from '@/utils'

  export default {
    name: 'ZaRegion',
    props: {
      /**
       * 是否开启定位
       */
      isLocation: {
        type: Boolean,
        default: true,
      },
      /**
       * 可选等级
       */
      maxLevel: {
        type: Number,
        default: COMMON_CITY_TYPE.DISTRICT,
      },
      /**
       * 是否禁用
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * 省份编码
       */
      province: {
        type: Number,
        default: 0,
      },
      /**
       * 城市编码
       */
      city: {
        type: Number,
        default: 0,
      },
      /**
       * 区域编码
       */
      district: {
        type: Number,
        default: 0,
      },
      /**
       * 纬度
       */
      lat: {
        type: String,
        default: '',
      },
      /**
       * 经度
       */
      lng: {
        type: String,
        default: '',
      },
      /**
       * 省市区
       */
      addr: {
        type: String,
        default: '',
      },
      clearable: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        selectCas: [],
        searchOption: {
          city: '全国',
        },
        prov: this.province,
        cit: this.city,
        dis: this.district,
        la: this.lat,
        ln: this.lng,
        ad: this.addr,
      }
    },
    computed: {
      // _placeSearch() {
      //   return new AMap.PlaceSearch(this.searchOption || {})
      // },
      regionTree() {
        const regionArray = getRegionArray(this.maxLevel)
        return composeTree(regionArray, 'i', 'p')
      },
      casKey() {
        return Date.now()
      },

      provinceCode: {
        get() {
          return this.prov
        },
        set(val) {
          this.prov = val
          this.$emit('update:province', val)
        },
      },
      cityCode: {
        get() {
          return this.cit
        },
        set(val) {
          this.cit = val
          this.$emit('update:city', val)
        },
      },
      districtCode: {
        get() {
          return this.dis
        },
        set(val) {
          this.dis = val
          this.$emit('update:district', val)
        },
      },
      latCode: {
        get() {
          return this.la
        },
        set(val) {
          this.la = val
          this.$emit('update:lat', val)
        },
      },
      lngCode: {
        get() {
          return this.ln
        },
        set(val) {
          this.ln = val
          this.$emit('update:lng', val)
        },
      },
      addrStr: {
        get() {
          return this.ad
        },
        set(val) {
          this.ad = val
          this.$emit('update:addr', val)
        },
      },
    },
    created() {
      if (this.provinceCode !== 0) {
        this.selectCas.push(this.provinceCode.toString())
      }
      if (this.cityCode !== 0) {
        this.selectCas.push(this.cityCode.toString())
      }
      if (this.districtCode !== 0) {
        this.selectCas.push(this.districtCode.toString())
      }

      /**
       * 区域修正
       */
      const curCasList = []
      if (this.selectCas.length > 0) {
        const lastCode = this.selectCas.slice(-1)[0]
        const arrayList = getRegionByAdCode(lastCode)
        let curAddrStr = ''
        for (let i = 0; i < this.maxLevel; i++) {
          const item = arrayList[i]
          curCasList.push(item.a)
          curAddrStr += item.n
          if (i === 0) {
            this.provinceCode = Number(item.a)
          } else if (i === 1) {
            this.cityCode = Number(item.a)
          } else {
            this.districtCode = Number(item.a)
          }
        }
        this.addrStr = curAddrStr
      }

      this.selectCas.splice(0)
      this.selectCas = curCasList
    },
    methods: {
      handleChange(value) {
        if (value.length === 0) {
          this.provinceCode = 0
          this.cityCode = 0
          this.districtCode = 0
          return
        }

        if (value[0]) {
          this.provinceCode = Number(value[0])
        }
        if (value[1]) {
          this.cityCode = Number(value[1])
        }
        if (value[2]) {
          this.districtCode = Number(value[2])
        }

        const disCode = value.slice(-1)[0]
        const array = getRegionByAdCode(disCode)

        this.addrStr = ''
        for (const item of array) {
          this.addrStr += item.n
        }
        // eslint-disable-next-line no-unused-vars
        let searchStr = ''
        switch (disCode) {
          case '110100':
            searchStr = '北京市'
            break
          case '120100':
            searchStr = '天津市'
            break
          case '500100':
            searchStr = '重庆市'
            break
          case '310100':
            searchStr = '上海市'
            break
          default:
            searchStr = this.addrStr
            searchStr = searchStr.replace('市辖区', '')
            break
        }

        // if (this.isLocation) {
        //   this._placeSearch.search(searchStr, (status, result) => {
        //     if (status === 'complete') {
        //       const tmpPoi = result.poiList.pois[0]
        //       this.latCode = tmpPoi.location.lat.toString()
        //       this.lngCode = tmpPoi.location.lng.toString()
        //     } else {
        //       this.latCode = ''
        //       this.lngCode = ''
        //     }
        //   })
        // }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .cascader {
    width: 50%;
  }
</style>
