var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login", style: _vm.fixStyle }, [
    _c(
      "div",
      [
        _c("loginHeard", {
          attrs: { title: !_vm.isRegister ? "登录" : "注册" },
        }),
      ],
      1
    ),
    _c("div", [
      _c("div", { staticClass: "bg_img" }),
      _c("div", { staticClass: "main" }, [
        _c("div", { staticClass: "loginInfoWrapper" }, [
          _c("div", {
            staticStyle: {
              position: "absolute",
              padding: "20px",
              "padding-left": "30px",
              top: "20px",
              "z-index": "10",
              width: "100%",
              "background-color": "white",
            },
            style: { zIndex: _vm.isQRCode ? 10 : -10 },
            attrs: { id: "weichat" },
          }),
          _c("img", {
            staticStyle: {
              position: "absolute",
              right: "0",
              top: "0",
              "z-index": "11",
            },
            attrs: { src: _vm.showImg, alt: "" },
            on: { click: _vm.GET_weixin },
          }),
          _c("div", { staticClass: "loginInfo" }, [
            !_vm.isRegister
              ? _c("div", { staticClass: "login-com" }, [_c("sign-in")], 1)
              : _c(
                  "div",
                  { staticClass: "register-com" },
                  [
                    _c("register", {
                      attrs: { visible: _vm.isRegister },
                      on: {
                        "update:visible": function ($event) {
                          _vm.isRegister = $event
                        },
                      },
                    }),
                  ],
                  1
                ),
          ]),
        ]),
      ]),
    ]),
    _c("div", [_c("loginFoot")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }