var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "compage" },
        [
          _vm.list.length <= 0
            ? _c("el-empty", { attrs: { "image-size": 200 } })
            : _vm._l(_vm.list, function (item, index) {
                return _c("div", { key: index, staticClass: "main" }, [
                  _c(
                    "div",
                    [
                      _c("el-image", {
                        staticClass: "pic-error-parent",
                        attrs: { src: item.pic },
                      }),
                    ],
                    1
                  ),
                  _c("div", [
                    _c("span", { staticClass: "title" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                    _c("div", { staticClass: "news" }, [
                      _vm._v(_vm._s(item.desc)),
                    ]),
                    _c("div", { staticClass: "compage_input" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.url +
                              "?popularizeCode=" +
                              _vm.popularizeInfo.code +
                              "&matterId=" +
                              item.id
                          ) +
                          " "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "footer" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handleClick(
                                  item.url +
                                    "?popularizeCode=" +
                                    _vm.popularizeInfo.code +
                                    "&matterId=" +
                                    item.id
                                )
                              },
                            },
                          },
                          [_vm._v(" 复制推广链接 ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "footer_share" },
                          [
                            _c("span", [_vm._v("分享至 :")]),
                            _c("za-svg-icon", {
                              attrs: {
                                "class-name": "icon-click",
                                "icon-class": "za-qq",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleShare(
                                    item.url +
                                      "?popularizeCode=" +
                                      _vm.popularizeInfo.code +
                                      "&matterId=" +
                                      item.id,
                                    item.title,
                                    "qq"
                                  )
                                },
                              },
                            }),
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "top",
                                  width: "280",
                                  trigger: "click",
                                },
                              },
                              [
                                _c("div", { staticClass: "tips" }, [
                                  _c("div", {
                                    ref: "qrcode",
                                    refInFor: true,
                                    attrs: { id: "qrcode" },
                                  }),
                                  _c("div", [
                                    _c("h2", [_vm._v("分享到微信")]),
                                    _c("div", [_vm._v("打开微信扫一扫")]),
                                    _c("div", [_vm._v("点击屏幕右上角分享")]),
                                  ]),
                                ]),
                                _c("za-svg-icon", {
                                  attrs: {
                                    slot: "reference",
                                    "class-name": "icon-click",
                                    "icon-class": "za-wechat",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleShare(
                                        item.url +
                                          "?popularizeCode=" +
                                          _vm.popularizeInfo.code +
                                          "&matterId=" +
                                          item.id,
                                        item.title,
                                        "wechat",
                                        index
                                      )
                                    },
                                  },
                                  slot: "reference",
                                }),
                              ],
                              1
                            ),
                            _c("za-svg-icon", {
                              attrs: {
                                "class-name": "icon-click",
                                "icon-class": "za-microblog",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleShare(
                                    item.url +
                                      "?popularizeCode=" +
                                      _vm.popularizeInfo.code +
                                      "&matterId=" +
                                      item.id,
                                    item.title,
                                    "weibo"
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ])
              }),
        ],
        2
      ),
      _c("pagination", {
        attrs: {
          limit: _vm.pageObj.size,
          page: _vm.pageObj.page,
          total: _vm.total,
        },
        on: {
          "update:limit": function ($event) {
            return _vm.$set(_vm.pageObj, "size", $event)
          },
          "update:page": function ($event) {
            return _vm.$set(_vm.pageObj, "page", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }