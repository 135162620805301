<template>
    <div class="agentHomeInfo" v-za-loading="isLoading">
        <div class="title">代办事项</div>
        <div style="display: grid;grid-template-columns: 1fr 1fr 1fr">
            <div v-for="(item,index) in list" :key="index" class="listStyle" @click="path(item.path)">
                <div>{{item.name}}</div>
                <div>
                    <!-- <span v-if="index=='0'" >{{result.renewal}}</span> -->
                    <span v-if="index=='0'" >{{result.unpaid}}</span>
                    <!-- <span v-if="index=='2'" >{{result.expired}}</span> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {apiGetWaitRenewResourceNumber,apiGetExpireResourceNumber} from '@/api/home'
    import {apiMyOrder} from '@/api/moneyCenter/orderManagement/myOrder'

    export default {
        data() {
            return {
                list: [
                    // {name: '待续费项', path: '/moneyCenter/orderManagement/renewal'},
                    {name: '待支付订单', path: '/moneyCenter/orderManagement/myOrder'},
                    // {name: '已过期资产', path: '/moneyCenter/orderManagement/renewal'},
                ],
                result: {
                    renewal: '',
                    unpaid: '',
                    expired: '',
                },
                pageObj: {
                    startTime : 0,
                    endTime : 0,
                    pageNo: 1,
                    pageSize: 10,
                    serviceType:'',
                    payType:0,
                    keyword:'',
                    orderType:[],
                    orderState:[0]
                },
            }
        },
        created() {
          this.getList()
        },
        methods: {
            getList(){
                this.onSubmitData(apiGetWaitRenewResourceNumber, null, null, false, false, (res) => {
                    this.result.renewal=res.data.number
                })
                this.onSubmitData(apiGetExpireResourceNumber, null, null, false, false, (res) => {
                    this.result.expired=res.data.number
                })
                this.onSubmitData(apiMyOrder, this.pageObj, null, false, false, (res) => {
                      this.result.unpaid=res.data.total
                })
            },
            path(val) {
                this.$router.push(val)
            }
        }
    }
</script>
<style lang="scss" scoped>
    .agentHomeInfo {
        width: 100%;
        height: 160px;
        background: #FFFFFF;
        border-radius: 2px;
        margin-bottom: 16px;
        padding: 20px;

        .title {
            width: 100%;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 20px;
            margin-bottom: 14px;
        }

        .listStyle {
            height: 80px;
            background: #F4F6FA;
            border-radius: 2px;
            padding: 10px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            margin-right: 20px;
            cursor: pointer;

            div:last-child {
                font-size: 22px;
                font-family: DINAlternate-Bold, DINAlternate;
                font-weight: bold;
                color: #F38800;
                margin-top: 17px;
            }
        }

        .listStyle:hover {
            background: #1B5DEB;
            color: #FFFFFF;
        }
    }
</style>