<template>
    <div class="sub-item">
        <div ref="item" class="item" @click.stop="handleClick" @mouseenter="handleMouseEnter">
            <div ref="bg" class="bg" :class="{ active_bg: isActive }"/>
            <div class="route_name" :class="{ active_name: isActive,isMenu:!isMenu }" :style="{ paddingLeft: getPaddingLeft }">
                <div class="left text-overflow">
                    <za-svg-icon v-if="item.meta.icon" class-name="svg_icon" :icon-class="item.meta.icon"/>
                    <span>{{ item.meta.title }}</span>
                </div>
                <div style="flex: 1; text-align: start">
                    <za-svg-icon v-if="isMenu" ref="svg" :class-name="arrowClass" icon-class="za-arrow"/>
                </div>
            </div>
        </div>
        <div ref="sub" class="sub-menu">
            <slot/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ItemBar',
        props: {
            item: {
                type: Object,
                default: () => {
                },
            },
            isMenu: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                defaultPaddingLeft: 13,
                increasing: 20,
                visible: false,
                arrowClass: 'svg_icon_arrow',
            }
        },
        computed: {
            isActive() {
                let isChildrenName = false
                if (this.item.meta.childrenName) {
                    const childrenNameArray = this.item.meta.childrenName.split(';')
                    for (let i = 0; i < childrenNameArray.length; i++) {
                        if (this.$route.name === childrenNameArray[i]) {
                            isChildrenName = true
                            break
                        }
                    }
                }

                return this.$route.name === this.item.name || isChildrenName
            },
            getRulesPath() {
                return this.$route.fullPath.toLowerCase().split('/')
            },
            getPaddingLeft() {
                const validPath = this.item.path.toLowerCase().split('/').splice(2)
                let level = 0
                for (let i = 0; i < validPath.length; i++) {
                    if (validPath[i] === this.item.name.toLowerCase()) {
                        level = i
                        break
                    }
                }
                return this.defaultPaddingLeft + this.increasing * level + 'px'
            },
            subHeight() {
                /**
                 * offsetHeight 获取的是div：height + padding内部高度
                 * @type {string}
                 */
                const oneItemHeight = `${this.$refs.item.offsetHeight}px + ${
                    window.getComputedStyle(this.$refs.item).marginTop
                } + ${window.getComputedStyle(this.$refs.item).marginBottom}`

                let total = ''
                for (let i = 0; i < this.item.children.length; i++) {
                    if (this.item.children[i].meta.hidden || this.item.children[i].meta.hidden) continue
                    if (total === '') total = oneItemHeight
                    else total = `${total} + ${oneItemHeight}`
                }
                return total
            },
        },
        watch: {
            $route: {
                handler() {
                    this.autoShowSub()
                },
                deep: true,
            },
        },
        mounted() {
            this.autoShowSub()
        },
        methods: {
            handleClick() {
                if (this.isMenu) {
                    if (this.visible) {
                        this.onChangeSub(false)
                    } else {
                        this.onChangeSub(true)
                    }
                    this.visible = !this.visible
                } else {
                    this.$router.push({path: this.item.path})
                }
            },
            autoShowSub() {
                if (this.isMenu) {
                    let isShow = false
                    for (let i = 0; i < this.getRulesPath.length; i++) {
                        if (this.item.name.toLowerCase() === this.getRulesPath[i]) {
                            isShow = true
                            break
                        }
                    }
                    this.onChangeSub(isShow)
                    this.visible = isShow
                }
            },
            onChangeSub(isShow) {
                if (isShow) {
                    if (!this.visible) {
                        this.arrowClass = 'svg_icon_arrow'
                        this.$refs.sub.style.height = `calc(${this.subHeight})`
                        this.$refs.sub.style.transition = 'height 0.3s ease-in-out'
                    }
                    /**
                     * 多余3级目录后会受到父height的影响导致展示不全的问题，如果还想有动画效果：
                     * 1、粗暴的方案，是设置max-height为一个无法达到的值，来解决auto高度不能进行动画的问题
                     * 2、通过js计算向上一级一级的重设父的height todo：有空时思考解决办法
                     */
                    // this.$refs.sub.style.maxHeight = '500px'
                    // this.$refs.sub.style.transition = 'max-height 1s ease-out'
                } else {
                    this.arrowClass = 'svg_icon_arrow svg_icon_arrow-isClick'
                    this.$refs.sub.style.height = '0'
                    // this.$refs.sub.style.maxHeight = '0'
                    // this.$refs.sub.style.transition = 'max-height 0.2s ease-out'
                }
            },
            handleMouseEnter() {
                if (this.isMenu) this.$refs.bg.style.display = 'none'
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~@/za/styles/mixin.scss';

    .sub-item {
        width: $base-left-menu-btn-width;
        height: auto;
        .item {
            width: $base-left-menu-btn-width;
            height: $base-left-menu-btn-height;
            margin-bottom: $base-side-margin;
            position: relative;
            &:hover .bg {
                opacity: 1;
                transition: all 0.4s;
            }

            & > div {
                width: 100%;
                height: 100%;
                position: absolute;
                cursor: pointer;
            }

            .bg {
                z-index: 1;
                background-color: #336FFE;
                opacity: 0;

            }

            .active_bg {
                opacity: 1;
            }
            .isMenu:hover{
                color: #FFFFFF;
            }
            .route_name {
                z-index: 2;
                @include flex_div($padding: 0, $just: flex-start);
                @include font_txt($color: $base-font-color-black-60);
                display: inline-flex;

                .left {
                    flex: 8;
                    .svg_icon {
                        font-size: 18px;
                    }

                    & span {
                        margin-left: 10px;
                        font-size: 14px;
                    }
                }

                .svg_icon_arrow {
                    font-size: 14px;
                    transform: rotate(0deg);
                    transition: all 0.4s ease-out;
                }

                .svg_icon_arrow-isClick {
                    transform: rotate(-180deg);
                    transition: all 0.4s ease-out;
                }
            }

            .active_name {
                color: #FFFFFF;
            }
        }

        .sub-menu {
            height: 0;
            transition: height 0.4s;
            overflow: hidden;
            .route_name:hover{
                color: #000000 !important;
                .left{
                    color: #FFFFFF;
                }

            }
        }
    }
</style>
