var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v(_vm._s(_vm.title))]),
    ]),
    _c(
      "div",
      { staticClass: "backOrder_center" },
      [
        _c(
          "el-tabs",
          {
            model: {
              value: _vm.activeName,
              callback: function ($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName",
            },
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "弹性云服务器", name: "first" } },
              [_c("comPage", { attrs: { tabData: _vm.tabData } })],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "轻量应用服务器", name: "second" } },
              [_c("comPage")],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "DDos防护", name: "third" } },
              [_c("comPage")],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "SSL证书", name: "four" } },
              [_c("comPage")],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }