<template>
    <div>
        <div class="heard">
            <div class="center">
                <div class="ignore_center" @click="officialWeb">
                    <el-image class="pic-error-parent" :src="require('@/assets/home/home_logo.png')"/>
                </div>
                <div class="title">
                    {{title}}
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    export default {
        props:{
            title:{
                type:String,
                default:''
            }
        },
        data() {
            return{}

        },
        methods:{
            officialWeb(){
                window.open(`${process.env.VUE_APP_PORTAL_TARGET_URL}`, '_self',)
            }
        }
    }
</script>
<style lang="scss" scoped>
.heard{
    width: 100%;
    min-width: 1220px;
    height: 50px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px 2px rgba(68,68,68,0.2);
    z-index: 100;
    padding-top: 10px;
    padding-bottom: 10px;
    position: absolute;

    .center{
        position: relative;
        left: 1%;
        height: 50px;
        width: 800px;
        display: flex;
        .ignore_center {
            width: 104px;
            height: 30px;
            box-sizing: border-box;
            cursor: pointer;
        }
        .title{
            font-size: 14px;
            float: left;
            width: 104px;
            height: 30px;
            box-sizing: border-box;
            margin-left: 66px;
            margin-top: 5px;
        }
    }
}

::v-deep{
    .el-image__inner{
        height: 30px !important;
    }
}
</style>