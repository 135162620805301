var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "za-loading",
          rawName: "v-za-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "moneySurplus",
    },
    [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "title" },
          [
            _c("span", [_vm._v("返佣余额")]),
            _c(
              "el-tooltip",
              { attrs: { effect: "light", placement: "right-start" } },
              [
                _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                  _c("div", { staticClass: "orange_font" }, [
                    _vm._v(
                      "返佣余额:指当前账号通过推广下级用户消费所获得的收益"
                    ),
                  ]),
                  _c("div", { staticClass: "grey_font" }, [
                    _vm._v("返佣余额提现需要先将余额转出到可提现钱包。"),
                  ]),
                ]),
                _c("i", { staticClass: "el-icon-info blue_font" }),
              ]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "main" }, [
          _c("div", [
            _c("span", [_vm._v(_vm._s(_vm.withdrawFee))]),
            _c("span", [_vm._v("元")]),
          ]),
          _vm.isCloudPushOfficer
            ? _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.navigateTo("transfer")
                        },
                      },
                    },
                    [_vm._v("转出")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.navigateTo("withdraw")
                        },
                      },
                    },
                    [_vm._v("提现")]
                  ),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.toPath } },
                    [_vm._v("加入渠道")]
                  ),
                ],
                1
              ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: { width: "800px", visible: _vm.withdrawOutDialog },
          on: {
            "update:visible": function ($event) {
              _vm.withdrawOutDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "prompt" },
            [
              _c("za-svg-icon", {
                staticClass: "svgIcon",
                attrs: { "icon-class": "za-wanning" },
              }),
              _c("span", [
                _vm._v(
                  "您当前所登录的账号云推官身份已失效，若您的账号中存在未结算完成的资金，请联系客服人员申请全部提出。"
                ),
              ]),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.withdrawOutDialog = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }