var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "za-loading",
              rawName: "v-za-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          staticClass: "history",
        },
        [
          _c("div", { staticClass: "title" }, [
            _c("div", [_vm._v("开票历史")]),
          ]),
          _c(
            "div",
            { staticClass: "occ-form" },
            [
              _c(
                "za-query-form",
                [
                  _c(
                    "za-query-form-left-panel",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form",
                        {
                          attrs: { inline: true, model: _vm.pageObj },
                          nativeOn: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  _vm.translateTitle("askfor.timer") + "：",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "daterange",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                },
                                on: { change: _vm.datePickEnd },
                                model: {
                                  value: _vm.timeValue,
                                  callback: function ($$v) {
                                    _vm.timeValue = $$v
                                  },
                                  expression: "timeValue",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "za-button",
                                {
                                  attrs: { icon: _vm.buttonIconObj.SEARCH },
                                  on: { click: _vm.handleQuery },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.translateTitle("btn.query")) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "za-button",
                                {
                                  attrs: { type: _vm.buttonTypeObj.GREY },
                                  on: { click: _vm.handleResetQuery },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.translateTitle("btn.reset")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "occ-table" },
                [
                  _c("occ-table", {
                    attrs: {
                      tableData: _vm.tableData,
                      columObj: _vm.columObj,
                      isOp: _vm.columObj.isOp,
                    },
                    on: { select: _vm.result },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "funBtn" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDetails(row)
                                      },
                                    },
                                  },
                                  [_vm._v("详情")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "text",
                                      disabled: !(
                                        row.invoicesState === "已开票" &&
                                        row.medium === "电子发票"
                                      ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleRefund(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 退票 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "text",
                                      disabled:
                                        _vm.getTimeFormat(row.invoicesAt) +
                                          86400 <
                                          _vm.currentTime ||
                                        row.invoicesState !== "待处理",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.revocation(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 撤销申请 ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                attrs: {
                  limit: _vm.pageObj.pageSize,
                  page: _vm.pageObj.pageNo,
                  total: _vm.total,
                },
                on: {
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.pageObj, "pageSize", $event)
                  },
                  "update:page": function ($event) {
                    return _vm.$set(_vm.pageObj, "pageNo", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          ),
          _c(
            "za-dialog",
            {
              attrs: {
                "top-title": "发票详情",
                visible: _vm.dialogTicket,
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogTicket = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "ticketInfo" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        disabled: _vm.confirmBtnLoading,
                        model: _vm.baseForm,
                        "label-position": "right",
                        "label-width": "140px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "发票抬头" + "：" } },
                                [
                                  _c("span", { staticClass: "tips" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.baseForm.invoicesHead || "暂无"
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "发票金额" + "：" } },
                                [
                                  _c("span", { staticClass: "tips" }, [
                                    _vm._v(
                                      "￥" +
                                        _vm._s(
                                          _vm.baseForm.invoicesAmount || "暂无"
                                        )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "发票类型" + "：" } },
                                [
                                  _c("span", { staticClass: "tips" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.baseForm.invoicesType || "暂无"
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "发票状态" + "：" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "tips",
                                      style: {
                                        color:
                                          _vm.baseForm.invoicesState == "已奇出"
                                            ? "green"
                                            : "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.baseForm.invoicesState || "暂无"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "za-dialog",
            {
              attrs: {
                "top-title": "退票申请",
                visible: _vm.dialogVisible,
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c("div", { staticClass: "prompt" }, [
                    _c(
                      "span",
                      { staticStyle: { "margin-left": "16px" } },
                      [
                        _c("za-svg-icon", {
                          attrs: { "icon-class": "za-wanning" },
                        }),
                      ],
                      1
                    ),
                    _vm.mediumType === 1
                      ? _c("span", [
                          _vm._v(
                            " 温馨提示: 退票申请一旦提交后无法撤回，且需要您承担邮寄费用，请谨慎操作！ "
                          ),
                        ])
                      : _vm._e(),
                    _vm.mediumType === 2
                      ? _c("span", [
                          _vm._v(
                            " 温馨提示: 请您在发起退票之前，先在邮箱里查找相应的发票号码和代码，并输入到系统中，以确保退票操作能成功进行 ！ "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        disabled: _vm.confirmBtnLoading,
                        model: _vm.returnForm,
                        "label-position": "right",
                        "label-width": "140px",
                        rules: _vm.rules,
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "退票金额" + "：" } },
                                [
                                  _c("span", { staticClass: "refundMoney" }, [
                                    _vm._v(
                                      _vm._s(_vm.returnForm.money + "元" || "")
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "退票原因" + "：",
                                    prop: "returnType",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      model: {
                                        value: _vm.returnForm.returnType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.returnForm,
                                            "returnType",
                                            $$v
                                          )
                                        },
                                        expression: "returnForm.returnType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.serverMsgTypeOption,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.name,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _vm.mediumType === 1
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "材料要求" + "：",
                                        prop: "mobile",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "requirement" },
                                        [
                                          _c("div", [
                                            _c("span", [
                                              _vm._v(" 请将 "),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "14px",
                                                    "font-weight": "500",
                                                  },
                                                },
                                                [_vm._v("发票原件")]
                                              ),
                                              _vm._v(" 邮寄给 "),
                                            ]),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "requireMain" },
                                            [
                                              _c("div", [
                                                _c("span", [
                                                  _vm._v("收件人："),
                                                ]),
                                                _c("span", [_vm._v("张静")]),
                                              ]),
                                              _c("div", [
                                                _c("span", [
                                                  _vm._v("联系电话："),
                                                ]),
                                                _c("span", [
                                                  _vm._v("13510781355"),
                                                ]),
                                              ]),
                                              _c("div", [
                                                _c("span", [
                                                  _vm._v("联系地址："),
                                                ]),
                                                _c("span", [
                                                  _vm._v(
                                                    "深圳市南山区南山街道阳光科创中心B座2207"
                                                  ),
                                                ]),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.mediumType === 2
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "发票号码" + "：", prop: "fphm" },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请填写发票号码" },
                                model: {
                                  value: _vm.returnForm.fphm,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.returnForm, "fphm", $$v)
                                  },
                                  expression: "returnForm.fphm",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.mediumType === 2
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "发票代码" + "：", prop: "fpdm" },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请填写发票代码" },
                                model: {
                                  value: _vm.returnForm.fpdm,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.returnForm, "fpdm", $$v)
                                  },
                                  expression: "returnForm.fpdm",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.mediumType === 1
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "退票快递公司" + "：",
                                prop: "textarea",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请填写快递公司" },
                                model: {
                                  value: _vm.returnForm.textarea,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.returnForm, "textarea", $$v)
                                  },
                                  expression: "returnForm.textarea",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.mediumType === 1
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "退票快递单号" + "：",
                                prop: "textarea",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请填写寄出的快递单号" },
                                model: {
                                  value: _vm.returnForm.textarea,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.returnForm, "textarea", $$v)
                                  },
                                  expression: "returnForm.textarea",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "退票说明" + "：", prop: "desc" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 4,
                                  placeholder: "详细的退票原因说明，100字以内",
                                  maxlength: "100",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.returnForm.desc,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.returnForm, "desc", $$v)
                                  },
                                  expression: "returnForm.desc",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "za-button",
                    {
                      attrs: {
                        disabled: _vm.confirmBtnLoading,
                        type: _vm.buttonTypeObj.GREY,
                      },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.translateTitle("btn.cancel")) + " "
                      ),
                    ]
                  ),
                  _c(
                    "za-button",
                    {
                      attrs: {
                        loading: _vm.confirmBtnLoading,
                        type: _vm.buttonTypeObj.BLUE,
                      },
                      on: { click: _vm.handleSubmitForm },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.translateTitle("btn.submit")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }