<template>
  <div>
    <el-dropdown :placement="'bottom'">
      <span class="dropdown-text">
        {{ title }}
      </span>
      <el-dropdown-menu slot="dropdown">
        <div class="top_dropdown_money">
          <div class="top_first">
            <div>可用余额</div>
            <div @click="$router.push('/moneyCenter/expenseManagement/topUp')">立即充值></div>
          </div>
          <div class="money">
            <span>¥ {{ balance.balance }}</span>
            <span></span>
          </div>
          <div class="top_volume">
            <div @click="$router.push('/moneyCenter/cardRoll/voucher')">代金券</div>
            <div @click="$router.push('/moneyCenter/cardRoll/coupon')">折扣券</div>
            <div @click="$router.push('/moneyCenter/invoiceManagement/askfor')">发票</div>
          </div>
        </div>
        <el-dropdown-item v-for="(item, index) in optionsList" :key="index" @click.native="toPath(item.path)">
          <template>
            <za-svg-icon :icon-class="item.icon" class-name="svg-item"></za-svg-icon>
            <span>{{ item.name }}</span>
          </template>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      optionsTitle: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        title: this.optionsTitle,
        optionsList: [
          { id: 1, name: '消费总览', path: '/moneyCenter/overview', icon: 'za-nav-overview' },
          { id: 2, name: '交易流水', path: '/moneyCenter/expenseManagement/details', icon: 'za-nav-details' },
          { id: 3, name: '订单管理', path: '/moneyCenter/orderManagement/myOrder', icon: 'za-nav-management' },
        ],
        num: this.orderNum,
      }
    },
    computed: {
      ...mapGetters({
        balance: 'user/balance',
      }),
    },
    methods: {
      toPath(val) {
        this.$router.push(val)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .dropdown-text {
    color: black;
    font-size: 13px;
  }

  .top_dropdown_money {
    width: 230px;

    .top_first {
      width: 100%;
      display: flex;
      justify-content: space-around;
      font-size: 13px;

      div:last-child {
        cursor: pointer;
        color: #3370ff;
      }
    }
    .money {
      font-size: 25px;
      margin: 10px 15px;

      span:first-child {
        margin-left: 16px;
      }

      span:last-child {
        margin-left: 5px;
        font-size: 12px;
      }
    }

    .top_volume {
      cursor: pointer;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      text-align: center;
      border-bottom: 1px solid rgba(245, 245, 245, 1);

      div {
        background-color: #fef4e9;
        margin-left: 5px;
        margin-right: 5px;
        padding: 5px 0px;
        margin-bottom: 10px;
        font-size: 12px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #f38800;
      }
    }
  }

  .svg-item {
    margin-right: 9px;
    font-size: 16px;
    vertical-align: middle;
  }
</style>