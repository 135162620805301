<template>
    <div class="msgPersonner-container">
        <div class="title">接受设置</div>
        <div>
            <div v-for="(item,index) in list" :key="index" class="main">
                <div class="master_top">
                    <div class="master_title">{{item.name}}</div>
                    <!-- <div>
                        <el-switch
                                @change="handleChange(item)"
                                v-model="item.value"
                                active-color="#13ce66"
                                inactive-color="#ff4949">
                        </el-switch>
                    </div> -->
                </div>
                <div v-for="(childItem,childIndex) in item.mConfig2" :key="childIndex" class="master_main">
                    <div>{{childItem.name}}</div>
                    <div>
                        <el-switch
                                v-model="childItem.value"
                                :active-value="1"
                                :inactive-value="0"
                                active-color="#13ce66"
                                inactive-color="#ff4949"
                                @change="childChange(childItem)"
                        >
                        </el-switch>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { apiMsgConfigGet, apiMsgConfigEdit } from '@/api/msgCenter/msgIndex'

    export default {
        data() {
            return {
                list: []
            }
        },
        created() {
            // this.changeList()
            this.queryConfigList()
        },
        methods: {

            queryConfigList(){
                apiMsgConfigGet().then(res => {
                    if (res && res.data.mConfig1 && res.code === 0) {
                        this.list = res.data.mConfig1
                    }else{
                        this.list = [] 
                    }
                })
            },
        
            changeList() {
                this.list.forEach((item) => {
                    if (!item.state) {
                        item.children.forEach((val) => {
                            val.state = false
                        })
                    } else {
                        item.children.forEach((val) => {
                            val.state = true
                        })
                    }
                })
            },
            /**
             * 父开关只要打开，子开关全部打开
             * */
            handleChange(val) {
                val.children.forEach((item) => {
                    item.state = val.state
                })
            },
            childChange(child) {
                /**
                 * 1:只要是子开关为开启状态，父开关都要打开
                 * 2：当所有子开关为关闭状态，父开关也要是关闭状态
                 * */
                // 15日上线不上父开关功能
                // if (child.state) {
                //     val.state = true
                // } else {
                //     let result = val.children.filter((item) => {
                //         return item.state == false
                //     })
                //     if (result.length == val.children.length) {
                //         val.state = false
                //     } else {
                //         val.state = true
                //     }
                // }
                apiMsgConfigEdit({ key: child.key, value: child.value } ).then(res => {
                    if (res && res.code === 0) {
                        this.queryConfigList();
                    }
                })

            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '~@/za/styles/mixin.scss';

    .title {
        @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
        width: 100%;
        height: 40px;
        margin-bottom: 20px;
        background-color: #FFFFFF;
    }

    .main {
        font-size: 14px;

        .master_top {
            display: flex;
            justify-content: space-between;
            height: 50px;
            background-color: rgba(248, 248, 248, 1);
            width: 100%;
            padding: 15px 50px 10px 20px;
            color: #000000
        }

        .master_main {
            display: flex;
            justify-content: space-between;
            height: 50px;
            background-color: #FFFFFF;
            padding: 15px 50px 10px 50px;
            border-bottom: 1px solid #f4f6fa;
        }
    }
</style>