import request from '@/utils/request'
import { CERTIFICATION, METHOD,GET_CERTIFICATION,GET_CORPORATE,GET_PERSONAL} from '@/api/api.config'



/**
 * @description 实名认证接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiCertification(data) {
    return request({
        url: CERTIFICATION,
        method: METHOD.GET,
        params: data,
    })
}
/**
 * @description 实名认证信息接口
 * @param data
 * @returns {AxiosPromise}
 */
export function apiGetCertification(data) {
    return request({
        url: GET_CERTIFICATION,
        method: METHOD.GET,
        params: data,
    })
}
/**
 * @description 实名认证修改为企业认证
 * @param data
 * @returns {AxiosPromise}
 */
export function apiGetCorporateCertification(data) {
    return request({
        url: GET_CORPORATE,
        method: METHOD.GET,
        params: data,
    })
}
/**
 * @description 实名认证修改为个人认证
 * @param data
 * @returns {AxiosPromise}
 */
export function apiGetPersonalCertification(data) {
    return request({
        url: GET_PERSONAL,
        method: METHOD.GET,
        params: data,
    })
}