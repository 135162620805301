<template>
    <div class="userHomeInfo">
        <div class="user_top">
            <div>
                <el-image ref="image" class="icon-html" fit="cover" :src="icoUrl()">
                    <div slot="error">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </el-image>
            </div>
            <div class="user_text">
                <div>{{userInfo.username}}</div>
                <div class="user_certification" @click="$router.push('/accountCenter/nameCertification')">
                    <div v-if="userInfo.accountType==0">
                        <div class="not_certification">未认证</div>
                        <div class="now" @click="$router.push('/accountCenter/nameCertification')">立即认证</div>
                    </div>
                    <div v-if="userInfo.accountType==2">
                        <div class="not_certification individual">个人认证</div>
                        <div class="now" @click="$router.push('/accountCenter/nameCertification')">升级为企业认证</div>
                    </div>
                    <div>
                        <div v-if="userInfo.accountType==1" class="not_certification enterprise">企业认证</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="infoId">
            <div>
                <span>账号ID：</span>
                <span>{{userInfo.uuid}}</span>
                <span>
                    <i class="el-icon-copy-document copyDocument" @click="copy(userInfo.uuid)"></i>
                   </span>
            </div>
            <div>
                <span>登录IP：</span>
                <span>{{userInfo.ip}}</span>
            </div>
        </div>
        <div class="info_safety">
            <div class="title">账号安全</div>
            <div class="infoName">
                <div class="infoIcon" @click="toSafetySet">
                    <div>
                        <za-svg-icon :icon-class="userInfo.mobileBind==0?'za-phone':'za-certification-phone'"/>
                    </div>
                    <div  class="beautiful">手机绑定</div>
                </div>
<!--                <div class="infoIcon" @click="toSafetySet">-->
<!--                    <div>-->
<!--                        <za-svg-icon :icon-class="userInfo.weChatBind==0?'za-wexin':'za-certification-wexin'"/>-->
<!--                    </div>-->
<!--                    <div  class="beautiful">微信绑定</div>-->
<!--                </div>-->
                <div class="infoIcon" @click="toSafetySet">
                    <div>
                        <za-svg-icon :icon-class="userInfo.emailBind==0?'za-mailbox':'za-certification-mailbox'"/>
                    </div>
                    <div class="beautiful">邮箱绑定</div>
                </div>
                <div class="infoIcon" @click="toSafetySet">
                    <div>
                        <za-svg-icon  :icon-class="userInfo.securityBind==0?'za-pwd':'za-certification-pwd'"/>
                    </div>
                    <div  class="beautiful ">密码保护</div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import {mapGetters} from "vuex";

    export default {
        data() {
            return {
            }
        },
        computed: {
            ...mapGetters({
                userInfo: 'user/userInfo',
                routes: 'routes/routes',
            })
        },
        methods: {
            icoUrl() {
                if (this.userInfo.profile) {
                    return this.userInfo.profile
                } else {
                    return require('@/assets/home/default.png')
                }
            },
            toSafetySet() {
                this.$router.push('/accountCenter/safetySet')
            }
        }
    }
</script>
<style lang="scss" scoped>
    .userHomeInfo {
        width: 50%;
        height: 210px;
        background: #FFFFFF;
        border-radius: 2px;
        padding: 20px;

        .user_top {
            display: flex;

            .icon-html {
                width: 45px;
                height: 45px;
                border-radius: 50%;
                margin-top: 2px;
            }

            .user_text {
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #000000;
                margin-left: 14px;

                .user_certification {
                    width: 200px;
                    margin-top: 5px;
                    cursor: pointer;
                    div {
                        display: flex;

                        width: 100%;
                    }

                    /*F38800*/
                    /*#5BB943*/
                    .not_certification {
                        width: 50%;
                        height: 24px;
                        background: #999999;
                        border-radius: 1px;
                        font-size: 12px;
                        font-weight: 300;
                        color: #FFFFFF;
                        line-height: 24px;
                        text-align: center;
                        display: inline-block;
                    }

                    .individual {
                        background: #F38800;
                        color: #FFFFFF;
                    }
                    .enterprise{
                        background: #5BB943;
                        color: #FFFFFF;
                    }

                    .now {
                        width: 50%;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #3370FF;
                        margin-left: 15px;
                        line-height: 24px;
                    }
                }


            }

        }

        .infoId {
            display: flex;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;
            margin-top: 2%;
            margin-bottom: 14px;

            div:first-child {
                margin-left: 9%;
            }

            div:last-child {
                margin-left: 40px;
            }

            div {
                span:last-child {
                    color: #000000;
                }
            }
        }

        .info_safety {
            height: 76px;
            background: #F4F6FA;
            border-radius: 2px;
            width: 100%;
            padding: 12px 14px;


            .title {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                margin-bottom: 5px;
                color: #000000;
            }

            .infoName {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr ;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;

                .infoIcon {
                    display: flex;
                    cursor: pointer;

                    div:first-child {
                        font-size: 24px;
                    }

                    div:last-child {
                        margin-top: 8px;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
</style>