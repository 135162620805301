<template>
    <div>
        <div class="title">
            <span>{{title}}</span>
        </div>
        <div class="backOrder_center">
            <el-tabs v-model="activeName">
                <el-tab-pane label="有效订单列表" name="first">
                    <effective></effective>
                </el-tab-pane>
                <el-tab-pane label="无效订单列表" name="second">
                    <voider></voider>
                </el-tab-pane>
                <el-tab-pane label="退降配订单" name="third">
                    <refund></refund>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
    import effective from './components/effective'
    import voider from './components/voider'
    import refund from './components/refund'


    export default {
        components: {effective,voider, refund},
        data() {
            return {
                title: '',
                activeName: 'first'
            }
        },
        created() {
            this.title = this.$route.meta.title
        }
    }
</script>
<style lang="scss" scoped>
    .title {
        height: 62px;
        background: #FFFFFF;
        border-radius: 2px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        padding-left: 20px;
        line-height: 62px;
        margin-bottom: 20px;
    }

    .backOrder_center {
        background: #FFFFFF;
        padding: 10px 20px 20px 20px;
    }
    ::v-deep{
        .el-tabs__item{
            font-weight: 600 !important;
        }
    }
</style>