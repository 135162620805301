var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "progress", style: { "--color": _vm.bgColor } },
    [
      _c("div", { staticClass: "txt-con" }, [
        _c(
          "span",
          {
            on: {
              click: function ($event) {
                return _vm.$emit("click")
              },
            },
          },
          [_vm._v(_vm._s(_vm.name))]
        ),
        _c("span", [_vm._v(_vm._s(_vm.perValue(_vm.curValue)))]),
      ]),
      _c("el-progress", {
        attrs: {
          color: _vm.color,
          percentage: _vm.perValue(),
          "show-text": false,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }