/**
 * ------------------------------------------------------------------
 * Login数据模块
 * ------------------------------------------------------------------
 */

/**
 * @description 登录请求数据结构
 * @property {String} password 密码
 * @property {String} username 账号
 * @type {{password: string, otp: string, username: string, force: boolean}}
 */
export const reqLoginObj = {
  password: '',
  username: '',
  verifyValue: '',
  captchaId: '',
  code: '',
}
/**
 * @description 注册请求数据结构
 * @property {String} account 账号
 * @property {String} company 公司名
 * @property {String} email 邮箱
 * @property {String} mobile 手机号
 * @property {String} password 密码
 * @property {String} confirmPassword 密码
 * @property {String} popularizeCode 推广id
 * @type {{password: string, mobile: string, company: string, account: string, email: string, confirmPassword: string}}
 */
export const reqRegister = {
  username: '',
  password: '',
  phone: '',
  smsCode: '',
  confirmPassword: '',
  popularizeCode: '',
}

/**
 * @description 修改密码的提示数据结构
 * @property {String} title 提示标题
 * @property {String} pwd_rules 密码规则
 * @property {String} token token
 * @type {{pwd_rules: string, title: string, token: string}}
 */
export const reqForgetTipsObj = {
  title: '',
  pwd_rules: '',
  token: '',
}

/**
 * @description 获取用户信息的数据结构
 * @property {String} token token
 * @type {{token: string}}
 */
export const reqUserInfoObj = {
  token: '',
}

/**
 * @description 获取发票历史详情的数据结构
 * @property {String} token token
 * @type {{token: string}}
 */
export const reqHistoryInfoObj = {
  invoicesHead: '',
  invoicesAmount: '',
  invoicesType: '',
  medium: '',
  invoicesNumber: '',
  invoicesState: '',
  addressInfo: '',
}
/**
 * @description 获取发票信息的数据结构
 * @property {String} token token
 * @type {{token: string}}
 */
export const reqInvoicesInfoObj = {
  head: '',
  invoicesTypeName: '',
  invoicesType: '',
  headType: '',
  taxpayerNumber: '',
  bankName: '',
  bankAccount: '',
  logonAddress: '',
  logonPhone: '',
  header: '',
  headers: '',
  organizationHeader: '',
  taxpayerNumberFirm: '',
}

export const reqverificationObj = {
  oneQuestion: '',
  twoQuestion: '',
  threeQuestion: '',
  oneAnswer: '',
  twoAnswer: '',
  threeAnswer: '',
  phone: '',
  smsCode: '',
  email: '',
  NewEmail: '',
}
export const reqAdress = {
  consignee: '',
  province: '',
  city: '',
  area: '',
  address: '',
  mobile: '',
}
export const reqSeleObj = {
  oneQuestion: '',
  twoQuestion: '',
  threeQuestion: '',
}
