var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product-overview" },
    [
      _c("h1", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
      _c("p", { staticClass: "desc" }, [_vm._v(_vm._s(_vm.desc))]),
      _c(
        "main",
        _vm._l(_vm.list, function (item, index) {
          return _c("div", { key: index }, [
            _c("img", { attrs: { src: item.icon, alt: "" } }),
            _c("h2", [_vm._v(_vm._s(item.title))]),
            _c("p", [_vm._v(_vm._s(item.desc))]),
          ])
        }),
        0
      ),
      _vm.active
        ? _c(
            "el-button",
            {
              attrs: { size: "medium", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$emit("click")
                },
              },
            },
            [_vm._v(_vm._s(_vm.translateTitle("btn.goUse")) + " ")]
          )
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }