<template>
  <div>
    <div class="title">
      <span>{{ title }}</span>
    </div>
    <div class="backOrder_center">
      <effective></effective>
      <!-- <el-tabs v-model="tag" @tab-click="handleClick">
                <el-tab-pane label="有效订单列表" name="1">
                    <effective :tag="1"></effective>
                </el-tab-pane> -->
      <!--                <el-tab-pane label="无效订单列表" name="second">-->
      <!--                    <voider></voider>-->
      <!--                </el-tab-pane>-->
      <!-- <el-tab-pane label="退降配订单" name="2">
                    <refund :tag="2"></refund>
                </el-tab-pane>
            </el-tabs> -->
    </div>
  </div>
</template>
<script>
import effective from './components/effective'
import voider from './components/void'
import refund from './components/refund'

export default {
  components: { effective, voider, refund },
  data() {
    return {
      title: '',
      tag: '1',
    }
  },
  created() {
    this.title = this.$route.meta.title
  },
  methods: {
    handleClick(tab) {
      // this.tag=tab.name
    },
  },
}
</script>
<style lang="scss" scoped>
.title {
  height: 62px;
  background: #ffffff;
  border-radius: 2px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  padding-left: 20px;
  line-height: 62px;
  margin-bottom: 20px;
}

.backOrder_center {
  background: #ffffff;
  padding: 10px 20px 20px 20px;
}

::v-deep {
  .el-tabs__item {
    font-weight: 600 !important;
  }
}
</style>