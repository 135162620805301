import request from '@/utils/request'
import {UPLOAD_ICO_PATH } from '@/api/api.config'
import { uploadTimeout } from '@/config'
/**
 * @description 上传图片接口
 * @param file
 * @param callBackFun
 * @returns {AxiosPromise}
 */
export function apiUploadImg(file) {
    return request({
        url: UPLOAD_ICO_PATH,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        method: 'post',
        data: file,
        timeout: uploadTimeout,
        transformRequest: [
            function () {
                return file
            },
        ],
    })
}
