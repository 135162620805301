<template>
  <div class="myHomeResources" v-za-loading="isLoading">
    <div class="title">我的资源</div>
    <div style="display: flex">
      <div v-for="(item, index) in list" class="listStyle" :key="index" @click="path(item.path)">
        <p style="margin: 0">
          <span>
            <za-svg-icon :icon-class="item.icon" class-name="svg-item"></za-svg-icon>
          </span>
          <span>{{ item.name }}</span>
        </p>
        <p class="listNum">
          <span v-if="index == 0">{{ listNum.bccNumber }}</span>
          <span v-if="index == 1">{{ listNum.bosNumber }}</span>
          <span v-if="index == 2">{{ listNum.bcdNumber }}</span>
          <span v-if="index == 3">{{ listNum.cdnNumber }}</span>
          <span v-if="index == 4">{{ listNum.casNumber }}</span>
          <span v-if="index == 5">{{ listNum.rdsNumber }}</span>
          <!-- <span>台</span> -->
        </p>
      </div>
    </div>
  </div>
</template>
<script>
  import { apiGetResourceNumber } from '@/api/home'

  export default {
    data() {
      return {
        list: [
          { name: 'BCC云服务器', icon: 'za-home-yun', path: 'esc' },
          { name: '对象存储', icon: 'za-home-computer', path: 'bos' },
          { name: '域名', icon: 'za-home-yuming', path: 'bcd' },
          { name: 'CDN节点', icon: 'za-home-cdn', path: 'cdn' },
          { name: 'SSL证书', icon: 'za-home-ssl', path: 'cas' },
          { name: '云数据库RDS', icon: 'za-home-rds', path: 'mysql' },
        ],
        listNum: {
          bccNumber: null,
          bchNumber: null,
          bcdNumber: null,
          cdnNumber: null,
          casNumber: null,
          rdsNumber: null,
          bosNumber: null,
        },
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.onSubmitData(apiGetResourceNumber, null, null, false, false, (res) => {
          this.listNum.bccNumber = res.data.bccNumber
          this.listNum.bchNumber = res.data.bchNumber
          this.listNum.bcdNumber = res.data.bcdNumber
          this.listNum.cdnNumber = res.data.cdnNumber
          this.listNum.casNumber = res.data.casNumber
          this.listNum.rdsNumber = res.data.rdsNumber
          this.listNum.bosNumber = res.data.bosNumber
        })
      },
      path(val) {
        this.$router.push({ path: '/business', query: { url: val } })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .myHomeResources {
    width: 100%;
    height: 164px;
    background: #ffffff;
    border-radius: 2px;
    margin-bottom: 16px;
    padding: 25px;

    .title {
      width: 100%;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 20px;
      margin-bottom: 14px;
    }

    .listStyle {
      width: 270px;
      height: 90px;
      background: #f4f6fa;
      border-radius: 2px;
      border: 1px solid #eef1fc;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      padding: 10px;
      cursor: pointer;
      margin-right: 16px;

      .listNum {
        margin-top: 20px;
        margin-bottom: 0;

        span:first-child {
          font-size: 22px;
        }
      }
    }

    .listStyle:hover {
      background: #1b5deb;
      color: #ffffff;
    }

    .svg-item {
      margin-right: 9px;
      font-size: 20px;
    }
  }
</style>