<template>
  <el-tooltip content="点击复制" effect="dark" placement="bottom">
    <span :class="className" :data-clipboard-text='content' style="cursor: pointer" @click="copy(className)">{{
        chief || content
      }}</span>
  </el-tooltip>
</template>

<script>
import Clipboard from 'clipboard'

export default {
  name: "ZaCopy",
  props: {
    content: {
      type: String,
      default: '文字'
    },
    className: {
      type: String,
      default: 'content'
    },
    chief: {
      type: String,
      default: ''
    }
  },
  methods: {
    copy(className) {
      const clipboard = new Clipboard('.' + className)
      clipboard.on('success', e => {
        // console.log(e)
        this.$msgSuccess('复制成功')
        //  释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // console.log(e)
        // 不支持复制
        this.$msgWarning('该浏览器不支持复制')
        // 释放内存
        clipboard.destroy()
      })
    }
  },
}
</script>

<style scoped>

</style>
