var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "info-center_bg" }, [
    _c(
      "div",
      [
        _c("za-go-back", {
          attrs: { title: "订单信息" },
          on: { click: _vm.goBack },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "za-loading",
            rawName: "v-za-loading",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
        staticClass: "order-card",
      },
      [
        _c("div", { staticClass: "title" }, [_vm._v("订单信息")]),
        _c("div", { staticClass: "infoStyle" }, [
          _c("div", { staticClass: "testInfo" }, [
            _c("span", [_vm._v("订单编号：")]),
            _c("span", [_vm._v(_vm._s(_vm.orderNumber))]),
          ]),
          _c("div", { staticClass: "testInfo" }, [
            _c("span", [_vm._v("订单类型：")]),
            _c("span", [_vm._v(_vm._s(_vm.orderType))]),
          ]),
          _c("div", { staticClass: "testInfo" }, [
            _c("span", [_vm._v("产品名称：")]),
            _c("span", [_vm._v(_vm._s(_vm.serviceName || "-"))]),
          ]),
          _c("div", { staticClass: "testInfo" }, [
            _c("span", [_vm._v("订单状态：")]),
            _c("span", [_vm._v(_vm._s(_vm.orderState))]),
          ]),
          _c("div", { staticClass: "testInfo" }, [
            _c("span", [_vm._v("订单创建时间：")]),
            _c("span", [_vm._v(_vm._s(_vm.createAt))]),
          ]),
          _c("div", { staticClass: "testInfo" }, [
            _c("span", [_vm._v("订单支付时间：")]),
            _c("span", [_vm._v(_vm._s(_vm.payAt))]),
          ]),
          _c("div", { staticClass: "testInfo" }, [
            _c("span", [_vm._v("付费方式：")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.payType == "1"
                    ? "预付费"
                    : _vm.payType == "2"
                    ? "后付费"
                    : "预付+后付费"
                )
              ),
            ]),
          ]),
        ]),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "za-loading",
            rawName: "v-za-loading",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
        staticClass: "order-main",
      },
      [
        _c("div", { staticClass: "title" }, [_vm._v("订单配置")]),
        _c("occ-table", {
          attrs: {
            tableData: _vm.tableData,
            columObj: _vm.columObj,
            isOp: _vm.columObj.isOp,
          },
        }),
        _c("div", { staticClass: "moneyInfo" }, [
          _c("div", [
            _c("span", [_vm._v("订单金额:")]),
            _c("span", [_vm._v("¥ " + _vm._s(_vm.price))]),
          ]),
          _c("div", [
            _c("span", [_vm._v("活动优惠:")]),
            _c("span", [_vm._v("¥ " + _vm._s(_vm.discountPrice))]),
          ]),
          _c("div", [
            _c("span", [_vm._v("订单实付:")]),
            _c("span", [_vm._v("¥ " + _vm._s(_vm.actualPrice))]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }