var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item.meta && !_vm.item.meta.hidden
    ? _c(
        "item-bar",
        { attrs: { "is-menu": _vm.isMenu, item: _vm.item } },
        [
          _vm.item.children && _vm.item.children.length
            ? _vm._l(_vm.item.children, function (route) {
                return _c("item-menu", {
                  key: route.path,
                  attrs: { item: route },
                })
              })
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }