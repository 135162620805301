<template>
    <div>
        <div v-if="show">
<!--            <div :class="[{ enable: enable }, { disabled: !enable }]" class="state"/>-->
            <span v-if=" msg=='1'">充值</span>
            <span v-if=" msg=='2'">新购</span>
            <span v-if=" msg=='3'">续费</span>
            <span v-if=" msg=='4'">提现</span>
            <span v-if=" msg=='5'">退款</span>
            <span v-if=" msg=='wechat'">微信</span>
            <span v-if=" msg=='alipay'">支付宝</span>
            <span v-if=" msg=='chengdu'">成都智安网络</span>
<!--            <div v-for="(item,index) in options"  :key="index">-->
<!--                <span>{{msg}}</span>-->
<!--                <span>{{item}}</span>-->
<!--                <span v-if="msg==item.id">{{msg}}</span>-->
<!--            </div>-->
        </div>
        <div v-else>
            <div :style="{borderColor}" class="state"/>
            <span>{{ txt }}</span>
        </div>
    </div>
</template>

<script>
    import {translateTitle} from '@/utils/i18n'

    export default {
        name: 'ZaState',
        props: {

            show: {
                type: Boolean,
                default: true,
            },
            borderColor: {
                type: String,
                default: 'red'
            },
            txt: {
                type: String,
                default: '失败'
            },
            enable: {
                type: Boolean,
                default: true,
            },
            msg: {
                type: Number|String,
                default: ''
            },
        },
        methods: {
            translateTitle,
        },
        data() {
            return {}
        },
        created() {
            // console.log(this.options,this.msg,'----enable----enable')
        }
    }
</script>

<style lang="scss" scoped>
    @import '~@/za/styles/mixin.scss';

    div {
        @include flex_div($padding: 0);
        justify-content: center;

        .state {
            width: 7px;
            height: 7px;
            border-radius: 50%;
            border: 1px solid transparent;
            margin-right: 13px;
        }

        .enable {
            border-color: #00ffce;
        }

        .disabled {
            border-color: #ff0000;
        }
    }
</style>
