var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c("div", { staticClass: "heardBlock" }, [
        _c(
          "div",
          { staticClass: "ignore_center", on: { click: _vm.officialWeb } },
          [
            _c("el-image", {
              staticClass: "pic-error-parent",
              attrs: { src: require("@/assets/home/home_logo.png") },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "title" }, [_vm._v("信息安全规定")]),
      ]),
    ]),
    _c("div", { staticClass: "main" }, [
      _c(
        "span",
        {
          staticClass: "blue_font",
          on: {
            click: function ($event) {
              return _vm.$router.push("/login")
            },
          },
        },
        [_vm._v("返回")]
      ),
      _vm._m(0),
      _c("h5", [_vm._v("背景")]),
      _c("span", [
        _vm._v(
          " 为保障智安云（以下简称”智安云”）及用户信息安全，深刻践行《互联网信息服务管理办法》、《计算机信息网络国际联网安全保护管理办法》、《文明上网自律公约》、《互联网终端安全服务自律公约》等相关法律法规，特制定本规定。作为对智安云用户服务协议的补充。 "
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("适用对象")]),
      _c("span", [
        _vm._v(
          "使用智安云包括但不仅限于BCC/BLB/CDN/BOS/SMS/BCH等产品的所有用户（包括代理商）。"
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("综述")]),
      _c("span", [
        _vm._v(
          " 用户在使用智安云产品时，应遵守国家法律、行政法规、各部门规章等规范性文件，并自行按照相关法律法规，向相关对象提供合法的产品及服务，履行相关义务，并自行承担全部责任（包括但不限于履行信息网络安全管理义务、健全各项网络安全管理制度和落实各项安全保护技术措施），对任何涉嫌违反国家法律、行政法规、部门规章等规范性文件的行为，本规则已有规定的，适用本规则；本规则尚无规定的，智安云有权酌情进行处理。 "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 说明：智安云对本规则拥有最终解释权，可对本规定进行更新并在智安云官网上予以公告。若用户不同意本规定相关变更，请立即停止使用智安云的相关服务及产品。智安云有权对用户行为及应适用的规则进行单方认定，并根据相关法律法规进行违规判定并进行处理。 "
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("一、信息安全规定细则：")]),
      _c("span", [
        _vm._v(
          "1、用户在购买及使用智安云提供的服务或产品时，必须遵守智安云各类服务或产品的用户协议及其他条款。"
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 2、用户在使用智安云提供的服务或产品时，除了遵守该产品的服务条款之外，还应遵守《智安云信息安全规定》中的如下细则。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " (1) 政治类 不得发布或者存储含有反动、破坏国家统一、破坏主权及领土完整、破坏社会稳定，涉及国家机密、散布政治谣言、扰乱社会秩序，宣扬邪教迷信，宣扬宗教、种族歧视等信息，或法律法规禁止出版发行的书籍、音像制品、视频、文件资料的信息。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " (2) 色情低俗类 不得发布或者存储含有色情淫秽低俗内容的音像制品、视频、音频、图片、动漫等； 不得提供色情陪聊、招嫖服务；不得提供含有可致使他人暂时失去反抗能力、意识模糊的口服或外用的催情类商品或者药品的售卖服务； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布成人网站论坛等信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布或者存储含有用于传播色情信息的软件。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("(3) 侵权类")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "不得发布或存储侵犯个人或企业名誉权、人身攻击、侵犯个人隐私的信息；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 不得发布或存储侵犯个人或企业商标、专利、图书音像制品、电视电影广播版权、网络出版物的信息。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("(4) 医药、保健品、食品类"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "不得发布国家公示已查处、药品监督管理局认定禁止生产、使用的药品的交易信息；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布未经国家药监局批准的药品的交易信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布宣扬有明显药物治疗效果的保健品的交易信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布未获得食品生产许可证及卫生许可证的食品的交易信息。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("(5) 暴恐类")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 不得发布或者存储管制刀具售卖、毒品、爆炸物制作、涉恐消息、涉恐视频、涉恐音频、有暴力倾向的包括但不仅限于文字、图片、音视频、出版物等信息。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("(6) 赌博类")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布或者存储含有赌博交易平台的信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布或者存储非法赌博器具介绍或售卖的信息。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("(7) 诈骗类")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布或者存储含有诈骗类中奖、钓鱼网站等信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布或者存储含有伪基站相关的交易信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布或者存储含有外挂、私服相关的网游类的信息。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("(8) 违禁物品类")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布或者存储含有毒品、制毒原料、制毒化学品的交易信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布或者存储含有剧毒化学品的交易信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "不得发布或者存储含有国家名录中禁止出售的危险化学品（剧毒化学品除外）的交易信息；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "不得发布或者存储含有易燃、易爆物品（烟花爆竹除外）及相关化学品类的交易信息；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "不得发布或者存储介绍制作易燃易爆品方法的相关教程、书籍的交易信息；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布或者存储含有枪支、弹药、军火及仿制品的交易信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "不得发布或者存储含有枪支、弹药、军火的相关器材、配件、附属产品等的交易信息；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "不得发布或者存储含有警用、军用制服、标志、设备及制品的交易信息；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "不得发布或者存储含有可致使他人暂时失去反抗能力，对他人身体造成重大伤害的管制器具；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "不得发布或者存储含有管制类刀具、弩配件及用于危害他人人身安全的管制器具的交易信息；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "不得发布或者存储含有用于监听、窃取隐私或机密的软件及设备的交易信息；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "不得发布或者存储含有用于非法摄像、录音、取证等用途的设备的交易信息；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布或者存储含有烟花爆竹类的交易信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 不得发布或者存储含有伪造/变造国家机关或特定机构颁发的文件、证书、证件、公章、防伪标签等，非法或仅限国家机关或特定机构方可提供服务的交易信息； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布或者存储含有代开尚可使用或用于报销的票据的交易信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布或者存储含有人体器官的交易信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 不得发布或者存储含有个人隐私信息及企业内部数据；提供个人手机定位、电话清单查询、银行账户查询等服务的交易信息； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布或者存储含有作弊干扰相关的交易信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布或者存储含有可能用于逃避交通管理的交易信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "不得发布或者存储含有撬锁工具、开锁服务及其相关教程、书籍的交易信息；"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布或者存储含有违禁汽车类的交易信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布或者存储含有烟草的交易信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布或者存储含有濒危动植物类的交易信息。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("(9) 金融类")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 不得发布或存储未经国家银监会、保监会等其他金融主管部门批准的金融产品（包括但不仅限于保险、理财、贷款、股票等）售卖或宣传的信息； "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布虚假捐款、非法集资等信息。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("(10) 其他类")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布或者存储含有非法传销类信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布或者存储含有网络雇佣（用于违法事件）的信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布或者存储含有拐卖人口的信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布仿冒其他网站的信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布含有病毒、木马、带有攻击性的软件的信息；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("不得发布以伤害用户体验为代价的应用，包括但不限于黑帽SEO应用。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 不得利用智安云产品或平台以任何方式发布虚假信息，诱导用户分享虚假信息等行为，如被第三方举报或经智安云查证属实，智安云有权对违规用户做出封禁产品、账号等处罚措施。 "
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("二、违规行为等级及处罚措施")]),
      _c("span", [_vm._v("1、违规行为等级")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("(1) 一般违规行为")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 一般违规是指用户发布或者存储、指向的信息内容构成了《智安云信息安全规定》的相关规则中的一般违规行为。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("(2) 严重违规行为")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 严重违规是指用户发布或者存储、指向的信息内容构成了《智安云信息安全规定》的相关规则中的严重违规行为。 "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("(3) 特别严重违规行为")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 特别严重违规是指用户发布或者存储、指向的信息内容构成了《智安云信息安全规定》的相关规则中的特别严重违规行为。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("2、违规处罚尺度")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 为维护智安云产品使用环境的洁净以及用户的正当权益，在用户违规处理期间按照规定的情形对用户采取以下违规处罚措施： "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("(1) 屏蔽产品中的违规内容；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("(2) 停用含有违规内容的产品；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("(3) 封禁用户账号，使其无法使用智安云所有的产品及服务。"),
      ]),
      _c("br"),
      _c("span", [_vm._v("3、违规处罚")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          " 用户违反本规则的相关规定，智安云将依照《智安云信息安全处罚细则》及其他相关规则对其进行处理，违规行为对应的处理分为： "
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("(1) 一般违规行为的处罚；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("(2) 严重违规行为的处罚；"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("(3) 特别严重违规行为的处罚。"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "各个产品对应的处罚标准不同，详情请参照《智安云信息安全处罚细则》"
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("三、申诉解禁")]),
      _c("span", [
        _vm._v(
          " 用户在接到智安云发出的封禁通知后，需要在规定时间内按照要求清除干净违规信息，承诺加强网站信息安全管理，通过工单系统提交解禁申请，智安云将按照相关规定进行处理。 对上级主管部门下发的封禁指令，如需解禁，需要首先得到上级主管部门的许可。 "
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("四、违规举报")]),
      _c("span", [
        _vm._v(
          " 网民如有发现智安云用户违反了《智安云信息安全规定》的行为，可通过违规举报入口进行举报，智安云将按照相关规定及时处理。 "
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("五、其他")]),
      _c("span", [
        _vm._v(
          "对于上级部门要求处理的违规情况，智安云将会直接根据上级的处罚意见进行处理。"
        ),
      ]),
      _c("br"),
      _c("h5", [_vm._v("附则")]),
      _c("span", [_vm._v("一、本规则标准于2023年3月31日首次生效。")]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 二、在不违反当地法律法规政策的前提下，对使用中国大陆以外地区（包括香港特别行政区和台湾、澳门及海外地区）智安云产品或服务用户的信息安全管理，可参照本规则执行。 "
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("三、智安云有权单方对本细则做出解释或更新。")]),
      _c("br"),
      _c("h5", [_vm._v("附件：")]),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("智安云信息安全处罚细则")]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v("智安云用户行业资质收取标准"),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [
        _vm._v(
          "按照相关法律法规要求，用户在使用智安云服务时应提供相应的行业资质，具体标准如下:"
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("医疗药品及相关用品")]),
      _c("br"),
      _c("div", { staticClass: "bg_imgOne" }),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("医疗机构及相关机构")]),
      _c("br"),
      _c("div", { staticClass: "bg_imgTwo" }),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("医疗器械及相关器械")]),
      _c("br"),
      _c("div", { staticClass: "bg_imgThree" }),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("医疗周边类")]),
      _c("br"),
      _c("div", { staticClass: "bg_imgFour" }),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("版权类")]),
      _c("br"),
      _c("div", { staticClass: "bg_imgFive" }),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("金融类")]),
      _c("br"),
      _c("div", { staticClass: "bg_imgSix" }),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("食品类")]),
      _c("div", { staticClass: "bg_imgSeven" }),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("商务服务类")]),
      _c("div", { staticClass: "bg_imgEight" }),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("生活服务类")]),
      _c("div", { staticClass: "bg_imgNine" }),
      _c("span", { staticClass: "mgLeft" }, [_vm._v("其他类")]),
      _c("div", { staticClass: "bg_imgTen" }),
      _c("h5", [_vm._v("注意")]),
      _c("span", [
        _vm._v(
          "1. 用户务必保证提供相关资质的真实有效性，一旦发现虚假资质将直接进行封禁，并有权追究其法律责任；"
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          " 2. 以上各项若有代理公司或个人，需提供原公司对应所有资质、授权代理协议、代理公司营业执照或代理个人身份证明； "
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v(
          "3. 药品／食品及保健品类，除了提供上述资质外，还应严格遵守新广告法的相关规定；"
        ),
      ]),
      _c("br"),
      _c("span", [_vm._v("4. 其他特殊行业资质以智安云公布的相关规则为准。")]),
      _c("br"),
      _c("span", [_vm._v("智安云信息安全处罚细则")]),
      _c("br"),
      _c("div", { staticClass: "bg_imgLast" }),
      _c("span", [_vm._v("说明：")]),
      _c("br"),
      _c("span", [
        _vm._v(
          "1、 2次一般违规，处罚措施参照严重等级执行；3次及以上一般违规，处罚措施参照特别严重等级执行。"
        ),
      ]),
      _c("br"),
      _c("span", [
        _vm._v("2、 2次及以上严重违规，处罚措施参照特别严重等级执行。"),
      ]),
      _c("br"),
    ]),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("h3", [_vm._v("信息安全规定")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ignore_center" }, [
      _c("span", [
        _vm._v(
          " Copyright©2023 深圳市智安网络有限公司 All Rights Reve 版权所有 《中华人民共和国增值电信业务经营许可证》编号：B1-20190666 "
        ),
        _c(
          "a",
          {
            staticClass: "blue_font",
            attrs: {
              href: "https://beian.miit.gov.cn/#/Integrated/index",
              target: "_blank",
            },
          },
          [_vm._v("粤ICP备18027011号")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }