<template>
    <div>
        <div>
            <loginHeard></loginHeard>
        </div>
        <div class="main">
            <div class="center">
                <div>
                    <el-image class="registerSuccess" :src="require('@/assets/login/Group.png')"/>
                </div>
                <div class="title">注册成功</div>
                <div class="prompt">
                    <div class="prompt_heard">
                        <div class="prompt_path">
                            <span>温馨提示：您可以前往账号中心</span>
                            <span class="prompt_blue" @click="$router.push('/accountCenter/basicInfo')">完善个人信息，</span>
                            <span class="prompt_blue" @click="$router.push('/accountCenter/safetySet')">修改密码，</span>
                            <span class="prompt_blue" @click="$router.push('/accountCenter/safetySet')">绑定邮箱，</span>
                            <span class="prompt_blue" @click="$router.push('/accountCenter/safetySet')">修改手机号，</span>
                            <span>等</span>
                        </div>
                    </div>
                    <div class="prompt_main">
                        <div class="prompt_info">
                            <div>手机号</div>
                            <div>登录用户名</div>
                            <div>{{infoObj.phone}}</div>
                            <div>{{infoObj.userName}}</div>
                        </div>
                    </div>
                    <div class="prompt_foot">
                        <span>完成实名认证，享受特惠云产品活动</span>
                        <div class="prompt_btn">
                            <za-button :loading="confirmBtnLoading" :size="14" class="button"
                                       @click="$router.push('/accountCenter/nameCertification')">
                                实名认证
                            </za-button>
                        </div>
                        <div class="prompt_btn">
                            <za-button :loading="confirmBtnLoading" :size="14" class="goback"
                                       @click="$router.push('/login')">
                                返回
                            </za-button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div>
            <loginFoot></loginFoot>
        </div>
    </div>

</template>
<script>
    import loginHeard from '@/views/login/components/loginHeard'
    import loginFoot from '@/views/login/components/loginFoot'
    import {apiUserInfo} from '@/api/accountCenter/basicInfo'
    export default {
        components: {loginHeard, loginFoot},
        data() {
            return {
                infoObj:{
                    phone:'',
                    userName:''
                }
            }
        },
        created() {
            this.getInfo()
        },
        methods: {
            getInfo(){
                this.onSubmitData(apiUserInfo, null, null, false, false, (res) => {
                    this.infoObj.phone=res.data.phone
                    this.infoObj.userName=res.data.username
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '~@/za/styles/mixin.scss';

    .main {
        height: calc(100vh - 60px);
        background-color: #FFFFFF;
        box-sizing: border-box;
        padding-bottom: 2px;
        border-bottom: 1px solid #E4E5E9;

        .center {
            width: 1080px;
            margin: 0px auto 0 auto;
            text-align: center;

        }

        .registerSuccess {
            text-align: center;
            width: 72px;
            height: 72px;
            margin-top: 70px;
        }

        .title {
            font-size: 24px;
            font-weight: bold;
            color: #000000;
            margin-top: 24px;
            margin-bottom: 40px;
            text-align: center;
        }

        .prompt {
            width: 1080px;
            height: 490px;
            padding: 40px 130px 102px 130px;
            @include img_div($imgUrl: url('~@/assets/pay/shape.png'));

            .prompt_heard {
                height: 49px;
                border-bottom: 1px dotted #999999;

                .prompt_path {
                    font-size: 18px;
                    font-weight: 400;
                    color: #000000;
                }

                .prompt_blue {
                    color: #3370FF;
                    cursor: pointer;
                }
            }

            .prompt_main {
                margin-top: 50px;
                text-align: left;
                margin-left: 10px;

                .prompt_info {
                    font-size: 14px;
                    font-weight: 400;
                    color: #999999;
                    display: grid;
                    grid-template-columns: 1fr 1fr;

                    div:nth-child(3) {
                        font-size: 20px;
                        font-weight: 500;
                        color: #000000;
                        margin-top: 10px;
                    }

                    div:nth-child(4) {
                        font-size: 20px;
                        font-weight: 500;
                        color: #000000;
                        margin-top: 10px;
                        margin-bottom: 58px;
                    }
                }
            }

            .prompt_foot {
                text-align: left;

                span {
                    font-size: 14px;
                    font-weight: 400;
                    color: #EB2F2F;
                    display: inline-block;
                    margin-bottom: 10px;
                    margin-left: 10px;
                }

                .button {
                    width: 100%;
                    height: 40px;
                    text-align: center;
                    font-weight: 400;
                    line-height: 22px;
                    margin-bottom: 20px;
                }

                .goback {
                    background: none;
                    border: 1px solid #999999;
                    color: #000000;
                }

            }
        }
    }
</style>