<template>
  <div>
    <za-button-single :button-list="timeList" label="label" @click="timeToggle" />
    <el-date-picker
      v-model="pickTime"
      :type="range"
      range-separator="至"
      :start-placeholder="range === 'daterange' ? '开始日期' : '开始月份'"
      :end-placeholder="range === 'daterange' ? '结束日期' : '结束月份'"
      @change="datePickEnd"
    ></el-date-picker>
    <!--    <el-row>-->
    <!--      <el-col :span="10">-->
    <!--        <za-button-single :button-list="timeList" label="label" @click="timeToggle" />-->
    <!--      </el-col>-->
    <!--      <el-col :span="14">-->
    <!--        <el-date-picker-->
    <!--          v-model="pickTime"-->
    <!--          type="daterange"-->
    <!--          range-separator="至"-->
    <!--          start-placeholder="开始日期"-->
    <!--          end-placeholder="结束日期"-->
    <!--          @change="datePickEnd"-->
    <!--        ></el-date-picker>-->
    <!--      </el-col>-->
    <!--    </el-row>-->
  </div>
</template>
<script>
  import { getTimestampRange } from '@/utils/timestampRange'

  export default {
    props: {
      timeList: {
        type: Array,
        default: () => [],
      },
      range: {
        type: String,
        default: 'daterange',
      },
    },
    data() {
      return {
        pickTime: [],
        time: {
          _startTime: '',
          _endTime: '',
        },
      }
    },
    mounted() {
      this.getTimeRange(this.timeList[0].value)
    },
    methods: {
      getTimeRange(num) {
        const timeRange = getTimestampRange(num)
        this.time._startTime = timeRange[0]
        this.time._endTime = timeRange[1]
        this.$emit('changeTime', this.time)
      },
      timeToggle(item) {
        this.pickTime = []
        this.getTimeRange(item.value)
      },
      datePickEnd(date) {
        if (date) {
          this.time._startTime = this.$changeTimeStamp(date[0])
          this.time._endTime = this.$changeTimeStamp(date[1]) + 3600 * 24 - 1
          this.$emit('changeTime', this.time)
        } else {
          this.time._startTime = ''
          this.time._endTim = ''
          this.$baseEventBus.$emit('init', 0)
          this.getTimeRange(this.timeList[0].value)
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep .el-range-editor--small.el-input__inner {
    height: 31px;
  }

  ::v-deep .el-input__inner {
    border-radius: 0;
  }

  ::v-deep .el-range-editor.el-input__inner {
    padding: 2px 10px;
  }
</style>