var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "settlement" }, [
    _c(
      "div",
      [
        _c("occ-table", {
          attrs: {
            tableData: _vm.tableData,
            columObj: _vm.columObj,
            isOp: _vm.columObj.isOp,
          },
        }),
        _c("pagination", {
          attrs: {
            limit: _vm.pageObj.pageSize,
            page: _vm.pageObj.pageNo,
            total: _vm.total,
          },
          on: {
            "update:limit": function ($event) {
              return _vm.$set(_vm.pageObj, "pageSize", $event)
            },
            "update:page": function ($event) {
              return _vm.$set(_vm.pageObj, "pageNo", $event)
            },
            pagination: _vm.getList,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }