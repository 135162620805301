<template>
    <div>
        <za-query-form>
            <za-query-form-left-panel :span="24">
                <el-form :inline="true" :model="pageObj" @submit.native.prevent>
                    <el-form-item label="起止日期">
                        <el-date-picker
                                v-model="pickTime"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                @change="datePickEnd"
                        ></el-date-picker>
                    </el-form-item>
                    <el-form-item label="选择账号" label-width="100px">
                        <el-select v-model="pageObj.accountId">
                            <el-option
                                    v-for="item in typeOptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </za-query-form-left-panel>
        </za-query-form>
        <div class="money">
            <span>可开票金额: </span>
            <span>￥{{amount}}</span>
        </div>
        <occ-table :tableData="tableData" :columObj="columObj"
                   @getList="changeList"
                   :expands="expands"
                   @handleSelectionChange="handleSelectionChange" :isOp="columObj.isOp"
                   ref="occTwoable"
                   :infoNum="infoNum"
        >
            <template #default="{row}">
                <el-button @click="clickRowHandle(row)" class="btn" :disabled="row.invoicesAmount==0">明细</el-button>
            </template>
        </occ-table>
        <div>
            <pagination
                    :limit.sync="pageObj.pageSize"
                    :page.sync="pageObj.pageNo"
                    :total="total"
                    @pagination="getList"
            />
        </div>
        <div>
            <div class="bun_fix">
                <el-button type="primary" @click="toTop" :disabled="multipleSelection.length===0 || isStopStep > 0">下一步</el-button>
                <el-button plain @click="cancel">取消</el-button>
            </div>
        </div>
    </div>
</template>
<script>
    import {apiGetMonthInvoices, apiGetOrderInvoices,apiGetOrderDetail} from '@/api/moneyCenter/invoiceManagement/drawAbill'
    import {apiFinanceAmount} from "@/api/moneyCenter/invoiceManagement/askfor"

    import {mapGetters} from "vuex";

    export default {
        data() {
            return {
                expands: [],
                pickTime: [],
                typeOptions: [],
                multipleSelection: [],
                isIndeterminate: false,
                total: 0,
                pageObj: {
                    startAt: '',
                    endAt: '',
                    pageNo: 1,
                    pageSize: 10,
                    accountId: ''
                },
                monthNum: '',
                amount: '',
                tableData: [],
                columObj: {
                    // 控制选择框
                    selection: true,
                    isOp: true,
                    type: 'expand',
                    width: '300px',
                    columnData: [
                        {
                            text: true,
                            prop: "month",
                            label: "开票月份",
                            width: "",
                            flag: true,
                            align: "center",

                        },
                        {
                            text: true,
                            prop: "name",
                            label: "单据名称",
                            flag: true,
                            width: "",
                            align: "center",

                        },
                        {
                            ownDefined: true,
                            prop: "invoicableAmount",
                            label: "可开票金额",
                            flag: true,
                            width: "",
                            align: "center",
                            ownDefinedReturn: ((row, index) => {
                                return '￥' + row.invoicableAmount
                            })
                        },
                        {
                            ownDefined: true,
                            prop: "invoicedAmount",
                            label: "已开票金额",
                            flag: true,
                            width: "",
                            align: "center",
                            ownDefinedReturn: ((row, index) => {
                                return '￥' + row.invoicedAmount
                            })
                        },
                        {
                            ownDefined: true,
                            prop: "invoicesAmount",
                            label: "发票总额",
                            flag: true,
                            width: "",
                            align: "center",
                            ownDefinedReturn: ((row, index) => {
                                return '￥' + row.invoicesAmount
                            })
                        },

                    ],
                },
                infoObj: {
                    // 控制选择框
                    selection: false,
                    isOp: false,
                    width: '300px',
                    columnData: [
                        {
                            text: true,
                            prop: "orderNumber",
                            label: "订单编号",
                            width: "",
                            flag: true,
                            align: "center",

                        },
                        {
                            text: true,
                            prop: "productName",
                            label: "产品名称",
                            flag: true,
                            width: "",
                            align: "center",

                        },
                        {
                            text: true,
                            prop: "fundType",
                            label: "资金类型",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        // {
                        //     text: true,
                        //     prop: "type",
                        //     label: "类型",
                        //     flag: true,
                        //     width: "",
                        //     align: "center",
                        // },
                        {
                            text: true,
                            prop: "deductionTime",
                            label: "订单/账单扣费时间",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            ownDefined: true,
                            prop: "payAmount",
                            label: "现金支付金额",
                            flag: true,
                            width: "",
                            align: "center",
                            ownDefinedReturn: ((row, index) => {
                                return '￥' + row.payAmount
                            })
                        },
                        {
                            ownDefined: true,
                            prop: "invoicableAmount",
                            label: "可开票金额",
                            flag: true,
                            width: "",
                            align: "center",
                            ownDefinedReturn: ((row, index) => {
                                return '￥' + row.invoicableAmount
                            })
                        }
                    ],
                },

                infoPageObj: {
                    pageNo: 1,
                    pageSize: 10,
                    startAt: 0,
                    endAt: 0,
                    orderNumber: '',
                    productName: '',
                    orderType: 0
                },
                infoNum: 0
            }
        },
        computed: {
            ...mapGetters({
                userInfo: 'user/userInfo',
            }),
            isStopStep(){
                return this.multipleSelection.filter(item => {
                    return item.invoicableAmount === 0
                }).length
            }
        },
        created() {
            this.getList()
            this.typeOptions.push({name: this.userInfo.username, id: this.userInfo.username})
        },
        methods: {
            getList() {
                this.onSubmitData(apiGetMonthInvoices, this.pageObj, null, false, false, (res) => {
                    this.total = res.data.total
                    if (!res.data.list) {
                        this.tableData = []
                    } else {
                        this.tableData = res.data.list
                        res.data.list.forEach((item) => {
                            this.clickRowHandle(item, true)
                        })
                    }
                })
                this.onSubmitData(apiFinanceAmount, null, null, false, false, (res) => {
                    this.amount = res.data.amount
                    this.monthNum = res.data.monthNum
                })
            },
            clickRowHandle(row, state = false) {
                let timer = this.computerTimer(row.month)
                row.infoObj = this.infoObj
                row.infoPageObj = JSON.parse(JSON.stringify(this.infoPageObj))
                this.infoPageObj.startAt = timer.startTime / 1000
                this.infoPageObj.endAt = timer.endTime / 1000
                row.infoPageObj.startAt = timer.startTime / 1000
                row.infoPageObj.endAt = timer.endTime / 1000
                row.infoPageObj.pageNo=1
                row.infoPageObj.pageSize=10
                this.getInfoList(row)
                if (!state) {
                    if (this.expands.includes(row.month)) {
                        this.expands = this.expands.filter(val => val !== row.month);
                    } else {
                        this.expands.push(row.month);
                    }
                }
            },
            computerTimer(val) {
                let data = new Date(val); //本月
                data.setDate(1);
                data.setHours(0);
                data.setSeconds(0);
                data.setMinutes(0);
                let endData = new Date(val); // 下月
                if (data.getMonth() == 11) {
                    endData.setMonth(0)
                } else {
                    endData.setMonth(data.getMonth() + 1)
                }
                endData.setDate(1);
                endData.setHours(0);
                endData.setSeconds(0);
                endData.setMinutes(0);
                // console.log('本月第一天0点' + data.getTime())
                // console.log('本月最后一天23点' + endData.getTime())
                return {
                    startTime: data.getTime(),
                    endTime: endData.getTime()
                }
            },
            getInfoList(row) {
                    this.onSubmitData(apiGetOrderDetail, row.infoPageObj, null, false, false, (res) => {
                        // row.infoTotal = res.data.total
                        this.infoNum = row.invoicableAmount
                        row.descData = res.data.list || []
                        this.tableData.forEach((item, index) => {
                            if (item.month == row.month) {
                                this.tableData.splice(index, 1, row)
                            }
                        })
                    })
            },
            changeList(val) {
                // 子页面点击分页
                this.getInfoList(val)
            },
            toTop() {
                this.$router.push({
                    path: '/moneyCenter/invoiceManagement/askfor/drawAbill/drawInfo',
                    query: {type: 'mouth'}
                })
            },
            cancel() {
                this.$refs.occTwoable.$emit('changeMultipleSelection', false)
            },

            datePickEnd(date) {
                if (date) {
                    this.pageObj.startAt = this.$changeTimeStamp(date[0])
                    this.pageObj.endAt = this.$changeTimeStamp(date[1]) + 3600 * 24 - 1
                } else {
                    this.pageObj.startAt = ''
                    this.pageObj.endAt = ''
                }
                this.getList()

            },
            handleSelectionChange(data) {
                let list = []
                if (data && data.length) {
                    data.forEach((item) => {
                        if (item.descData) {
                            item.descData.forEach((val) => {
                                list.push({date: item.month, orderNumber: [val.orderNumber]})
                            })
                        }
                    })
                }
                if (list.length) {
                    let newList = [];
                    list.forEach((item) => {
                        let _index = newList.findIndex((e) => {
                            return e.date === item.date
                        })
                        if (_index === -1) {
                            newList.push(item)
                        } else {
                            let newItem = newList[_index]
                            newItem.orderNumber = newItem.orderNumber.concat(item.orderNumber)
                            newList[_index] = newItem
                        }
                    })
                    localStorage.setItem('drawAbill', JSON.stringify(newList))
                }
                this.multipleSelection = data
            },
            changeMultipleSelection(bool) {
                if (bool) {
                    this.$refs.occTwoable.$emit('changeMultipleSelection', true)
                } else {
                    this.$refs.occTwoable.$emit('changeMultipleSelection', false)
                }

            }

        }
    }
</script>
<style lang="scss" scoped>
    .money {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        margin-bottom: 25px;

        span:last-child {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: red;
        }

    }

    .btn {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3370FF;
    }


    .bun_fix {
        padding: 16px 10px 16px 80%;
        position: fixed;
        left: 210px;
        bottom: 0px;
        width: 100%;
        right: 0;
        height: 64px;
        background: #FFFFFF;
        z-index: 99;
        box-shadow: 0px -9px 28px 8px rgba(0, 0, 0, 0.05);
    }
</style>