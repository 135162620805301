<template>
    <div>
        <div class="showHeardInfo">
            <div>
                <div class="showHeardPirectrue">
                    <el-image ref="image" class="icon-heardPicture" fit="cover" :src="icoUrl()" >
<!--                        @click="changePicture"-->
                        <div slot="error">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
<!--                    <el-image ref="image" class="icon-footPicture" fit="cover"-->
<!--                              :src="require('@/assets/accountCenter/changePicture.png')" @click="changePicture">-->
<!--                        <div slot="error">-->
<!--                            <i class="el-icon-picture-outline"></i>-->
<!--                        </div>-->
<!--                    </el-image>-->
                </div>
            </div>
            <div class="heardFlex">
                <div>
                    <div>
                        <span>登录账号:</span>
                        <span class="value">{{querydata.username}}</span>
                    </div>
                    <div>
                        <span>账号ID:</span>
                        <span class="value"> {{ querydata.uuid}}</span>
                        <span>
                    <i class="el-icon-copy-document copyDocument" @click="copy(userInfo.uuid)"></i>
                   </span>
                    </div>

                </div>
                <div>
                    <div>
                        <span>实名认证:</span>
                        <span class="certification enterprise" v-if="querydata.accountType=='1'">企业认证</span>
                        <span class="certification individual" v-if="querydata.accountType=='2'">个人认证</span>
                        <span class="certification" v-if="querydata.accountType=='0'">未认证</span>

                        <span class="changeTo" @click="toSet">{{querydata.accountType!='0'?'详情':'立即认证'}}</span>
                    </div>
<!--                    <div>-->
<!--                        <span>三方账号绑定:</span>-->
<!--                        <span class="value">{{querydata.contacts||'暂无'}}</span>-->
<!--                        <span class="changeTo " @click="toPartyLogin">修改</span>-->
<!--                    </div>-->

                    <div>
                        <span>上次登录时间:</span>
                        <span class="value">{{querydata.loginAt}}</span>
                    </div>
                </div>
                <div>
                    <div>
                        <span>注册时间:</span>
                        <span class="value">{{querydata.createAt}}</span>
                    </div>
                </div>
            </div>

        </div>
        <za-dialog :visible.sync="dialogdouble"
                   :top-title="'修改头像'"
                   width="620px">
            <uplode-img @change="closeDialog" @top="openDialog"></uplode-img>
            <div slot="footer">
                <za-button :disabled="confirmBtnLoading" :type="buttonTypeObj.GREY" @click="dialogdouble = false">
                    {{ translateTitle('btn.cancel') }}
                </za-button>
                <za-button :loading="confirmBtnLoading" :type="buttonTypeObj.BLUE" @click="uploadImg" v-if="false">
                    上传头像
                </za-button>
            </div>
        </za-dialog>
    </div>
</template>
<script>
    import ZaDialog from "@/za/components/zaDialog";
    import uplodeImg from '../uplodeImg'
    import {downLoadUrl} from '@/config'
    import {mapGetters} from "vuex";

    export default {
        components: {ZaDialog, uplodeImg},
        data() {
            return {
                dialogdouble: false,
                querydata: {}
            }
        },
        mounted() {
            this.$baseEventBus.$on('getHeardInfo', (data) => {
                this.querydata = data
                if(this.querydata.loginAt.search('1970')!==-1){
                    this.querydata.loginAt='-'
                }
            })
        },
        computed: {
            ...mapGetters({
                userInfo: 'user/userInfo',
            })
        },
        methods: {
            icoUrl() {
                if( this.userInfo.profile){
                    return this.userInfo.profile
                }else{
                    return require('@/assets/home/default.png')
                }

            },
            changePicture() {
                this.dialogdouble = true
            },
            uploadImg() {
                console.log("上传图片")
            },
            closeDialog() {
                console.log("调用了")
                this.dialogdouble = false
            },
            openDialog() {
                this.dialogdouble = true
            },
            toPartyLogin() {
                this.$router.push('/accountCenter/partyLogin')
            },
            toSet() {
                this.$router.push('/accountCenter/nameCertification')
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '~@/za/styles/mixin.scss';

    .showHeardInfo {
        display: flex;
        width: 100%;
        .heardFlex{
            display: flex;
            font-size: 14px;
            font-weight: 400;
            color: #555555;
            div{
                margin-right: 40px;
                .value{
                    color: #000000;
                    margin-top: 10px;
                    display: inline-block;
                    margin-left: 5px;
                }
                .certification{
                    width: 72px;
                    height: 17px;
                    font-size: 12px;
                    font-family: PingFangSC-Light, PingFang SC;
                    font-weight: 300;
                    color: #FFFFFF;
                    display: inline-block;
                    background: #999999;
                    text-align: center;
                    margin-top: 10px;
                    margin-left: 10px;
                }
                .individual {
                    background: #F38800;
                    color: #FFFFFF;
                }
                .enterprise{
                    background: #5BB943;
                    color: #FFFFFF;
                }
                .changeTo{
                    color: #3370FF;
                    margin-left: 10px;
                    cursor: pointer;
                }
            }
        }

        .showHeardPirectrue {
            width: 60px;
            height: 60px;
            position: relative;
            margin-right: 40px;
        }
    }

    .icon-heardPicture {
        width: 60px !important;
        height: 60px;
        border-radius: 50%;
    }

    .icon-footPicture {
        width: 60px !important;
        position: absolute;
        top: 30px;
        left: 0;
    }

</style>