<template>
    <div class="customerTab">
        <za-query-form>
            <za-query-form-left-panel :span="18">
                <el-form :inline="true" :model="pageObj" @submit.native.prevent>
                    <el-form-item label="客户类型">
                        <el-select v-model="pageObj.tradeAccount">
                            <el-option
                                    v-for="item in typeOptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="注册时间"
                                  style="margin-left: 15px !important">
                        <el-date-picker
                                v-model="pickTime"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                @change="datePickEnd"
                        ></el-date-picker>
                    </el-form-item>
                    <el-form-item label="关联类型" label-width="100px">
                        <el-select v-model="pageObj.payType">
                            <el-option
                                    v-for="item in typeOptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </za-query-form-left-panel>
            <za-query-form-left-panel :span="6">
                <el-form :inline="true" :model="pageObj" @submit.native.prevent>
                    <el-form-item  label-width="100px">
                        <el-input v-model="pageObj.tradeNo"
                                  placeholder="输入用户名或ID查找"/>
                    </el-form-item>
                    <el-form-item>
                        <za-button :icon="buttonIconObj.SEARCH" @click="handleQuery">{{ translateTitle('btn.query')
                            }}
                        </za-button>
                        <za-button :type="buttonTypeObj.GREY" @click="handleResetQuery">{{
                            translateTitle('btn.reset')
                            }}
                        </za-button>
                    </el-form-item>
                </el-form>
            </za-query-form-left-panel>
        </za-query-form>
        <div>
            <occ-table :tableData="tableData" :columObj="columObj" :isOp="columObj.isOp"></occ-table>
            <pagination
                    :limit.sync="pageObj.pageSize"
                    :page.sync="pageObj.pageNo"
                    :total="total"
                    @pagination="getList"
            />
        </div>
    </div>
</template>
<script>
    export default {
        data(){
            return{
                pageObj:{
                    pageNo: 1,
                    pageSize: 10,
                },
                pickTime:[],
                typeOptions:[],
                tableData: [],
                total:0,
                columObj: {
                    // 控制选择框
                    selection: false,
                    isOp: false,
                    width: '300px',
                    columnData: [
                        {
                            text: true,
                            prop: "loginAt",
                            label: "关联客户ID",
                            width: "",
                            flag: true,
                            align: "center",


                        },
                        {
                            text: true,
                            prop: "name",
                            label: "客户类型",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "ip",
                            label: "关联状态",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "loginPlace",
                            label: "消费金额",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "loginPlace",
                            label: "累计有效佣金",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "loginPlace",
                            label: "关联成功时间",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "time",
                            label: "关联失效时间",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "loginPlace",
                            label: "关联失效原因",
                            flag: true,
                            width: "",
                            align: "center",
                        },

                    ],
                }
            }
        },
        methods:{
            getList(){

            },
            handleQuery(){

            },
            handleResetQuery(){

            },
            datePickEnd(date) {
                if (date) {
                    this.pageObj.startAt = this.$changeTimeStamp(date[0])
                    this.pageObj.endAt = this.$changeTimeStamp(date[1]) + 3600 * 24 - 1

                } else {
                    this.pageObj.startAt = ''
                    this.pageObj.endAt = ''
                }
                this.getList()
            },
        }
    }
</script>
<style lang="scss" scoped>
.customerTab{
    background: #FFFFFF;
    padding: 20px;
}
</style>