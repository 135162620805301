<template>
  <div class="safetySet-container" v-za-loading="isLoading">
    <div class="title">账号安全</div>
    <div class="pwdLevel">
      <div class="levelTitle">账号安全级别：</div>
      <div>
        <div
          class="default weak"
          :class="[{ medium: pageObj.safetyLevel == '中等' }, { strong: pageObj.safetyLevel == '较强' }]"
        ></div>
        <div
          class="default"
          :class="[{ medium: pageObj.safetyLevel == '中等' }, { strong: pageObj.safetyLevel == '较强' }]"
        ></div>
        <div class="default" :class="[pageObj.safetyLevel == '较强' ? 'strong' : '']"></div>
      </div>

      <div class="prompt">{{ pageObj.safetyLevel }}</div>
    </div>
    <div class="main">
      <ul>
        <li>
          <div class="main_top">
            <div>
              <za-svg-icon icon-class="za-success" class-name="svg-success"></za-svg-icon>
              <span class="login_pwd">登录密码</span>
            </div>
            <div class="modify" @click="pathVerification(0)">修改</div>
          </div>
          <div class="main_bottom">
            安全性高的密码可以使帐号更安全。建议您定期更换密码，设置一个限字母、数字或特殊字符至少包含两种且长度超过6位的密码
          </div>
        </li>
        <li>
          <div class="main_top">
            <div>
              <za-svg-icon
                :icon-class="pageObj.mobileBind == 0 ? 'za-failure' : 'za-success'"
                class-name="svg-success"
              ></za-svg-icon>
              <span class="login_pwd">手机绑定</span>
            </div>
            <div class="modify" @click="pathVerification(1)">修改</div>
          </div>
          <div class="main_bottom">
            <span>
              您已绑定了手机(
              <span style="color: #1b5deb">{{ phone }}</span>
              )
            </span>
            <span>[您的手机为安全手机，可用于接收消息，账号核身（如找回密码、变更安全设置等）]</span>
          </div>
        </li>
        <li>
          <div class="main_top">
            <div>
              <za-svg-icon
                :icon-class="pageObj.emailBind == 0 ? 'za-failure' : 'za-success'"
                class-name="svg-success"
              ></za-svg-icon>
              <span class="login_pwd">邮箱绑定</span>
            </div>
            <div class="modify" @click="pathVerification(4)" v-if="pageObj.emailBind == 0">设置</div>
            <div class="modify" @click="pathVerification(2)" v-if="pageObj.emailBind == 1">修改</div>
          </div>
          <div class="main_bottom">备用邮箱绑定后可用来接收智安云发送的各类系统、营销、服务通知</div>
        </li>
        <li>
          <div class="main_top">
            <div>
              <za-svg-icon
                :icon-class="pageObj.securityBind == 0 ? 'za-failure' : 'za-success'"
                class-name="svg-success"
              ></za-svg-icon>
              <span class="login_pwd">密保问题</span>
            </div>
            <div class="modify" @click="setPwdInfo" v-if="pageObj.securityBind == 0">设置</div>
            <div class="modify" @click="pathVerification(3)" v-if="pageObj.securityBind !== 0">修改</div>
          </div>
          <div class="main_bottom">
            建议您设置三个容易记住，且最不容易被他人获取的问题及答案，更有效保障您的密码安全
          </div>
        </li>
        <!--                <li>-->
        <!--                    <div class="main_top">-->
        <!--                        <div>-->
        <!--                            <za-svg-icon :icon-class="pageObj.weChatBind==0?'za-failure':'za-success'" class-name="svg-success"></za-svg-icon>-->
        <!--                            <span class="login_pwd">微信绑定</span>-->
        <!--                        </div>-->
        <!--                        <div class="modify">{{pageObj.weChatBind==0?'设置':'修改'}}</div>-->
        <!--                    </div>-->
        <!--                    <div class="main_bottom">-->
        <!--                        绑定后可获取相关信息安全认证，及使用微信登录-->
        <!--                    </div>-->
        <!--                </li>-->
        <li>
          <div class="main_top">
            <div>
              <za-svg-icon
                :icon-class="pageObj.accountType == 0 ? 'za-failure' : 'za-success'"
                class-name="svg-success"
              ></za-svg-icon>
              <span class="login_pwd">实名认证</span>
            </div>
            <div class="modify" @click="$router.push('/accountCenter/nameCertification')">
              {{ pageObj.accountType == 0 ? '设置' : '修改' }}
            </div>
          </div>
          <div class="main_bottom" v-if="pageObj.accountType !== 0">您已通过实名认证</div>
          <div class="main_bottom" v-if="pageObj.accountType == 0">请实名认证</div>
        </li>
        <li>
          <div class="main_top">
            <div>
              <za-svg-icon
                :icon-class="pageObj.loginProtection.length <= 1 ? 'za-failure' : 'za-success'"
                class-name="svg-success"
              ></za-svg-icon>
              <!--                            <za-svg-icon icon-class="za-failure" class-name="svg-success"></za-svg-icon>-->
              <span class="login_pwd">登录保护</span>
            </div>
            <div class="modify" @click="nowSave">立即保存</div>
          </div>
          <div class="main_bottom">
            <el-checkbox-group v-model="pageObj.loginProtection">
              <el-checkbox label="1">异地登录时二次验证</el-checkbox>
              <el-checkbox label="2">更换常用设备时</el-checkbox>
              <el-checkbox label="3">每次验证</el-checkbox>
            </el-checkbox-group>
          </div>
        </li>
        <li>
          <div class="main_top">
            <div>
              <za-svg-icon
                :icon-class="pageObj.weChatBind === 1 ? 'za-success' : 'za-failure'"
                class-name="svg-success"
              ></za-svg-icon>
              <span class="login_pwd">微信绑定</span>
            </div>
          </div>
          <div class="main_bottom" v-if="pageObj.weChatBind === 1">
            您已绑定微信
            <span style="color: #419efe">{{ pageObj.weChatInfo.weChatName }}</span>
            [支持使用所绑定微信登录]
          </div>
          <div class="main_bottom" v-else>绑定后可获取相关信息安全认证，及使用微信登录</div>
        </li>
      </ul>
    </div>
    <za-dialog top-title="设置密码保护" :visible.sync="dialogVisible" width="600px">
      <div class="tipsInfo">
        <za-svg-icon icon-class="za-wanning"></za-svg-icon>
        <span>请妥善保管密保问题，设置后可用于找回密码、变更安全设置等!</span>
      </div>
      <el-form
        ref="form"
        :disabled="confirmBtnLoading"
        :model="baseForm"
        label-position="right"
        label-width="140px"
        :rules="rules"
      >
        <el-form-item :label="'请选择密保问题' + '：'" prop="oneQuestion">
          <el-select
            v-model="baseForm.oneQuestion"
            style="width: 100%"
            clearable
            @change="handleChange('oneQuestion', $event)"
          >
            <el-option
              v-for="item in oneQuestion"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="'自定义问题' + '：'" prop="orderNumber1" v-if="baseForm.oneQuestion == 'oneId'">
          <el-input v-model="baseForm.orderNumber1" placeholder="请输入自定义问题" />
        </el-form-item>
        <el-form-item :label="'密保答案' + '：'" prop="oneAnswer">
          <el-input v-model="baseForm.oneAnswer" placeholder="请输入密保答案" />
        </el-form-item>
        <el-form-item :label="'请选择密保问题' + '：'" prop="twoQuestion">
          <el-select
            v-model="baseForm.twoQuestion"
            style="width: 100%"
            clearable
            @change="handleChange('twoQuestion', $event)"
          >
            <el-option
              v-for="item in twoQuestion"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="'自定义问题' + '：'" prop="orderNumber2" v-if="baseForm.twoQuestion == 'oneId'">
          <el-input v-model="baseForm.orderNumber2" placeholder="请输入自定义问题" />
        </el-form-item>
        <el-form-item :label="'密保答案' + '：'" prop="twoAnswer">
          <el-input v-model="baseForm.twoAnswer" placeholder="请输入密保答案" />
        </el-form-item>
        <el-form-item :label="'请选择密保问题' + '：'" prop="threeQuestion">
          <el-select
            v-model="baseForm.threeQuestion"
            style="width: 100%"
            @change="handleChange('threeQuestion', $event)"
          >
            <el-option
              v-for="item in threeQuestion"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="'自定义问题' + '：'" prop="orderNumber3" v-if="baseForm.threeQuestion == 'oneId'">
          <el-input v-model="baseForm.orderNumber3" placeholder="请输入自定义问题" />
        </el-form-item>
        <el-form-item :label="'密保答案' + '：'" prop="threeAnswer">
          <el-input v-model="baseForm.threeAnswer" placeholder="请输入密保答案" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <za-button :disabled="confirmBtnLoading" :type="buttonTypeObj.GREY" @click="dialogVisible = false">
          {{ translateTitle('btn.cancel') }}
        </za-button>
        <za-button :loading="confirmBtnLoading" :type="buttonTypeObj.BLUE" @click="handleSubmitForm">
          {{ translateTitle('btn.submit') }}
        </za-button>
      </div>
    </za-dialog>
  </div>
</template>
<script>
  import { apiGetPhone, apiLoginSafe, apisaveSecurity, apiUseerOverview } from '@/api/accountCenter/safetySet'
  import { reqSeleObj } from '@/za/model/reqModel'

  export default {
    name: 'SafetySet',
    data() {
      return {
        phone: '',
        pageObj: {
          safetyLevel: '',
          accountType: 0,
          loginProtection: [],
          mobileBind: 0,
          emailBind: 0,
          weChatBind: 0,
          securityBind: 0,
        },
        clickList: [],
        baseForm: {
          oneAnswer: '',
          twoAnswer: '',
          threeAnswer: '',
          oneQuestion: '',
          twoQuestion: '',
          threeQuestion: '',
          orderNumber3: '',
          orderNumber2: '',
          orderNumber1: '',
        },
        rules: {
          oneQuestion: [{ required: true, message: '请选择密保问题', trigger: 'change' }],
          twoQuestion: [{ required: true, message: '请选择密保问题', trigger: 'change' }],
          threeQuestion: [{ required: true, message: '请选择密保问题', trigger: 'change' }],
          orderNumber3: [{ required: true, message: '请输入密保问题', trigger: 'blur' }],
          orderNumber2: [{ required: true, message: '请输入密保问题', trigger: 'blur' }],
          orderNumber1: [{ required: true, message: '请输入密保问题', trigger: 'blur' }],
          oneAnswer: [{ required: true, message: '请输入密保答案', trigger: 'blur' }],
          twoAnswer: [{ required: true, message: '请输入密保答案', trigger: 'blur' }],
          threeAnswer: [{ required: true, message: '请输入密保答案', trigger: 'blur' }],
        },
        oneQuestion: [
          { id: '我母亲的姓名?', name: '我母亲的姓名?' },
          { id: '我就读的第一所学校的名称？', name: '我就读的第一所学校的名称？' },
          { id: '我的学号或工号是什么？', name: '我的学号或工号是什么' },
          { id: '我父亲的姓名', name: '我父亲的姓名' },
          { id: '我父亲的职业', name: '我父亲的职业' },
          { id: '我母亲的职业', name: '我母亲的职业' },
          { id: '我的出生地', name: '我的出生地' },
          { id: '我初中班主任的名字是', name: '我初中班主任的名字是' },
          { id: '我最爱的人的名字', name: '我最爱的人的名字' },
          { id: '我妈妈的生日', name: '我妈妈的生日' },
          { id: 'oneId', name: '自定义问题' },
        ],
        twoQuestion: [
          { id: '我母亲的姓名?', name: '我母亲的姓名?' },
          { id: '我就读的第一所学校的名称？', name: '我就读的第一所学校的名称？' },
          { id: '我的学号或工号是什么？', name: '我的学号或工号是什么' },
          { id: '我父亲的姓名', name: '我父亲的姓名' },
          { id: '我父亲的职业', name: '我父亲的职业' },
          { id: '我母亲的职业', name: '我母亲的职业' },
          { id: '我的出生地', name: '我的出生地' },
          { id: '我初中班主任的名字是', name: '我初中班主任的名字是' },
          { id: '我最爱的人的名字', name: '我最爱的人的名字' },
          { id: '我妈妈的生日', name: '我妈妈的生日' },
          { id: 'oneId', name: '自定义问题' },
        ],
        threeQuestion: [
          { id: '我母亲的姓名?', name: '我母亲的姓名?' },
          { id: '我就读的第一所学校的名称？', name: '我就读的第一所学校的名称？' },
          { id: '我的学号或工号是什么？', name: '我的学号或工号是什么' },
          { id: '我父亲的姓名', name: '我父亲的姓名' },
          { id: '我父亲的职业', name: '我父亲的职业' },
          { id: '我母亲的职业', name: '我母亲的职业' },
          { id: '我的出生地', name: '我的出生地' },
          { id: '我初中班主任的名字是', name: '我初中班主任的名字是' },
          { id: '我最爱的人的名字', name: '我最爱的人的名字' },
          { id: '我妈妈的生日', name: '我妈妈的生日' },
          { id: 'oneId', name: '自定义问题' },
        ],
        seleObj: Object.assign({}, reqSeleObj),
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      getList() {
        this.onSubmitData(apiUseerOverview, null, null, false, false, (res) => {
          this.pageObj = res.data
        })
        this.onSubmitData(apiGetPhone, null, null, false, false, (res) => {
          this.phone = res.data.phone
        })
      },
      handleChange(val, event) {
        if (val == 'oneQuestion' && event !== 'oneId' && event !== 'twoId' && event !== 'threeId') {
          this.seleObj.oneQuestion = event
        } else if (val == 'twoQuestion' && event !== 'oneId' && event !== 'twoId' && event !== 'threeId') {
          this.seleObj.twoQuestion = event
        } else if (val == 'threeQuestion' && event !== 'oneId' && event !== 'twoId' && event !== 'threeId') {
          this.seleObj.threeQuestion = event
        }
        this.oneQuestion.forEach((item) => {
          item.disabled = false
          if (
            this.seleObj.oneQuestion == item.id ||
            this.seleObj.twoQuestion == item.id ||
            this.seleObj.threeQuestion == item.id
          ) {
            item.disabled = true
          }
        })
        this.twoQuestion = this.oneQuestion
        this.threeQuestion = this.oneQuestion
      },
      pathVerification(val) {
        this.$router.push({
          path: '/accountCenter/safetySet/verification',
          query: { pwdState: this.pageObj.securityBind, clickVal: val, emailState: this.pageObj.emailBind },
        })
      },
      nowSave() {
        let saveList = { loginProtection: this.pageObj.loginProtection }
        this.onSubmitData(apiLoginSafe, saveList, null, false, false, (res) => {
          if (res.code == 0) {
            this.$msgSuccess('保存成功')
          }
        })
      },
      setPwdInfo() {
        this.seleObj = Object.assign({}, reqSeleObj)
        this.oneQuestion.forEach((item) => {
          item.disabled = false
        })
        this.dialogVisible = true
      },
      handleSubmitForm() {
        let allQuestion = {
          securityQuestion: [this.baseForm.oneQuestion, this.baseForm.twoQuestion, this.baseForm.threeQuestion],
          securityAnswer: [this.baseForm.oneAnswer, this.baseForm.twoAnswer, this.baseForm.threeAnswer],
        }
        if (this.baseForm.oneQuestion == 'oneId') {
          allQuestion.securityQuestion[0] = this.baseForm.orderNumber1
        }
        if (this.baseForm.twoQuestion == 'oneId') {
          allQuestion.securityQuestion[1] = this.baseForm.orderNumber2
        }
        if (this.baseForm.threeQuestion == 'oneId') {
          allQuestion.securityQuestion[2] = this.baseForm.orderNumber3
        }
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let res = await apisaveSecurity(allQuestion).finally(() => (this.confirmBtnLoading = false))
            if (res.code === 0) {
              this.$msgSuccess('设置成功')
              this.dialogVisible = false
              this.$store.dispatch('user/getUserInfo')
              this.getList()
            }
          } else {
            return false
          }
        })
      },
    },
  }
</script>
<style scoped lang="scss">
  @import '~@/za/styles/mixin.scss';

  .tipsInfo {
    height: 40px;
    background: #fffbe6;
    border-radius: 2px;
    border: 1px solid #ffe58f;
    padding: 9px;
    margin-bottom: 30px;
  }

  .title {
    @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
    width: 100%;
    height: 20px;
  }

  .pwdLevel {
    display: flex;
    width: 100%;
    margin-top: 20px;

    .levelTitle {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #555555;
    }

    .prompt {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #555555;
      margin-left: 20px;
    }

    .default {
      background: #eeeeee;
      display: inline-block;
      width: 70px;
      height: 6px;
      line-height: 30px;
      text-align: center;
      vertical-align: middle;
    }

    .weak {
      background: #eb2f2f;
    }

    .medium {
      background: #aada63;
    }

    .strong {
      background-color: #78b02c;
    }
  }

  .main {
    margin-top: 30px;

    ul {
      list-style-type: none;
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 0;
      margin-top: 0;

      li {
        height: 100px;
        background-color: #f4f6fa;
        margin-bottom: 12px;
        padding: 20px 16px;

        .main_top {
          display: flex;
          justify-content: space-between;
          margin-bottom: 14px;

          .login_pwd {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #000000;
            margin-left: 10px;
          }

          .modify {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #3370ff;
            cursor: pointer;
          }
        }

        .main_bottom {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #555555;
          margin-left: 22px;
        }
      }

      li:nth-of-type(even) {
        margin-left: 20px;
      }
    }
  }
</style>