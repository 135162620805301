<template>
    <div class="home">
        <navbar/>
        <div class="mainBody">
            <div class="left">
                <div style="display: flex">
                    <userInfo></userInfo>
                    <moneyInfo/>
                </div>
                <agentInfo></agentInfo>
                <myResources></myResources>
                <product></product>
                <systemLog></systemLog>
            </div>
            <!-- <div class="right">
                <announcement></announcement>
                <resources></resources>
                <developer></developer>
                <helpDocument></helpDocument>
            </div> -->
        </div>
    </div>
</template>

<script>
    import Navbar from '@/za/layout/components/Navbar'
    import userInfo from './components/userInfo'
    import moneyInfo from './components/moneyInfo'
    import agentInfo from './components/agentInfo'
    import myResources from './components/myResources'
    import product from './components/product'
    import systemLog from './components/systemLog'
    import announcement from './components/announcement'
    import resources from './components/resources'
    import developer from './components/developer'
    import helpDocument from './components/helpDocument'
    import { apiGetCertification} from '@/api/accountCenter/nameCertification'
    export default {
        name: 'Home',
        components: {
            Navbar,
            userInfo,
            moneyInfo,
            agentInfo,
            myResources,
            product,
            systemLog,
            announcement,
            resources,
            developer,
            helpDocument
        },
        created() {
            this.$store.dispatch('user/getUserInfo')
        },
        mounted() {
          this.onSubmitData(apiGetCertification, null, null, false, false, () => {
                })
                // console.log(process.env.NODE_ENV);
        },
    }
    
</script>
<style lang="scss" scoped>


    .home {
        height: auto;
        background: #F3F4F7;
        box-sizing: border-box;
        /*overflow-x: hidden;*/

        .mainBody {
            box-sizing: border-box;
            padding: 20px 20px 0px 20px;
            display: flex;
            // @media (min-width: 1330px) {
            //     width: 1200px;
            // }
            @media (min-width: 1600px) {
                width: 1600px;
            }
            margin: 0 auto;

            .left {
                /*width: 1504px;*/
                height: auto;
                flex: 1 auto;
                min-width: 820px;
            }
            .right {
                width: 366px;
                height: auto;
                margin-left: 10px;
            }
        }
    }


</style>
