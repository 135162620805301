<template>
  <div>
    <div class="prompt" v-if="generalFlag">
      <div>
        <za-svg-icon icon-class="za-wanning" class-name="za-waring"></za-svg-icon>
      </div>
      <!--      <div>
              <span>您已经成功通过个人认证，您可以</span>
              <span class="blue_font" @click="amendPerson">修改个人认证</span>
              <span>企业用户可以选择</span>
              <span class="blue_font" @click="amendCompany">变更为企业认证</span>
            </div>-->
      <div>
        <span>
          温馨提示：您已经成功通过个人认证（或企业认证），请不要随意变更实名认证信息，若您需要变更，请仔细阅读《
        </span>
        <span class="blue_font" style="cursor: auto; margin: 0">申请账号主体的变更协议</span>
        <span>》，您提交认证变更视为已知晓并同意该协议。</span>
      </div>
    </div>
    <div class="prompt" v-if="cloudFlag">
      <div>
        <za-svg-icon icon-class="za-wanning" class-name="za-waring"></za-svg-icon>
      </div>
      <div>
        <span>温馨提示:您的账号已签约成为</span>
        <span class="blue_font" @click="amendCloud">智安云推官</span>
        <span>，变更实名信息将取消云推官的所有相关权益，账户未清算资金将被冻结，请勿随意变更。</span>
      </div>
    </div>
    <div v-if="url" v-za-loading="isLoading">
      <iframe :src="url" width="100%" frameborder="0" class="iframe" id="iframe"></iframe>
    </div>
    <za-dialog
      top-title="申请账号主体变更协议"
      :visible="promiseDialog"
      width="1000px"
      :showClose="false"
      :footState="true"
    >
      <Promise />
      <div slot="footer">
        <el-checkbox v-model="promiseFlag">我已阅读并接受《申请账号主体的变更协议》</el-checkbox>
        <el-button :type="buttonTypeObj.BLUE" @click="dialogToPay()" :disabled="!promiseFlag">确定</el-button>
      </div>
    </za-dialog>
  </div>
</template>
<script>
  import {
    apiCertification,
    apiGetCorporateCertification,
    apiGetPersonalCertification,
  } from '@/api/accountCenter/nameCertification'
  import { mapGetters } from 'vuex'
  import Promise from '@/views/accountCenter/nameCertification/components/promise.vue'

  export default {
    name: 'NameCertification',
    components: { Promise },
    data() {
      return {
        url: null,
        accountType: null,
        promiseDialog: false, //强制提醒弹框
        promiseFlag: false, //接受协议按钮判断
        generalFlag: false, //通用文案
        cloudFlag: false, //云推官文案
      }
    },
    computed: {
      ...mapGetters({
        isCloudPushOfficer: 'user/isRegister',
        popularizeInfo: 'user/popularizeInfo',
        userInfo: 'user/userInfo',
      }),
    },
    created() {
      this.getUrl()
      this.accountType = this.userInfo.accountType
      /**
       * @description 判断用户信息，展示不同的文案
       * @promiseDialog(Boolean):true,false  强制提醒
       * @accountType(Number):0未认证、1企业认证、2个人认证
       * 0、未认证：不展示文案
       * 1、企业认证：通用文案、强制提醒
       * 2、个人认证：云推官状态 state 开启 1、暂停 2 （云推官文案）；云推官状态 失效 3、不是云推官（通用文案、强制提醒）
       * */
      if (this.accountType === 1) {
        this.promiseDialog = true
        this.generalFlag = true
      } else if (this.accountType === 2) {
        if (this.isCloudPushOfficer && this.popularizeInfo.state !== 3) {
          this.cloudFlag = true
        } else {
          this.promiseDialog = true
          this.generalFlag = true
        }
      }
    },
    mounted() {
      // window.addEventListener("message", e => {
      // console.log(e, '-----------实名认证数据')
      // console.log(e.data, '<<<data11111111111111')
      // })
      // window.onmessage = (e) => {
      //   console.log(e);
      //   this.$store.dispatch('user/getUserInfo')
      // }
    },
    methods: {
      getUrl() {
        this.onSubmitData(apiCertification, null, null, false, false, (res) => {
          this.url = res.data.url
        })
      },
      amendPerson() {
        this.onSubmitData(apiGetPersonalCertification, null, null, false, false, (res) => {
          this.url = res.data.url
        })
      },
      amendCompany() {
        this.onSubmitData(apiGetCorporateCertification, null, null, false, false, (res) => {
          this.url = res.data.url
        })
      },
      amendCloud() {
        this.$router.push('/promotionManagement/overview')
      },
      dialogToPay() {
        this.promiseDialog = false
      },
    },
  }
</script>
<style scoped lang="scss">
  .iframe {
    height: calc(#{$base-page-height} - #{$base-header-height} - 40px);
  }

  .prompt {
    display: flex;
    height: 40px;
    background: #fffbe6;
    border-radius: 2px;
    border: 1px solid #ffe58f;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 40px;
    margin-bottom: 20px;

    .za-waring {
      margin-left: 16px;
      margin-right: 10px;
    }

    .blue_font {
      margin-left: 10px;
    }
  }
</style>