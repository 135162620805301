<template>
  <div>
    <el-table
      size="medium"
      class="change_text"
      :data="tableData"
      :cell-style="cellStyle"
      :header-cell-style="headerRowStyle"
      :header-cell-class-name="headerClassName"
      :expand-row-keys="expands"
      :row-class-name="getRowClass"
      :row-key="
        (row) => {
          return row.id || row.month
        }
      "
      :height="styleHeight || tableHeight"
      @selection-change="handleSelectionChange"
      @filter-change="filterChange"
      v-bind="$attrs"
      v-on="$listeners"
      :ref="occTable"
    >
      <!--  二级表格是否开启-->
      <el-table-column v-if="columObj.type" :type="columObj.type" width="1">
        <template #default="props">
          <div style="margin-bottom: 20px">
            <span>{{ props.row.month + ':' }}</span>
            <span>可申请发票，可开票总额：￥</span>
            <span>{{ infoNum }}</span>
          </div>
          <div>
            <occ-table
              :tableData="props.row.descData || []"
              :columObj="props.row.infoObj"
              @handleSelectionChange="handleSelectionChange"
              :isOp="props.row.infoObj.isOp"
              :style-height="'calc(100%-10px)'"
            ></occ-table>
          </div>

          <div v-if="props.row.infoTotal > 10">
            <pagination
              :limit.sync="props.row.infoPageObj.pageSize"
              :page.sync="props.row.infoPageObj.pageNo"
              :total="props.row.infoTotal"
              @pagination="getList(props.row)"
            />
          </div>
        </template>
      </el-table-column>
      <!-- 多选选择框是否开启，selectable控制是否单行禁用 -->
      <el-table-column v-if="columObj.selection && redio" type="selection" width="50px" />
      <!--  单选选择框是否开启    -->
      <el-table-column v-if="columObj.selection && !redio" type="selection" width="50px" />
      <!-- 普通列 -->
      <template v-for="(column, columIndex) in columObj.columnData">
        <template v-if="column.flag">
          <el-table-column
            :prop="column.prop"
            :key="columIndex"
            :label="column.label"
            :width="column.width"
            :fixed="column.fixed"
            :align="column.align || 'center'"
            :index="columIndex"
            :show-overflow-tooltip="column.showOverFlow == false ? false : true"
            :filters="column.filters ? column.filters : null"
            :filter-multiple="true"
            :column-key="column.prop"
          >
            <template slot-scope="{ row, $index }">
              <!-- 默认展示 -->
              <template v-if="column.text">
                <span :style="{ color: column.color || '' }">{{ row[column.prop] || '-' }}</span>
              </template>
              <!-- 默认展示 -->
              <template v-if="column.copy">
                <span @click="copy(row[column.prop] || '-')">{{ row[column.prop] || '-' }}</span>
              </template>
              <!-- 状态对象展示 -->
              <template v-else-if="column.status">
                <span>
                  <za-state
                    :enable="row[column.prop]"
                    :disabled-txt="column.disabledText"
                    :enabled-txt="column.enabledText"
                  ></za-state>
                </span>
              </template>
              <!-- 自定义内容 -->
              <template v-else-if="column.ownDefined">
                <span :style="[{ color: column.color || '' }, { fontSize: column.fontSize || '14px' }]">
                  {{ column.ownDefinedReturn(row, $index, column) }}
                </span>
              </template>
              <!--自定义HTML-->
              <template v-else-if="column.ownInnerHtml">
                <span v-html="column.htmlTest(row, $index)"></span>
              </template>
              <!--时间戳转换时间-->
              <template v-if="column.toTime">
                <span>{{ row.created | changeTimeStamp }}</span>
              </template>
              <!--  过滤器-->
              <template v-else-if="column.details">
                <span class="details" @click="datails(row)">{{ row[column.prop] || '-' }}</span>
              </template>
              <template v-else-if="column.desc">
                <span @click="handleDesc(row)" style="cursor: pointer; color: #1b5deb">
                  {{ row[column.prop] || '-' }}
                </span>
              </template>
              <!--下拉框-->
              <template v-else-if="column.selectState">
                <span class="selectState">
                  <template>
                    <el-select
                      v-model="row[column.prop]"
                      @change="handleCellEdit($index, row)"
                      placeholder="请选择等级"
                    >
                      <el-option
                        v-for="item in column.selectList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </template>
                </span>
              </template>
              <!-- 自定义文字颜色 -->
              <template v-else-if="column.colorType">
                <span :style="getEnumStyle(column, row[column.prop])">
                  {{ row[column.prop] }}
                </span>
              </template>
              <!--  文字颜色-->
              <template v-else-if="column.colorTest">
                <span :style="getEnumStyle(column, row[column.prop])">
                  {{ row[column.prop] }}
                </span>
              </template>
              <!-- 枚举 -->
              <template v-else-if="column.enumType">
                <span :style="getEnumStyle(column, row[column.prop])">
                  {{ formatVal(row, column, row[column.prop], column.enumType || column.prop) }}
                </span>
              </template>
            </template>
          </el-table-column>
        </template>
        <template v-else-if="column.prompt">
          <el-table-column
            :prop="column.prop"
            :key="columIndex"
            :label="column.label"
            :width="column.width"
            :render-header="renderHeader"
          >
            <template slot-scope="{ row, $index }">
              <!-- 自定义内容 -->
              <template v-if="column.ownDefined">
                <span :style="[{ color: column.color || '' }, { fontSize: column.fontSize || '14px' }]">
                  {{ column.ownDefinedReturn(row, $index, column) }}
                </span>
              </template>
            </template>
          </el-table-column>
        </template>
      </template>

      <el-table-column v-if="isOp" label="操作" :width="columObj.width">
        <template #default="{ row }">
          <div class="opColumn">
            <slot :row="row"></slot>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import autoTableHeightMixin from '@/za/mixin/autoTableHeightMixin'
  import { formatter } from '@/utils/formatter'

  export default {
    name: 'OccTable',
    mixins: [autoTableHeightMixin],
    props: {
      expands: {
        type: Array,
        default: () => [],
      },
      isOp: {
        type: Boolean,
        default: false,
      },
      isMultipleHeader: {
        type: Boolean,
        default: false,
      },
      tableData: {
        type: Array,
        required: () => [],
      },
      columObj: {
        type: Object,
        required: true,
      },
      redio: {
        type: Boolean,
        default: true,
      },
      styleHeight: {
        type: String,
        default: '',
      },
      infoNum: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        occTable: 'occTable',
        multipleSelection: [],
        diaplay: 'none',
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.$on('changeMultipleSelection', (bool) => {
          this.changeMultipleSelection(this.tableData, bool)
        })
      })
    },
    methods: {
      renderHeader(h, { column, $index }) {
        return h('div', [
          h('span', column.label),
          h(
            'el-tooltip',
            {
              props: {
                effect: 'dark',
                content: '根据国家税法规定，依法为推广者代扣代缴的税费',
                placement: 'top',
              },
            },
            [
              h('i', {
                class: 'el-icon-warning',
                style: 'margin-left:5px;',
                // style: 'color:#409eff;margin-left:5px;',
              }),
            ]
          ),
        ])
      },
      getEnumStyle(col, value) {
        if (!col.colors) {
          return {}
        } else {
          return {
            color: col.colors[value],
          }
        }
      },
      // 格式化表格字段
      formatVal(row, column, cellValue, enumType) {
        if (enumType) {
          return formatter(enumType, cellValue)
        }
        return formatter(column.property, cellValue)
      },
      handleCellEdit(index, row) {
        this.$emit('changeTime', row)
      },
      headerClassName(row) {
        if (typeof row.column.filterable == 'object') {
          return 'totalClass'
        }
        if (this.redio == false) {
          return 'selectRedio'
        }
      },
      getRowClass() {
        let res = []
        res.push('row-expand-unhas')
        return res
      },
      headerRowStyle() {
        const styleObj = {}
        styleObj.color = ' rgba(0,0,0,0.85)'
        styleObj.backgroundColor = '#F4F6FA'
        styleObj.border = 0
        styleObj.fontSize = '14px'
        styleObj.textAlign = 'center'
        if (this.isMultipleHeader) {
          styleObj.border = '1px solid rgba(229, 229, 229, 1)'
        } else {
          styleObj.borderBottom = '1px solid rgba(229, 229, 229, 1)'
        }
        return styleObj
      },
      cellStyle({ rowIndex }) {
        const styleObj = {}
        styleObj.color = '#5C5F66'
        styleObj.fontSize = '14px'
        styleObj.textAlign = 'center'
        if (this.isMultipleHeader) {
          styleObj.border = '1px solid rgba(229, 229, 229, 1)'
        } else {
          styleObj.borderBottom = '1px solid rgba(229, 229, 229, 1)'
        }

        return styleObj
      },
      //多选框变化
      handleSelectionChange(val) {
        if (this.redio) {
          this.multipleSelection = val
          this.$emit('handleSelectionChange', this.multipleSelection)
          this.changeMultipleSelection(val, true)
        } else {
          this.multipleSelection = []
          if (val.length > 1) {
            this.$refs.occTable.clearSelection()
            this.$refs.occTable.toggleRowSelection(val[val.length - 1])
          }
          this.multipleSelection = [val[val.length - 1]]
          this.$emit('handleSelectionChange', this.multipleSelection)
        }
      },
      changeMultipleSelection(rows, bool) {
        if (rows && bool) {
          rows.forEach((row) => {
            this.$nextTick(() => {
              this.$refs.occTable.toggleRowSelection(row, true)
            })
          })
        } else {
          this.$refs.occTable.clearSelection()
        }
      },
      filterChange(column) {
        this.$emit('select', column)
      },
      handleDesc(row) {
        this.$emit('handleDesc', row)
      },
      getList(row) {
        this.$emit('getList', row)
      },
      datails(row) {
        this.$emit('datails', row)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .details {
    font-size: 14px;
    color: #1b5deb;
  }

  .el-button {
    margin: 0 6px;
  }

  ::v-deep .el-input__inner {
    border: none;
  }

  ::v-deep .el-image__inner {
    height: 50px;
  }

  // switch左边文字颜色
  ::v-deep .el-switch__label--left {
    color: #606266;
  }

  ::v-deep .el-icon-arrow-down:before {
    font-family: 'iconfont' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    font-style: normal;
    content: '\e610';
  }

  img {
    height: 400px;
  }

  .change_text {
    ::v-deep {
      .el-table__column-filter-trigger {
        display: none !important;
      }

      .totalClass {
        .el-table__column-filter-trigger {
          display: inline-block !important;
        }
      }

      .selectRedio .el-checkbox {
        //找到表头那一行，然后把里面的复选框隐藏掉
        display: none;
      }

      .el-table__expanded-cell {
        background: #dde0e5;
        padding: 20px 40px;
      }

      .el-table__expanded-cell:hover {
        background: #dde0e5 !important;
      }

      .row-expand-unhas .el-table__expand-column {
        pointer-events: none;
      }

      .row-expand-unhas .el-table__expand-column .el-icon {
        visibility: hidden;
      }
    }

    .selectState {
      ::v-deep {
        .el-select {
          border: 1px solid rgb(244, 246, 250);
        }
      }
    }
  }
</style>