<template>
  <div>
    <div>
      <div class="heardBlock">
        <div class="ignore_center" @click="officialWeb">
          <el-image class="pic-error-parent" :src="require('@/assets/home/home_logo.png')" />
        </div>
        <div class="title">智安服务条款</div>
      </div>
    </div>
    <div class="main">
      <span class="blue_font" @click="$router.push('/login')">返回</span>
      <div class="title">
        <h3>智安服务条款</h3>
      </div>
      <h5>特别提示</h5>
      <span class="first">
        您在使用智安公司提供的各项服务之前，请您务必审慎阅读、充分理解本协议各条款内容，特别是以粗体标注的部分，包括但不限于免除或者限制责任的条款。如您不同意本服务协议及/或随时对其的修改，您可以主动停止使用智安公司提供的服务；您一旦使用智安公司提供的服务，即视为您已了解并完全同意本服务协议各项内容，包括智安公司对服务协议随时所做的任何修改，并成为我们的用户。
      </span>
      <h5>一、总则</h5>
      <span>
        1.
        用户可以使用智安各个频道、产品的单项服务，当用户使用智安各单项服务时，用户的使用行为视为其对该单项服务的服务条款以及智安在该单项服务中发出的各类公告的同意。
      </span>
      <br />
      <span>
        2.
        智安用户协议以及各个频道、产品单项服务条款和公告可由智安公司随时更新，且无需另行通知。您在使用相关服务时,应关注并遵守其所适用的相关条款。
      </span>
      <br />
      <h5>二、帐号注册与使用</h5>
      <span>您使用部分智安产品或服务时需要注册智安帐号，当您注册和使用智安帐号时应遵守下述要求：</span>
      <span>
        1.
        用户注册成功后，智安公司将给予每个用户一个用户帐号，用户可以自主设置帐号密码。该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。
      </span>
      <br />
      <span>
        2.
        您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，除智安平台的具体产品对帐户有单独的注册要求外，您可获得智安平台（zhiannet.com网站）帐号并成为智安用户，可通过智安帐户使用智安平台的各项产品和服务。
      </span>
      <br />
      <span>
        3.
        为了方便您在智安产品中享有一致性的服务，如您已经在某一智安产品中登录智安帐号，在您首次使用其他智安产品时可能同步您的登录状态。此环节并不会额外收集、使用您的个人信息。如您想退出帐号登录，可在产品设置页面退出登录。
      </span>
      <br />
      <span>
        4.
        智安帐号（即智安用户ID）的所有权归智安公司，用户按注册页面引导填写信息，阅读并同意本协议且完成全部注册程序后，即可获得智安帐号并成为用户。用户应提供及时、详尽及准确的个人资料，并不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。如果因注册信息不真实或更新不及时而引发的相关问题，智安公司不负任何责任。您可以通过智安帐号设置页面查询、更正您的信息，智安帐号设置页面地址：用户应当通过真实身份信息认证注册帐号，且用户提交的帐号名称、头像和简介等注册信息中不得出现违法和不良信息，经智安公司审核，如存在上述情况，智安公司将不予注册；同时，在注册后，如发现用户以虚假信息骗取帐号名称注册，或其帐号头像、简介等注册信息存在违法和不良信息的，智安公司有权不经通知单方采取限期改正、暂停使用、注销登记、收回等措施。
      </span>
      <br />
      <span>
        5.
        基于国家法律法规的规定要求或者智安服务政策的要求，您在使用某些智安服务时需要填写真实的姓名、身份证号等信息。若您填写的信息不完整、不正确，则可能无法使用相应服务或在使用过程中受到限制。
      </span>
      <br />
      <span>
        6.
        当您使用刷脸登录、验证功能时，我们会收集并保存您的人脸照片和信息。您应按照页面的提示完成相应动作，智安系统判断您的动作符合要求后，即可完成脸部拍摄。您同意仅限您本人为您本人所持有的智安账户使用刷脸登录、验证服务。为了提高验证的准确性，您同意智安可在必要时将您向智安提供的人脸照片、脸部特征与法律法规允许的机构或政府机关授权的机构所保存的您的脸部特征进行比对核验。
      </span>
      <br />
      <span>
        7.
        智安帐号包括帐户名称和密码，您可使用设置的帐户名称（包括用户名、手机号、邮箱）和密码登录；在帐号使用过程中，为了保障您的帐号安全基于不同的终端以及您的使用习惯，我们可能采取不同的验证措施识别您的身份。例如您的帐户在新设备首次登录，我们可能通过密码加校验码的方式识别您的身份，验证方式包括但不限于短信验证码、服务使用信息验证。
      </span>
      <br />
      <span>
        8.
        用户不应将其帐号、密码转让、出售或出借予他人使用，帐号使用权仅属于初始申请注册人，用户应对帐号内的全部行为独立承担全部责任。如果智安可合理判断相关帐号存在帐号交易情况、帐号被盗风险，智安有权独立采取冻结帐号、封禁帐号等措施。如因帐号转让、买卖或出借导致帐号纠纷或智安无法判断帐号归属，智安有权拒绝提供帐号找回，一旦发生该等举报或投诉，智安有权立即对帐号进行冻结或封禁。您应该妥善保管您的帐号和密码，您不应轻信借款、索要密码或其他涉及财产的网络信息，不应对外透露您的智安帐号、密码、智安向您发送的验证码信息或在非智安网站输入智安帐号、密码、验证码信息或在非智安网站通过二维码扫描等方式进行登录操作，否则可能导致您的帐号泄露及财产受损，上述因您帐号保管不当造成的损失将由您自行承担，同时，当发生上述情形且当智安无法合理判断帐号归属时，将无法为您找回帐号。
      </span>
      <br />
      <h6>
        9.因您个人原因导致的帐号无法使用，如需找回智安帐号，请按照智安帐号找回要求提供相应的信息，并确保提供的信息合法真实有效，若存在以下情形之一：（1）提供的信息不符合要求（2）帐号的唯一凭证不再有效（3）无法通过智安公司安全验证（4）存在帐号被盗等安全风险，或存在其他导致无法判断帐号归属的情形，智安公司有权拒绝提供帐号找回服务，并视情况采取冻结帐号、封禁帐号等措施。您智安帐号下绑定的手机号如因您个人原因停止使用，请尽快更改智安帐号的绑定手机，否则如遇运营商二次放号，后续使用该手机号的用户可以通过您原来的手机号进行验证登录，可查看到您的全部帐号信息及产品使用信息，您的智安帐号将无法继续使用，帐号内个人信息也有泄露的风险，请您务必及时更换绑定手机，如因未及时换绑手机号导致帐号丢失或个人信息泄露的责任由您自行承担，智安公司有权拒绝提供帐号找回服务，并视情况采取冻结帐号、封禁帐号等措施。
      </h6>
      <h6>
        10.
        在需要终止使用智安帐号服务时，符合以下条件的，您可以申请注销您的智安帐号，帐号注销不可撤销且不可逆转，请您谨慎操作，同时请您务必知悉确认：
      </h6>
      <h6>（1）您仅能申请注销您本人的帐号，并依照智安公司的流程进行注销；</h6>
      <h6>（2）您仍应对您在注销帐号前且使用智安公司服务期间的行为承担相应责任；</h6>
      <h6>
        （3）请您在注销前自行备份相关信息及数据，注销成功后，除法律法规另有规定或双方另有约定，帐号信息、个人身份证信息、交易记录、会员权益、存储信息等与帐号绑定或相关的信息将无法找回、恢复或提供，您也将无法再通过原帐号进行登录（即使通过同一手机号重新注册或能够证明您是原帐号权利人）或进行任何操作，您新注册的帐号也将无法再设置原帐号用户名。
      </h6>

      <h6>
        11.为了防止资源占用，如您连续六个月未使用您的智安帐号或未通过智安公司认可的其他方式登录过您的智安帐户，智安公司有权对该帐户进行回收，您将不能再通过该帐号登录名登录本网站或使用相关服务。如该帐户有关联的理财产品、待处理交易或余额，智安公司会在合理范围内协助您处理，请您按照智安公司提示的方式进行操作。
      </h6>
      <span>
        12.
        我们将按照智安公司的隐私权保护声明,使用您在注册智安帐户、使用智安服务中提交、留存的信息。您保证在您使用智安公司的所有产品和服务前，已经充分了解并同意智安公司可以据此处理用户信息。
      </span>
      <br />
      <span>
        13.
        为了满足相关法律法规的要求和保障您的帐号安全，尤其是您在进行帐号解绑、注销等敏感操作时，我们会将您提交的个人身份信息发送至银行、运营商等机构进行验证查询，以核实您身份的真实性。
      </span>
      <br />
      <span>
        14.
        当用户使用一键登录功能时，运营商会将用户的本机手机号传输给智安，用以智安判断您的手机号是否已注册智安帐号。如您的手机号已注册智安帐号，并通过安全验证，则您将无需填写手机号、验证码、帐户名、密码等，可直接完成帐号登录。如您不满足一键登录的要求，或者选取了其他登录方式，您可使用其他登录方式进行帐号登录。
      </span>
      <br />
      <span>
        15.
        在符合法律法规的规定，或经国家机关要求的前提下，我们有权对您的帐号进行限制或冻结，在该等情况下，您可能无法继续登录或使用您的帐号，如相关规定同时要求或建议我们开展进一步帐号认证工作，请您配合。为配合监管机关、执法机构、司法部门要求，或为处理诉讼案件等与您有关的争议所需，我们可能会向监管机关、执法机构或司法部门提供与您帐号有关的信息，包括但不限于您的注册信息、登录信息、实名认证信息、绑定信息及可以反映您帐号归属、使用情况的其他有关信息。
      </span>
      <br />
      <span>
        16.
        您应当合理注册并使用智安帐号，不得恶意注册或违规使用智安帐号（常见违规行为包括但不限于频繁注册、批量注册等可以合理认为是通过机器进行帐号操作的行为、恶意滥用多个帐号、恶意辅助验证/找回帐号、使用智安帐号进行违法、违反本协议或智安公司（及关联公司）其他产品协议/规则的行为或将智安帐号用于其他不正当用途）。您需要通过智安官方渠道注册智安帐号，不得通过其他非官方渠道注册或获取智安帐号或其登录凭证，不得通过实际控制多个帐号等方式实施违反法律法规、本协议及智安（及关联公司）其他产品协议的行为，不得利用任何手段绕开、对抗或破坏平台注册、登录及使用规则。我们有权对上述恶意注册、违规使用行为进行独立判断并在合理判断存在上述行为时，单方决定采取处置措施，处置措施包括但不限于删除用户发布信息内容、暂停使用许可、终止服务、限制使用、禁止登录，禁止换绑，禁止申诉、回收智安帐号、冻结/封禁智安帐号、追究法律责任等智安判断可用以合理处置作弊行为、保护平台及用户帐号安全的措施。如用户有证据证明未采取以上作弊行为，可以通过本协议公示的联系渠道向我们进行申诉。如智安公司（及关联公司）其他产品协议对智安帐号使用进行了具体规定，相关规定将作为本协议的补充同时适用。
      </span>
      <br />
      <h5>三、使用规则</h5>
      <h6>
        1.用户在使用智安公司的服务时，必须遵守《网络安全法》、《计算机信息网络国际联网安全保护管理办法》、《互联网信息服务管理办法》、《互联网电子公告服务管理规定》、《维护互联网安全的决定》、《互联网新闻信息服务管理规定》、《长江保护法》、《测绘法》、《互联网宗教信息服务管理办法》等中华人民共和国相关法律法规的规定，用户应同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为:
      </h6>
      <h6>（1）上载、展示、张贴、传播或以其它方式传送含有下列内容之一的信息：</h6>
      <h6 class="mgLeft">1）反对宪法所确定的基本原则的；</h6>
      <h6 class="mgLeft">2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</h6>
      <h6 class="mgLeft">3）损害国家荣誉和利益的；</h6>
      <h6 class="mgLeft">4）煽动民族仇恨、民族歧视、破坏民族团结的；</h6>
      <h6 class="mgLeft">
        5）破坏国家宗教政策，传教,
        开展宗教教育培训、发布讲经讲道内容或者转发、链接相关内容,组织开展宗教活动,以直播或者录播宗教仪式,成立宗教组织、设立宗教院校和宗教活动场所、发展教徒,以宗教名义开展募捐,宣扬邪教和封建迷信的；
      </h6>
      <h6 class="mgLeft">6）散布谣言，扰乱社会秩序，破坏社会稳定的</h6>
      <h6 class="mgLeft">7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</h6>
      <h6 class="mgLeft">8）侮辱或者诽谤他人，侵害他人合法权利的；</h6>
      <h6 class="mgLeft">
        9）含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；
      </h6>
      <h6 class="mgLeft">
        10）与电鱼、毒鱼、炸鱼等破坏渔业资源和生态环境的捕捞行为相关的违法违规内容（包括但不限于文字、图片、动态、视频等）；
      </h6>
      <h6 class="mgLeft">11）含有可能涉及侵害国家主权和领土完整、违反国家关于地图绘制、发布相关规定的内容；</h6>
      <h6 class="mgLeft">12）含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；</h6>
      <h6>（2）不得为任何非法目的而使用网络服务系统；</h6>
      <h6>（3）不利用智安公司的服务从事以下活动：</h6>
      <h6 class="mgLeft">1) 未经允许，进入计算机信息网络或者使用计算机信息网络资源的；</h6>
      <h6 class="mgLeft">2) 未经允许，对计算机信息网络功能进行删除、修改或者增加的；</h6>
      <h6 class="mgLeft">
        3) 未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；
      </h6>
      <h6 class="mgLeft">4) 故意制作、传播计算机病毒等破坏性程序的；</h6>
      <h6 class="mgLeft">5) 其他危害计算机信息网络安全的行为。</h6>
      <h6>
        2.
        用户违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，您同意赔偿智安公司与合作公司、关联公司，并使之免受损害。对此，智安公司有权视用户的行为性质，采取包括但不限于删除用户发布信息内容、暂停使用许可、终止服务、限制使用、回收智安帐号、追究法律责任等措施。
      </h6>
      <span>3. 用户不得对本服务任何部分或本服务之使用或获得，进行复制、拷贝、出售、转售或用于任何其它商业目的。</span>
      <br />
      <span>
        4.
        用户须对自己在使用智安公司服务过程中的行为承担法律责任。用户承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在智安公司首先承担了因用户行为导致的行政处罚或侵权损害赔偿责任后，用户应给予智安公司等额的赔偿。
      </span>
      <br />
      <span>5. 用户在使用智安公司服务时遵守以下互联网底线：</span>
      <br />
      <span class="mgLeft">1）法律法规底线</span>
      <br />
      <span class="mgLeft">2）社会主义制度底线</span>
      <br />
      <span class="mgLeft">3）国家利益底线</span>
      <br />
      <span class="mgLeft">4）公民合法权益底线</span>
      <br />
      <span class="mgLeft">5）社会公共秩序底线</span>
      <br />
      <span class="mgLeft">6）道德风尚底线</span>
      <br />
      <span class="mgLeft">7）信息真实性底线</span>
      <br />
      <span>
        其是您在进行帐号解绑、注销等敏感操作时，我们会将您提交的个人身份信息发送至银行、运营商等机构进行验证查询，以核实您身份的真实性。
      </span>
      <br />
      <span>
        6.
        当用户使用一键登录功能时，运营商会将用户的本机手机号传输给智安，用以智安判断您的手机号是否已注册智安帐号。如您的手机号已注册智安帐号，并通过安全验证，则您将无需填写手机号、验证码、帐户名、密码等，可直接完成帐号登录。如您不满足一键登录的要求，或者选取了其他登录方式，您可使用其他登录方式进行帐号登录。
      </span>
      <br />
      <h5>四、服务内容</h5>
      <span>1. 智安公司网络服务的具体内容由智安公司根据实际情况提供。</span>
      <br />
      <span>2. 除非本服务协议另有其它明示规定，智安公司所推出的新产品、新功能、新服务，均受到本服务协议之规范。</span>
      <br />
      <span>
        3.
        为使用本服务，您必须能够自行经有法律资格对您提供互联网接入服务的第三方，进入国际互联网，并应自行支付相关服务费用。此外，您必须自行配备及负责与国际联网连线所需之一切必要装备，包括计算机、数据机或其它存取装置。
      </span>
      <br />
      <h6>
        4.
        鉴于网络服务的特殊性，用户同意智安公司有权不经事先通知，随时变更、中断或终止部分或全部的网络服务（包括收费网络服务）。智安公司不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。
      </h6>
      <h6>5. 免责声明：因以下情况造成网络服务在合理时间内的中断，智安公司无需为此承担任何责任；</h6>
      <h6 class="mgLeft">
        （1）智安公司需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，智安公司保留不经事先通知为维修保养、升级或其它目的暂停本服务任何部分的权利。
      </h6>
      <h6 class="mgLeft">（2）因台风、地震、洪水、雷电或恐怖袭击等不可抗力原因；</h6>
      <h6 class="mgLeft">（3）用户的电脑软硬件和通信线路、供电线路出现故障的；</h6>
      <h6 class="mgLeft">
        4）因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因、第三方服务瑕疵或政府行为等原因。
        尽管有前款约定，智安公司将采取合理行动积极促使服务恢复正常。
      </h6>
      <h6>
        6.
        本服务或第三人可提供与其它国际互联网上之网站或资源之链接。由于智安公司无法控制这些网站及资源，您了解并同意，此类网站或资源是否可供利用，智安公司不予负责，存在或源于此类网站或资源之任何内容、广告、产品或其它资料，智安公司亦不予保证或负责。因使用或依赖任何此类网站或资源发布的或经由此类网站或资源获得的任何内容、商品或服务所产生的任何损害或损失，智安公司不承担任何责任。
      </h6>
      <h6>
        7.
        用户明确同意其使用智安公司网络服务所存在的风险将完全由其自己承担。用户理解并接受下载或通过智安公司服务取得的任何信息资料取决于用户自己，并由其承担系统受损、资料丢失以及其它任何风险。智安公司对在服务网上得到的任何商品购物服务、交易进程、招聘信息，都不作担保。
      </h6>
      <h6>
        8.用户须知：智安公司提供的各种挖掘推送服务中，推送给用户曾经访问过的网站或资源之链接是基于机器算法自动推出，智安公司不对其内容的有效性、安全性、合法性等做任何担保。
      </h6>
      <h6>
        9.
        智安公司有权于任何时间暂时或永久修改或终止本服务（或其任何部分），而无论其通知与否，智安公司对用户和任何第三人均无需承担任何责任。
      </h6>
      <span>10. 终止服务</span>
      <h6>
        您同意智安公司得基于其自行之考虑，因任何理由，包含但不限于长时间（超过一年）未使用，或智安公司认为您已经违反本服务协议的文字及精神，终止您的密码、帐号或本服务之使用（或服务之任何部分），并将您在本服务内任何内容加以移除并删除。您同意依本服务协议任何规定提供之本服务，无需进行事先通知即可中断或终止，您承认并同意，智安公司可立即关闭或删除您的帐号及您帐号中所有相关信息及文件，及/或禁止继续使用前述文件或本服务。此外，您同意若本服务之使用被中断或终止或您的帐号及相关信息和文件被关闭或删除，智安公司对您或任何第三人均不承担任何责任。
      </h6>
      <h5>五、知识产权和其他合法权益（包括但不限于名誉权、商誉权）</h5>
      <span>1. 用户权利保护</span>
      <br />
      <span>
        若您认为他人智安帐号（包括智安帐号的头像、用户名、昵称、简介）的使用侵犯了您的合法权益，请按照以下说明向智安公司提供资料∶
      </span>
      <br />
      <span>
        请注意：如果权利通知的陈述失实，权利通知提交者将承担对由此造成的全部法律责任（包括但不限于赔偿各种费用及律师费）。如果上述个人或单位不确定网络上可获取的资料是否侵犯了其知识产权和其他合法权益，智安公司建议该个人或单位首先咨询专业人士。
      </span>
      <br />
      <span>为了智安公司有效处理上述个人或单位的权利通知，请使用以下格式（包括各条款的序号）：</span>
      <br />
      <span class="mgLeft">1）权利人对涉嫌侵权内容拥有合法权益的权属证明；</span>
      <br />
      <span class="mgLeft">
        2）请充分、明确地描述被侵犯了合法权益的情况并请提供涉嫌侵权的第三方帐号信息（包括用户名、昵称、头像、简介）和网址（如果有）。
      </span>
      <br />
      <span class="mgLeft">3）请指明涉嫌侵权帐号的哪些内容侵犯了第2项中列明的权利。</span>
      <br />
      <span class="mgLeft">
        4）请提供权利人具体的联络信息，包括姓名、身份证或护照复印件（对自然人）、单位登记证明复印件（对单位）、通信地址、电话号码、传真和电子邮件。
      </span>
      <br />
      <span class="mgLeft">
        5）请在权利通知中加入如下关于通知内容真实性的声明：
        “我保证，本通知中所述信息是充分、真实、准确的，如果本权利通知内容不完全属实，本人将承担由此产生的一切法律责任。”
      </span>
      <br />
      <span class="mgLeft">
        6）请您签署该文件，如果您是依法成立的机构或组织，请您加盖公章。请您把以上资料的原件扫描件发送至：
      </span>
      <br />
      <span class="mgLeft">PC端：【帐号设置】-【意见反馈】或【在线客服】</span>
      <br />
      <h6 class="mgLeft">
        【特别注意：上述入口仅接受关于智安帐号（即仅限于帐号头像、昵称、用户名、简介）的侵权投诉。如您有关于智安非帐号产品的投诉，请按照智安权利声明或具体产品公示的投诉路径进行投诉。如您有针对智安产品的版权侵权投诉，请您至智安版权平台进行投诉。】
      </h6>
      <span>
        2.对于用户通过智安公司服务（包括但不限于贴吧、公众号、智安云等）上传到智安公司网站上可公开获取区域的任何内容，用户同意智安公司在全世界范围内具有免费的、永久性的、不可撤销的、非独家的和完全再许可的权利和许可，以使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示此等内容（整体或部分），和/或将此等内容编入当前已知的或以后开发的其他任何形式的作品、媒体或技术中。
      </span>
      <br />
      <span>
        3. 智安公司拥有本网站内所有资料的版权。任何被授权的浏览、复制、打印和传播属于本网站内的资料必须符合以下条件：
      </span>
      <br />
      <span class="mgLeft">所有的资料和图象均以获得信息为目的；</span>
      <br />
      <span class="mgLeft">所有的资料和图象均不得用于商业目的；</span>
      <br />
      <span class="mgLeft">所有的资料、图象及其任何部分都必须包括此版权声明;</span>
      <br />
      <span class="mgLeft">
        本网站（www.zhiannet.com）所有的产品、技术与所有程序均属于智安公司知识产权，在此并未授权。“zhian”,
        “智安”及相关图形等为智安公司的注册商标。
      </span>
      <br />
      <span class="mgLeft">
        未经智安公司许可，任何人不得擅自（包括但不限于：以非法的方式复制、传播、展示、镜像、上载、下载）使用。否则，智安公司将依法追究法律责任。
      </span>
      <br />
      <h5>六、青少年用户特别提示</h5>
      <span>青少年用户必须遵守全国青少年网络文明公约：</span>
      <br />
      <span>
        要善于网上学习，不浏览不良信息；要诚实友好交流，不侮辱欺诈他人；要增强自护意识，不随意约会网友；要维护网络安全，不破坏网络秩序；要有益身心健康，不沉溺虚拟时空。
      </span>
      <br />
      <h5>七、其他</h5>
      <span>1. 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。</span>
      <span>
        2.
        如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向智安公司所在地深圳市南山区人民法院提起诉讼。
      </span>
      <span>3.智安公司未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。</span>
      <br />
      <span>
        4. 如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。
      </span>
      <br />
      <span>
        5.
        当您通过智安帐号登录、使用第三方产品或服务时，在遵守本协议基础上，您同时需要遵守该第三方产品或服务的具体使用规则，请您在发现任何违反本服务协议之情形时，通知智安。您可以通过如下联络方式同智安联系：
      </span>
      <br />
      <span class="mgLeft">PC端：【帐号设置】-【意见反馈】或【在线客服】</span>
      <br />
    </div>
    <div class="ignore_center">
      <span>
        Copyright©2023 深圳市智安网络有限公司 All Rights Reve 版权所有
        《中华人民共和国增值电信业务经营许可证》编号：B1-20190666
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" class="blue_font">粤ICP备18027011号</a>
      </span>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {}
    },
    methods: {
      officialWeb() {
        window.open(`${process.env.VUE_APP_PORTAL_TARGET_URL}`, '_self')
      },
    },
  }
</script>
<style lang="scss" scoped>
  .heardBlock {
    width: $base-page-width;
    height: 50px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 2px rgba(68, 68, 68, 0.2);
    z-index: 100;
    padding: 10px 20px;

    .ignore_center {
      float: left;
      width: 104px;
      height: 30px;
      box-sizing: border-box;
      margin-top: 0;
      cursor: pointer;
    }

    .title {
      font-size: 14px;
      float: left;
      width: 104px;
      height: 30px;
      box-sizing: border-box;
      margin-left: 66px;
      margin-top: 5px;
    }

    ::v-deep {
      .el-image__inner {
        height: 30px !important;
      }
    }
  }

  .ignore_center {
    width: $base-page-width;
    background: #ffffff;
    text-align: center;
    height: 60px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 60px;
    margin-top: 50px;
  }

  span {
    margin: 0px;
    padding: 0px;
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
    color: #333333;
    list-style: none;
    font-family: Arial, '宋体';
  }

  h6 {
    font-weight: bold;
    margin: 0px;
    padding: 0px;
    font-size: 12px;
    line-height: 16px;
    color: #333333;
    list-style: none;
    font-family: Arial, '宋体';
  }

  .mgLeft {
    margin-left: 30px;
  }

  .heard {
    width: 960px;
    min-width: 960px;
    margin: 0 auto;
    padding: 15px;

    .picparent {
      width: 137px;
      height: 46px;
    }
  }

  .main {
    width: 1170px;
    min-width: 1170px;
    max-width: 1170px;
    margin: 50px auto 0px;
    padding: 20px 20px 16px 20px;
    line-height: 22px;
    background: #ffffff;

    h3 {
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 1px;
      text-align: center;
      margin: 0;
    }

    a {
      text-decoration: none;
      color: #1b5deb;
    }

    h5 {
      font-size: 14px;
      font-weight: bold;
      padding: 1em 0px;
      margin: 0;
    }

    .first {
      font-weight: bold;
    }
  }
</style>
