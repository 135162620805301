<template>
    <div class="renewal-center_bg" v-za-loading="isLoading">
        <div class="screening">
            <div class="title">{{title}}</div>
            <div class="condition">
                <div>续费条件 ：</div>
                <div v-for="(item,index) in conditionList " :class="{ selected: index === conditionIndex }"
                     class="choose" @click="chooseCond(index,item,'1')">{{item.name}}
                </div>
            </div>
            <div class="condition">
                <div>产品类别 ：</div>
                <div v-for="(item,index) in typeList " :class="{ selected: index === typeIndex }"
                     class="choose" @click="chooseCond(index,item,'2')">{{item.name+'('+item.value+')'}}
                </div>
            </div>
            <div class="condition mgleft">
                <div>地域 ：</div>
                <div v-for="(item,index) in regionList " :class="{ selected: index === regionIndex }"
                     class="choose" @click="chooseCond(index,item,'3')">{{item.name+'('+item.value+')'}}
                </div>
            </div>
        </div>
        <div class="main">
            <za-query-form>
                <za-query-form-left-panel :span='13'>
                    <div class="condition">
                        <div>到期时间 ：</div>
                        <div v-for="(item,index) in expireList " :class="{ selectTime: index === exprieIndex }"
                             class="choose"
                             @click="chooseCond(index,item,'4')">{{item.name+'('+item.value+')'}}
                        </div>
                    </div>
                </za-query-form-left-panel>
                <za-query-form-right-panel :span="11">
                    <el-row>
                        <el-form :inline="true" :model="pageObj" @submit.native.prevent>
                            <el-col :span="16">
                                <el-form-item>
                                    <el-input v-model="pageObj.keyword"
                                              placeholder="输入产品名称或ID查找  ">
                                        <el-button slot="append" icon="el-icon-search" @click="getList()"></el-button>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="4">
                                <el-form-item style="margin-left: 70px!important;">
                                    <za-grid :tableTitle="columObj.columnData " @getList="getList"
                                             :checkList="checkList"
                                             :checkboxList="checkboxList"/>
                                </el-form-item>
                            </el-col>
                        </el-form>
                    </el-row>
                </za-query-form-right-panel>
            </za-query-form>
            <za-query-form>
                <za-query-form-left-panel :span="24">
                    <el-button type="primary" @click="batchRenewal" :disabled="multipleSelection.length === 0">批量续费
                    </el-button>
                    <el-button type="primary" @click="autoRenewal" :disabled="multipleSelection.length === 0">设为自动续费
                    </el-button>
                    <el-button type="primary" @click="handleEdit" :disabled="multipleSelection.length === 0">关闭自动续费
                    </el-button>
                </za-query-form-left-panel>
            </za-query-form>
            <occ-table :tableData="tableData" :columObj="columObj"
                       @handleSelectionChange="handleSelectionChange" :isOp="columObj.isOp">
                <template #default="{row}">
<!--                    1自动 2 手动-->
                    <el-button type="primary" @click="batchRenewal(row)" v-if="row.renewType==2">
                        续费
                    </el-button>
                    <el-button type="primary" @click="autoRenewal(row)" v-if="row.renewType==2">
                        设置为自动续费
                    </el-button>
                    <el-button type="primary"  v-if="row.renewType!=2" disabled>自动续费</el-button>
                    <el-button type="primary" @click="handleEdit(row)" :disabled="row.renewType!=1">
                        关闭自动续费
                    </el-button>
                </template>
            </occ-table>
            <pagination
                    :limit.sync="pageObj.pageSize"
                    :page.sync="pageObj.pageNo"
                    :total="total"
                    @pagination="getList"
            />
        </div>
        <div>
            <za-dialog
                    :top-title="dialogType | dialogTitle(dialogTypeObj)"
                    :visible.sync="dialogInfo"
                    width="800px"
            >
                <div>
                    <occ-table :tableData="renewalData" :columObj="renewalObj"
                               :isOp="renewalObj.isOp" :style-height="'calc(100%-10px)'"
                               @changeTime="changeTime"
                    >
                    </occ-table>
                </div>
                <div slot="footer">
                    <za-button :loading="confirmBtnLoading" :type="buttonTypeObj.BLUE" @click="handleSubmitForm">
                        下一步
                    </za-button>
                    <za-button :disabled="confirmBtnLoading" :type="buttonTypeObj.GREY" @click="dialogInfo = false">
                        取消
                    </za-button>
                </div>
            </za-dialog>
            <za-dialog
                    :top-title="dialogType | dialogTitle(dialogTypeObj)"
                    :visible.sync="autoDialog"
                    width="800px"
            >
                <div class="prompt">
                    <div>自动续费说明：</div>
                    <div>1. 开通自动续费后，系统会在到期日前7天自动为其续费。</div>
                    <div>2. 自动续费将按照您最近一次设置的续费周期执行。</div>
                    <div>3. 请保持云服务账号内可用余额充足，系统自动从您的账号中扣取下一个续费周期的费用。</div>
                    <div>4. 自动续费执行时，会针对某一个实例资源生成一个续费订单。</div>
                    <div>5. 若资源到期时间不满7天时开启自动续费，系统将立即执行一次自动续费操作。</div>
                </div>
                <div>
                    <occ-table :tableData="autoData" :columObj="autoObj"
                               :isOp="autoObj.isOp" :style-height="'calc(100%-10px)'" @changeTime="changeTime">
                    </occ-table>
                </div>
                <div slot="footer">
                    <za-button :loading="confirmBtnLoading" :type="buttonTypeObj.BLUE" @click="handleSet">
                        确定
                    </za-button>
                    <za-button :disabled="confirmBtnLoading" :type="buttonTypeObj.GREY" @click="autoDialog = false">
                        取消
                    </za-button>
                </div>
            </za-dialog>
        </div>

    </div>
</template>
<script>
    import zaGrid from '@/za/components/zaGrid'
    import {
        apiResource,
        apiResourceList,
        apiOrderaAutoSubmit,
        apiOrderCloseAutoRenew
    } from '@/api/moneyCenter/orderManagement/renewal'
    import {tenBitTimestamp} from '@/utils'

    export default {
        components: {zaGrid},
        data() {
            return {
                exprieIndex: 0,
                expireList: [],
                regionIndex: 0,
                regionList: [],
                typeIndex: 0,
                typeList: [],
                conditionIndex: 0,
                conditionList: [{name: '手动续费', value: 2}, {name: '自动续费', value: 1}],
                title: '',
                total: 0,
                checkList: [],
                checkboxList: [],
                multipleSelection: [],
                pageObj: {
                    renewType: 2,
                    serviceType: '',
                    region: '',
                    expireType: 0,
                    pageNo: 1,
                    pageSize: 10,
                    keyword: ''
                },
                tableData: [],
                columObj: {
                    // 控制选择框
                    selection: true,
                    isOp: true,
                    width: '400px',
                    columnData: [
                        {
                            copy: true,
                            prop: "productName",
                            label: "产品名称",
                            width: "",
                            flag: true,
                            align: "center",

                        },
                        {
                            copy: true,
                            prop: "hortId",
                            label: "资源ID",
                            flag: true,
                            width: "200",
                            align: "center",

                        },
                        // {
                        //     text: true,
                        //     prop: "status",
                        //     label: "资源状态",
                        //     flag: true,
                        //     width: "",
                        //     align: "center",

                        // },
                        {
                            text: true,
                            prop: "createAt",
                            label: "创建时间",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "expireAt",
                            label: "到期时间",
                            flag: true,
                            width: "",
                            align: "center",

                        },
                        {
                            ownDefined: true,
                            prop: "payType",
                            label: "续费模式",
                            flag: true,
                            width: "",
                            align: "center",
                            ownDefinedReturn: (row, $index) => {
                                return row.payType == '1' ? '预付费' : '后付费'
                            }
                        },
                    ],
                },
                dialogInfo: false,
                unitPrice: '2000',
                renewalData: [],
                renewalObj: {
                    selection: false,
                    isOp: false,
                    width: '300px',
                    columnData: [
                        {
                            text: true,
                            prop: "productName",
                            label: "产品名称",
                            width: "",
                            flag: true,
                            align: "center",

                        },
                        {
                            text: true,
                            prop: "hortId",
                            label: "资源ID",
                            flag: true,
                            width: "200",
                            align: "center",

                        },
                        {
                            text: true,
                            prop: "expireAt",
                            label: "当前到期时间",
                            flag: true,
                            width: "",
                            align: "center",

                        },
                        {
                            selectState: true,
                            prop: "time",
                            label: "续费时长",
                            flag: true,
                            width: "",
                            align: "center",
                            selectList: [{label: '1个月', value: 1}, {label: '2个月', value: 2}, {label: '3个月', value: 3},
                                {label: '4个月', value: 4}, {label: '5个月', value: 5}, {label: '6个月', value: 6},
                                {label: '7个月', value: 7}, {label: '8个月', value: 8}, {label: '9个月', value: 9},
                                {label: '10个月', value: 10}, {label: '11个月', value: 11}, {label: '一年', value: 12},
                                {label: '两年', value: 24}, {label: '三年', value: 36}]
                        },
                        {
                            text: true,
                            prop: "lateTime",
                            label: "续费后到期时间",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                    ],
                },
                autoDialog: false,
                autoData: [],
                autoObj: {
                    selection: false,
                    isOp: false,
                    width: '300px',
                    columnData: [
                        {
                            text: true,
                            prop: "productName",
                            label: "产品名称",
                            width: "",
                            flag: true,
                            align: "center",

                        },
                        {
                            text: true,
                            prop: "hortId",
                            label: "资源ID",
                            flag: true,
                            width: "200",
                            align: "center",
                        },
                        {
                            text: true,
                            prop: "expireAt",
                            label: "当前到期时间",
                            flag: true,
                            width: "",
                            align: "center",

                        },
                        {
                            selectState: true,
                            prop: "time",
                            label: "续费时长",
                            flag: true,
                            width: "",
                            align: "center",
                            selectList: [{label: '1个月', value: 1}, {label: '2个月', value: 2}, {label: '3个月', value: 3},
                                {label: '4个月', value: 4}, {label: '5个月', value: 5}, {label: '6个月', value: 6},
                                {label: '7个月', value: 7}, {label: '8个月', value: 8}, {label: '9个月', value: 9},
                                {label: '10个月', value: 10}, {label: '11个月', value: 11}, {label: '一年', value: 12},
                                {label: '两年', value: 24}, {label: '三年', value: 36}]
                        },
                        {
                            text: true,
                            prop: "lateTime",
                            label: "续费后到期时间",
                            flag: true,
                            width: "",
                            align: "center",
                        },
                    ],
                },

            }
        },
        created() {
            this.dialogTypeObj.ADD.text = '续费时长'
            this.dialogTypeObj.EDIT.text = '设置为自动续费'
            this.dialogTypeObj.TEST = {
                type: 3,
                text: '设置为手动续费',
            }
            this.title = this.$route.meta.title
            this.getList()
            this.getCondtion()
            this.getChecklist()
        },
        methods: {
            getCondtion() {
                this.onSubmitData(apiResource, this.pageObj, null, false, false, (res) => {
                    this.changeList(res.data.expire, 'expire')
                    this.changeList(res.data.region, 'region')
                    this.changeList(res.data.type, 'type')
                })
            },
            getList() {
                this.onSubmitData(apiResourceList, this.pageObj, null, false, false, (res) => {
                    this.tableData = res.data.list
                    this.total = res.data.total
                })
            },
            changeList(arr, logArr) {
                let list = []
                if(logArr!=='expire'){
                    let num = 0
                    arr.forEach((item) => {
                        num += item.value
                        list.push(item)
                    })
                    list.unshift({
                        name: "全部", key: "0", value: num
                    })
                }else{
                    list=arr
                }
                switch (logArr) {
                    case 'expire':
                        this.expireList = list
                        break
                    case 'region':
                        this.regionList = list
                        break
                    case 'type':
                        this.typeList = list
                        break
                    default:
                        break
                }
            },
            //续费
            batchRenewal(row) {
                this.dialogType = this.dialogTypeObj.ADD.type
                this.dialogInfo = true;
                let list = []
                if (row.productName) {
                    list.push({
                        resourceId:row.resourceId,
                        productName: row.productName,
                        hortId: row.hortId,
                        expireAt: row.expireAt,
                        time: 1,
                        lateTime: tenBitTimestamp(new Date(new Date(row.expireAt).getTime() / 1000 + 3600 * 24 * 30))
                    })
                } else {
                    this.multipleSelection.forEach((val) => {
                            list.push({
                                resourceId:row.resourceId,
                                productName: val.productName,
                                hortId: val.hortId,
                                expireAt: val.expireAt,
                                time: 1,
                                lateTime: tenBitTimestamp(new Date(new Date(val.expireAt).getTime() / 1000 + 3600 * 24 * 30))
                            })


                    })
                }
                this.renewalData = list
            },
            changeTime(row) {
                row.lateTime = tenBitTimestamp(new Date(new Date(row.expireAt).getTime() / 1000 + 3600 * 24 * 30 * row.time))
            },
            //设置自动续费
            autoRenewal(row) {
                this.dialogType = this.dialogTypeObj.EDIT.type;
                this.autoDialog = true;
                let list = []
                if (row.productName) {
                    list.push({
                        resourceId:row.resourceId,
                        productName: row.productName,
                        hortId: row.hortId,
                        expireAt: row.expireAt,
                        time: 1,
                        lateTime: tenBitTimestamp(new Date(new Date(row.expireAt).getTime() / 1000 + 3600 * 24 * 30))
                    })
                } else {
                    this.multipleSelection.forEach((val) => {
                        if(val.renewType==2){
                            list.push({
                                resourceId:row.resourceId,
                                productName: val.productName,
                                hortId: val.hortId,
                                expireAt: val.expireAt,
                                time: 1,
                                lateTime: tenBitTimestamp(new Date(new Date(val.expireAt).getTime() / 1000 + 3600 * 24 * 30))
                            })
                        }

                    })
                }
                this.autoData = list
            },
            //关闭自动续费
            handleEdit(val) {
                let result = {
                    resourceIds: []
                }
                if (val.resourceId) result.resourceIds = [val.resourceId]
                // else result.resourceIds = this.multipleSelection.map(item => item.resourceId==1)
                else result.resourceIds = this.multipleSelection.filter((item)=>{
                    if(item.resourceId==1){
                        return item
                    }
                })
                if(result.resourceIds&&result.resourceIds.length){
                    this.onSubmitData(apiOrderCloseAutoRenew, result, null, false, false, (res) => {
                        this.$msgSuccess('自动续费已关闭')
                        this.getList()
                    })
                }else{
                    this.$msgWarning('您所选的订单包含手动续费订单,请取消后重试')
                }
            },
            //自动续费确认
            async handleSet() {
                let data = {list: this.autoData}
                let res = await apiOrderaAutoSubmit(data).finally(() => (this.confirmBtnLoading = false))
                if (res.code == 0) {
                    this.autoDialog = false
                    this.$msgSuccess('设置成功')
                    this.getList()
                }
            },
            //下一步
            handleSubmitForm() {
                localStorage.setItem('confirmation', JSON.stringify(this.renewalData))
                this.$router.push({path: '/moneyCenter/orderManagement/renewal/orderConfirmation'})
            },
            //条件选择
            chooseCond(index, item, val) {
                switch (val) {
                    case '1':
                        this.conditionIndex = index
                        this.pageObj.renewType = item.value
                        this.getList()
                        break
                    case '2':
                        this.typeIndex = index
                        this.pageObj.serviceType = item.key
                        if (this.pageObj.serviceType == '0') {
                            this.pageObj.serviceType = ''
                        }
                        this.getList()
                        break
                    case '3':
                        this.regionIndex = index
                        this.pageObj.region = item.key
                        if (this.pageObj.region == '0') {
                            this.pageObj.region = ''
                        }
                        this.getList()
                        break
                    case '4':
                        this.exprieIndex = index
                        this.pageObj.expireType = item.key
                        this.getList()
                        break
                    default:
                        break
                }

            },
            //多选触发
            handleSelectionChange(e) {
                this.multipleSelection =e
                // e.forEach((item) => {
                //     if (item.payType == 2) {
                //         this.multipleSelection = []
                //     } else {
                //         this.multipleSelection.push(item)
                //     }
                // })

            },
        }

    }
</script>
<style lang="scss" scoped>
    @import '~@/za/styles/mixin.scss';

    .title {
        @include top_title($size: 14px, $weight: 600, $color: $base-font-color-black-60);
        width: 100%;
        height: 20px;
        margin-bottom: 20px;
    }

    .screening {
        height: 216px;
        background: #FFFFFF;
        border-radius: 2px;
        padding: 20px;
    }

    .condition {
        display: flex;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
          cursor: pointer;
        div:first-child {
            margin-top: 5px;
        }

        .choose {
            width: 130px;
            height: 32px;
            border-radius: 2px;
            border: 1px solid #D9D9D9;
            color: #D9D9D9;
            line-height: 32px;
            text-align: center;
            margin-bottom: 20px;
            margin-left: 10px;
            margin-right: 10px;
        }

        .selected {
            width: 130px;
            height: 32px;
            border-radius: 2px;
            border: 1px solid #3370FF;
            color: #3370FF;
            line-height: 32px;
            text-align: center;
            margin-left: 10px;
            margin-right: 10px;
        }

    }

    .mgleft {
        margin-left: 26px;
    }

    .main {
        position: relative;
        height: 754px;
        background: #FFFFFF;
        border-radius: 2px;
        margin-top: 20px;
        padding: 20px;

        .choose {
            width: 130px;
            height: 32px;
            border-radius: 2px;
            border: 1px solid #D9D9D9;
            color: #D9D9D9;
            line-height: 32px;
            text-align: center;
            margin-bottom: 20px;
        }

        .selectTime {
            width: 130px;
            height: 32px;
            border-radius: 2px;
            border: 1px solid #3370FF;
            color: #3370FF;
            line-height: 32px;
            text-align: center;
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    .prompt {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #555555;
        margin-left: 20px;
        margin-bottom: 20px;

        div {
            margin-bottom: 10px;
        }

    }
</style>