<template>
  <div :class="{ hidden: hidden }" class="pagination-container" :style="{textAlign: position }">
    <el-pagination
      background
      :current-page.sync="currentPage"
      layout="total, sizes, prev, pager, next, jumper"
      :page-size.sync="pageSize"
      :page-sizes="pageSizes"
      :total="total"
      v-bind="$attrs"
      v-on="$listeners"
      @current-change="$emit('pagination')"
      @size-change="$emit('pagination')"
    />
  </div>
</template>

<script>

export default {
  name: 'Pagination',
  props: {
    total: {
      required: true,
      type: Number,
    },
    page: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 20,
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30]
      },
    },
    autoScroll: {
      type: Boolean,
      default: true,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    position:{
      type: String,
      default: 'right',
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      },
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      },
    },
  },
}
</script>

<style scoped>
.pagination-container {
  padding: 36px 0;
}

.pagination-container.hidden {
  display: none;
}
</style>
