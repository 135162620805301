var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "add-panel" }, [
    _c(
      "div",
      {
        staticClass: "comp-con",
        style: _vm.itemWidth !== "" ? { width: _vm.itemWidth } : "",
      },
      _vm._l(_vm._value, function (item, index) {
        return _c(
          "div",
          {
            key: item.guid,
            staticClass: "comp-item",
            class: [
              { "last-item": _vm.isCloseAddBtn },
              { "is-bg": _vm.isShowBg },
              { "comp-bottom": _vm.isOpenMarginBottom },
            ],
          },
          [
            _c(_vm.comp, {
              key: item.guid,
              ref: "component",
              refInFor: true,
              tag: "component",
              attrs: {
                disabled: _vm.disabled,
                "head-data": _vm.headData,
                "sub-data": _vm.subData,
              },
              model: {
                value: _vm._value[index],
                callback: function ($$v) {
                  _vm.$set(_vm._value, index, $$v)
                },
                expression: "_value[index]",
              },
            }),
            _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm._value.length > 1,
                  expression: "_value.length > 1",
                },
              ],
              staticClass: "el-icon-error close-btn",
              class: [
                { "close-btn_disabled": _vm.disabled },
                { "close-btn_no_bottom": !_vm.isOpenMarginBottom },
              ],
              style: _vm.closeStyle,
              on: {
                click: function ($event) {
                  return _vm._handleDelSub(index)
                },
              },
            }),
          ],
          1
        )
      }),
      0
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isCloseAddBtn,
            expression: "!isCloseAddBtn",
          },
        ],
        staticClass: "add-btn",
        class: { "add-btn_disabled": _vm.disabled },
        on: { click: _vm._handleAdd },
      },
      [
        _c("i", { staticClass: "el-icon-circle-plus" }),
        _c("span", [_vm._v(_vm._s(_vm.translateTitle("btn.add")))]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }