<template>
  <div class="register_main">
    <el-row>
      <div class="title">账户注册</div>
      <el-form
        ref="form"
        :disabled="confirmBtnLoading"
        :model="form"
        :rules="rules"
        class="register"
        label-position="right"
      >
        <el-col :span="24">
          <el-form-item prop="username">
            <el-input
              v-model.trim="form.username"
              :placeholder="translateTitle('register.placeAccount')"
              :readonly="confirmBtnLoading"
              @keyup.native="trim('username')"
              @input="clangeWaringDesc()"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="password">
            <el-input
              v-model.trim="form.password"
              :placeholder="translateTitle('register.placePwd')"
              :readonly="confirmBtnLoading"
              ref="password"
              :type="passwordType"
              @keyup.native="trim('password')"
            >
              <template v-if="passwordType === 'password'" #suffix>
                <za-svg-icon class-name="svg-suffix" icon-class="za-eye-close" @click="handlePassword" />
              </template>
              <template v-else #suffix>
                <za-svg-icon class-name="svg-suffix" icon-class="za-eye-open" @click="handlePassword" />
              </template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="confirmPassword">
            <el-input
              v-model.trim="form.confirmPassword"
              :placeholder="translateTitle('register.placeConfirmPwd')"
              :readonly="confirmBtnLoading"
              :type="newPasswordType"
              maxlength="30"
              ref="newPasswordType"
              @keyup.native="trim('confirmPassword')"
            >
              <template v-if="newPasswordType === 'password'" #suffix>
                <za-svg-icon class-name="svg-suffix" icon-class="za-eye-close" @click="handleNewPassword" />
              </template>
              <template v-else #suffix>
                <za-svg-icon class-name="svg-suffix" icon-class="za-eye-open" @click="handleNewPassword" />
              </template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="phone">
            <el-input
              v-model.trim="form.phone"
              :placeholder="translateTitle('register.placeMobile')"
              :readonly="confirmBtnLoading"
              maxlength="11"
              @keyup.native="trim('phone')"
              @input="clangeWaringDesc()"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-col :span="16">
            <el-form-item prop="smsCode">
              <el-input
                v-model="form.smsCode"
                placeholder="请输入验证码"
                maxlength="6"
                @keyup.enter.native="handleSub()"
                @input="clangeWaringDesc()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="1">&nbsp;</el-col>
          <el-col :span="7">
            <el-button @click="sendData" class="sendInfo" :disabled="dis" :class="{ grey: isGrey, blue: !isGrey }">
              <span v-if="telState == 1">发送验证码</span>
              <span v-if="telState == 2">{{ count }}秒重试</span>
              <span v-if="telState == 3">重新发送</span>
            </el-button>
          </el-col>
        </el-col>
        <el-col>
          <span v-if="waringDescState" class="red-font">{{ waringDesc }}</span>
        </el-col>
        <el-col>
          <el-checkbox v-model="checked" class="confirmCheck" @keyup.enter.native="submitForm()"></el-checkbox>
          <span class="clause" style="color: #999999">我已阅读并同意</span>
          <span class="toclause" @click="$router.push('/protocal/registerPrototal')">服务协议，</span>
          <span class="toclause" @click="$router.push('/protocal/policy')">隐私条款，</span>
          <span class="toclause" @click="$router.push('/protocal/onlineOrder')">线上订购协议</span>
        </el-col>
        <el-col>
          <za-button :loading="confirmBtnLoading" :size="14" class="button" @click="handleSub">
            {{ translateTitle('btn.register') }}
          </za-button>
        </el-col>
        <el-col>
          <p class="login-btn" @click="handleClose">
            已有账号,
            <span style="color: #1b5deb">立即登录</span>
          </p>
        </el-col>
      </el-form>
    </el-row>
    <div>
      <el-dialog width="25%" title="验证" :visible.sync="innerVisible" append-to-body>
        <za-verify v-if="showVerity" ref="verify" @verifySuccess="success" :selectKey="dialogType" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import { translateTitle } from '@/utils/i18n'
  import { reqRegister } from '@/za/model/reqModel'
  import { apiRegister, apiSendSms } from '@/api/apiLogin'
  import ZaVerify from '@/za/components/zaVerify'
  import { resLoginObj } from '@/za/model/resModel'
  import { mapActions } from 'vuex'
  import { isHaveSpace, isPwdNotTest, isSPpassWord } from '@/utils/validate'

  export default {
    name: 'register',
    directives: {
      focus: {
        inserted(el) {
          el.querySelector('input').focus()
        },
      },
    },
    components: { ZaVerify },
    data() {
      const password = (rule, value, callback) => {
        if (value === '') callback(new Error(translateTitle('register.placePwd')))
        else if (value.length < 6 || value.length > 20) callback(new Error(' 请输入6-20字符'))
        else if (isPwdNotTest(value)) callback(new Error('密码设置不能输入中文'))
        else if (isSPpassWord(value)) callback(new Error('密码复杂度太低，限字母、数字或特殊字符至少包含2种'))
        else callback()
      }
      const confirmPassword = (rule, value, callback) => {
        if (value === '') callback(new Error(translateTitle('register.tipsConfirmPwd')))
        else if (this.form.confirmPassword !== '' && this.form.confirmPassword !== this.form.password)
          callback(new Error(translateTitle('register.tipsDistinctPwd')))
        else callback()
      }
      const userName = (rule, value, callback) => {
        let regx = /^[A-Za-z0-9\u4e00-\u9fa5]{6,20}$/
        if (value == '') callback(new Error('请输入用户名'))
        else if (value.length < 6 || value.length > 20) callback(new Error('请输入6-20字符，限数字、字母或者汉字'))
        else if (!regx.test(value)) callback(new Error('请输入6-20字符，限数字、字母或者汉字'))
        else callback()
      }
      const smsCode = (rule, value, callback) => {
        if (value === '') callback(new Error('验证码不能为空'))
        else if (isHaveSpace(value)) callback(new Error('验证码不能包含空格！'))
        else callback()
      }
      return {
        form: Object.assign({}, reqRegister),
        rules: {
          username: [{ required: true, validator: userName, trigger: 'blur' }],
          password: [{ required: true, trigger: 'blur', validator: password }],
          confirmPassword: [{ required: true, trigger: 'blur', validator: confirmPassword }],
          smsCode: [{ required: true, trigger: 'blur', validator: smsCode }],
          phone: [{ required: true, message: translateTitle('register.placeMobile'), trigger: 'blur' }],
        },
        count: '',
        passwordType: 'password',
        newPasswordType: 'password',
        telState: 1,
        dis: false,
        show: true,
        timer: null, //设置计时器,
        isGrey: false, //按钮样式
        showVerity: false,
        innerVisible: false,
        checked: false,
      }
    },
    watch: {
      'form.smsCode': {
        handler(value) {
          this.form.smsCode = value.replace(/\s+/g, '')
        },
      },
    },
    created() {
      const code = this.$route.query.id
      code ? (this.form.popularizeCode = code) : (this.form.popularizeCode = '')
    },
    mounted() {},
    methods: {
      ...mapActions({
        setLoginData: 'user/setLoginData',
      }),
      translateTitle,
      trim(val) {
        this.form[val] = this.form[val].replace(/\s*/g, '')
      },
      submitForm() {
        this.handleSub()
      },
      handleSub() {
        if (!this.checked) {
          this.$msgWarning('请阅读并同意协议')
          return
        }
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            try {
              this.confirmBtnLoading = true
              let res = await apiRegister(this.form).catch()
              if (res.code == 0) {
                this.$printLog(res, 'Login')
                const resObj = Object.assign(resLoginObj, res.data)
                await this.setLoginData(resObj).catch()
                await this.$router.push('/registerSuccess')
                // this.$printLog(res, 'Login')
                // const resObj = Object.assign(resLoginObj, res.data)
                // await this.setLoginData(resObj).catch()
                // // await this.$router.push('/registerSuccess')
                // await this.$router.push('/evaluationActivity')
              } else if (res.code == 569983) {
                this.waringDescState = true
                this.waringDesc = res.msg
              }
            } finally {
              this.confirmBtnLoading = false
            }
          } else {
            return false
          }
        })
      },
      handleClose() {
        this.$baseEventBus.$emit('register', false)
        this.$emit('update:visible', false)
      },
      handlePassword() {
        this.passwordType === 'password' ? (this.passwordType = '') : (this.passwordType = 'password')
        this.$nextTick(() => {
          this.$refs.password.focus()
        })
      },
      handleNewPassword() {
        this.newPasswordType === 'password' ? (this.newPasswordType = '') : (this.newPasswordType = 'password')
        this.$nextTick(() => {
          this.$refs.newPasswordType.focus()
        })
      },
      async success(val) {
        this.innerVisible = false
        this.showVerity = false
        let res = await apiSendSms({ phone: this.form.phone }).catch()

        if (res.code == 0) {
          let TIME_COUNT = 60
          if (!this.timer) {
            this.count = TIME_COUNT
            this.isGrey = true
            this.telState = 2
            this.dis = true
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--
              } else {
                this.dis = false
                this.isGrey = false
                this.telState = 3
                clearInterval(this.timer)
                this.timer = null
              }
            }, 1000)
          }
        } else if (res.code == 589996) {
          this.waringDescState = true
          this.waringDesc = res.msg
        }
      },
      sendData() {
        if (!this.form.phone) {
          this.$msgWarning('手机号不能为空')
        } else {
          this.innerVisible = true
          this.showVerity = true
        }
      },
      destroyed() {},
    },
  }
</script>

<style lang="scss" scoped>
  @import '~@/za/styles/mixin.scss';

  .register_main {
    width: 360px !important;
  }

  .title {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #000000;
    margin-bottom: 33px;
    text-align: center;
  }

  .login-btn {
    font-size: 14px;
    cursor: pointer;
    text-align: end;
  }

  .register {
    width: 360px;
    // height: 500px;

    .button {
      width: 100%;
      height: 40px;
      text-align: center;
      font-weight: 400;
      line-height: 22px;
      margin: 0;
    }

    .svg-prefix {
      margin-left: 5px;
    }
  }

  .confirmCheck {
    font-size: 12px;
    font-weight: 400;
    color: #555555;
    margin-top: 21px;
    margin-bottom: 13px;
  }

  .clause {
    font-size: 14px;
    font-weight: 400;
    color: #409eff;
  }

  .button {
    text-align: center;
    font-weight: bold;
    line-height: 22px;
    margin: 0 0 20px 0;
    width: 360px;
    height: 40px;
    background: #4680ff;
    border-color: #4680ff;
    box-shadow: none;
    color: #fff;
  }

  .toclause {
    font-size: 14px;
    font-weight: 400;
    color: #409eff;
    cursor: pointer;
  }

  .button:hover {
    background: #1b5deb;
    border-color: #1b5deb;
  }
</style>
