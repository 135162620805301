<template>
    <div class="resources">
        <div class="title">资源预警</div>
        <div style="display: grid;grid-template-columns: 1fr 1fr ;">
            <div v-for="(item,index) in list" :key="index" class="resourcesText">
                <za-svg-icon :icon-class="item.icon" class-name="icon"></za-svg-icon>
                <span>{{item.name}}</span>
                <span style="color: red;margin-left: 3px">{{'('+item.num+')'}}</span>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                list: [
                    {name: '资源超标提醒', icon: 'za-internet', num: 0},
                    {name: '网站挂马提醒', icon: 'za-internet', num: 0},
                    {name: '非法信息通知', icon: 'za-internet', num: 0},
                    {name: '云监控报警', icon: 'za-internet', num: 0},
                    {name: '网站反诈核验', icon: 'za-internet', num: 0}
                ]
            }
        }
    }
</script>
<style lang="scss" scoped>
    .resources {
        width: 360px;
        height: 148px;
        background: #FFFFFF;
        border-radius: 2px;
        margin-bottom: 16px;
        padding: 20px;

        .title {
            width: 100%;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 20px;
            margin-bottom: 13px;
        }

        .icon {
            margin-right: 3px;
        }

        .resourcesText {
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            line-height: 17px;
            margin-bottom: 12px;
        }
        .resourcesText:hover{
            color: #3370FF;
        }
    }
</style>