<template>
    <div>
        <div class="title">
            <span>{{title}}</span>
        </div>
        <div>
            <card></card>
            <settlement></settlement>
        </div>
    </div>
</template>
<script>
    import card from './components/card'
    import settlement from './components/settlement'

    export default {
        components:{card,settlement},
        data() {
            return {
                title:''
            }
        },
        created() {
            this.title=this.$route.meta.title
        }
    }
</script>
<style lang="scss" scoped>
    .title{
        height: 62px;
        background: #FFFFFF;
        border-radius: 2px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        padding-left: 20px;
        line-height: 62px;
        margin-bottom: 20px;
    }
</style>